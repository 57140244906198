import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import {
  Almacen,
  AlmacenFormValues,
} from "../../../../app/models/inventario/Almacen";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";

interface idProps {
  id: number
}

export default observer(function AlmacenForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 8;
  const costeoTipoByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "PEPS",
      value: 1,
      contex: "PEPS",
    },
    {
      key: 2,
      text: "Promedio",
      value: 2,
      contex: "Promedio",
    },
  ];
  const { almacenStore, almacenTipoStore, modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const {
    createAlmacen,
    updateAlmacen,
    loadAlmacen,
    almacenesById,
    loadingInitial,
    loading,
  } = almacenStore;
  const { almacenTipoByDropdownByClave, loadAlmacenTipos } = almacenTipoStore;
  const [almacen, setAlmacen] = useState(new AlmacenFormValues());
  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de almacen es requerida."),
    nombre: Yup.string().required("El nombre de almacen es requerida."),
    almacenTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo almacen.")
      .required("El tipo de almacen es requerido."),
    costeoTipoId: Yup.number()
      .min(1, "Favor de seleccionar tipo costeo.")
      .required("El tipo de costeo es requerido."),
  });

  useEffect(() => {
    loadAlmacenTipos();
  }, [loadAlmacenTipos]);

  useEffect(() => {
    if (id) {
      loadAlmacen(id).then((almacen) => {
        setAlmacen(new AlmacenFormValues(almacen));
      });
    } else {
      almacen.clave =
        almacenesById.map((almacen) => almacen.clave).length > 0
          ? Math.max(...almacenesById.map((almacen) => almacen.clave)) + 1
          : 1;
    }
  }, []);

  function handleSubmit(almacen: Almacen) {
    almacen.almacenTipo = undefined;
    if (almacen.almacenTipoId.toString() !== "0" && almacen.nombre !== null) {
      almacen.id > 0 ? updateAlmacen(almacen) : createAlmacen(almacen);
    } else {
      toast.info("Favor de llenar campos");
    }
  }
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial)
    return <LoadingComponent content="Loading Cuenta Inventario" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={almacen}
      >
        {({ values, setFieldValue }) => (
          <Form className="ui form">
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Identificación del Almacen
              </Label>
              <div className="cols">
                <div className="col15Block">
                  <MyTextInput
                    placeholder="Clave"
                    label="Clave"
                    name="clave"
                    disabled={true}
                    tabIndex={0}
                    maxLength={3}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    autoFocus={true}
                    placeholder="Nombre"
                    label="Nombre"
                    name="nombre"
                    tabIndex={1}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    label="Nombre corto"
                    placeholder="Nombre corto"
                    name="nombreCorto"
                    tabIndex={2}
                    enterInput={enterInput}
                    maxLength={15}
                  />
                </div>

                <div className="displayInLine">
                  <div className="col30">
                    <MySelectInput
                      tabIndex={3}
                      placeholder={"Seleccionar"}
                      label="Tipo Almacén"
                      name="almacenTipoId"
                      options={almacenTipoByDropdownByClave}
                      enterInput={enterInput}
                      inputWidth="85px"
                      menuWidth="320px"
                    />
                  </div>
                  <div className="col30">
                    <MySelectInput
                      tabIndex={4}
                      placeholder={"Seleccionar"}
                      label="Tipo costeo"
                      name="costeoTipoId"
                      options={costeoTipoByDropdown}
                      enterInput={enterInput}
                    />
                  </div>
                </div>
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  id="fechaObservacion"
                  type="button"
                  tabIndex={5}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(fechaO) => {
                          setFieldValue("fechaObservacion", fechaO);
                        }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Almacen
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/almacenes"
                loading={loading}
                tabIndex={6}
                firstElement={0}
                lastElement={8}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});