import { makeAutoObservable, runInAction } from "mobx";
import agent, { urlApi } from "../../api/agent";
import { SaldoCliente } from "../../models/gestionCliente/SaldoCliente";
import { toast } from "react-toastify";

export default class SaldoClienteStore {
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    imprimirSaldoClientes = async (filtros: SaldoCliente) => {
        this.loading = true;
        try {
            const namePdf = await agent.SaldoClientes.imprimirSaldoClientes(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    const blob = new Blob([namePdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                }
                this.loading = false;
            });
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}