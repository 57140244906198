import {
  FechaObservacion,
  FechaObservacionValues,
} from "../configuracion/FechaObservacion";
import { ConceptoClienteTipo } from "./ConceptoClienteTipo";
export interface ConceptoCliente {
  id: number;
  clave: number;
  nombre: string;
  conceptoDeClienteTipoId: number;
  tipo?: ConceptoClienteTipo;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}
export class ConceptoClienteFormValues implements ConceptoCliente {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  conceptoDeClienteTipoId: number = 0;
  tipo?: ConceptoClienteTipo;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  demo: boolean = false;

  constructor(conceptoCliente?: ConceptoCliente) {
    Object.assign(this, conceptoCliente);
  }
}
