import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import {
  ControlListaDePrecio,
  ControlListaDePrecioArregloFormValues,
} from "../../models/controlPrecio/ControlListaDePrecio";
import {
  PaginationFormValues,
  PaginationSpecParams,
} from "../../models/Especificaciones/PaginationSpecParams";
import { Pagination } from "../../models/controlPrecio/pagination";

export default class ControlListaDePrecioStore {
  controlListaDePrecioRegistry = new Map<number, ControlListaDePrecio>();
  selectedControlListaDePrecio: ControlListaDePrecio | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  filtoControlListaPrecio: PaginationSpecParams = new PaginationFormValues();
  controlListaPrecioParams: PaginationSpecParams = new PaginationFormValues();
  pagination = new Pagination();
  empresaSucursal: PaginationSpecParams = new PaginationFormValues();
  constructor() {
    makeAutoObservable(this);
  }

  get controlListaDePrecioById() {
    return Array.from(this.controlListaDePrecioRegistry.values()).sort();
  }

  get controlesListasDePreciosByDate() {
    return Array.from(this.controlListaDePrecioRegistry.values()).sort(
      (a) => a.id
    );
  }
  setControlesListasPrecio(id: number) {
    //if(this?.controlListaPrecioParams!==undefined){
    // this.controlListaPrecioParams.id = 0;
    // this.controlListaPrecioParams.clave = 0;
    // this.controlListaPrecioParams.nombre = "";
    // this.controlListaPrecioParams.sort = "nombre";
    // this.controlListaPrecioParams.pageNumber = 1;
    // this.controlListaPrecioParams.pageSize = 12;
    // this.controlListaPrecioParams.empresaId = 0;
    // this.controlListaPrecioParams.sucursalId = 0;
    // this.controlListaPrecioParams.tipoVentaId = 0;
    // this.controlListaPrecioParams.serieId = 0;
    // }
  }

  loadControlesListasDePrecios = async () => {
    this.setLoadingInitial(true);
    try {
      const controlesListasDePrecios =
        await agent.ControlesListasDePrecios.list();
      return controlesListasDePrecios;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadPaginationControlesListasDePrecios = async (
    filtoControlListaPrecio: PaginationSpecParams
  ) => {
   // this.setLoadingInitial(true);
    try {
      const controlesListasDePrecios =
        await agent.ControlesListasDePrecios.pagingList(
          filtoControlListaPrecio
        );
      runInAction(() => {
        this.pagination = controlesListasDePrecios;
      });
      return controlesListasDePrecios.data;
    } catch (error) {
      console.log(error);
    //  this.setLoadingInitial(false);
    }
  };

  // getControlesListasDePrecios = async () => {
  //   this.setLoadingInitial(true);
  //   const controlesListasDePrecios = await agent.ControlesListasDePrecios.list();
  //   try {
  //     controlesListasDePrecios.forEach((controlListaDePrecio) => {
  //       this.setControlListaDePrecio(controlListaDePrecio);
  //     });
  //     this.setLoadingInitial(false);
  //     return controlesListasDePrecios;
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //     return controlesListasDePrecios;
  //   }
  // };

  loadControlesListaDePrecio = async (id: number) => {
    let controlListaDePrecio = this.getControlListaDePrecio(id);
    if (controlListaDePrecio) {
      this.selectedControlListaDePrecio = controlListaDePrecio;
      return controlListaDePrecio;
    } else {
      this.loadingInitial = true;
      try {
        controlListaDePrecio = await agent.ControlesListasDePrecios.details(id);
        this.setControlListaDePrecio(controlListaDePrecio);
        this.selectedControlListaDePrecio = controlListaDePrecio;
        this.setLoadingInitial(false);
        return controlListaDePrecio;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setControlListaDePrecio = (
    controlListaDePrecio: ControlListaDePrecio
  ) => {
    this.controlListaDePrecioRegistry.set(
      controlListaDePrecio.id,
      controlListaDePrecio
    );
  };

  private getControlListaDePrecio = (id: number) => {
    return this.controlListaDePrecioRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createControlListaDePrecio = async (
    controlListaDePrecio: ControlListaDePrecioArregloFormValues
  ) => {
    this.loading = true;
    try {
      await agent.ControlesListasDePrecios.create(controlListaDePrecio);
      runInAction(() => {
        //this.selectedControlListaDePrecio = controlListaDePrecio;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Control de lista De Precio creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateControlListaDePrecio = async (
    controlListaDePrecio: ControlListaDePrecio
  ) => {
    this.loading = true;
    try {
      await agent.ControlesListasDePrecios.update(controlListaDePrecio);
      runInAction(() => {
        this.selectedControlListaDePrecio = controlListaDePrecio;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Control de Lista De Precio modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteControlListaDePrecio = async (id: number) => {
    if (id > 0) {
      this.loading = true;
      try {
        await agent.ControlesListasDePrecios.delete(id);
        runInAction(() => {
          this.controlListaDePrecioRegistry.delete(id);
          this.loading = false;
        });
        toast.success("Control de Lista De Precio eliminada con éxito");
      } catch (error) {
        toast.error("Problema al eliminar Control Lista De Precio");
        runInAction(() => {
          this.loading = false;
        });
      }
    }
  };

  /*get ControlListaDePreciosByDropdown(){
    let ListaDePreciosArreg=[new TipoDropDownValues()];
    let tipoMonedasOpt =new TipoDropDownValues();
    const montipos=  Array.from(this.listaDePrecioRegistry.values()).sort(
      (a, b) =>  {  if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0}
    );

    montipos.forEach((element) => {
      tipoMonedasOpt.key = element.id;
      tipoMonedasOpt.text = element.nombre;
      tipoMonedasOpt.value = element.id;
      tipoMonedasOpt.content = element.nombre;
      ListaDePreciosArreg.push(tipoMonedasOpt);
      tipoMonedasOpt =new TipoDropDownValues();
      
    });
    return ListaDePreciosArreg;
    }*/
}
