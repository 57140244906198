import React, { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label, Checkbox, Grid, GridColumn } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { Impuesto, ImpuestoFormValues } from "../../../../app/models/configuracion/Impuesto";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import * as Yup from "yup";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";
import { ImpuestoProducto, ImpuestoProductoFormValues } from "../../../../app/models/inventario/ImpuestoProducto";

interface idProps {
  id: number
}

export default observer(function ImpuestoProductoForm({ id }: idProps) {
  const { impuestoProductoStore, impuestoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createImpuestoProducto, updateImpuestoProducto, loadImpuestoProducto, loadingInitial, impuestosProductosById, loading } = impuestoProductoStore;
  const { ImpuestosByDropdown, loadImpuestos } = impuestoStore;
  const [impuestoProducto, setImpuestoProducto] = useState(new ImpuestoProductoFormValues());
  const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());
  const firstElement = 0;
  const lastElement = 7;

  useEffect(() => {
    loadImpuestos();
  }, []);

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de impuesto es requerida."),
    nombre: Yup.string().required("El nombre de el impuesto es requerida.")
  });

  useEffect(() => {
    if (id) {
      loadImpuestoProducto(id).then((impuestoProducto) => {
        setImpuestoProducto(new ImpuestoProductoFormValues(impuestoProducto))
        setFechaObservacion(new FechaObservacionValues(impuestoProducto?.fechaObservacion));
      });
    }
    else {
      impuestoProducto.clave =
        impuestosProductosById.map((impuestoProducto) => impuestoProducto.clave).length > 0
          ? Math.max(...impuestosProductosById.map((impuestoProducto) => impuestoProducto.clave)) + 1
          : 1;
    }
  }, [])


  function handleSubmit(impuestoProducto: ImpuestoProducto) {
    if (impuestoProducto.nombre !== null) {
      if (impuestoProducto.fechaObservacion) {
        impuestoProducto.fechaObservacion = fechaObservacion;
      }
      impuestoProducto.id > 0 ? updateImpuestoProducto(impuestoProducto) : createImpuestoProducto(impuestoProducto);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    let x = tabIndex + 2;
    if (tabIndex === 11) tabIndex = 0;
    let next = document.querySelector<HTMLElement>(
      '[tabIndex="' + (tabIndex + 1) + '"]'
    );
    if (next) {
      while ((next as any)?.disabled === true) {
        next = document.querySelector<HTMLElement>('[tabIndex="' + x + '"]');
        x++;
      }
    }
    if (next) {
      next.focus();
    }
  }
  const tabuladorButton = (event: any) => {
    if (event.key !== "Tab") {
      return;
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    enterInput(tabIndex);
    event.preventDefault();
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading impuesto' />

  return (
    <Segment clearing>
      <Formik validationSchema={validationSchema} onSubmit={values => { handleSubmit(values) }}


        enableReinitialize initialValues={impuestoProducto}>

        {({ handleSubmit, isValid, dirty, values, setFieldValue }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Datos del impuesto
              </Label>
              <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
              <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
            </Segment>
            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Impuestos Trasladados
              </Label>
              <Grid>

                <GridColumn mobile={16} tablet={8} computer={5}>
                  <div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Iva Trasladado"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    />

                    <Checkbox toggle label='Incluida en precio' />
                  </div>
                </GridColumn>
                <GridColumn mobile={16} tablet={8} computer={5}>
                  <div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="IEPS Trasladado"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                    <Checkbox toggle label='Incluida en precio' />
                  </div>
                </GridColumn>
                <GridColumn mobile={16} tablet={8} computer={6}>

                  <div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Impuesto local Trasladado"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                    <Checkbox toggle label='Incluida en precio' />
                  </div>
                </GridColumn>
              </Grid>
            </Segment>


            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Impuestos retenidos
              </Label>
<Grid>
<GridColumn mobile={16} tablet={8} computer={3}>
<div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Impuesto Retenido"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    /> 
                  </div>
 </GridColumn> 
 <GridColumn mobile={16} tablet={8} computer={3}>
  
 </GridColumn> 
 <GridColumn mobile={16} tablet={8} computer={3}>
  
 </GridColumn> 
  </Grid>
              <Grid>
                <GridColumn mobile={16} tablet={8} computer={5}>
                  <div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Impuesto Retenido"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                    <Checkbox toggle label='Incluida en precio' />
                  </div>
                </GridColumn>
                <GridColumn mobile={16} tablet={8} computer={5}>
                  <div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Impuesto Retenido"
                      name="impuestoTipoId"
                      enterInput={enterInput}
                      tabIndex={3}
                      options={ImpuestosByDropdown}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                    <Checkbox toggle label='Incluida en precio' />
                  </div>
                </GridColumn>
                </Grid>
            </Segment>

            <Segment clearing>
              <div className="col20">
                <Button
                  tabIndex={4}
                  type="button"
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => {
                          setFieldValue("fechaObservacion", data)
                          impuestoProducto.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                        }}

                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Impuesto
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <BarraFechaObservacion
                fechaObservacion={values.fechaObservacion}
              ></BarraFechaObservacion>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/impuestos"
                loading={loading}
                tabIndex={5}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})