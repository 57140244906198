import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import MySelectInput from "../../../../app/common/form/MySelectInput";

import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";
import { ConceptoFinanza, ConceptoFinanzaFormValues } from "../../../../app/models/Finanza/ConceptoFinanza";

export default observer(function ConceptoFinanzaForm() {
  const firstElement = 0;
  const lastElement = 7;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { conceptoFinanzaStore, conceptoFinanzaTipoStore } = useStore();

  const {
    createConceptoFinanza,
    updateConceptoFinanza,
    loadConceptoFinanza,
    loadingInitial,
    conceptoFinanzaById,
    loading
  } = conceptoFinanzaStore;
  const { conceptosFinanzasTipoByDropdown, getConceptoFinanzasTipos } = conceptoFinanzaTipoStore;
  const { id } = useParams<{ id: string }>();
  const [conceptoFinanza, setConceptoFinanza] = useState(new ConceptoFinanzaFormValues());
  const [conceptoFinanzaTipos, setConceptoFinanzaTipos] = useState([{ key: 0, value: 0, text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  useEffect(() => {
    getConceptoFinanzasTipos().then(cvts => { });
  }, [])


  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave del concepto de venta es requerida.'),
    nombre: Yup.string().required('El nombre del concepto de venta es requerido.'),
    conceptoFinanzaTipoId: Yup.number()
    .min(1, "Favor de seleccionar un tipo Concepto de Venta.")
    .required("El tipo Concepto de Venta es requerido."),
  });

  useEffect(() => {
    debugger
    if (id) {
      loadConceptoFinanza(Number.parseInt(id)).then(conceptoFinanza => {
        setConceptoFinanza(new ConceptoFinanzaFormValues(conceptoFinanza))
        setFechaObservacion(
          new FechaObservacionValues(conceptoFinanza?.fechaObservacion)
        );
      }
      );
    } else {
      conceptoFinanza.clave =
        conceptoFinanzaById.map((conceptoFinanza) => conceptoFinanza.clave).length > 0
          ? Math.max(...conceptoFinanzaById.map((conceptoFinanza) => conceptoFinanza.clave)) + 1
          : 1;
    }
  }, []);



  function handleSubmit(conceptoFinanza: ConceptoFinanza) {
    if (/*cuentaDeVenta.cuentaDeVentaTipoId.toString() !== "0" &&*/ conceptoFinanza.nombre !== null) {
      conceptoFinanza.id > 0 ? updateConceptoFinanza(conceptoFinanza) : createConceptoFinanza(conceptoFinanza);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Concepto Finanza' />

  return (
    <Segment clearing>
      <Formik onSubmit={(values) => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={conceptoFinanza}
      >
        {({ isValid, dirty, handleSubmit }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Concepto de Finanza
              </Label>

              <MyTextInput
                placeholder="Clave"
                label="Clave"
                name="clave"
                disabled={true}
                tabIndex={0}
              />

              <MyTextInput
                autoFocus={true}
                placeholder="Nombre"
                label="Nombre"
                name="nombre"
                tabIndex={1}
                enterInput={enterInput}
                maxLength={30}
              />
              <div className="col60Block">
                <MyTextInput
                  label="Abreviacion"
                  placeholder="Abreviacion"
                  name="abreviacion"
                  tabIndex={2}
                  enterInput={enterInput}
                  maxLength={30}
                />
              </div>
              <div className="col">
                <MySelectInput
                  tabIndex={3}
                  placeholder={"Seleccionar"}
                  label="Tipo"
                  name="cuentaFinanzaTipoId"
                  options={conceptosFinanzasTipoByDropdown}
                  enterInput={enterInput}
                />
              </div>

            </Segment>
            <Segment clearing>
            <div className="col30Block">
              <Button
                type="button"
                class="ui primary button"
                tabIndex={4}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <FechaObservaciones
                      fechaObservacion={fechaObservacion}
                      setFechaObservacion={setFechaObservacion}
                      closeModals={closeModals}
                      tabIndex={lastElement}
                    />,
                    enumHeaderModalsFechasObservaciones.ConceptoFinanza
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="calendar alternate outline" />
                Fechas y Observaciones
              </Button>
              </div>
                <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={conceptoFinanza.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/ConceptosFinanzas"
                loading={loading}
                tabIndex={5}
                id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
