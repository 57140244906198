import { useContext } from 'react';
import { Button, Checkbox, Container, Icon, Menu, MenuMenu } from 'semantic-ui-react';
import { UserContext } from '../context/UserContext';
import { useAuth } from '../router/AuthContext';
import { router } from '../../app/router/Routers';


interface NavBarProps {
    menuVisible: boolean;
    setMenuVisible: (visible: boolean) => void;
}

export default function NavBar({ menuVisible, setMenuVisible }: NavBarProps) {
    const { setUser, empresaLog } = useContext(UserContext);
    const { user, logout } = useAuth();

    if(user === null)
    {
        logout();
    }
    
    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Menu position='left'>
                    <Menu.Item onClick={() => setMenuVisible(!menuVisible)}>
                        <label>Menu.</label>
                    <Checkbox toggle />
                        
                        {/* <Button color='facebook' icon labelPosition='left'>
                            <Icon name={menuVisible ? 'arrow left' : 'bars'} />
                            {menuVisible ? 'Ocultar Menú' : 'Menú'}
                        </Button> */}
                    </Menu.Item>
                    <Menu.Item  header>
                        <img src="/assets/images/logo.png" alt="logo" style={{ marginRight: '10px' }} />
                        Administra YA!
                    </Menu.Item>
                </Menu.Menu>
                
                <Menu.Menu position='right'>
                    <Menu.Item>Empresa Conectado: {empresaLog?.name}</Menu.Item>
                    <Menu.Item>Usuario Conectado: { user?.userName}</Menu.Item>
                    <Menu.Item>
                        <Button
                            color='facebook'
                            onClick={() => { logout();  router.navigate('/'); }}
                            content='Logout'
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    );
}