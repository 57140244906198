import { makeAutoObservable } from "mobx";
import agent from "../../api/agent";
import { VerificadorGestionProveedor } from "../../models/gestionProveedor/VerificadorGestionProveedor";

export default class VerificadorGestionProveedorStore {
    reporteGestionProveedorRegistry = new Map<number, VerificadorGestionProveedor>();
    selectedVerificadorGestionProveedor: VerificadorGestionProveedor | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    imprimirVerificadorGestionProveedores = async (filtros: VerificadorGestionProveedor) => {
        try {
            debugger
            const verificadorGestionProveedor = await agent.VerificadorGestionProveedores.imprimirVerificadorGestionProveedores(filtros);
            if (verificadorGestionProveedor) {
                return verificadorGestionProveedor;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

}