import Login from '../../features/account/Login';
import Register from '../../features/account/Resgister';
import HomePage from '../../features/home/HomePage';
import App from '../layout/App'
import { Navigate, createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Login />
    },
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/home', element: <HomePage /> },
            { path: '/register', element: <Register /> },
            { path: '*', element: <Navigate replace to='/not-found' /> },
        ]
    }
])