import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PoliticaDescuento } from "../../models/inventario/PoliticaDescuento";
import { setDate } from "../../common/util/util";
import { history } from "../../..";
import { toast } from "react-toastify";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class PoliticaDescuentoStore {
  politicaDescuentoRegistry = new Map<number, PoliticaDescuento>();
  selectedPoliticaDescuento: PoliticaDescuento | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get politicaDescuentosById() {
    return Array.from(this.politicaDescuentoRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }
  get politicaDescuentoByDropdown() {
    return entityByDropdown<PoliticaDescuento>(
      Array.from(this.politicaDescuentoRegistry.values())
    );
  }
  loadPoliticaDescuentos = async () => {
    this.setLoadingInitial(true);
    try {
      const politicaDescuentos = await agent.PoliticaDescuentos.list();
      politicaDescuentos.forEach((politicaDescuento) => {
        //  setDate<PoliticaDescuento>(politicaDescuento);
        this.setPoliticaDescuento(politicaDescuento);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadPoliticaDescuento = async (id: number) => {
    let politicaDescuento = this.getPoliticaDescuento(id);
    if (politicaDescuento) {
      this.selectedPoliticaDescuento = politicaDescuento;
      return politicaDescuento;
    } else {
      this.loadingInitial = true;
      try {
        politicaDescuento = await agent.PoliticaDescuentos.details(id);
        //   setDate<PoliticaDescuento>(politicaDescuento);
        this.setPoliticaDescuento(politicaDescuento);
        this.selectedPoliticaDescuento = politicaDescuento;
        this.setLoadingInitial(false);
        return politicaDescuento;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setPoliticaDescuento = (politicaDescuento: PoliticaDescuento) => {
    this.politicaDescuentoRegistry.set(politicaDescuento.id, politicaDescuento);
  };

  private getPoliticaDescuento = (id: number) => {
    return this.politicaDescuentoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createPoliticaDescuento = async (politicaDescuento: PoliticaDescuento) => {
    this.loading = true;
    try {
      await agent.PoliticaDescuentos.create(politicaDescuento);
      runInAction(() => {
        this.selectedPoliticaDescuento = politicaDescuento;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("politicaDescuento creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updatePoliticaDescuento = async (politicaDescuento: PoliticaDescuento) => {
    this.loading = true;
    try {
      await agent.PoliticaDescuentos.update(politicaDescuento);
      runInAction(() => {
        this.selectedPoliticaDescuento = politicaDescuento;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("politicaDescuento modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePoliticaDescuento = async (id: number) => {
    this.loading = true;
    try {
      await agent.PoliticaDescuentos.delete(id);
      runInAction(() => {
        this.politicaDescuentoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("politicaDescuento eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar politicaDescuento");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
