export interface EnviarCorreo {
  id: number;
  clave: number;
  nombre: string;
  empresaId: number;
  sucursalId: number;
  para: string;
  asunto: string;
  adjuntar: string;
  mensaje: string;
}
export class EnviarCorreoFormValues implements EnviarCorreo {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  empresaId = 0;
  sucursalId = 0;
  para: string = "";
  asunto: string = "";
  adjuntar: string = "";
  mensaje: string = "";
  constructor(init?: EnviarCorreo) {
    Object.assign(this, init);
  }
}