import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Empresa } from "../../models/configuracion/Empresa";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { Entity } from "../../models/Entity";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";

export default class EmpresaStore {
  empresaRegistry = new Map<number, Empresa>();
  empresaEntity = new Map<number, Entity>();
  selectedEmpresa: Empresa | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get empresaById() {
    return Array.from(this.empresaRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }
  get empresasByDate() {
    return Array.from(this.empresaRegistry.values()).sort((a) => a.id);
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }

  get EmpresasByDropdown() {
    return entityByDropdown<Empresa>(Array.from(this.empresaEntity.values()));
  }
  get EmpresasByDropdownByClave() {
    return entityByDropdown<Empresa>(Array.from(this.empresaEntity.values()), true);
  }

  cargarEmpresaPorId = async (filtros: PaginationSpecParams) => {
    try {
        const empresa = await agent.Empresas.obtenerEmpresaPorId(filtros);
        if (empresa) {
            return empresa;
        }
    } catch (error) {
        console.log(error);
    }
};

  fetchsEmpresas = async () => {
    try {
      const response = await agent.Empresas.list();
      return response;
    } catch (error) {
      console.error("Error al buscar Empresa", error);
    }
  };
  loadEmpresas = async () => {
    this.setLoadingInitial(true);
    try {
      const empresas = await agent.Empresas.list();
      empresas.forEach((empresa) => {
        empresa.fechaInicioVigenciaCFD = new Date(empresa.fechaInicioVigenciaCFD);
        empresa.fechaFinVigenciaCFD = new Date(empresa.fechaFinVigenciaCFD);
        setDate<Empresa>(empresa);
        this.setEmpresa(empresa);
      });

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  loadEntityEmpresas = async () => {
    try {
      const empresas = await agent.Empresas.listEntity();
      empresas.forEach((empresa) => {
        this.setEmpresaEntity(empresa);
      });
      return empresas;
    } catch (error) {
      console.log(error);
    }
  };
  getEmpresas = async () => {
    this.setLoadingInitial(true);
    const empresas = await agent.Empresas.list();
    try {
      empresas.forEach((empresa) => {
        this.setEmpresa(empresa);
      });
      this.setLoadingInitial(false);
      return empresas;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return empresas;
    }
  };

  loadEmpresa = async (id: number) => {
    let empresa = this.getEmpresa(id);
    // console.log("trae empres",empresa)
    if (empresa) {
      this.selectedEmpresa = empresa;
      return empresa;
    } else {
      this.loadingInitial = true;
      try {
        empresa = await agent.Empresas.details(id);
        empresa.fechaInicioVigenciaCFD = new Date(
          empresa.fechaInicioVigenciaCFD
        );
        empresa.fechaFinVigenciaCFD = new Date(empresa.fechaFinVigenciaCFD);
        this.setEmpresa(empresa);
        this.selectedEmpresa = empresa;
        this.setLoadingInitial(false);
        return empresa;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setEmpresa = (empresa: Empresa) => {
    this.empresaRegistry.set(empresa.id, empresa);
  };

  private setEmpresaEntity = (empresa: Entity) => {
    this.empresaEntity.set(empresa.id, empresa);
  };
  private getEmpresa = (id: number) => {
    return this.empresaRegistry.get(id);
  };
  private getFormEmpresa = (empresa: Empresa) => {
    let formData = new FormData();
    formData.append("id", empresa.id?.toString()!);
    formData.append("clave", empresa.clave.toString()!);
    formData.append("nombre", empresa.nombre!);
    formData.append("demo", empresa.demo.toString()!);
    formData.append("rfc", empresa.rfc!);
    formData.append("curp", empresa.curp!);
    formData.append("codigoContable", empresa.codigoContable!);
    formData.append("ContrasenaClavePrivada", empresa.contrasenaClavePrivada!);
    formData.append("contrasenaFirmaPrivada", empresa.contrasenaFirmaPrivada!);

    //formData.append("fechaInicioVigenciaCFD",empresa.fechaInicioVigenciaCFD.toString());
    formData.append(
      "cFechaInicioVigenciaCFD",
      empresa.fechaInicioVigenciaCFD
        ? empresa.fechaInicioVigenciaCFD.toDateString()
        : ""
    );
    formData.append(
      "cFechaFinVigenciaCFD",
      empresa.fechaFinVigenciaCFD
        ? empresa.fechaFinVigenciaCFD.toDateString()
        : ""
    );
    //formData.append("fechaFinVigenciaCFD",empresa.fechaFinVigenciaCFD.toString());
    // fechaInicioVigenciaCFD: undefined;
    // fechaFinVigenciaCFD: undefined;

    formData.append("direccionId", empresa.direccionId.toString());
    formData.append("direccion.calle", empresa.direccion.calle);
    formData.append(
      "direccion.numero",
      empresa.direccion.numero === null ? "" : empresa.direccion.numero
    );
    formData.append(
      "direccion.numeroInterior",
      empresa.direccion.numeroInterior === null
        ? ""
        : empresa.direccion.numeroInterior.toString()
    );
    formData.append(
      "direccion.codigoPostal",
      empresa.direccion.codigoPostal === null
        ? ""
        : empresa.direccion.codigoPostal.toString()
    );

    formData.append(
      "direccion.colonia",
      empresa.direccion.colonia === null ? "" : empresa.direccion.colonia
    );
    formData.append(
      "direccion.ciudad",
      empresa.direccion === null ? "" : empresa.direccion.ciudad
    );
    formData.append(
      "direccion.municipio",
      empresa.direccion === null ? "" : empresa.direccion.municipio
    );
    formData.append("direccion.paisId", empresa.direccion.paisId.toString());
    formData.append(
      "direccion.estadoId",
      empresa.direccion.estadoId.toString()
    );
    formData.append(
      "direccion.telefono",
      empresa.direccion === null ? "" : empresa.direccion.telefono.toString()
    );
    formData.append("direccion.referencia", empresa.direccion.referencia);

    formData.append("listaDePrecioId", empresa.listaDePrecioId.toString());
    formData.append("pacId", empresa.pacId.toString());
    formData.append("usuarioPac", empresa.usuarioPac!);
    formData.append("usuarioPacPassword", empresa.usuarioPacPassword!);
    formData.append("ciec", empresa.ciec!);
    formData.append("tokenInfinito", empresa.tokenInfinito!);
    formData.append("regimenFiscalId", empresa.regimenFiscalId.toString());
    formData.append(
      "fileCerId",
      empresa.fileCerId === null ? "" : empresa.fileCerId.toString()
    );
    formData.append(
      "fileKeyId",
      empresa.fileKeyId === null ? "" : empresa.fileKeyId.toString()
    );
    //formData.append("Pac",empresa.pacId.toString());
    //formData.append('fechaFinVigenciaCFD', empresa.fechaFinVigenciaCFD?empresa.fechaFinVigenciaCFD.getDate.toString():"");
    //formData.append('fechaFinVigenciaCFD', empresa.fechaFinVigenciaCFD!);
    formData.append("cer", empresa.cer!);
    formData.append("key", empresa.key!);

    formData.append(
      "fechaObservacion.observaciones",
      empresa.fechaObservacion.observaciones
    );
    formData.append(
      "fechaObservacion.baja",
      empresa.fechaObservacion.baja!
        ? empresa.fechaObservacion.baja.toDateString()
        : ""
    );

    return formData;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createEmpresa = async (empresa: Empresa) => {
    this.loading = true;
    try {
      await agent.Empresas.create(this.getFormEmpresa(empresa));
      runInAction(() => {
        this.selectedEmpresa = empresa;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Empresa creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateEmpresa = async (empresa: Empresa) => {
    this.loading = true;
    try {
      await agent.Empresas.update(this.getFormEmpresa(empresa), empresa.id);
      runInAction(() => {
        this.selectedEmpresa = empresa;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Empresa modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteEmpresa = async (id: number) => {
    this.loading = true;
    try {
      await agent.Empresas.delete(id);
      runInAction(() => {
        this.empresaRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Empresa eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar empresa");
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
