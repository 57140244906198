import { useState, useEffect } from 'react'
import { useStore } from '../../../../app/stores/store';
import { Button,  Segment, Header,  Icon, Label, Grid, GridColumn } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { urlApi } from "../../../../app/api/agent";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import {  enterInputs} from "../../../../app/common/funciones/tabuladorEnter";
import { ReporteVentas, ReporteVentasFormValues } from "../../../../app/models/venta/ReporteVentas";

export default observer(function ComprobantesAExcelForm() {

  const firstElement = 0;
  const lastElement = 19;
  const {    reporteVentasStore } = useStore();
  const { ExportarComprobantesAExcel } = reporteVentasStore
  
  const [reporteVentas, setReporteVentas] = useState(new ReporteVentasFormValues());
  const TipoReporteByDropdown = [   
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];


  const OrdenReporteByDropdown = [
   
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().required("El folio es requerido."),
    fechaFin: Yup.date().required("El folio es requerido."),
    tipoReporte: Yup.number()
      .min(1, "Favor de seleccionar un tipo Reporte.")
      .required("El tipo Reporte es requerido."),
    ordenado: Yup.number()
      .min(1, "Favor de seleccionar un Ordenado.")
      .required("El Ordenado es requerido."),
  });

  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setReporteVentas((state) => ({
      ...state,
      nombre: "COMPROBANTES A EXCEL", ordenado: "1",
      tipoReporte: "1",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(reporteVentasDetalles: ReporteVentas) {
    ExportarComprobantesAExcel(reporteVentasDetalles).then(result=>{
      
        
        
      
      if (result !== undefined) {
        let features ='menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
      window.open(urlApi + result, '_blank', features);
    
        
      }
    })
   
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  //if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteVentas}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Comprobantes a Excel' color='blue' />


            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  tabIndex={0}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                />
              </div>

              <div></div>
              
              <div className='col35'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={1}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}

                />
              </div>
              
              <div className='col35'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={2}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>
              
              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div></div>
              
              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>

              
              <Grid>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={5}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={6}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Tipo Venta'
                  label="Tipo Venta"
                  name='tipoVenta'
                  maxLength={50}
                  tabIndex={7}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Serie'
                  label="Serie"
                  name='serie'
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Folio'
                  label="Folio"
                  name='folio'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput}/>
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Cliente'
                  label="Cliente"
                  name='cliente'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Cuenta De Venta'
                  label="Cuenta De Venta"
                  name='cuentaVenta'
                  maxLength={50}
                  tabIndex={11}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Vendedor'
                  label="Vendedor"
                  name='vendedor'
                  maxLength={50}
                  tabIndex={12}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Trabajador'
                  label="Trabajador"
                  name='trabajador'
                  maxLength={50}
                  tabIndex={13}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Producto'
                  label="Producto"
                  name='producto'
                  maxLength={50}
                  tabIndex={14}
                  enterInput={enterInput} />
              </GridColumn>
              </Grid>
            </Segment>

            <Segment>
            <Button
          tabIndex={15}
          type="submit"
          color="blue"
        >
          <Icon name="print" />
          Imprimir
        </Button>
            
            </Segment>
          </Form>
        )}

      </Formik>

    </Segment>




  )
})