import { observer } from 'mobx-react-lite'
import { useEffect } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import SucursalList from './SucursalList'
import { useStore } from '../../../../app/stores/store';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import SucursalForm from '../form/SucursalForm';
import ReporteSucursalForm from '../form/ReporteSucursalForm';

export default observer(function SucursalDashboard() {
  const { tabsStore } = useStore();
  const { sucursalStore } = useStore();
  useEffect(() => {
    sucursalStore.loadSucursales();
  }, [sucursalStore]);

  if (sucursalStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h1' icon='warehouse' content='Sucursales' color='blue'  ></Header>
        </Grid.Column>
        <Grid.Column width={8}  >
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Sucursal', <SucursalForm id={0} />)}
              content='Crear Sucursal' color='blue' />

            <Button onClick={() => tabsStore.addTab('Imprimir Sucursal', <ReporteSucursalForm />)}
              content="Imprimir" color="blue" />

            <Button onClick={() => sucursalStore.loadSucursales()}
              content='Actualizar' color='blue' />
          </Header>

        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <SucursalList />
      </Grid.Column>
    </Grid>
  )
})