import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import agent from "../api/agent";
import { Usuario } from "../models/configuracion/Usuario";
import { TipoDropDownValues } from "../models/configuracion/TipoDropdown";
import { entityByDropdown } from "../common/funciones/entityByDropdown";
import { store } from "./store";
import { PaginationFormValues, PaginationSpecParams } from "../models/Especificaciones/PaginationSpecParams";


export default class UsuarioStore {
  usuarioByEmpresaRegistry = new Map<number, Usuario>();
  usuarioRegistry = new Map<number, Usuario>();
  selectedUsuario: Usuario | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  usuarioParams: PaginationSpecParams = new PaginationFormValues();
  constructor() {
    makeAutoObservable(this);
  }

  fetchsUsuarios = async () => {
    try {
      const response = await agent.Usuarios.list();
      return response;
    } catch (error) {
      console.error("Error al buscar ", error);
    }
  };

  fetchsUsuariosVendedor = async () => {
    try {
      debugger
      let filtro = new PaginationFormValues();
      filtro.esVendedor = true;
      const response = await agent.Usuarios.getUsuarioVendedor(filtro);
      return response;
    } catch (error) {
      console.error("Error al buscar ", error);
    }
  };
  fetchsUsuariosTrabajador = async () => {
    try {
      let filtro = new PaginationFormValues();
      filtro.esTrabajador = true;
      const response = await agent.Usuarios.getUsuarioTrabajador(filtro);
      return response;
    } catch (error) {
      console.error("Error al buscar ", error);
    }
  };

  fetchsUsuariosComprador = async () => {
    try {
      let filtro = new PaginationFormValues();
      filtro.esComprador = true;
      const response = await agent.Usuarios.getUsuarioComprador(filtro);
      return response;
    } catch (error) {
      console.error("Error al buscar ", error);
    }
  };

  obtenerSiguienteClave = async (filtros: PaginationSpecParams) => {
    try {
      const clave = await agent.Usuarios.obtenerClave(filtros);
      return clave;
    } catch (error) {
      console.log(error);
    }
  };

  get usuariosByDropdown() {
    let usuariosArreg = [new TipoDropDownValues()];
    let usuariosOpt = new TipoDropDownValues();
    const cvts = Array.from(this.usuarioRegistry.values()).sort(
      (a, b) => {
        if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0
      }
    );
    cvts.forEach((element) => {
      usuariosOpt.key = element.id;
      usuariosOpt.text = element.nombre;
      usuariosOpt.value = element.id;
      usuariosOpt.content = element.nombre;
      usuariosArreg.push(usuariosOpt);
      usuariosOpt = new TipoDropDownValues();

    });
    return usuariosArreg;
  }
  get usuariosByEmpresaByDropdown() {
    return entityByDropdown<Usuario>(
      Array.from(this.usuarioByEmpresaRegistry.values())
    );
  }
  loadUsuarioByEmpresaSucursal = async (empresaSucursal: PaginationSpecParams) => {
    const { proveedorStore } = store;
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {

        this.usuarioByEmpresaRegistry.clear();
        const usuarios = await agent.Usuarios.listByEmpresaSucursal(empresaSucursal);
        usuarios.forEach((usuario) => {
          this.setUsuariobyEmpresaSucursal(usuario);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.usuarioByEmpresaRegistry.clear();
    }
  };
  getUsuarios = async () => {
    this.setLoadingInitial(true);
    const usuarios = await agent.Usuarios.list();
    try {
      usuarios.forEach(usuario => {
        this.setUsuario(usuario);
      })
      this.setLoadingInitial(false);
      return usuarios;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return usuarios;
    }
  }

  get usuariosById() {
    return Array.from(this.usuarioRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }
  loadUsuarioByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
    try {
      const usuarios = await agent.Usuarios.getUsuarioByParams(filtros);
      return entityByDropdown<Usuario>(Array.from(usuarios));
    } catch (error) {
      console.error("error", error);
    }
  }
  loadUsuariosParams = async (filtoCliente: PaginationSpecParams) => {
    try {
      let usuarios =
        await agent.Usuarios.getUsuarioByParams(
          filtoCliente
        );
      usuarios.forEach((usuario) => {
        this.setUsuario(usuario);
      });
      return usuarios;
    } catch (error) {
      console.error("Error al cargar usuarios:", error);
      return [];
    }
  }
  loadUsuarios = async () => {
    this.setLoadingInitial(true);
    try {
      const usuarios = await agent.Usuarios.list();
      usuarios.forEach((usuario) => {
        this.setUsuario(usuario);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadUsuario = async (id: number) => {
    let usuario = this.getUsuario(id);
    if (usuario) {
      this.selectedUsuario = usuario;
      return usuario;
    } else {
      this.loadingInitial = true;
      try {
        usuario = await agent.Usuarios.details(id);
        this.setUsuario(usuario);
        this.selectedUsuario = usuario;
        this.setLoadingInitial(false);
        return usuario;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setUsuario = (usuario: Usuario) => {
    this.usuarioRegistry.set(usuario.id, usuario);
  };
  private setUsuariobyEmpresaSucursal = (usuario: Usuario) => {
    this.usuarioByEmpresaRegistry.set(usuario.id, usuario);
  };

  public setUsuarioRegClear = () => {
    this.usuarioRegistry.clear();
  }
  private getUsuario = (id: number) => {
    return this.usuarioRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createUsuario = async (usuario: Usuario) => {
    this.loading = true;
    try {
      await agent.Usuarios.create(usuario);
      runInAction(() => {
        this.loadUsuarios();
        this.selectedUsuario = usuario;
        this.editMode = false;
        this.loading = false;
      });

      toast.success("Usuario creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateUsuario = async (usuario: Usuario) => {
    this.loading = true;
    try {
      await agent.Usuarios.update(usuario);
      runInAction(() => {
        this.loadUsuarios();
        this.selectedUsuario = usuario;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Usuario modificado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteUsuario = async (id: number) => {
    this.loading = true;
    try {
      await agent.Usuarios.delete(id);
      runInAction(() => {
        this.usuarioRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Usuario eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar usuario");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}