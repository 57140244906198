import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Proveedor } from "../../models/gestionProveedor/Proveedor";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import {
  PaginationFormValues,
  PaginationSpecParams,
} from "../../models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";

export default class ProveedorStore {
  proveedorRegistry = new Map<number, Proveedor>();
  proveedorByEmpresaRegistry = new Map<number, Proveedor>();
  selectedProveedor: Proveedor | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  empresaSucursal: PaginationSpecParams = new PaginationFormValues();
  constructor() {
    makeAutoObservable(this);
  }
  get proveedorById() {
    return Array.from(this.proveedorRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  get proveedoresByDate() {
    return Array.from(this.proveedorRegistry.values()).sort((a) => a.id);
  }
  get ProveedoresByDropdown() {
    return entityByDropdown<Proveedor>(Array.from(this.proveedorRegistry.values()));
  }

  fetchsProveedor = async () => {
    try {
      const response = await agent.Proveedores.list();
      return response;
    } catch (error) {
      console.error("Error al buscar Proveedor", error);
    }
  };

  loadProveedores = async () => {
    this.setLoadingInitial(true);
    try {
      const proveedores = await agent.Proveedores.list();
      proveedores.forEach((proveedor) => {
        setDate<Proveedor>(proveedor);
        this.setProveedor(proveedor);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  setProveedorByEmpSucRegClear = () => {
    this.proveedorByEmpresaRegistry.clear();
  }
  loadProveedorByIdEmpresa = async (empresaSucursal: PaginationSpecParams) => {
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        this.proveedorByEmpresaRegistry.clear();
        const proveedores = await agent.Proveedores.listByEmpresa(
          empresaSucursal
        );
        proveedores.forEach((proveedor) => {
          this.setProveedorbyEmpresa(proveedor);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.proveedorByEmpresaRegistry.clear();
    }
  };
  get proveedoresByDropdown() {
    return entityByDropdown<Proveedor>(
      Array.from(this.proveedorRegistry.values())
    );
  }
  get proveedoresByEmpresaByDropdown() {
    return entityByDropdown<Proveedor>(
      Array.from(this.proveedorByEmpresaRegistry.values())
    );
  }
  loadProveedor = async (id: number) => {
    let proveedor = this.getProveedor(id);
    if (proveedor) {
      this.selectedProveedor = proveedor;
      return proveedor;
    } else {
      this.loadingInitial = true;
      try {
        proveedor = await agent.Proveedores.details(id);
        setDate<Proveedor>(proveedor);
        this.setProveedor(proveedor);
        this.selectedProveedor = proveedor;
        this.setLoadingInitial(false);
        return proveedor;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setProveedor = (proveedor: Proveedor) => {
    this.proveedorRegistry.set(proveedor.id, proveedor);
  };
  private setProveedorbyEmpresa = (proveedor: Proveedor) => {
    this.proveedorByEmpresaRegistry.set(proveedor.id, proveedor);
  };

  private getProveedor = (id: number) => {
    return this.proveedorRegistry.get(id);
  };

  getProveedores = async () => {
    this.setLoadingInitial(true);
    const proveedores = await agent.Proveedores.list();
    try {
      proveedores.forEach(prov => {
        setDate<Proveedor>(prov);
        this.setProveedor(prov);
      })
      this.setLoadingInitial(false);
      return proveedores;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return proveedores;
    }
  }
  cleanProvedorByEmpresa = async () => {
    this.proveedorByEmpresaRegistry.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createProveedor = async (proveedor: Proveedor) => {
    this.loading = true;
    try {
      await agent.Proveedores.create(proveedor);
      runInAction(() => {
        this.selectedProveedor = proveedor;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Proveedor creado con éxito");
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  updateProveedor = async (proveedor: Proveedor) => {
    this.loading = true;
    try {
      await agent.Proveedores.create(proveedor);
      runInAction(() => {
        this.selectedProveedor = proveedor;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Proveedor Actualizado con éxito");
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };


  deleteProveedor = async (id: number) => {
    this.loading = true;
    try {
      await agent.Proveedores.delete(id);
      runInAction(() => {
        this.proveedorRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  resetEmpresaSucursal() {
    this.empresaSucursal = new PaginationFormValues();
  }
  obtenerSiguienteClave = async (filtros: PaginationSpecParams) => {
    try {
      const clave = await agent.Proveedores.obtenerClave(filtros);
      return clave;
    } catch (error) {
      console.log(error);
    }
  };
  cargarProveedor = async (filtros: PaginationSpecParams) => {
    try {
      const proveedor = await agent.Proveedores.obtenerProveedorPorClave(filtros);
      if (proveedor) {
        setDate<Proveedor>(proveedor);
        return proveedor;
      }
    } catch (error) {
      console.log(error);
    }
  };
}