import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { CapturaPagos, MovimientoEliminado, PagoAplicado } from "../../models/gestionCliente/CapturaPagos";
import { Entity } from "../../models/Entity";
import agent from "../../api/agent";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
export default class CapturaPagosStore {
    pagoAplicadoRegistry = new Map<number, PagoAplicado>();
    movimientoEliminadoRegistry = new Map<number, MovimientoEliminado>();
    capturaPagosRegistry = new Map<number, CapturaPagos>();
    capturaPagosEntity = new Map<number, Entity>();
    selectedCapturaPagos: CapturaPagos[] | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    capturaPagosParams: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this);
    }

    get capturaPagossById() {
        return Array.from(this.capturaPagosRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    createCapturaPagos = async (capturaPagos: CapturaPagos[]) => {
        this.loading = true;
        try {
            await agent.CapturasPagos.create(capturaPagos!);
            runInAction(() => {
                this.selectedCapturaPagos = capturaPagos;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("CapturaPagos creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };


    deleteCapturaPagos = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.CapturasPagos.delete(id);
                runInAction(() => {
                    this.capturaPagosRegistry.delete(id);
                    this.loading = false;
                });
                toast.success("Captura Pagos eliminada con éxito");
            } catch (error) {
                toast.error("Problema al eliminar captura Pagos");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    };
    deletePagoAplicado = async (id: number) => {
        this.loading = true;
        try {
            await agent.CapturasPagos.deletePagoAplicado(id);
            runInAction(() => {
                this.pagoAplicadoRegistry.delete(id);
                this.loading = false;
            });
            toast.success("Pago Aplicado eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar Pago Aplicado");
            runInAction(() => {
                this.loading = false;
            });
        }
    };
    loadCapturaPagos = async (filtoCliente: PaginationSpecParams) => {
        try {
            let capturasPagos =
                await agent.CapturasPagos.getCapturaPagosByClave(
                    filtoCliente
                );
            capturasPagos.pagoAplicado.forEach((pagoAplicado) => {
                this.setPagoAplicado(pagoAplicado);
            });
            capturasPagos.movimientoEliminado.forEach((movimientoEliminado) => {
                this.setMovimientoEliminado(movimientoEliminado);
            });
            return capturasPagos;
        } catch (error) {
            console.log(error);
        }
    }
    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
            try {
                const capturaPagoss = await agent.CapturasPagos.obtenerFolio(filtros);
                return capturaPagoss;

            } catch (error) {
                console.log(error);
            }
        } else {
        }
    };
    private setPagoAplicado = (pagoAplicado: PagoAplicado) => {
        this.pagoAplicadoRegistry.set(pagoAplicado.id, pagoAplicado);
    };
    private setMovimientoEliminado = (movimientoEliminado: MovimientoEliminado) => {
        this.movimientoEliminadoRegistry.set(movimientoEliminado.id, movimientoEliminado);
    };
    get pagosAplicadosById() {
        return Array.from(this.pagoAplicadoRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }
    get movimientoEliminadoById() {
        return Array.from(this.movimientoEliminadoRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }
    private getPagoAplicado = (id: number) => {
        return this.pagoAplicadoRegistry.get(id);
    };
    private getMovimientoEliminado = (id: number) => {
        return this.movimientoEliminadoRegistry.get(id);
    };
}
