import { makeAutoObservable } from 'mobx';
import { CartItem } from '../../models/types';
import { Producto } from '../../models/inventario/Producto';

interface PaymentInfo {
    method: 'cash' | 'card' | 'credit';
    cardNumber?: string;
    expirationDate?: string;
    cvv?: string;
    cashAmount?: number;
    creditTerm?: number;
}

export class CartStore {
    items: CartItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    addToCart = (product: Producto) => {
        const existingItem = this.items.find((item) => item.product.id === product.id);
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            this.items.push({ product, quantity: 1 });
        }
    };

    removeFromCart = (productId: number) => {
        const index = this.items.findIndex((item) => item.product.id === productId);
        if (index !== -1) {
            if (this.items[index].quantity > 1) {
                this.items[index].quantity -= 1;
            } else {
                this.items.splice(index, 1);
            }
        }
    };

    get total() {
        return this.items?.reduce((sum, item) => sum + (item.product.controlListaDePrecio?.length > 0 ? item.product.controlListaDePrecio[0]?.precio : 0) * item.quantity, 0);
    }

    checkout = (paymentInfo: PaymentInfo) => {
        console.log('Procesando pago:', paymentInfo);
        console.log('Total:', this.total);

        switch (paymentInfo.method) {
            case 'cash':
                if (paymentInfo.cashAmount && paymentInfo.cashAmount >= this.total) {
                    console.log('Pago en efectivo aceptado');
                    console.log('Cambio:', paymentInfo.cashAmount - this.total);
                } else {
                    console.log('Monto en efectivo insuficiente');
                    return;
                }
                break;
            case 'card':
                console.log('Pago con tarjeta procesado');
                break;
            case 'credit':
                console.log('Pago a crédito procesado');
                console.log('Plazo de crédito:', paymentInfo.creditTerm, 'meses');
                break;
        }

        // Limpia el carrito después de procesar el pago
        this.items = [];
    };
}