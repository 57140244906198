import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ImpuestoTipo } from "../../models/configuracion/ImpuestoTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class ImpuestoTipoStore {
    impuestoTipoRegistry = new Map<number, ImpuestoTipo>();
    selectedImpuestoTipo: ImpuestoTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadImpuestoTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const impuestoTipos = await agent.ImpuestoTipos.list();
            impuestoTipos.forEach(impuestoTipo => {
                this.setImpuestoTipo(impuestoTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getImpuestoTipos = async () => {
        this.setLoadingInitial(true);
        const impuestoTipos = await agent.ImpuestoTipos.list();
        try {
            impuestoTipos.forEach(impuestoTipo => {
                this.setImpuestoTipo(impuestoTipo);
            })
            this.setLoadingInitial(false);
            return impuestoTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return impuestoTipos;
        }
    }

    loadImpuestoTipo = async (id: number) => {
        let impuestoTipo = this.getImpuestoTipo(id);
        if (impuestoTipo) {
            this.selectedImpuestoTipo = impuestoTipo;
            return impuestoTipo;

        } else {
            this.loadingInitial = true;
            try {
                impuestoTipo = await agent.ImpuestoTipos.details(id);
                this.setImpuestoTipo(impuestoTipo);
                this.selectedImpuestoTipo = impuestoTipo;
                this.setLoadingInitial(false);
                return impuestoTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setImpuestoTipo = (impuestoTipo: ImpuestoTipo) => {
        this.impuestoTipoRegistry.set(impuestoTipo.id, impuestoTipo);
    }
    private getImpuestoTipo = (id: number) => {
        return this.impuestoTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createImpuestoTipo = async (impuestoTipo: ImpuestoTipo) => {
        this.loading = true;
        try {
            await agent.ImpuestoTipos.create(impuestoTipo);
            runInAction(() => {
                this.impuestoTipoRegistry.set(impuestoTipo.id, impuestoTipo);
                this.selectedImpuestoTipo = impuestoTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateImpuestoTipo = async (impuestoTipo: ImpuestoTipo) => {
        this.loading = true;
        try {
            await agent.ImpuestoTipos.update(impuestoTipo);
            runInAction(() => {
                this.impuestoTipoRegistry.set(impuestoTipo.id, impuestoTipo);
                this.selectedImpuestoTipo = impuestoTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteImpuestoTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ImpuestoTipos.delete(id);
            runInAction(() => {
                this.impuestoTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get impuestosTipoByDropdown() {
        let impuestoTipoArreg = [new TipoDropDownValues()];
        let tipoImpuestosOpt = new TipoDropDownValues();
        const imptipos = Array.from(this.impuestoTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        imptipos.forEach((element) => {
            tipoImpuestosOpt.key = element.id;
            tipoImpuestosOpt.text = element.nombre;
            tipoImpuestosOpt.value = element.id;
            tipoImpuestosOpt.content = element.nombre;
            impuestoTipoArreg.push(tipoImpuestosOpt);
            tipoImpuestosOpt = new TipoDropDownValues();

        });
        return impuestoTipoArreg;
    }
}
