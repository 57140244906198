import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeCliente } from "../../models/gestionCliente/CuentaDeCliente";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { CuentaFinanza } from "../../models/Finanza/CuentaFinanza";

export default class CuentaFinanzaStore {
    cuentaFinanzaRegistry = new Map<number, CuentaFinanza>();
    selectedCuentaFinanza: CuentaFinanza | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentaFinanzaByDropdown() {
        return entityByDropdown<CuentaDeCliente>(Array.from(this.cuentaFinanzaRegistry.values()));
    }
    get cuentasFinanzasByDate() {
        return Array.from(this.cuentaFinanzaRegistry.values()).sort((a) => a.id);
    }

    get cuentaFinanzaById() {
        return Array.from(this.cuentaFinanzaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get cuentasClientesByDropdown() {
        let CuentaClienteArreg = [new TipoDropDownValues()];
        let cuentaClienteOpt = new TipoDropDownValues();
        const cvts = Array.from(this.cuentaFinanzaRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            cuentaClienteOpt.key = element.id;
            cuentaClienteOpt.text = element.nombre;
            cuentaClienteOpt.value = element.id;
            cuentaClienteOpt.content = element.nombre;
            CuentaClienteArreg.push(cuentaClienteOpt);
            cuentaClienteOpt = new TipoDropDownValues();

        });
        return CuentaClienteArreg;
    }

    getCuentasFinanzas = async () => {
        this.setLoadingInitial(true);
        const cuentasFinanzas = await agent.CuentasFinanzas.list();
        try {
            cuentasFinanzas.forEach(cuentaFinanza => {
                this.setCuentaFinanza(cuentaFinanza);
            })
            this.setLoadingInitial(false);
            return cuentasFinanzas;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasFinanzas;
        }
    }

    loadCuentasFinanzas = async () => {
        this.setLoadingInitial(true);
        try {
            debugger
            const cuentasFinanzas = await agent.CuentasFinanzas.list();
            cuentasFinanzas.forEach(cuentaFinanza => {
                setDate<CuentaFinanza>(cuentaFinanza);
                this.setCuentaFinanza(cuentaFinanza);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCuentaFinanza = async (id: number) => {
        let cuentaFinanza = this.getCuentaFinanza(id);
        if (cuentaFinanza) {
            setDate<CuentaFinanza>(cuentaFinanza);
            this.selectedCuentaFinanza = cuentaFinanza;
            return cuentaFinanza;
        } else {
            this.loadingInitial = true;
            try {
                cuentaFinanza = await agent.CuentasFinanzas.details(id);
                this.setCuentaFinanza(cuentaFinanza);
                this.selectedCuentaFinanza = cuentaFinanza;
                this.setLoadingInitial(false);
                return cuentaFinanza;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaFinanza = (cuentaFinanza: CuentaFinanza) => {
        this.cuentaFinanzaRegistry.set(cuentaFinanza.id, cuentaFinanza);
    }

    private getCuentaFinanza = (id: number) => {
        return this.cuentaFinanzaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaFinanza = async (cuentaFinanza: CuentaFinanza) => {
        this.loading = true;
        try {
            await agent.CuentasFinanzas.create(cuentaFinanza);
            runInAction(() => {
                this.selectedCuentaFinanza = cuentaFinanza;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Cuenta de finanza creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaFinanza = async (cuentaFinanza: CuentaFinanza) => {
        this.loading = true;
        try {
            await agent.CuentasFinanzas.update(cuentaFinanza);
            runInAction(() => {
                this.selectedCuentaFinanza = cuentaFinanza;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Cuenta de finanzas editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaFinanza = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasFinanzas.delete(id);
            runInAction(() => {
                this.cuentaFinanzaRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Cuenta de Finanza eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar la cuenta de Finanza");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
