import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { PersonalTipo } from "./PersonalTipo";
import { Direccion, DireccionValues } from "../configuracion/Direccion";

//TODO: id and clave pased of number to string on this case
export interface Personal {
    id: number ;
    empresaId:number;
    empresa?:Empresa;
    sucursalId:number;
    sucursal?:Sucursal;
    clave: number ;
    nombre: string ;
    personalTipoId: number;
    personalTipo?: PersonalTipo;
    direccionId: number ;
    direccion: Direccion;
    //contacto: string ;
    numeroLicencia: string ;
    vencimientoLicencia:  Date;
    codigoContable: string ;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}


export class PersonalFormValues implements Personal {
    id: number = 0;
    empresaId:number =0;
    empresa?:Empresa;
    sucursalId:number =0;
    sucursal?:Sucursal;
    clave: number = 0;
    nombre: string = '';
    personalTipoId: number = 0;
    personalTipo?: PersonalTipo;
    direccionId: number =0;
    direccion: Direccion=new DireccionValues();;
    /* contacto: string = '';*/
    numeroLicencia: string = '';
    vencimientoLicencia:  Date = new Date();
    codigoContable: string = '';
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    constructor(init?: Personal) {
        Object.assign(this, init);
      }
}