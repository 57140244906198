import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import ConceptoClienteForm from "../form/ConceptoClienteForm";

export default observer(function ConceptoClienteList() {
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { conceptoClienteStore } = useStore();
  const { deleteConceptoCliente, conceptosClientesById, loading } = conceptoClienteStore;
  const [target, setTarget] = useState("");

  function deleteConfirmed(e: string, id: number) {
    deleteConceptoCliente(id);
    setTarget(e);
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }
  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>CLAVE</Table.HeaderCell>
            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
            <Table.HeaderCell>TIPO CONCEPTO</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {conceptosClientesById.map((conceptoCliente) => (
            <Table.Row key={conceptoCliente.id}>
              <Table.Cell>{conceptoCliente.clave}</Table.Cell>
              <Table.Cell>{conceptoCliente.nombre}</Table.Cell>
              <Table.Cell>{conceptoCliente.tipo?.nombre}</Table.Cell>
              <Table.Cell>
                <Button
                onClick={() => tabsStore.addTab('Editar Conceptos Clientes', <ConceptoClienteForm id={conceptoCliente.id} />)}
                  floated="right"
                  content="Editar"
                  color="blue"
                />
                <Button
                  name={conceptoCliente.id}
                  disabled={loading && target === String(conceptoCliente.id)}
                  loading={loading && target === String(conceptoCliente.id)}
                  onClick={(e) =>
                    openModal(
                      <DeleteModals
                        deleteConfirmed={deleteConfirmed}
                        deletedeclined={deletedeclined}
                        e={e.currentTarget.name.toString()}
                        id={conceptoCliente.id}
                        nombre={conceptoCliente.nombre}
                      />,
                      enumHeaderModals.ConceptoCliente
                    )
                  }
                  floated="right"
                  content="Eliminar"
                  color="red"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
});