import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { VehiculoTipo } from "../../models/gestionFlotilla/VehiculoTipo";

export default class VehiculoTipoStore {
    vehiculoTipoRegistry = new Map<number, VehiculoTipo>();
    selectedVehiculoTipo: VehiculoTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get vehiculoTiposByDropdown() {
        debugger
        let vehiculoTiposArreg = [new TipoDropDownValues()];
        let vehiculoTiposOpt = new TipoDropDownValues();
        const cvts = Array.from(this.vehiculoTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            vehiculoTiposOpt.key = element.id;
            vehiculoTiposOpt.text = element.nombre;
            vehiculoTiposOpt.value = element.id;
            vehiculoTiposOpt.content = element.nombre;
            vehiculoTiposArreg.push(vehiculoTiposOpt);
            vehiculoTiposOpt = new TipoDropDownValues();

        });
        return vehiculoTiposArreg;
    }
    get tipoVentaTiposByDate() {
        return Array.from(this.vehiculoTipoRegistry.values()).sort((a) => a.id);
    }


    loadVehiculoTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const vehiculoTipos = await agent.VehiculoTipos.list();

            vehiculoTipos.forEach(vehiculoTipo => {
                this.setVehiculoTipo(vehiculoTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getVehiculoTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        const vehiculoTipos = await agent.VehiculoTipos.list();
        try {
            vehiculoTipos.forEach(vehiculoTipo => {
                this.setVehiculoTipo(vehiculoTipo);
            })
            this.setLoadingInitial(false);
            return vehiculoTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return vehiculoTipos;
        }
    }

    loadVehiculoTipo = async (id: number) => {
        let vehiculoTipo = this.getVehiculoTipo(id);
        if (vehiculoTipo) {
            this.selectedVehiculoTipo = vehiculoTipo;
            return vehiculoTipo;
        } else {
            this.loadingInitial = true;
            try {
                vehiculoTipo = await agent.VehiculoTipos.details(id);
                this.setVehiculoTipo(vehiculoTipo);
                this.selectedVehiculoTipo = vehiculoTipo;
                this.setLoadingInitial(false);
                return vehiculoTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setVehiculoTipo = (vehiculoTipo: VehiculoTipo) => {
        this.vehiculoTipoRegistry.set(vehiculoTipo.id, vehiculoTipo);
    }

    private getVehiculoTipo = (id: number) => {
        return this.vehiculoTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createVehiculoTipo = async (vehiculoTipo: VehiculoTipo) => {
        this.loading = true;
        try {
            await agent.VehiculoTipos.create(vehiculoTipo);
            runInAction(() => {
                this.vehiculoTipoRegistry.set(vehiculoTipo.id, vehiculoTipo);
                this.selectedVehiculoTipo = vehiculoTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteVehiculoTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.VehiculoTipos.delete(id);
            runInAction(() => {
                this.vehiculoTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
