import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Message, Segment, Header, Radio } from 'semantic-ui-react';
import * as Yup from 'yup';

interface PagoFormProps {
    total: number;
    onSubmit: (pagoInfo: PagoInfo) => void;
}

interface PagoInfo {
    method: 'cash' | 'card' | 'credit';
    cardNumber?: string;
    expirationDate?: string;
    cvv?: string;
    cashAmount?: number;
    creditTerm?: number;
}

interface PagoInfoErrors {
    method?: string;
    cardNumber?: string;
    expirationDate?: string;
    cvv?: string;
    cashAmount?: string;
    creditTerm?: string;
}

const cardSchema = Yup.object().shape({
    cardNumber: Yup.string()
        .matches(/^\d{16}$/, 'El número de tarjeta debe tener 16 dígitos')
        .required('El número de tarjeta es requerido'),
    expirationDate: Yup.string()
        .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Formato inválido. Use MM/YY')
        .required('La fecha de expiración es requerida'),
    cvv: Yup.string()
        .matches(/^\d{3,4}$/, 'El CVV debe tener 3 o 4 dígitos')
        .required('El CVV es requerido'),
});

const cashSchema = Yup.object().shape({
    cashAmount: Yup.number()
        .min(0, 'El monto en efectivo no puede ser negativo')
        .required('El monto en efectivo es requerido'),
});

const creditSchema = Yup.object().shape({
    creditTerm: Yup.number()
        .min(1, 'El plazo de crédito debe ser de al menos 1 mes')
        .max(24, 'El plazo de crédito no puede exceder 24 meses')
        .required('El plazo de crédito es requerido'),
});

export const PagoForm: React.FC<PagoFormProps> = observer(({ total, onSubmit }) => {
    const [pagoInfo, setPagoInfo] = useState<PagoInfo>({
        method: 'cash',
        cardNumber: '',
        expirationDate: '',
        cvv: '',
        cashAmount: 0,
        creditTerm: 1,
    });
    const [errors, setErrors] = useState<PagoInfoErrors>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
        setPagoInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleMethodChange = (e: React.SyntheticEvent, { value }: any) => {
        setPagoInfo((prev) => ({ ...prev, method: value }));
    };

    const validatePagoInfo = async () => {
        try {
            switch (pagoInfo.method) {
                case 'card':
                    await cardSchema.validate(pagoInfo, { abortEarly: false });
                    break;
                case 'cash':
                    await cashSchema.validate(pagoInfo, { abortEarly: false });
                    break;
                case 'credit':
                    await creditSchema.validate(pagoInfo, { abortEarly: false });
                    break;
            }
            setErrors({});
            return true;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors: PagoInfoErrors = {};
                err.inner.forEach((error) => {
                    if (error.path) {
                        newErrors[error.path as keyof PagoInfoErrors] = error.message;
                    }
                });
                setErrors(newErrors);
            }
            return false;
        }
    };

    const handleSubmit = async () => {
        const isValid = await validatePagoInfo();
        if (isValid) {
            onSubmit(pagoInfo);
        }
    };

    return (
        <Segment>
            <Header as="h2">Forma de Pago</Header>
            <Form onSubmit={handleSubmit}>
                <Form.Group inline>
                    {/* <label>Método de pago:</label> */}
                    <Form.Field
                        control={Radio}
                        label='Efectivo'
                        value='cash'
                        checked={pagoInfo.method === 'cash'}
                        onChange={handleMethodChange}
                    />
                    <Form.Field
                        control={Radio}
                        label='Tarjeta'
                        value='card'
                        checked={pagoInfo.method === 'card'}
                        onChange={handleMethodChange}
                    />
                    <Form.Field
                        control={Radio}
                        label='Crédito'
                        value='credit'
                        checked={pagoInfo.method === 'credit'}
                        onChange={handleMethodChange}
                    />
                </Form.Group>

                {pagoInfo.method === 'cash' && (
                    <Form.Input
                        fluid
                        label="Monto en efectivo"
                        name="cashAmount"
                        type="number"
                        value={pagoInfo.cashAmount}
                        onChange={handleChange}
                        error={errors.cashAmount}
                    />
                )}

                {pagoInfo.method === 'card' && (
                    <>
                        <Form.Input
                            fluid
                            label="Número de Tarjeta"
                            name="cardNumber"
                            placeholder="1234 5678 9012 3456"
                            value={pagoInfo.cardNumber}
                            onChange={handleChange}
                            error={errors.cardNumber}
                        />
                        <Form.Input
                            fluid
                            label="Fecha de Expiración (MM/YY)"
                            name="expirationDate"
                            placeholder="12/25"
                            value={pagoInfo.expirationDate}
                            onChange={handleChange}
                            error={errors.expirationDate}
                        />
                        <Form.Input
                            fluid
                            label="CVV"
                            name="cvv"
                            placeholder="123"
                            value={pagoInfo.cvv}
                            onChange={handleChange}
                            error={errors.cvv}
                        />
                    </>
                )}

                {pagoInfo.method === 'credit' && (
                    <Form.Input
                        fluid
                        label="Plazo de crédito (meses)"
                        name="creditTerm"
                        type="number"
                        min={1}
                        max={24}
                        value={pagoInfo.creditTerm}
                        onChange={handleChange}
                        error={errors.creditTerm}
                    />
                )}

                <Message>
                    <Message.Header>Total a pagar: ${total.toFixed(2)}</Message.Header>
                </Message>
                <Button primary fluid type="submit">Pagar</Button>
            </Form>
        </Segment>
    );
});