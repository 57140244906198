import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { SerieFolioTipo } from "../../models/venta/SerieFolioTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";


export default class SerieFolioTipoStore {
    serieFolioTipoRegistry = new Map<number, SerieFolioTipo>();
    selectedSerieFolioTipo: SerieFolioTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    get serieFolioTiposByDropdown() {
        let SerieFolioTiposArreg = [new TipoDropDownValues()];
        let SerieFolioTiposOpt = new TipoDropDownValues();
        const cvts = Array.from(this.serieFolioTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            SerieFolioTiposOpt.key = element.id;
            SerieFolioTiposOpt.text = element.nombre;
            SerieFolioTiposOpt.value = element.id;
            SerieFolioTiposOpt.content = element.nombre;
            SerieFolioTiposArreg.push(SerieFolioTiposOpt);
            SerieFolioTiposOpt = new TipoDropDownValues();

        });
        return SerieFolioTiposArreg;
    }


    get serieFolioTiposByDropdownByClave() {
        let SerieFolioTiposArreg = [new TipoDropDownValues()];
        let SerieFolioTiposOpt = new TipoDropDownValues();
        const cvts = Array.from(this.serieFolioTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            SerieFolioTiposOpt.key = element.id;
            SerieFolioTiposOpt.text = element.clave.toString();
            SerieFolioTiposOpt.value = element.id;
            SerieFolioTiposOpt.content = element.clave.toString() +" " +element.nombre;
            SerieFolioTiposArreg.push(SerieFolioTiposOpt);
            SerieFolioTiposOpt = new TipoDropDownValues();

        });
        return SerieFolioTiposArreg;
    }

    get seriesFoliosTiposByDate() {
        return Array.from(this.serieFolioTipoRegistry.values()).sort((a) => a.id);
    }


    getSerieFolioTipos = async () => {
        this.setLoadingInitial(true);
        const serieFolioTipos = await agent.SeriesFoliosTipos.list();
        try {
            serieFolioTipos.forEach(serieFolioTipo => {
                this.setSerieFolioTipo(serieFolioTipo);

            })
            this.setLoadingInitial(false);
            return serieFolioTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return serieFolioTipos;
        }
    }

    loadSeriesFoliosTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const seriesFoliosTipos = await agent.SeriesFoliosTipos.list();
            seriesFoliosTipos.forEach(serieFolioTipo => {
                this.setSerieFolioTipo(serieFolioTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadSerieFolioTipo = async (id: number) => {
        let serieFolioTipo = this.getSerieFolioTipo(id);
        if (serieFolioTipo) {
            this.selectedSerieFolioTipo = serieFolioTipo;
            return serieFolioTipo;

        } else {
            this.loadingInitial = true;
            try {
                serieFolioTipo = await agent.SeriesFoliosTipos.details(id);
                this.setSerieFolioTipo(serieFolioTipo);
                this.selectedSerieFolioTipo = serieFolioTipo;
                this.setLoadingInitial(false);
                return serieFolioTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setSerieFolioTipo = (serieFolioTipo: SerieFolioTipo) => {
        this.serieFolioTipoRegistry.set(serieFolioTipo.id, serieFolioTipo);
    }

    private getSerieFolioTipo = (id: number) => {
        return this.serieFolioTipoRegistry.get(id);
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createSerieFolioTipo = async (serieFolioTipo: SerieFolioTipo) => {
        this.loading = true;
        try {
            await agent.SeriesFoliosTipos.create(serieFolioTipo);
            runInAction(() => {
                this.serieFolioTipoRegistry.set(serieFolioTipo.id, serieFolioTipo);
                this.selectedSerieFolioTipo = serieFolioTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }



    deleteSerieFolioTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.SeriesFoliosTipos.delete(id);
            runInAction(() => {
                this.serieFolioTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
