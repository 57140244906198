import axios, { AxiosResponse } from "axios";

import { Empresa } from "../models/configuracion/Empresa";
import { Moneda } from "../models/configuracion/Moneda";
import { MonedaTipo } from "../models/configuracion/MonedaTipo";
import { MonedaSAT } from "../models/configuracion/MonedaSAT";
import { Sucursal } from "../models/configuracion/Sucursal";
import { Usuario } from "../models/configuracion/Usuario";
import { UsuarioTipo } from "../models/configuracion/UsuarioTipo";
import { Perfil } from "../models/configuracion/Perfil";
import { Impuesto } from "../models/configuracion/Impuesto";
import { ImpuestoTipo } from "../models/configuracion/ImpuestoTipo";
import { Producto } from "../models/inventario/Producto";

import { CuentaDeVenta } from "../models/venta/CuentaDeVenta";
import { CuentaDeVentaTipo } from "../models/venta/CuentaDeVentaTipo";
import { TipoVenta } from "../models/venta/TipoVenta";
import { TipoVentaTipo } from "../models/venta/TipoVentaTipo";
import { SerieFolioDeVenta } from "../models/venta/SerieFolioDeVenta";

import { ListaDePrecio } from "../models/controlPrecio/ListaDePrecio";
import { ListaDePrecioTipo } from "../models/controlPrecio/ListaDePrecioTipo";
import {
    ControlListaDePrecio,
    ControlListaDePrecioArregloFormValues,
} from "../models/controlPrecio/ControlListaDePrecio";

import { Cliente } from "../models/gestionCliente/Cliente";
import { ClienteTipo } from "../models/gestionCliente/ClienteTipo";
import { CondicionDePago } from "../models/gestionCliente/CondicionDePago";
import { FormaDePago } from "../models/gestionCliente/FormaDePago";
import { MetodoPago } from "../models/sat/c_MetodoPago";
import { UsoCFDI } from "../models/sat/UsoCFDI";
import { ProductoTipo } from "../models/inventario/ProductoTipo";
import { UnidadMedida } from "../models/inventario/UnidadMedida";
import { CategoriaProducto } from "../models/inventario/CategoriaProducto";
import { ImpuestoComprobante } from "../models/configuracion/ImpuestoComprobante";

import { CuentaDeCliente } from "../models/gestionCliente/CuentaDeCliente";
import { CuentaDeClienteTipo } from "../models/gestionCliente/CuentaDeClienteTipo";
import { ConceptoCliente } from "../models/gestionCliente/ConceptoCliente";
import { ConceptoClienteTipo } from "../models/gestionCliente/ConceptoClienteTipo";

import { Almacen } from "../models/inventario/Almacen";
import { AlmacenTipo } from "../models/inventario/AlmacenTipo";
import { ConceptoDeInventario } from "../models/inventario/ConceptoDeInventario";
import { ConceptoDeInventarioTipo } from "../models/inventario/ConceptoDeInventarioTipo";

import { Proveedor } from "../models/gestionProveedor/Proveedor";
import { ProveedorTipo } from "../models/gestionProveedor/ProveedorTipo";

import { CuentaDeProveedor } from "../models/gestionProveedor/CuentaDeProveedor";
import { CuentaDeProveedorTipo } from "../models/gestionProveedor/CuentaDeProveedorTipo";
import { ConceptoDeProveedor } from "../models/gestionProveedor/ConceptoDeProveedor";
import { ConceptoDeProveedorTipo } from "../models/gestionProveedor/ConceptoDeProveedorTipo";

import { CuentaDeCompra } from "../models/compra/CuentaDeCompra";
import { CuentaDeCompraTipo } from "../models/compra/CuentaDeCompraTipo";
import { TipoDeCompra } from "../models/compra/TipoDeCompra";
import { TipoDeCompraTipo } from "../models/compra/TipoDeCompraTipo";
import { SerieFolioCompra } from "../models/compra/SerieFolioCompra";

import { Vehiculo } from "../models/gestionFlotilla/Vehiculo";
import { Personal } from "../models/gestionFlotilla/Personal";
import { PersonalTipo } from "../models/gestionFlotilla/PersonalTipo";
import { toast } from "react-toastify";
import { Pac } from "../models/configuracion/Pac";
import { Pais } from "../models/configuracion/Pais";
import { Estado } from "../models/configuracion/Estado";
import { PoliticaDescuento } from "../models/inventario/PoliticaDescuento";
import { PaginationFormValues, PaginationSpecParams } from "../models/Especificaciones/PaginationSpecParams";
import { CorteDeCajaFiltro } from "../models/venta/CorteDeCajaFiltro"
import { SerieFolioTipo } from "../models/venta/SerieFolioTipo";
import { Clasificacion } from "../models/inventario/Clasificacion";
import { Entity } from "../models/Entity";
import { PagoDe } from "../models/pagoDe";
import { VehiculoTipo } from "../models/gestionFlotilla/VehiculoTipo";
import { Departamento } from "../models/gestionFlotilla/Departamento";
import { GeneracionComprobante } from "../models/venta/GeneracionComprobante";

import { IPagination } from "../models/controlPrecio/pagination";
import { cRegimenFiscal } from "../models/sat/cRegimenFiscal";
import { TipoMovimiento } from "../models/TipoMovimiento";
import { MovimientoInventario } from "../models/inventario/MovimientoInventario";
import { ReporteVentas } from "../models/venta/ReporteVentas";
import { MovimientoCliente } from "../models/gestionCliente/MovimientoCliente";
import { MovimientoDeProveedor } from "../models/gestionProveedor/MovimientoDeProveedor";
import { CuentaFinanza } from "../models/Finanza/CuentaFinanza";
import { CuentaFinanzaTipo } from "../models/Finanza/CuentaFinanzaTipo";
import { ConceptoFinanza } from "../models/Finanza/ConceptoFinanza";
import { ConceptoFinanzaTipo } from "../models/Finanza/ConceptoFinanzaTipo";
import { Banco } from "../models/Finanza/Banco";
import { BancoSat } from "../models/Finanza/BancoSat";
import { MovimientoFinanza } from "../models/Finanza/MovimientoFinanza";
import { GeneracionComprobanteCompra } from "../models/compra/GeneracionComprobanteCompra";
import { ReporteCompras } from "../models/compra/ReporteCompras";
import { VerificadorGestionCliente } from "../models/gestionCliente/VerificadorGestionCliente";
import { VerificadorGestionProveedor } from "../models/gestionProveedor/VerificadorGestionProveedor";
import { VerificadorAlmacen } from "../models/inventario/VerificadorAlmacen";
import { c_Mes } from "../models/sat/c_Mes";
import { c_Periodicidad } from "../models/sat/c_Periodicidad";
import { tipoSAT } from "../models/sat/tipoSAT";
import { CapturaPagos } from "../models/gestionCliente/CapturaPagos";
import { General } from "../models/configuracion/General";
import { CorteDeCaja } from "../models/venta/CorteDeCaja";
import { GeneracionCompronanteTickets, Tickets } from "../models/venta/GeneracionCompronanteTickets";
import { SaldoCliente } from "../models/gestionCliente/SaldoCliente";
import { ReporteAlmacen } from "../models/inventario/ReporteAlmacen";
import { VerificadorPago } from "../models/gestionCliente/VerificadorPagos";
import { EstadoCuenta } from "../models/gestionCliente/EstadoCuenta";
import { c_MotivoCancelacion } from "../models/sat/c_MotivoCancelacion";
import { c_TipoRelacion } from "../models/sat/c_TipoRelacion";
import { TipoNotaCredito } from "../models/venta/TipoNotaCredito";
import { CuentaBancaria } from "../models/Finanza/CuentaBancaria";
import { AxiosRequestConfig } from "axios";
import { EnviarCorreo } from "../models/EnviarCorreo";
import { InventarioFisico } from "../models/inventario/InventarioFisicoDetalle";
import { ImpuestoProducto } from "../models/inventario/ImpuestoProducto";

//export const urlApi = "http://ais-online.com.mx:8081/api/Reports/";//para los reportes
export const urlApi = "http://ais-test.com.mx:8081/api/Reports/";//para los reportes
//export const urlApi = "https://localhost:44327/api/Reports/";//para los reportes
export const urlApiImagenes = "http://ais-test.com.mx:8081/";
//export const urlApiImagenes = "https://localhost:44327/";//p
export const urlReports = "reports--Pdfs--";
const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

//axios.defaults.headers.post['Content-Type'] = 'application/json';
//axios.defaults.baseURL = "http://ais-online.com.mx:8081/api";
axios.defaults.baseURL = "http://ais-test.com.mx:8081/api";
//axios.defaults.baseURL = "https://localhost:44327/api";

axios.interceptors.response.use(undefined, (error) => {
    //let history = useHistory();
    if (error.message === "Network Error" && !error.response) {
        toast.error('Error de red: asegúrese de que la API se esté ejecutando!')
    }
    if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
            if (data) {
                let mensaje = data.errors ? data.errors : data;
                toast.error(mensaje);
            }
        }

    if (
      status === 400
    ) {
      let mensaje = "";
      if (data) {
        mensaje = data.errors ? data.errors : data;
        toast.error(mensaje)
      }
    }
    if (status === 500) {
      let mensaje = "";
      if (data)
        mensaje = data.errors ? data.errors : data;
      toast.error('Error del servidor: consulte la terminal para obtener más información. ' + mensaje)
    }
  }
  throw error.response;
});

////

axios.interceptors.response.use(async (response) => {
    try {
        await sleep(0);
        return response;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
// post: (url: string, body: {}) => axios.post(url, body).then(sleep(1000)).then(responseBody),
const requests = {
    getSignal: async <T>(url: string, config: AxiosRequestConfig = {}) => {
        const controller = new AbortController();
        const signal = controller.signal;
        config.signal = signal;
        try {
            const response = await axios.get<T>(url, config);
            return responseBody(response);
        } finally {
            controller.abort();
        }
    },
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    getPaginationSignal: <T>(url: string, body = {}, signal?: AbortSignal): Promise<T> =>
        axios.get<T>(url, { params: body, signal }).then(responseBody),
    getPagination: <T>(url: string, body = {}) =>
        axios.get<T>(url, { params: body }).then(responseBody),
    getReportes: <T>(url: string, body = {}) =>
        axios.get<T>(url, { params: body, responseType: 'blob' }).then(responseBody),
    post: <T>(url: string, body = {}) =>
        axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body = {}) =>
        axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const ReportesConfiguraciones = {
    imprimirReporteEmpresas: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/empresas/GetReporteEmpresas", filtro),
    imprimirReporteSucursales: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/sucursales/GetReporteSucursales", filtro),
    imprimirReporteUsuarios: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/usuarios/GetReporteUsuarios", filtro),
    imprimirReportePerfiles: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/perfiles/GetReportePerfiles", filtro),
    imprimirReporteMonedas: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/monedas/GetReporteMonedas", filtro),
    imprimirReporteImpuestos: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/impuestos/GetReporteImpuestos", filtro)
}

const Empresas = {
  listEntity: () => requests.get<Entity[]>("/empresas/GetEntity"),
  list: () => requests.get<Empresa[]>("/empresas"),
  details: (id: number) => requests.get<Empresa>(`/empresas/${id}`),
  create: (empresa: FormData) => requests.post<void>("/empresas", empresa),
  update: (empresa: FormData, id: number) =>
    requests.put<void>(`/empresas/${id}`, empresa),
  delete: (id: number) => requests.del<void>(`/empresas/${id}`),
  obtenerEmpresaPorId: (filtro: PaginationSpecParams) => requests.getPagination<Empresa>("/empresas/ObtenerEmpresaPorId", filtro),
};

const Sucursales = {
    list: () => requests.get<Sucursal[]>("/sucursales"),
    listByEmpresa: (id: number) =>
        requests.get<Sucursal[]>(`/sucursales/GetSucursalesByEmpresa/${id}`),
    listByEmpresaSiganl: (id: number) =>
        requests.getSignal<Sucursal[]>(`/sucursales/GetSucursalesByEmpresa/${id}`),
    obtenerClave: (filtro: PaginationSpecParams) => requests.getPagination<number>(
        "/sucursales/GetClaveSucursal", filtro),
    details: (id: number) => requests.get<Sucursal>(`/sucursales/${id}`),
    create: (sucursal: Sucursal) => requests.post<void>("/sucursales", sucursal),
    update: (sucursal: Sucursal) =>
        requests.put<void>(`/sucursales/${sucursal.id}`, sucursal),
    delete: (id: number) => requests.del<void>(`/sucursales/${id}`),
};

const Usuarios = {
    list: () => requests.get<Usuario[]>("/usuarios"),
    listByEmpresaSucursal: (filtro: PaginationSpecParams) =>
        requests.getPagination<Usuario[]>(
            "/usuarios/GetUsuariosByEmpresaSucursal",
            filtro
        ),
    obtenerClave: (filtro: PaginationSpecParams) => requests.getPagination<number>(
        "/usuarios/GetClave", filtro),
    getUsuarioByParams: (filtro: PaginationSpecParams) =>
        requests.getPagination<Usuario[]>("/usuarios/getUsuarioByParams", filtro),
    getUsuarioVendedor: (filtro: PaginationSpecParams) =>
        requests.getPagination<Usuario[]>("/usuarios/getUsuarioByParamsVendedor", filtro),
    getUsuarioTrabajador: (filtro: PaginationSpecParams) =>
        requests.getPagination<Usuario[]>("/usuarios/getUsuarioByParamsTrabajador", filtro),
    getUsuarioComprador: (filtro: PaginationSpecParams) =>
        requests.getPagination<Usuario[]>("/usuarios/getUsuarioByParamsComprador", filtro),
    details: (id: number) => requests.get<Usuario>(`/usuarios/${id}`),
    create: (usuario: Usuario) => requests.post<void>("/usuarios", usuario),
    update: (usuario: Usuario) =>
        requests.put<void>(`/usuarios/${usuario.id}`, usuario),
    delete: (id: number) => requests.del<void>(`/usuarios/${id}`),
};

const Perfiles = {
    list: () => requests.get<Perfil[]>("/perfiles"),
    details: (id: number) => requests.get<Perfil>(`/perfiles/${id}`),
    create: (perfil: Perfil) => requests.post<void>("/perfiles", perfil),
    update: (perfil: Perfil) =>
        requests.put<void>(`/perfiles/${perfil.id}`, perfil),
    delete: (id: number) => requests.del<void>(`/perfiles/${id}`),
};

const Pacs = {
    list: () => requests.get<Pac[]>("/pacs"),
    details: (id: number) => requests.get<Pac>(`/pacs/${id}`),
    create: (pac: Pac) => requests.post<void>("/pacs", pac),
    update: (pac: Pac) => requests.put<void>(`/pacs/${pac.id}`, pac),
    delete: (id: number) => requests.del<void>(`/pacs/${id}`),
};

const Paises = {
    list: () => requests.get<Pais[]>("/paises"),
    details: (id: number) => requests.get<Pais>(`/paises/${id}`),
    create: (pais: Pais) => requests.post<void>("/paises", pais),
    update: (pais: Pais) => requests.put<void>(`/paises/${pais.id}`, pais),
    delete: (id: number) => requests.del<void>(`/paises/${id}`),
};

const Estados = {
    list: () => requests.get<Estado[]>("/estados"),
    details: (id: number) => requests.get<Estado>(`/estados/${id}`),
    create: (estado: Estado) => requests.post<void>("/estados", estado),
    update: (estado: Estado) =>
        requests.put<void>(`/estados/${estado.id}`, estado),
    delete: (id: number) => requests.del<void>(`/estados/${id}`),
};

const Monedas = {
    listEntity: () => requests.get<Entity[]>("/monedas/GetEntity"),
    list: () => requests.get<Moneda[]>("/monedas"),
    details: (id: number) => requests.get<Moneda>(`/monedas/${id}`),
    create: (moneda: Moneda) => requests.post<void>("/monedas", moneda),
    update: (moneda: Moneda) =>
        requests.put<void>(`/monedas/${moneda.id}`, moneda),
    delete: (id: number) => requests.del<void>(`/monedas/${id}`),
};

const MonedaTipos = {
    list: () => requests.get<MonedaTipo[]>("/monedaTipos"),
    details: (id: number) => requests.get<MonedaTipo>(`/monedaTipos/${id}`),
    create: (monedaTipo: MonedaTipo) =>
        requests.post<void>("/monedaTipos", monedaTipo),
    update: (monedaTipo: MonedaTipo) =>
        requests.put<void>(`/monedaTipos/${monedaTipo.id}`, { monedaTipo }),
    delete: (id: number) => requests.del<void>(`/monedaTipos/${id}`),
};
const MonedasSAT = {
    list: () => requests.get<MonedaSAT[]>("/monedasSAT"),
    details: (id: number) => requests.get<MonedaSAT>(`/monedasSAT/${id}`),
    create: (monedaSAT: MonedaSAT) =>
        requests.post<void>("/monedasSAT", monedaSAT),
    update: (monedaSAT: MonedaSAT) =>
        requests.put<void>(`/monedasSAT/${monedaSAT.id}`, { monedaSAT }),
    delete: (id: number) => requests.del<void>(`/monedasSAT/${id}`),
};
const Generales = {
    list: () => requests.get<General[]>("/generales"),
    getByParams: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
        requests.getPaginationSignal<General>(
            "/generales/getGeneralSpecParams", { ...filtro, ...options }, signal
        ),
    details: (id: number) => requests.get<General>(`/generales/${id}`),
    create: (general: General) =>
        requests.post<void>("/generales", general),
    update: (general: General) =>
        requests.put<void>(`/generales/${general.id}`, { general }),
    delete: (id: number) => requests.del<void>(`/generales/${id}`),
};

const TiposVentas = {
    list: () => requests.get<TipoVenta[]>("/tiposVentas"),
    listByParams: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
        requests.getPaginationSignal<TipoVenta[]>(
            "/tiposVentas/getTipoVentaSpecParams", { ...filtro, ...options }, signal
        ),
    getTipoVentaByParams: (filtro: PaginationSpecParams) =>
        requests.getPagination<TipoVenta[]>("/tiposVentas/getTipoVentaSpecParams", filtro),
    details: (id: number) => requests.get<TipoVenta>(`/tiposVentas/${id}`),
    create: (tipoVenta: TipoVenta) =>
        requests.post<void>("/tiposVentas", tipoVenta),
    update: (tipoVenta: TipoVenta) =>
        requests.put<void>(`/tiposVentas/${tipoVenta.id}`, tipoVenta),
    delete: (id: number) => requests.del<void>(`/tiposVentas/${id}`),
};

const TipoVentaTipos = {
    list: () => requests.get<TipoVentaTipo[]>("/tipoVentaTipos"),
    details: (id: number) => requests.get<TipoVentaTipo>(`/tipoVentaTipos/${id}`),
    create: (tipoVentaTipo: TipoVentaTipo) =>
        requests.post<void>("/tipoVentaTipos", tipoVentaTipo),
    update: (tipoVentaTipo: TipoVentaTipo) =>
        requests.put<void>(`/tipoVentaTipos/${tipoVentaTipo.id}`, tipoVentaTipo),
    delete: (id: number) => requests.del<void>(`/tipoVentaTipos/${id}`),
};

const SeriesFoliosDeVentas = {
    list: () => requests.get<SerieFolioDeVenta[]>("/SeriesFoliosDeVentas"),
    listByTipoVenta: (filtro: PaginationSpecParams) =>
        requests.getPagination<SerieFolioDeVenta[]>(
            "/SeriesFoliosDeVentas/GetSeriesFoliosByTipoVenta",
            filtro
        ),
    listByParams: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
        requests.getPaginationSignal<SerieFolioDeVenta[]>(
            "/SeriesFoliosDeVentas/GetSeriesFoliosByTipoVenta", { ...filtro, ...options }, signal
        ),
    details: (id: number) =>
        requests.get<SerieFolioDeVenta>(`/SeriesFoliosDeVentas/${id}`),
    create: (serieFolioDeVenta: FormData) =>
        requests.post<void>("/SeriesFoliosDeVentas", serieFolioDeVenta),
    update: (serieFolioDeVenta: FormData, id: number) =>
        requests.put<void>(`/SeriesFoliosDeVentas/${id}`, serieFolioDeVenta),
    delete: (id: number) => requests.del<void>(`/SeriesFoliosDeVentas/${id}`),
};

const SeriesFoliosTipos = {
    list: () => requests.get<SerieFolioTipo[]>("/seriesFoliosTipos"),
    details: (id: number) =>
        requests.get<SerieFolioTipo>(`/seriesFoliosTipos/${id}`),
    create: (serieFolioTipo: SerieFolioTipo) =>
        requests.post<void>("/seriesFoliosTipos", serieFolioTipo),
    update: (serieFolioTipo: SerieFolioTipo) =>
        requests.put<void>(
            `/seriesFoliosTipos/${serieFolioTipo.id}`,
            serieFolioTipo
        ),
    delete: (id: number) => requests.del<void>(`/seriesFoliosTipos/${id}`),
};

const ReportesVentas = {
    imprimirReporteCuentasVentas: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/CuentasVentas/GetReporteCuentasVentas", filtro),
    imprimirReporteTiposVentas: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/TiposVentas/GetReporteTiposVentas", filtro),
    imprimirReporteSeriesFolio: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/SeriesFoliosDeVentas/GetReporteSeriesFolios", filtro),
    imprimirReporteVentas: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/generacionComprobantes/GetReporteVentas", filtro),
    imprimirReporteVentasDetalles: (filtro: PaginationFormValues) => requests.getReportes<any>(
        "/generacionComprobantes/GetReporteDetalleVentas", filtro),
    imprimirReporteSurtidoComprobantes: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/generacionComprobantes/GetReporteSurtidoComprobantes", filtro),
    exportarComprobantesAExcel: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/generacionComprobantes/GetExportarComprobantesAExcel", filtro)
}

const ReportesCompras = {
    imprimirReporteCompras: (filtro: ReporteCompras) => requests.getPagination<ReporteCompras[]>
        ("/generacionComprobantesCompras/GetReporteCompras", filtro),
    imprimirReporteComprasDetalles: (filtro: ReporteCompras) => requests.getPagination<ReporteCompras[]>
        ("/generacionComprobantesCompras/GetReporteDetalleCompras", filtro),
    imprimirReporteSurtidoComprobanteCompras: (filtro: ReporteCompras) => requests.getPagination<ReporteCompras[]>(
        "/generacionComprobantesCompras/GetReporteSurtidoComprobantesCompras", filtro)
}

const CortesDeCajas = {
    list: () => requests.get<CorteDeCaja[]>("/cortesDeCajas"),
    details: (id: number) =>
        requests.get<CorteDeCaja>(`/cortesDeCajas/${id}`),
    obtenerCorteDeCajaPorFecha: (filtro: PaginationSpecParams) =>
        requests.getPagination<CorteDeCaja>("/cortesDeCajas/GetObtenerCorteDeCajaPorFecha",
            filtro
        ),
    create: (corteDeCaja: CorteDeCaja) =>
        requests.post<string>("/cortesDeCajas", corteDeCaja),
    update: (corteDeCaja: CorteDeCaja) =>
        requests.post<void>(`/cortesDeCajas/${corteDeCaja.id}`, {
            corteDeCaja,
        }),
    delete: (id: number) => requests.del<void>(`/cortesDeCajas/${id}`),
}

const GeneracionComprobantes = {
    list: () => requests.get<GeneracionComprobante[]>("/generacionComprobantes"),

    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<number>(
        "/generacionComprobantes/GetFolioComprobante", filtro),
    obtenerImportesVentasDeContado: (filtro: CorteDeCajaFiltro) => requests.getPagination<GeneracionComprobante[]>(
        "/generacionComprobantes/GetImportesVentasDeContado", filtro),
    obtenerImportesVentasDeCredito: (filtro: CorteDeCajaFiltro) => requests.getPagination<GeneracionComprobante[]>(
        "/generacionComprobantes/GetImportesVentasDeCredito", filtro),
    obtenerImportesFacturasDeAnticipo: (filtro: CorteDeCajaFiltro) => requests.getPagination<GeneracionComprobante[]>(
        "/generacionComprobantes/GetImportesFacturasDeAnticipo", filtro),
    obtenerComprobantePorFolio: (filtro: PaginationSpecParams) =>
        requests.getPagination<GeneracionComprobante>("/generacionComprobantes/GetObtenerComprobantePorFolio", filtro),
    obtenerTickets: (filtro: PaginationSpecParams) =>
        requests.getPagination<GeneracionComprobante>("/generacionComprobantes/GetObtenerTickets",
            filtro
        ),
    obtenerCotizacion: (filtro: PaginationSpecParams) =>
        requests.getPagination<GeneracionComprobante>("/generacionComprobantes/GetCotizacion", filtro),
    obtenerVentasPorCategoria: (filtro: PaginationSpecParams) =>
        requests.getPagination<any>("/generacionComprobantes/obtenerVentasPorCategorias", filtro),
    ObtenerComprobantesPorUUIDRelacionado: (filtro: PaginationSpecParams) =>
        requests.getPagination<GeneracionComprobante[]>("/generacionComprobantes/getComprobantesPorUUIDRelacionado", filtro),
    details: (id: number) =>
        requests.get<GeneracionComprobante>(`/generacionComprobantes/${id}`),
    create: (generacionComprobante: GeneracionComprobante) =>
        requests.post<string>("/generacionComprobantes", generacionComprobante),
    update: (generacionComprobante: GeneracionComprobante) =>
        requests.post<void>(`/generacionComprobantes/${generacionComprobante.id}`, {
            generacionComprobante,
        }),
    delete: (id: number) => requests.del<void>(`/generacionComprobantes/${id}`),
    imprimir: (idComprobante: number) => requests.getPagination<string>(
        "/generacionComprobantes/getImprimirComprobantes", { idComprobante }),
};

const EnviarCorreos = {
    detailsByParams: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
        requests.getPaginationSignal<EnviarCorreo>(
            "/enviarCorreos/GetCorreosParams", { ...filtro, ...options }, signal
        ),
    create: (enviarCorreo: any) => requests.post<void>("/enviarCorreos/send-email", enviarCorreo),
}

const Productos = {
  list: () => requests.get<Producto[]>("/productos"),
  listClaveSat: (options = {}, signal?: AbortSignal) =>
    requests.getPaginationSignal<any[]>("/productos/GetClaveSat", { ...options }, signal),
  claveByEmpresaSucursal: (empresaSucursal: PaginationSpecParams) =>
    requests.getPagination<number>(
      "/productos/GetProductoClaveByEmpresaSucursal",
      empresaSucursal
    ),
  listByEmpresaSucursal: (empresaSucursal: PaginationSpecParams) =>
    requests.getPagination<Producto[]>(
      "/productos/GetProductosByEmpresaSucursal",
      empresaSucursal
    ),
  listByEmpresaSucursalExistencia: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
    requests.getPaginationSignal<Producto[]>(
      "/productos/GetProductosByEmpresaSucursal", { ...filtro, ...options }, signal
    ),
  details: (id: number) => requests.get<Producto>(`/productos/${id}`),
  create: (producto: FormData) => requests.post<void>("/productos", producto),
  update: (producto: FormData, id: number) =>
    requests.put<void>(`/productos/${id}`, producto),
  delete: (id: number) => requests.del<void>(`/productos/${id}`),
  obtenerProductoPorClave: (filtro: PaginationSpecParams) =>
    requests.getPagination<Producto>("/productos/ObtenerProductoPorClave",
      filtro
    ),
};

const TraspasoEntreSucursal = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<any[]>(
        "/traspasoEntreSucursal/GetNextFolio", filtro),
    create: (traspasoEntreSucursal: any) => requests.post<void>("/traspasoEntreSucursal", traspasoEntreSucursal),
};

const Clientes = {
    list: () => requests.get<Cliente[]>("/clientes"),
    listByEmpresaSucursal: (empresaSucursal: PaginationSpecParams) =>
        requests.getPagination<Cliente[]>(
            "/clientes/GetClientesByEmpresaSucursal",
            empresaSucursal
        ),
    getComprobanteSaldoByClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<Cliente>("/clientes/GetClientesClave", filtro),
    details: (id: number) => requests.get<Cliente>(`/clientes/${id}`),
    create: (cliente: Cliente) => requests.post<void>("/clientes", cliente),
    update: (cliente: Cliente) =>
        requests.put<void>(`/clientes/${cliente.id}`, cliente),
    delete: (id: number) => requests.del<void>(`/clientes/${id}`),
    obtenerClave: (filtro: PaginationSpecParams) => requests.getPagination<number>("/clientes/GetClaveCliente", filtro),
    obtenerClientePorClave: (filtro: PaginationSpecParams) => requests.getPagination<Cliente>("/clientes/ObtenerClientePorClave", filtro),
    obtenerClientePorId: (filtro: PaginationSpecParams) => requests.getPagination<Cliente>("/clientes/ObtenerClientePorId", filtro),
};

const ClienteTipos = {
    list: () => requests.get<ClienteTipo[]>("/clienteTipos"),
    details: (id: number) => requests.get<ClienteTipo>(`/clienteTipos/${id}`),
    create: (clienteTipo: ClienteTipo) =>
        requests.post<void>("/clienteTipos", clienteTipo),
    update: (clienteTipo: ClienteTipo) =>
        requests.put<void>(`/clienteTipos/${clienteTipo.id}`, { clienteTipo }),
    delete: (id: number) => requests.del<void>(`/clienteTipos/${id}`),
};

const CondicionesPagos = {
    list: () => requests.get<CondicionDePago[]>("/CondicionesPagos"),
    details: (id: number) =>
        requests.get<CondicionDePago>(`/CondicionesPagos/${id}`),
    create: (condicionPago: CondicionDePago) =>
        requests.post<void>("/CondicionesPagos", condicionPago),
    update: (condicionPago: CondicionDePago) =>
        requests.put<void>(`/CondicionesPagos/${condicionPago.id}`, {
            condicionPago,
        }),
    delete: (id: number) => requests.del<void>(`/CondicionesPagos/${id}`),
};

const FormasPagos = {
    list: () => requests.get<FormaDePago[]>("/formasPagos"),
    details: (id: number) => requests.get<FormaDePago>(`/formasPagos/${id}`),
    create: (formaPago: FormaDePago) =>
        requests.post<void>("/formasPagos", formaPago),
    update: (formaPago: FormaDePago) =>
        requests.post<void>(`/formasPagos/${formaPago.id}`, { formaPago }),
    delete: (id: number) => requests.del<void>(`/formasPagos/${id}`),
};

const Impuestos = {
    list: () => requests.get<Impuesto[]>("/impuestos"),
    details: (id: number) => requests.get<Impuesto>(`/impuestos/${id}`),
    create: (impuesto: Impuesto) => requests.post<void>("/impuestos", impuesto),
    update: (impuesto: Impuesto) => requests.put<void>(`/impuestos/${impuesto.id}`, impuesto),
    delete: (id: number) => requests.del<void>(`/impuestos/${id}`),
};
const ImpuestoTipos = {
    list: () => requests.get<ImpuestoTipo[]>("/impuestoTipos"),
    details: (id: number) => requests.get<ImpuestoTipo>(`/impuestoTipos/${id}`),
    create: (impuestoTipo: ImpuestoTipo) => requests.post<void>("/impuestoTipos", impuestoTipo),
    update: (impuestoTipo: ImpuestoTipo) => requests.put<void>(`/impuestoTipos/${impuestoTipo.id}`, impuestoTipo),
    delete: (id: number) => requests.del<void>(`/impuestoTipos/${id}`),
};

const UsuarioTipos = {
    list: () => requests.get<UsuarioTipo[]>("/usuarioTipos"),
    details: (id: number) => requests.get<UsuarioTipo>(`/usuarioTipos/${id}`),
    create: (usuarioTipo: UsuarioTipo) =>
        requests.post<void>("/usuarioTipos", usuarioTipo),
    update: (usuarioTipo: UsuarioTipo) =>
        requests.post<void>(`/usuarioTipos/${usuarioTipo.id}`, { usuarioTipo }),
    delete: (id: number) => requests.del<void>(`/usuarioTipos/${id}`),
};

const MetodosPagos = {
    list: () => requests.get<MetodoPago[]>("/cMetodosPagos"),
    details: (id: number) => requests.get<MetodoPago>(`/cMetodosPagos/${id}`),
    create: (metodoPago: MetodoPago) =>
        requests.post<void>("/cMetodosPagos", metodoPago),
    update: (metodoPago: MetodoPago) =>
        requests.post<void>(`/cMetodosPagos/${metodoPago.id}`, { metodoPago }),
    delete: (id: number) => requests.del<void>(`/cMetodosPagos/${id}`),
};
const UsosCFDIs = {
    list: () => requests.get<UsoCFDI[]>("/c_UsosCFDIs"),
    details: (id: number) => requests.get<UsoCFDI>(`/C_UsosCFDIs/${id}`),
    create: (usoCFDI: UsoCFDI) => requests.post<void>("/C_UsosCFDIs", usoCFDI),
    update: (usoCFDI: UsoCFDI) => requests.post<void>(`/C_UsosCFDIs/${usoCFDI.id}`, { usoCFDI }),
    delete: (id: number) => requests.del<void>(`/C_UsosCFDIs/${id}`),
    getUsosCFDIPorIdRegimenFiscal: (idRegimen: number) => requests.getPagination<UsoCFDI[]>("/C_UsosCFDIs/GetUsosCFDIPorIdRegimenFiscal", { idRegimen }),
    //getUsosCFDIPorIdRegimenFiscal: (idComprobante: number) => requests.getPagination<string>("/generacionComprobantes/getImprimirComprobantes", { idComprobante }),
};

const ProductoTipos = {
    list: () => requests.get<ProductoTipo[]>("/productoTipos"),
    details: (id: number) => requests.get<ProductoTipo>(`/productoTipos/${id}`),
    create: (productoTipo: ProductoTipo) =>
        requests.post<void>("/productoTipos", productoTipo),
    update: (productoTipo: ProductoTipo) =>
        requests.post<void>(`/productoTipos/${productoTipo.id}`, { productoTipo }),
    delete: (id: number) => requests.del<void>(`/productoTipos/${id}`),
};


const ImpuestosProductos = {
  list: () => requests.get<ImpuestoProducto[]>("/impuestosProductos"),
  details: (id: number) => requests.get<ImpuestoProducto>(`/impuestosProductos/${id}`),
  create: (impuestoProducto: ImpuestoProducto) => requests.post<void>("/impuestosProductos", impuestoProducto),
  update: (impuestoProducto: ImpuestoProducto) => requests.put<void>(`/impuestosProductos/${impuestoProducto.id}`, impuestoProducto),
  delete: (id: number) => requests.del<void>(`/impuestosProductos/${id}`),
};


const UnidadesMedidas = {
    list: () => requests.get<UnidadMedida[]>("/unidadesMedidas"),
    details: (id: number) => requests.get<UnidadMedida>(`/unidadesMedidas/${id}`),
    create: (unidadMedida: UnidadMedida) =>
        requests.post<void>("/unidadesMedidas", unidadMedida),
    update: (unidadMedida: UnidadMedida) =>
        requests.post<void>(`/unidadesMedidas/${unidadMedida.id}`, {
            unidadMedida,
        }),
    delete: (id: number) => requests.del<void>(`/unidadesMedidas/${id}`),
};

const CategoriasProductos = {
    list: () => requests.get<CategoriaProducto[]>("/categoriasProductos"),
    details: (id: number) =>
        requests.get<CategoriaProducto>(`/categoriasProductos/${id}`),
    create: (categoriaProducto: CategoriaProducto) =>
        requests.post<void>("/categoriasProductos", categoriaProducto),
    update: (categoriaProducto: CategoriaProducto) =>
        requests.post<void>(`/categoriasProductos/${categoriaProducto.id}`, {
            categoriaProducto,
        }),
    delete: (id: number) => requests.del<void>(`/categoriasProductos/${id}`),
};

const ImpuestosComprobantes = {
    list: () => requests.get<ImpuestoComprobante[]>("/impuestosComprobantes"),
    details: (id: number) =>
        requests.get<ImpuestoComprobante>(`/impuestosComprobantes/${id}`),
    create: (impuestoComprobante: ImpuestoComprobante) =>
        requests.post<void>("/impuestosComprobantes", impuestoComprobante),
    update: (impuestoComprobante: ImpuestoComprobante) =>
        requests.post<void>(`/impuestosComprobantes/${impuestoComprobante.id}`, {
            impuestoComprobante,
        }),
    delete: (id: number) => requests.del<void>(`/impuestosComprobantes/${id}`),
};

const CuentasDeClientes = {
    list: () => requests.get<CuentaDeCliente[]>("/cuentasDeClientes"),
    details: (id: number) =>
        requests.get<CuentaDeCliente>(`/cuentasDeClientes/${id}`),
    create: (cuentaDeCliente: CuentaDeCliente) =>
        requests.post<void>("/cuentasDeClientes", cuentaDeCliente),
    update: (cuentaDeCliente: CuentaDeCliente) =>
        requests.put<void>(
            `/cuentasDeClientes/${cuentaDeCliente.id}`,
            cuentaDeCliente
        ),
    delete: (id: number) => requests.del<void>(`/cuentasDeClientes/${id}`),
};
const CuentasDeClientesTipos = {
    list: () => requests.get<CuentaDeClienteTipo[]>("/cuentasDeClientesTipos"),
    details: (id: number) =>
        requests.get<CuentaDeClienteTipo>(`/cuentasDeClientesTipos/${id}`),
    create: (cuentaDeClienteTipo: CuentaDeClienteTipo) =>
        requests.post<void>("/cuentasDeClientesTipos", cuentaDeClienteTipo),
    update: (cuentaDeClienteTipo: CuentaDeClienteTipo) =>
        requests.put<void>(`/cuentasDeClientesTipos/${cuentaDeClienteTipo.id}`, {
            cuentaDeClienteTipo,
        }),
    delete: (id: number) => requests.del<void>(`/cuentasDeClientesTipos/${id}`),
};

const ConceptosClientes = {
    list: () => requests.get<ConceptoCliente[]>("/conceptosClientes"),
    details: (id: number) =>
        requests.get<ConceptoCliente>(`/conceptosClientes/${id}`),
    create: (conceptoCliente: ConceptoCliente) =>
        requests.post<void>("/conceptosClientes", conceptoCliente),
    update: (conceptoCliente: ConceptoCliente) =>
        requests.put<void>(
            `/conceptosClientes/${conceptoCliente.id}`,
            conceptoCliente
        ),
    delete: (id: number) => requests.del<void>(`/conceptosClientes/${id}`),
};

const ConceptosClientesTipos = {
    list: () => requests.get<ConceptoClienteTipo[]>("/conceptosClientesTipos"),
    details: (id: number) =>
        requests.get<ConceptoClienteTipo>(`/conceptosClientesTipos/${id}`),
    create: (conceptoClienteTipo: ConceptoClienteTipo) =>
        requests.post<void>("/conceptosClientesTipos", conceptoClienteTipo),
    update: (conceptoClienteTipo: ConceptoClienteTipo) =>
        requests.post<void>(`/conceptosClientesTipos/${conceptoClienteTipo.id}`, {
            conceptoClienteTipo,
        }),
    delete: (id: number) => requests.del<void>(`/conceptosClientesTipos/${id}`),
};

const MovimientoClientes = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<MovimientoCliente[]>(
        "/movimientosClientes/GetFolioMovimiento", filtro),
    list: () => requests.get<MovimientoCliente[]>("/movimientoCliente"),
    obtenerMovimientoPorClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<MovimientoCliente[]>("/movimientosClientes/GetObtenerMovimientoClienteDetallePorClave", filtro),
    details: (id: number) =>
        requests.get<MovimientoCliente>(`/movimientosClientes/${id}`),
    create: (movimientoCliente: MovimientoCliente[]) =>
        requests.post<void>("/movimientosClientes", movimientoCliente),
    update: (movimientoCliente: MovimientoCliente) =>
        requests.post<void>(`/movimientosClientes/${movimientoCliente.id}`, {
            movimientoCliente,
        }),
    delete: (id: number) => requests.del<void>(`/movimientosClientes/${id}`),
};

const CapturasPagos = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<CapturaPagos[]>(
        "/capturaPagos/GetFolioMovimiento", filtro),
    list: () => requests.get<CapturaPagos[]>("/capturaPagos"),
    getCapturaPagosByClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<CapturaPagos>("/capturaPagos/getCapturaPagoByClave", filtro),
    details: (id: number) =>
        requests.get<CapturaPagos>(`/capturaPagos/${id}`),
    create: (capturaPagos: CapturaPagos[]) =>
        requests.post<void>("/capturaPagos", capturaPagos),
    update: (capturaPagos: CapturaPagos) =>
        requests.post<void>(`/capturaPagos/${capturaPagos.id}`, {
            capturaPagos,
        }),
    delete: (id: number) => requests.del<void>(`/capturaPagos/${id}`),
    deletePagoAplicado: (id: number) => requests.del<void>(`/capturaPagos/pagoAplicado/${id}`),
};

const EstadoCuentas = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<EstadoCuenta[]>(
        "/estadoCuenta/GetFolioMovimiento", filtro),
    list: () => requests.get<EstadoCuenta[]>("/estadoCuenta"),
    getEstadoCuentaByClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<EstadoCuenta>("/estadoCuenta/getEstadoCuentaByClave", filtro),
    details: (id: number) =>
        requests.get<EstadoCuenta>(`/estadoCuenta/${id}`),
    create: (estadoCuenta: EstadoCuenta[]) =>
        requests.post<void>("/estadoCuenta", estadoCuenta),
    update: (estadoCuenta: EstadoCuenta) =>
        requests.post<void>(`/estadoCuenta/${estadoCuenta.importe}`, {
            estadoCuenta,
        }),
    delete: (id: number) => requests.del<void>(`/estadoCuenta/${id}`),
    deleteTotalesMesuales: (id: number) => requests.del<void>(`/estadoCuenta/totalesMensuales/${id}`),
};

const EstadoCuentasAlmacenes = {
  obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<EstadoCuenta[]>("/estadoCuentaAlmacen/GetFolioMovimiento", filtro),
  list: () => requests.get<EstadoCuenta[]>("/estadoCuentaAlmacen"),
  getEstadoCuentaByClave: (filtro: PaginationSpecParams) => requests.getPagination<EstadoCuenta>("/estadoCuentaAlmacen/getEstadoCuentaAlmacenByClave", filtro),
  details: (id: number) => requests.get<EstadoCuenta>(`/estadoCuentaAlmacen/${id}`),
  create: (estadoCuenta: EstadoCuenta[]) => requests.post<void>("/estadoCuentaAlmacen", estadoCuenta),
  update: (estadoCuenta: EstadoCuenta) => requests.post<void>(`/estadoCuentaAlmacen/${estadoCuenta.importe}`, { estadoCuenta, }),
  delete: (id: number) => requests.del<void>(`/estadoCuenta/${id}`),
  deleteTotalesMesuales: (id: number) => requests.del<void>(`/estadoCuenta/totalesMensuales/${id}`),
};

const VerificadorGestionClientes = {
    imprimirVerificadorGestionClientes: (filtro: VerificadorGestionCliente) => requests.getPagination<string>(
        "/verificadorGestionCliente/GetReporteVentas", filtro)
}

const VerificadorPagos = {
    imprimirVerificadorPagos: (filtro: VerificadorPago) => requests.getPagination<string>(
        "/VerificadorPagos/GetReporte", filtro)
}

const SaldoClientes = {
    imprimirSaldoClientes: (filtro: SaldoCliente) => requests.getReportes<any>(
        "/saldoCliente/GetReporteSaldoCliente", filtro)
}

const ReportesClientes = {
  imprimirAnaliticaClientes: (filtro: VerificadorGestionCliente) => requests.getReportes<any>(
    "/movimientosClientes/GetReporteAnaliticaCliente", filtro)

}

const ReportesGestionProveedores = {
    imprimirReporteCuentaProveedor: (filtro: ReporteVentas) => requests.getPagination<ReporteVentas[]>(
        "/CuentasDeProveedores/GetReporteEmpresas", filtro),
    imprimirAnaliticaProveedores: (filtro: VerificadorGestionProveedor) => requests.getPagination<VerificadorGestionProveedor[]>(
        "/MovimientosDeProveedores/GetReporteAnaliticaProveedor", filtro)
}

const Almacenes = {
    list: () => requests.get<Almacen[]>("/almacenes"),
    details: (id: number) => requests.get<Almacen>(`/almacenes/${id}`),
    create: (almacen: Almacen) => requests.post<void>("/almacenes", almacen),
    update: (almacen: Almacen) =>
        requests.put<void>(`/almacenes/${almacen.id}`, almacen),
    delete: (id: number) => requests.del<void>(`/almacenes/${id}`),
};

const AlmacenesTipos = {
    list: () => requests.get<AlmacenTipo[]>("/almacenesTipos"),
    details: (id: number) => requests.get<AlmacenTipo>(`/almacenesTipos/${id}`),
    create: (almacenTipo: AlmacenTipo) =>
        requests.post<void>("/almacenesTipos", almacenTipo),
    update: (almacenTipo: AlmacenTipo) =>
        requests.post<void>(`/almacenesTipos/${almacenTipo.id}`, { almacenTipo }),
    delete: (id: number) => requests.del<void>(`/almacenesTipos/${id}`),
};

const ConceptosDeInventarios = {
    list: () => requests.get<ConceptoDeInventario[]>("/conceptosDeInventarios"),
    details: (id: number) =>
        requests.get<ConceptoDeInventario>(`/conceptosDeInventarios/${id}`),
    create: (conceptoDeInventario: ConceptoDeInventario) =>
        requests.post<void>("/conceptosDeInventarios", conceptoDeInventario),
    update: (conceptoDeInventario: ConceptoDeInventario) =>
        requests.put<void>(
            `/conceptosDeInventarios/${conceptoDeInventario.id}`,
            conceptoDeInventario
        ),
    delete: (id: number) => requests.del<void>(`/conceptosDeInventarios/${id}`),
};

const ConceptosDeInventariosTipos = {
    list: () => requests.get<ConceptoDeInventarioTipo[]>("/conceptosDeInventariosTipos"), details: (id: number) =>
        requests.get<ConceptoDeInventarioTipo>(`/conceptosDeInventariosTipos/${id}`),
    create: (conceptoDeInventarioTipo: ConceptoDeInventarioTipo) => requests.post<void>("/conceptosDeInventariosTipos",
        conceptoDeInventarioTipo),
    update: (conceptoDeInventarioTipo: ConceptoDeInventarioTipo) => requests.put<void>(`/conceptosDeInventariosTipos/${conceptoDeInventarioTipo.id}`,
        conceptoDeInventarioTipo),
    delete: (id: number) => requests.del<void>(`/conceptosDeInventariosTipos/${id}`)
};

const MovimientosInventarios = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<MovimientoInventario[]>(
        "/movimientosAlmacenes/GetFolioMovimiento", filtro),
    obtenerMovimientoPorClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<MovimientoInventario>("/movimientosAlmacenes/GetObtenerMovimientoPorClave", filtro),
    list: () => requests.get<MovimientoInventario[]>("/movimientosAlmacenes"),
    details: (id: number) => requests.get<MovimientoInventario>(`/movimientosAlmacenes/${id}`),
    create: (movimientoInventario: MovimientoInventario) => requests.post<void>("/movimientosAlmacenes", movimientoInventario),
    update: (movimientoInventario: MovimientoInventario) => requests.put<void>(`/movimientosAlmacenes/${movimientoInventario.id}`, movimientoInventario),
    delete: (id: number) => requests.del<void>(`/movimientosAlmacenes/${id}`)
};

const InventariosFisicos= {
  obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<InventarioFisico[]>(
    "/movimientosAlmacenes/GetFolioMovimiento", filtro),
  obtenerMovimientoPorClave: (filtro: PaginationSpecParams) =>
    requests.getPagination<InventarioFisico>("/inventariosFisicos/GetObtenerMovimientoPorClave", filtro),
  list: () => requests.get<InventarioFisico[]>("/movimientosAlmacenes"),
  details: (id: number) => requests.get<InventarioFisico>(`/movimientosAlmacenes/${id}`),
  create: (inventarioFisico: InventarioFisico) => requests.post<void>("/movimientosAlmacenes", inventarioFisico),
  update: (inventarioFisico: InventarioFisico) => requests.put<void>(`/movimientosAlmacenes/${inventarioFisico.id}`, inventarioFisico),
  delete: (id: number) => requests.del<void>(`/movimientosAlmacenes/${id}`)
};

const VerificadorAlmacenes = {
    imprimirVerificadorAlmacenes: (filtro: VerificadorAlmacen) => requests.getPagination<VerificadorAlmacen[]>(
        "/movimientosAlmacenes/GetVerificadorAlmacen", filtro)
};

const ReportesAlmacenes = {
    imprimirReporteKardex: (filtro: PaginationSpecParams) => requests.getReportes<any>(
        "/movimientosAlmacenes/GetKardex", filtro),
    imprimirReporteExistencia: (filtro: ReporteAlmacen) => requests.getPagination<ReporteAlmacen[]>(
        "/movimientosAlmacenes/GetExistencia", filtro)
}

const MovimientosDeProveedores = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<MovimientoDeProveedor[]>(
        "/movimientosDeProveedores/GetFolioMovimiento", filtro),
    list: () => requests.get<MovimientoDeProveedor[]>("/movimientosDeProveedores"),
    obtenerMovimientoPorClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<MovimientoDeProveedor>("/movimientosDeProveedores/GetObtenerMovimientoPorClave", filtro),
    details: (id: number) =>
        requests.get<MovimientoDeProveedor>(`/movimientosDeProveedores/${id}`),
    create: (movimientoDeProveedor: MovimientoDeProveedor) =>
        requests.post<void>("/movimientosDeProveedores", movimientoDeProveedor),
    update: (movimientoDeProveedor: MovimientoDeProveedor) =>
        requests.put<void>(`/movimientosDeProveedores/${movimientoDeProveedor.id}`, {
            movimientoDeProveedor
        }),
    delete: (id: number) => requests.del<void>(`/movimientosDeProveedores/${id}`)
};

const Proveedores = {
    list: () => requests.get<Proveedor[]>("/proveedores"),
    listByEmpresa: (empresaSucursal: PaginationSpecParams) =>
        requests.getPagination<Proveedor[]>(
            "/proveedores/GetProveedoresByEmpresa",
            empresaSucursal
        ),
    details: (id: number) => requests.get<Proveedor>(`/proveedores/${id}`),
    create: (proveedor: Proveedor) =>
        requests.post<void>("/proveedores", proveedor),
    update: (proveedor: Proveedor) =>
        requests.put<void>(`/proveedores/${proveedor.id}`, { proveedor }),
    delete: (id: number) => requests.del<void>(`/proveedores/${id}`),
    obtenerClave: (filtro: PaginationSpecParams) => requests.getPagination<number>(
        "/proveedores/GetClaveProveedor", filtro),
    obtenerProveedorPorClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<Proveedor>("/proveedores/ObtenerProveedorPorClave",
            filtro
        ),
};

const ProveedorTipos = {
    list: () => requests.get<ProveedorTipo[]>("/proveedorTipos"),
    details: (id: number) => requests.get<ProveedorTipo>(`/proveedorTipos/${id}`),
    create: (proveedorTipo: ProveedorTipo) =>
        requests.post<void>("/proveedorTipos", proveedorTipo),
    update: (proveedorTipo: ProveedorTipo) =>
        requests.post<void>(`/proveedorTipos/${proveedorTipo.id}`, {
            proveedorTipo,
        }),
    delete: (id: number) => requests.del<void>(`/proveedorTipos/${id}`),
};

const CuentasDeProveedores = {
    list: () => requests.get<CuentaDeProveedor[]>("/cuentasDeProveedores"),
    details: (id: number) => requests.get<CuentaDeProveedor>(`/cuentasDeProveedores/${id}`),
    create: (cuentaDeProveedor: CuentaDeProveedor) => requests.post<void>("/cuentasDeProveedores", cuentaDeProveedor),
    update: (cuentaDeProveedor: CuentaDeProveedor) => requests.post<void>(`/cuentasDeProveedores/${cuentaDeProveedor.id}`, { cuentaDeProveedor, }),
    delete: (id: number) => requests.del<void>(`/cuentasDeProveedores/${id}`),
};

const CuentasDeProveedoresTipos = {
    list: () => requests.get<CuentaDeProveedorTipo[]>("/cuentasDeProveedoresTipos"),
    details: (id: number) => requests.get<CuentaDeProveedorTipo>(`/cuentasDeProveedoresTipos/${id}`),
    create: (cuentaDeProveedorTipo: CuentaDeProveedorTipo) => requests.post<void>("/cuentasDeProveedoresTipos", cuentaDeProveedorTipo),
    update: (cuentaDeProveedorTipo: CuentaDeProveedorTipo) => requests.post<void>(`/cuentasDeProveedoresTipos/${cuentaDeProveedorTipo.id}`, { cuentaDeProveedorTipo, }),
    delete: (id: number) => requests.del<void>(`/cuentasDeProveedoresTipos/${id}`),
};

const ConceptosDeProveedores = {
    list: () => requests.get<ConceptoDeProveedor[]>("/conceptosDeProveedores"),
    details: (id: number) => requests.get<ConceptoDeProveedor>(`/conceptosDeProveedores/${id}`),
    create: (conceptoDeProveedor: ConceptoDeProveedor) => requests.post<void>("/conceptosDeProveedores", conceptoDeProveedor),
    update: (conceptoDeProveedor: ConceptoDeProveedor) => requests.post<void>(`/conceptosDeProveedores/${conceptoDeProveedor.id}`, { conceptoDeProveedor, }),
    delete: (id: number) => requests.del<void>(`/conceptosDeProveedores/${id}`),
};

const ConceptosDeProveedoresTipos = {
    list: () => requests.get<ConceptoDeProveedorTipo[]>("/conceptosDeProveedoresTipos"),
    details: (id: number) => requests.get<ConceptoDeProveedorTipo>(`/conceptosDeProveedoresTipos/${id}`),
    create: (conceptoDeProveedorTipo: ConceptoDeProveedorTipo) => requests.post<void>("/conceptosDeProveedoresTipos", conceptoDeProveedorTipo),
    update: (conceptoDeProveedorTipo: ConceptoDeProveedorTipo) => requests.post<void>(`/conceptosDeProveedoresTipos/${conceptoDeProveedorTipo.id}`, { conceptoDeProveedorTipo }),
    delete: (id: number) => requests.del<void>(`/conceptosDeProveedoresTipos/${id}`),
};

const VerificadorGestionProveedores = {
    imprimirVerificadorGestionProveedores: (filtro: VerificadorGestionProveedor) => requests.getPagination<VerificadorGestionProveedor[]>(
        "/MovimientosDeProveedores/GetVerificadorGestionProveedores", filtro)
}

const TiposDeCompras = {
    list: () => requests.get<TipoDeCompra[]>("/tiposDeCompras"),
    details: (id: number) => requests.get<TipoDeCompra>(`/tiposDeCompras/${id}`),
    create: (tipoDeCompra: TipoDeCompra) => requests.post<void>("/tiposDeCompras", tipoDeCompra),
    update: (tipoDeCompra: TipoDeCompra) => requests.put<void>(`/tiposDeCompras/${tipoDeCompra.id}`, tipoDeCompra),
    delete: (id: number) => requests.del<void>(`/tiposDeCompras/${id}`),
};

const TiposDeCompraTipos = {
    list: () => requests.get<TipoDeCompraTipo[]>("/tiposDeComprasTipos"),
    details: (id: number) => requests.get<TipoDeCompraTipo>(`/tiposDeComprasTipos/${id}`),
    create: (tipoDeCompraTipo: TipoDeCompraTipo) => requests.post<void>("/tiposDeComprasTipos", tipoDeCompraTipo),
    update: (tipoDeCompraTipo: TipoDeCompraTipo) => requests.put<void>(`/tiposDeComprasTipos/${tipoDeCompraTipo.id}`, tipoDeCompraTipo,),
    delete: (id: number) => requests.del<void>(`/tiposDeComprasTipos/${id}`),
};

const SeriesFoliosCompras = {
    list: () => requests.get<SerieFolioCompra[]>("/seriesFoliosCompras"),
    listByTipoCompra: (filtro: PaginationSpecParams) =>
        requests.getPagination<SerieFolioCompra[]>(
            "/SeriesFoliosCompras/GetSeriesFoliosByTipoCompra",
            filtro
        ),
    listByParams: (filtro: PaginationSpecParams, options = {}, signal?: AbortSignal) =>
        requests.getPaginationSignal<SerieFolioCompra[]>(
            "/seriesFoliosCompras/GetSeriesFoliosByTipoCompra", { ...filtro, ...options }, signal
        ),
    details: (id: number) =>
        requests.get<SerieFolioCompra>(`/seriesFoliosCompras/${id}`),
    claveByEmpresaSucursal: (empresaSucursal: PaginationSpecParams) =>
        requests.getPagination<SerieFolioCompra>(
            "/seriesFoliosCompras/GetSerieFolioCompraClaveByEmpresaSucursal",
            empresaSucursal
        ),
    create: (serieFolioCompra: FormData) =>
        requests.post<void>("/seriesFoliosCompras", serieFolioCompra),
    update: (serieFolioCompra: FormData, id: number) =>
        requests.put<void>(`/seriesFoliosCompras/${id}`,
            serieFolioCompra),
    delete: (id: number) => requests.del<void>(`/seriesFoliosCompras/${id}`),
};

const GeneracionComprobantesCompras = {
    list: () => requests.get<GeneracionComprobanteCompra[]>("/generacionComprobantesCompras"),
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<GeneracionComprobante[]>
        ("/generacionComprobantesCompras/GetFolioComprobanteCompras", filtro),
    obtenerComprobantePorFolio: (filtro: PaginationSpecParams) => requests.getPagination<GeneracionComprobanteCompra>
        ("/generacionComprobantesCompras/GetObtenerComprobanteCompraPorFolio", filtro),
    details: (id: number) => requests.get<GeneracionComprobanteCompra>
        (`/generacionComprobantesCompras/${id}`),
    create: (generacionComprobanteCompra: GeneracionComprobanteCompra) => requests.post<void>
        ("/generacionComprobantesCompras", generacionComprobanteCompra),
    update: (generacionComprobanteCompra: GeneracionComprobanteCompra) => requests.post<void>
        (`/generacionComprobantesCompras/${generacionComprobanteCompra.id}`, { generacionComprobanteCompra, }),
    delete: (id: number) => requests.del<void>
        (`/generacionComprobantesCompras/${id}`),
    imprimir: (idComprobante: number) => requests.getPagination<string>(
        "/generacionComprobantesCompras/getImprimirComprobantes", { idComprobante }),
    obtenerComprobante: (filtro: PaginationSpecParams) => requests.getPagination<GeneracionComprobanteCompra>
        ("/GeneracionComprobantesCompras/GetComprobante", filtro),
};

const FacturaGlobal = {
    getTickets: (filtro: PaginationSpecParams) =>
        requests.getPagination<Tickets[]>("/generacionComprobantesTicket/GetObtenerTickets",
            filtro
        ),
    create: (facturaGlobal: GeneracionCompronanteTickets) => requests.post<void>("/generacionComprobantesTicket", facturaGlobal),
};

const ReporteProductosVendidos = {
    imprimirReporteProductosVendidos: (filtro: PaginationSpecParams) => requests.getReportes<any>(
        "/productos/GetReporteProductosVendidos", filtro)
}

const ReporteProductosComprados = {
    imprimirReporteProductosComprados: (filtro: PaginationSpecParams) => requests.getReportes<any>(
        "/productos/GetReporteProductosComprados", filtro)
}

const CuentasDeVentas = {
    list: () => requests.get<CuentaDeVenta[]>("/cuentasVentas"),
    details: (id: number) => requests.get<CuentaDeVenta>(`/cuentasVentas/${id}`),
    create: (cuentaDeVenta: CuentaDeVenta) => requests.post<void>("/cuentasVentas", cuentaDeVenta),
    update: (cuentaDeVenta: CuentaDeVenta) => requests.put<void>(`/cuentasVentas/${cuentaDeVenta.id}`, cuentaDeVenta),
    delete: (id: number) => requests.del<void>(`/cuentasVentas/${id}`),
};

const CuentasDeVentasTipos = {
    list: () => requests.get<CuentaDeVentaTipo[]>("/cuentasVentasTipos"),
    details: (id: number) => requests.get<CuentaDeVentaTipo>(`/cuentasVentasTipos/${id}`),
    create: (cuentaDeVentaTipo: CuentaDeVentaTipo) => requests.post<void>("/cuentasDeVentasTipos", cuentaDeVentaTipo),
    update: (cuentaDeVentaTipo: CuentaDeVentaTipo) => requests.put<void>(`/cuentasDeVentasTipos/${cuentaDeVentaTipo.id}`, { cuentaDeVentaTipo, }),
    delete: (id: number) => requests.del<void>(`/cuentasVentasTipos/${id}`),
};

const CuentasDeCompras = {
    list: () => requests.get<CuentaDeCompra[]>("/cuentasDeCompras"),
    details: (id: number) =>
        requests.get<CuentaDeCompra>(`/cuentasDeCompras/${id}`),
    create: (cuentaDeCompra: CuentaDeCompra) =>
        requests.post<void>("/cuentasDeCompras", cuentaDeCompra),
    update: (cuentaDeCompra: CuentaDeCompra) =>
        requests.put<void>(
            `/cuentasDeCompras/${cuentaDeCompra.id}`,
            cuentaDeCompra
        ),
    delete: (id: number) => requests.del<void>(`/cuentasDeCompras/${id}`),
};

const CuentasDeComprasTipos = {
    list: () => requests.get<CuentaDeCompraTipo[]>("/cuentasDeComprasTipos"),
    details: (id: number) =>
        requests.get<CuentaDeCompraTipo>(`/cuentasDeComprasTipos/${id}`),
    create: (cuentaDeCompraTipo: CuentaDeCompraTipo) =>
        requests.post<void>("/cuentasDeComprasTipos", cuentaDeCompraTipo),
    update: (cuentaDeCompraTipo: CuentaDeCompraTipo) =>
        requests.put<void>(
            `/cuentasDeComprasTipos/${cuentaDeCompraTipo.id}`,
            cuentaDeCompraTipo
        ),
    delete: (id: number) => requests.del<void>(`/cuentasDeComprasTipos/${id}`),
};

const Vehiculos = {
    list: () => requests.get<Vehiculo[]>("/vehiculos"),
    details: (id: number) => requests.get<Vehiculo>(`/vehiculos/${id}`),
    create: (vehiculo: Vehiculo) => requests.post<void>("/vehiculos", vehiculo),
    update: (vehiculo: Vehiculo) =>
        requests.put<void>(`/vehiculos/${vehiculo.id}`, vehiculo),
    delete: (id: number) => requests.del<void>(`/vehiculos/${id}`),
};

const VehiculoTipos = {
    list: () => requests.get<VehiculoTipo[]>("/vehiculosTipos"),
    details: (id: number) => requests.get<VehiculoTipo>(`/vehiculosTipos/${id}`),
    create: (vehiculoTipo: VehiculoTipo) =>
        requests.post<void>("/vehiculosTipos", vehiculoTipo),
    update: (vehiculoTipo: VehiculoTipo) =>
        requests.put<void>(`/vehiculosTipos/${vehiculoTipo.id}`, vehiculoTipo),
    delete: (id: number) => requests.del<void>(`/vehiculosTipos/${id}`),
};

const Departamentos = {
    list: () => requests.get<Departamento[]>("/departamentos"),
    details: (id: number) => requests.get<Departamento>(`/departamentos/${id}`),
    create: (departamento: Departamento) =>
        requests.post<void>("/departamentos", departamento),
    update: (departamento: Departamento) =>
        requests.put<void>(`/departamentos/${departamento.id}`, departamento),
    delete: (id: number) => requests.del<void>(`/departamentos/${id}`),
};

const Personales = {
    list: () => requests.get<Personal[]>("/personales"),
    details: (id: number) => requests.get<Personal>(`/personales/${id}`),
    create: (personal: Personal) => requests.post<void>("/personales", personal),
    update: (personal: Personal) =>
        requests.post<void>(`/personales/${personal.id}`, { personal }),
    delete: (id: number) => requests.del<void>(`/personales/${id}`),
};

const PersonalTipos = {
    list: () => requests.get<PersonalTipo[]>("/personalesTipos"),
    details: (id: number) => requests.get<PersonalTipo>(`/personalesTipos/${id}`),
    create: (personalTipo: PersonalTipo) =>
        requests.post<void>("/personalesTipos", personalTipo),
    update: (personalTipo: PersonalTipo) =>
        requests.put<void>(`/personalesTipos/${personalTipo.id}`, personalTipo),
    delete: (id: number) => requests.del<void>(`/personalesTipos/${id}`),
};

const ListasDePrecios = {
    list: () => requests.get<ListaDePrecio[]>("/listasDePrecios"),
    details: (id: number) => requests.get<ListaDePrecio>(`/listasDePrecios/${id}`),
    create: (listaDePrecio: ListaDePrecio) => requests.post<void>("/listasDePrecios", listaDePrecio),
    update: (listaDePrecio: ListaDePrecio) => requests.put<void>(`/listasDePrecios/${listaDePrecio.id}`, listaDePrecio),
    delete: (id: number) => requests.del<void>(`/listasDePrecios/${id}`),
};

const ListasDePreciosTipos = {
    list: () => requests.get<ListaDePrecioTipo[]>("/listasDePreciosTipos"),
    details: (id: number) =>
        requests.get<ListaDePrecioTipo>(`/listasDePreciosTipos/${id}`),
    create: (listaDePrecioTipo: ListaDePrecioTipo) =>
        requests.post<void>("/listasDePreciosTipos", listaDePrecioTipo),
    update: (listaDePrecioTipo: ListaDePrecioTipo) =>
        requests.put<void>(
            `/listasDePreciosTipos/${listaDePrecioTipo.id}`,
            listaDePrecioTipo
        ),
    delete: (id: number) => requests.del<void>(`/listasDePreciosTipos/${id}`),
};

const ControlesListasDePrecios = {
    list: () =>
        requests.get<ControlListaDePrecioArregloFormValues>(
            "/ControlesListasDePrecios"
        ),
    pagingList: (filtro: PaginationSpecParams) =>
        requests.getPagination<IPagination>(
            `/ControlesListasDePrecios/GetPaginationControlListaPrecio`,
            filtro
        ),
    details: (id: number) =>
        requests.get<ControlListaDePrecio>(`/ControlesListasDePrecios/${id}`),
    create: (controlListaDePrecio: ControlListaDePrecioArregloFormValues) =>
        requests.post<void>("/ControlesListasDePrecios", controlListaDePrecio),
    update: (controlListaDePrecio: ControlListaDePrecio) =>
        requests.put<void>(
            `/ControlesListasDePrecios/${controlListaDePrecio.id}`,
            controlListaDePrecio
        ),
    delete: (id: number) => requests.del<void>(`/ControlesListasDePrecios/${id}`),
};

const PoliticaDescuentos = {
    list: () => requests.get<PoliticaDescuento[]>("/politicaDescuentos"),
    details: (id: number) =>
        requests.get<PoliticaDescuento>(`/politicaDescuentos/${id}`),
    create: (politicaDescuento: PoliticaDescuento) =>
        requests.post<void>("/politicaDescuentos", politicaDescuento),
    update: (politicaDescuento: PoliticaDescuento) =>
        requests.put<void>(
            `/politicaDescuentos/${politicaDescuento.id}`,
            politicaDescuento
        ),
    delete: (id: number) => requests.del<void>(`/politicaDescuentos/${id}`),
};

const Clasificaciones = {
    list: () => requests.get<Clasificacion[]>("/clasificaciones"),
    details: (id: number) =>
        requests.get<Clasificacion>(`/clasificaciones/${id}`),
    create: (clasificacion: Clasificacion) =>
        requests.post<void>("/clasificaciones", clasificacion),
    update: (clasificacion: Clasificacion) =>
        requests.put<void>(`/clasificaciones/${clasificacion.id}`, clasificacion),
    delete: (id: number) => requests.del<void>(`/clasificaciones/${id}`),
};

const PagosDe = {
    list: () => requests.get<PagoDe[]>("/pagosDe"),
    details: (id: number) => requests.get<PagoDe>(`/pagosDe/${id}`),
    create: (pagoDe: PagoDe) => requests.post<void>("/pagosDe", pagoDe),
    update: (pagoDe: PagoDe) => requests.put<void>(`/pagosDe/${pagoDe.id}`, pagoDe),
    delete: (id: number) => requests.del<void>(`/pagosDe/${id}`),
};

const TiposSATs = {
    list: () => requests.get<tipoSAT[]>("/tiposSATs"),
    details: (id: number) => requests.get<tipoSAT>(`/tiposSATs/${id}`),
    create: (tipoSAT: tipoSAT) => requests.post<void>("/tiposSATs", tipoSAT),
    update: (tipoSAT: tipoSAT) => requests.put<void>(`/tiposSATs/${tipoSAT.id}`, tipoSAT),
    delete: (id: number) => requests.del<void>(`/tiposSATs/${id}`),
};

const RegimenesFiscales = {
    list: () => requests.get<cRegimenFiscal[]>("/cRegimenesFiscales"),
    details: (id: number) => requests.get<cRegimenFiscal>(`/cRegimenesFiscales/${id}`),
    create: (cRegimenFiscal: cRegimenFiscal) => requests.post<void>("/cRegimenesFiscales", cRegimenFiscal),
    update: (cRegimenFiscal: cRegimenFiscal) => requests.put<void>(`/cRegimenesFiscales/${cRegimenFiscal.id}`, cRegimenFiscal),
    delete: (id: number) => requests.del<void>(`/cRegimenesFiscales/${id}`),
};

const TiposMovimientos = {
    list: () => requests.get<TipoMovimiento[]>("/tiposMovimientos"),
    details: (id: number) => requests.get<TipoMovimiento>(`/tiposMovimientos/${id}`),
    create: (tipoMovimiento: TipoMovimiento) => requests.post<void>("/tiposMovimientos", tipoMovimiento),
    update: (tipoMovimiento: TipoMovimiento) => requests.put<void>(`/tiposMovimientos/${tipoMovimiento.id}`, tipoMovimiento),
    delete: (id: number) => requests.del<void>(`/tiposMovimientos/${id}`),
};

/******************************Finanzas***************************/
/*****************************************************************/
const CuentasFinanzas = {
    list: () => requests.get<CuentaFinanza[]>("/cuentasFinanzas"),
    details: (id: number) => requests.get<CuentaFinanza>(`/cuentasFinanzas/${id}`),
    create: (cuentaFinanza: CuentaFinanza) => requests.post<void>("/cuentasFinanzas", cuentaFinanza),
    update: (cuentaFinanza: CuentaFinanza) => requests.put<void>(`/cuentasFinanzas/${cuentaFinanza.id}`, cuentaFinanza),
    delete: (id: number) => requests.del<void>(`/cuentasFinanzas/${id}`),
};

const CuentasFinanzasTipos = {
    list: () => requests.get<CuentaFinanzaTipo[]>("/cuentasFinanzasTipos"),
    details: (id: number) => requests.get<CuentaFinanzaTipo>(`/cuentasFinanzasTipos/${id}`),
    create: (cuentaFinanzaTipo: CuentaFinanzaTipo) => requests.post<void>("/cuentasFinanzasTipos", cuentaFinanzaTipo),
    update: (cuentaFinanzaTipo: CuentaFinanzaTipo) => requests.put<void>(`/cuentasFinanzasTipos/${cuentaFinanzaTipo.id}`, { cuentaFinanzaTipo, }),
    delete: (id: number) => requests.del<void>(`/cuentasFinanzasTipos/${id}`),
};

const ConceptosFinanzas = {
    list: () => requests.get<ConceptoFinanza[]>("/conceptosFinanzas"),
    details: (id: number) => requests.get<ConceptoFinanza>(`/conceptosFinanzas/${id}`),
    create: (conceptoFinanza: ConceptoFinanza) => requests.post<void>("/conceptosFinanzas", conceptoFinanza),
    update: (conceptoFinanza: ConceptoFinanza) => requests.put<void>(`/conceptosFinanzas/${conceptoFinanza.id}`, conceptoFinanza),
    delete: (id: number) => requests.del<void>(`/cuentasFinanzas/${id}`),
};

const ConceptosFinanzasTipos = {
    list: () => requests.get<ConceptoFinanzaTipo[]>("/conceptosFinanzasTipos"),
    details: (id: number) => requests.get<ConceptoFinanzaTipo>(`/conceptosFinanzasTipos/${id}`),
    create: (conceptoFinanzaTipo: ConceptoFinanzaTipo) => requests.post<void>("/conceptosFinanzasTipos", conceptoFinanzaTipo),
    update: (conceptoFinanzaTipo: ConceptoFinanzaTipo) => requests.put<void>(`/conceptosFinanzasTipos/${conceptoFinanzaTipo.id}`, { conceptoFinanzaTipo, }),
    delete: (id: number) => requests.del<void>(`/conceptosFinanzasTipos/${id}`),
};

const Bancos = {
    list: () => requests.get<Banco[]>("/bancos"),
    details: (id: number) => requests.get<Banco>(`/bancos/${id}`),
    create: (banco: Banco) => requests.post<void>("/bancos", banco),
    update: (banco: Banco) => requests.put<void>(`/bancos/${banco.id}`, { banco, }),
    delete: (id: number) => requests.del<void>(`/bancos/${id}`),
};

const CuentasBancarias = {
    list: () => requests.get<CuentaBancaria[]>("/cuentasBancarias"),
    details: (id: number) => requests.get<CuentaBancaria>(`/cuentasBancarias/${id}`),
    create: (cuentaBancaria: CuentaBancaria) => requests.post<void>("/cuentasBancarias", cuentaBancaria),
    update: (cuentaBancaria: CuentaBancaria) => requests.put<void>(`/cuentasBancarias/${cuentaBancaria.id}`, { cuentaBancaria, }),
    delete: (id: number) => requests.del<void>(`/cuentasBancarias/${id}`),
}

const BancosSats = {
    list: () => requests.get<BancoSat[]>("/bancosSats"),
    details: (id: number) => requests.get<BancoSat>(`/bancosSats/${id}`),
    create: (bancoSat: BancoSat) => requests.post<void>("/bancosSats", bancoSat),
    update: (bancoSat: BancoSat) => requests.put<void>(`/bancosSats/${bancoSat.id}`, { bancoSat, }),
    delete: (id: number) => requests.del<void>(`/bancosSats/${id}`),
};

const MovimientosFinanzas = {
    obtenerFolio: (filtro: PaginationSpecParams) => requests.getPagination<MovimientoFinanza[]>(
        "/movimientosFinanzas/GetFolioMovimiento", filtro),
    list: () => requests.get<MovimientoFinanza[]>("/movimientosFinanzas"),
    obtenerMovimientoPorClave: (filtro: PaginationSpecParams) =>
        requests.getPagination<MovimientoFinanza>("/movimientosFinanzas/GetObtenerMovimientoPorClave", filtro),
    details: (id: number) => requests.get<MovimientoFinanza>(`/movimientosFinanzas/${id}`),
    create: (movimientoFinanza: MovimientoFinanza) =>
        requests.post<void>("/movimientosFinanzas", movimientoFinanza),
    update: (movimientoFinanza: MovimientoFinanza) =>
        requests.post<void>(`/movimientosFinanzas/${movimientoFinanza.id}`, { movimientoFinanza, }),
    delete: (id: number) => requests.del<void>(`/movimientosFinanzas/${id}`),
};

const C_Meses = {
    list: () => requests.get<c_Mes[]>("/c_Meses"),
    details: (id: number) => requests.get<c_Mes>(`/c_Meses/${id}`),
    create: (c_Mes: c_Mes) => requests.post<void>("/c_Meses", c_Mes),
    update: (c_Mes: c_Mes) => requests.put<void>(`/c_Meses/${c_Mes.id}`, { c_Mes, }),
    delete: (id: number) => requests.del<void>(`/c_Meses/${id}`),
};

const C_TiposRelaciones = {
    list: () => requests.get<c_TipoRelacion[]>("/c_TiposRelaciones"),
    details: (id: number) => requests.get<c_TipoRelacion>(`/c_TiposRelaciones/${id}`),
    create: (c_TipoRelacion: c_TipoRelacion) => requests.post<void>("/c_TiposRelaciones", c_TipoRelacion),
    update: (c_TipoRelacion: c_TipoRelacion) => requests.put<void>(`/c_TiposRelaciones/${c_TipoRelacion.id}`, { c_TipoRelacion, }),
    delete: (id: number) => requests.del<void>(`/c_TiposRelaciones/${id}`),
};

const TiposNotasCreditos = {
    list: () => requests.get<TipoNotaCredito[]>("/TiposNotasCreditos"),
    details: (id: number) => requests.get<TipoNotaCredito>(`/TiposNotasCreditos/${id}`),
    create: (tipoNotaCredito: TipoNotaCredito) => requests.post<void>("/TiposNotasCreditos", tipoNotaCredito),
    update: (tipoNotaCredito: TipoNotaCredito) => requests.put<void>(`/TiposNotasCreditos/${tipoNotaCredito.id}`, { tipoNotaCredito, }),
    delete: (id: number) => requests.del<void>(`/TiposNotasCreditos/${id}`),
}

const C_Periodicidades = {
    list: () => requests.get<c_Periodicidad[]>("/c_Periodicidades"),
    details: (id: number) => requests.get<c_Periodicidad>(`/c_Periodicidades/${id}`),
    create: (c_Periodicidad: c_Periodicidad) => requests.post<void>("/c_Periodicidades", c_Periodicidad),
    update: (c_Periodicidad: c_Periodicidad) => requests.put<void>(`/c_Periodicidades/${c_Periodicidad.id}`, { c_Periodicidad, }),
    delete: (id: number) => requests.del<void>(`/c_Periodicidades/${id}`),
};

const C_MotivosCancelaciones = {
    list: () => requests.get<c_MotivoCancelacion[]>("/C_MotivosCancelaciones"),
    details: (id: number) => requests.get<c_MotivoCancelacion>(`/C_MotivosCancelaciones/${id}`),
    create: (c_MotivoCancelacion: c_MotivoCancelacion) => requests.post<void>("/C_MotivosCancelaciones", c_MotivoCancelacion),
    update: (c_MotivoCancelacion: c_MotivoCancelacion) => requests.put<void>(`/C_MotivosCancelaciones/${c_MotivoCancelacion.id}`, { c_MotivoCancelacion, }),
    delete: (id: number) => requests.del<void>(`/C_MotivosCancelaciones/${id}`),
};

const InsertaInfoCatalogos = {
    create: (insetainfo: FormData) => requests.post<void>("/insertaObtenerInfoCatalogos", insetainfo),
};

const Account = {
    login: (values: any) => requests.post('account/login', values),
    register: (values: any) => requests.post('account/register', values),
    currentUser: () => requests.get('account/currentUser')
}
/*********************************************************** */
/*********************************************************** */
const agent = {
    Account,
    Empresas,
    Sucursales,
    Usuarios,
    UsuarioTipos,
    Perfiles,
    Monedas,
    MonedaTipos,
    MonedasSAT,
    Pacs,
    Paises,
    Estados,
    ListasDePrecios,
    ListasDePreciosTipos,
    Productos,
    TraspasoEntreSucursal,
    ControlesListasDePrecios,
    PoliticaDescuentos,
    CuentasDeVentas,
    CuentasDeVentasTipos,
    TiposVentas,
    TipoVentaTipos,
    SeriesFoliosDeVentas,
    SeriesFoliosTipos,
    GeneracionComprobantes,
    EnviarCorreos,
    FacturaGlobal,
    ReporteProductosVendidos,
    ReporteProductosComprados,
    CortesDeCajas,
    ReportesVentas,
    TiposNotasCreditos,
    Clientes,
    ClienteTipos,
    CondicionesPagos,
    FormasPagos,
    Impuestos,
    ImpuestoTipos,
    MetodosPagos,
    UsosCFDIs,
    ProductoTipos,
    UnidadesMedidas,
    CategoriasProductos,
    ImpuestosComprobantes,
    CuentasDeClientes,
    CuentasDeClientesTipos,
    ConceptosClientes,
    ConceptosClientesTipos,
    MovimientoClientes,
    CapturasPagos,
    EstadoCuentas,
    VerificadorGestionClientes,
    SaldoClientes,
    VerificadorPagos,
    ReportesClientes,

  Almacenes,
  AlmacenesTipos,
  ConceptosDeInventarios,
  ConceptosDeInventariosTipos,
  MovimientosInventarios,
  InventariosFisicos,
  ImpuestosProductos,
  VerificadorAlmacenes,
  ReportesAlmacenes,
  EstadoCuentasAlmacenes,

    Proveedores,
    ProveedorTipos,
    CuentasDeProveedores,
    CuentasDeProveedoresTipos,
    ConceptosDeProveedores,
    ConceptosDeProveedoresTipos,
    VerificadorGestionProveedores,
    CuentasDeCompras,
    CuentasDeComprasTipos,
    TiposDeCompras,
    TiposDeCompraTipos,
    SeriesFoliosCompras,
    GeneracionComprobantesCompras,
    ReportesCompras,
    Vehiculos,
    VehiculoTipos,
    Departamentos,
    Personales,
    PersonalTipos,
    Clasificaciones,
    PagosDe,
    RegimenesFiscales,
    TiposSATs,
    TiposMovimientos,
    MovimientosDeProveedores,
    CuentasFinanzas,
    CuentasFinanzasTipos,
    ConceptosFinanzas,
    ConceptosFinanzasTipos,
    Bancos,
    CuentasBancarias,
    BancosSats,
    MovimientosFinanzas,
    C_Meses,
    C_TiposRelaciones,

    C_MotivosCancelaciones,
    C_Periodicidades,
    ReportesConfiguraciones,
    Generales,
    ReportesGestionProveedores,

    InsertaInfoCatalogos,
};

export default agent;