import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import ProductoList from "./ProductoList";
import { useStore } from "../../../../app/stores/store";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ProductoForm from "../form/ProductoForm";
import NotFound from "../../../../app/layout/NotFound";

export default observer(function ProductoDashboard() {
  const { productoStore, tabsStore } = useStore();
  useEffect(() => {
    productoStore.loadProductos();
  }, [productoStore.loadProductos]);

  if (productoStore.loadingInitial)
    return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header
            as="h2"
            icon="dropbox"
            content="Productos y Servicios"
            color="blue"
          ></Header>
        </Grid.Column>
        <Grid.Column width={9}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Productos y Servicios', <ProductoForm id={0} />)}
              content="Crear Productos y Servicios" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Productos y Servicios', <NotFound />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => productoStore.loadProductos()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <ProductoList />
      </Grid.Column>
    </Grid>
  );
});