import { MonedaTipo } from "./MonedaTipo";
import { MonedaSAT } from "./MonedaSAT";
import { FechaObservacion, FechaObservacionValues } from "./FechaObservacion";
import { IdiomaDelImporteConLetras } from "./IdiomaDelImporteConLetras";

export interface Moneda {
  id: number;
  clave: number;
  nombre: string;
  nombreSingular: string;
  nombrePlural: string;
  leyendaDeCentavos: string;
  idiomaDelImporteConLetrasId: number;
  idiomaDelImporteConLetras?: IdiomaDelImporteConLetras;
  monedaTipoId: number;
  tipo?: MonedaTipo;
  monedaSATId: number;
  monedaSAT?: MonedaSAT;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}

export class MonedaFormValues implements Moneda {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  nombreSingular: string = "";
  nombrePlural: string = "";
  leyendaDeCentavos: string = "";
  idiomaDelImporteConLetrasId: number = 0;
  idiomaDelImporteConLetras?: IdiomaDelImporteConLetras;
  monedaTipoId: number = 0;
  tipo?: MonedaTipo;
  monedaSATId: number = 0;
  monedaSAT?: MonedaSAT;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion=new FechaObservacionValues();
  constructor(init?: Moneda) {
    Object.assign(this, init);
  }
}
