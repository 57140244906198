import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { GeneracionComprobanteCompra } from "../../models/compra/GeneracionComprobanteCompra";
//import { entityByDropdown } from "../common/funciones/entityByDropdown";

export default class GeneracionComprobanteCompraStore {
    generacionComprobanteCompraRegistry = new Map<number, GeneracionComprobanteCompra>();
    selectedGeneracionComprobanteCompra: GeneracionComprobanteCompra | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tipoCompraById() {
        return Array.from(this.generacionComprobanteCompraRegistry.values()).sort();
    }
    //loadSerieByIdTipoVenta 

    cargarComprobante = async (filtros: PaginationSpecParams) => {
        try {

            const generacionComprobanteCompra = await agent.GeneracionComprobantesCompras.obtenerComprobantePorFolio(filtros);

            if (generacionComprobanteCompra) {
                this.setGeneracionComprobanteCompra(generacionComprobanteCompra);
                //this.selectedGeneracionComprobante = generacionComprobante
                return generacionComprobanteCompra;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };


    obtenerSiguienteFolioCompra = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoCompraId && filtros.serieId) {
            try {
                const generacionComprobanteCompra = await agent.GeneracionComprobantesCompras.obtenerFolio(filtros);

                return generacionComprobanteCompra;

            } catch (error) {
                console.log(error);
            }
        } else {
            // this.serieFolioByTipoVentaRegistry.clear();
        }
    };


    get tiposComprasByDate() {
        return Array.from(this.generacionComprobanteCompraRegistry.values()).sort((a) => a.id);
    }


    loadGeneracionComprobantesCompras = async () => {
        this.setLoadingInitial(true);
        try {
            const generacionComprobantesCompras = await agent.GeneracionComprobantesCompras.list();
            generacionComprobantesCompras.forEach(generacionComprobanteCompra => {
                this.setGeneracionComprobanteCompra(generacionComprobanteCompra);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    getComprobante = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            const generacionComprobanteCompra = await agent.GeneracionComprobantesCompras.obtenerComprobante(filtros);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
            if (generacionComprobanteCompra) {
                return generacionComprobanteCompra;
            }

        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getGeneracionComprobantesCompras = async () => {
        this.setLoadingInitial(true);
        const generacionComprobantesCompras = await agent.GeneracionComprobantesCompras.list();
        try {
            generacionComprobantesCompras.forEach(generacionComprobanteCompra => {
                this.setGeneracionComprobanteCompra(generacionComprobanteCompra);
            })
            this.setLoadingInitial(false);
            return generacionComprobantesCompras;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return generacionComprobantesCompras;
        }
    }

    loadGeneracionComprobanteCompra = async (id: number) => {
        let generacionComprobanteCompra = this.getGeneracionComprobanteCompra(id);
        if (generacionComprobanteCompra) {
            this.selectedGeneracionComprobanteCompra = generacionComprobanteCompra;
            return generacionComprobanteCompra;
        } else {
            this.loadingInitial = true;
            try {
                generacionComprobanteCompra = await agent.GeneracionComprobantesCompras.details(id);
                this.setGeneracionComprobanteCompra(generacionComprobanteCompra);
                this.selectedGeneracionComprobanteCompra = generacionComprobanteCompra;
                this.setLoadingInitial(false);
                return generacionComprobanteCompra;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    imprimirComprobanteCompra = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                var nom = "";
                nom = await agent.GeneracionComprobantesCompras.imprimir(id);
                runInAction(() => { })
                this.loading = false;
                toast.success("Impresion de Comprobante con éxito");
                return nom;
            } catch (error) {
                toast.success("Problema al imprimir el Comprobante");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    }
    private setGeneracionComprobanteCompra = (generacionComprobanteCompra: GeneracionComprobanteCompra) => {
        this.generacionComprobanteCompraRegistry.set(generacionComprobanteCompra.id, generacionComprobanteCompra);
    }

    private getGeneracionComprobanteCompra = (id: number) => {
        return this.generacionComprobanteCompraRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createGeneracionComprobanteCompra = async (generacionComprobanteCompra: GeneracionComprobanteCompra) => {

        this.loading = true;
        try {
            await agent.GeneracionComprobantesCompras.create(generacionComprobanteCompra);
            runInAction(() => {
                this.selectedGeneracionComprobanteCompra = generacionComprobanteCompra;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Comprobante de Compra creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateGeneracionComprobanteCompra = async (generacionComprobanteCompra: GeneracionComprobanteCompra) => {
        this.loading = true;
        try {

            await agent.GeneracionComprobantesCompras.create(generacionComprobanteCompra);
            runInAction(() => {
                this.selectedGeneracionComprobanteCompra = generacionComprobanteCompra;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Comprobante de Compra Actualizado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteGeneracionComprobanteCompra = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.GeneracionComprobantesCompras.delete(id);
                runInAction(() => {
                    this.generacionComprobanteCompraRegistry.delete(id);
                    this.loading = false;
                })
                toast.success("Comprobante de Compra eliminada con éxito");

            } catch (error) {
                toast.success("Problema al eliminar Tipo Compra");
                console.log(error);
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    }
}
