import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { Usuario } from "../configuracion/Usuario";
import { CorteDeCajaEfectivo } from "./CorteDeCajaEfectivo";


export interface CorteDeCaja {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    cajeroId: number;
    cajero?: Usuario;
    fecha: Date;
    corteDeCajaEfectivoId:number;
    corteDeCajaEfectivo?:CorteDeCajaEfectivo;
    totalEfectivo:number;
    fondoFijo: number;
    saldoConteo: number;
    ventaContado:number;
    facturasContado:number;
}
export interface ICorteDeCajaFormValues extends Partial<CorteDeCaja> {
    selectedIdEmpresa: any;

}
export class CorteDeCajaFormValues implements ICorteDeCajaFormValues {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    cajeroId: number=0;
    cajero?: Usuario;
    fecha: Date= new Date();
    corteDeCajaEfectivoId:number=0;
    corteDeCajaEfectivo?:CorteDeCajaEfectivo;
    totalEfectivo:number=0;
    fondoFijo: number=0;
    saldoConteo: number =0;
    ventaContado:number =0;
    facturasContado:number=0;
    constructor(init?: CorteDeCaja) {
        Object.assign(this, init);
    }
}


