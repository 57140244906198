import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import CuentaDeCompraForm from '../form/CuentaDeCompraForm';

export default observer(function CuentaCompraList() {
    ///modal
    const { modalStore, tabsStore } = useStore();
    const { openModal, closeModal } = modalStore;
    //final modal
    const { cuentaDeCompraStore } = useStore();
    const { deleteCuentaDeCompra, cuentasDeComprasByDate, loading } = cuentaDeCompraStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteCuentaDeCompra(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Tipo</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {cuentasDeComprasByDate.map(cuentaDeCompra => (
                    <Table.Row key={cuentaDeCompra.id}>
                        <Table.Cell>{cuentaDeCompra.clave}</Table.Cell>
                        <Table.Cell>{cuentaDeCompra.nombre}</Table.Cell>
                        <Table.Cell>{cuentaDeCompra.cuentaDeCompraTipo?.nombre}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Crear cuenta de compras', <CuentaDeCompraForm id={cuentaDeCompra.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button
                                name={cuentaDeCompra.id}
                                loading={loading && target === String(cuentaDeCompra.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={cuentaDeCompra.id}
                                            nombre={cuentaDeCompra.nombre}
                                        />,
                                        enumHeaderModals.cuentaDeCompra
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
})