import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaFinanzaTipo } from "../../models/Finanza/CuentaFinanzaTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class CuentaFinanzaTipoStore {
    cuentaFinanzaTipoRegistry = new Map<number, CuentaFinanzaTipo>();
    selectedCuentaFinanzaTipo: CuentaFinanzaTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadCuentasFinanzasTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasFinanzasTipos = await agent.CuentasFinanzasTipos.list();
            cuentasFinanzasTipos.forEach(cuentaFinanzasTipo => {
                this.setCuentaFinanzaTipo(cuentaFinanzasTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasFinanzasTipos = async () => {
        this.setLoadingInitial(true);
        const cuentasFinanzasTipos = await agent.CuentasFinanzasTipos.list();
        try {
            cuentasFinanzasTipos.forEach(cuentaFinanzaTipo => {
                this.setCuentaFinanzaTipo(cuentaFinanzaTipo);
            })
            this.setLoadingInitial(false);
            return cuentasFinanzasTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasFinanzasTipos;
        }
    }

    loadCuentaFinanzaTipo = async (id: number) => {
        let cuentaFinanzaTipo = this.getCuentaFinanzaTipo(id);
        if (cuentaFinanzaTipo) {
            this.selectedCuentaFinanzaTipo = cuentaFinanzaTipo;
            return cuentaFinanzaTipo;

        } else {
            this.loadingInitial = true;
            try {
                cuentaFinanzaTipo = await agent.CuentasFinanzasTipos.details(id);
                this.selectedCuentaFinanzaTipo = cuentaFinanzaTipo;
                this.setLoadingInitial(false);
                return cuentaFinanzaTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaFinanzaTipo = (cuentaFinanzaTipo: CuentaFinanzaTipo) => {
        this.cuentaFinanzaTipoRegistry.set(cuentaFinanzaTipo.id, cuentaFinanzaTipo);
    }
    
    private getCuentaFinanzaTipo = (id: number) => {
        return this.cuentaFinanzaTipoRegistry.get(id);
    }
    
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaFinanzaTipo = async (cuentaFinanzaTipo: CuentaFinanzaTipo) => {
        this.loading = true;
        try {
            await agent.CuentasFinanzasTipos.create(cuentaFinanzaTipo);
            runInAction(() => {
                this.cuentaFinanzaTipoRegistry.set(cuentaFinanzaTipo.id, cuentaFinanzaTipo);
                this.selectedCuentaFinanzaTipo = cuentaFinanzaTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteCuentaFinanzaTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasFinanzasTipos.delete(id);
            runInAction(() => {
                this.cuentaFinanzaTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get cuentasFinanzasTipoByDropdown(){
        let cuentaFinanzaTipoArreg=[new TipoDropDownValues()];
        let tipoCuentasFinanzasOpt =new TipoDropDownValues();
        const cucltipos=  Array.from(this.cuentaFinanzaTipoRegistry.values()).sort(
          (a, b) =>  {  if (a.nombre > b.nombre) return 1
            if (a.nombre < b.nombre) return -1
            return 0}
        );
        cucltipos.forEach((element) => {
            tipoCuentasFinanzasOpt.key = element.id;
            tipoCuentasFinanzasOpt.text = element.nombre;
            tipoCuentasFinanzasOpt.value = element.id;
            tipoCuentasFinanzasOpt.content = element.nombre;
            cuentaFinanzaTipoArreg.push(tipoCuentasFinanzasOpt);
            tipoCuentasFinanzasOpt =new TipoDropDownValues();
          
        });
        return cuentaFinanzaTipoArreg;
    }
}
