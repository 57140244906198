import { observer } from 'mobx-react-lite'
import { Segment } from 'semantic-ui-react';
import BarraBotones from '../../../Component/BarraBotones';
import { useStore } from '../../../../app/stores/store';

const GenealesFooter: React.FC<{ lastElement: number; }> = observer(({ lastElement }) => {
    const { generalStore } = useStore();
    const { loading } = generalStore
    return (
            <Segment>
                <BarraBotones
                    toLink="/"
                    loading={loading}
                    tabIndex={lastElement + 1}
                    firstElement={0}
                    lastElement={lastElement + 3}
                ></BarraBotones>
            </Segment>
    )
});
export default GenealesFooter;