export interface FechaObservacion {
  id: number;
  alta?: Date;
  modificacion?: Date;
  baja?: Date;
  observaciones: string;
}
export interface FechaObservacionFormValues extends Partial<FechaObservacion> {
  time?: Date;
}

export class FechaObservacionValues implements FechaObservacionFormValues {
  id: number = 0;
  alta?: Date = undefined;
  modificacion?: Date = undefined;
  baja?: Date = undefined;
  observaciones: string = "";
  constructor(init?: FechaObservacion) {
    Object.assign(this, init);
  }
}
