import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { VerificadorAlmacen } from "../../models/inventario/VerificadorAlmacen";

export default class VerificadorInventarioStore {
    verificadorAlmacenRegistry = new Map<number, VerificadorAlmacen>();
    selectedVerificadorAlmacen: VerificadorAlmacen | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    imprimirVerificadorAlmacenes = async (filtros: VerificadorAlmacen) => {
        try {
            const verificadorAlmacen = await agent.VerificadorAlmacenes.imprimirVerificadorAlmacenes(filtros);
            if (verificadorAlmacen) {
                return verificadorAlmacen;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

}