import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ListaDePrecioTipo } from "../../models/controlPrecio/ListaDePrecioTipo";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class ListaDePrecioTipoStore {
  listaDePrecioTipoRegistry = new Map<number, ListaDePrecioTipo>();
  selectedListaDePrecioTipo: ListaDePrecioTipo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get listaDePrecioTipoById() {
    
    return Array.from(this.listaDePrecioTipoRegistry.values()).sort((a, b) => a.clave - b.clave);
  }
  private setListaDePrecioTipo = (listaDePrecioTipo: ListaDePrecioTipo) => {
    this.listaDePrecioTipoRegistry.set(listaDePrecioTipo.id, listaDePrecioTipo);
  };

  get ListaDePreciosTiposByDropdown(){
    let ListaDePreciosTiposArreg=[new TipoDropDownValues()];
    let ListaDePreciosTiposOpt =new TipoDropDownValues();
    const montipos=  Array.from(this.listaDePrecioTipoRegistry.values()).sort(
      (a, b) =>  {  if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0}
    );
    montipos.forEach((element) => {
      ListaDePreciosTiposOpt.key = element.id;
      ListaDePreciosTiposOpt.text = element.nombre;
      ListaDePreciosTiposOpt.value = element.id;
      ListaDePreciosTiposOpt.content = element.nombre;
      ListaDePreciosTiposArreg.push(ListaDePreciosTiposOpt);
      ListaDePreciosTiposOpt =new TipoDropDownValues();
      
    });
    return ListaDePreciosTiposArreg;
    }

  loadListaDePrecios = async () => {
    this.setLoadingInitial(true);
    try {
      debugger
      const listasDePrecios = await agent.ListasDePrecios.list();
      debugger
      listasDePrecios.forEach((listaDePrecio) => {
        this.setListaDePrecioTipo(listaDePrecio);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getListasDePreciosTipos = async () => {
    this.setLoadingInitial(true);
    const listasDePreciosTipos = await agent.ListasDePreciosTipos.list();
    
    try {
      listasDePreciosTipos.forEach((pac) => {
        this.setListaDePrecioTipo(pac);
      });
      this.setLoadingInitial(false);
      return listasDePreciosTipos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return listasDePreciosTipos;
    }
  };

  loadListaDePrecioTipo = async (id: number) => {
    let listaDePrecioTipo = this.getListasDePrecioTipo(id);
    if (listaDePrecioTipo) {
      this.selectedListaDePrecioTipo = listaDePrecioTipo;
      return listaDePrecioTipo;
    } else {
      this.loadingInitial = true;
      try {
        listaDePrecioTipo = await agent.ListasDePreciosTipos.details(id);
        this.setListaDePrecioTipo(listaDePrecioTipo);
        this.selectedListaDePrecioTipo = listaDePrecioTipo;
        this.setLoadingInitial(false);
        return listaDePrecioTipo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };


  get listaDePrecioTiposByDropdown(){
    let ListaDePrecioTiposArreg=[new TipoDropDownValues()];
    let listaDePrecioTiposOpt =new TipoDropDownValues();
    const cvts=  Array.from(this.listaDePrecioTipoRegistry.values()).sort(
      (a, b) =>  {  if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0}
    );
    debugger
    cvts.forEach((element) => {
      listaDePrecioTiposOpt.key = element.id;
      listaDePrecioTiposOpt.text = element.nombre;
      listaDePrecioTiposOpt.value = element.id;
      listaDePrecioTiposOpt.content = element.nombre;
      ListaDePrecioTiposArreg.push(listaDePrecioTiposOpt);
      listaDePrecioTiposOpt =new TipoDropDownValues();
      
    });
    return ListaDePrecioTiposArreg;
    }
  private getListasDePrecioTipo = (id: number) => {
    debugger
    return this.listaDePrecioTipoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createListaDePrecioTipo = async (listaDePrecioTipo: ListaDePrecioTipo) => {
    this.loading = true;
    try {
      await agent.ListasDePreciosTipos.create(listaDePrecioTipo);
      runInAction(() => {
        this.selectedListaDePrecioTipo = listaDePrecioTipo;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("lista De Precio Tipo creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateListaDePrecioTipo = async (listaDePrecioTipo: ListaDePrecioTipo) => {
    this.loading = true;
    try {
      
      await agent.ListasDePreciosTipos.update(listaDePrecioTipo);
      runInAction(() => {
        this.selectedListaDePrecioTipo = listaDePrecioTipo;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Lista De Precio Tipo modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteListaDePrecioTipo = async (id: number) => {
    this.loading = true;
    try {
      await agent.ListasDePreciosTipos.delete(id);
      runInAction(() => {
        this.listaDePrecioTipoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Lista De Precio Tipo eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar Lista De Precio Tipo");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
