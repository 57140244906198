import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { MetodoPago } from "../../models/sat/c_MetodoPago";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { cRegimenFiscal } from "../../models/sat/cRegimenFiscal";

export default class c_RegimenFiscalStore {
    regimenFiscalRegistry = new Map<number, cRegimenFiscal>();
    selectedRegimenFiscal: cRegimenFiscal | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get regimenesFiscalesByDropdown() {
        let regimenesFiscalesArreg = [new TipoDropDownValues()];
        let regimenesFiscalesOpt = new TipoDropDownValues();
        const cvts = Array.from(this.regimenFiscalRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            regimenesFiscalesOpt.key = element.id;
            regimenesFiscalesOpt.text = element.nombre;
            regimenesFiscalesOpt.value = element.id;
            regimenesFiscalesOpt.content = element.nombre;
            regimenesFiscalesArreg.push(regimenesFiscalesOpt);
            regimenesFiscalesOpt = new TipoDropDownValues();

        });
        return regimenesFiscalesArreg;
    }

    get regimenesFiscalesByDate() {
        return Array.from(this.regimenFiscalRegistry.values()).sort((a) => a.id);
    }


    loadRegimenesFiscales = async () => {
        this.setLoadingInitial(true);
        try {
            const regimenesFiscales = await agent.RegimenesFiscales.list();
            regimenesFiscales.forEach(regimenFiscal => {
                this.setRegimenFiscal(regimenFiscal);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getRegimenesFiscales = async () => {
        this.setLoadingInitial(true);
        debugger
        const RegimenesFiscales = await agent.RegimenesFiscales.list();
        try {
            RegimenesFiscales.forEach(regimFiscal => {
               this.setRegimenFiscal(regimFiscal);
            })
            this.setLoadingInitial(false);
            return RegimenesFiscales;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return RegimenesFiscales;
        }
    }







    loadRegimenFiscal = async (id: number) => {
        let regimenFiscal = this.getRegimenFiscal(id);
        if (regimenFiscal) {
            this.selectedRegimenFiscal = regimenFiscal;
            return regimenFiscal;
        } else {
            this.loadingInitial = true;
            try {
                regimenFiscal = await agent.RegimenesFiscales.details(id);
                this.setRegimenFiscal(regimenFiscal);
                this.selectedRegimenFiscal = regimenFiscal;
                this.setLoadingInitial(false);
                return regimenFiscal;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setRegimenFiscal = (RegimenFiscal: cRegimenFiscal) => {
        this.regimenFiscalRegistry.set(RegimenFiscal.id, RegimenFiscal);
    }

    private getRegimenFiscal = (id: number) => {
        return this.regimenFiscalRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRegimenFiscal = async (regimenFiscal: cRegimenFiscal) => {
        this.loading = true;
        try {
            await agent.RegimenesFiscales.create(regimenFiscal);
            runInAction(() => {
                this.regimenFiscalRegistry.set(regimenFiscal.id, regimenFiscal);
                this.selectedRegimenFiscal = regimenFiscal;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

 
    deleteRegimenFiscal = async (id: number) => {
        this.loading = true;
        try {
            await agent.RegimenesFiscales.delete(id);
            runInAction(() => {
                this.regimenFiscalRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
