import { ListaDePrecio, ListaDePrecioFormValues } from "../controlPrecio/ListaDePrecio";
import { Direccion, DireccionValues } from "./Direccion";
import { Empresa } from "./Empresa";
import { FechaObservacion, FechaObservacionValues } from "./FechaObservacion";

export interface Sucursal {
    id: number;
    clave:number;
    nombre: string;
    empresaId:number;
    empresa?:Empresa;
    listaDePrecioId:number;
    listaDePrecio:ListaDePrecio;
    codigoContable?: string;
    centroCosto?: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    direccionId: number;
    direccion: Direccion;
}
export interface ISucursalFormValues extends Partial<Sucursal> {
    selectedIdEmpresa: any;
}

export class SucursalFormValues implements ISucursalFormValues {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    empresaId:number= 0;
    empresa?:Empresa;
    listaDePrecioId:number=0;
    listaDePrecio:ListaDePrecio = new ListaDePrecioFormValues();
    codigoContable?: string="";
    centroCosto?: string="";
    selectedIdEmpresa: undefined;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    direccionId: number = 0;
    direccion: Direccion=new DireccionValues();
    constructor(init?: Sucursal) {
        Object.assign(this, init);
    }
}

