import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { UsoCFDI } from "../../models/sat/UsoCFDI";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class UsoCFDIStore {
    usoCFDIRegistry = new Map<number, UsoCFDI>();
    selectedUsoCFDI: UsoCFDI | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    get usosCFDIsByDropdown() {
        let usosCFDIsArreg = [new TipoDropDownValues()];
        let usosCFDIsOpt = new TipoDropDownValues();
        const cvts = Array.from(this.usoCFDIRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            usosCFDIsOpt.key = element.id;
            usosCFDIsOpt.text = element.nombre;
            usosCFDIsOpt.value = element.id;
            usosCFDIsOpt.content = element.nombre;
            usosCFDIsArreg.push(usosCFDIsOpt);
            usosCFDIsOpt = new TipoDropDownValues();

        });
        return usosCFDIsArreg;
    }
    get usosCFDIsByDate() {
        return Array.from(this.usoCFDIRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadUsosCFDIs = async () => {
        this.setLoadingInitial(true);
        try {
            const usosCFDIs = await agent.UsosCFDIs.list();
            usosCFDIs.forEach(usoCFDI => {
                this.setUsoCFDI(usoCFDI);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getUsosCFDIs = async () => {

        this.setLoadingInitial(true);
        const usosCFDIs = await agent.UsosCFDIs.list();
        try {
            usosCFDIs.forEach(usoCFDI => {
                this.setUsoCFDI(usoCFDI);
            })
            this.setLoadingInitial(false);
            return usosCFDIs;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return usosCFDIs;
        }
    }

    loadUsoCFDI = async (id: number) => {
        let usoCFDI = this.getUsoCFDI(id);
        if (usoCFDI) {
            this.selectedUsoCFDI = usoCFDI;
            return usoCFDI;

        } else {
            this.loadingInitial = true;
            try {
                usoCFDI = await agent.UsosCFDIs.details(id);
                this.setUsoCFDI(usoCFDI);
                this.selectedUsoCFDI = usoCFDI;
                this.setLoadingInitial(false);
                return usoCFDI;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    obtenerusosCFDIsPorRegimenFiscalCliente = async (idRegimen: number) => {

        const usosCFDIs = await agent.UsosCFDIs.getUsosCFDIPorIdRegimenFiscal(idRegimen);
        this.usoCFDIRegistryClear();
        try {
            usosCFDIs.forEach((uso) => {
                this.setUsoCFDI(uso);
            });
            this.setLoadingInitial(false);
            return usosCFDIs;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return usosCFDIs;
        }
    }
    
    public usoCFDIRegistryClear = () => {
        this.usoCFDIRegistry.clear();
    }

    private setUsoCFDI = (usoCFDI: UsoCFDI) => {
        this.usoCFDIRegistry.set(usoCFDI.id, usoCFDI);
    }

    private getUsoCFDI = (id: number) => {
        return this.usoCFDIRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createUsoCFDI = async (usoCFDI: UsoCFDI) => {
        this.loading = true;
        try {
            await agent.UsosCFDIs.create(usoCFDI);
            runInAction(() => {
                this.usoCFDIRegistry.set(usoCFDI.id, usoCFDI);
                this.selectedUsoCFDI = usoCFDI;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }


    deleteUsoCFDI = async (id: number) => {
        this.loading = true;
        try {
            await agent.UsosCFDIs.delete(id);
            runInAction(() => {
                this.usoCFDIRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
