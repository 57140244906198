import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { SerieFolioCompra } from "./SerieFolioCompra";
import { Moneda } from "../configuracion/Moneda";
import { CuentaDeCompra } from "./CuentaDeCompra";
import { Usuario } from "../configuracion/Usuario";
import { GeneracionComprobanteCompraDetalle, GeneracionComprobanteCompraDetalleFormValues } from "./GeneracionComprobanteCompraDetalle";
import { TipoDeCompra } from "./TipoDeCompra";
import { Proveedor } from "../gestionProveedor/Proveedor";
import { GeneracionComprobantePago } from "../venta/GeneracionComprobantePagos";


export interface GeneracionComprobanteCompra {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoCompraId: number;
    tipoCompra?: TipoDeCompra;
    serieFolioDeCompraId: number;
    serieFolioDeCompra?: SerieFolioCompra;
    folio: number;
    fecha: Date;
    fechaCancelacion?: Date;
    proveedorId: number;
    proveedor?: Proveedor;
    monedaId: number;
    moneda?: Moneda;
    tipoCambio: number;
    cuentaDeCompraId: number;
    cuentaDeCompra?: CuentaDeCompra;
    compradorId: number;
    comprador?: Usuario;
    trabajadorId: number;
    trabajador?: Usuario;
    facturaProveedor: string;
    fechaFacturaProveedor?: Date;
    comentarios: string;
    subtotal: number;
    iva: number;
    total: number;
    detalles?: [GeneracionComprobanteCompraDetalle];
    pago?: GeneracionComprobantePago;
}
export interface IGeneracionComprobanteCompraFormValues extends Partial<GeneracionComprobanteCompra> {
    selectedIdEmpresa: any;

}
export class GeneracionComprobanteCompraFormValues implements IGeneracionComprobanteCompraFormValues {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoCompraId: number = 0;
    tipoCompra?: TipoDeCompra;
    serieFolioDeCompraId: number = 0;
    serieFolioDeCompra?: SerieFolioCompra;
    folio: number = 0;
    fecha: Date = new Date();
    fechaCancelacion?: Date = undefined;;
    proveedorId: number = 0;
    proveedor?: Proveedor;
    monedaId: number = 0;
    moneda?: Moneda;
    tipoCambio: number = 0;
    cuentaDeCompraId: number = 0;
    cuentaDeCompra?: CuentaDeCompra;
    compradorId: number = 0;
    comprador?: Usuario;
    trabajadorId: number = 0;
    trabajador?: Usuario;
    facturaProveedor: string = "";
    fechaFacturaProveedor?: Date = undefined;
    comentarios: string = "";
    subtotal: number = 0;
    iva: number = 0;
    total: number = 0;
    detalles?: [GeneracionComprobanteCompraDetalle] = [new GeneracionComprobanteCompraDetalleFormValues()];

    constructor(init?: GeneracionComprobanteCompra) {
        Object.assign(this, init);
    }
}


