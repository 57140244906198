import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { PagoDe } from "../pagoDe";
import { CuentaDeCompraTipo } from "./CuentaDeCompraTipo";

export interface CuentaDeCompra {
    id: number;
    clave: number;
    nombre: string;
    abreviacion: string;
    cuentaDeCompraTipoId: number;
    cuentaDeCompraTipo?: CuentaDeCompraTipo;
    pagoDeId: number;
    pagoDe?: PagoDe;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export interface ICuentaDeCompraFormValues extends Partial<CuentaDeCompra> {
    cuentaDeCompraTipoId: any;
}

export class CuentaDeCompraFormValues implements ICuentaDeCompraFormValues {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    abreviacion: string="";
    cuentaDeCompraTipoId: number=0;
    cuentaDeCompraTipo?: CuentaDeCompraTipo;
    pagoDeId: number=0;
    pagoDe?: PagoDe;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    constructor(init?: CuentaDeCompra) {
        Object.assign(this, init);
    }
}