import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { General } from "../../models/configuracion/General";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";
import { history } from "../../..";
export default class GeneralStore {
    generalRegistry = new Map<number, General>();
    selectedUsoCFDI: General | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    generales = [];
    constructor() {
        makeAutoObservable(this)
    }
    fetchGeneralesByParams = async (filtros: PaginationSpecParams) => {
        try {
            const response = await agent.Generales.getByParams(filtros);
            //  this.generales = response;
            return response;
        } catch (error) {
            console.error('Error fetching generales', error);
            return undefined;
        }
    };
    createGeneral = async (general: General) => {
        this.loading = true;
        try {
            await agent.Generales.create(general);
            runInAction(() => {
                this.generalRegistry.set(general.id, general);
                this.selectedUsoCFDI = general;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("General creada con éxito");
            
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
