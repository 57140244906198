import { FechaObservacion } from "../../models/configuracion/FechaObservacion";
interface IProps<T> {
  item?: T
  fechaObservacion: FechaObservacion;
}
export const combineDateAndTime = (date: Date, time: Date) => {
  const timeString = time.getHours() + ":" + time.getMinutes() + ":00";
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateString = `${year}-${month}-${day}`;

  return new Date(dateString + " " + timeString);
};

export const setDate = <T>(props: IProps<T>) => {
  if (props.fechaObservacion) {
    props.fechaObservacion.alta = props.fechaObservacion?.alta
      ? new Date(props.fechaObservacion.alta)
      : undefined;
    props.fechaObservacion.baja = props.fechaObservacion?.baja
      ? new Date(props.fechaObservacion.baja)
      : undefined;
    props.fechaObservacion.modificacion = props.fechaObservacion?.modificacion
      ? new Date(props.fechaObservacion.modificacion)
      : undefined;
  }
  return props;
};

export const updateInputValue1 = (inputValue: string = "", key: string | number): string => {
  const keyString = key.toString(); // Convertir la clave a string
  const trimmedInput = inputValue?.trim(); // Eliminar espacios al inicio y al final

  // Validar si la clave ya existe en el valor del input
  if (trimmedInput?.includes(keyString)) {
    return inputValue;
  }

  // Validar si el valor del input termina con una coma
  if (trimmedInput?.endsWith(',')) {
    return `${trimmedInput}${keyString}`;
  }

  // Validar si el valor del input termina con un guion
  if (trimmedInput?.endsWith('-')) {
    return `${trimmedInput}${keyString}`;
  }

  // Si el valor del input no termina con coma ni guion, agregar una coma antes de la clave
  return trimmedInput ? `${trimmedInput},${keyString}` : keyString;
};
export const updateInputValue = (inputValue: string = "", key: string | number): string => {
  const keyString = key.toString(); // Convertir la clave a string
  const trimmedInput = inputValue.trim(); // Eliminar espacios al inicio y al final

  // Convertir el valor del input en una lista de claves, dividiendo por comas
  const keysArray = trimmedInput?.split(/[,|-]/)?.filter(Boolean);

  // Validar si la clave ya existe en el valor del input
  if (keysArray?.includes(keyString)) {
    return inputValue;
  }

  // Si el valor del input está vacío, simplemente devolver la clave
  if (trimmedInput === "") {
    return keyString;
  }

  // Si el valor del input no termina en coma o guion, agregar una coma antes de la clave
  if (!trimmedInput?.endsWith(',') && !trimmedInput?.endsWith('-')) {
    return `${trimmedInput},${keyString}`;
  }

  // Agregar la clave al final del valor del input
  return `${trimmedInput}${keyString}`;
};
