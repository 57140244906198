import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import MySelectInput from "../../../../app/common/form/MySelectInput";

import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";
import { CuentaFinanza, CuentaFinanzaFormValues } from "../../../../app/models/Finanza/CuentaFinanza";

export default observer(function CuentaFinanzaForm() {
  const firstElement = 0;
  const lastElement = 7;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { cuentaFinanzaStore, cuentaFinanzaTipoStore } = useStore();

  const {
    createCuentaFinanza,
    updateCuentaFinanza,
    loadCuentaFinanza,
    loadingInitial,
    cuentaFinanzaById,
    loading
  } = cuentaFinanzaStore;
  const { cuentasFinanzasTipoByDropdown, getCuentasFinanzasTipos } = cuentaFinanzaTipoStore;
  const { id } = useParams<{ id: string }>();
  const [cuentaFinanza, setCuentaFinanza] = useState(new CuentaFinanzaFormValues());
  const [cuentaFinanzaTipos, setCuentaFinanzaTipos] = useState([{ key: 0, value: 0, text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  useEffect(() => {
    getCuentasFinanzasTipos().then(cvts => { });
  }, [])


  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave de la cuenta de venta es requerida.'),
    nombre: Yup.string().required('El nombre de la cuenta de venta es requerido.'),
    cuentaDeVentaTipoId: Yup.number()
    .min(1, "Favor de seleccionar un tipo Cuenta de Venta.")
    .required("El tipo Cuenta de Venta es requerido."),
  });

  useEffect(() => {
    debugger
    if (id) {
      loadCuentaFinanza(Number.parseInt(id)).then(cuentaFinanza => {
        setCuentaFinanza(new CuentaFinanzaFormValues(cuentaFinanza))
        setFechaObservacion(
          new FechaObservacionValues(cuentaFinanza?.fechaObservacion)
        );
      }
      );
    } else {
      cuentaFinanza.clave =
        cuentaFinanzaById.map((cuentaFinanza) => cuentaFinanza.clave).length > 0
          ? Math.max(...cuentaFinanzaById.map((cuentaFinanza) => cuentaFinanza.clave)) + 1
          : 1;
    }
  }, []);



  function handleSubmit(cuentaFinanza: CuentaFinanza) {
    if (/*cuentaDeVenta.cuentaDeVentaTipoId.toString() !== "0" &&*/ cuentaFinanza.nombre !== null) {
      cuentaFinanza.id > 0 ? updateCuentaFinanza(cuentaFinanza) : createCuentaFinanza(cuentaFinanza);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Cuenta Finanza' />

  return (
    <Segment clearing>
      <Formik onSubmit={(values) => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={cuentaFinanza}
      >
        {({ isValid, dirty, handleSubmit }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Cuenta de Finanza
              </Label>

              <MyTextInput
                placeholder="Clave"
                label="Clave"
                name="clave"
                disabled={true}
                tabIndex={0}
              />

              <MyTextInput
                autoFocus={true}
                placeholder="Nombre"
                label="Nombre"
                name="nombre"
                tabIndex={1}
                enterInput={enterInput}
                maxLength={30}
              />
              <div className="col60Block">
                <MyTextInput
                  label="Abreviacion"
                  placeholder="Abreviacion"
                  name="abreviacion"
                  tabIndex={2}
                  enterInput={enterInput}
                  maxLength={30}
                />
              </div>
              <div className="col">
                <MySelectInput
                  tabIndex={3}
                  placeholder={"Seleccionar"}
                  label="Tipo"
                  name="cuentaFinanzaTipoId"
                  options={cuentasFinanzasTipoByDropdown}
                  enterInput={enterInput}
                />
              </div>

            </Segment>
            <Segment clearing>
            <div className="col30Block">
              <Button
                type="button"
                class="ui primary button"
                tabIndex={4}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <FechaObservaciones
                      fechaObservacion={fechaObservacion}
                      setFechaObservacion={setFechaObservacion}
                      closeModals={closeModals}
                      tabIndex={lastElement}
                    />,
                    enumHeaderModalsFechasObservaciones.CuentaFinanza
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="calendar alternate outline" />
                Fechas y Observaciones
              </Button>
              </div>
                <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={cuentaFinanza.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/CuentasFinanzas"
                loading={loading}
                tabIndex={5}
                id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
