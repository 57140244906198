import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import SerieFolioList from './SerieFolioList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import SerieFolioForm from '../form/SerieFolioForm';
import ReporteSerieFolioForm from '../form/ReporteSerieFolioForm';

export default observer(function SerieFolioDashboard() {
  const { serieFolioStore, tabsStore } = useStore();
  useEffect(() => {
    serieFolioStore.loadSeriesFolios();
  }, [serieFolioStore]);

  if (serieFolioStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h1' icon='warehouse' content='Series y Folios' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}  >
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Series y Folios', <SerieFolioForm id={0} />)}
              content='Crear Serie y Folio ' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Series y Folios', <ReporteSerieFolioForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => serieFolioStore.loadSeriesFolios()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <SerieFolioList />
      </Grid.Column>
    </Grid>
  )
})