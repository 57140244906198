
import { Label } from "semantic-ui-react";
import { Direccion } from "../../app/models/configuracion/Direccion";

interface Props {
  className?: string;
  direccion: Direccion;
}
export default function BarraDireccion(props: Props) {
  const { direccion } = props;
  return (
    <Label.Group color="blue" size="mini">
      {direccion?.codigoPostal ? (
        <Label> {(direccion ? "CP: " : "") + direccion?.codigoPostal}</Label>
      ) : null}
      
      {direccion?.calle ? (
        <Label> {(direccion ? "calle: " : "") + direccion?.calle}</Label>
      ) : null}
      
      
      {direccion?.numero ? (
        <Label> {(direccion ? "numero: " : "") + direccion?.numero}</Label>
      ) : null}
      
      
      {direccion?.numeroInterior ? (
        <Label> {(direccion?.numeroInterior ? "numero Interior: " + direccion.numeroInterior : "")}</Label>
      ) : null}
      
      {direccion?.colonia ? (
        <Label> {(direccion?.colonia ? "Colonia: " + direccion.colonia : "")}</Label>
      ) : null}
      
      {direccion?.ciudad ? (
        <Label> {(direccion?.ciudad ? "Ciudad: " + direccion.ciudad : "")}</Label>
      ) : null}

      {direccion?.municipio ? (
         <Label> {(direccion?.municipio ? "Municipio: " + direccion.municipio : "")}</Label>
      ) : null}
    </Label.Group>
  );
}