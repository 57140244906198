export interface VerificadorPago {
  id: number;
  fechaInicio?: Date;
  fechaFin?: Date;
  nombre: string;
  estado: string;
  tipoReporte: string;
  ordenado: string;
  empresa: string;
  sucursal: string;
  serieComp: string;
  folioComp: string;
  cliente?: string;
  cuentaCliente?: string;
  conceptoCliente?: string;
  folioPago: string;
  importe: number;
  chequera: string;
  metodoPago: string;
  usuarioElabora: string;
  vendedor: string;
  referencia: string;

}
export class VerificadorPagoFormValues implements VerificadorPago {
  id: number = 0;
  fechaInicio?: Date;
  fechaFin?: Date;
  nombre: string = "";
  estado: string = "";
  tipoReporte: string = "";
  ordenado: string = "";
  empresa: string = "";
  sucursal: string = "";
  serieComp: string = "";
  folioComp: string = "";
  cliente?: string = "";
  cuentaCliente?: string = "";
  conceptoCliente?: string = "";
  folioPago: string = "";
  importe: number = 0;
  chequera: string = "";
  metodoPago: string = "";
  usuarioElabora: string = "";
  vendedor: string = "";
  referencia: string = "";
  constructor(init?: VerificadorPago) {
    Object.assign(this, init);
  }
}
