import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ConceptoDeProveedor } from "../../models/gestionProveedor/ConceptoDeProveedor";


import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";


export default class ConceptoDeProveedorStore {
    conceptoDeProveedorRegistry = new Map<number, ConceptoDeProveedor>();
    selectedConceptoDeProveedor: ConceptoDeProveedor | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get conceptosDeProveedoresByDate() {
        return Array.from(this.conceptoDeProveedorRegistry.values()).sort((a) => a.id);
    }

    get conceptoDeProveedorById(){
        return Array.from(this.conceptoDeProveedorRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get conceptosDeProveedoresByDropdown() {
        let ConceptoDeProveedoresArreg = [new TipoDropDownValues()];
        let conceptoDeProveedorOpt = new TipoDropDownValues();
        const cvts = Array.from(this.conceptoDeProveedorRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            conceptoDeProveedorOpt.key = element.id;
            conceptoDeProveedorOpt.text = element.nombre;
            conceptoDeProveedorOpt.value = element.id;
            conceptoDeProveedorOpt.content = element.nombre;
            ConceptoDeProveedoresArreg.push(conceptoDeProveedorOpt);
            conceptoDeProveedorOpt = new TipoDropDownValues();

        });
        return ConceptoDeProveedoresArreg;
    }

    getConceptosDeProveedores = async () => {
        this.setLoadingInitial(true);
        const conceptosDeProveedores = await agent.ConceptosDeProveedores.list();
        try {
            conceptosDeProveedores.forEach(conceptoDeProveedor => {
                this.setConceptoDeProveedor(conceptoDeProveedor);
            })
            this.setLoadingInitial(false);
            return conceptosDeProveedores;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosDeProveedores;
        }
    }

    loadConceptosDeProveedores = async () => {
        this.setLoadingInitial(true);
        try {
            const conceptosDeProveedores = await agent.ConceptosDeProveedores.list();
            conceptosDeProveedores.forEach(conceptoDeProveedor => {

                this.setConceptoDeProveedor(conceptoDeProveedor);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadConceptoDeProveedor = async (id: number) => {
        let conceptoDeProveedor = this.getConceptoDeProveedor(id);
        if (conceptoDeProveedor) {

            this.selectedConceptoDeProveedor = conceptoDeProveedor;
            return conceptoDeProveedor;
        } else {
            this.loadingInitial = true;
            try {
                conceptoDeProveedor = await agent.ConceptosDeProveedores.details(id);
                this.setConceptoDeProveedor(conceptoDeProveedor);
                this.selectedConceptoDeProveedor = conceptoDeProveedor;
                this.setLoadingInitial(false);
                return conceptoDeProveedor;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoDeProveedor = (conceptoDeProveedor: ConceptoDeProveedor) => {
        this.conceptoDeProveedorRegistry.set(conceptoDeProveedor.id, conceptoDeProveedor);
    }

    private getConceptoDeProveedor = (id: number) => {
        return this.conceptoDeProveedorRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoDeProveedor = async (conceptoDeProveedor: ConceptoDeProveedor) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedores.create(conceptoDeProveedor);
            runInAction(() => {
                this.selectedConceptoDeProveedor = conceptoDeProveedor;
                this.editMode = false;
                this.loading = false;
            })


        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateConceptoDeProveedor = async (conceptoDeProveedor: ConceptoDeProveedor) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedores.update(conceptoDeProveedor)
            runInAction(() => {
                this.selectedConceptoDeProveedor = conceptoDeProveedor;
                this.editMode = false;
                this.loading = false;
            })


        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteConceptoDeProveedor = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedores.delete(id);
            runInAction(() => {
                this.conceptoDeProveedorRegistry.delete(id);
                this.loading = false;
            })

        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}