import { Empresa, IFile } from "../configuracion/Empresa";
import {
  FechaObservacion,
  FechaObservacionValues,
} from "../configuracion/FechaObservacion";
import { Moneda } from "../configuracion/Moneda";
import { Sucursal } from "../configuracion/Sucursal";
import { ControlListaDePrecio } from "../controlPrecio/ControlListaDePrecio";
import { Proveedor } from "../gestionProveedor/Proveedor";
import { Almacen } from "./Almacen";
import { CategoriaProducto } from "./CategoriaProducto";
import { Clasificacion } from "./Clasificacion";
import { ImpuestoProducto, ImpuestoProductoFormValues } from "./ImpuestoProducto";
import { PoliticaDescuento } from "./PoliticaDescuento";
import { ProductoTipo } from "./ProductoTipo";
import { UnidadMedida } from "./UnidadMedida";

export interface Producto {
  id: number;
  clave: string;
  nombre: string;
  empresaId: number;
  empresa?: Empresa;
  sucursalId: number;
  sucursal?: Sucursal;
  nombreCorto: string;
  productoTipoId?: number;
  productoTipo?: ProductoTipo;
  codigoBarra: string;
  usaDescripcionAdicional?: boolean;
  usaDescripcionAdicionalCompra?: boolean;
  usaSerie?: boolean;
  usaLotes?: boolean;
  usaInformacionAduanera?: boolean;
  esAnticipo?: boolean;
  categoriaProductoId?: number;
  categoriaProducto?: CategoriaProducto;
  almacenId: number;
  almacen?: Almacen;
  fichaTecnica: string;
  clasificacionId: number;
  clasificacion?: Clasificacion;
  impuestoId: number;
  impuesto: ImpuestoProducto;
  monedaId: number;
  moneda?: Moneda;
  unidadMedida1Id: number;
  unidadMedida1?: UnidadMedida;
  numeroDecimales1: number;
  unidadMedida2Id?: number;
  unidadMedida2?: UnidadMedida;
  numeroDecimales2: number;
  unidadMedidaCompraId?: number;
  unidadMedidaCompra?: UnidadMedida;
  numeroDecimalesCompra?: number;
  permitirVenderSinExistencia?: boolean;
  descuento?: number;
  maximoPorcentajeDesc?: number;
  pesoKilos?: number;
  almacenUbicacion: string;
  politicaDescuentoId?: number;
  politicaDescuento?: PoliticaDescuento;
  claveSat: string;
  claveSATCartaPorte: string;
  maximo?: number;
  minimo?: number;
  puntoReorden?: number;
  esProrrateable?: boolean;
  codigoProveedor: string;
  proveedorId?: number;
  ProveedorAsignado?: Proveedor;
  fechaObservacionId?: number;
  fechaObservacion: FechaObservacion;
  productoFileId?: number;
  productoFile?: IFile;
  productoImagen?: null;
  controlListaDePrecio: ControlListaDePrecio[];
  existencia?: number;
  costo?: number;
}
export class ProductoFormValues implements Producto {
  id: number = 0;
  clave: string = "";
  nombre: string = "";
  empresaId: number = 0;
  empresa?: Empresa;
  sucursalId: number = 0;
  sucursal?: Sucursal;
  nombreCorto: string = "";
  productoTipoId?: number;
  productoTipo?: ProductoTipo;
  codigoBarra: string = "";
  usaDescripcionAdicional?: boolean;
  usaDescripcionAdicionalCompra?: boolean;
  usaSerie?: boolean;
  usaLotes?: boolean;
  usaInformacionAduanera?: boolean;
  esAnticipo?: boolean;
  categoriaProductoId?: number;
  categoriaProducto?: CategoriaProducto;
  almacenId: number = 0;
  almacen?: Almacen;
  fichaTecnica: string = "";
  clasificacionId: number = 0;
  clasificacion?: Clasificacion;
  impuestoId: number = 0;
  impuesto: ImpuestoProducto = new ImpuestoProductoFormValues();
  monedaId: number = 0;
  moneda?: Moneda;
  unidadMedida1Id: number = 0;
  unidadMedida1?: UnidadMedida;
  numeroDecimales1: number = 0;
  unidadMedida2Id?: number;
  unidadMedida2?: UnidadMedida;
  numeroDecimales2: number = 0;
  unidadMedidaCompraId?: number;
  unidadMedidaCompra?: UnidadMedida;
  numeroDecimalesCompra?: number;
  permitirVenderSinExistencia?: boolean;
  descuento?: number;
  maximoPorcentajeDesc?: number;
  pesoKilos?: number;
  almacenUbicacion: string = "";
  politicaDescuentoId?: number;
  politicaDescuento?: PoliticaDescuento;
  claveSat: string = "";
  claveSATCartaPorte: string = "";
  maximo?: number;
  minimo?: number;
  puntoReorden?: number;
  esProrrateable?: boolean;
  codigoProveedor: string = "";
  proveedorId?: number;
  ProveedorAsignado?: Proveedor;
  fechaObservacionId?: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  productoFileId?: number;
  productoFile?: IFile;
  productoImagen?: null;
  controlListaDePrecio: ControlListaDePrecio[] = [];
  existencia?: number = 0;
  costo?: number =0;

  constructor(init?: Producto) {
    Object.assign(this, init);
  }
}
