import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { Producto } from "../inventario/Producto";

export interface ControlListaDePrecio {
  id: number;
  empresaId: number;
  empresa?: Empresa;
  sucursalId: number;
  sucursal?: Sucursal;
  productoId: number;
  producto?: Producto;
  costo: number;
  precio: number;
  fechaMoficacion?: Date;
  listaDePrecioId: number;
}
export class ControlListaDePrecioFormValues implements ControlListaDePrecio {
  id: number = 0;
  empresaId: number = 0;
  empresa?: Empresa;
  sucursalId: number = 0;
  sucursal?: Sucursal;
  productoId: number = 0;
  producto?: Producto;
  costo: number = 0;
  precio: number = 0;
  selectedIdEmpresa: undefined;
  fechaMoficacion?: Date;
  listaDePrecioId: number = 0;

  constructor(init?: ControlListaDePrecio) {
    Object.assign(this, init);
  }
}
export class ControlListaDePrecioArregloFormValues {
  //controlListaDePrecio: ControlListaDePrecio[]= [new ControlListaDePrecioFormValues()];
  controlListaDePrecio?: ControlListaDePrecio[];
  constructor(init?: ControlListaDePrecio) {
    Object.assign(this, init);
  }
}
