import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ProveedorTipo } from "../../models/gestionProveedor/ProveedorTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ProveedorTipoStore {
    proveedorTipoRegistry = new Map<number, ProveedorTipo>();
    selectedProveedorTipo: ProveedorTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    
    get proveedoresTiposByDropdown() {
        return entityByDropdown<ProveedorTipo>(
          Array.from(this.proveedorTipoRegistry.values())
        );
      }

    get proveedoresByDate() {
        return Array.from(this.proveedorTipoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadProveedorTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const proveedorTipos = await agent.ProveedorTipos.list();
            proveedorTipos.forEach(proveedorTipo => {
                this.setProveedorTipo(proveedorTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getProveedorTipos = async () => {
        this.setLoadingInitial(true);
        const proveedorTipos = await agent.ProveedorTipos.list();
        try {
            proveedorTipos.forEach(proveedorTipo => {
                this.setProveedorTipo(proveedorTipo);
            })
            this.setLoadingInitial(false);
            return proveedorTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return proveedorTipos;
        }
    }

    loadProveedorTipo = async (id: number) => {
        let proveedorTipo = this.getProveedorTipo(id);
        if (proveedorTipo) {
            this.selectedProveedorTipo = proveedorTipo;
            return proveedorTipo;

        } else {
            this.loadingInitial = true;
            try {
                proveedorTipo = await agent.ProveedorTipos.details(id);
                this.setProveedorTipo(proveedorTipo);
                this.selectedProveedorTipo = proveedorTipo;
                this.setLoadingInitial(false);
                return proveedorTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setProveedorTipo = (proveedorTipo: ProveedorTipo) => {
        this.proveedorTipoRegistry.set(proveedorTipo.id, proveedorTipo);
    }

    private getProveedorTipo = (id: number) => {
        return this.proveedorTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createProveedorTipo = async (proveedorTipo: ProveedorTipo) => {
        this.loading = true;
        try {
            await agent.ProveedorTipos.create(proveedorTipo);
            runInAction(() => {
                this.proveedorTipoRegistry.set(proveedorTipo.id, proveedorTipo);
                this.selectedProveedorTipo = proveedorTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteProveedorTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ProveedorTipos.delete(id);
            runInAction(() => {
                this.proveedorTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
