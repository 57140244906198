import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { observer } from "mobx-react-lite";
import { Button, Grid, Header, Segment, Icon } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { CorteDeCaja, CorteDeCajaFormValues } from "../../../../app/models/venta/CorteDeCaja";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { useStore } from '../../../../app/stores/store';
import MyDateInput from "../../../../app/common/form/MyDateInput";
import BarraBotones from "../../../Component/BarraBotones";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import CorteDeCajaEfectivoForm from "./CorteDeCajaEfectivoForm";
import { CorteDeCajaEfectivoFormValues } from "../../../../app/models/venta/CorteDeCajaEfectivo";
import CapturaCheques from "./CapturaCheques";
import { GeneracionComprobantePagoFormValues } from "../../../../app/models/venta/GeneracionComprobantePagos";
import CapturaTerminales from "./CapturaTerminales";
import CapturaFichasDepositos from "./CapturaFichasDepositos";

export default observer(function CorteDeCajaForm() {
  const [corteDeCaja, setCorteDeCaja] = useState(new CorteDeCajaFormValues());
  const { empresaStore, sucursalStore, proveedorStore, usuarioStore, corteDeCajaStore, modalStore, generacionComprobanteStore } = useStore();
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { empresaSucursal } = proveedorStore

  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown } = sucursalStore
  const { loadUsuarioByEmpresaSucursal, usuariosByEmpresaByDropdown } = usuarioStore
  const { obtenerImportesVentasDeContado, obtenerImportesVentasDeCredito, obtenerImportesFacturasDeAnticipo } = generacionComprobanteStore
  const { id } = useParams<{ id: string }>();
  const { loading, updateCorteDeCaja, createCorteDeCaja, corteDeCajaFiltro } = corteDeCajaStore
  const firstElement = 0;
  const lastElement = 33;
  const [efectivo, setEfectivo] = useState(new CorteDeCajaEfectivoFormValues());
  function enterInput(tabIndex: number) {
    let x = tabIndex + 2;
    if (tabIndex === 33) tabIndex = 0;
    let next = document.querySelector<HTMLElement>(
      '[tabIndex="' + (tabIndex + 1) + '"]'
    );
    if (next) {
      while ((next as any)?.disabled === true) {
        next = document.querySelector<HTMLElement>('[tabIndex="' + x + '"]');
        x++;
      }
    }
    if (next) {
      next.focus();
    }
  }
  const tabuladorButton = (event: any) => {
    if (event.key !== "Tab") {
      return;
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    enterInput(tabIndex);
    event.preventDefault();
  };
  const { openModal, closeModal } = modalStore;

  const [cheques, setCheques] = useState(new GeneracionComprobantePagoFormValues());
  const [terminales, setTerminales] = useState(new GeneracionComprobantePagoFormValues());
  const [fichasDepositos, setfichasDepositos] = useState(new GeneracionComprobantePagoFormValues());
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido.")
  })
  function closeModals() {
    closeModal();
  }

  function onChangeSelectEmpresa(idSelected: number, setFieldValue?: any, index?: any, values?: CorteDeCaja) {
    empresaSucursal.empresaId = idSelected;
    corteDeCajaFiltro.empresaId = idSelected;
    debugger
    empresaSucursal.sucursalId = 0;
    void loadSucursalByIdEmpresa(empresaSucursal.empresaId);
  }
  function onChangeSelectSucursal(idSelected: number, setFieldValue?: any, index?: any, values?: CorteDeCaja) {
    empresaSucursal.sucursalId = idSelected;
    debugger
    corteDeCajaFiltro.sucursalId = idSelected;
    loadUsuarioByEmpresaSucursal(empresaSucursal)

  }
  function onChangeSelectCajera(idSelected: number, setFieldValue: any, index?: any, values?: CorteDeCaja) {
    corteDeCajaFiltro.cajeraId = idSelected;
    debugger
    corteDeCajaFiltro.fecha = values?.fecha;
    obtenerImportesVentasDeContado(corteDeCajaFiltro).then((importeContado) => {
      setFieldValue("facturasContado", importeContado);
    });
    obtenerImportesVentasDeCredito(corteDeCajaFiltro).then((importeCredito) => {
      setFieldValue("facturasCredito", importeCredito);
    });
    debugger
    obtenerImportesFacturasDeAnticipo(corteDeCajaFiltro).then((importeAnticipos) => {
      debugger
      setFieldValue("facturasAnticipo", importeAnticipos);
    });
  }
  
  useEffect(() => {
    empresaStore.getEmpresas();
    void loadEntityEmpresas();
  }, []);
  function handleSubmit(corteDeCaja: CorteDeCaja) {
    debugger

    corteDeCaja.corteDeCajaEfectivo = efectivo;
    corteDeCaja.id > 0 ? updateCorteDeCaja(corteDeCaja) : createCorteDeCaja(corteDeCaja).then(resul => {
      if (resul !== undefined) {
      }
    });
  }
  function onKeyDownSelectFondoFijo(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: CorteDeCaja): void {
    calcularConteos(values);
  }
  function onKeyDownSelectTotalEfectivo(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: CorteDeCaja): void {
    calcularConteos(values);
  }

  const calcularConteos = (values: CorteDeCaja) => {
    values.saldoConteo = values.fondoFijo - values.totalEfectivo;
  }
  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={corteDeCaja}
      >
        {({ values, isValid, dirty, setFieldValue }) => (
          <Form className='ui form' >
            {corteDeCaja.id === 0 ? (<div className="nuevo">  Registro Nuevo    </div>) : ("")}
            <div className='colsp' />
            <Grid columns='equal'>
              <Grid.Row>
                <Header as='h2' icon='compass' content='Corte de Caja' color='blue' />
              </Grid.Row>
              <Segment>
                <div className='col25'>
                  <MySelectInput autoFocus={true} tabIndex={1} placeholder={"Seleccionar"} label="Empresa" name="empresaId" options={EmpresasByDropdown} enterInput={enterInput} onChange={onChangeSelectEmpresa} />
                </div>
                <div className='col25'>
                  <MySelectInput tabIndex={2} placeholder={"Seleccionar"} label="Sucursal" name="sucursalId" options={sucursalesByEmpresaByDropdown} enterInput={enterInput} onChange={onChangeSelectSucursal} />
                </div>
                <div className='col25'>
                  <MySelectInput tabIndex={3} placeholder={"Seleccionar"} label="Cajera" name="cajeroId" options={usuariosByEmpresaByDropdown} enterInput={enterInput} onChange={onChangeSelectCajera} />
                </div>
                <div className='col18'>
                  Fecha Emision
                  <MyDateInput tabIndex={4} placeholderText="Fecha Emision" name="fecha" dateFormat="dd/MM/yyyy" enterInput={enterInput} />
                </div>
              </Segment>
            </Grid>
            <Grid>
              Efectivo
              <Segment>
                <div className="cols">
                  <div className='col15'>
                    <Button
                      type="button"
                      class="ui primary button"
                      tabIndex={5}
                      onKeyDown={tabuladorButton}
                      loading={loading}
                      onClick={() =>
                        openModal(
                          <CorteDeCajaEfectivoForm
                            efectivo={efectivo}
                            setPagos={setEfectivo}
                            closeModals={closeModals}
                            tabIndex={2228}
                          />,
                          'Conteo Efectivo',
                          'large'
                        )
                      }
                      floated="left"
                      color="blue"
                    >
                      <Icon name="money bill alternate" />
                      Conteo
                    </Button>
                  </div>
                  <div className='col15'>
                    <MyTextInput placeholder="Clave" label="Efectivo" name="totalEfectivo" onKeyDown={onKeyDownSelectTotalEfectivo} tabIndex={6} enterInput={enterInput} maxLength={8} />
                  </div>
                  <div className='colsp' />
                  <div className='col15'>
                    <MyTextInput placeholder="Clave" label="Pendiente Cajera" name="clave" tabIndex={7} enterInput={enterInput} maxLength={8} />
                  </div>
                  <div className='colsp' />
                  <div className='col15'>
                    <MyTextInput placeholder="Clave" label="Devoluciones" name="clave" tabIndex={8} enterInput={enterInput} maxLength={8} />
                  </div>
                  <div className='colsp' />
                  <div className='col15'>
                    <MyTextInput placeholder="Clave" label="Faltantes" name="clave" tabIndex={9} enterInput={enterInput} maxLength={8} />
                  </div>
                </div>
              </Segment>
            </Grid>
            <div className='colsp' />
            <div className='colsp' />   <div className='colsp' />
            <div><div className='colsp' /></div>
            <Grid>
              Menos
              <Segment>
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Fondo Fijo" name="fondoFijo" onKeyDown={onKeyDownSelectFondoFijo} tabIndex={10} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Saldo del día" name="clave" tabIndex={11} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Venta del día" name="clave" tabIndex={12} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Otros" name="clave" tabIndex={13} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col15'>

                  <MyTextInput placeholder="Clave" label="Total" name="clave" tabIndex={14} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Saldo" name="saldoConteo" tabIndex={15} enterInput={enterInput} maxLength={8} />
                </div>

              </Segment>
            </Grid>
            <div className='colsp' />   <div className='colsp' />
            <div><div className='colsp' /></div>
            <Grid>
              Ventas de contado
              <Segment>
                <div className='col20'>
                  <MyTextInput placeholder="Contado" label="Factura de Contado" name="facturasContado" tabIndex={16} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Facturas de Anticipos" name="facturasAnticipo" tabIndex={17} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Pendiente por liquidar" name="clave" tabIndex={18} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Fecha Saldo" name="clave" tabIndex={19} enterInput={enterInput} maxLength={8} />
                </div>
              </Segment>
            </Grid>
            <Grid>
              Ventas de Crédito
              <Segment>
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Facturas de Credíto" name="facturasCredito" tabIndex={20} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Mas anticipos aplicados" name="clave" tabIndex={21} enterInput={enterInput} maxLength={8} />
                </div>

                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Menos Devoluciones de creditos" name="clave" tabIndex={22} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Total Credito" name="clave" tabIndex={23} enterInput={enterInput} maxLength={8} />
                </div>
              </Segment>
            </Grid>
            <Grid>
              Recibio Cajera
              <Segment>
              <div className="cols">
                  <div className='col15'>
                    <Button
                      type="button"
                      class="ui primary button"
                      tabIndex={24}
                      onKeyDown={tabuladorButton}
                      loading={loading}
                      onClick={() =>
                        openModal(
                          <CapturaCheques
                            cheques={cheques}
                            setPagos={setEfectivo}
                            closeModals={closeModals}
                            tabIndex={2228}
                          />,
                          'Captura de Cheques',
                          'large'
                        )
                      }
                      floated="left"
                      color="blue"
                    >
                      <Icon name="money bill alternate" />
                      Cheques
                    </Button>
                  </div>
                  </div>
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Clave" name="clave" tabIndex={25} enterInput={enterInput} maxLength={8} />
                </div>                
                <div className='colsp' />
                <div className='col15'>
                <Button
                  type="button"
                  class="ui primary button"
                  tabIndex={26}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <CapturaTerminales
                      terminales = {terminales}
                        setPagos={setEfectivo}
                        closeModals={closeModals}
                        tabIndex={2228}                        
                      />,
                      'Captura de Terminales',
                      'large'
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="money bill alternate" />
                  Terminales
                </Button>
              </div>
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="Clave" name="clave" tabIndex={27} enterInput={enterInput} maxLength={8} />
                </div>

                <div className='colsp' />
                <div className='colsp' />
                <div className='col15'>
                <Button
                  type="button"
                  class="ui primary button"
                  tabIndex={28}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <CapturaFichasDepositos
                      fichasDepositos={fichasDepositos}
                        setPagos={setEfectivo}
                        closeModals={closeModals}
                        tabIndex={2228}
                      />,
                      'Captura de fichas de depositos',
                      'large'
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="money bill alternate" />
                  Fichas de depositos
                </Button>
              </div>
                <div className='col15'>
                  <MyTextInput placeholder="Clave" label="" name="clave" tabIndex={29} enterInput={enterInput} maxLength={8} />
                </div>
                <div className='colsp' />
                <div className='col20'>
                  <MyTextInput placeholder="Clave" label="Total Entregado" name="clave" tabIndex={30} enterInput={enterInput} maxLength={8} />
                </div>
              </Segment>
            </Grid>
            <Segment>
            <BarraBotones
                  toLink="/home"
                  loading={loading}
                  tabIndex={31}
                  id={id}
                  firstElement={firstElement}
                  lastElement={lastElement}
                ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>)
})