import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { CuentaFinanzaTipo } from "./CuentaFinanzaTipo";

export interface CuentaFinanza {
    id: number;
    clave: number;
    nombre: string;
    abreviacion: string;
    cuentaFinanzaTipoId: number;
    Tipo?: CuentaFinanzaTipo;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export interface ICuentaFinanzaFormValues extends Partial<CuentaFinanza> {
    cuentaFinanzaTipoId: any;
}

export class CuentaFinanzaFormValues implements ICuentaFinanzaFormValues {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    abreviacion:string="";
    cuentaFinanzaTipoId:number= 0;
    Tipo?:CuentaFinanzaTipo;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    constructor(init?: CuentaFinanza) {
        Object.assign(this, init);
    }
}