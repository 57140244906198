import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ConceptoDeInventario } from "../../models/inventario/ConceptoDeInventario";
import { history } from "../../..";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ConceptoDeInventarioStore {
    conceptoDeInventarioRegistry = new Map<number, ConceptoDeInventario>();
    selectedConceptoDeInventario: ConceptoDeInventario | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get conceptosInventariosByDropdown() {
        return entityByDropdown<ConceptoDeInventario>(Array.from(this.conceptoDeInventarioRegistry.values()));
    }

    get conceptosInventariosByDate() {
        return Array.from(this.conceptoDeInventarioRegistry.values()).sort((a) => a.id);
    }

    get conceptosDeInventariosById() {
        return Array.from(this.conceptoDeInventarioRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    fetchsConceptoInventarios = async () => {
        try {
            const response = await agent.ConceptosDeInventarios.list();
            return response;
        } catch (error) {
            console.error("Error al obtener los conceptos", error);
        }
    };

    loadConceptosDeInventarios = async () => {
        this.setLoadingInitial(true);
        try {
            const conceptosDeInventarios = await agent.ConceptosDeInventarios.list();
            conceptosDeInventarios.forEach(conceptoDeInventario => {
                setDate<ConceptoDeInventario>(conceptoDeInventario);
                this.setConceptoDeInventario(conceptoDeInventario);
            });
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };

    loadConceptoDeInventario = async (id: number) => {
        let conceptoDeInventario = this.getConceptoDeInventario(id);
        if (conceptoDeInventario) {
            setDate<ConceptoDeInventario>(conceptoDeInventario);
            this.selectedConceptoDeInventario = conceptoDeInventario;
            return conceptoDeInventario;
        } else {
            this.loadingInitial = true;
            try {
                conceptoDeInventario = await agent.ConceptosDeInventarios.details(id);
                this.setConceptoDeInventario(conceptoDeInventario);
                this.selectedConceptoDeInventario = conceptoDeInventario;
                this.setLoadingInitial(false);
                return conceptoDeInventario;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoDeInventario = (conceptoDeInventario: ConceptoDeInventario) => {
        this.conceptoDeInventarioRegistry.set(conceptoDeInventario.id, conceptoDeInventario);
    }

    getConceptosDeInventarios = async () => {
        this.setLoadingInitial(true);
        const conceptosInventarios = await agent.ConceptosDeInventarios.list();
        try {
            conceptosInventarios.forEach((conceptoInventario) => {
                setDate<ConceptoDeInventario>(conceptoInventario);
                this.setConceptoDeInventario(conceptoInventario);
            });
            this.setLoadingInitial(false);
            return conceptosInventarios;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosInventarios;
        }
    };

    private getConceptoDeInventario = (id: number) => {
        return this.conceptoDeInventarioRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoDeInventario = async (conceptoDeInventario: ConceptoDeInventario) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventarios.create(conceptoDeInventario);
            runInAction(() => {
                this.selectedConceptoDeInventario = conceptoDeInventario;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Concepto de inventario creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateConceptoDeInventario = async (conceptoDeInventario: ConceptoDeInventario) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventarios.update(conceptoDeInventario)
            runInAction(() => {
                this.selectedConceptoDeInventario = conceptoDeInventario;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Concepto de Inventario editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteConceptoDeInventario = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventarios.delete(id);
            runInAction(() => {
                this.conceptoDeInventarioRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Concepto de Inventario eliminado con éxito");
        } catch (error) {
            toast.error("Problema al eliminar los datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}