import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Grid, Header, Button, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { enumHeaderModals, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { MovimientoFinanzaDetalleFormValues, MovimientoFinanza, MovimientoFinanzaFormValues } from '../../../../app/models/Finanza/MovimientoFinanza';
import DeleteModals from "../../../modals/DeleteModals";

export default observer(function MovimientoFinanzaForm() {
  const automatico = [{
    key: 0,
    text: "Seleccionar",
    value: 0,
    contex: "Seleccionar",
  },
  {
    key: 1,
    text: "Verdadero",
    value: true,
    contex: "Verdadero",
  },
  {
    key: 2,
    text: "Falso",
    value: false,
    contex: "Falso",
  }
  ];
  const [polizaCargo, setPolizaCargo] = useState(0);
  const [polizaAbonos, setPolizaAbonos] = useState(0);
  const initialIndexTable = 6;
  const numberControlsTable = 13;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const [target, setTarget] = useState("");
  const firstElement = 0;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { empresaStore, sucursalStore, proveedorStore, cuentaFinanzaStore, tipoMovimientoStore, conceptoFinanzaStore, movimientoFinanzaStore, monedaStore, formaPagoStore } = useStore();
  const { empresaSucursal } = proveedorStore;
  const { formasPagosByDropdown, loadFormasPagos } = formaPagoStore;
  const { monedasByDropdown, loadEnityMoneda } = monedaStore;
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { tiposMovimientosByDropdown, loadTiposMovimientos } = tipoMovimientoStore
  const { cuentaFinanzaByDropdown, loadCuentasFinanzas } = cuentaFinanzaStore
  const { conceptosFinanzasByDropdown, loadConceptosFinanzas, conceptoFinanzaRegistry } = conceptoFinanzaStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown } = sucursalStore
  const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());
  const [movimientoFinanza, setMovimientoFinanza] = useState(new MovimientoFinanzaFormValues());
  const { deleteMovimientoFinanza, loadingInitial, createMovimientoFinanza, loading, obtenerSiguienteFolio, cargarMovimiento } = movimientoFinanzaStore
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    tipoMovimientoId: Yup.number()
      .min(1, "Favor de seleccionar un Tipo Movimiento.")
      .required("Tipo movimiento es requerido."),
    clave: Yup.number()
      .typeError("El valor debe ser un numero.")
      .min(1, "Favor de ingresar una clave mayor a 0.")
      .required("La clave es requerida."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    movimientoFinanzaDetalle: Yup.array().of(
      Yup.object().shape({
        cuentaFinanzaId: Yup.number()
          .min(1, "Favor de seleccionar una Cuenta.")
          .required("Cuenta es requerido."),
        conceptoFinanzaId: Yup.number()
          .min(1, "Favor de seleccionar una Concepto.")
          .required("Concepto es requerido."),
        importe: Yup.number().typeError("El valor debe ser un numero.").required("El importe es requerido"),
        monedaId: Yup.number()
          .min(1, "Favor de seleccionar una Moneda.")
          .required("Moneda es requerido."),
        tipoCambio: Yup.number().when("monedaId", {
          is: (monedaId: number) => monedaId === 2,
          then: Yup.number().typeError("El valor debe ser un numero.").min(1, "Tipo de cambio debe ser mayor a 0").required("Tipo de cambio es requerido"),
          otherwise: Yup.number().nullable(true)
            // checking self-equality works for NaN, transforming it to null
            .transform((_, val) => val === Number(val) ? val : null),
        }),
        formaDePagoId: Yup.number()
          .min(1, "Favor de seleccionar una Forma de pago.")
          .required("Forma de pago es requerido."),
        automatico: Yup.string()
          .required()
          .oneOf(["true", "false"], "Automatico debe ser Verdadero o False.")
          .required("Automatico es requerido"),
      })
    ),
  });

  useEffect(() => {
    void loadFormasPagos();
    void loadEnityMoneda();
    void loadConceptosFinanzas();
    void loadCuentasFinanzas();
    void loadEntityEmpresas();
    void loadTiposMovimientos();
  }, []);

  function formatNumber(number: number) {
    return new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN" }).format(number);

  }
  function handleSubmit(movimientoFinanza: MovimientoFinanza) {
    if (movimientoFinanza.fechaObservacion)
    movimientoFinanza.fechaObservacion = fechaObservacion;
    if (movimientoFinanza.movimientoFinanzaDetalle)
    movimientoFinanza.movimientoFinanzaDetalle?.forEach((element) => {
        
        element.conceptoFinanza = undefined;
        element.cuentaFinanza = undefined;
        element.formaDePago = undefined;
        element.moneda = undefined;
      });
    void createMovimientoFinanza(movimientoFinanza);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElementTable + 3);
  };

  function closeModals() {
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }
  function calcularImporte(values?: MovimientoFinanza) {
    setPolizaCargo(0);
    setPolizaAbonos(0);
    values?.movimientoFinanzaDetalle?.forEach((valor) => {
      let valorEncotnrado = conceptoFinanzaRegistry.get(valor.conceptoFinanzaId);
      if (valorEncotnrado)
        if (valorEncotnrado?.clave < 100) {
          setPolizaCargo((eles) => eles + Number(valor.importe));
        } else if (valorEncotnrado?.clave >= 100 && valorEncotnrado?.clave < 200) {
          setPolizaAbonos((eles) => eles + Number(valor.importe));
        }
    }
    )
  }
  function onKeyDownSelectConceptoFinanza(id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, values?: any) {
    calcularImporte(values);
  }
  function onKeyDownInputImporte(id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, index?: any, columna?: any, values?: MovimientoFinanza,) {
    calcularImporte(values);
  }

  function onChangeSelectEmpresa(idSelected: number, setFieldAny: any) {
    setFieldAny("sucursalId", 0);
    empresaSucursal.sucursalId = 0;
    void loadSucursalByIdEmpresa(idSelected);
    empresaSucursal.empresaId = idSelected;
  }

  function onChangeSelectTipoMovimiento(idSelectedTipoMovimiento: number, setFieldValue: any) {
    empresaSucursal.tipoMovimientoId = idSelectedTipoMovimiento;
    obtenerSiguienteFolio(empresaSucursal).then((clave) => {
      setFieldValue("clave", clave);
    });
  }

  function onChangeSelectSucursal(idSelectedSucursal: number, setFieldValue: any) {
    empresaSucursal.sucursalId = idSelectedSucursal;
  }

  function onKeyDownClave(clave: any, setFieldValue: any, event: any) {
    empresaSucursal.folio = parseInt(clave);
    if ((event.key === "Enter" || event.key === "Tab") &&
      (empresaSucursal.folio > 0 && empresaSucursal.empresaId > 0 && empresaSucursal.sucursalId > 0 && empresaSucursal.tipoMovimientoId)) {
      cargarMovimiento(empresaSucursal).then((result) => {
        if (result) {
          setFechaObservacion(
            new FechaObservacionValues(result?.fechaObservacion));
          setFieldValue("id", result.id);
          setFieldValue("movimientoFinanzaDetalle", result.movimientoFinanzaDetalle);
          setFieldValue("comentarios", result.comentarios);
          setFieldValue("fecha", result.fecha);
          setFieldValue("fechaObservacionId", result.fechaObservacionId);
          setFieldValue("fechaObservacion", result.fechaObservacion);

        }
        else {
          setFechaObservacion(
            new FechaObservacionValues()
          );
          setFieldValue("id", 0);
          setFieldValue("movimientoFinanzaDetalle", [new MovimientoFinanzaDetalleFormValues()]);
          setFieldValue("comentarios", "");
          setFieldValue("fecha", new Date());
          setFieldValue("fechaObservacionId", 0);
          setFieldValue("fechaObservacion", new FechaObservacionValues());
        }
      })
    }
  }

  function onKeyDownSelectAutomatico(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    arrayHelpers.push(new MovimientoFinanzaDetalleFormValues());
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deleteMovimientoFinanza(id).then((respuest) => {
      arrayHelpers.remove(index);
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable - numberControlsTable
      );

    });
    setTarget(e);
    closeModal();
  }

  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={movimientoFinanza}
      >
        {({ isValid, values }) => (
          <Form className='ui form' >
            <Segment>
              {values.id === 0 ? (<div className="nuevo">
                Registro Nuevo
              </div>
              ) : ("")
              }

              <Grid columns='equal'>
                <Grid.Row>
                  <Header as='h2' icon='align center' content='Movimientos de Finanzas' color='blue' />
                </Grid.Row>

                <Grid.Row>

                  <div className='colsp' />
                  <div className='col18'>
                    <MySelectInput
                      autoFocus={true}
                      tabIndex={1}
                      placeholder={"Seleccionar"}
                      label="Empresa"
                      name="empresaId"
                      options={EmpresasByDropdown}
                      enterInput={enterInput}
                      onChange={onChangeSelectEmpresa}
                    />
                  </div>
                  <div className='col18'>
                    <MySelectInput
                      tabIndex={2}
                      placeholder={"Seleccionar"}
                      label="Sucursal"
                      name="sucursalId"
                      options={sucursalesByEmpresaByDropdown}
                      enterInput={enterInput}
                      onChange={onChangeSelectSucursal}
                    />
                  </div>
                  <div className='col18'>
                    <MySelectInput
                      tabIndex={3}
                      placeholder={"Seleccionar"}
                      label="Tipo Movimiento"
                      name="tipoMovimientoId"
                      options={tiposMovimientosByDropdown}
                      enterInput={enterInput}
                      onChange={onChangeSelectTipoMovimiento}
                    />
                  </div>
                  <div className='col10'>
                    <MyTextInput
                      tabIndex={4}
                      placeholder="Clave"
                      label="Clave"
                      name="clave"
                      onKeyDown={onKeyDownClave}
                      enterInput={enterInput}
                      pattern="[0-9]{0,16}"
                    />
                  </div>
                  <div className='col12'>
                    Fecha Emision
                    <MyDateInput
                      tabIndex={5}
                      placeholderText="Fecha Emision"
                      name="fecha"
                      dateFormat="dd/MM/yyyy"
                      enterInput={enterInput}
                    />
                  </div>
                </Grid.Row>
                <Grid.Row>
                  <div className='colsp' />
                  <div className='col50'>
                    <MyTextInput
                      tabIndex={6}
                      placeholder=''
                      label="Comentarios"
                      name='comentarios'
                      enterInput={enterInput}
                    />
                  </div>
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />

                </Grid.Row>
                <div className="col100">
                  <FieldArray
                    name="movimientoFinanzaDetalle"
                    render={(arrayHelpers) => (
                      /////
                      <Segment>
                        <Table
                          style={{
                            overflow: "auto",
                            display: "inline-block",
                            maxHeight: "inherit",
                            width: 830,
                            height: 350
                          }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Cuenta</Table.HeaderCell>
                              <Table.HeaderCell>Concepto</Table.HeaderCell>
                              <Table.HeaderCell>Serie</Table.HeaderCell>
                              <Table.HeaderCell>Folio</Table.HeaderCell>
                              <Table.HeaderCell>SerieAfecta</Table.HeaderCell>
                              <Table.HeaderCell>FolioAfecta</Table.HeaderCell>
                              <Table.HeaderCell>Importe</Table.HeaderCell>
                              <Table.HeaderCell>Moneda</Table.HeaderCell>
                              <Table.HeaderCell>TipoCambio</Table.HeaderCell>
                              <Table.HeaderCell>FormaPago</Table.HeaderCell>
                              <Table.HeaderCell>Automatico</Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {/* {values.friends && values.friends.length > 0 */}
                            {values.movimientoFinanzaDetalle?.map(
                              (movimientoFinanzaDetalles, index) => (
                                <Table.Row key={index.valueOf()} >
                                  <Table.Cell >
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 1)}
                                      placeholder="Cuenta"
                                      name={`movimientoFinanzaDetalle[${index}].cuentaFinanzaId`}
                                      options={cuentaFinanzaByDropdown}
                                      enterInput={enterInput}
                                      index={index}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 2)}
                                      placeholder="Concepto"
                                      name={`movimientoFinanzaDetalle[${index}].conceptoFinanzaId`}
                                      options={conceptosFinanzasByDropdown}
                                      enterInput={enterInput}
                                      index={index}
                                      onKeyDown={onKeyDownSelectConceptoFinanza}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 4)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Serie"
                                      name={`movimientoFinanzaDetalle[${index}].serie`}
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 5)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Folio"
                                      name={`movimientoFinanzaDetalle[${index}].folio`}
                                      pattern="[0-9]{0,16}"
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 6)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Serie Afecta"
                                      name={`movimientoFinanzaDetalle[${index}].serieAfecta`}
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 7)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Folio Afecta"
                                      name={`movimientoFinanzaDetalle[${index}].folioAfecta`}
                                      pattern="[0-9]{0,16}"
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 8)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Importe"
                                      name={`movimientoFinanzaDetalle[${index}].importe`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      onKeyDown={onKeyDownInputImporte}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 9)}
                                      placeholder="Moneda"
                                      name={`movimientoFinanzaDetalle[${index}].monedaId`}
                                      options={monedasByDropdown}
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 10)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Tipo cambio"
                                      name={`movimientoFinanzaDetalle[${index}].tipoCambio`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 11)}
                                      placeholder="Forma de pago"
                                      name={`movimientoFinanzaDetalle[${index}].formaDePagoId`}
                                      options={formasPagosByDropdown}
                                      enterInput={enterInput}
                                      index={index}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 12)}
                                      options={automatico}
                                      placeholder="Automatico"
                                      name={`movimientoFinanzaDetalle[${index}].automatico`}
                                      enterInput={enterInput}
                                      onKeyDown={(!isValid) ? undefined : onKeyDownSelectAutomatico}
                                      arrayHelpers={arrayHelpers}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      icon
                                      tabIndex={getIndexTable(index, 13)}
                                      name={movimientoFinanzaDetalles.id}
                                      type="Button"
                                      disabled={
                                        loading &&
                                        target ===
                                        String(movimientoFinanzaDetalles.id)
                                      }
                                      loading={
                                        loading &&
                                        target ===
                                        String(movimientoFinanzaDetalles.id)
                                      }
                                      onClick={(e) => {
                                        openModal(
                                          <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={movimientoFinanzaDetalles.id}
                                            nombre={"Fila"}
                                            arrayHelpers={arrayHelpers}
                                            index={index}
                                          />,
                                          enumHeaderModals.MovimientoFinanza
                                        );
                                      }}
                                      floated="right"
                                      // content="Eliminar"
                                      color="red"
                                    >
                                      <Icon name='delete' />
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                            {!values.movimientoFinanzaDetalle?.length ? (
                              arrayHelpers.push(new MovimientoFinanzaDetalleFormValues())
                            ) : null}
                          </Table.Body>
                        </Table>
                      </Segment>
                      /////
                    )}
                  />
                </div>
              </Grid>
              <Segment clearing>
                <div className="col30Block">
                  <Button
                    id="fechaObservacion"
                    type="button"
                    className="ui primary button"
                    tabIndex={lastElementTable + 1}
                    onKeyDown={tabuladorButton}
                    loading={loading}
                    onClick={() =>
                      openModal(
                        <FechaObservaciones
                          fechaObservacion={fechaObservacion}
                          setFechaObservacion={setFechaObservacion}
                          closeModals={closeModals}
                          tabIndex={lastElementTable + 4}
                        />,
                        enumHeaderModalsFechasObservaciones.MovimientoFinanza
                      )
                    }
                    floated="left"
                    color="blue"
                  >
                    <Icon name="calendar alternate outline" />
                    Fechas y Observaciones
                  </Button>
                </div>
                <div className="col80">
                  <BarraFechaObservacion
                    fechaObservacion={fechaObservacion}
                  ></BarraFechaObservacion>
                </div>
              </Segment>
            </Segment>
            <Segment>
              <Grid className="ui grid lineheight">
                <div className='col60'></div>
                <label></label>
                <div className='col60'></div>
                <label>   </label>
                <div className='col20'>
                  <h5>Total Póliza Cargos: {formatNumber(polizaCargo)}</h5>
                  <h5>Total Póliza Abonos: {formatNumber(polizaAbonos)}</h5>
                </div>
              </Grid>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/"
                loading={loading}
                tabIndex={lastElementTable + 2}
                firstElement={firstElement}
                lastElement={lastElementTable + 4}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})