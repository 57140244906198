import React from 'react'
import { Container } from 'semantic-ui-react'

export default function HomePage(){
    return(
        <Container className="observer" style={{marginTop: '9em' } }>
            <h1>Bien Venido al sistema AIS.
                Administration Integral Solution(en)
                Solucion Integral Administrativa(es)            
            </h1>
        </Container>
    )
}