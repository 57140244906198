import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoVenta } from "./TipoVenta";
import { Cliente } from "../gestionCliente/Cliente";
import { SerieFolioDeVenta } from "./SerieFolioDeVenta";
import { Moneda } from "../configuracion/Moneda";
import { CuentaDeVenta } from "./CuentaDeVenta";
import { Usuario } from "../configuracion/Usuario";
import { GeneracionComprobanteDetalle } from "./GeneracionComprobanteDetalle";
import { FormaDePago } from "../gestionCliente/FormaDePago";
import { MetodoPago } from "../sat/c_MetodoPago";
import { UsoCFDI } from "../sat/UsoCFDI";
import { GeneracionComprobantePago } from "./GeneracionComprobantePagos";
import { CancelarGeneracionComprobante } from "./CancelarGeneracionComprobante";
import { GeneracionComprobanteNotaCredito } from "./GeneracionComprobanteNotaCredito";
import { GeneracionComprobanteComplementoPago } from "./GeneracionComprobanteComplementoPago";
import { c_TipoRelacion } from "../sat/c_TipoRelacion";

export interface GeneracionComprobante {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoVentaId: number;
    tipoVenta?: TipoVenta;
    tipoVentaCopiaId: number;
    tipoVentaCopia?: TipoVenta;
    serieFolioDeVentaId: number;
    serieFolioDeVenta?: SerieFolioDeVenta;
    serieFolioDeVentaCopiaId: number;
    serieFolioDeVentaCopia?: SerieFolioDeVenta;
    folio: number;
    fecha: Date;
    fechaCancelacion?: Date;
    clienteId: number;
    cliente?: Cliente;
    monedaId: number;
    moneda?: Moneda;
    tipoCambio: number;
    cuentaDeVentaId: number;
    cuentaDeVenta?: CuentaDeVenta;
    vendedorId: number;
    vendedor?: Usuario;
    trabajadorId: number;
    trabajador?: Usuario;
    formaDePagoId: number;
    formaDePago?: FormaDePago;
    usoCfdiId: number;
    usoCfdi?: UsoCFDI;
    metodoPagoId: number;
    metodoPago?: MetodoPago;
    subtotal: number;
    iva: number;
    total: number;
    importePagoREP: number;
    comentario: string;
    detalles?: GeneracionComprobanteDetalle[];
    pagoId: number;
    pago?: GeneracionComprobantePago;
    notaCreditoId: number;
    notaCredito?: GeneracionComprobanteNotaCredito;
    cancelacionId: number;
    cancelacion?: CancelarGeneracionComprobante;
    complementoPagoId: number;
    complementoPago?: GeneracionComprobanteComplementoPago;
    uuid: string;
    uuidsRelacionados: string;
    c_TipoRelacionId: number;
    c_TipoRelacion?: c_TipoRelacion;
}

export interface IGeneracionComprobanteFormValues extends Partial<GeneracionComprobante> {
    selectedIdEmpresa?: any;
}

export class GeneracionComprobanteFormValues implements IGeneracionComprobanteFormValues {
    id: number = 0;
    selectedIdEmpresa?: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoVentaId: number = 0;
    tipoVenta?: TipoVenta;
    tipoVentaCopiaId: number = 0;
    tipoVentaCopia?: TipoVenta;
    serieFolioDeVentaId: number = 0;
    serieFolioDeVenta?: SerieFolioDeVenta;
    serieFolioDeVentaCopiaId: number = 0;
    serieFolioDeVentaCopia?: SerieFolioDeVenta;
    folio: number = 0;
    fecha: Date = new Date();
    fechaCancelacion?: Date = undefined;
    clienteId: number = 0;
    cliente?: Cliente;
    monedaId: number = 0;
    moneda?: Moneda;
    tipoCambio: number = 0;
    cuentaDeVentaId: number = 0;
    cuentaDeVenta?: CuentaDeVenta;
    vendedorId: number = 0;
    vendedor?: Usuario;
    trabajadorId: number = 0;
    trabajador?: Usuario;
    formaDePagoId: number = 0;
    formaDePago?: FormaDePago
    usoCfdiId: number = 0
    usoCfdi?: UsoCFDI;
    metodoPagoId: number = 0;
    metodoPago?: MetodoPago;
    subtotal: number = 0;
    iva: number = 0;
    total: number = 0;
    importePagoREP: number = 0;
    comentario: string = "";
    detalles?: GeneracionComprobanteDetalle[];
    pagoId: number = 0;
    pago?: GeneracionComprobantePago;
    notaCreditoId: number = 0;
    notaCredito?: GeneracionComprobanteNotaCredito;
    cancelacionId: number = 0;
    cancelacion?: CancelarGeneracionComprobante;
    complementoPagoId: number = 0;
    complementoPago?: GeneracionComprobanteComplementoPago;
    uuid: string = "";
    uuidsRelacionados: string = "";
    c_TipoRelacionId: number = 0;
    c_TipoRelacion?: c_TipoRelacion;

    constructor(init?: GeneracionComprobante) {
        Object.assign(this, init);
    }
}


