import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Label, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import Direccion from "../../../Component/Direccion";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { EmpresaFormValues } from "../../../../app/models/configuracion/Empresa";

import MyDateInput from "../../../../app/common/form/MyDateInput";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsDirecciones, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import FileWidgetDropzone from "../../../../app/common/form/FileWidgetDropzone";
import { DireccionValues } from "../../../../app/models/configuracion/Direccion";
import BarraBotones from "../../../Component/BarraBotones";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraDireccion from "../../../Component/BarraDireccion";

interface idProps {
    id: number
}

export default observer(function EmpresaForm({ id }: idProps) {
    const firstElement = 1;
    const lastElement = 15;
    const { empresaStore, pacStore, listaDePrecioStore, c_RegimenFiscalStore } = useStore();
    const { regimenesFiscalesByDropdown } = c_RegimenFiscalStore
    const {
        createEmpresa,
        loadEmpresa,
        loadingInitial,
        empresaById,
        loading,
    } = empresaStore;
    const { modalStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const [empresa, setEmpresa] = useState(new EmpresaFormValues());
    const { ListaDePreciosByDropdown } = listaDePrecioStore;
    const { PacsByDropdown } = pacStore;
    
    const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());
    const [direccion, setDireccion] = useState(new DireccionValues());

    useEffect(() => {
        c_RegimenFiscalStore.getRegimenesFiscales();
        pacStore.getPacs().then((pacss) => { });
        listaDePrecioStore.getListasDePrecios().then((listasDePrecioss) => { });
    }, [c_RegimenFiscalStore, pacStore, listaDePrecioStore]);

    const validationSchema = Yup.object({
        clave: Yup.number().required("La clave de empresa es requerida."),
        nombre: Yup.string().required("El nombre de la empresa es requerido."),
        rfc: Yup.string().required("El RFC de la empresa es requerido."),
        listaDePrecioId: Yup.number()
            .min(1, "Favor de seleccionar una Lista de precio.")
            .required("La Lista de precio es requerido."),
        regimenFiscalId: Yup.number()
            .min(1, "Favor de seleccionar el regimen fiscal.")
            .required("El regimen fiscal es requerido.")
    });

    useEffect(() => {
        if (id) {
            loadEmpresa(id).then((empresa) => {
                debugger
                setEmpresa(new EmpresaFormValues(empresa));
                setFechaObservacion(new FechaObservacionValues(empresa?.fechaObservacion));
                setDireccion(new DireccionValues(empresa?.direccion));
            });
        } else {
            empresa.clave =
                empresaById.map((empresa) => empresa.clave).length > 0
                    ? Math.max(...empresaById.map((empresa) => empresa.clave)) + 1
                    : 1;
        }
    }, []);

    function handleSubmit(empresa: EmpresaFormValues) {
        debugger
        if (direccion.paisId > 0) {
            if (direccion.estadoId > 0) {
                if (empresa.fechaObservacion) {
                    empresa.fechaObservacion = fechaObservacion;
                }
                if (empresa.nombre !== null) {
                    empresa.direccion = direccion;
                    createEmpresa(empresa);
                } else {
                    toast.info("Favor de llenar campos");
                }
            } else {
                toast.info("Favor de llenar el campo Estado.");
            }
        } else {
            toast.info("Favor de llenar el campo Pais.");
        }
    }

    function enterInput(tabIndex: number) {
        enterInputs(tabIndex, firstElement, lastElement);
    }

    const tabuladorButton = (event: any) => {
        tabuladorButtons(event, firstElement, lastElement);
    };


    if (loadingInitial) return (<LoadingComponent content="Loading empresa"></LoadingComponent>);

    return (
        <Segment clearing>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                enableReinitialize
                initialValues={empresa}
            >
                {({ setFieldValue, values }) => (
                    <Form className="ui form">
                        <Segment clearing>
                            <Label as="a" color="red" ribbon>
                                Empresa
                            </Label>
                            <div></div>

                            <div className="cols">
                                <div className="col15">
                                    <MyTextInput placeholder="Clave" 
                                    //tabIndex={1} 
                                    label="Clave" 
                                    name="clave" 
                                    disabled={true} 
                                    enterInput={enterInput} />
                                </div>
                                <div className="col50" />

                                <div className="col10">
                                    <MyCheckInput placeholder="Demostración" name="demo" />
                                </div>
                            </div>

                            <div className="colsp"></div>
                            <div className="col60">
                                <MyTextInput placeholder="Nombre"   tabIndex={1} autoFocus={true} label="Nombre:" name="nombre" className="uiAnchoCombo heighttext"  enterInput={enterInput} />
                            </div>
                        </Segment>

                        <Segment clearing>
                            <Label as="a" color="blue" ribbon>
                                Generales
                            </Label>
                            <div></div>

                            <div className="col20">
                                <MyTextInput placeholder="RFC" tabIndex={2} label="RFC:" name="rfc" className="uiAnchoCombo heighttext"  enterInput={enterInput} />
                            </div>
                            <div className="colsp"></div>
                            <div className="col20">
                                <MyTextInput placeholder="CURP"   tabIndex={3} label="CURP:" name="curp" maxLength={18} className="uiAnchoCombo heighttext" tipoDato={enumTipoDato.Alfanumerico}  enterInput={enterInput} />
                            </div>
                            <div></div>

                            <div className="col20">
                                <MySelectInput
                                    placeholder={"Seleccionar"}
                                    label="Lista de Precio"
                                    name="listaDePrecioId"
                                    options={ListaDePreciosByDropdown}
                                    tabIndex={4}
                                    enterInput={enterInput}
                                    inputWidth="140px"
                                    menuWidth="300px"
                                />
                            </div>
                            <div className='col20'>
                                <MySelectInput
                                    placeholder="Seleccionar"
                                    label="Regimen Fiscal"
                                    name="regimenFiscalId"
                                    options={regimenesFiscalesByDropdown}
                                    tabIndex={5}
                                    enterInput={enterInput}
                                    inputWidth="140px"
                                    menuWidth="620px"
                                />
                            </div>
                        </Segment>
                        <Segment clearing>
                            <Button
                                type="button"
                                className="ui primary button"
                                tabIndex={5}
                                onKeyDown={tabuladorButton}
                                loading={loading}
                                onClick={() =>
                                    openModal(
                                        <Direccion
                                            direccion={direccion}
                                            setDireccion={(data) => {
                                                setFieldValue("direccion", data)
                                                empresa.direccion = data;
                                                setDireccion(new DireccionValues(data));
                                            }}
                                            closeModals={() => {closeModal();}}
                                            tabIndex={6}
                                        />,
                                        enumHeaderModalsDirecciones.empresa
                                    )
                                }
                                floated="left"
                                color="blue"
                            >
                                <Icon name="map marker alternate" />
                                Direccion
                            </Button>
                            <div className="col70">
                                <BarraDireccion
                                    direccion={values.direccion}
                                ></BarraDireccion>
                            </div>
                        </Segment>
                        <Segment clearing>
                            <Label as="a" color="blue" ribbon>
                                Certificados
                            </Label>
                            <div></div>
                            <div></div>
                            <div className="col30">
                                <MyTextInput placeholder="Contraseña llave privada" label="Contraseña llave privada" tipoDato={enumTipoDato.Contrasena} name="contrasenaClavePrivada"
                                    className="widthtext heighttext" tabIndex={6} enterInput={enterInput}
                                />
                            </div>
                            ?
                            <div></div>
                            <div className="col60Block">
                                <FileWidgetDropzone
                                    label="Archivo cer"
                                    files={values.fileCer}
                                    className="uiAnchoCombo heightFileWidgetDropzone input"
                                    acceptFileDrop={".cer"}
                                    name="cer"
                                />
                            </div>
                            <div className="col60Block">
                                <FileWidgetDropzone
                                    label="Archivo key"
                                    files={values.fileKey}
                                    className="uiAnchoCombo heightFileWidgetDropzone input"
                                    acceptFileDrop={".key"}
                                    name="key"
                                />
                            </div>
                            <div></div>
                            <div className="col20">
                                Fecha Inicio Vigencia
                                <MyDateInput
                                    placeholderText="Fecha Inicio Vigencia CFD"
                                    name="fechaInicioVigenciaCFD"
                                    dateFormat="dd/MM/yyyy"
                                    tabIndex={7} enterInput={enterInput}
                                />
                            </div>
                            <div className="colsp"></div>
                            <div className="col20">
                                Fecha Fin Vigencia
                                <MyDateInput
                                    placeholderText="Fecha Fin Vigencia CFD"
                                    name="fechaFinVigenciaCFD"
                                    dateFormat="dd/MM/yyyy"
                                    tabIndex={8} enterInput={enterInput}
                                />
                            </div>
                        </Segment>
                        <Segment clearing>
                            <Label as="a" color="blue" ribbon>
                                Pac
                            </Label>
                            <div></div>

                            <div className="col20">
                                <MySelectInput placeholder={"Seleccionar"} label="PAC" name="pacId" options={PacsByDropdown} tabIndex={9} enterInput={enterInput} />
                            </div>

                            <div className="col20">
                                <MyTextInput placeholder="Usuario PAC" label="Usuario Pac" name="usuarioPac" className="uiAnchoCombo heighttext" tabIndex={10} enterInput={enterInput} />
                            </div>
                            <div className="colsp"></div>
                            <div className="col20">
                                <MyTextInput placeholder="Usuario PAC Password" label="Password" name="usuarioPacPassword" className="uiAnchoCombo heighttext" tabIndex={11} enterInput={enterInput}
                                />
                            </div>
                            <div> </div>
                            <div className="col15">
                                <MyTextInput
                                    placeholder="Contraseña CIEC" label="Contraseña CIEC" tipoDato={enumTipoDato.Contrasena} name="ciec" className="uiAnchoCombo heighttext" tabIndex={12} enterInput={enterInput}
                                />
                            </div>
                            <div> </div>
                            <div className="col15">
                                <MyTextInput placeholder="Token Infinito" label="Token Infinito" tipoDato={enumTipoDato.Contrasena} name="tokenInfinito" className="uiAnchoCombo heighttext" tabIndex={13} enterInput={enterInput}
                                />
                            </div>
                        </Segment>
                        <Segment clearing>
                            <div className="col30Block">
                                <Button
                                    type="button"
                                    tabIndex={14}
                                    onKeyDown={tabuladorButton}
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() =>
                                        openModal(
                                            <FechaObservaciones
                                                fechaObservacion={fechaObservacion}
                                                setFechaObservacion={(data) => {setFieldValue("fechaObservacion", data)
                                                    empresa.fechaObservacion = data;
                                                    setFechaObservacion(new FechaObservacionValues(data));
                                                }}
                                                closeModals={() => {closeModal();}}
                                                tabIndex={lastElement}
                                            />,
                                            enumHeaderModalsFechasObservaciones.Empresa
                                        )
                                    }
                                    floated="left"
                                    color="blue"
                                >
                                    <Icon name="calendar alternate outline" />
                                    Fechas y Observaciones
                                </Button>
                            </div>
                            <div className="col80">
                                <BarraFechaObservacion
                                    fechaObservacion={values.fechaObservacion}
                                ></BarraFechaObservacion>
                            </div>
                        </Segment>
                        <BarraBotones
                            toLink="/empresas"
                            loading={loading}
                            tabIndex={15}
                            id={`id`}
                            firstElement={0}
                            lastElement={11}
                        ></BarraBotones>
                    </Form>
                )}
            </Formik>
        </Segment>
    );
});