export interface CorteDeCajaEfectivo {
    id: number;
    CantidadBillete20?: number;
    CantidadBillete50?: number;
    CantidadBillete100?: number;
    CantidadBillete200?: number;
    CantidadBillete500?: number;
    CantidadBillete1000?: number;
    CantidadMoneda10c?: number;
    CantidadMoneda20c?: number;
    CantidadMoneda50c?: number;
    CantidadMoneda1?: number;
    CantidadMoneda2?: number;
    CantidadMoneda5?: number;
    CantidadMoneda10?: number;
    CantidadMoneda20?: number;
    CantidadMoneda50?: number;
    CantidadMoneda100?: number;
}
export interface ICorteDeCajaEfectivoFormValues extends Partial<CorteDeCajaEfectivo> {
    id: any;
}
export class CorteDeCajaEfectivoFormValues implements ICorteDeCajaEfectivoFormValues {
    id: number = 0;
    CantidadBillete20?: number =0;
    CantidadBillete50?: number=0;
    CantidadBillete100?: number=0;
    CantidadBillete200?: number=0;
    CantidadBillete500?: number=0;
    CantidadBillete1000?: number=0;
    CantidadMoneda10c?: number=0;
    CantidadMoneda20c?: number=0;
    CantidadMoneda50c?: number=0;
    CantidadMoneda1?: number=0;
    CantidadMoneda2?: number=0;
    CantidadMoneda5?: number=0;
    CantidadMoneda10?: number=0;
    CantidadMoneda20?: number=0;
    CantidadMoneda50?: number=0;
    CantidadMoneda100?: number=0;
    constructor(init?: CorteDeCajaEfectivo) {
        Object.assign(this, init);
    }
}