import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PagoDe } from "../models/pagoDe";
import { entityByDropdown } from "../common/funciones/entityByDropdown";

export default class PagoDeStore {
    pagoDeRegistry = new Map<number, PagoDe>();
    selectedPagoDe: PagoDe | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadPagosDe = async () => {
        this.setLoadingInitial(true);
        try {
            const pagosDe = await agent.PagosDe.list();
            pagosDe.forEach(pagoDe => {
                this.setPagoDe(pagoDe);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadPagoDe = async (id: number) => {
        let pagoDe = this.getPagoDe(id);
        if (pagoDe) {
            this.selectedPagoDe = pagoDe;
            return pagoDe;

        } else {
            this.loadingInitial = true;
            try {
                pagoDe = await agent.PagosDe.details(id);
                this.selectedPagoDe = pagoDe;
                this.setLoadingInitial(false);
                return pagoDe;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setPagoDe = (pagoDe: PagoDe) => {
        this.pagoDeRegistry.set(pagoDe.id, pagoDe);
    }

    private getPagoDe = (id: number) => {
        return this.pagoDeRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createPagoDe = async (pagoDe: PagoDe) => {
        this.loading = true;
        try {
            await agent.PagosDe.create(pagoDe);
            runInAction(() => {
                this.pagoDeRegistry.set(pagoDe.id, pagoDe);
                this.selectedPagoDe = pagoDe;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updatePagoDe = async (pagoDe: PagoDe) => {
        this.loading = true;
        try {
            await agent.PagosDe.update(pagoDe);
            runInAction(() => {
                this.pagoDeRegistry.set(pagoDe.id, pagoDe);
                this.selectedPagoDe = pagoDe;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deletePagoDe = async (id: number) => {
        this.loading = true;
        try {
            await agent.PagosDe.delete(id);
            runInAction(() => {
                this.pagoDeRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get pagosDeByDropdown() {
        return entityByDropdown<PagoDe>(
            Array.from(this.pagoDeRegistry.values())
        );
    }

}