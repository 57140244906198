import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeVentaTipo } from "../../models/venta/CuentaDeVentaTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class CuentaDeVentaTipoStore {
    cuentaDeVentaTipoRegistry = new Map<number, CuentaDeVentaTipo>();
    selectedCuentaDeVentaTipo: CuentaDeVentaTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentaDeVentaTiposByDropdown(){
        let CuentaDeVentaTiposArreg=[new TipoDropDownValues()];
        let cuentaDeVentaTiposOpt =new TipoDropDownValues();
        const cvts=  Array.from(this.cuentaDeVentaTipoRegistry.values()).sort(
          (a, b) =>  {  if (a.nombre > b.nombre) return 1
            if (a.nombre < b.nombre) return -1
            return 0}
        );
        cvts.forEach((element) => {
            cuentaDeVentaTiposOpt.key = element.id;
            cuentaDeVentaTiposOpt.text = element.nombre;
            cuentaDeVentaTiposOpt.value = element.id;
            cuentaDeVentaTiposOpt.content = element.nombre;
          CuentaDeVentaTiposArreg.push(cuentaDeVentaTiposOpt);
          cuentaDeVentaTiposOpt =new TipoDropDownValues();
          
        });
        return CuentaDeVentaTiposArreg;
        }
    get cuentasDeVentasTiposByDate() {
        return Array.from(this.cuentaDeVentaTipoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadCuentasDeVentasTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasDeVentasTipos = await agent.CuentasDeVentasTipos.list();
            cuentasDeVentasTipos.forEach(cuentaDeVentaTipo => {
                this.setCuentaDeVentaTipo(cuentaDeVentaTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasDeVentasTipos = async () => {
        this.setLoadingInitial(true);
        const cuentasDeVentasTipos = await agent.CuentasDeVentasTipos.list();
        try {
            cuentasDeVentasTipos.forEach(cuentaDeVentaTipo => {
                this.setCuentaDeVentaTipo(cuentaDeVentaTipo);
            })
            this.setLoadingInitial(false);
            return cuentasDeVentasTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasDeVentasTipos;
        }
    }

    loadCuentaDeVentaTipo = async (id: number) => {
        let cuentaDeVentaTipo = this.getCuentaDeVentaTipo(id);
        if (cuentaDeVentaTipo) {
            this.selectedCuentaDeVentaTipo = cuentaDeVentaTipo;
            return cuentaDeVentaTipo;

        } else {
            this.loadingInitial = true;
            try {
                cuentaDeVentaTipo = await agent.CuentasDeVentasTipos.details(id);
                this.setCuentaDeVentaTipo(cuentaDeVentaTipo);
                this.selectedCuentaDeVentaTipo = cuentaDeVentaTipo;
                this.setLoadingInitial(false);
                return cuentaDeVentaTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setCuentaDeVentaTipo = (cuentaDeVentaTipo: CuentaDeVentaTipo) => {
        this.cuentaDeVentaTipoRegistry.set(cuentaDeVentaTipo.id, cuentaDeVentaTipo);
    }

    private getCuentaDeVentaTipo = (id: number) => {
        return this.cuentaDeVentaTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeVentaTipo = async (cuentaDeVentaTipo: CuentaDeVentaTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeVentasTipos.create(cuentaDeVentaTipo);
            runInAction(() => {
                this.cuentaDeVentaTipoRegistry.set(cuentaDeVentaTipo.id, cuentaDeVentaTipo);
                this.selectedCuentaDeVentaTipo = cuentaDeVentaTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteCuentaDeVentaTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeVentasTipos.delete(id);
            runInAction(() => {
                this.cuentaDeVentaTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
