export const enumHeaderModals = {
  Sucursal: 'Eliminar Sucursal',
  Perfil: 'Eliminar Perfil',
  Usuario: 'Eliminar Usuario',
  Moneda: 'Eliminar Moneda',
  Impuesto: 'Eliminar Impuesto',
  Cliente: 'Eliminar Cliente',
  cuentaDeCliente: 'Eliminar Cuenta Cliente',
  ConceptoCliente: 'Eliminar Concepto cliente',
  cuentaDeVenta: 'Eliminar Cuenta Venta',
  TipoVenta: 'Eliminar Tipo Venta',
  SerieFolioVenta: 'Eliminar Serie Folio de Venta',
  Almacen: 'Eliminar Almacen',
  Producto: 'Producto y Servicio',
  ConceptoDeInventario: 'Eliminar concepto de inventario',
  ListaDePrecio: 'Eliminar Lista de precio',
  cuentaDeCompra: 'Eliminar cuenta de compra',
  controlListaPrecio: 'Eliminar control lista de precio',
  TipoDeCompra: 'Eliminar Tipo de Compra',
  MovimientoCliente: 'Eliminar Movimiento de Cliente',
  MovimientoInventario: 'Eliminar Movimiento de Inventario',
  MovimientoDeProveedor: 'Eliminar Movimiento de Proveedor',
  Proveedor: 'Eliminar Proveedor',
  DetallesComprobante: 'Eliminar partida de Comprobante',
  CuentaFinanza: 'Eliminar Cuenta Finanza',
  ConceptoFinanza: 'Eliminar Concepto Finanza',
  Banco: 'Eliminar Banco',
  MovimientoFinanza: 'Eliminar Movimiento de Finanza',
  CapturaPago: 'Eliminar Captura de Pago',
  ProductoAyuda: 'Ayuda de productos',
}
export const enumHeaderModalsBusqueda = {
  ProductoBusqueda: 'Busqueda de productos',
  EmpresaBusqueda: 'Busqueda de empresa',
  SucursalBusqueda: 'Busqueda de sucursal',
  TipoVentaBusqueda: 'Busqueda de Tipo Venta',
  SerieBusqueda: 'Busqueda de Serie',
  ClienteBusqueda: 'Busqueda de Cliente',
  CuentaDeVentaBusqueda: 'Busqueda de Cuenta de Venta',
  VendedorBusqueda: 'Busqueda de Vendedor',
  TrabajadorBusqueda: 'Busqueda de Trabajador',
  MonedaBusqueda: 'Busqueda de Moneda',
  TipoComprobanteBusqueda: 'Busqueda de Tipo de Comprobante',
  TipoCompraBusqueda: 'Busqueda de Tipo Compra',
  CuentaDeCompraBusqueda: 'Busqueda de Cuenta de Compra',
  ProveedorBusqueda: 'Busqueda de Proveedor',
  CompradorBusqueda: 'Busqueda de Comprador',
  AlmacenBusqueda: 'Busqueda de Almacen',
  CantidadBusqueda: 'Busqueda de Cantidad',
  ConceptoInventarioBusqueda: 'Busqueda de Concepto de inventario',
}

export const enumHeaderModalsFechasObservaciones = {
  Empresa:'Fechas y Observaciones de Empresa',
  Sucursal:'Fechas y Observaciones de Sucursal',
  CuentaDeVenta:'Fechas y Observaciones de Cuenta de Venta',
  TipoDeVenta:'Fechas y Observaciones de Tipo de Venta',
  SerieFolioVenta:'Fechas y Observaciones de Serie folio de Venta',
  Moneda: 'Fechas y Observaciones de Moneda',
  Impuesto: 'Fechas y Observaciones de Impuesto',
  ConceptoCliente: 'Fechas y Observaciones de Concepto de Cliente',
  Almacen: 'Fechas y Observaciones de Almacen',
  Producto: 'Fechas y Observaciones de Producto y Servicio',
  ConceptoDeInventario: 'Fechas y Observaciones de Concepto de Inventario',
  cuentaDeCliente: 'Fechas y Observaciones de Cuenta de Cliente',
  ListaDePrecio: 'Fechas y Observaciones de Lista de precio',
  cuentaDeCompra: 'Fechas y Observaciones de Cuenta de Compra',
  SerieFolioCompra:'Fechas y Observaciones de Serie folio de Compra',
  movimientoInventario: 'Fechas y Observaciones de Movimientos de Inventario',
  MovimientoDeProveedor: 'Fechas y Observaciones de Movimientos de Proveedor',
  TipoDeCompra: 'Fechas y Observaciones de Tipo de Compra',
  MovimientoCliente: 'Fechas y Observaciones de Movimiento de Cliente',
  Cliente: 'Fechas y Observaciones de Cliente',
  Proveedor: 'Fechas y Observaciones de Proveedor',
  CuentaFinanza: 'Fechas y Observaciones de Cuenta Finanza',
  ConceptoFinanza: 'Fechas y Observaciones de Concepto Finanza',
  Banco: 'Fechas y Observaciones del Banco',
  MovimientoFinanza: 'Fechas y Observaciones de Movimiento de Finanza',
  CapturaPagos: 'Fechas y Observaciones de Captura de Pagos'
}
export const enumHeaderModalsDirecciones = {
  empresa: 'Direccion de Empresa',
  sucursal: 'Direccion de Sucursal',
  proveedor: 'Direccion de Proveedor',
  cliente: 'Direccion del Cliente'
}