import { Empresa } from "./Empresa";
import { Sucursal } from "./Sucursal";


export interface General {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    RutaFisica?: string;
    RutaLogica?: string;
    RutaRespaldos?: string;
    utilizaSSL: boolean;
    ServidorSMTP?: string;
    Usuario?: string;
    Contrasena?: string;
    CorreoElectronico?: string;
    Destinatario?: string;
    correoAutomatico?: boolean
    //gestionCliente
    serieFolioDeVentaId?: number;
    tipoVentaId?: number;
}

export class GeneralFormValues implements General {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    RutaFisica?: string = "";
    RutaLogica?: string = "";
    RutaRespaldos?: string = "";
    utilizaSSL: boolean = false;
    ServidorSMTP?: string = "";
    Usuario?: string = "";
    Contrasena?: string = "";
    CorreoElectronico?: string = "";
    Destinatario?: string = "";
    correoAutomatico?: boolean = false;
    serieFolioDeVentaId?: number = 0;
    tipoVentaId?: number = 0;
    constructor(init?: General) {
        Object.assign(this, init);
    }
}