import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import SucursalForm from '../form/SucursalForm';

export default observer(function SucursalList() {
  const { tabsStore, modalStore, sucursalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { deleteSucursal, sucursalesByDate, loading } = sucursalStore;
  const [target, setTarget] = useState('');

  function deleteConfirmed(e: string, id: number) {
    deleteSucursal(id);
    setTarget(e);
    closeModal();
  }
  function deletedeclined() {
    closeModal();
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Empresa</Table.HeaderCell>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sucursalesByDate.map(sucursal => (
          <Table.Row key={sucursal.id}>
            <Table.Cell>{sucursal.empresa?.clave}</Table.Cell>
            <Table.Cell>{sucursal.clave}</Table.Cell>
            <Table.Cell>{sucursal.nombre}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => tabsStore.addTab('Editar Sucursal', <SucursalForm id={sucursal.id} />)}
                floated='right' content='Editar' color='blue' />
              <Button name={sucursal.id}
                loading={loading && target === String(sucursal.id)}
                onClick={(e) =>
                  openModal(
                    <DeleteModals
                      deleteConfirmed={deleteConfirmed}
                      deletedeclined={deletedeclined}
                      e={e.currentTarget.name.toString()}
                      id={sucursal.id}
                    />,
                    enumHeaderModals.Sucursal
                  )
                }
                floated='right'
                content='Eliminar'
                color='red'
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})