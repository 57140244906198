import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { ConceptoDeInventario, ConceptoDeInventarioFormValues } from "../../../../app/models/inventario/ConceptoDeInventario";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import * as Yup from "yup";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function ConceptoDeInventarioForm({ id }: idProps) {
  const { conceptoDeInventarioStore, conceptoDeInventarioTipoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createConceptoDeInventario, updateConceptoDeInventario, loadConceptoDeInventario, loadingInitial, conceptosDeInventariosById, loading } = conceptoDeInventarioStore;
  const { conceptosDeInventariosTipoByDropdownByClave, getConceptosDeInventariosTipos } = conceptoDeInventarioTipoStore;
  const [conceptoDeInventario, setConceptoDeInventario] = useState(new ConceptoDeInventarioFormValues());
  const [ConceptoDeInventarioTipos, setConceptoDeInventarioTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const firstElement = 0;
  const lastElement = 7;

  useEffect(() => {
    getConceptosDeInventariosTipos().then((conDeInvTip) => {
      conDeInvTip.forEach((element) => {
        let conceptoInvTipOpt = { key: 999, value: 999, text: "" };
        conceptoInvTipOpt.key = element.id;
        conceptoInvTipOpt.value = element.clave;
        conceptoInvTipOpt.text = element.nombre;
        ConceptoDeInventarioTipos.push(conceptoInvTipOpt);
      });
    })
  }, [getConceptosDeInventariosTipos, ConceptoDeInventarioTipos, setConceptoDeInventarioTipos])

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de el concepto de inventario es requerida."),
    nombre: Yup.string().required("El nombre de el concepto de inventario es requerida."),
    conceptoDeInventarioTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo de concepto de inventario.")
      .required("El tipo de concepto de inventario es requerido.")
  });

  useEffect(() => {
    if (id) {
      loadConceptoDeInventario(id).then((conceptoDeInventario) => {
        setConceptoDeInventario(new ConceptoDeInventarioFormValues(conceptoDeInventario))
      });
    }
    else {
      conceptoDeInventario.clave =
        conceptosDeInventariosById.map((conceptoDeInventario) => conceptoDeInventario.clave).length > 0
          ? Math.max(...conceptosDeInventariosById.map((conceptoDeInventario) => conceptoDeInventario.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(conceptoDeInventario: ConceptoDeInventario) {
    if (conceptoDeInventario.conceptoDeInventarioTipo) {
      const tipoConceptoDeInventarioSelected = ConceptoDeInventarioTipos.find(
        (a) => a.key === conceptoDeInventario.conceptoDeInventarioTipoId
      );
      if (tipoConceptoDeInventarioSelected) {
        conceptoDeInventario.conceptoDeInventarioTipo.nombre = tipoConceptoDeInventarioSelected.text;
        conceptoDeInventario.conceptoDeInventarioTipo.id = tipoConceptoDeInventarioSelected.key;
        conceptoDeInventario.conceptoDeInventarioTipo.clave = tipoConceptoDeInventarioSelected.value;
      }
    }
    if (conceptoDeInventario.conceptoDeInventarioTipoId.toString() !== "0" && conceptoDeInventario.nombre !== null) {
      conceptoDeInventario.id > 0 ? updateConceptoDeInventario(conceptoDeInventario) : createConceptoDeInventario(conceptoDeInventario);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Concepto de Inventario' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={conceptoDeInventario} >

        {({ values, setFieldValue }) => {
          return (
            <Form className='ui form'>
              <Segment clearing>
                <Label as='a' color='red' ribbon>
                  Datos del concepto de inventario
                </Label>
                <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
              </Segment>
              <Segment clearing>
                <Label as='a' color='blue' ribbon>
                  Datos especí­ficos
                </Label>
                <MyTextInput tabIndex={2} enterInput={enterInput} label="Nombre corto:" placeholder="Nombre corto" name="nombreCorto" />
                <MySelectInput
                  placeholder="Concepto Inventario Tipo"
                  label="Tipo de Concepto de Inventario"
                  name="conceptoDeInventarioTipoId"
                  enterInput={enterInput}
                  tabIndex={3}
                  options={conceptosDeInventariosTipoByDropdownByClave}
                  inputWidth="85px"
                  menuWidth="320px"
                />
              </Segment>

              <Segment clearing>
                <Button
                  type="button"
                  tabIndex={4}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(fechaO) => { setFieldValue("fechaObservacion", fechaO) }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.ConceptoDeInventario
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
                <div className="col80">
                  <BarraFechaObservacion
                    fechaObservacion={values.fechaObservacion}
                  ></BarraFechaObservacion>
                </div>
              </Segment>
              <Segment>
                <BarraBotones
                  toLink="/conceptosDeInventarios"
                  loading={loading}
                  tabIndex={5}
                  firstElement={firstElement}
                  lastElement={lastElement}
                ></BarraBotones>
              </Segment>
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
})