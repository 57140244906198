import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Perfil } from "../../models/configuracion/Perfil";
import { toast } from "react-toastify";
import { history } from "../../..";

export default class PerfilStore {
  perfilRegistry = new Map<number, Perfil>();
  selectedPerfil: Perfil | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get perfilesById() {
    return Array.from(this.perfilRegistry.values()).sort((a, b) => a.clave - b.clave);
  }

  loadPerfiles = async () => {
    this.setLoadingInitial(true);
    try {
      const perfiles = await agent.Perfiles.list();
      perfiles.forEach((perfil) => {
        this.setPerfil(perfil);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getPerfiles = async () => {
    this.setLoadingInitial(true);
    const perfiles = await agent.Perfiles.list();
    try {
      perfiles.forEach((perfil) => {
        this.setPerfil(perfil);
      });
      this.setLoadingInitial(false);
      return perfiles;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return perfiles;
    }
  };

  loadPerfil = async (id: number) => {
    let perfil = this.getPerfil(id);
    if (perfil) {
      this.selectedPerfil = perfil;
      return perfil;
    } else {
      this.loadingInitial = true;
      try {
        perfil = await agent.Perfiles.details(id);
        this.setPerfil(perfil);
        this.selectedPerfil = perfil;
        this.setLoadingInitial(false);
        return perfil;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setPerfil = (perfil: Perfil) => {
    this.perfilRegistry.set(perfil.id, perfil);
  };

  private getPerfil = (id: number) => {
    return this.perfilRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createPerfil = async (perfil: Perfil) => {
    this.loading = true;
    try {
      await agent.Perfiles.create(perfil);
      runInAction(() => {
        //   this.perfilRegistry.set(perfil.id, perfil);
        this.selectedPerfil = perfil;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Perfil creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updatePerfil = async (perfil: Perfil) => {
    this.loading = true;
    try {
      
      await agent.Perfiles.update(perfil);
      runInAction(() => {
        this.selectedPerfil = perfil;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Perfil modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePerfil = async (id: number) => {
    this.loading = true;
    try {
      await agent.Perfiles.delete(id);
      runInAction(() => {
        this.perfilRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Perfil eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar moneda");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
