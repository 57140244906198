import { observer } from 'mobx-react-lite';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import CuentaDeProveedorForm from '../form/CuentaDeProveedorForm';
import { useState } from 'react';

export default observer(function CuentaProveedorList() {
    const { cuentaDeProveedorStore, tabsStore } = useStore();
    const { modalStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { deleteCuentaDeProveedor, cuentasDeProveedoresById, loading } = cuentaDeProveedorStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteCuentaDeProveedor(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Abreviación</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {cuentasDeProveedoresById.map(cuentaDeProveedor => (
                    <Table.Row key={cuentaDeProveedor.id}>
                        <Table.Cell>{cuentaDeProveedor.clave}</Table.Cell>
                        <Table.Cell>{cuentaDeProveedor.nombre}</Table.Cell>
                        <Table.Cell>{cuentaDeProveedor.abreviación}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Crear Cuenta de Proveedor', <CuentaDeProveedorForm id={cuentaDeProveedor.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button
                                name={cuentaDeProveedor.id}
                                loading={loading && target === String(cuentaDeProveedor.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={cuentaDeProveedor.id}
                                        />,
                                        enumHeaderModals.Impuesto
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
})