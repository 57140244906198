import { useField, useFormikContext } from "formik";
import { Label, Dropdown } from "semantic-ui-react";
import "../../layout/styles.css";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { OnChangeProps, OnKeyDownProps } from "../../models/myProps";
import { findDOMNode } from "react-dom";


interface Props {
  placeholder: string;
  name: string;
  disabled?: boolean;
  fieldName?: string;
  options: any[];
  label?: string;
  tabIndex?: number;
  className?: string;
  enterInput?: (e: number) => void;
  autoFocus?: boolean;
  onChangeProps?: (props: OnChangeProps) => void;
  onChange?: (idSelected: any, setFieldValue?: any, index?: any, values?: any, setValues?: any, fieldName?: any, value?: any) => void;
  onChangeTable?: (idSelected: any, setFieldValue?: any, index?: any, values?: any) => boolean;
  // onKeyUp?: (id: any, setFieldValue?: any, key?: any) => void;
  onKeyUp?: (id: any, setFieldValue?: any, values?: any) => void;
  onKeyDown?: (id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, values?: any) => void;
  onKeyDownProps?: (props: OnKeyDownProps) => void;
  arrayHelpers?: any;
  //  value?: any;
  index?: any;
  ref?: any
  onClick?: (index: number) => void;
  selectWithLabel?: boolean;
  noResultsMessage?: string;
  onBlur?: (idSelected: any, setFieldValue?: any, index?: any, values?: any) => void;
  inputWidth?: any;
  menuWidth?: any;
  fluid?: boolean;
  multiple?: boolean;
}
//export default function CustomSelect({ field,meta,helpers, form, ...props }: any){
const MySelectInput = forwardRef((props: Props, ref) => {
  const inputRef = useRef<any>(null);
  const { setFieldValue, values, setValues } = useFormikContext();
  const [field, meta, helpers] = useField(props.name);
  const [selectLabel, setSelectLabel] = useState("");
  useImperativeHandle(ref, () => ({

    Focus: () => {
      const node = findDOMNode(inputRef.current) as HTMLElement;
      if (node) {
        const input = node.querySelector('input');
        if (input) input.focus();
      }
    },
    Reset: () => {
      setFieldValue(props.name, 0);
    },
    GetText: () => {
      return field.value;
    },

  }));
  const onKeyDown = (event: any) => {
    props.onKeyDown?.(field.value, setFieldValue, event, props?.arrayHelpers, values);
    props.onKeyDownProps?.({ value: field.value, setFieldValue: setFieldValue, event: event, arrayHelpers: props?.arrayHelpers, values: values, index: props.index });
    props.onKeyUp?.(field.value, setFieldValue, values);
    if (event.key === "Enter" || event.key === "Tab") {
      let element = event.target;
      let tabIndex = element.tabIndex;
      props.enterInput?.(tabIndex);
      event.preventDefault();
    }
  };
  const entroOnClick = (event: any) => {
    props.onClick?.(props.index);
  };
  const dropdownStyle = {
    '--input-width': props.inputWidth || '100px',
    '--menu-width': props.menuWidth || '200px',
  };
  return (
    <>
      {props.label ? (
        <label className="heighttext"> {props.label}:</label>
      ) : null}
      <div className="ui form mini">
        <Dropdown
          ref={inputRef}
          search
          compact
          selection
          fluid={props.fluid}
          multiple={props.multiple}
          //className="widthTextTable heighttext input "
          className={
            props.className ? props.className :
              props.inputWidth ? "custom-dropdown" :
                " widthTextTable heighttext input"
          }
          style={dropdownStyle}
          disabled={props.disabled}
          options={props.options || []}
          value={field.value}
          onKeyDown={onKeyDown}
          onChange={(e, d) => {
            let valor = true;
            props.onChangeProps?.({ index: props.index, name: props.fieldName, value: d.value, values: values, setFieldValue: setFieldValue });
            props.onChange?.(d.value, setFieldValue, props.index, values, setValues, "field.name", "d?.target?.value");
            valor = props.onChangeTable ? props.onChangeTable?.(d.value, setFieldValue, props.index, values) : true;
            if (valor) {
              helpers.setValue(d.value);
              if (props.selectWithLabel)
                setSelectLabel(d.options?.find(options => options.key === d.value)?.content?.toString() ?? "");
            }
          }}
          onBlur={(e) => { helpers.setTouched(true); props.onBlur?.(setFieldValue) }}
          placeholder={props.placeholder}
          tabIndex={props.tabIndex}
          searchInput={{ autoFocus: props.autoFocus }}
          onClick={entroOnClick}
          noResultsMessage={props.noResultsMessage}
        />
        {props.selectWithLabel && selectLabel && selectLabel !== "Seleccionar" && field?.value !== 0 ? <Label basic >{selectLabel}</Label> : null}

      </div>
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </>
  );
});
export default MySelectInput;
