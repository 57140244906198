import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import UsuarioForm from "../form/UsuarioForm";

export default observer(function UsuarioList() {
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { usuarioStore } = useStore();
  const { deleteUsuario, usuariosById, loading } = usuarioStore;
  const [target, setTarget] = useState("");
  function deleteConfirmed(e: string, id: number) {
    deleteUsuario(id);
    setTarget(e);
    closeModal();
  }
  function deletedeclined() {
    closeModal();
  }

  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Clave</Table.HeaderCell>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Empresa</Table.HeaderCell>
            <Table.HeaderCell>Sucursal</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {usuariosById.map((usuario) => (
            <Table.Row key={usuario.id}>
              <Table.Cell>{usuario.clave}</Table.Cell>
              <Table.Cell>{usuario.nombre}</Table.Cell>
              <Table.Cell>{usuario.empresa?.nombre}</Table.Cell>
              <Table.Cell>{usuario.sucursal?.nombre}</Table.Cell>
              <Table.Cell>
                <Button onClick={() => tabsStore.addTab('Editar Usuario', <UsuarioForm id={usuario.id} />)}
                  floated="right"
                  content="Editar"
                  color="blue"
                />
                <Button
                  name={usuario.id}
                  loading={loading && target === String(usuario.id)}
                  onClick={(e) =>
                    openModal(
                      <DeleteModals
                        deleteConfirmed={deleteConfirmed}
                        deletedeclined={deletedeclined}
                        e={e.currentTarget.name.toString()}
                        id={usuario.id}
                      />,
                      enumHeaderModals.Usuario
                    )
                  }
                  floated="right"
                  content="Eliminar"
                  color="red"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
});