import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { CorteDeCajaFiltro } from "../../models/venta/CorteDeCajaFiltro";
import { GeneracionComprobante } from "../../models/venta/GeneracionComprobante";

export default class GeneracionComprobanteStore {
    generacionComprobanteRegistry = new Map<number, GeneracionComprobante>();
    selectedGeneracionComprobante: GeneracionComprobante | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    copiarComprobanteParams: PaginationSpecParams = new PaginationFormValues();
    comprobante: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this)
    }

    get tipoVentaById() {
        return Array.from(this.generacionComprobanteRegistry.values()).sort();
    }
    //loadSerieByIdTipoVenta 


    obtenerTickets = async (filtros: PaginationSpecParams) => {
        try {

            const generacionComprobante = await agent.GeneracionComprobantes.obtenerTickets(filtros);

            if (generacionComprobante) {
                this.setGeneracionComprobante(generacionComprobante);
                //this.selectedGeneracionComprobante = generacionComprobante
                return generacionComprobante;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };
    obtenerVentasPorCategoria = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            const ventasPorCategoria = await agent.GeneracionComprobantes.obtenerVentasPorCategoria(filtros);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
            if (ventasPorCategoria) {
                return ventasPorCategoria;
            }

        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };
    getCotizacion = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            debugger
            const generacionComprobante = await agent.GeneracionComprobantes.obtenerCotizacion(filtros);
            debugger
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
            if (generacionComprobante) {
                return generacionComprobante;
            }

        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    getComprobantesPorUUIDRelacionado = async (filtros: PaginationSpecParams) => {
        this.setLoadingInitial(true);
        try {
            const generacionComprobantesUUids = await agent.GeneracionComprobantes.ObtenerComprobantesPorUUIDRelacionado(filtros);
            return generacionComprobantesUUids;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }


    cargarComprobante = async (filtros: PaginationSpecParams) => {
        try {
            const generacionComprobante = await agent.GeneracionComprobantes.obtenerComprobantePorFolio(filtros);
            if (generacionComprobante) {
                this.setGeneracionComprobante(generacionComprobante);
                //this.selectedGeneracionComprobante = generacionComprobante
                return generacionComprobante;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };


    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoVentaId && filtros.serieId) {
            try {
                const generacionComprobante = await agent.GeneracionComprobantes.obtenerFolio(filtros);

                return generacionComprobante;

            } catch (error) {
                console.log(error);
            }
        } else {
            // this.serieFolioByTipoVentaRegistry.clear();
        }
    };

    obtenerImportesVentasDeContado = async (filtros: CorteDeCajaFiltro) => {
        if (filtros.empresaId && filtros.sucursalId) {
            try {
                const generacionComprobante = await agent.GeneracionComprobantes.obtenerImportesVentasDeContado(filtros);
                return generacionComprobante;
            } catch (error) {
                console.log(error);
            }
        }
    };

    obtenerImportesVentasDeCredito = async (filtros: CorteDeCajaFiltro) => {
        if (filtros.empresaId && filtros.sucursalId) {
            try {
                const generacionComprobante = await agent.GeneracionComprobantes.obtenerImportesVentasDeCredito(filtros);

                return generacionComprobante;
            } catch (error) {
                console.log(error);
            }
        }
    };
    obtenerImportesFacturasDeAnticipo = async (filtros: CorteDeCajaFiltro) => {
        if (filtros.empresaId && filtros.sucursalId) {
            try {
                const generacionComprobante = await agent.GeneracionComprobantes.obtenerImportesFacturasDeAnticipo(filtros);

                return generacionComprobante;
            } catch (error) {
                console.log(error);
            }
        }
    };


    get tiposVentasByDate() {
        return Array.from(this.generacionComprobanteRegistry.values()).sort((a) => a.id);
    }


    loadGeneracionComprobantes = async () => {
        this.setLoadingInitial(true);
        try {
            const generacionComprobantes = await agent.GeneracionComprobantes.list();
            generacionComprobantes.forEach(generacionComprobante => {
                this.setGeneracionComprobante(generacionComprobante);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getGeneracionComprobantes = async () => {
        this.setLoadingInitial(true);
        const generacionComprobantes = await agent.GeneracionComprobantes.list();
        debugger
        try {
            generacionComprobantes.forEach(generacionComprobante => {
                this.setGeneracionComprobante(generacionComprobante);
            })
            this.setLoadingInitial(false);
            return generacionComprobantes;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return generacionComprobantes;
        }
    }

    loadGeneracionComprobante = async (id: number) => {
        let generacionComprobante = this.getGeneracionComprobante(id);
        if (generacionComprobante) {
            this.selectedGeneracionComprobante = generacionComprobante;
            return generacionComprobante;
        } else {
            this.loadingInitial = true;
            try {
                generacionComprobante = await agent.GeneracionComprobantes.details(id);
                this.setGeneracionComprobante(generacionComprobante);
                this.selectedGeneracionComprobante = generacionComprobante;
                this.setLoadingInitial(false);
                return generacionComprobante;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setGeneracionComprobante = (generacionComprobante: GeneracionComprobante) => {
        this.generacionComprobanteRegistry.set(generacionComprobante.id, generacionComprobante);
    }

    private getGeneracionComprobante = (id: number) => {
        return this.generacionComprobanteRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createGeneracionComprobante = async (generacionComprobante: GeneracionComprobante) => {
        this.loading = true;
        try {
            var nom = await agent.GeneracionComprobantes.create(generacionComprobante);
            runInAction(() => {
                this.selectedGeneracionComprobante = generacionComprobante;
                this.editMode = false;
                this.loading = false;
            })
            toast.success(nom + "Comprobante creado con éxito");
            return nom;
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateGeneracionComprobante = async (generacionComprobante: GeneracionComprobante) => {
        this.loading = true;
        try {
            var nom = await agent.GeneracionComprobantes.create(generacionComprobante);
            runInAction(() => {
                this.selectedGeneracionComprobante = generacionComprobante;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Comprobante Actualizado con éxito");
            return nom;
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    imprimirComprobante = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                var nom = "";
                nom = await agent.GeneracionComprobantes.imprimir(id);
                runInAction(() => { })
                this.loading = false;
                toast.success("Impresion de Comprobante con éxito");
                return nom;
            } catch (error) {
                toast.success("Problema al imprimir el Comprobante");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    }

    deleteGeneracionComprobante = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.GeneracionComprobantes.delete(id);
                runInAction(() => {
                    this.generacionComprobanteRegistry.delete(id);
                    this.loading = false;
                })
                toast.success("Comprobante eliminado con éxito");

            } catch (error) {
                toast.success("Problema al eliminar el Comprobante");
                console.log(error);
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    }
    resetGeneracionComprobanteParams() {
        this.copiarComprobanteParams = new PaginationFormValues();
    }
    resetComprobanteParams() {
        this.comprobante = new PaginationFormValues();
    }
}
