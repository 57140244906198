import { makeAutoObservable } from "mobx";

export default class ModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  modal = {
    open: false,
    body: null,
    header: "",
    headerIcon: undefined,
    sizes: undefined,
    closeIcon: false,
  };

  openModal = (content: any, header: string, sizes: any = "mini", closeIcon: boolean = false, headerIcon?: any) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.header = header;
    this.modal.headerIcon = headerIcon;
    this.modal.sizes = sizes;
    this.modal.closeIcon = closeIcon;

  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
