import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import { Vehiculo } from "../../models/gestionFlotilla/Vehiculo";
import { history } from "../../..";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { store } from "./../store";
import { setDate } from "../../common/util/util";

export default class VehiculoStore {
    vehiculoRegistry = new Map<number, Vehiculo>();
    selectedVehiculo: Vehiculo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get vehiculoById() {
        return Array.from(this.vehiculoRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }
    get vehiculosByDate() {
        return Array.from(this.vehiculoRegistry.values()).sort((a) => a.id);
    }


    loadVehiculos = async () => {
        this.setLoadingInitial(true);
        try {
            const vehiculos = await agent.Vehiculos.list();
            vehiculos.forEach(vehiculos => {
                vehiculos.venceSeguro = new Date(vehiculos.venceSeguro);
                vehiculos.renovarPlaca = new Date(vehiculos.renovarPlaca);
                setDate<Vehiculo>(vehiculos);
                this.setVehiculo(vehiculos);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    getVehiculos = async () => {
        this.setLoadingInitial(true);
        const vehiculos = await agent.Vehiculos.list();
        try {

            vehiculos.forEach(vehiculo => {
                this.setVehiculo(vehiculo);
            })
            this.setLoadingInitial(false);
            return vehiculos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return vehiculos;
        }
    }

    loadVehiculo = async (id: number) => {
        debugger
        let vehiculo = this.getVehiculo(id);
        if (vehiculo) {
            this.selectedVehiculo = vehiculo;
            return vehiculo;
        } else {
            this.loadingInitial = true;
            try {
                vehiculo = await agent.Vehiculos.details(id);
                vehiculo.venceSeguro = new Date(vehiculo.venceSeguro);
                vehiculo.renovarPlaca = new Date(vehiculo.renovarPlaca);
                
                this.setVehiculo(vehiculo);
                this.selectedVehiculo = vehiculo;
                this.setLoadingInitial(false);
                return vehiculo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setVehiculo = (vehiculo: Vehiculo) => {
        this.vehiculoRegistry.set(vehiculo.id, vehiculo);
    }

    private getVehiculo = (id: number) => {
        return this.vehiculoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createVehiculo = async (vehiculo: Vehiculo) => {
       
        this.loading = true;
        try {
            debugger;
            await agent.Vehiculos.create(vehiculo);
            runInAction(() => {
                this.selectedVehiculo = vehiculo;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Vehiculo creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateVehiculo = async (vehiculo: Vehiculo) => {
        this.loading = true;
        try {
            debugger
            await agent.Vehiculos.create(vehiculo);
            runInAction(() => {
                this.selectedVehiculo = vehiculo;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Vehiculo Actualizado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar el Vehículo");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteVehiculo = async (id: number) => {
        this.loading = true;
        try {
            await agent.Vehiculos.delete(id);
            runInAction(() => {
                this.vehiculoRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Vehículo eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar el Vehículo");
            runInAction(() => {
                this.loading = false;
            });
        }
    }

}
