import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label, GridColumn, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import {
  Perfil,
  PerfilFormValues,
} from "../../../../app/models/configuracion/Perfil";
import * as Yup from "yup";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function PerfilForm({ id }: idProps) {
  const { perfilStore } = useStore();
  const {
    perfilesById,
    createPerfil,
    loadPerfil,
    loadingInitial,

    loading
  } = perfilStore;

  const [perfil, setPerfil] = useState(new PerfilFormValues());
  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre de perfil es requerida."),
  });
  
  useEffect(() => {
    if (id) {
      loadPerfil(id).then((perfil) =>
        setPerfil(new PerfilFormValues(perfil))
      );
    } else {
      perfil.clave =
        perfilesById.map((perfil) => perfil.clave).length > 0
          ? Math.max(...perfilesById.map((perfil) => perfil.clave)) + 1
          : 1;
    }
  }, []);

  function handleSubmit(perfil: Perfil) {
    debugger
     createPerfil(perfil);
  }
  if (loadingInitial) return <LoadingComponent content="Loading perfil" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={perfil}
      >
        {({ handleSubmit, dirty, isValid }) => (
          <Form className="ui form">
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Perfil
              </Label>
              <div></div>
              <div className="cols">
                <div className="col15">
                  <MyTextInput placeholder="Clave" label="Clave"  name="clave"  disabled={true}/>
                </div>
                <div className="col50" />
              </div>

              <div className="col60">
                <MyTextInput autoFocus={true} placeholder="Nombre" label="Nombre" name="nombre"/>
              </div>
            </Segment>
            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Permisos para Ventas
              </Label>
              <div></div>
              <Grid>
              <GridColumn mobile={16} tablet={8} computer={5}>
              <div className="col60">
                <MyTextInput placeholder="Tipo Venta" label="Tipo Venta" name="tipoVenta"/>
              </div>
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={5}>
              <div className="col60">
                <MyTextInput placeholder="Serie" label="Serie" name="seriesVentas"/>
              </div>
              </GridColumn>
              </Grid>
              </Segment>

              <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Permisos para Compras
              </Label>
              <div></div>
              <Grid>
              <GridColumn mobile={16} tablet={8} computer={5}>
              <div className="col60">
                <MyTextInput placeholder="Tipo Venta" label="Tipo Compra" name="tipoCompra"/>
              </div>
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={5}>
              <div className="col60">
                <MyTextInput placeholder="Serie" label="Serie" name="seriesCompras"/>
              </div>
              </GridColumn>
              </Grid>
              </Segment>

            <BarraBotones
                            toLink="/perfiles"
                            loading={loading}
                            tabIndex={15}
                            id={`id`}
                            firstElement={0}
                            lastElement={11}
                        ></BarraBotones>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
