import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TipoVenta, TipoVentaFormValues } from "../../../../app/models/venta/TipoVenta";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons } from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function TipoVentaForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 9;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { tipoSATStore, tipoVentaStore, tipoVentaTipoStore, almacenStore, conceptoDeInventarioStore, cuentaDeClienteStore, conceptoClienteStore } = useStore();

  const {
    createTipoVenta,
    loadTipoVenta,
    loadingInitial,
    tipoVentaById,
    loading
  } = tipoVentaStore;
  const { tipoVentaTiposByDropdown, getTipoVentaTipos } = tipoVentaTipoStore;
  const { tiopsSATsByDropdown, getTiposSATs } = tipoSATStore;
  const { almacenesByDropdown, getAlmacenes } = almacenStore;
  const { conceptosInventariosByDropdown, getConceptosDeInventarios } = conceptoDeInventarioStore;
  const { cuentasClientesByDropdown, getCuentasClientes } = cuentaDeClienteStore;
  const { conceptosClientesByDropdown, getConceptosClientes } = conceptoClienteStore;
  const [tipoVenta, setTipoVenta] = useState(new TipoVentaFormValues());
  const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());

  useEffect(() => {
    getTipoVentaTipos()
    getAlmacenes()
    getConceptosDeInventarios()
    getCuentasClientes()
    getConceptosClientes()
    getTiposSATs()
  }, [getTipoVentaTipos,getAlmacenes,getConceptosDeInventarios,getConceptosClientes,getCuentasClientes,getTiposSATs])

  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave del tipo de venta es requerida.'),
    nombre: Yup.string().required('El nombre del tipo de venta es requerido.'),
    tipoVentaTipoId: Yup.number()
      .min(1, "Favor de seleccionar un Tipo de Tipo Venta.")
      .required("El tipo de Tipo Venta es requerido."),
  });

  useEffect(() => {
    if (id) {
      loadTipoVenta(id).then(tipoVenta => {

        setTipoVenta(new TipoVentaFormValues(tipoVenta))
        setFechaObservacion(
          new FechaObservacionValues(tipoVenta?.fechaObservacion)
        );
      }
      );
    } else {
      tipoVenta.clave =
        tipoVentaById.map((tipoVenta) => tipoVenta.clave).length > 0
          ? Math.max(...tipoVentaById.map((tipoVenta) => tipoVenta.clave)) + 1
          : 1;
    }
  }, []);
  //}, [id,loadTipoVenta,tipoVenta,tipoVentaById]);


  function handleSubmit(tipoVenta: TipoVenta) {
    if (/*cuentaDeVenta.cuentaDeVentaTipoId.toString() !== "0" &&*/ tipoVenta.nombre !== null) {
      //tipoVenta.id > 0 ? updateTipoVenta(tipoVenta) : 
      createTipoVenta(tipoVenta);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Tipo Venta' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={tipoVenta}>

        {({ isValid, dirty, handleSubmit,setFieldValue,values }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Tipos de Ventas
              </Label>
              <div></div>
              <div className='cols'>
                <div className='col15'>
                  <MyTextInput disabled={true} placeholder="Clave" label="Clave" name="clave" tabIndex={0} enterInput={enterInput} />
                </div>
                <div className='col50' />
              </div>
              <div className='col60'>
                <MyTextInput autoFocus={true} placeholder="Nombre" label="Nombre" name="nombre" tabIndex={1} enterInput={enterInput} />
              </div>
              <div></div>
              <MySelectInput
                tabIndex={2}
                placeholder="Seleccionar"
                label="Tipo"
                name="tipoVentaTipoId"
                options={tipoVentaTiposByDropdown}
                enterInput={enterInput}
                inputWidth="140px"
                menuWidth="250px"
              />
              <MySelectInput
                tabIndex={3}
                placeholder="Seleccionar"
                label="Tipo SAT"
                name="tipoSATId"
                options={tiopsSATsByDropdown}
                selectWithLabel={true}
                enterInput={enterInput}
                inputWidth="140px"
                menuWidth="450px"
              />


            </Segment>

            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Afectación de Inventario
              </Label>
              <div ></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Seleccionar"
                  label="Almacen"
                  name="almacenId"
                  options={almacenesByDropdown}
                  enterInput={enterInput}
                  inputWidth="200px"
                  menuWidth="300px"
                />
              </div>
              <div className='colsp'></div>
              <div className='col35'>
                <div className='col35'>
                  <MySelectInput
                    tabIndex={5}
                    placeholder="Seleccionar"
                    label="Concepto"
                    name="conceptoDeInventarioId"
                    options={conceptosInventariosByDropdown}
                    enterInput={enterInput}
                    inputWidth="200px"
                    menuWidth="300px"
                  />
                </div>
              </div>
            </Segment>


            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Afectación de inventario por movimientos de entrada por traspaso.
              </Label>
              <div ></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Seleccionar"
                  label="Almacen"
                  name="almacenTraspasoEntradaId"
                  options={almacenesByDropdown}
                  enterInput={enterInput}
                  inputWidth="200px"
                  menuWidth="300px"
                />
              </div>
              <div className='colsp'></div>
              <div className='col35'>
                <div className='col35'>
                  <MySelectInput
                    tabIndex={5}
                    placeholder="Seleccionar"
                    label="Concepto"
                    name="conceptoDeInventarioTraspasoEntradaId"
                    options={conceptosInventariosByDropdown}
                    enterInput={enterInput}
                    inputWidth="200px"
                    menuWidth="300px"
                  />
                </div>
              </div>
            </Segment>

            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Afectación de Cartera de Clientes
              </Label>
              <div ></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={6}
                  placeholder="Seleccionar"
                  label="Cuenta"
                  name="cuentaDeClienteId"
                  options={cuentasClientesByDropdown}
                  enterInput={enterInput}
                  inputWidth="200px"
                  menuWidth="300px"
                />
              </div>
              <div className='colsp'></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={7}
                  placeholder="Seleccionar"
                  label="Concepto"
                  name="conceptoDeClienteId"
                  options={conceptosClientesByDropdown}
                  enterInput={enterInput}
                  inputWidth="200px"
                  menuWidth="300px"
                />
              </div>


            </Segment>
            <Segment clearing>
              <div className='col60'>
                <MyTextInput placeholder="Cuenta Contable" label="Cuenta Contable" name="codigoContable" tabIndex={8} enterInput={enterInput} />
              </div>
            </Segment>
            <Segment clearing>
              <Button
                type="button"
                class="ui primary button"
                tabIndex={9}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <FechaObservaciones
                      fechaObservacion={fechaObservacion}
                      setFechaObservacion={(data) => {
                        setFieldValue("fechaObservacion", data)
                        tipoVenta.fechaObservacion = data;
                        setFechaObservacion(new FechaObservacionValues(data));
                    }}
                      closeModals={closeModals}
                      tabIndex={10}
                    />,
                    enumHeaderModalsFechasObservaciones.TipoDeVenta
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="calendar alternate outline" />
                Fechas y Observaciones
              </Button>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/TiposVentas"
                loading={loading}
                tabIndex={5}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>

    </Segment>
  );
})