import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { CuentaDeVentaTipo } from "./CuentaDeVentaTipo";

export interface CuentaDeVenta {
    id: number;
    clave: number;
    nombre: string;
    abreviacion: string;
    cuentaDeVentaTipoId: number;
    cuentaDeVentaTipo?: CuentaDeVentaTipo;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export interface ICuentaDeVentaFormValues extends Partial<CuentaDeVenta> {
    cuentaDeVentaTipoId: any;

}

export class CuentaDeVentaFormValues implements ICuentaDeVentaFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    abreviacion: string = "";
    cuentaDeVentaTipoId: number = 0;
    cuentaDeVentaTipo?: CuentaDeVentaTipo;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    constructor(init?: CuentaDeVenta) {
        Object.assign(this, init);
    }
}
