import { Dropdown, Menu } from 'semantic-ui-react'
import { useStore } from '../stores/store';
import EmpresaDashboard from '../../features/Configuration/Empresas/dashboard/EmpresaDashboard';
import SucursalDashboard from '../../features/Configuration/Sucursales/dashboard/SucursalDashboard';
import UsuarioDashboard from '../../features/Configuration/Usuarios/dashboard/UsuarioDashboard';
import PerfilDashboard from '../../features/Configuration/Perfiles/dashboard/PerfilDashboard';
import MonedaDashboard from '../../features/Configuration/Monedas/dashboard/MonedaDashboard';
import ImpuestoDashboard from '../../features/Configuration/Impuestos/dashboard/ImpuestoDashboard';
import GeneralesForm from '../../features/Configuration/Generales/form/GeneralesForm';

import CuentaVentaDashboard from '../../features/Venta/CuentasVentas/dashboard/CuentaVentaDashboard';
import TipoVentaDashboard from '../../features/Venta/TiposVentas/dashboard/TipoVentaDashboard';
import SerieFolioDashboard from '../../features/Venta/SeriesFolios/dashboard/SerieFolioDashboard';
import GeneracionComprobanteForm from '../../features/Venta/GeneracionComprobantes/form/GeneracionComprobanteForm';
import CorteDeCajaForm from '../../features/Venta/CorteDeCaja/form/CorteDeCajaForm';
import FacturaGlobalForm from '../../features/Venta/FacturaGlobal/form/FacturaGlobalForm';
import ListaDePrecioDashboard from '../../features/ControlPrecio/ListadePrecio/dashboard/ListaDePrecioDashboard';
import ControlListaDePrecioForm from '../../features/ControlPrecio/ControlListaDePrecio/form/ControlListaDePrecioForm';
import ReporteVentaForm from '../../features/Venta/ReporteVenta/form/ReporteVentaForm';
import ReporteDetalleVentaForm from '../../features/Venta/ReporteDetalleVenta/form/ReporteDetalleVentaForm';
import ReporteProductosVendidosForm from '../../features/Venta/ReporteProducto/ReporteProductosVendidosForm';
import SurtidoComprobanteForm from '../../features/Venta/SurtidoComprobantes/form/SurtidoComprobanteForm';
import ComprobantesAExcelForm from '../../features/Venta/ComprobantesAExcel/form/ComprobantesAExcelForm';
import AlmacenDashboard from '../../features/Inventario/Almacen/dashboard/AlmacenDashboard';
import ConceptoDeInventarioDashboard from '../../features/Inventario/ConceptoDeInventario/dashboard/ConceptoDeInventarioDashboard';
import ProductoDashboard from '../../features/Inventario/Producto/dashboard/ProductoDashboard';
import MovimientoInventarioForm from '../../features/Inventario/MovimientoInventario/form/MovimientoInventarioForm';
import TraspasoEntreSucursalForm from '../../features/Inventario/TraspasoEntreSucursal/form/TraspasoEntreSucursalForm';
import CuentaDeClienteDashboard from '../../features/GestionCliente/CuentaCliente/dashboard/CuentaDeClienteDashboard';
import ConceptoClienteDashboard from '../../features/GestionCliente/ConceptoCliente/dashboard/ConceptoClienteDashboard';
import ClienteDashboard from '../../features/GestionCliente/Cliente/dashboard/ClienteDashboard';
import MovimientoClienteForm from '../../features/GestionCliente/MovimientoCliente/form/MovimientoClienteForm';
import CapturaPagosForm from '../../features/GestionCliente/CapturaPagos/form/CapturaPagosForm';
import VerificadorGestionClienteForm from '../../features/GestionCliente/VerificadorGestionCliente/form/VerificadorGestionClienteForm';
import SaldoClienteForm from '../../features/GestionCliente/SaldoCliente/form/SaldoClienteForm';
import EstadoCuentaClienteForm from '../../features/GestionCliente/EstadoCuentaCliente/form/EstadoCuentaClienteForm';
import VerificadorPagosForm from '../../features/GestionCliente/VerificadorPagos/form/VerificadorPagosForm';
import AnaliticaClienteForm from '../../features/GestionCliente/AnaliticaCliente/form/AnaliticaClienteForm';
import BitacoraComprobanteClienteForm from '../../features/GestionCliente/BitacoraComprobanteCliente/form/BitacoraComprobanteClienteForm';
import NotFound from './NotFound';
import VerificadorAlmacenForm from '../../features/Inventario/VerificadorInventario/form/VerificadorAlmacenForm';
import ExistenciaForm from '../../features/Inventario/Existencia/form/ExistenciaForm';
import KardexForm from '../../features/Inventario/Kardex/form/KardexForm';
import CuentaDeCompraDashboard from '../../features/Compra/CuentasCompras/dashboard/CuentaDeCompraDashboard';
import TipoDeCompraDashboard from '../../features/Compra/TiposDeCompras/dashboard/TipoDeCompraDashboard';
import SerieFolioCompraDashboard from '../../features/Compra/SeriesFoliosCompras/dashboard/SerieFolioCompraDashboard';
import GeneracionComprobanteCompraForm from '../../features/Compra/GeneracionComprobantesCompras/form/GeneracionComprobanteCompraForm';
import ReporteCompraForm from '../../features/Compra/ReporteCompra/form/ReporteCompraForm';
import ReporteDetalleCompraForm from '../../features/Compra/ReporteDetalleCompra/form/ReporteDetalleCompraForm';
import SurtidoComprobanteCompraForm from '../../features/Compra/SurtidoComprobantesCompras/form/SurtidoComprobanteCompraForm';
import CuentaDeProveedorDashboard from '../../features/GestionProveedor/CuentaProveedor/dashboard/CuentaDeProveedorDashboard';
import ConceptoDeProveedorDashboard from '../../features/GestionProveedor/ConceptoProveedor/dashboard/ConceptoDeProveedorDashboard';
import ProveedorDashboard from '../../features/GestionProveedor/Proveedor/dashboard/ProveedorDashboard';
import MovimientoDeProveedorForm from '../../features/GestionProveedor/MovimientoDeProveedor/Form/MovimientoDeProveedorForm';
import VerificadorGestionProveedorForm from '../../features/GestionProveedor/VerificadorGestionProveedor/form/VerificadorGestionProveedorForm';
import EstadoCuentaProveedorForm from '../../features/GestionProveedor/EstadoCuentaProveedor/form/EstadoCuentaProveedorForm';
import AnaliticaProveedorForm from '../../features/GestionProveedor/AnaliticaProveedor/form/AnaliticaProveedorForm';
import BitacoraComprobanteProveedorForm from '../../features/GestionProveedor/BitacoraComprobanteProveedor/form/BitacoraComprobanteProveedorForm';
import InsertaObtenerInfoForm from '../../features/HerramientaExtra/InsertaObtenerInfoCatalogos/Form/InsertaObtenerInfoForm';
import ReporteProductosCompradosForm from '../../features/Compra/ReporteProducto/ReporteProductosCompradosForm';
import VentasPorCategoria from '../../features/Venta/Graficas/VentasPorCategoria';

import VehiculoForm from '../../features/GestionVehiculo/Vehiculo/form/VehiculoForm';
import PersonalForm from '../../features/GestionVehiculo/Personal/form/PersonalForm';
import CuentaFinanzaForm from '../../features/Finanzas/CuentaFinanza/form/CuentaFinanzaForm';
import ConceptoFinanzaForm from '../../features/Finanzas/ConceptoFinanza/form/ConceptoFinanzaForm';
import BancoForm from '../../features/Finanzas/Banco/form/BancoForm';
import MovimientoFinanzaForm from '../../features/Finanzas/MovimientoFinanza/form/MovimientoFinanzaForm';
import PuntoVentaForm from '../../features/PuntoVentas/PuntoVenta/form/PuntoVentaForm';
import EstadoCuentaAlmacenForm from '../../features/Inventario/EstadoCuentaAlmacenes/form/EstadoCuentaAlmacenForm';
import PedidoForm from '../../features/PuntoVentas/Pedidos/form/PedidoForm';
import InventarioFisicoForm from '../../features/Inventario/InventarioFisico/form/InventarioFisicoForm';
import ImpuestoProductoDashboard from '../../features/Inventario/Impuestos/dashboard/ImpuestoProductoDashboard';
import { useState } from 'react';
import { number } from 'yup';

export default function MenuVertical() {
  const { tabsStore } = useStore();
  const [cont,setCont] = useState(1)
  return (
    <Menu vertical className='verticalMenu'>
      <Dropdown item text='Configuracion'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='building' text='Empresas' onClick={() => tabsStore.addTab('Empresas', <EmpresaDashboard />)} />
          <Dropdown.Item icon='warehouse' text='Sucursales' onClick={() => tabsStore.addTab('Sucursales', <SucursalDashboard />)} />
          <Dropdown.Item icon='user' text='Usuario' onClick={() => tabsStore.addTab('Usuario', <UsuarioDashboard />)} />
          <Dropdown.Item icon='settings' text='Perfiles' onClick={() => tabsStore.addTab('Perfiles', <PerfilDashboard />)} />
          <Dropdown.Item icon='money' text='Monedas' onClick={() => tabsStore.addTab('Monedas', <MonedaDashboard />)} />
          <Dropdown.Item icon='money bill alternate outline' text='Impuestos' onClick={() => tabsStore.addTab('Impuestos', <ImpuestoDashboard />)} />
          <Dropdown.Item icon='cog' text='Generales' onClick={() => tabsStore.addTab('Generales', <GeneralesForm />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Venta' >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='clipboard' text='Cuentas de Ventas' onClick={() => tabsStore.addTab('Cuentas de Ventas', <CuentaVentaDashboard />)} />
          <Dropdown.Item icon='file code' text='Tipos de Ventas' onClick={() => tabsStore.addTab('Tipos de Ventas', <TipoVentaDashboard />)} />
          <Dropdown.Item icon='clone' text='Series y Folios' onClick={() => tabsStore.addTab('Series y Folios', <SerieFolioDashboard />)} />
          <Dropdown.Item icon='compass' text='Generacion de Comprobantes' onClick = {
            
            () => {
              tabsStore.addTab('Generacion de Comprobantes'+cont, <GeneracionComprobanteForm />)
              setCont(cont+1);
            }
        } />
          <Dropdown.Item icon='compass' text='Corte de Caja' onClick={() => tabsStore.addTab('Corte de Caja', <CorteDeCajaForm />)} />
          <Dropdown.Item icon='compass' text='Factura Global' onClick={() => tabsStore.addTab('Factura Global', <FacturaGlobalForm />)} />
          <Dropdown item text='Control de Precios'>
            <Dropdown.Menu className='verticalMenu'>
              <Dropdown.Item icon='clipboard' text='Listas de precios' onClick={() => tabsStore.addTab('Listas de precios', <ListaDePrecioDashboard />)} />
              <Dropdown.Item icon='clone' text='Control de Listas de Precios' onClick={() => tabsStore.addTab('Control de Listas de Precios', <ControlListaDePrecioForm />)} />
              <Dropdown.Item icon='file code' text='Detalle de Producto' />
              <Dropdown.Item text='------------Reportes-------------' />
              <Dropdown.Item icon='file code' text='Detalle Listas de precios' onClick={() => tabsStore.addTab('Detalle Listas de precios', <ReporteDetalleVentaForm />)} />
              <Dropdown.Item icon='file code' text='Bitacora de Cambios' />
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='file alternate outline' text='Reporte de Ventas' onClick={() => tabsStore.addTab('Reporte Ventas', <ReporteVentaForm />)} />
          <Dropdown.Item icon='file alternate' text='Reporte detalle de Ventas' onClick={() => tabsStore.addTab('Reporte Detalle de Ventas', <ReporteDetalleVentaForm />)} />
          <Dropdown.Item icon='file alternate' text='Reporte de productos más, menos y no vendidos' onClick={() => tabsStore.addTab('Reporte de productos más, menos y no vendidos', < ReporteProductosVendidosForm />)} />
          <Dropdown.Item icon='copy outline' text='Surtido de comprobantes' onClick={() => tabsStore.addTab('Surtido de comprobantes', <SurtidoComprobanteForm />)} />
          <Dropdown.Item icon='copy outline' text='Comprobantes a Excel' onClick={() => tabsStore.addTab('Comprobantes a Excel', <ComprobantesAExcelForm />)} />
          <Dropdown.Item text='------------Graficas-------------' />
          <Dropdown.Item icon='line graph' text='Ventas Por Categoria' onClick={() => tabsStore.addTab('Ventas Por Categoria', <VentasPorCategoria />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Punto de ventas' >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='clipboard' text='Punto de venta' onClick={() => tabsStore.addTab('Punto de Venta', <PuntoVentaForm />)} />
          <Dropdown.Item icon='clipboard' text='Pedidos' onClick={() => tabsStore.addTab('Pedidos', <PedidoForm />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Gestion de Clientes'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='list alternate' text='Cuentas Cartera de Clientes' onClick={() => tabsStore.addTab('Cuentas Cartera de Clientes', <CuentaDeClienteDashboard />)} />
          <Dropdown.Item icon='external square alternate' text='Conceptos Cartera de Clientes' onClick={() => tabsStore.addTab('Conceptos Cartera de Clientes', <ConceptoClienteDashboard />)} />
          <Dropdown.Item icon='id badge outline' text='Clientes' onClick={() => tabsStore.addTab('Clientes', <ClienteDashboard />)} />
          <Dropdown.Item icon='id badge outline' text='Movimientos de Clientes' onClick={() => tabsStore.addTab('Movimientos de Clientes', <MovimientoClienteForm />)} />
          <Dropdown.Item icon='id badge outline' text='Captura de pagos' onClick={() => tabsStore.addTab('Captura de pagos', <CapturaPagosForm />)} />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='id badge outline' text='Verificador de Gestion de Clientes' onClick={() => tabsStore.addTab('Verificador de Gestion de Clientes', <VerificadorGestionClienteForm />)} />
          <Dropdown.Item icon='id badge outline' text='Saldos de Clientes' onClick={() => tabsStore.addTab('Saldos de Clientes', <SaldoClienteForm />)} />
          <Dropdown.Item icon='id badge outline' text='Estado de cuenta Cliente' onClick={() => tabsStore.addTab('Estado de cuenta Cliente', <EstadoCuentaClienteForm />)} />
          <Dropdown.Item icon='id badge outline' text='Verificador de Pagos' onClick={() => tabsStore.addTab('Verificador de Pagos', <VerificadorPagosForm />)} />
          <Dropdown.Item icon='id badge outline' text='Analíticas de Clientes' onClick={() => tabsStore.addTab('Analíticas de Clientes', <AnaliticaClienteForm />)} />
          <Dropdown.Item icon='id badge outline' text='Bitacora de Comprobante de Clientes' onClick={() => tabsStore.addTab('Bitacora de Comprobante de Clientes', <BitacoraComprobanteClienteForm />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Inventario'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='block layout' text='Almacen' onClick={() => tabsStore.addTab('Almacen', <AlmacenDashboard />)} />
          <Dropdown.Item icon='exchange' text='Conceptos de Inventarios' onClick={() => tabsStore.addTab('Conceptos de Inventarios', <ConceptoDeInventarioDashboard />)} />
          <Dropdown.Item icon='cubes' text='Producto o Servicio' onClick={() => tabsStore.addTab('Producto o Servicio', <ProductoDashboard />)} />
          <Dropdown.Item icon='align center' text='Movimientos de Inventario' onClick={() => tabsStore.addTab('Movimientos de Inventario', <MovimientoInventarioForm />)} />
          <Dropdown.Item icon='align center' text='Traspasos Entre Sucursales' onClick={() => tabsStore.addTab('Traspasos Entre Sucursales', <TraspasoEntreSucursalForm />)} />
          <Dropdown.Item icon='cubes' text='Lineas de Artículos' onClick={() => tabsStore.addTab('Lineas de Artículos', <NotFound />)} />
          <Dropdown.Item icon='money bill alternate outline' text='Impuestos Productos' onClick={() => tabsStore.addTab('Impuestos Productos', <ImpuestoProductoDashboard />)} />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='align center' text='Verificador de Almacen' onClick={() => tabsStore.addTab('Verificador de Almacen', <VerificadorAlmacenForm />)} />
          <Dropdown.Item icon='cubes' text='Estado de cuenta' onClick={() => tabsStore.addTab('Estado de cuenta de almacen', < EstadoCuentaAlmacenForm />)} />
          <Dropdown.Item icon='cubes' text='Existencias' onClick={() => tabsStore.addTab('Existencias', <ExistenciaForm />)} />
          <Dropdown.Item icon='cubes' text='Inventario Fisico' onClick={() => tabsStore.addTab('Inventario Fisico', <InventarioFisicoForm />)} />
          <Dropdown.Item icon='cubes' text='Toma de Inventario Fisico' onClick={() => tabsStore.addTab('Toma de Inventario Fisico', <NotFound />)} />
          <Dropdown.Item icon='cubes' text='Kardex' onClick={() => tabsStore.addTab('Kardex', <KardexForm />)} />
          <Dropdown.Item icon='cubes' text='Traspasos por Recibir' onClick={() => tabsStore.addTab('Traspasos por Recibir', <NotFound />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Compra'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='clipboard outline' text='Cuentas de Compras' onClick={() => tabsStore.addTab('Cuentas de Compras', <CuentaDeCompraDashboard />)} />
          <Dropdown.Item icon='file outline' text='Tipos de Compras' onClick={() => tabsStore.addTab('Tipos de Compras', <TipoDeCompraDashboard />)} />
          <Dropdown.Item icon='clone outline' text='Series y Folios de Compras' onClick={() => tabsStore.addTab('Series y Folios de Compras', <SerieFolioCompraDashboard />)} />
          <Menu.Item icon='shopping cart' name='Generación de Comprobantes de Compras' onClick={() => tabsStore.addTab('Generación de Comprobantes de Compras', <GeneracionComprobanteCompraForm />)} />
          <Dropdown item text='Control de Costos por Proveedor'>
            <Dropdown.Menu className='verticalMenu'>
              <Dropdown.Item icon='clone' text='Control de Listas de Costos' onClick={() => tabsStore.addTab('Control de Listas de Costos', <NotFound />)} />
              <Dropdown.Item icon='file code' text='Detalle de Producto' onClick={() => tabsStore.addTab('Detalle de Producto', <NotFound />)} />
              <Dropdown.Item text='------------Reportes-------------' />
              <Dropdown.Item icon='file code' text='Detalle Listas de costos' onClick={() => tabsStore.addTab('Detalle Listas de costos', <NotFound />)} />
              <Dropdown.Item icon='file code' text='Bitacora de Cambios' onClick={() => tabsStore.addTab('Bitacora de Cambios', <NotFound />)} />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='compass' text='Reporte de Compras'
            onClick={() => tabsStore.addTab('Reporte de Compras', <ReporteCompraForm />)} />
          <Dropdown.Item icon='compass' text='Reporte detalle de Compras' onClick={() => tabsStore.addTab('Reporte detalle de Compras', <ReporteDetalleCompraForm />)} />
          <Dropdown.Item icon='compass' text='Reporte de productos más, menos y no comprados' onClick={() => tabsStore.addTab('Reporte de productos más, menos y no comprados', <ReporteProductosCompradosForm />)} />
          <Dropdown.Item icon='compass' text='Comprobante por Surtir' onClick={() => tabsStore.addTab('Comprobante por Surtir', <SurtidoComprobanteCompraForm />)} />
          <Dropdown.Item icon='copy outline' text='Comprobantes de compras a Excel' onClick={() => tabsStore.addTab('Comprobantes de compras a Excel', <NotFound />)} />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Gestion de Proveedores'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='list alternate outline' text='Cuentas Cartera de Proveedores' onClick={() => tabsStore.addTab('Cuentas Cartera de Proveedores', <CuentaDeProveedorDashboard />)} />
          <Dropdown.Item icon='external alternate' text='Conceptos Cartera de Proveedores' onClick={() => tabsStore.addTab('Conceptos Cartera de Proveedores', <ConceptoDeProveedorDashboard />)} />
          <Dropdown.Item icon='id badge' text='Proveedores' onClick={() => tabsStore.addTab('Proveedores', <ProveedorDashboard />)} />
          <Dropdown.Item icon='external alternate' text='Movimientos de Proveedores' onClick={() => tabsStore.addTab('Movimientos de Proveedores', <MovimientoDeProveedorForm />)} />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='id badge outline' text='Verificador de Proveedores' onClick={() => tabsStore.addTab('Verificador de Proveedores', <VerificadorGestionProveedorForm />)} />
          <Dropdown.Item icon='id badge outline' text='Saldos de Proveedores' onClick={() => tabsStore.addTab('Saldos de Proveedores', <NotFound />)} />
          <Dropdown.Item icon='id badge outline' text='Estado de cuenta Proveedor' onClick={() => tabsStore.addTab('Estado de cuenta Proveedor', <EstadoCuentaProveedorForm />)} />
          <Dropdown.Item icon='id badge outline' text='Analíticas de proveedores' onClick={() => tabsStore.addTab('Analíticas de proveedores', <AnaliticaProveedorForm />)} />
          <Dropdown.Item icon='id badge outline' text='Bitacora de Comprobantes de Proveedores' onClick={() => tabsStore.addTab('Bitacora de Comprobantes de Proveedores', <BitacoraComprobanteProveedorForm />)} />
        </Dropdown.Menu>
      </Dropdown>


      <Dropdown item text='Control de Flotilla' disabled >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Vehiculo' onClick={() => tabsStore.addTab('Vehiculo', <VehiculoForm />)} />
          <Dropdown.Item icon='user circle' text='Personal' onClick={() => tabsStore.addTab('Personal', <PersonalForm />)} />
          <Dropdown.Item icon='user circle' text='Servicios/Matenimiento' />
          <Dropdown.Item icon='user circle' text='Cargas de Combustibles y Lubricantes' />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='user circle' text='Historico LLantas' />
          <Dropdown.Item icon='user circle' text='Historico Baterias' />
          <Dropdown.Item icon='user circle' text='Detalle de cargas de Comb./Lubri.' />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Finanzas' disabled >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Cuentas de Finanzas' onClick={() => tabsStore.addTab('Cuentas de Finanzas', <CuentaFinanzaForm />)} />
          <Dropdown.Item icon='truck' text='Conceptos de finanzas' onClick={() => tabsStore.addTab('Conceptos de finanzas', <ConceptoFinanzaForm />)} />
          <Dropdown.Item icon='truck' text='Bancos' onClick={() => tabsStore.addTab('Bancos', <BancoForm />)} />
          <Dropdown.Item icon='user circle' text='Movimientos Finanzas' onClick={() => tabsStore.addTab('Movimientos Finanzas', <MovimientoFinanzaForm />)} />
          <Dropdown.Item icon='user circle' text='Generacion de Cheques' />
          <Dropdown.Item icon='user circle' text='Generacion de Transferencias' />
          <Dropdown.Item icon='user circle' text='Caja chica' />
          <Dropdown.Item icon='truck' text='Captura de Gastos' />
          <Dropdown.Item icon='truck' text='Captura de Vales' />
          <Dropdown.Item icon='truck' text='Conciliacion Bancaria' />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='truck' text='Estado de Cuenta' />
          <Dropdown.Item icon='truck' text='Detalle de Gastos' />
          <Dropdown.Item icon='truck' text='Detalle de Vales' />
          <Dropdown.Item icon='truck' text='Detalle de Caja chica' />
          <Dropdown.Item icon='truck' text='Detalle de Cheques' />
          <Dropdown.Item icon='truck' text='Detalle de Transferencia' />
          <Dropdown.Item icon='truck' text='Detalle de Movimientos' />


        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text='Fiscal' disabled >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Control CFDI' />
          <Dropdown.Item icon='user circle' text='Buzon Tributario' />
          <Dropdown.Item icon='truck' text='Consulta EFOS' />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='Contabilidad' disabled >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Cuentas Contables' />
          <Dropdown.Item icon='user circle' text='Centros de costos' />
          <Dropdown.Item icon='truck' text='Tipo de Póliza' />
          <Dropdown.Item icon='truck' text='Captura de Póliza' />
          <Dropdown.Item icon='truck' text='Captura de Activos Fijos' />
          <Dropdown.Item icon='user circle' text='Cedula de depreciacion' />
          <Dropdown.Item icon='truck' text='Estado de Cuenta Contable' />
          <Dropdown.Item icon='truck' text='Estado Centro ICG' />
          <Dropdown.Item icon='user circle' text='Poliza de Apertura' />
          <Dropdown.Item icon='truck' text='Contabilidad Electronica SAT' />
          <Dropdown.Item icon='truck' text='DIOT' />
          <Dropdown.Item text='------------Reportes-------------' />
          <Dropdown.Item icon='truck' text='Detalle de pólizas' />
          <Dropdown.Item icon='user circle' text='Balance' />
          <Dropdown.Item icon='truck' text='Balanza de comprobación' />
          <Dropdown.Item icon='truck' text='Estado de Resultados' />
          <Dropdown.Item icon='user circle' text='Relacion analíticas' />
          <Dropdown.Item icon='truck' text='Mayor General' />
          <Dropdown.Item icon='truck' text='Control Global' />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text='Producción' disabled >
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Captura de Producción' />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text='Herramienta Extra'>
        <Dropdown.Menu className='verticalMenu'>
          <Dropdown.Item icon='truck' text='Inserta y Obtener Información de Catálogos'
            onClick={() => tabsStore.addTab('Inserta y Obtener Información de Catálogos', <InsertaObtenerInfoForm />)} />
        </Dropdown.Menu>
      </Dropdown>

    </Menu>
  )
}
