import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { Departamento } from "../../models/gestionFlotilla/Departamento";

export default class DepartamentoStore {
    departamentoRegistry = new Map<number, Departamento>();
    selectedDepartamento: Departamento | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get departamentosByDropdown() {
        debugger
        let departamentosArreg = [new TipoDropDownValues()];
        let departamentosOpt = new TipoDropDownValues();
        const cvts = Array.from(this.departamentoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            departamentosOpt.key = element.id;
            departamentosOpt.text = element.nombre;
            departamentosOpt.value = element.id;
            departamentosOpt.content = element.nombre;
            departamentosArreg.push(departamentosOpt);
            departamentosOpt = new TipoDropDownValues();

        });
        return departamentosArreg;
    }
    get departamentosByDate() {
        return Array.from(this.departamentoRegistry.values()).sort((a) => a.id);
    }


    loadDepartamentos = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const departamentos = await agent.Departamentos.list();

            departamentos.forEach(departamento => {
                this.setDepartamento(departamento);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getDepartamentos = async () => {
        debugger
        this.setLoadingInitial(true);
        const departamentos = await agent.Departamentos.list();
        try {
            departamentos.forEach(departamento => {
                this.setDepartamento(departamento);
            })
            this.setLoadingInitial(false);
            return departamentos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return departamentos;
        }
    }

    loadDepartamento = async (id: number) => {
        let departamento = this.getDepartamento(id);
        if (departamento) {
            this.selectedDepartamento = departamento;
            return departamento;
        } else {
            this.loadingInitial = true;
            try {
                departamento = await agent.Departamentos.details(id);
                this.setDepartamento(departamento);
                this.selectedDepartamento = departamento;
                this.setLoadingInitial(false);
                return departamento;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setDepartamento = (departamento: Departamento) => {
        this.departamentoRegistry.set(departamento.id, departamento);
    }

    private getDepartamento = (id: number) => {
        return this.departamentoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createDepartamento = async (departamento: Departamento) => {
        this.loading = true;
        try {
            await agent.Departamentos.create(departamento);
            runInAction(() => {
                this.departamentoRegistry.set(departamento.id, departamento);
                this.selectedDepartamento = departamento;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteDepartamento = async (id: number) => {
        this.loading = true;
        try {
            await agent.Departamentos.delete(id);
            runInAction(() => {
                this.departamentoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
