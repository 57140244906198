import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ListaDePrecio } from "../../models/controlPrecio/ListaDePrecio";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";

export default class ListaDePrecioStore {
  listaDePrecioRegistry = new Map<number, ListaDePrecio>();
  selectedListaDePrecio: ListaDePrecio | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get listaDePrecioById() {
    return Array.from(this.listaDePrecioRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  get listasDePreciosByDate() {
    return Array.from(this.listaDePrecioRegistry.values()).sort((a) => a.id);
  }

  loadListaDePrecios = async () => {
    this.setLoadingInitial(true);
    try {
      const listasDePrecios = await agent.ListasDePrecios.list();
      listasDePrecios.forEach((listaDePrecio) => {
        setDate<ListaDePrecio>(listaDePrecio);
        this.setListaDePrecio(listaDePrecio);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getListasDePrecios = async () => {
    this.setLoadingInitial(true);
    const listasDePrecios = await agent.ListasDePrecios.list();
    try {
      listasDePrecios.forEach((listaDePrecio) => {
        setDate<ListaDePrecio>(listaDePrecio);
        this.setListaDePrecio(listaDePrecio);
      });
      this.setLoadingInitial(false);
      return listasDePrecios;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return listasDePrecios;
    }
  };

  loadListaDePrecio = async (id: number) => {
    let listaDePrecio = this.getListasDePrecio(id);
    if (listaDePrecio) {
      this.selectedListaDePrecio = listaDePrecio;
      return listaDePrecio;
    } else {
      this.loadingInitial = true;
      try {
        listaDePrecio = await agent.ListasDePrecios.details(id);
        this.setListaDePrecio(listaDePrecio);
        this.selectedListaDePrecio = listaDePrecio;
        this.setLoadingInitial(false);
        return listaDePrecio;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setListaDePrecio = (listaDePrecio: ListaDePrecio) => {
    this.listaDePrecioRegistry.set(listaDePrecio.id, listaDePrecio);
  }

  private getListasDePrecio = (id: number) => {
    return this.listaDePrecioRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createListaDePrecio = async (listaDePrecio: ListaDePrecio) => {
    debugger
    this.loading = true;
    try {
      await agent.ListasDePrecios.create(listaDePrecio);

      runInAction(() => {
        this.selectedListaDePrecio = listaDePrecio;
        this.editMode = false;
        this.loading = false;
      });

      
      toast.success("lista De Precio creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateListaDePrecio = async (listaDePrecio: ListaDePrecio) => {
    debugger
    this.loading = true;
    try {
      await agent.ListasDePrecios.update(listaDePrecio);
      runInAction(() => {
        this.selectedListaDePrecio = listaDePrecio;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Lista De Precio modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteListaDePrecio = async (id: number) => {
    this.loading = true;
    try {
      await agent.ListasDePrecios.delete(id);
      runInAction(() => {
        this.listaDePrecioRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Lista De Precio eliminada con éxito");

    } catch (error) {
          toast.error("Problema al eliminar Lista De Precio");
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  get ListaDePreciosByDropdown(){
    let ListaDePreciosArreg=[new TipoDropDownValues()];
    let tipoMonedasOpt =new TipoDropDownValues();
    const montipos=  Array.from(this.listaDePrecioRegistry.values()).sort(
      (a, b) =>  {  if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0}
    );

    montipos.forEach((element) => {
      tipoMonedasOpt.key = element.id;
      tipoMonedasOpt.text = element.nombre;
      tipoMonedasOpt.value = element.id;
      tipoMonedasOpt.content = element.nombre;
      ListaDePreciosArreg.push(tipoMonedasOpt);
      tipoMonedasOpt =new TipoDropDownValues();
      
    });
    return ListaDePreciosArreg;
    }
}
