import { Empresa } from "./Empresa";
import { FechaObservacion } from "./FechaObservacion";
import { Perfil } from "./Perfil";
import { Sucursal } from "./Sucursal";
import { UsuarioTipo } from "./UsuarioTipo";

export interface Usuario {
  id: number;
  clave: number;
  nombre: string;
  fechaObservacion?: FechaObservacion;
  fechaObservacionId: number;
  empresaId: number;
  empresa?: Empresa;
  sucursalId: number;
  sucursal?: Sucursal;
  email?: string;
  nombreCorto?: string;
  perfilId: number;
  perfil?: Perfil;
  usuarioTipoId: number;
  usuarioTipo?: UsuarioTipo;
  codigoContable: string;
  contrasena?:string;
  esVendedor: boolean;
  esTrabajador: boolean;
  esComprador: boolean;
}
export interface IMonedaFormValues extends Partial<Usuario> {
  confirmarContrasena?:string;
  
}
export class UsuarioFormValues implements IMonedaFormValues {
  id: number = 0;
  empresaId: number = 0;
  empresa?: Empresa;
  sucursalId: number = 0;
  sucursal?: Sucursal;
  clave: number = 0;
  nombre: string = "";
  nombreCorto?: string = "";
  perfilId: number = 0;
  perfil?: Perfil;
  usuarioTipoId: number = 0;
  usuarioTipo?: UsuarioTipo;
  
 fechaObservacionId: number = 0; 
 fechaObservacion?: FechaObservacion;
  
  
  email?: string = "";
  
  contrasena?:string="";
  confirmarContrasena?:string="";
  codigoContable: string = "";
  esVendedor: boolean = false;
  esTrabajador: boolean = false;
  esComprador: boolean = false;
  constructor(usuario?: Usuario) {
    Object.assign(this, usuario);
  }
}
