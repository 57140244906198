import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { history } from '../../..';
import { toast } from 'react-toastify';
import { PaginationSpecParams } from '../../models/Especificaciones/PaginationSpecParams';

export default class TraspasoEntreSucursalStore {
  editMode = false;
  loading = false;
  loadingInitial = false;
  lastElementTable: number = 9;
  constructor() {
    makeAutoObservable(this);
  }
  setLastElementTable(value: number) {
    this.lastElementTable = value;
  }
  getNextFolio = async (filtros: PaginationSpecParams) => {
    if (filtros.empresaId && filtros.sucursalId && filtros.serieId) {
      try {
        const nextFolio = await agent.TraspasoEntreSucursal.obtenerFolio(filtros);
        return nextFolio;
      } catch (error) {
        console.error("Error", error);
        throw error; // Propaga el error si necesitas manejarlo en el componente llamador
      }
    }
  };
  private getFormTraspasoEntreSucursales = (traspasoEntreSucursales: any) => {
    let formData = new FormData();
    formData.append("catalogoInsertaId", traspasoEntreSucursales.catalogoInsertaId.toString());
    formData.append("archivoInserta", traspasoEntreSucursales.archivoInserta!);
    return formData;
  };
  createTraspasoEntreSucursales = async (traspasoEntreSucursales: any) => {
    this.loading = true;
    try {
      await agent.TraspasoEntreSucursal.create(traspasoEntreSucursales);
      runInAction(() => {
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Se inserto con éxito");
    } catch (error: any) {
      // El error ya fue manejado y mostrado por el interceptor
      if (error) {
        console.error('Error:', error);
      }
      runInAction(() => {
        this.loading = false;
      });
    }
  };

}