export const enterInputs = (
  tabIndex: number,
  firstElement: number,
  lastElement: number
) => {
  let x = tabIndex + 2;
  if (tabIndex === lastElement) tabIndex = firstElement;
  let next = document.querySelector<HTMLElement>(
    '[tabIndex="' + (tabIndex + 1) + '"]'
  );
  if (next) {
    while ((next as any)?.disabled === true) {
      next = document.querySelector<HTMLElement>('[tabIndex="' + x + '"]');
      x++;
    }
  }
  if (next) {
    next.focus();
    if ((next as any).select)
      (next as any).select();
  }
};
export const tabuladorButtons = (
  event: any,
  firstElement: number,
  lastElement: number
) => {
  if (event.key !== "Tab") {
    return;
  }
  let element = event.target;
  let tabIndex = element.tabIndex;
  enterInputs(tabIndex, firstElement, lastElement);
  event.preventDefault();
};
