import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Almacen } from "../../models/inventario/Almacen";
import { setDate } from "../../common/util/util";
import { history } from "../../..";
import { toast } from "react-toastify";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class AlmacenStore {
  almacenRegistry = new Map<number, Almacen>();
  selectedAlmacen: Almacen | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }
  get almacenesById() {
    return Array.from(this.almacenRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  get almacenesByDropdown() {
    return entityByDropdown<Almacen>(Array.from(this.almacenRegistry.values()));
  }
  get almacenesByDropdownClave() {
    return entityByDropdown<Almacen>(
      Array.from(this.almacenRegistry.values()),true
    );
  }

  fetchsAlmacenes = async () => {
    try {
      const response = await agent.Almacenes.list();
      return response;
    } catch (error) {
      console.error("Error al obtener los almacenes", error);
    }
  };

  loadAlmacenes = async () => {
    this.setLoadingInitial(true);
    try {
      const almacenes = await agent.Almacenes.list();
      debugger
      almacenes.forEach((almacen) => {
        setDate<Almacen>(almacen);
        this.setAlmacen(almacen);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getAlmacenes = async () => {
    this.setLoadingInitial(true);
    const almacenes = await agent.Almacenes.list();
    debugger
    try {
      almacenes.forEach((almacen) => {
        setDate<Almacen>(almacen);
        this.setAlmacen(almacen);
      });
      this.setLoadingInitial(false);
      return almacenes;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return almacenes;
    }
  };

  loadAlmacen = async (id: number) => {
    let almacen = this.getAlmacen(id);
    if (almacen) {
      this.selectedAlmacen = almacen;
      return almacen;
    } else {
      this.loadingInitial = true;
      try {
        almacen = await agent.Almacenes.details(id);
        setDate<Almacen>(almacen);
        this.setAlmacen(almacen);
        this.selectedAlmacen = almacen;
        this.setLoadingInitial(false);
        return almacen;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setAlmacen = (almacen: Almacen) => {
    this.almacenRegistry.set(almacen.id, almacen);
  };

  private getAlmacen = (id: number) => {
    return this.almacenRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createAlmacen = async (almacen: Almacen) => {
    this.loading = true;
    try {
      await agent.Almacenes.create(almacen);
      runInAction(() => {
        this.selectedAlmacen = almacen;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Almacen creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateAlmacen = async (almacen: Almacen) => {
    this.loading = true;
    try {
      await agent.Almacenes.update(almacen);
      runInAction(() => {
        this.selectedAlmacen = almacen;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Almacen modificado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteAlmacen = async (id: number) => {
    this.loading = true;
    try {
      await agent.Almacenes.delete(id);
      runInAction(() => {
        this.almacenRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Almacen eliminado con éxito");
    } catch (error) {
      toast.error("Problema al eliminar el Almacen");
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
