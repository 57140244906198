import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { CuentaDeCompra, CuentaDeCompraFormValues } from "../../../../app/models/compra/CuentaDeCompra";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import * as Yup from "yup";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function CuentaDeCompraForm({ id }: idProps) {
  const { cuentaDeCompraStore, cuentaDeCompraTipoStore, pagoDeStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createCuentaDeCompra, updateCuentaDeCompra, loadCuentaDeCompra, loadingInitial, cuentaDeCompraById, loading } = cuentaDeCompraStore;
  const { cuentasDeComprasTipoByDropdown, loadCuentasComprasTipos } = cuentaDeCompraTipoStore;
  const { pagosDeByDropdown, loadPagosDe } = pagoDeStore;
  const [cuentaDeCompra, setCuentaDeCompra] = useState(new CuentaDeCompraFormValues);
  const [cuentaDeCompraTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const firstElement = 0;
  const lastElement = 8;


  useEffect(() => {
    loadCuentasComprasTipos();
    loadPagosDe();
  }, []);

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de la cuenta del compra es requerida."),
    nombre: Yup.string().required("El nombre de la cuenta de compra es requerida."),
    abreviacion: Yup.string().required("La abreviación de la cuenta de compra es requerida."),
    pagoDeId: Yup.number()
      .min(1, "Favor de seleccionar un pago de la cuenta de compra.")
      .required("El pago de la cuenta de compra es requerida."),
    cuentaDeCompraTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo la cuenta de compra.")
      .required("El tipo de la cuenta de compra es requerida.")
  });

  useEffect(() => {
    if (id) {
      loadCuentaDeCompra(id).then((cuentaDeCompra) => {
        setCuentaDeCompra(new CuentaDeCompraFormValues(cuentaDeCompra))
      });
    }
    else {
      cuentaDeCompra.clave =
        cuentaDeCompraById.map((cuentaDeCompra) => cuentaDeCompra.clave).length > 0
          ? Math.max(...cuentaDeCompraById.map((cuentaDeCompra) => cuentaDeCompra.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(cuentaDeCompra: CuentaDeCompra) {
    if (cuentaDeCompra.cuentaDeCompraTipo) {
      const tipoCuentaDeCompraSelected = cuentaDeCompraTipos.find(
        (a) => a.key === cuentaDeCompra.cuentaDeCompraTipoId
      );
      if (tipoCuentaDeCompraSelected) {
        cuentaDeCompra.cuentaDeCompraTipo.nombre = tipoCuentaDeCompraSelected.text;
        cuentaDeCompra.cuentaDeCompraTipo.id = tipoCuentaDeCompraSelected.key;
        cuentaDeCompra.cuentaDeCompraTipo.clave = tipoCuentaDeCompraSelected.value;
      }
    }
    else {
      toast.info("Favor de llenar campos");
    }

    if (cuentaDeCompra.cuentaDeCompraTipoId.toString() !== "0" && cuentaDeCompra.nombre !== null) {
      cuentaDeCompra.id > 0 ? updateCuentaDeCompra(cuentaDeCompra) : createCuentaDeCompra(cuentaDeCompra);
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Cuenta De Compra' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={cuentaDeCompra}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className='ui form'>
              <Segment clearing>
                <Label as='a' color='red' ribbon>
                  Datos de la Cuenta De Compra.
                </Label>
                <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
                <MyTextInput
                  label="Abreviación"
                  placeholder="Abreviación"
                  name="abreviacion"
                  tabIndex={2}
                  enterInput={enterInput}
                  maxLength={30}
                />
              </Segment>
              <Segment clearing>
                <Label as='a' color='blue' ribbon>
                  Datos especí­ficos
                </Label>

                <MySelectInput
                  placeholder={"Seleccionar"}
                  label="Seleccione el PagoDe"
                  name="pagoDeId"
                  enterInput={enterInput}
                  tabIndex={3}
                  options={pagosDeByDropdown}
                />

                <MySelectInput
                  placeholder={"Seleccionar"}
                  label="Seleccione el tipo de compra"
                  name="cuentaDeCompraTipoId"
                  enterInput={enterInput}
                  tabIndex={4}
                  options={cuentasDeComprasTipoByDropdown}
                />
              </Segment>

              <Segment clearing>
                <Button
                  type="button"
                  className="ui primary button"
                  tabIndex={5}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(data) => {
                          setFieldValue("fechaObservacion", data)
                        }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.cuentaDeCompra
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
                <div className="col80">
                  <BarraFechaObservacion
                    fechaObservacion={values.fechaObservacion}
                  ></BarraFechaObservacion>
                </div>
              </Segment>
              <Segment>
                <BarraBotones
                  toLink="/CuentasDeCompras"
                  loading={loading}
                  tabIndex={6}
                  //id={id}
                  firstElement={firstElement}
                  lastElement={lastElement}
                ></BarraBotones>
              </Segment>
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
})