import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { SerieFolioCompra } from "../../models/compra/SerieFolioCompra";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { entity2ByDropdown } from "../../common/funciones/entityByDropdown";


export default class SerieFolioCompraStore {
    serieFolioCompraRegistry = new Map<number, SerieFolioCompra>();
    serieFolioByTipoCompraRegistry = new Map<number, SerieFolioCompra>();
    serieFolioByTipoCompraCopiaRegistry = new Map<number, SerieFolioCompra>();
    selectedSerieFolioCompra: SerieFolioCompra | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    empresaSucursal: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this)
    }

    get seriesFoliosComprasById() {
        return Array.from(this.serieFolioCompraRegistry.values()).sort((a) => a.id);
    }

    get SeriesFoliosByEmpresaByDropdown() {
        return entity2ByDropdown<SerieFolioCompra>(
            Array.from(this.serieFolioByTipoCompraRegistry.values())
        );
    }

    get SeriesFoliosByEmpresaByDropdownByClave() {
        return entity2ByDropdown<SerieFolioCompra>(Array.from(this.serieFolioByTipoCompraRegistry.values()), true);
    }

    loadSerieByIdTipoCompra = async (empresaSucursal: PaginationSpecParams) => {
        if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
            try {
                this.serieFolioByTipoCompraRegistry.clear();
                const serieFolioCompra = await agent.SeriesFoliosCompras.listByTipoCompra(empresaSucursal);
                serieFolioCompra.forEach((serieFolioCompra) => {
                    this.setSerieFoliobyTipoCompra(serieFolioCompra);
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            //proveedorStore.cleanProvedorByEmpresa();
            this.serieFolioByTipoCompraRegistry.clear();
        }
    };

    loadSerieByIdTipoCompraCopia = async (empresaSucursal: PaginationSpecParams) => {
        if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
            try {
                // void tipoVentaStore.cleanProvedorByEmpresa();

                this.serieFolioByTipoCompraCopiaRegistry.clear();
                const serieFolioDeCompraCopia = await agent.SeriesFoliosCompras.listByTipoCompra(empresaSucursal);
                serieFolioDeCompraCopia.forEach((serieFolioDeCompra) => {
                    this.setSerieFoliobyTipoCompraCopia(serieFolioDeCompra);
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            this.serieFolioByTipoCompraCopiaRegistry.clear();
        }
    };


    private setSerieFoliobyTipoCompra = (serieFolioCompra: SerieFolioCompra) => {
        this.serieFolioByTipoCompraRegistry.set(serieFolioCompra.id, serieFolioCompra);
    };

    private setSerieFoliobyTipoCompraCopia = (serieFolio: SerieFolioCompra) => {
        this.serieFolioByTipoCompraCopiaRegistry.set(serieFolio.id, serieFolio);
    };

    loadSeriesFolios = async () => {
        this.setLoadingInitial(true);
        try {
            const seriesFoliosCompras = await agent.SeriesFoliosCompras.list();
            seriesFoliosCompras.forEach(serieFolioCompra => {
                setDate<SerieFolioCompra>(serieFolioCompra);
                this.setSerieFolioCompra(serieFolioCompra);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadSerieFolioCompra = async (id: number) => {
        let serieFolioCompra = this.getSerieFolioCompra(id);
        if (serieFolioCompra) {
            this.selectedSerieFolioCompra = serieFolioCompra;
            return serieFolioCompra;

        } else {
            this.loadingInitial = true;
            try {
                serieFolioCompra = await agent.SeriesFoliosCompras.details(id);
                setDate<SerieFolioCompra>(serieFolioCompra);
                this.setSerieFolioCompra(serieFolioCompra);
                this.selectedSerieFolioCompra = serieFolioCompra;
                this.setLoadingInitial(false);
                return serieFolioCompra;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }
    loadClaveSerieFolioCompraByEmpresaSucursal = async (
        empresaSucursal: PaginationSpecParams
    ) => {
        try {
            const serieFolioCompra = await agent.SeriesFoliosCompras.claveByEmpresaSucursal(empresaSucursal);
            setDate<SerieFolioCompra>(serieFolioCompra);
            return serieFolioCompra;
        } catch (error) {
            console.log(error);
        }
    }
    private setSerieFolioCompra = (serieFolioCompra: SerieFolioCompra) => {
        this.serieFolioCompraRegistry.set(serieFolioCompra.id, serieFolioCompra);
    }


    private getSerieFolioCompra = (id: number) => {
        return this.serieFolioCompraRegistry.get(id);
    }

    getSeriesFoliosCompras = async () => {
        this.setLoadingInitial(true);
        const seriesFoliosCompras = await agent.SeriesFoliosCompras.list();
        try {
            seriesFoliosCompras.forEach(serie => {
                this.setSerieFolioCompra(serie);
            })
            this.setLoadingInitial(false);
            return seriesFoliosCompras;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return seriesFoliosCompras;
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }
    private getFormSerieFolioCompra = (serieFolioCompra: SerieFolioCompra) => {
        let formData = new FormData();
        formData.append("id", serieFolioCompra.id?.toString());
        formData.append("clave", serieFolioCompra.clave);
        formData.append("nombre", serieFolioCompra.nombre);
        formData.append("tipoCompraId", serieFolioCompra.tipoCompraId.toString());
        formData.append("empresaId", serieFolioCompra.empresaId.toString());
        formData.append("sucursalId", serieFolioCompra.sucursalId.toString());
        formData.append("lemaEmpresa", serieFolioCompra.lemaEmpresa ? serieFolioCompra.lemaEmpresa : "");
        formData.append("ultimoFolio", serieFolioCompra.ultimoFolio?.toString()!);
        formData.append(
            "fechaObservacionId",
            serieFolioCompra.fechaObservacionId?.toString()!
        );
        formData.append(
            "fechaObservacion.observaciones",
            serieFolioCompra.fechaObservacion.observaciones
                ? serieFolioCompra.fechaObservacion.observaciones
                : ""
        );
        formData.append(
            "fechaObservacion.baja",
            serieFolioCompra.fechaObservacion?.baja
                ? serieFolioCompra.fechaObservacion.baja?.toDateString()
                : ""
        );
        formData.append(
            "logotipoEmpresaFileId",
            serieFolioCompra.logotipoEmpresaFileId ? serieFolioCompra.logotipoEmpresaFileId.toString() : ""
        );
        formData.append("logoTipoEmpresa", serieFolioCompra.logotipoEmpresa!);
        formData.append(
            "logotipoTicketFileId",
            serieFolioCompra.logotipoTicketFileId ? serieFolioCompra.logotipoTicketFileId.toString() : ""
        );
        formData.append("logoTipoEmpresa", serieFolioCompra.logotipoEmpresa!);
        formData.append("logotipoTicket", serieFolioCompra.logotipoTicket!);


        return formData;
    };

    createSerieFolioCompra = async (serieFolioCompra: SerieFolioCompra) => {
        this.loading = true;
        try {
            await agent.SeriesFoliosCompras.create(this.getFormSerieFolioCompra(serieFolioCompra));
            runInAction(() => {
                //  this.serieFolioCompraRegistry.set(serieFolioCompra.id, serieFolioCompra);
                this.selectedSerieFolioCompra = serieFolioCompra;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Serie folio de compra creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateSerieFolioCompra = async (serieFolioCompra: SerieFolioCompra) => {
        this.loading = true;
        try {
            await agent.SeriesFoliosCompras.update(this.getFormSerieFolioCompra(serieFolioCompra), serieFolioCompra.id);
            runInAction(() => {
                // this.serieFolioCompraRegistry.set(serieFolioCompra.id, serieFolioCompra);
                this.selectedSerieFolioCompra = serieFolioCompra;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Serie folio de compra modificada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteSerieFolioCompra = async (id: number) => {
        this.loading = true;
        try {
            await agent.SeriesFoliosCompras.delete(id);
            runInAction(() => {
                this.serieFolioCompraRegistry.delete(id);
                this.loading = false;
            });
            toast.success("Serie Folio Compra eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar Serie Folio Compra");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    loadSerieByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
        try {
            const serieFolioDeCompra = await agent.SeriesFoliosCompras.listByParams(filtros, options);
            return entity2ByDropdown<SerieFolioCompra>(Array.from(serieFolioDeCompra));
        } catch (error) {
            console.error("error", error);
        }
    }
}
