import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CuentaDeVenta, CuentaDeVentaFormValues } from "../../../../app/models/venta/CuentaDeVenta";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons } from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function CuentaVentaForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 7;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { cuentaDeVentaStore, cuentaDeVentaTipoStore } = useStore();

  const {
    createCuentaDeVenta,
    loadCuentaDeVenta,
    loadingInitial,
    cuentaDeVentaById,
    loading
  } = cuentaDeVentaStore;
  const { cuentaDeVentaTiposByDropdown, getCuentasDeVentasTipos } = cuentaDeVentaTipoStore;
  const [cuentaDeVenta, setCuentaDeVenta] = useState(new CuentaDeVentaFormValues());
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  useEffect(() => {
    getCuentasDeVentasTipos().then(cvts => { });
  }, [getCuentasDeVentasTipos])

  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave de la cuenta de venta es requerida.'),
    nombre: Yup.string().required('El nombre de la cuenta de venta es requerido.'),
    cuentaDeVentaTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo Cuenta de Venta.")
      .required("El tipo Cuenta de Venta es requerido."),
  });

  useEffect(() => {
    if (id) {
      loadCuentaDeVenta(id).then(cuentaDeVenta => {
        setCuentaDeVenta(new CuentaDeVentaFormValues(cuentaDeVenta))
        setFechaObservacion(
          new FechaObservacionValues(cuentaDeVenta?.fechaObservacion)
        );
      }
      );
    } else {
      cuentaDeVenta.clave =
        cuentaDeVentaById.map((cuentaDeVenta) => cuentaDeVenta.clave).length > 0
          ? Math.max(...cuentaDeVentaById.map((cuentaDeVenta) => cuentaDeVenta.clave)) + 1
          : 1;
    
        }
      }, []);
  //}, [id, cuentaDeVenta, cuentaDeVentaById, loadCuentaDeVenta]);



  function handleSubmit(cuentaDeVenta: CuentaDeVenta) {
    if ( cuentaDeVenta.nombre !== null) {
      cuentaDeVenta.fechaObservacion = fechaObservacion;
      createCuentaDeVenta(cuentaDeVenta);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Cuenta Venta' />

  return (
    <Segment clearing>
      <Formik onSubmit={(values) => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={cuentaDeVenta}
      >
        {({ isValid, dirty, handleSubmit,setFieldValue,values }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Cuenta de venta
              </Label>
              <MyTextInput
                placeholder="Clave"
                label="Clave"
                name="clave"
                disabled={true}
                tabIndex={0}
              />
              <MyTextInput
                autoFocus={true}
                placeholder="Nombre"
                label="Nombre"
                name="nombre"
                tabIndex={1}
                enterInput={enterInput}
                maxLength={30}
              />
              <div className="col60Block">
                <MyTextInput
                  label="Abreviacion"
                  placeholder="Abreviacion"
                  name="abreviacion"
                  tabIndex={2}
                  enterInput={enterInput}
                  maxLength={30}
                />
              </div>
              <div className="col">
                <MySelectInput
                  tabIndex={3}
                  placeholder={"Seleccionar"}
                  label="Tipo"
                  name="cuentaDeVentaTipoId"
                  options={cuentaDeVentaTiposByDropdown}
                  enterInput={enterInput}
                  inputWidth="140px"
                  menuWidth="180px"
                />
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  class="ui primary button"
                  tabIndex={4}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => {
                          setFieldValue("fechaObservacion", data)
                          cuentaDeVenta.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                      }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.CuentaDeVenta
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/CuentasVentas"
                loading={loading}
                tabIndex={5}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
