
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { ConceptoCliente } from "../gestionCliente/ConceptoCliente";
import { CuentaDeCliente } from "../gestionCliente/CuentaDeCliente";
import { Almacen } from "../inventario/Almacen";
import { ConceptoDeInventario } from "../inventario/ConceptoDeInventario";
import { tipoSAT } from "../sat/tipoSAT";
import { TipoVentaTipo } from "./TipoVentaTipo";

//TODO: id and clave pased of number to string on this case

export interface TipoVenta {
    id: number;
    clave: number;
    nombre: string;
    tipoVentaTipoId: number;
    tipoVentaTipo?: TipoVentaTipo;
    
    tipoSATId: number;
    tipoSAT?: tipoSAT;


    

    almacenId?: number;
    almacen?: Almacen;
    conceptoDeInventarioId: number;
    conceptoDeInventario?: ConceptoDeInventario;

    almacenTraspasoEntradaId?: number;
    almacenTraspasoEntrada?: Almacen;
    conceptoDeInventarioTraspasoEntradaId: number;
    conceptoDeInventarioTraspasoEntrada?: ConceptoDeInventario;

    cuentaDeClienteId?: number;
    cuentaDeCliente?: CuentaDeCliente;
    conceptoDeClienteId?: number;
    conceptoDeCliente?: ConceptoCliente;

    codigoContable: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export interface ITipoVentaFormValues extends Partial<TipoVenta> {
    tipoVentaTipoId: any;
}

export class TipoVentaFormValues implements ITipoVentaFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    tipoVentaTipoId: number = 0;
    tipoVentaTipo?: TipoVentaTipo;
    tipoSATId: number =0;
    tipoSAT?: tipoSAT;

    almacenId: number = 0;
    almacen?: Almacen;
    conceptoDeInventarioId: number = 0;
    conceptoDeInventario?: ConceptoDeInventario;

    almacenTraspasoEntradaId?: number =0;
    almacenTraspasoEntrada?: Almacen;
    conceptoDeInventarioTraspasoEntradaId: number=0;
    conceptoDeInventarioTraspasoEntrada?: ConceptoDeInventario;

    cuentaDeClienteId?: number = 0;
    cuentaDeCliente?: CuentaDeCliente;
    conceptoDeClienteId?: number = 0;
    conceptoDeCliente?: ConceptoCliente;

    codigoContable: string = "";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    constructor(init?: TipoVenta) {
        Object.assign(this, init);
    }
}
