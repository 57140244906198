import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { c_MotivoCancelacion } from "../../models/sat/c_MotivoCancelacion";

export default class c_MotivoCancelacionStore {
    c_MotivoCancelacionRegistry = new Map<number, c_MotivoCancelacion>();
    selectedc_MotivoCancelacion: c_MotivoCancelacion | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get c_MotivosCancelacionesByDropdown() {
        let c_MotivosCancelacionesArreg = [new TipoDropDownValues()];
        let c_MotivosCancelacionesOpt = new TipoDropDownValues();
        const cvts = Array.from(this.c_MotivoCancelacionRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            c_MotivosCancelacionesOpt.key = element.id;
            c_MotivosCancelacionesOpt.text = element.nombre;
            c_MotivosCancelacionesOpt.value = element.id;
            c_MotivosCancelacionesOpt.content = element.nombre;
            c_MotivosCancelacionesArreg.push(c_MotivosCancelacionesOpt);
            c_MotivosCancelacionesOpt = new TipoDropDownValues();

        });
        return c_MotivosCancelacionesArreg;
    }

    get c_MotivosCancelacionesByDate() {
        return Array.from(this.c_MotivoCancelacionRegistry.values()).sort((a) => a.id);
    }


    loadc_MotivosCancelaciones = async () => {
        this.setLoadingInitial(true);
        try {
            const c_MotivosCancelaciones = await agent.C_MotivosCancelaciones.list();
            c_MotivosCancelaciones.forEach(c_MotivoCancelacion => {
                this.setc_MotivoCancelacion(c_MotivoCancelacion);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
        }
    }

    getc_MotivosCancelaciones= async () => {
        this.setLoadingInitial(true);
        const c_MotivosCancelaciones = await agent.C_MotivosCancelaciones.list();
        try {
            c_MotivosCancelaciones.forEach(c_MotivoCancelacion => {
                this.setc_MotivoCancelacion(c_MotivoCancelacion);
            })
            this.setLoadingInitial(false);
            return c_MotivosCancelaciones;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return c_MotivosCancelaciones;
        }
    }







    loadc_MotivoCancelacion = async (id: number) => {
        let c_MotivoCancelacion = this.getc_MotivoCancelacion(id);
        if (c_MotivoCancelacion) {
            this.selectedc_MotivoCancelacion = c_MotivoCancelacion;
            return c_MotivoCancelacion;
        } else {
            this.loadingInitial = true;
            try {
                c_MotivoCancelacion = await agent.C_MotivosCancelaciones.details(id);
                this.setc_MotivoCancelacion(c_MotivoCancelacion);
                this.selectedc_MotivoCancelacion = c_MotivoCancelacion;
                this.setLoadingInitial(false);
                return c_MotivoCancelacion;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setc_MotivoCancelacion = (c_MotivoCancelacion: c_MotivoCancelacion) => {
        this.c_MotivoCancelacionRegistry.set(c_MotivoCancelacion.id, c_MotivoCancelacion);
    }

    private getc_MotivoCancelacion = (id: number) => {
        return this.c_MotivoCancelacionRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createc_MotivoCancelacion = async (c_MotivoCancelacion: c_MotivoCancelacion) => {
        this.loading = true;
        try {
            await agent.C_MotivosCancelaciones.create(c_MotivoCancelacion);
            runInAction(() => {
                this.c_MotivoCancelacionRegistry.set(c_MotivoCancelacion.id, c_MotivoCancelacion);
                this.selectedc_MotivoCancelacion = c_MotivoCancelacion;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }



    deletec_MotivoCancelacion = async (id: number) => {
        this.loading = true;
        try {
            await agent.C_MotivosCancelaciones.delete(id);
            runInAction(() => {
                this.c_MotivoCancelacionRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
