export interface cRegimenFiscal {
    id: number;
    clave:string;
    nombre: string;
    personaFisica:number;
    personaMoral:number;
    fechaFinVigenciaCFD: Date;
    fechaInicioVigencia: Date;
}
export interface IcRegimenFiscalFormValues extends Partial<cRegimenFiscal> {
  id: any;

}

export class cRegimenFiscalFormValues implements IcRegimenFiscalFormValues {
    id: number =0;
    clave:string='';
    nombre: string='';
    personaFisica:number=0;
    personaMoral:number=0;
    fechaFinVigenciaCFD: Date = new Date();
    fechaInicioVigencia: Date = new Date();
  constructor(init?: cRegimenFiscal) {
    Object.assign(this, init);
  }
}



