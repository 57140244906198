import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoMovimiento } from "../TipoMovimiento";
import { CuentaFinanza } from "./CuentaFinanza";
import { ConceptoFinanza } from "./ConceptoFinanza";
//import { Cliente } from "./Cliente";
import { Moneda } from "../configuracion/Moneda";
import { FormaDePago } from "../gestionCliente/FormaDePago";
//import { FormaDePago } from "./FormaDePago";


export interface MovimientoFinanzaDetalle {
    id: number;
    cuentaFinanzaId: number;
    cuentaFinanza?: CuentaFinanza;
    conceptoFinanzaId: number;
    conceptoFinanza?: ConceptoFinanza;
    //finanzaId: number;
    //finanza?: Finanza;
    serie: string;
    folio: number;
    serieAfecta: string;
    folioAfecta: number;
    importe: number;
    monedaId: number;
    moneda?: Moneda;
    tipoCambio: number;
    formaDePagoId: number;
    formaDePago?: FormaDePago;
    automatico?: boolean | number;
    movimientoFinanzaId: number;
}
export class MovimientoFinanzaDetalleFormValues implements MovimientoFinanzaDetalle {
    id: number = 0;
    cuentaFinanzaId: number = 0;
    cuentaFinanza?: CuentaFinanza;
    conceptoFinanzaId: number = 0;
    conceptoFinanza?: ConceptoFinanza;
    //clienteId: number = 0;
    //cliente?: Cliente;
    serie: string = "";
    folio: number = 0;
    serieAfecta: string = "";
    folioAfecta: number = 0;
    importe: number = 0;
    monedaId: number = 0;
    moneda?: Moneda;
    tipoCambio: number = 0;
    formaDePagoId: number = 0;
    formaDePago?: FormaDePago;
    automatico?: boolean | number = 0;
    movimientoFinanzaId: number = 0;
    constructor(init?: MovimientoFinanzaDetalle) {
        Object.assign(this, init);
    }
}
export interface MovimientoFinanza {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoMovimientoId: number;
    tipoMovimiento?: TipoMovimiento;
    clave: number;
    fecha: Date;
    comentarios: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    movimientoFinanzaDetalle?: MovimientoFinanzaDetalle[];
}
export class MovimientoFinanzaFormValues implements MovimientoFinanza {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoMovimientoId: number = 0;
    tipoMovimiento?: TipoMovimiento;
    clave: number = 0;
    fecha: Date = new Date();
    comentarios: string = "";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    movimientoFinanzaDetalle: MovimientoFinanzaDetalle[] = [new MovimientoFinanzaDetalleFormValues()]
    //controlListaDePrecio: ControlListaDePrecio[]= [new ControlListaDePrecioFormValues()];
    //MovimientoClienteDetalle?: MovimientoClienteDetalle[];
    constructor(init?: MovimientoFinanza) {
        Object.assign(this, init);
    }
}

