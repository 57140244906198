import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import ListaDePrecioForm from "../form/ListaDePrecioForm";

export default observer(function ListaDePrecioList() {
  ///modal
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  //final modal
  const { listaDePrecioStore } = useStore();
  const { deleteListaDePrecio, listasDePreciosByDate, loading } = listaDePrecioStore;
  const [target, setTarget] = useState('');

  function deleteConfirmed(e: string, id: number) {
    deleteListaDePrecio(id);
    setTarget(e);
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listasDePreciosByDate.map((listaDePrecio) => (
          <Table.Row key={listaDePrecio.id}>
            <Table.Cell>{listaDePrecio.clave}</Table.Cell>
            <Table.Cell>{listaDePrecio.nombre}</Table.Cell>
            <Table.Cell>{listaDePrecio.listaDePrecioTipo?.nombre}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => tabsStore.addTab('Crear Lista de Precio', <ListaDePrecioForm id={listaDePrecio.id} />)}
                floated="right" content="Editar" color="blue" />
              <Button
                name={listaDePrecio.id}
                disabled={loading && target === String(listaDePrecio.id)}
                onClick={(e) =>
                  openModal(
                    <DeleteModals
                      deleteConfirmed={deleteConfirmed}
                      deletedeclined={deletedeclined}
                      e={e.currentTarget.name.toString()}
                      id={listaDePrecio.id}
                    />,
                    enumHeaderModals.ListaDePrecio
                  )
                }
                floated="right"
                content="Eliminar"
                color="red"
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})