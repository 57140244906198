import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Moneda } from "../models/configuracion/Moneda";
import { toast } from "react-toastify";
import { setDate } from "../common/util/util";
import { entityByDropdown } from "../common/funciones/entityByDropdown";
import { Entity } from "../models/Entity";
export default class MonedaStore {
  monedaRegistry = new Map<number, Moneda>();
  monedaEntity = new Map<number, Entity>();
  selectedMoneda: Moneda | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  constructor() {
    makeAutoObservable(this);
  }

  get monedasById() {
    return Array.from(this.monedaRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }
  get MonedasByDropdown() {
    return entityByDropdown<Moneda>(
      Array.from(this.monedaRegistry.values())
    );
  }
  loadEnityMoneda = async () => {
    try {
      const empresas = await agent.Monedas.listEntity();
      empresas.forEach((empresa) => {
        this.setEmpresaEntity(empresa);
      });
    } catch (error) {
      console.log(error);
    }
  };
  private setEmpresaEntity = (empresa: Entity) => {
    this.monedaEntity.set(empresa.id, empresa);
  };
  loadMonedas = async () => {
    this.setLoadingInitial(true);
    try {
      const monedas = await agent.Monedas.list();
      monedas.forEach((moneda) => {
        setDate<Moneda>(moneda);
        this.setMoneda(moneda);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get monedasByDropdown() {
    return entityByDropdown<Moneda>(Array.from(this.monedaEntity.values()));
  }
  getMonedas = async () => {
    this.setLoadingInitial(true);
    const monedas = await agent.Monedas.list();
    try {
      monedas.forEach((moneda) => {
        setDate<Moneda>(moneda);
        this.setMoneda(moneda);
      });
      this.setLoadingInitial(false);
      return monedas;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return monedas;
    }
  };

  loadMoneda = async (id: number) => {
    let moneda = this.getMoneda(id);
    if (moneda) {
      this.selectedMoneda = moneda;
      return moneda;
    } else {
      this.loadingInitial = true;
      try {
        moneda = await agent.Monedas.details(id);
        setDate<Moneda>(moneda);
        this.setMoneda(moneda);
        this.selectedMoneda = moneda;
        this.setLoadingInitial(false);
        return moneda;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setMoneda = (moneda: Moneda) => {
    this.monedaRegistry.set(moneda.id, moneda);
  };

  getMoneda = (id: number) => {
    return this.monedaRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createMoneda = async (moneda: Moneda) => {
    this.loading = true;
    try {
      await agent.Monedas.create(moneda);
      runInAction(() => {
        this.loadMonedas();
        this.selectedMoneda = moneda;
        this.editMode = false;
        this.loading = false;
      });

      toast.success("Moneda creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateMoneda = async (moneda: Moneda) => {
    this.loading = true;
    try {
      await agent.Monedas.update(moneda);
      runInAction(() => {
        this.loadMonedas();
        this.selectedMoneda = moneda;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Moneda modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteMoneda = async (id: number) => {
    this.loading = true;
    try {
      await agent.Monedas.delete(id);
      runInAction(() => {
        this.monedaRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Moneda eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar moneda");
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  fetchsMonedas = async () => {
    try {
      const response = await agent.Monedas.list();
      return response;
    } catch (error) {
      console.error("Error fetching Monedas", error);
    }
  };
}