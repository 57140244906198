import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Grid, Header, Button } from "semantic-ui-react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import ListaDePrecioList from './ListaDePrecioList';
import ListaDePrecioForm from "../form/ListaDePrecioForm";

export default observer(function ListaDePrecioDashboard() {
  const { listaDePrecioStore, tabsStore } = useStore();
  const { loadListaDePrecios } = listaDePrecioStore;

  useEffect(() => {
    loadListaDePrecios();
  }, [listaDePrecioStore]);

  if (listaDePrecioStore.loadingInitial) return <LoadingComponent />;

  return (

    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h1" icon="accusoft" content="Lista de Precio" color="blue"></Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Lista de Precio', <ListaDePrecioForm id={0} />)}
              content="Crear Lista de Precio" color="blue" />
            <Button onClick={() => loadListaDePrecios()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <ListaDePrecioList />
      </Grid.Column>
    </Grid>
  );
});