import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Grid, Header, Button, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import { MovimientoClienteDetalleFormValues, MovimientoCliente, MovimientoClienteFormValues } from '../../../../app/models/gestionCliente/MovimientoCliente';
import DeleteModals from "../../../modals/DeleteModals";
import MyDataGridRows from '../../../../app/common/form/MyDataGridRows';

export default observer(function MovimientoClienteForm() {
  const [registroNuevo, setRegistroNuevo] = useState(0);
  const [polizaCargo, setPolizaCargo] = useState(0);
  const [polizaAbonos, setPolizaAbonos] = useState(0);
  const initialIndexTable = 6;
  const numberControlsTable = 12;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const [target, setTarget] = useState("");
  const firstElement = 0;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { empresaStore, sucursalStore, proveedorStore, movimientoClienteStore, cuentaDeClienteStore, tipoMovimientoStore, conceptoClienteStore, clienteStore, monedaStore, formaPagoStore } = useStore();
  const { empresaSucursal } = proveedorStore;
  const { formasPagosByDropdown, loadFormasPagos } = formaPagoStore;
  const { monedasByDropdown, loadEnityMoneda } = monedaStore;
  const { ClientesByDropdown, loadClientes } = clienteStore
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore
  const { tiposMovimientosByDropdownByClave, loadTiposMovimientos } = tipoMovimientoStore
  const { cuentaClienteByDropdown, loadCuentasClientes } = cuentaDeClienteStore
  const { conceptosClientesByDropdown, loadConceptosClientes, conceptoClienteRegistry } = conceptoClienteStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const [movimientoCliente, setMovimientoCliente] = useState(new MovimientoClienteFormValues());
  const { deleteMovimientoCliente, loadingInitial, createMovimientoCliente, loading, obtenerSiguienteFolio, cargarMovimiento } = movimientoClienteStore
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    tipoMovimientoId: Yup.number()
      .min(1, "Favor de seleccionar un Tipo Movimiento.")
      .required("Tipo movimiento es requerido."),
    clave: Yup.number()
      .typeError("El valor debe ser un numero.")
      .min(1, "Favor de ingresar una clave mayor a 0.")
      .required("La clave es requerida."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    movimientoClienteDetalle: Yup.array().of(
      Yup.object().shape({
        cuentaDeClienteId: Yup.number()
          .min(1, "Favor de seleccionar una Cuenta.")
          .required("Cuenta es requerido."),
        conceptoDeClienteId: Yup.number()
          .min(1, "Favor de seleccionar una Concepto.")
          .required("Concepto es requerido."),
        clienteId: Yup.number()
          .min(1, "Favor de seleccionar una Cliente.")
          .required("Cliente es requerido."),
        importe: Yup.number().typeError("El valor debe ser un numero.").required("El importe es requerido"),
        monedaId: Yup.number()
          .min(1, "Favor de seleccionar una Moneda.")
          .required("Moneda es requerido."),
        tipoCambio: Yup.number().when("monedaId", {
          is: (monedaId: number) => monedaId === 2,
          then: Yup.number().typeError("El valor debe ser un numero.").min(1, "Tipo de cambio debe ser mayor a 0").required("Tipo de cambio es requerido"),
          otherwise: Yup.number().nullable(true)
            // checking self-equality works for NaN, transforming it to null
            .transform((_, val) => val === Number(val) ? val : null),
        }),
        formaDePagoId: Yup.number()
          .min(1, "Favor de seleccionar una Forma de pago.")
          .required("Forma de pago es requerido."),
      })
    ),
  });

  useEffect(() => {
    void loadFormasPagos();
    void loadEnityMoneda();
    void loadClientes();
    void loadConceptosClientes();
    void loadCuentasClientes();
    void loadEntityEmpresas();
    void loadTiposMovimientos();
  }, []);

  function formatNumber(number: number) {
    return new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN" }).format(number);

  }
  function handleSubmit(movimientoCliente: MovimientoCliente) {
    let movClienteDetalle: MovimientoCliente[] = [];
    if (movimientoCliente.movimientoClienteDetalle)
      movimientoCliente.movimientoClienteDetalle?.forEach((element) => {
        element.cliente = undefined;
        element.conceptoDeCliente = undefined;
        element.cuentaDeCliente = undefined;
        element.formaDePago = undefined;
        element.moneda = undefined;
        movClienteDetalle.push({
          clave: movimientoCliente.clave,
          nombre: "",
          empresaId: movimientoCliente.empresaId,
          sucursalId: movimientoCliente.sucursalId,
          tipoMovimientoId: movimientoCliente.tipoMovimientoId,
          fecha: movimientoCliente.fecha,
          //
          id: element.id,
          cuentaDeClienteId: element.cuentaDeClienteId,
          conceptoDeClienteId: element.conceptoDeClienteId,
          clienteId: element.clienteId,
          serie: element.serie,
          folio: element.folio,
          serieAfecta: element.serieAfecta,
          folioAfecta: element.folioAfecta,
          importe: element.importe,
          monedaId: element.monedaId,
          tipoCambio: element.tipoCambio,
          formaDePagoId: element.formaDePagoId,
          automatico: element.automatico,
          subtotal: element.subtotal,
          iva: element.iva,
          fechaVencimiento: element.fechaVencimiento,
          esPPD: element.esPPD,

          //dudas
          condicionDePagoId: 1,
          vendedorId: 1,
        });

      });
    if (movClienteDetalle?.length > 0)
      void createMovimientoCliente(movClienteDetalle);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  function deletedeclined() {
    closeModal();
  }
  function calcularImporte(values?: MovimientoCliente) {
    setPolizaCargo(0);
    setPolizaAbonos(0);
    values?.movimientoClienteDetalle?.forEach((valor) => {
      let valorEncotnrado = conceptoClienteRegistry.get(valor.conceptoDeClienteId);
      if (valorEncotnrado)
        if (valorEncotnrado?.clave < 100) {
          setPolizaCargo((eles) => eles + Number(valor.importe));
        } else if (valorEncotnrado?.clave >= 100 && valorEncotnrado?.clave < 200) {
          setPolizaAbonos((eles) => eles + Number(valor.importe));
        }
    }
    )
  }
  function calcularTotales(values?: MovimientoCliente[]) {
    setPolizaCargo(0);
    setPolizaAbonos(0);
    values?.forEach((valor) => {
      let valorEncotnrado = conceptoClienteRegistry.get(valor.conceptoDeClienteId);
      if (valorEncotnrado)
        if (valorEncotnrado?.clave < 100) {
          setPolizaCargo((eles) => eles + Number(valor.importe));
        } else if (valorEncotnrado?.clave >= 100 && valorEncotnrado?.clave < 200) {
          setPolizaAbonos((eles) => eles + Number(valor.importe));
        }
    }
    )
  }
  function onKeyDownSelectConceptoCliente(id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, values?: any) {
    calcularImporte(values);
  }
  function onKeyDownInputImporte(id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, index?: any, columna?: any, values?: MovimientoCliente,) {
    calcularImporte(values);
  }

  function onChangeSelectEmpresa(idSelected: number, setFieldAny: any) {
    setFieldAny("sucursalId", 0);
    empresaSucursal.sucursalId = 0;
    void loadSucursalByIdEmpresa(idSelected);
    empresaSucursal.empresaId = idSelected;
  }

  function onChangeSelectTipoMovimiento(idSelectedTipoMovimiento: number, setFieldValue: any) {
    empresaSucursal.tipoMovimientoId = idSelectedTipoMovimiento;
    obtenerSiguienteFolio(empresaSucursal).then((clave) => {
      setFieldValue("clave", clave);
    });
  }

  function onChangeSelectSucursal(idSelectedSucursal: number, setFieldValue: any) {
    empresaSucursal.sucursalId = idSelectedSucursal;
  }

  function onKeyDownClave(clave: any, setFieldValue: any, event: any, arrayHelpers: any) {
    debugger
    empresaSucursal.clave = parseInt(clave);
    if ((event.key === "Enter" || event.key === "Tab") &&
      (empresaSucursal.clave > 0 && empresaSucursal.empresaId > 0 && empresaSucursal.sucursalId > 0 && empresaSucursal.tipoMovimientoId)) {
      cargarMovimiento(empresaSucursal).then((result) => {
        calcularTotales(result);
        if (result && result.length > 0) {
          setRegistroNuevo(1);
          setFieldValue("movimientoClienteDetalle", result);
          setFieldValue("comentarios", result[0].comentarios);
          setFieldValue("fecha", new Date(result[0].fecha));
          setLastElementTable(
            (prevLastElementTable) =>
              prevLastElementTable + numberControlsTable
          );
        } else {
          setRegistroNuevo(0);
          setFieldValue("movimientoClienteDetalle", [new MovimientoClienteDetalleFormValues()]);
          setFieldValue("comentarios", "");
          setFieldValue("fecha", new Date());
          setLastElementTable(
            (prevLastElementTable) =>
              prevLastElementTable + numberControlsTable
          );
        }
      })
    }
  }

  function onKeyDownSelectFormaPago(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    arrayHelpers.push(new MovimientoClienteDetalleFormValues());
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deleteMovimientoCliente(id).then(() => {
      arrayHelpers.remove(index);
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable - numberControlsTable
      );

    });
    setTarget(e);
    closeModal();
  }
  const addRowMovCliente = (arrayHelpers: any) => {
    arrayHelpers.push(new MovimientoClienteDetalleFormValues())
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={movimientoCliente}
      >
        {({ isValid, values }) => (
          <Form className='ui form' >
            <Segment>
              {registroNuevo === 0 ? (<div className="nuevo">
                Registro Nuevo
              </div>
              ) : ("")
              }

              <Grid columns='equal'>
                <Grid.Row>
                  <Header as='h2' icon='align center' content='Movimientos de Clientes' color='blue' />
                </Grid.Row>

                <Grid.Row>

                  <div className='colsp' />
                  <div className='col14'>
                    <MySelectInput
                      autoFocus={true}
                      tabIndex={1}
                      placeholder={"Seleccionar"}
                      label="Empresa"
                      name="empresaId"
                      enterInput={enterInput}
                      onChange={onChangeSelectEmpresa}
                      options={EmpresasByDropdownByClave}
                      inputWidth="85px"
                      menuWidth="300px"
                    />
                  </div>
                  <div className='colsp' />
                  <div className='col14'>
                    <MySelectInput
                      tabIndex={2}
                      placeholder={"Seleccionar"}
                      label="Sucursal"
                      name="sucursalId"
                      enterInput={enterInput}
                      onChange={onChangeSelectSucursal}
                      options={sucursalesByEmpresaByDropdownByClave}
                      inputWidth="85px"
                      menuWidth="300px"
                    />
                  </div>
                  <div className='colsp' />
                  <div className='col18'>
                    <MySelectInput
                      tabIndex={3}
                      placeholder={"Seleccionar"}
                      label="Tipo Movimiento"
                      name="tipoMovimientoId"
                      options={tiposMovimientosByDropdownByClave}
                      enterInput={enterInput}
                      onChange={onChangeSelectTipoMovimiento}
                    />
                  </div>
                  <div className='col10'>
                    <MyTextInput
                      tabIndex={4}
                      placeholder="Clave"
                      label="Clave"
                      name="clave"
                      onKeyDown={onKeyDownClave}
                      enterInput={enterInput}
                      pattern="[0-9]{0,16}"
                    />
                  </div>
                  <div className='col12'>
                    Fecha Emision
                    <MyDateInput
                      tabIndex={5}
                      placeholderText="Fecha Emision"
                      name="fecha"
                      dateFormat="dd/MM/yyyy"
                      enterInput={enterInput}
                    />
                  </div>
                  <div className='col20'></div>
                  
                </Grid.Row>
                <Grid.Row>
                  <div className='colsp' />
                  <div className='col50'>
                    <MyTextInput
                      tabIndex={6}
                      placeholder=''
                      label="Comentarios"
                      name='comentarios'
                      enterInput={enterInput}
                    />
                  </div>
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />
                  <div className='colsp' />

                </Grid.Row>
                <div className="col100">
                  <FieldArray
                    name="movimientoClienteDetalle"
                    render={(arrayHelpers) => (
                      /////
                      <Segment>
                        <Table
                          style={{
                            overflow: "auto",
                            display: "inline-block",
                            maxHeight: "inherit",
                            width: 830,
                            height: 350
                          }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Cuenta</Table.HeaderCell>
                              <Table.HeaderCell>Concepto</Table.HeaderCell>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Serie</Table.HeaderCell>
                              <Table.HeaderCell>Folio</Table.HeaderCell>
                              <Table.HeaderCell>SerieAfecta</Table.HeaderCell>
                              <Table.HeaderCell>FolioAfecta</Table.HeaderCell>
                              <Table.HeaderCell>Importe</Table.HeaderCell>
                              <Table.HeaderCell>FechaCanc</Table.HeaderCell>
                              <Table.HeaderCell>Moneda</Table.HeaderCell>
                              <Table.HeaderCell>TipoCambio</Table.HeaderCell>
                              <Table.HeaderCell>FormaPago</Table.HeaderCell>
                              <Table.HeaderCell>Automatico</Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {values.movimientoClienteDetalle && values.movimientoClienteDetalle.length > 0 ? (
                              values.movimientoClienteDetalle?.map(
                                (movimientoClienteDetalles, index) => (
                                  <Table.Row key={index.valueOf()} >
                                    <Table.Cell >
                                      {" "}
                                      <MySelectInput
                                        tabIndex={getIndexTable(index, 1)}
                                        placeholder="Cuenta"
                                        name={`movimientoClienteDetalle[${index}].cuentaDeClienteId`}
                                        options={cuentaClienteByDropdown}
                                        enterInput={enterInput}
                                        index={index}
                                        inputWidth="140px"
                                        menuWidth="300px"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MySelectInput
                                        tabIndex={getIndexTable(index, 2)}
                                        placeholder="Concepto"
                                        name={`movimientoClienteDetalle[${index}].conceptoDeClienteId`}
                                        options={conceptosClientesByDropdown}
                                        enterInput={enterInput}
                                        index={index}
                                        onKeyDown={onKeyDownSelectConceptoCliente}
                                        inputWidth="140px"
                                        menuWidth="300px"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MySelectInput
                                        tabIndex={getIndexTable(index, 3)}
                                        placeholder="Cliente"
                                        name={`movimientoClienteDetalle[${index}].clienteId`}
                                        options={ClientesByDropdown}
                                        enterInput={enterInput}
                                        index={index}
                                        inputWidth="140px"
                                        menuWidth="300px"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 4)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Serie"
                                        name={`movimientoClienteDetalle[${index}].serie`}
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 5)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Folio"
                                        name={`movimientoClienteDetalle[${index}].folio`}
                                        pattern="[0-9]{0,16}"
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 6)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Serie Afecta"
                                        name={`movimientoClienteDetalle[${index}].serieAfecta`}
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 7)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Folio Afecta"
                                        name={`movimientoClienteDetalle[${index}].folioAfecta`}
                                        pattern="[0-9]{0,16}"
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 8)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Importe"
                                        name={`movimientoClienteDetalle[${index}].importe`}
                                        pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                        enterInput={enterInput}
                                        onKeyDown={onKeyDownInputImporte}
                                      />
                                    </Table.Cell>
                                    <MyDataGridRows width="100px" value={movimientoClienteDetalles?.fechaCancelacion? movimientoClienteDetalles?.fechaCancelacion.toString() : ""} alineacion="center" />
                                    <Table.Cell>
                                      {" "}
                                      <MySelectInput
                                        tabIndex={getIndexTable(index, 9)}
                                        placeholder="Moneda"
                                        name={`movimientoClienteDetalle[${index}].monedaId`}
                                        options={monedasByDropdown}
                                        enterInput={enterInput}
                                        inputWidth="140px"
                                        menuWidth="220px"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MyTextInput
                                        tabIndex={getIndexTable(index, 10)}
                                        className="widthTextTable heighttext input "
                                        placeholder="Tipo cambio"
                                        name={`movimientoClienteDetalle[${index}].tipoCambio`}
                                        pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      {" "}
                                      <MySelectInput
                                        tabIndex={getIndexTable(index, 11)}
                                        placeholder="Forma de pago"
                                        name={`movimientoClienteDetalle[${index}].formaDePagoId`}
                                        options={formasPagosByDropdown}
                                        enterInput={enterInput}
                                        index={index}
                                        onKeyDown={(!isValid) ? undefined : onKeyDownSelectFormaPago}
                                        arrayHelpers={arrayHelpers}
                                        inputWidth="140px"
                                        menuWidth="220px"
                                      />

                                    </Table.Cell>
                                    <MyDataGridRows width="100px" value={movimientoClienteDetalles?.automatico ? "TRUE" : "FALSE"} alineacion="center" />
                                    <Table.Cell>
                                      <Button
                                        icon
                                        tabIndex={getIndexTable(index, 12)}
                                        name={movimientoClienteDetalles?.id}
                                        type="Button"
                                        disabled={
                                          loading &&
                                          target ===
                                          String(movimientoClienteDetalles?.id)
                                        }
                                        loading={
                                          loading &&
                                          target ===
                                          String(movimientoClienteDetalles?.id)
                                        }
                                        onClick={(e) => {
                                          openModal(
                                            <DeleteModals
                                              deleteConfirmed={deleteConfirmed}
                                              deletedeclined={deletedeclined}
                                              e={e.currentTarget.name.toString()}
                                              id={movimientoClienteDetalles?.id}
                                              nombre={values.movimientoClienteDetalle ? values.movimientoClienteDetalle[index]?.cliente?.nombre : "Fila"}
                                              arrayHelpers={arrayHelpers}
                                              index={index}
                                            />,
                                            enumHeaderModals.MovimientoCliente
                                          );
                                        }}
                                        floated="right"
                                        // content="Eliminar"
                                        color="red"
                                      >
                                        <Icon name='delete' />
                                      </Button>
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )) : <Table.Row>
                              <Table.Cell>

                                <Button
                                  tabIndex={7}
                                  //  disabled={!isValid}
                                  primary
                                  type="button"
                                  onClick={() => {
                                    addRowMovCliente(arrayHelpers)
                                  }}
                                  content="Nuevo"
                                />

                              </Table.Cell>
                            </Table.Row>}

                          </Table.Body>
                        </Table>
                      </Segment>
                      /////
                    )}
                  />
                </div>
              </Grid>
            </Segment>
            <Segment>
              <Grid className="ui grid lineheight">
                <div className='col60'></div>
                <label></label>
                <div className='col60'></div>
                <label>   </label>
                <div className='col20'>
                  <h5>Total Póliza Cargos: {formatNumber(polizaCargo)}</h5>
                  <h5>Total Póliza Abonos: {formatNumber(polizaAbonos)}</h5>
                </div>
              </Grid>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/"
                loading={loading}
                tabIndex={lastElementTable + 2}
                firstElement={firstElement}
                lastElement={lastElementTable + 4}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})