import { Form, Label } from "semantic-ui-react";
import { useTipoDato } from '../../hooks/useTipoDato';
import { enumTipoDato } from './types/myTextinput-types';
import { forwardRef, useRef, useImperativeHandle, memo } from 'react';

const TextInput = forwardRef(function TextInput(props: Readonly<any>, ref) {
  const inputRef = useRef<any>(null);
  const texto = useRef<string>("");
  const [validateText, validateTextBlur] = useTipoDato();

  useImperativeHandle(ref, () => ({
    Focus(milisegundos = 0) { FocusControl(milisegundos); },
    ScrollIntoView() {
      inputRef.current.scrollIntoView();
    },
    SetText(texto: string) {
      inputRef.current.value = texto || "";
    },
    GetText() {
      return inputRef.current.value;
    },
    Clear() {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }));

  const setTexto = (texto: any) => {
    inputRef.current.value = texto || "";
  }

  const FocusControl = (milisegundos = 0) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
        inputRef.current.focus();
      }
    }, milisegundos);
  };

  const onKeyDown = (event: any) => {
    let esOnKeyDown;
    if (props.onKeyDown)
      esOnKeyDown = props.onKeyDown?.(event);
    if (esOnKeyDown !== false) {
      if (event.key === "Enter" || event.key === "Tab") {
        let element = event.target;
        let tabIndex = element.tabIndex;
        props.enterInput?.(tabIndex);
        event.preventDefault();
      }
    }
  };

  const onChangeValidation = (e: any) => {
    let startPos = e.target.selectionStart;
    const esValido = e.target.validity.valid;
    let esValidoOnChange;
    if (esValido) {
      if (props.tipoDato && props.tipoDato !== enumTipoDato.Email) {
        // Validación de acuerdo al tipo de dato (numérico, texto, etc.)
        validateText(e.target.value, props.tipoDato, props.aceptaNegativos, async (isValid: boolean, value: string) => {
          if (isValid) {
            texto.current = value;
          } else {
            // Restaurar el cursor si la entrada no es válida
            setTimeout(() => {
              e.target.setSelectionRange(startPos - 1, startPos - 1);
            }, 1);
          }
        }, props.decimalDigits);
      } else {
        texto.current = e.target.value;
      }
      if (props.onChange)
        esValidoOnChange = props.onChange?.(e);
      if (esValidoOnChange !== false) {
        props.form.setFieldValue(props.field.name, texto.current);
      } else {
        setTimeout(() => {
          e.target.setSelectionRange(startPos - 1, startPos - 1);
        }, 1);
      }
    }
  };

  const onBlurValidation = (e: any) => {
    let esValidoonBlur;
    let textoBlur = e?.target?.value;
    validateTextBlur(textoBlur, props.tipoDato, "", async (isValid: any, value: any) => {
      let _nt = "";
      if (isValid) {
        if (value === "" && props.tipoDato === enumTipoDato.Numerico) {
          textoBlur = "0";
        } else {
          _nt = value;
          textoBlur = value;
        }
      } else {
        if (props.tipoDato === enumTipoDato.Numerico) {
          _nt = '0';
          textoBlur = _nt;
        } else {
          _nt = "";
          textoBlur = _nt;
        }
      }
    }, "");
    esValidoonBlur = props.onBlur?.(textoBlur);
    if (esValidoonBlur !== false) {
      setTexto(textoBlur);
    }
  }

  return (
    <Form.Field className="width:5rem" error={props.touched && !!props.error}>
      <div className="form-group">
        <label className="uiAnchoCombo heighttext">{props.label}</label>
        <input
          style={props.style}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          className={
            props.className ? props.className : " widthtext heighttext input"
          }
          value={props.field.value || props.field.value === 0 ? props.field.value : ""}
          maxLength={props.maxLength}
          type={props.type}
          ref={inputRef}
          pattern={props.pattern}
          tabIndex={props.tabIndex}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
          onChange={onChangeValidation}
          onBlur={onBlurValidation}
          data-index={props.dataIndex}
        />
      </div>
      {props.touched && props.error ? (
        <Label basic color="red">
          {props.error}
        </Label>
      ) : null}
    </Form.Field>
  );
});
export default TextInput;