import { makeAutoObservable } from "mobx";
import agent from "../../api/agent";
import { ReporteVentas } from "../../models/venta/ReporteVentas";

export default class ReporteConfiguracionesStore {
    reporteVentaRegistry = new Map<number, ReporteVentas>();
    selectedReporteVenta: ReporteVentas | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    imprimirReporteEmpresas = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReporteEmpresas(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };
    imprimirReporteSucursales = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReporteSucursales(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };
    imprimirReporteUsuarios = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReporteUsuarios(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

    imprimirReportePerfiles = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReportePerfiles(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
    imprimirReporteMonedas = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReporteMonedas(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
    imprimirReporteImpuestos = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesConfiguraciones.imprimirReporteImpuestos(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
}