import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDeCompra } from "../../models/compra/TipoDeCompra";
import { toast } from "react-toastify";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { setDate } from "../../common/util/util";


export default class TipoDeCompraStore {
    tipoDeCompraRegistry = new Map<number, TipoDeCompra>();
    selectedTipoDeCompra: TipoDeCompra | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tipoDeCompraById() {
        return Array.from(this.tipoDeCompraRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get tiposDeComprasByDate() {
        return Array.from(this.tipoDeCompraRegistry.values()).sort((a) => a.id);
    }

    get tiposDeComprasByDropdown() {
        return entityByDropdown<TipoDeCompra>(Array.from(this.tipoDeCompraRegistry.values()));
    }
    get tiposDeComprasByDropdownByClave() {
        return entityByDropdown<TipoDeCompra>(Array.from(this.tipoDeCompraRegistry.values()), true);
    }

    fetchsTiposCompras = async () => {
        try {
            const response = await agent.TiposDeCompras.list();
            return response;
        } catch (error) {
            console.error("Error al buscar Tipo de compra", error);
        }
    };

    loadTiposDeCompras = async () => {
        this.setLoadingInitial(true);
        try {
            const tiposDeCompras = await agent.TiposDeCompras.list();
            tiposDeCompras.forEach(tipoDeCompra => {
                setDate<TipoDeCompra>(tipoDeCompra);
                this.setTipoDeCompra(tipoDeCompra);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    getTiposCompras = (id: number): TipoDeCompra | undefined => {
        return this.tipoDeCompraRegistry.get(id);
    }
    getTiposDeCompras = async () => {
        this.setLoadingInitial(true);
        const tiposDeCompras = await agent.TiposDeCompras.list();
        try {
            tiposDeCompras.forEach(tipoDeCompra => {
                setDate<TipoDeCompra>(tipoDeCompra);
                this.setTipoDeCompra(tipoDeCompra);
            })
            this.setLoadingInitial(false);
            return tiposDeCompras;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return tiposDeCompras;
        }
    }

    loadTipoDeCompra = async (id: number) => {
        let tipoDeCompra = this.getTipoDeCompra(id);
        if (tipoDeCompra) {
            this.selectedTipoDeCompra = tipoDeCompra;
            return tipoDeCompra;
        } else {
            this.loadingInitial = true;
            try {
                tipoDeCompra = await agent.TiposDeCompras.details(id);
                setDate<TipoDeCompra>(tipoDeCompra);
                this.setTipoDeCompra(tipoDeCompra);
                this.selectedTipoDeCompra = tipoDeCompra;
                this.setLoadingInitial(false);
                return tipoDeCompra;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setTipoDeCompra = (tipoDeCompra: TipoDeCompra) => {
        this.tipoDeCompraRegistry.set(tipoDeCompra.id, tipoDeCompra);
    }

    private getTipoDeCompra = (id: number) => {
        return this.tipoDeCompraRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoDeCompra = async (tipoDeCompra: TipoDeCompra) => {
        this.loading = true;
        try {
            await agent.TiposDeCompras.create(tipoDeCompra);

            runInAction(() => {
                this.selectedTipoDeCompra = tipoDeCompra;
                this.editMode = false;
                this.loading = false;
            })

            toast.success("Tipo de Compra creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar los datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateTipoDeCompra = async (tipoDeCompra: TipoDeCompra) => {
        this.loading = true;
        try {

            tipoDeCompra.tipoDeCompraTipo = undefined;
            tipoDeCompra.almacen = undefined;
            tipoDeCompra.conceptoDeInventario = undefined;
            tipoDeCompra.cuentaDeProveedor = undefined;
            tipoDeCompra.conceptoDeProveedor = undefined;
            await agent.TiposDeCompras.update(tipoDeCompra);
            runInAction(() => {
                this.selectedTipoDeCompra = tipoDeCompra;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Tipo de Compra actualizado con éxito");
        } catch (error) {
            toast.error("Problema al enviar los datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteTipoDeCompra = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposDeCompras.delete(id);
            runInAction(() => {
                this.tipoDeCompraRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Tipo de Compra eliminado con éxito");

        } catch (error) {
            toast.success("Problema al eliminar Tipo de Compra");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
