import { useEffect } from 'react'
import { Formik, Form } from "formik";
import { Button, Icon, Segment } from 'semantic-ui-react';
import { tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { observer } from 'mobx-react-lite';
import MyTextArea from '../../../../app/common/form/MyTextArea';


interface Props {
  closeModals: () => void;
  tabIndex: number;
  onDescripcion?: (data: any) => void;
}

export default observer(function DescripcionAdicional(props: Props) {
  const firstElement = props.tabIndex;
  const lastElement = props.tabIndex + 3;
  useEffect(() => { }, [])
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    props.onDescripcion?.(fo?.descripcionAdicional);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={{ descripcionAdicional: "" }}
    >
      {() => (
        <Form className="ui form">

          <div className="col100">
            <Segment>
              <div className='col100'>
                <MyTextArea
                  autoFocus
                  tabIndex={props.tabIndex + 1}
                  type='textarea'
                  placeholder=''
                  label="Descripcion adicional"
                  name="descripcionAdicional"
                  rows="4"
                />
              </div>
            </Segment>
          </div>
          <Segment>
            <Button.Group>
              <Button
                tabIndex={props.tabIndex + 2}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 3}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
                type="button"
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})