import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { MovimientoInventario } from "../../models/inventario/MovimientoInventario";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";

export default class MovimientoInventarioStore {
  movimientoInventarioRegistry = new Map<number, MovimientoInventario>();
  selectedMovimientoInventario: MovimientoInventario | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  movimientoInventarioFiltros: PaginationSpecParams = new PaginationFormValues();
  constructor() {
    makeAutoObservable(this);
  }

  get MovimientosInventariosByDate() {
    return Array.from(this.movimientoInventarioRegistry.values()).sort((a) => a.id);
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }

  cargarMovimiento = async (filtros: PaginationSpecParams) => {
    try {

      const movimientoInventario = await agent.MovimientosInventarios.obtenerMovimientoPorClave(filtros);
      if (movimientoInventario) {
        this.setMovimientoInventario(movimientoInventario);
        //this.selectedGeneracionComprobante = generacionComprobante
        return movimientoInventario;
      }
      // else {
      //     this.loadingInitial = true;
      // }
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
    if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
      try {
        const movimientoInventario = await agent.MovimientosInventarios.obtenerFolio(filtros);
        return movimientoInventario;

      } catch (error) {
        console.log(error);
      }
    } else {
      // this.serieFolioByTipoVentaRegistry.clear();
    }
  };

  loadMovimientosInventarios = async () => {
    this.setLoadingInitial(true);
    try {
      const movimientosInventarios = await agent.MovimientosInventarios.list();
      movimientosInventarios.forEach((movimientoInventario) => {
        this.setMovimientoInventario(movimientoInventario);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get movimientoInventarioByDropdown() {
    let movInvArreg = [new TipoDropDownValues()];
    let movInvOpt = new TipoDropDownValues();
    const movimientosInventarios = Array.from(this.movimientoInventarioRegistry.values()).sort(
      (a, b) => {
        if (a.clave > b.clave) return 1;
        if (a.clave < b.clave) return -1;
        return 0;
      }
    );
    movimientosInventarios.forEach((element) => {
      movInvOpt.key = element.id;

      movInvOpt.value = element.id;

      movInvArreg.push(movInvOpt);
      movInvOpt = new TipoDropDownValues();
    });
    return movInvArreg;
  }

  getMovimientosInventarios = async () => {
    this.setLoadingInitial(true);
    const movimientosInventarios = await agent.MovimientosInventarios.list();
    try {
      movimientosInventarios.forEach((movimientoInventario) => {
        this.setMovimientoInventario(movimientoInventario);
      });
      this.setLoadingInitial(false);
      return movimientosInventarios;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return movimientosInventarios;
    }
  };

  loadMovimientoInventario = async (id: number) => {
    let movimientoInventario = this.getMovimientoInventario(id);
    if (movimientoInventario) {
      this.selectedMovimientoInventario = movimientoInventario;
      return movimientoInventario;
    } else {
      this.loadingInitial = true;
      try {
        movimientoInventario = await agent.MovimientosInventarios.details(id);
        this.setMovimientoInventario(movimientoInventario);
        this.selectedMovimientoInventario = movimientoInventario;
        this.setLoadingInitial(false);
        return movimientoInventario;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setMovimientoInventario = (movimientoInventario: MovimientoInventario) => {
    this.movimientoInventarioRegistry.set(movimientoInventario.id, movimientoInventario);
  };

  private getMovimientoInventario = (id: number) => {
    return this.movimientoInventarioRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createMovimientoInventario = async (movimientoInventario: MovimientoInventario) => {
    this.loading = true;
    try {
      await agent.MovimientosInventarios.create(movimientoInventario);
      runInAction(() => {
        this.movimientoInventarioRegistry.set(movimientoInventario.id, movimientoInventario);
        this.selectedMovimientoInventario = movimientoInventario;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Movimiento inventario creada con éxito");
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateMovimientoInventario = async (movimientoInventario: MovimientoInventario) => {
    this.loading = true;
    try {
      await agent.MovimientosInventarios.update(movimientoInventario);
      runInAction(() => {
        this.movimientoInventarioRegistry.set(movimientoInventario.id, movimientoInventario);
        this.selectedMovimientoInventario = movimientoInventario;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteMovimientoInventario = async (id: number) => {
    this.loading = true;
    try {
      await agent.MovimientosInventarios.delete(id);
      runInAction(() => {
        this.movimientoInventarioRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
