import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { UnidadMedida } from "../../models/inventario/UnidadMedida";
import { entity2ByDropdown } from "../../common/funciones/entityByDropdown";

export default class UnidadMedidaStore {
  unidadMedidaRegistry = new Map<number, UnidadMedida>();
  selectedUnidadMedida: UnidadMedida | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get UnidadesMedidasByDate() {
    return Array.from(this.unidadMedidaRegistry.values()).sort((a) => a.id);
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }

  loadUnidadesMedidas = async () => {
    this.setLoadingInitial(true);
    try {
      const unidadesMedidas = await agent.UnidadesMedidas.list();
      unidadesMedidas.forEach((unidadMedida) => {
        this.setUnidadMedida(unidadMedida);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get unidadesMedidasTipoByDropdown() {
    return entity2ByDropdown<UnidadMedida>(
      Array.from(this.unidadMedidaRegistry.values())
    );
  }
  getUnidadesMedidas = async () => {
    this.setLoadingInitial(true);
    const unidadesMedidas = await agent.UnidadesMedidas.list();
    try {
      unidadesMedidas.forEach((unidadMedida) => {
        this.setUnidadMedida(unidadMedida);
      });
      this.setLoadingInitial(false);
      return unidadesMedidas;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return unidadesMedidas;
    }
  };

  loadUnidadMedida = async (id: number) => {
    let unidadMedida = this.getUnidadMedida(id);
    if (unidadMedida) {
      this.selectedUnidadMedida = unidadMedida;
      return unidadMedida;
    } else {
      this.loadingInitial = true;
      try {
        unidadMedida = await agent.UnidadesMedidas.details(id);
        this.setUnidadMedida(unidadMedida);
        this.selectedUnidadMedida = unidadMedida;
        this.setLoadingInitial(false);
        return unidadMedida;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setUnidadMedida = (unidadMedida: UnidadMedida) => {
    this.unidadMedidaRegistry.set(unidadMedida.id, unidadMedida);
  };

  private getUnidadMedida = (id: number) => {
    return this.unidadMedidaRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createUnidadMedida = async (unidadMedida: UnidadMedida) => {
    this.loading = true;
    try {
      await agent.UnidadesMedidas.create(unidadMedida);
      runInAction(() => {
        this.unidadMedidaRegistry.set(unidadMedida.id, unidadMedida);
        this.selectedUnidadMedida = unidadMedida;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };



  deleteUnidadMedida = async (id: number) => {
    this.loading = true;
    try {
      await agent.UnidadesMedidas.delete(id);
      runInAction(() => {
        this.unidadMedidaRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
