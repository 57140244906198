import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { ConceptoFinanza } from "../../models/Finanza/ConceptoFinanza";

export default class ConceptoFinanzaStore {
    conceptoFinanzaRegistry = new Map<number, ConceptoFinanza>();
    selectedConceptoFinanza: ConceptoFinanza | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get conceptoFinanzaByDropdown() {
        return entityByDropdown<ConceptoFinanza>(Array.from(this.conceptoFinanzaRegistry.values()));
    }
    get conceptosFinanzasByDate() {
        return Array.from(this.conceptoFinanzaRegistry.values()).sort((a) => a.id);
    }

    get conceptoFinanzaById() {
        return Array.from(this.conceptoFinanzaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get conceptosFinanzasByDropdown() {
        let ConceptoFinanzaArreg = [new TipoDropDownValues()];
        let conceptoFinanzaOpt = new TipoDropDownValues();
        const cvts = Array.from(this.conceptoFinanzaRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            conceptoFinanzaOpt.key = element.id;
            conceptoFinanzaOpt.text = element.nombre;
            conceptoFinanzaOpt.value = element.id;
            conceptoFinanzaOpt.content = element.nombre;
            ConceptoFinanzaArreg.push(conceptoFinanzaOpt);
            conceptoFinanzaOpt = new TipoDropDownValues();

        });
        return ConceptoFinanzaArreg;
    }

    getConceptosFinanzas = async () => {
        this.setLoadingInitial(true);
        const conceptosFinanzas = await agent.ConceptosFinanzas.list();
        try {
            conceptosFinanzas.forEach(conceptoFinanza => {
                this.setConceptoFinanza(conceptoFinanza);
            })
            this.setLoadingInitial(false);
            return conceptosFinanzas;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosFinanzas;
        }
    }

    loadConceptosFinanzas = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const conceptosFinanzas = await agent.ConceptosFinanzas.list();
            conceptosFinanzas.forEach(conceptoFinanza => {
                setDate<ConceptoFinanza>(conceptoFinanza);
                this.setConceptoFinanza(conceptoFinanza);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadConceptoFinanza = async (id: number) => {
        let conceptoFinanza = this.getConceptoFinanza(id);
        if (conceptoFinanza) {
            setDate<ConceptoFinanza>(conceptoFinanza);
            this.selectedConceptoFinanza = conceptoFinanza;
            return conceptoFinanza;
        } else {
            this.loadingInitial = true;
            try {
                conceptoFinanza = await agent.ConceptosFinanzas.details(id);
                this.setConceptoFinanza(conceptoFinanza);
                this.selectedConceptoFinanza = conceptoFinanza;
                this.setLoadingInitial(false);
                return conceptoFinanza;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoFinanza = (conceptoFinanza: ConceptoFinanza) => {
        this.conceptoFinanzaRegistry.set(conceptoFinanza.id, conceptoFinanza);
    }

    private getConceptoFinanza = (id: number) => {
        return this.conceptoFinanzaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoFinanza = async (conceptoFinanza: ConceptoFinanza) => {
        this.loading = true;
        try {
            await agent.ConceptosFinanzas.create(conceptoFinanza);
            runInAction(() => {
                this.selectedConceptoFinanza = conceptoFinanza;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Concepto de finanza creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateConceptoFinanza = async (conceptoFinanza: ConceptoFinanza) => {
        this.loading = true;
        try {
            await agent.ConceptosFinanzas.update(conceptoFinanza);
            runInAction(() => {
                this.selectedConceptoFinanza = conceptoFinanza;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Concepto de finanzas editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteConceptoFinanza = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosFinanzas.delete(id);
            runInAction(() => {
                this.conceptoFinanzaRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Concepto de Finanza eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar el concepto de Finanza");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
