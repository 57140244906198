import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Grid, Header, Button, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { enumHeaderModals, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { MovimientoInventario, MovimientoInventarioDetallesFormValues, MovimientoInventarioFormValues } from "../../../../app/models/inventario/MovimientoInventario";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { OnChangeProps } from "../../../../app/models/myProps";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import { TipoDropdown, TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import { PaginationFormValues } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import DeleteModals from "../../../modals/DeleteModals";

export default observer(function MovimientoInventarioDashboard() {
  const firstElement = 0;
  const lastElement = 2000000;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { empresaStore, almacenStore, productoStore, conceptoDeInventarioStore, sucursalStore, movimientoInventarioStore, tipoMovimientoStore } = useStore();
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore
  const { tiposMovimientosByDropdownByClave } = tipoMovimientoStore
  const initialIndexTable = 7;
  const numberControlsTable = 6;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const { fetchSucursales } = sucursalStore
  const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());
  const [target, setTarget] = useState("");
  const {
    obtenerSiguienteFolio,
    loadingInitial,
    createMovimientoInventario,
    updateMovimientoInventario,
    loading,
    movimientoInventarioFiltros,
    deleteMovimientoInventario,
    cargarMovimiento
  } = movimientoInventarioStore
  const inputAlmacenRef = useRef<any>(null);
  const { id } = useParams<{ id: string }>();
  const [movimientoInventario, setMovimientoInventario] = useState(new MovimientoInventarioFormValues());
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    tipoMovimientoId: Yup.number()
      .min(1, "Favor de seleccionar un Tipo Movimiento.")
      .required("Tipo movimiento es requerido."),
    clave: Yup.number()
      .typeError("El valor debe ser un numero.")
      .min(1, "Favor de ingresar una clave mayor a 0.")
      .required("La clave es requerida."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    detalles: Yup.array().of(
      Yup.object().shape({
        almacenId: Yup.number()
          .min(1, "Favor de seleccionar un almacen.")
          .required("Almacen es requerido."),
        conceptoDeInventarioId: Yup.number()
          .min(1, "Favor de seleccionar una Concepto.")
          .required("Concepto es requerido."),
        productoId: Yup.number()
          .min(1, "Favor de seleccionar un producto.")
          .required("Producto es requerido."),
        importe: Yup.number().typeError("El valor debe ser un numero.").required("El importe es requerido"),
        cantidad: Yup.number().typeError("El valor debe ser un numero.").required("La cantidad es requerido"),
      })
    ),
  });
  const { almacenesByDropdown, loadAlmacenes } = almacenStore;
  const { conceptosInventariosByDropdown, getConceptosDeInventarios } = conceptoDeInventarioStore;
  const { loadProductoByEmpresaSucursal, getProductoByEmpresaSucursalById, productosByEmpresaSucursalDropdownByClave } = productoStore

  useEffect(() => {
    getConceptosDeInventarios()
    void loadEntityEmpresas();
    tipoMovimientoStore.getTiposMovimientos();
    void loadAlmacenes();
  }, []);

  const deleteConfirmed = (e: string, id: number, arrayHelpers: any, index: any) => {
    if (id > 0) {
      deleteMovimientoInventario(id).then(() => {
        arrayHelpers.remove(index);
      });
    } else {
      arrayHelpers.remove(index);
    }
    setTarget(e);
    closeModal();
  }

  const deletedeclined = () => {
    closeModal();
  }

  function handleSubmit(movimientoInventario: MovimientoInventario) {
    movimientoInventario.id > 0 ? updateMovimientoInventario(movimientoInventario) : createMovimientoInventario(movimientoInventario);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };

  function closeModals() {
    closeModal();
  }

  const onChangeSelectEmpresa = ({ value, setFieldValue }: OnChangeProps) => {
    setFieldValue("sucursalId", 0)
    setFieldValue("tipoMovimientoId", 0)
    setFieldValue("clave", 0)
    fetchSucursales(value).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
    movimientoInventarioFiltros.empresaId = value;
  }

  const onChangeSelectTipoMovimiento = ({ value, setFieldValue, values }: OnChangeProps) => {
    setFieldValue("clave", 0)
    if (values.empresaId && values.sucursalId && value)
      obtenerSiguienteFolio(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, tipoMovimientoId: value })).then((clave) => {
        setFieldValue("clave", clave);
      });

  }

  const oncChangeSelectSucursal = ({ setFieldValue, values, value }: OnChangeProps) => {
    setFieldValue("tipoMovimientoId", 0)
    setFieldValue("clave", 0)
    if (values.empresaId && value)
      loadProductoByEmpresaSucursal((new PaginationFormValues({ empresaId: values.empresaId, sucursalId: value })));
  }

  const onBlurFolio = (setFieldValue?: any, values?: any, index?: any, text?: any) => {
    if (values.empresaId && values.sucursalId && values.tipoMovimientoId && text && text > 0)
      cargarMovimiento(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, tipoMovimientoId: values.tipoMovimientoId, folio: text })).then((result) => {
        if (result) {
          setMovimientoInventario(result);
        }
      })
  }

  const onKeyDownSelectImpuesto = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any, isValid: any) => {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    if (isValid) {
      arrayHelpers.push(new MovimientoInventarioDetallesFormValues());
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable + numberControlsTable
      );
    }
    setTimeout(() => {
      if (inputAlmacenRef.current) {
        inputAlmacenRef.current.Focus();
      }
    }, 0);
  }

  function OnChangeSelectProducto(
    productoId: number,
    setfieldValue: any,
    index?: any,
    values?: any
  ) {
    if (productoId === 0) {
      setfieldValue(`detalles[${index}].costo`, 0);
    }
    else {
      const producto = getProductoByEmpresaSucursalById(productoId);
      if (producto) {
        setfieldValue(`detalles[${index}].costo`, producto.costo);
      }
    }
  }

  const handleFieldChange = ({ index, name, value, setFieldValue, values }: OnChangeProps) => {
    const detalles = [...values?.detalles];
    if (index !== undefined && name !== undefined) {
      detalles[index][name] = value;
      if (name === 'cantidad') {
        const cantidad = parseFloat(detalles[index].cantidad) || 0;
        const costo = parseFloat(detalles[index]?.costo) || 0;
        detalles[index].importe = (cantidad * costo).toFixed(2);
      }
    }
    setFieldValue('detalles', detalles);
  }

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={movimientoInventario}
      >
        {({ isValid, values }) => (
          <Form className='ui form' >
            {movimientoInventario.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <div className='colsp' />
            <Grid columns='equal'>
              <Grid.Row>
                <Header as='h2' icon='align center' content='Movimientos de Inventario' color='blue' />
              </Grid.Row>
              <Grid.Row>
                <div className='colsp' />
                <div className='col14'>
                  <MySelectInput
                    tabIndex={1}
                    placeholder={"Seleccionar"}
                    label="Empresa"
                    name="empresaId"
                    enterInput={enterInput}
                    onChangeProps={onChangeSelectEmpresa}
                    options={EmpresasByDropdownByClave}
                    inputWidth="85px"
                    menuWidth="300px"
                    autoFocus
                  />
                </div>
                <div className='colsp' />
                <div className='col14'>
                  <MySelectInput
                    tabIndex={2}
                    placeholder={"Seleccionar"}
                    label="Sucursal"
                    name="sucursalId"
                    enterInput={enterInput}
                    onChangeProps={oncChangeSelectSucursal}
                    options={sucursales}
                    inputWidth="85px"
                    menuWidth="300px"
                  />
                </div>
                <div className='colsp' />
                <div className='col18'>
                  <MySelectInput
                    tabIndex={3}
                    placeholder={"Seleccionar"}
                    label="Tipo Movimiento"
                    name="tipoMovimientoId"
                    options={tiposMovimientosByDropdownByClave}
                    enterInput={enterInput}
                    onChangeProps={onChangeSelectTipoMovimiento}
                  />
                </div>
                <div className='col10'>
                  <MyTextInput
                    tabIndex={4}
                    placeholder=''
                    label="clave"
                    name='clave'
                    onBlur={onBlurFolio}
                    enterInput={enterInput}
                  />
                </div>
                <div className='col12'>
                  Fecha Emision
                  <MyDateInput
                    tabIndex={5}
                    placeholderText="Fecha Emision"
                    name="fecha"
                    dateFormat="dd/MM/yyyy"
                    enterInput={enterInput}

                  />
                </div>
                <div className='col20'></div>
                <div className='col12'>
                  Fec. Cancelacion
                  <MyDateInput
                    tabIndex={6}
                    placeholderText="Fecha Cancelacion"
                    name="fechaCancelacion"
                    dateFormat="dd/MM/yyyy"
                    enterInput={enterInput}
                  />
                </div>

              </Grid.Row>
              <Grid.Row>
                <div className='colsp' />
                <div className='col50'>
                  <MyTextInput
                    tabIndex={7}
                    placeholder=''
                    label="Comentarios"
                    name='comentarios'
                    enterInput={enterInput}
                  />
                </div>
                <div className='colsp' />
              </Grid.Row>
              <FieldArray
                name="detalles"
                render={(arrayHelpers) =>
                (
                  /////
                  <Segment basic className="tabla-container">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Almacen</Table.HeaderCell>
                          <Table.HeaderCell>Concepto</Table.HeaderCell>
                          <Table.HeaderCell>Producto</Table.HeaderCell>
                          <Table.HeaderCell>Cantidad</Table.HeaderCell>
                          <Table.HeaderCell>Costo</Table.HeaderCell>
                          <Table.HeaderCell>Importe</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          values.detalles && values.detalles.length > 0 ?
                            (
                              values.detalles?.map(
                                (detalles, index) =>
                                (
                                  <Table.Row key={index.valueOf()} >
                                    <Table.Cell >
                                      <div className="col30Block">
                                        <MySelectInput
                                          tabIndex={index * numberControlsTable + 8}
                                          placeholder="Almacen"
                                          name={`detalles[${index}].almacenId`}
                                          index={index}
                                          fieldName='almacenId'
                                          options={almacenesByDropdown}
                                          enterInput={enterInput}
                                          inputWidth="170px"
                                          menuWidth="300px"
                                          ref={inputAlmacenRef}
                                        />
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div className='col35'>
                                        <MySelectInput
                                          tabIndex={index * numberControlsTable + 9}
                                          placeholder="Seleccionar"
                                          name={`detalles[${index}].conceptoDeInventarioId`}
                                          index={index}
                                          fieldName='conceptoDeInventarioId'
                                          options={conceptosInventariosByDropdown}
                                          enterInput={enterInput}
                                          inputWidth="200px"
                                          menuWidth="300px"
                                        />
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <MySelectInput
                                        tabIndex={index * numberControlsTable + 10}
                                        placeholder="Producto"
                                        name={`detalles[${index}].productoId`}
                                        options={productosByEmpresaSucursalDropdownByClave}
                                        enterInput={enterInput}
                                        index={index}
                                        fieldName='productoId'
                                        onChange={OnChangeSelectProducto}
                                        inputWidth="140px"
                                        menuWidth="250px"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <MyTextInput
                                        name={`detalles[${index}].cantidad`}
                                        //   type="number"
                                        tabIndex={index * numberControlsTable + 11}
                                        onChangeProps={handleFieldChange}
                                        index={index}
                                        fieldName='cantidad'
                                        enterInput={enterInput}
                                        decimalDigits={"1,2"}
                                        tipoDato={enumTipoDato.Numerico}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <MyTextInput
                                        name={`detalles[${index}].costo`}
                                        disabled
                                        tabIndex={index * numberControlsTable + 12}
                                        index={index}
                                        fieldName='costo'
                                        enterInput={enterInput}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <MyTextInput placeholder='Importe'
                                        name={`detalles[${index}].importe`}
                                        maxLength={7}
                                        arrayHelpers={arrayHelpers}
                                        index={index}
                                        enterInput={enterInput}
                                        tabIndex={index * numberControlsTable + 13}
                                        fieldName='importe'
                                        onKeyDown={
                                          index === values.detalles?.length! - 1 && isValid ? (onKeyDownSelectImpuesto) : undefined
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        icon
                                        tabIndex={index * numberControlsTable + 14}
                                        name={detalles?.id}
                                        type="Button"
                                        disabled={
                                          loading &&
                                          target ===
                                          String(detalles?.id)
                                        }
                                        loading={
                                          loading &&
                                          target ===
                                          String(detalles?.id)
                                        }
                                        onClick={(e) => {
                                          openModal(
                                            <DeleteModals
                                              deleteConfirmed={deleteConfirmed}
                                              deletedeclined={deletedeclined}
                                              e={e.currentTarget.name.toString()}
                                              id={detalles?.id}
                                              nombre={detalles?.producto ? detalles.producto?.nombre : "Fila"}
                                              arrayHelpers={arrayHelpers}
                                              index={index}
                                            />,
                                            enumHeaderModals.MovimientoInventario
                                          );
                                        }}
                                        floated="right"
                                        color="red"
                                      >
                                        <Icon name='delete' />
                                      </Button>
                                    </Table.Cell>

                                  </Table.Row>
                                ))) : <Table.Row>
                              <Table.Cell>
                                <Button
                                  tabIndex={values.detalles?.length! * numberControlsTable + 15}
                                  primary
                                  type="button"
                                  onClick={() => {
                                    if (isValid) {
                                      arrayHelpers.push(new MovimientoInventarioDetallesFormValues());
                                      setLastElementTable(
                                        (prevLastElementTable) =>
                                          prevLastElementTable + numberControlsTable
                                      );
                                    }
                                    setTimeout(() => {
                                      if (inputAlmacenRef.current) {
                                        inputAlmacenRef.current.Focus();
                                      }
                                    }, 0);
                                  }}
                                  content="Nuevo"
                                />

                              </Table.Cell>
                            </Table.Row>
                        }
                      </Table.Body>
                    </Table>
                  </Segment>
                )}
              />
            </Grid>

            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  className="ui primary button"
                  tabIndex={lastElement + 1}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={setFechaObservacion}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Moneda
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={movimientoInventario.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>

            <Segment>
              <BarraBotones
                loading={loading}
                tabIndex={lastElement + 2}
                id={id}
                firstElement={firstElement}
                lastElement={lastElement + 4}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})