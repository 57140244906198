import React, { useEffect, useState,useRef } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import { CancelarGeneracionComprobante, CancelarGeneracionComprobanteFormValues } from '../../../../app/models/venta/CancelarGeneracionComprobante';
import { observer } from 'mobx-react-lite';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';

interface Props {
  cancelacion: CancelarGeneracionComprobante;
  closeModals: () => void;
  setCancelacion: (setFO: CancelarGeneracionComprobanteFormValues) => void;
  tabIndex: number;
  onCopy?: (data: any) => void;
  uuid?:string;
  
}

export default observer (function CancelarComprobantes(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const { c_MotivoCancelacionStore } = useStore();
  const { c_MotivosCancelacionesByDropdown } = c_MotivoCancelacionStore;
  const { generacionComprobanteStore } = useStore();
  const { getComprobantesPorUUIDRelacionado,  loading } = generacionComprobanteStore;
  const [conRelacion, setconRelacion] = useState(false);
  const validationSchema = Yup.object({  });
  const [copiarComprobanteParams, setCopiarComprobanteParams] = useState<PaginationSpecParams>(new PaginationFormValues());

  const uuidsRelacionadosRef = useRef<any>(null);
  useEffect(() => {
    c_MotivoCancelacionStore.getc_MotivosCancelaciones();
  }, [])
  
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };


  const obtenerUUIDComprobanteRelacionados = (values: any, setFieldValue: any) => {
    debugger
    getComprobantesPorUUIDRelacionado({ ...copiarComprobanteParams, uuid: props.uuid }).then((result) => {
        
      if (result) {
        setFieldValue("uuidsRelacionados", result);
        uuidsRelacionadosRef.current.SetText(result);
        //setGeneracionComprobanteCot(result)
      }
     /* if (result === undefined || result === null) {
        setGeneracionComprobanteCot(null);
        toast.info("No hay Comprobante con esos Filtros", { position: "bottom-right" })
      }*/
    })
    
  }



  function seleccionMotivo(idSelected: number,setFieldValue?: any, values?: any) {
    debugger
    setconRelacion(idSelected === 1)
    obtenerUUIDComprobanteRelacionados(values, setFieldValue);
    //const tipoVenta = tipoVentaStore.getTipoVenta(idSelectedTipoVenta);
    //setesNotaCredito(tipoVenta?.tipoVentaTipo?.clave === 30);
    //setesREP(tipoVenta?.tipoVentaTipo?.clave === 40);
    //void loadSerieByIdTipoVenta(empresaSucursal);
  }

  function handleSubmit(fo: any) {
    props.onCopy?.(fo);
    props.setCancelacion(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  return (
    <Formik
      //validationSchema={validationSchema}
      onSubmit={(values) => {
       // debugger
        handleSubmit(values);
      }}
      enableReinitialize 
      initialValues={props.cancelacion}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <Segment>
            <div className="col100">
              <FieldArray
                name="cancelacion"
                render={(arrayHelpers) => (
                  /////
                  <Segment>
                    <div>
                      <div className='col30'>
                        <MyCheckInput
                          label="&nbsp;"
                          placeholder="Cancelacion en CERO"
                          name="cancelacionCero"
                          enterInput={enterInput}
                          tabIndex={16}
                        />
                      </div>
                      <div className='col30'>
                        <MyCheckInput
                          label="&nbsp;"
                          placeholder="Cancelacion en ROJO"
                          name="cancelacionRojo"
                          enterInput={enterInput}
                          tabIndex={16}
                        />
                      </div>
                    </div>
                    <div className='col50'>
                      <MyTextInput
                        tabIndex={3}
                        label="Motivo Cancelacion Interna"
                        placeholder="Motivo Cancelacion Interna"
                        name="motivoCancelacion"
                        enterInput={enterInput} />
                    </div>
                    <div className='col50'>
                      <MySelectInput
                        tabIndex={9}
                        label="Motivo Cancelacion SAT"
                        placeholder="Motivo Cancelacion SAT"
                        name="c_MotivoCancelacionId"
                        options={c_MotivosCancelacionesByDropdown}
                        onChange={seleccionMotivo}
                        enterInput={enterInput} 
                        inputWidth="550px"
                        menuWidth="610px"
                        />
                    </div>
                    {conRelacion ? (
                    <div className='col50'>
                      <MyTextInput
                        ref={uuidsRelacionadosRef}
                        label="UUIDS Relacionados"
                        placeholder="UUIDS Relacionados"
                        name="uuidsRelacionados"
                        tabIndex={3}
                        enterInput={enterInput} 
                        />
                    </div>
                    ): ("")}
                  </Segment>
                  /////
                )}
              />
            </div>
          </Segment>
          <Segment>
            <Button.Group>
              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>

              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton} >
                <Icon name="backward" />
                Cancelar
              </Button>

            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>

  )
} )