import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CondicionDePago } from "../../models/gestionCliente/CondicionDePago";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CondicionPagoStore {
    condicionPagoRegistry = new Map<number, CondicionDePago>();
    selectedCondicionPago: CondicionDePago | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get condicionesPagosByDropdown() {
        return entityByDropdown<CondicionDePago>(
            Array.from(this.condicionPagoRegistry.values())
        );
    }
    get condicionesPagosByDate() {
        return Array.from(this.condicionPagoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadCondicionesPagos = async () => {
        this.setLoadingInitial(true);
        try {
            const condicionesPagos = await agent.CondicionesPagos.list();
            condicionesPagos.forEach(condicionPago => {
                this.setCondicionPago(condicionPago);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCondicionesPagos = async () => {
        this.setLoadingInitial(true);
        const condicionesPagos = await agent.CondicionesPagos.list();
        try {
            condicionesPagos.forEach(condicionPago => {
                this.setCondicionPago(condicionPago);
            })
            this.setLoadingInitial(false);
            return condicionesPagos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return condicionesPagos;
        }
    }
    // Este método filtra las opciones de pago en función de la clave de cuentasDeVentasTipos
    filterCondicionesPagosByTipoVenta = (cuentasDeVentasTiposClave: any) => {
        // Si es clave 10, solo muestra CondicionesDePagos clave 1
        if (cuentasDeVentasTiposClave === 10) {
            return entityByDropdown<CondicionDePago>(
                Array.from(this.condicionPagoRegistry.values()).filter(condicionPago => condicionPago.clave === 1));
            //return this.condicionesPagosByDropdown.filter(pago => pago.key === 1);
        }
        // Si no es clave 10, devuelve todas las opciones de pago
        return this.condicionesPagosByDropdown;
    }
    loadCondicionPago = async (id: number) => {
        let condicionPago = this.getCondicionPago(id);
        if (condicionPago) {
            this.selectedCondicionPago = condicionPago;
            return condicionPago;

        } else {
            this.loadingInitial = true;
            try {
                condicionPago = await agent.CondicionesPagos.details(id);
                this.setCondicionPago(condicionPago);
                this.selectedCondicionPago = condicionPago;
                this.setLoadingInitial(false);
                return condicionPago;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setCondicionPago = (condicionPago: CondicionDePago) => {
        this.condicionPagoRegistry.set(condicionPago.id, condicionPago);
    }

    private getCondicionPago = (id: number) => {
        return this.condicionPagoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCondicionPago = async (condicionPago: CondicionDePago) => {
        this.loading = true;
        try {
            await agent.CondicionesPagos.create(condicionPago);
            runInAction(() => {
                this.condicionPagoRegistry.set(condicionPago.id, condicionPago);
                this.selectedCondicionPago = condicionPago;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }


    deleteCondicionPago = async (id: number) => {
        this.loading = true;
        try {
            await agent.CondicionesPagos.delete(id);
            runInAction(() => {
                this.condicionPagoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
