import { useState, useEffect, useRef, useContext } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Header, Button, Icon, Label, SegmentGroup, TabPane, Tab } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import { CapturaPagos, CapturaPagosFormValues, ComprobantesSaldo, MovimientoEliminado, PagoAplicado } from '../../../../app/models/gestionCliente/CapturaPagos';
import MyCheckInput from '../../../../app/common/form/MyCheckInput';
import { enumTipoDato } from '../../../../app/common/form/types/myTextinput-types';
import { Redondear, formatDecimal } from '../../../../app/base/Tools';
import moment from 'moment';
import MyDataGridRows from '../../../../app/common/form/MyDataGridRows';
import { UserContext } from '../../../../app/context/UserContext';
import DeleteModals from '../../../modals/DeleteModals';

export default observer(function EstadoCuentaProveedorForm() {
  const { user } = useContext(UserContext);
  const txtAbono = useRef<any>()
  let indexCaptura = 0;
  const lastElementTable = 242;
  const [target, setTarget] = useState("");
  const firstElement = 0;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { empresaStore, sucursalStore, capturaPagosStore, cuentaDeClienteStore, conceptoClienteStore, clienteStore, formaPagoStore } = useStore();
  const { formasPagosByDropdown, loadFormasPagos } = formaPagoStore;
  const { clienteByEmpresaSucursalByDropdownByClave, setClienteByEmpSucRegClear, loadClienteByIdEmpresaSucursal } = clienteStore
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore
  const { cuentaClienteByDropdownByClave, loadCuentasClientes } = cuentaDeClienteStore
  const { conceptosClientesByDropdown, loadConceptosClientes } = conceptoClienteStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const { deletePagoAplicado, pagosAplicadosById, movimientoEliminadoById, loadingInitial, createCapturaPagos, loading, loadCapturaPagos, capturaPagosParams } = capturaPagosStore
  const [capturaPagosFormValues] = useState(new CapturaPagosFormValues());
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    cuentaDeClienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cuenta.")
      .required("Cuenta es requerido."),
    clienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cliente.")
      .required("Cliente es requerido."),
    abonar: Yup.number()
      .typeError("El valor debe ser un numero.")
      .moreThan(0, "Favor de ingresar abonar mayor a 0.")
      .required("Abonar es requerida."),
    numOperacion: Yup.number()
      .typeError("El valor debe ser un numero.")
      .moreThan(0, "Favor de ingresar Núm Operación mayor a 0.")
      .required("Núm Operación es requerida."),
    conceptoDeClienteId: Yup.number()
      .min(1, "Favor de seleccionar un Concepto.")
      .required("Concepto es requerido."),
    formaDePagoId: Yup.number()
      .min(1, "Favor de seleccionar un Forma de pago.")
      .required("Forma de pago es requerido."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    fechaDeposito: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    comprobantesSaldo: Yup.array().of(
      Yup.object().shape({
      })
    ),
  });

  useEffect(() => {
    void loadFormasPagos();
    void loadConceptosClientes();
    void loadCuentasClientes();
    void loadEntityEmpresas();
  }, []);
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deletePagoAplicado(id);
    setTarget(e);
    closeModal();
  }
  const handleSubmit = (capturaPagos: CapturaPagos) => {
    let capturapagosArray: CapturaPagos[] = [];
    capturaPagos.comprobantesSaldo?.forEach((movimientos) => {
      if (movimientos.abono > 0) {
       
      }
    });
    if (capturapagosArray?.length > 0)
      void createCapturaPagos(capturapagosArray);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }



  function deletedeclined() {
    closeModal();
  }

  const selectInputEmpresaOnChange = (idSelected: number, setFieldAny: any) => {
    setFieldAny("sucursalId", 0);
    setFieldAny("clienteId", 0);
    setClienteByEmpSucRegClear();
    capturaPagosParams.empresaId = 0;
    capturaPagosParams.sucursalId = 0;
    capturaPagosParams.clienteId = 0;
    void loadSucursalByIdEmpresa(idSelected);
    capturaPagosParams.empresaId = idSelected;
  }
  const selectInputSucursalOnChange = (idSelected: number, setFieldAny: any) => {
    setFieldAny("clienteId", 0);
    setClienteByEmpSucRegClear();
    capturaPagosParams.clienteId = 0;
    capturaPagosParams.sucursalId = idSelected;
    void loadClienteByIdEmpresaSucursal(capturaPagosParams);
  }
  const selectInputClienteOnChange = (idSelected: any) => {
    capturaPagosParams.clienteId = parseInt(idSelected);

  }
  const selectInputCuentaOnChange = (idSelected: any) => {
    capturaPagosParams.cuentaId = idSelected;

  }
  const selectInputClienteOnBluer = (setFieldValue: any) => {
    asignarDatos(setFieldValue);
  }
  const selectInputClienteOnKeyUp = (clienteId: any, setFieldValue: any) => {
    asignarDatos(setFieldValue);
  }
  const asignarDatos = (setFieldValue?: any) => {
    if (capturaPagosParams.clienteId > 0 && capturaPagosParams.empresaId > 0 && capturaPagosParams.sucursalId > 0 && capturaPagosParams.cuentaId > 0) {
      loadCapturaPagos(capturaPagosParams).then((result) => {
        if (result) {
          let pagoAplicadoMovimiento = 0;
          let movimientoDetalleArray: ComprobantesSaldo[] = [];
          result.comprobantesSaldo?.forEach((movimientoDetalle) => {
            pagoAplicadoMovimiento = 0;
            result?.pagoAplicado?.forEach(pagoAplicado => {
              //  if ((pagoAplicado.folio == movimientoDetalle.folio && pagoAplicado.serie == movimientoDetalle.serie)) {
              if ((pagoAplicado.movimientoClienteId == movimientoDetalle.id)) {
                pagoAplicadoMovimiento += pagoAplicado.abono;
              }
            });
            movimientoDetalle.saldo = movimientoDetalle.importe - pagoAplicadoMovimiento;
            movimientoDetalle.abono = 0;
            if (movimientoDetalle.saldo > 0)
              movimientoDetalleArray = [...movimientoDetalleArray, movimientoDetalle];
          });
          setFieldValue("comprobantesSaldo", movimientoDetalleArray);
          setFieldValue("pagoAplicado", result.pagoAplicado);
        }
        else {
          setFieldValue("comprobantesSaldo", []);
        }
      })
    } else {
      setFieldValue("comprobantesSaldo", []);
    }
  }
  const inputAbonarKeyDown = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any) => {
    calcularDataGrid(values, event, index, setFiledValue);
  }
  const inputAbonarOnBlur = (setFiledValue: any, values: any) => {
    calcularDataGrid(values, "", "", setFiledValue);
  }
  const inputAbonoKeyDown = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any) => {
    calcularDataGrid(values, event, index, setFiledValue);
  }
  const inputAbonoOnBlur = (setFiledValue: any, values: any, index: any) => {
    calcularDataGrid(values, "", index, setFiledValue);
  }
  const calcularDataGrid = (values: CapturaPagos, event?: any, index?: any, setFiledValue?: any) => {
    if (values.abonar > 0) {
      let abonadoGrid = 0;
      let totalFaacturas = 0;
      let saldo = 0;
      let validar = true;
      values?.comprobantesSaldo?.forEach((detalle) => {
        if (validar) {
          abonadoGrid += (parseFloat(detalle.abono.toString()) || 0);
          totalFaacturas += detalle.importe;
          saldo += detalle.saldo;
        }
        return detalle;
      });
      setFiledValue("totalFacturas", totalFaacturas);
      setFiledValue("totalSaldo", saldo);
      setFiledValue("abonado", Redondear(abonadoGrid, 2))
      let disponible = (values.abonar - abonadoGrid);
      setFiledValue("disponible", Redondear(disponible, 2))
    }
  }
  const panes = (values: any) => [
    {
      menuItem: 'Totales Mensuales', render: () => <TabPane>
        <FieldArray
          name="comprobantesSaldo"
          render={() => (
            <Segment basic className="tabla-container">
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Mes</Table.HeaderCell>
                    <Table.HeaderCell>Saldo Anterior</Table.HeaderCell>
                    <Table.HeaderCell>Cargos</Table.HeaderCell>
                    <Table.HeaderCell>Abonos</Table.HeaderCell>
                    <Table.HeaderCell>Saldo Actual</Table.HeaderCell>
                    
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values.comprobantesSaldo?.map(
                    (item: ComprobantesSaldo, index: any) => (
                      <Table.Row key={index.valueOf()}>
                        <MyDataGridRows width="100px" value={item?.empresa?.clave} alineacion="center" />
                        <MyDataGridRows width="40px" value={item?.sucursal?.nombre} alineacion="center" />
                        <MyDataGridRows width="100px" value={item.serie + item.folio} alineacion="center" />
                        <MyDataGridRows width="90px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
                        <MyDataGridRows width="50px" value={"item.plazo"} alineacion="center" />
                        <Table.Cell>
                          <MyTextInput
                            style={{
                              textAlign: "right",
                              border: "0px solid rgba(0, 0, 0, 0.277)",
                              width: '90px',
                              background: "transparent"
                            }}
                            tabIndex={index * 3 + 1}
                            placeholder="Abono"
                            name={`comprobantesSaldo.${index}.abono`}
                            tipoDato={enumTipoDato.Numerico}
                            decimalDigits="1,2"
                            dataIndex="abono"
                            onKeyDown={inputAbonoKeyDown}
                            enterInput={enterInput}
                            index={index}
                            onBlur={inputAbonoOnBlur}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <MyTextInput
                            style={{
                              textAlign: "center",
                              border: "0px solid rgba(0, 0, 0, 0.277)",
                              width: '90px',
                              background: "transparent"
                            }}
                            tabIndex={index * 3 + 1}
                            placeholder="Observaciones"
                            name={`comprobantesSaldo.${index}.observaciones`}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          <Button type="button" style={{
                            width: '50px',
                            background: "transparent"
                          }}><Icon color='green' name='file pdf' />
                          </Button>
                        </Table.Cell>
                        <MyDataGridRows width="50" value={item.esPPD ? "PPD" : "PUE"} alineacion="center" />
                        <MyDataGridRows width="50" value={item.tipoCambio} alineacion="center" />
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Segment>
          )}
        />
      </TabPane>
    },
    {
      menuItem: 'Detalles', render: () => <TabPane tabIndex={getIndexTable()}>
        <Segment basic className="tabla-container">
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Mes</Table.HeaderCell>
                <Table.HeaderCell>Saldo Anterior</Table.HeaderCell>
                <Table.HeaderCell>Cargos</Table.HeaderCell>
                <Table.HeaderCell>Abonos</Table.HeaderCell>
                <Table.HeaderCell>Saldo Actual</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pagosAplicadosById?.map(
                (item: PagoAplicado, index: any) => (
                  <Table.Row key={index.valueOf()}>
                    <MyDataGridRows width="80px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
                    <MyDataGridRows width="60px" value={item?.folio} alineacion="center" />
                    <MyDataGridRows width="100px" value={item.conceptoDeCliente?.nombre} alineacion="center" />
                    <MyDataGridRows width="90px" value={formatDecimal(item.abonar, 2)} alineacion="right" />
                    <MyDataGridRows width="90px" value={formatDecimal(item.abonado, 2)} alineacion="right" />
                    
                    <MyDataGridRows width="150px" value={"Teso"} alineacion="center" />
                    <Table.Cell textAlign='center' >
                      <Button type="button" style={{
                        width: '50px',
                        background: "transparent"
                      }}><Icon color='green' name='check' />
                      </Button>
                    </Table.Cell>
                    <Table.Cell textAlign='center' border="3px">
                      <Button type="button" style={{
                        width: '50px',
                        background: "transparent"
                      }}><Icon color='green' name='file pdf' />
                      </Button>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Button type="button" style={{
                        width: '50px',
                        background: "transparent"
                      }}><Icon color='green' name='file pdf' />
                      </Button>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Button type="button" style={{
                        width: '50px',
                        background: "transparent"
                      }}><Icon color='green' name='envelope' />
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        name={item.id}
                        type="Button"
                        disabled={
                          loading &&
                          target ===
                          String(item.id)
                        }
                        loading={
                          loading &&
                          target ===
                          String(item.id)
                        }
                        onClick={(e) => {
                          openModal(
                            <DeleteModals
                              deleteConfirmed={deleteConfirmed}
                              deletedeclined={deletedeclined}
                              e={e.currentTarget.name.toString()}
                              id={item.id}
                              nombre={values.pagoAplicado ? values.pagoAplicado[index]?.folio : "Fila"}
                            />,
                            enumHeaderModals.CapturaPago
                          );
                        }}
                        floated="right"
                        content="Eliminar"
                        color="red"
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>

          </Table>
        </Segment></TabPane>
    },
    
  ]

  const TabCapturaPago = (values: any) => <Tab panes={panes(values)} />

  const getIndexTable = () => {
    return indexCaptura = indexCaptura + 1;
  };

  if (loadingInitial) return <LoadingComponent />

  const SearchFilters = () => {
    return (
      <Segment clearing>
        <div>
          <Label as="a" color="red" ribbon>
            Filtros
          </Label>
        </div>
        <div className="col20">
          <MySelectInput
            autoFocus={true}
            tabIndex={getIndexTable()}
            placeholder="Empresa"
            label="Empresa"
            name="empresaId"
            options={EmpresasByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputEmpresaOnChange}
          />
        </div>
        <div className="col20">
          <MySelectInput
            tabIndex={getIndexTable()}
            placeholder="Sucursal"
            label="Sucursal"
            name="sucursalId"
            options={sucursalesByEmpresaByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputSucursalOnChange}
          />
        </div>
        <div className="col20">
          <MySelectInput
            tabIndex={getIndexTable()}
            placeholder="Cuenta"
            label="Cuenta"
            name="cuentaDeProveedorId"
            options={cuentaClienteByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputCuentaOnChange}
          />
        </div>
        <div className="col30">
          <MySelectInput
            tabIndex={getIndexTable()}
            placeholder="Proveedor"
            label="Proveedor"
            name="proveedorId"
            options={clienteByEmpresaSucursalByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputClienteOnChange}
            selectWithLabel={true}
            onKeyUp={selectInputClienteOnKeyUp}
            onBlur={selectInputClienteOnBluer}

          />
        </div>
      </Segment>
    );
  };
  
  return (
    <Segment clearing>
      <Formik
        initialValues={capturaPagosFormValues}
        validationSchema={validationSchema}
        onSubmit={
          handleSubmit
        }
      >
        {({ values }) => (
          <Form className='ui form' >
           
            <Header as='h2' icon='id badge outline' content='Estado de Cuenta de Proveedor' color='blue' />
            {SearchFilters()}
            
            {TabCapturaPago(values)}
            <Segment clearing>
              <div style={{ display: 'flex', fontWeight: 'bold' }}>
                <div style={{ width: '20%' }}>
                  Total Meses: 
                </div>
                <div style={{ width: '40%' }}>
                  Saldo Anterior: {formatDecimal(values.totalSaldo, 2)}
                </div>
                <div style={{ width: '22%' }}>
                  Cargo: {formatDecimal(values.totalSaldo, 2)}
                </div>
                <div style={{ width: '33%' }}>
                  Abono: {formatDecimal(values.totalSaldo, 2)}
                </div>
                <div style={{ width: '37%' }}>
                  Saldo Actual: {formatDecimal(values.abonado, 2)}
                </div>
              </div>
            </Segment>
            
          </Form>
        )}
      </Formik>
    </Segment>
  )
})