import { observer } from 'mobx-react-lite'
import { useEffect } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react'
import CuentaVentaList from './CuentaVentaList'
import { useStore } from '../../../../app/stores/store';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CuentaVentaForm from '../form/CuentaVentaForm';
import ReporteCuentaVentaForm from '../form/ReporteCuentaVentaForm';
export default observer(function CuentaVentaDashboard() {
  const { tabsStore } = useStore();
  const { cuentaDeVentaStore } = useStore();
  const { loadCuentasDeVentas } = cuentaDeVentaStore;

  useEffect(() => {
    cuentaDeVentaStore.loadCuentasDeVentas();
  }, [cuentaDeVentaStore]);

  useEffect(() => {
    loadCuentasDeVentas();
  }, [loadCuentasDeVentas]);

  if (cuentaDeVentaStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />

        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h1' icon='warehouse' content='Cuentas de Ventas' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Cuentas de Ventas', <CuentaVentaForm id={0} />)}
              content="Crear Cuenta Venta" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Cuentas de Ventas', <ReporteCuentaVentaForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => cuentaDeVentaStore.loadCuentasDeVentas()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <CuentaVentaList />
      </Grid.Column>
    </Grid>
  )
})