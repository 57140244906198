import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { MonedaSAT } from "../models/configuracion/MonedaSAT";
import { entity2ByDropdown } from "../common/funciones/entityByDropdown";

export default class MonedaSATStore {
  monedaSATRegistry = new Map<number, MonedaSAT>();
  selectedMonedaSAT: MonedaSAT | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadMonedasSAT = async () => {
    this.setLoadingInitial(true);
    try {
      const monedasSAT = await agent.MonedasSAT.list();
      runInAction(() => {
        monedasSAT.forEach((monedaSAT) => {
          this.setMonedaSAT(monedaSAT);
        });
        this.setLoadingInitial(false);
      });
    } catch (error) {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };
  get monedasSATByDropdown() {
    return entity2ByDropdown<MonedaSAT>(
      Array.from(this.monedaSATRegistry.values())
    );
  }

  getMonedasSAT = async () => {
    this.setLoadingInitial(true);
    const monedasSAT = await agent.MonedasSAT.list();
    try {
      monedasSAT.forEach((monedaSAT) => {
        this.setMonedaSAT(monedaSAT);
      });
      this.setLoadingInitial(false);
      return monedasSAT;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return monedasSAT;
    }
  };

  loadMonedaSAT = async (id: number) => {
    let monedaSAT = this.getMonedaSAT(id);
    if (monedaSAT) {
      this.selectedMonedaSAT = monedaSAT;
      return monedaSAT;
    } else {
      this.loadingInitial = true;
      try {
        monedaSAT = await agent.MonedasSAT.details(id);
        this.setMonedaSAT(monedaSAT);
        this.selectedMonedaSAT = monedaSAT;
        this.setLoadingInitial(false);
        return monedaSAT;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setMonedaSAT = (monedaSAT: MonedaSAT) => {
    this.monedaSATRegistry.set(monedaSAT.id, monedaSAT);
  };
  private setMonedasSAT = (monedaSAT: MonedaSAT[]) => {
    // this.monedaTipoRegistry.set(monedaTipo.id, monedaTipo);
  };

  private getMonedaSAT = (id: number) => {
    return this.monedaSATRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createMonedaSAT = async (monedaSAT: MonedaSAT) => {
    this.loading = true;
    try {
      await agent.MonedasSAT.create(monedaSAT);
      runInAction(() => {
        this.monedaSATRegistry.set(monedaSAT.id, monedaSAT);
        this.selectedMonedaSAT = monedaSAT;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  

  deleteMonedaSAT = async (id: number) => {
    this.loading = true;
    try {
      await agent.MonedasSAT.delete(id);
      runInAction(() => {
        this.monedaSATRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
