import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ConceptoDeProveedorTipo } from "../../models/gestionProveedor/ConceptoDeProveedorTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ConceptoDeProveedorTipoStore {
    conceptoDeProveedorTipoRegistry = new Map<number, ConceptoDeProveedorTipo>();
    selectedConceptoDeProveedorTipo: ConceptoDeProveedorTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadConceptosDeProveedoresTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const conceptosDeProveedoresTipos = await agent.ConceptosDeProveedoresTipos.list();
            conceptosDeProveedoresTipos.forEach(conceptoDeProveedorTipo => {
                this.setConceptoDeProveedorTipo(conceptoDeProveedorTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getConceptosDeProveedoresTipos = async () => {
        this.setLoadingInitial(true);
        const conceptosDeProveedoresTipos = await agent.ConceptosDeProveedoresTipos.list();
        try {
            conceptosDeProveedoresTipos.forEach(conceptoDeProveedorTipo => {
                this.setConceptoDeProveedorTipo(conceptoDeProveedorTipo);
            })
            this.setLoadingInitial(false);
            return conceptosDeProveedoresTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosDeProveedoresTipos;
        }
    }

    loadConceptoDeProveedorTipo = async (id: number) => {
        let conceptoDeProveedorTipo = this.getConceptoDeProveedorTipo(id);
        if (conceptoDeProveedorTipo) {
            this.selectedConceptoDeProveedorTipo = conceptoDeProveedorTipo;
            return conceptoDeProveedorTipo;
        } else {
            this.loadingInitial = true;
            try {
                conceptoDeProveedorTipo = await agent.ConceptosDeProveedoresTipos.details(id);
                this.setConceptoDeProveedorTipo(conceptoDeProveedorTipo);
                this.selectedConceptoDeProveedorTipo = conceptoDeProveedorTipo;
                this.setLoadingInitial(false);
                return conceptoDeProveedorTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoDeProveedorTipo = (conceptoDeProveedorTipo: ConceptoDeProveedorTipo) => {
        this.conceptoDeProveedorTipoRegistry.set(conceptoDeProveedorTipo.id, conceptoDeProveedorTipo);
    }
    private getConceptoDeProveedorTipo = (id: number) => {
        return this.conceptoDeProveedorTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoDeProveedorTipo = async (conceptoDeProveedorTipo: ConceptoDeProveedorTipo) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedoresTipos.create(conceptoDeProveedorTipo);
            runInAction(() => {
                this.conceptoDeProveedorTipoRegistry.set(conceptoDeProveedorTipo.id, conceptoDeProveedorTipo);
                this.selectedConceptoDeProveedorTipo = conceptoDeProveedorTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateConceptoDeProveedorTipo = async (conceptoDeProveedorTipo: ConceptoDeProveedorTipo) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedoresTipos.update(conceptoDeProveedorTipo)
            runInAction(() => {
                this.conceptoDeProveedorTipoRegistry.set(conceptoDeProveedorTipo.id, conceptoDeProveedorTipo);
                this.selectedConceptoDeProveedorTipo = conceptoDeProveedorTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteConceptoDeProveedorTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosDeProveedoresTipos.delete(id);
            runInAction(() => {
                this.conceptoDeProveedorTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get conceptosDeProveedoresTipoByDropdown() {
        return entityByDropdown<ConceptoDeProveedorTipo>(
          Array.from(this.conceptoDeProveedorTipoRegistry.values())
        );
    }
}