import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import { urlApi, urlReports } from "../../../../app/api/agent";
import { ReporteAlmacen,ReporteAlmacenFormValues } from "../../../../app/models/inventario/ReporteAlmacen";

export default observer(function ExistenciaForm() {

  const firstElement = 0;
  const lastElement = 19;

  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { proveedorStore, reporteAlmacenesStore } = useStore();

  const { empresaSucursal } = proveedorStore

  const { imprimirExistencia } = reporteAlmacenesStore

  const { id } = useParams<{ id: string }>();
  const [reporteAlmacen, setReporteAlmacen] = useState(new ReporteAlmacenFormValues());

  const TipoExistenciaByDropdown=[
    {
      key: 1,
      text: "Solo Con Existencia",
      value: 1,
      contex: "Solo Con Existencia",
    },
    {
      key: 2,
      text: "Solo Negativos",
      value: 2,
      contex: "Solo Negativos",
    },
    {
      key: 3,
      text: "En ceros",
      value: 3,
      contex: "En ceros",
    }
    ,
    {
      key: 4,
      text: "Todos",
      value: 4,
      contex: "Todos",
    },
  ]

  const TipoReporteByDropdown = [

    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];


  const OrdenReporteByDropdown = [

    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().required("El folio es requerido."),
    fechaFin: Yup.date().required("El folio es requerido."),
    tipoReporte: Yup.number()
      .min(1, "Favor de seleccionar un tipo Reporte.")
      .required("El tipo Reporte es requerido."),
    ordenado: Yup.number()
      .min(1, "Favor de seleccionar un Ordenado.")
      .required("El Ordenado es requerido."),
  });
  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setReporteAlmacen((state) => ({
      ...state,
      nombre: "Existencia", ordenado: "1",
      tipoReporte: "1",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(reporteKardex: ReporteAlmacen) {
    imprimirExistencia(reporteKardex).then(result =>{
      debugger
      if (result !== undefined) {
        let features =
          'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        window.open(urlApi + urlReports + result, '_blank', features);
      }
    })
 
    //generacionComprobante.id > 0 ? updateGeneracionComprobante(generacionComprobante) : createGeneracionComprobante(generacionComprobante);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };

  function closeModals() {
    closeModal();
  }


  //if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteAlmacen}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Existencias' color='blue' />


            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  tabIndex={0}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                  //change={chageEnter}
                />
              </div>
              <div></div>
              <div className='col35'>
                Existencia al:
                <MyDateInput
                  tabIndex={1}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}
                />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Tipo Existencia"
                  label="Tipo Existencia"
                  name="tipoExistencia"
                  options={TipoExistenciaByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>

              <div className='col35'>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={5}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={6}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Almacen'
                  label="Almacen"
                  name='almacen'
                  maxLength={50}
                  tabIndex={7}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Producto'
                  label="Producto"
                  name='producto'
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Cantidad'
                  label="cantidad"
                  name='cantidad'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Costo'
                  label="Costo"
                  name='costo'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput} />
              </div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Proveedor'
                  label="Proveedor"
                  name='proveedor'
                  maxLength={50}
                  tabIndex={11}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Concepto'
                  label="Concepto"
                  name='concepto'
                  maxLength={50}
                  tabIndex={11}
                  enterInput={enterInput} />
              </div>
            </Segment>

            <Segment>
              <Button tabIndex={12} type="submit" color="blue">
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>




  )
})