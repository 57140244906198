import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { ConceptoDeProveedor } from "../gestionProveedor/ConceptoDeProveedor";
import { CuentaDeProveedor } from "../gestionProveedor/CuentaDeProveedor";
import { Almacen } from "../inventario/Almacen";
import { ConceptoDeInventario } from "../inventario/ConceptoDeInventario";
import { TipoDeCompraTipo } from "./TipoDeCompraTipo";


//TODO: id and clave pased of number to string on this case

export interface TipoDeCompra {
    id: number;
    clave: number;
    nombre: string;
    tipoDeCompraTipoId: number;
    tipoDeCompraTipo?:TipoDeCompraTipo;

    almacenId: number;
    almacen?: Almacen;
    conceptoDeInventarioId: number;
    conceptoDeInventario?: ConceptoDeInventario;

    cuentaDeProveedorId: number;
    cuentaDeProveedor?: CuentaDeProveedor;
    conceptoDeProveedorId: number;
    conceptoDeProveedor?: ConceptoDeProveedor;

    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}

export interface ITipoDeCompraFormValues extends Partial<TipoDeCompra> {
    tipoDeCompraTipoId: any;
}

export class TipoDeCompraFormValues implements ITipoDeCompraFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    tipoDeCompraTipoId: number = 0;
    tipoDeCompraTipo?: TipoDeCompraTipo;

    almacenId: number = 0;
    almacen?: Almacen;
    conceptoDeInventarioId: number = 0;
    conceptoDeInventario?: ConceptoDeInventario;

    cuentaDeProveedorId: number = 0;
    cuentaDeProveedor?: CuentaDeProveedor;
    conceptoDeProveedorId: number = 0;
    conceptoDeProveedor?: ConceptoDeProveedor;

    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();

    constructor(init?: TipoDeCompra) {
        Object.assign(this, init);
    }
}