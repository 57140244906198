import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { CuentaBancaria } from "../../models/Finanza/CuentaBancaria";

export default class CuentaBancariaStore { 
    cuentaBancariaRegistry = new Map<number, CuentaBancaria>();
    selectedCuentaBancaria: CuentaBancaria | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentaBancariaByDropdown() {
        return entityByDropdown<CuentaBancaria>(Array.from(this.cuentaBancariaRegistry.values()));
    }
    get cuentaBancariaByDate() {
        return Array.from(this.cuentaBancariaRegistry.values()).sort((a) => a.id);
    }

    get cuentaBancariaById() {
        return Array.from(this.cuentaBancariaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get cuentasBancariasByDropdown() {
        let cuentaBancariaArreg = [new TipoDropDownValues()];
        let cuentaBancariaOpt = new TipoDropDownValues();
        const cvts = Array.from(this.cuentaBancariaRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            cuentaBancariaOpt.key = element.id;
            cuentaBancariaOpt.text = element.nombre;
            cuentaBancariaOpt.value = element.id;
            cuentaBancariaOpt.content = element.nombre;
            cuentaBancariaArreg.push(cuentaBancariaOpt);
            cuentaBancariaOpt = new TipoDropDownValues();

        });
        return cuentaBancariaArreg;
    }

    getCuentasBancarias = async () => {
        
        this.setLoadingInitial(true);
        const cuentasBancarias = await agent.CuentasBancarias.list();
        try {
            cuentasBancarias.forEach(cuentaBancaria => {
                this.setCuentaBancaria(cuentaBancaria);
            })
            this.setLoadingInitial(false);
            return cuentasBancarias;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasBancarias;
        }
    }

    loadCuentasBancarias = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasBancarias = await agent.CuentasBancarias.list();
            cuentasBancarias.forEach(cuentaBancaria => {
                setDate<CuentaBancaria>(cuentaBancaria);
                this.setCuentaBancaria(cuentaBancaria);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCuentaBancaria = async (id: number) => {
        let cuentaBancaria = this.getCuentaBancaria(id);
        if (cuentaBancaria) {
            setDate<CuentaBancaria>(cuentaBancaria);
            this.selectedCuentaBancaria = cuentaBancaria;
            return cuentaBancaria;
        } else {
            this.loadingInitial = true;
            try {
                cuentaBancaria = await agent.CuentasBancarias.details(id);
                this.setCuentaBancaria(cuentaBancaria);
                this.selectedCuentaBancaria = cuentaBancaria;
                this.setLoadingInitial(false);
                return cuentaBancaria;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaBancaria = (cuentaBancaria: CuentaBancaria) => {
        this.cuentaBancariaRegistry.set(cuentaBancaria.id, cuentaBancaria);
    }

    private getCuentaBancaria = (id: number) => {
        return this.cuentaBancariaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaBancaria = async (cuentaBancaria: CuentaBancaria) => {
        this.loading = true;
        try {
            await agent.CuentasBancarias.create(cuentaBancaria);
            runInAction(() => {
                this.selectedCuentaBancaria = cuentaBancaria;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Cuenta de Bancaria creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaBancaria = async (cuentaBancaria: CuentaBancaria) => {
        this.loading = true;
        try {
            await agent.CuentasBancarias.update(cuentaBancaria);
            runInAction(() => {
                this.selectedCuentaBancaria = cuentaBancaria;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Cuenta de Bancaria editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaBancaria = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasBancarias.delete(id);
            runInAction(() => {
                this.cuentaBancariaRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Cuenta de Bancaria eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar la cuenta de Bancaria");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
