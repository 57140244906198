import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import {
  ConceptoCliente,
  ConceptoClienteFormValues,
} from "../../../../app/models/gestionCliente/ConceptoCliente";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function ConceptoClienteForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 6;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { conceptoClienteStore, conceptoClienteTipoStore } = useStore();
  const {
    createConceptoCliente,
    updateConceptoCliente,
    loadConceptoCliente,
    loadingInitial,
    conceptosClientesById,
    loading,
  } = conceptoClienteStore;
  //const { id } = useParams<{ id: string }>();
  const { conceptosClientesTipoByDropdown, loadConceptosClientesTipos } =
    conceptoClienteTipoStore;
  const [conceptoCliente, setConceptoCliente] = useState(
    new ConceptoClienteFormValues()
  );
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de moneda es requerida."),
    nombre: Yup.string().required("El nombre de Concepto es requerida."),
    conceptoDeClienteTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo concepto.")
      .required("El tipo de concepto es requerido."),
  });
  useEffect(() => {
    loadConceptosClientesTipos();
  }, [loadConceptosClientesTipos]);

  useEffect(() => {
    if (id) {
      loadConceptoCliente(id).then((conceptoCliente) => {
        setConceptoCliente(new ConceptoClienteFormValues(conceptoCliente));
        setFechaObservacion( new FechaObservacionValues(conceptoCliente?.fechaObservacion)        );
      });
    } else {
      conceptoCliente.clave =
        conceptosClientesById.map((conceptoCliente) => conceptoCliente.clave)
          .length > 0
          ? Math.max(
              ...conceptosClientesById.map(
                (conceptoCliente) => conceptoCliente.clave
              )
            ) + 1
          : 1;
    }
  }, []);

  function handleSubmit(conceptoCliente: ConceptoCliente) {
    conceptoCliente.tipo = undefined;
    conceptoCliente.fechaObservacion = fechaObservacion;
    if (
      conceptoCliente.conceptoDeClienteTipoId.toString() !== "0" &&
      conceptoCliente.nombre !== null
    ) {
      conceptoCliente.id > 0
        ? updateConceptoCliente(conceptoCliente)
        : createConceptoCliente(conceptoCliente);
    } else {
      toast.info("Favor de llenar campos");
    }
  }
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial)
    return <LoadingComponent content="Loading Concepto Cliente" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={conceptoCliente}
      >
        {({ handleSubmit, values,setFieldValue }) => (
          <Form className="ui form">
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Concepto de Cliente
              </Label>
              <div className="cols">
                <div className="col15Block">
                  <MyTextInput
                    placeholder="Clave"
                    label="Clave"
                    name="clave"
                    disabled={true}
                    tabIndex={0}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    autoFocus={true}
                    placeholder="Nombre"
                    label="Nombre"
                    name="nombre"
                    tabIndex={1}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col30">
                  <MySelectInput
                    tabIndex={2}
                    placeholder={"Seleccionar"}
                    label="Tipo Concepto"
                    name="conceptoDeClienteTipoId"
                    options={conceptosClientesTipoByDropdown}
                    enterInput={enterInput}
                    inputWidth="200px"
                    menuWidth="300px"
                  />
                </div>
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  id="fechaObservacion"
                  type="button"
                  tabIndex={3}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) =>{ setFieldValue("fechaObservacion", data)
                          conceptoCliente.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                        }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.ConceptoCliente
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/conceptosClientes"
                loading={loading}
                tabIndex={4}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
