import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Table, Segment, Grid, Header, Dropdown, GridRow, GridColumn, Icon, Label, DropdownMenu, DropdownItem, Menu, DropdownDivider } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
//import { ImpuestoFormValues } from "../../../../app/models/configuracion/Impuesto";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import CancelarComprobantes from '../form/CancelarComprobantes';
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import { GeneracionComprobante, GeneracionComprobanteFormValues } from "../../../../app/models/venta/GeneracionComprobante";
import { GeneracionComprobanteDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobanteDetalle';

import { enumHeaderModals } from "../../../modals/MensajeModals";
import CopiarComprobante from "./CopiarComprobante";
import ComentarioComprobante from "./ComentarioComprobante";
import ComplementoPagoForm from "./ComplementoPagoForm";
import FacturasComplementoPagoForm from "./FacturasComplementoPagoForm";
import DeleteModals from "../../../modals/DeleteModals";
import { ImpuestoDetalleFormValues } from "../../../../app/models/venta/ImpuestoDetalle";
import { urlApi, urlReports } from "../../../../app/api/agent";
import { GeneracionComprobantePagoFormValues } from "../../../../app/models/venta/GeneracionComprobantePagos";
import PagoComprobantes from "./PagoComprobantes";
import NotaCreditoComprobante from "./NotaCreditoComprobante";
import { CancelarGeneracionComprobanteFormValues } from '../../../../app/models/venta/CancelarGeneracionComprobante';
import { GeneracionComprobanteNotaCreditoFormValues } from "../../../../app/models/venta/GeneracionComprobanteNotaCredito";
import { GeneracionComprobanteComplementoPagoFormValues } from "../../../../app/models/venta/GeneracionComprobanteComplementoPago";
import RelacionarComprobante from "./RelacionarComprobante";
import EnviarCorreoModal from "./EnviarCorreoModal ";
import DescripcionAdicional from "./DescripcionAdicional";
import Ayuda from "../../../General/Ayuda";
import { OnKeyDownProps } from "../../../../app/models/myProps";
import { toast } from "react-toastify";
import { PaginationFormValues } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import { TipoDropdown, TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import { entityByDropdown } from "../../../../app/common/funciones/entityByDropdown";
import { Usuario } from "../../../../app/models/configuracion/Usuario";
import { Cliente } from "../../../../app/models/gestionCliente/Cliente";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import { Empresa } from "../../../../app/models/configuracion/Empresa";
import { ImpuestoProductoFormValues } from "../../../../app/models/inventario/ImpuestoProducto";

export default observer(function GeneracionComprobanteDashboard() {
  const firstElement = 0;
  const [target, setTarget] = useState("");
  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { usuarioStore, empresaStore, sucursalStore, tipoVentaStore, serieFolioStore, clienteStore,
    generacionComprobanteStore, cuentaDeVentaStore, monedaStore,
    productoStore, metodoPagoStore, formaPagoStore, usoCFDIStore } = useStore();
  const { loadProductoByEmpresaSucursal, productosByEmpresaSucursalDropdownByClave, productoByEmpresaSucursalRegistry,loadProducto  } = productoStore
  const { loadEntityEmpresas, EmpresasByDropdownByClave,cargarEmpresaPorId } = empresaStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const { TiposVentasByDropdownByClave } = tipoVentaStore
  const { loadSerieByIdTipoVenta, SeriesFoliosByEmpresaByDropdownByClave } = serieFolioStore
  const { loadClienteByParamsDropdown, clienteByEmpresaSucursalRegistry, clienteRegistry, cargarClientePorId } = clienteStore
  const { usosCFDIsByDropdown, obtenerusosCFDIsPorRegimenFiscalCliente } = usoCFDIStore;
  const { metodosPagosByDropdown, getMetodoPagoPorClave } = metodoPagoStore
  const { cuentasDeVentasByDropdown, getCuentaDeVenta } = cuentaDeVentaStore
  const { MonedasByDropdown } = monedaStore
  const { loadUsuariosParams } = usuarioStore;
  const { obtenerSiguienteFolio,
    loadingInitial,
    createGeneracionComprobante,
    updateGeneracionComprobante,
    loading,
    deleteGeneracionComprobante,
    cargarComprobante,
    imprimirComprobante,
    comprobante
  } = generacionComprobanteStore
  const { id } = useParams<{ id: string }>();
  const [deshabilitadoMetodoPago, setDeshabilitadoMetodoPago] = useState(false);
  const [esNotaCredito, setesNotaCredito] = useState(false);
  const [existencia, setExistencia] = useState(0);
  const [costo, setCosto] = useState(0);
  const [esREP, setesREP] = useState(false);
  const [monedaExtranjera, setMonedaExtranjera] = useState(false);
  const [generacionComprobante, setGeneracionComprobante] = useState(new GeneracionComprobanteFormValues());
  const [cancelacion, setCancelacion] = useState(new CancelarGeneracionComprobanteFormValues());
  const [complementoPago, setComplementoPago] = useState(new GeneracionComprobanteComplementoPagoFormValues());
  const [pagos, setPagos] = useState(new GeneracionComprobantePagoFormValues());
  const [notaCreditos, setNotaCreditos] = useState(new GeneracionComprobanteNotaCreditoFormValues());
  const [clientes, setClientes] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [cliente, setCliente] = useState<Cliente>();
  const [empresa, setEmpresa] = useState<Empresa>();
  const [vendedor, setVendedor] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [trabajador, setTrabajador] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [comentario, setComentario] = useState("");
  const [impuesto, setImpuesto] = useState(new ImpuestoProductoFormValues());
  const [impuestoD ] = useState(new ImpuestoDetalleFormValues());

  const inputProductoRef = useRef<any>(null);
  const empresaRef = useRef<any>()
  const sucursalRef = useRef<any>()
  const tipoVentaRef = useRef<any>()
  const serieRef = useRef<any>()
  const folioRef = useRef<any>()
  const clienteRef = useRef<any>()
  const [esExtranjero, setEsExtranjero] = useState(false);
  // const [imprimiendo, setImprimiendo] = useState(false);
  ///let guardando = false;

  //const {detalle} = generacionComprobante.detalles[0].impuestosDetalles; 
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),

    tipoVentaId: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),

    serieFolioDeVentaId: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de venta es requerido."),
    vendedorId: Yup.number()
      .min(1, "Favor de seleccionar un Vendedor .")
      .required("La Vendedor es requerido."),
    trabajadorId: Yup.number()
      .min(1, "Favor de seleccionar un Trabajador .")
      .required("El Trabajador es requerido."),
    folio: Yup.number().required("El folio es requerido."),
    clienteId: Yup.number()
      .min(1, "Favor de seleccionar el Cliente.")
      .required("El Cliente es requerido."),
    detalles: Yup.array().of(
      Yup.object().shape({
        productoId: Yup.number()
          .typeError("El valor debe ser un numero.")
          .min(1, "Req.")
          .required("Producto es requerido."),
        cantidad: Yup.number()
          .typeError("El valor debe ser un numero.")
          .moreThan(0, "Favor de ingresar cantidad mayor a 0.")
          .required("Cantidad es requerida."),
        precio: Yup.number()
          .typeError("El valor debe ser un numero.")
          .moreThan(0, "Favor de ingresar precio mayor a 0.")
          .required("Precio es requerida.")
      })
    ),
  });

  useEffect(() => {
    setImpuesto(new ImpuestoProductoFormValues());
    metodoPagoStore.getMetodosPagos();
    formaPagoStore.getFormasPagos();
    tipoVentaStore.getTiposVentas();
    cuentaDeVentaStore.getCuentasDeVentas();
    monedaStore.getMonedas();
    usuarioStore.getUsuarios();
    usoCFDIStore.getUsosCFDIs();
  }, []);

  //



  // Función para cargar sucursales de una empresa
  const cargarSucursales = async (empresaId: number, setGeneComprobante: any) => {
    const sucursales = await loadSucursalByIdEmpresa(empresaId);
    if (sucursales?.length === 1) {
      const sucursalId = sucursales[0]?.id;
      setGeneComprobante((prevValues: any) => ({
        ...prevValues,
        sucursalId
      }));
      comprobante.sucursalId = sucursalId;
      if (tipoVentaRef.current) tipoVentaRef.current.Focus();
      return sucursalId;
    }
    return null;
  };

  // Función para cargar clientes de una sucursal
  const cargarClientes = async (empresaId: number, sucursalId: number) => {
    const clientes = await loadClienteByParamsDropdown(
      new PaginationFormValues({
        empresaId,
        sucursalId
      })
    );
    if (clientes) setClientes(clientes);
  };
  const cargarUsuarios = async (empresaId: number, sucursalId: number) => {
    const result = await loadUsuariosParams(new PaginationFormValues({
      empresaId: empresaId,
      sucursalId: sucursalId,
      esTrabajador: true,
      esVendedor: true
    }));
    if (result && result.length > 0) {
      // Filtramos usuarios que sean trabajadores y vendedores
      setVendedor(entityByDropdown<Usuario>(Array.from(result.filter(usuario => usuario.esVendedor))));
      setTrabajador(entityByDropdown<Usuario>(Array.from(result.filter(usuario => usuario.esTrabajador))));
    } else {
      setVendedor([new TipoDropDownValues()]);
      setTrabajador([new TipoDropDownValues()]);
    }
  }
  // Función para cargar tipos de venta y series
  const cargarTiposDeVenta = async (empresaId: number, sucursalId: number, setGeneComprobante: any) => {
    const tiposVentas = await tipoVentaStore.getTiposVentas();
    if (tiposVentas?.length === 1) {
      const tipoVentaId = tiposVentas[0]?.id;
      setGeneComprobante((prevValues: any) => ({
        ...prevValues,
        tipoVentaId
      }));
      comprobante.tipoVentaId = tipoVentaId;
      if (serieRef.current) serieRef.current.Focus();
      return tipoVentaId;
    }
    return null;
  }
  const cargarSerie = async (empresaId: number, sucursalId: number, tipoVentaId: number, setGeneComprobante: any) => {
    const series = await loadSerieByIdTipoVenta(
      new PaginationFormValues({
        empresaId,
        sucursalId,
        tipoVentaId
      })
    );
    if (series?.length === 1) {
      const serieFolioDeVentaId = series[0]?.id;
      setGeneComprobante((prevValues: any) => ({
        ...prevValues,
        serieFolioDeVentaId
      }));
      comprobante.serieId = serieFolioDeVentaId;
      if (folioRef.current) folioRef.current.Focus();
      return serieFolioDeVentaId;
    }

    return null;
  }


  // Función para obtener el siguiente folio
  const obtenerFolio = async (empresaId: number, sucursalId: number, tipoVentaId: number, serieId: number, setGeneComprobante: any) => {
    const folio = await obtenerSiguienteFolio(
      new PaginationFormValues({
        empresaId,
        sucursalId,
        tipoVentaId,
        serieId
      })
    );
    if (folio) {
      setGeneComprobante((prevValues: any) => ({
        ...prevValues,
        folio,
        id: 0
      }));
      comprobante.folio = folio;
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const empresas = await loadEntityEmpresas();
        if (empresas?.length === 1) {
          const empresaId = empresas[0]?.id;
          comprobante.empresaId = empresaId;
          cargarEmpresaPorId(comprobante).then((result) => {
            setEmpresa(result);
          });
          setGeneracionComprobante(prevValues => ({
            ...prevValues,
            empresaId,
            detalles: [...(prevValues.detalles || []), new GeneracionComprobanteDetalleFormValues()]  // Mantener los detalles previos y agregar uno nuevo
          }));
          

          const sucursalId = await cargarSucursales(empresaId, setGeneracionComprobante);
          if (sucursalId) {
            await cargarClientes(empresaId, sucursalId);
            await cargarUsuarios(empresaId, sucursalId)
            const tipoVentaId = await cargarTiposDeVenta(empresaId, sucursalId, setGeneracionComprobante);
            if (tipoVentaId) {
              const tipoVenta = tipoVentaStore.getTipoVenta(tipoVentaId);
              setesNotaCredito(tipoVenta?.tipoVentaTipo?.clave === 30);
              setesREP(tipoVenta?.tipoVentaTipo?.clave === 40);
              const serieFolioDeVentaId = await cargarSerie(empresaId, sucursalId, tipoVentaId, setGeneracionComprobante);
              if (serieFolioDeVentaId) {
                await obtenerFolio(empresaId, sucursalId, comprobante.tipoVentaId, serieFolioDeVentaId, setGeneracionComprobante);
                if (clienteRef.current) clienteRef.current.Focus();
              } else if (serieRef.current) serieRef.current.Focus();
            } else if (tipoVentaRef.current) tipoVentaRef.current.Focus();
          } else if (sucursalRef.current) sucursalRef.current.Focus();

        }
        // setFieldValue("detalles", [new GeneracionComprobanteDetalleFormValues()])
        // Manejo de casos cuando hay más de una empresa
        if (empresas?.length !== 1 && empresaRef.current) {
          empresaRef.current.Focus();
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    return () => {
      serieFolioStore.cleanSerieFolioByTipoVenta();
      generacionComprobanteStore.resetComprobanteParams();
      sucursalStore.setsucursalByEmpresaRegistryClear();
    };
  }, []);

  function handleSubmit(generacionComprobante: GeneracionComprobante, setFieldValue?: any) {
    
    
    const tipoVenta = tipoVentaStore.getTipoVenta(generacionComprobante.tipoVentaId);
    if (generacionComprobante.fechaCancelacion != null) {
      openModal(
        <CancelarComprobantes
          cancelacion={cancelacion}
          uuid={generacionComprobante.uuid}
          setCancelacion={setCancelacion}
          closeModals={() => {
            closeModal();
          }}
          onCopy={(data) => {
            generacionComprobante.cancelacion = data;
            Guardar(generacionComprobante);
          }
          }
          tabIndex={2228}
        />,
        'Cancelacion',
        'small'
      )
    } else if (tipoVenta && (tipoVenta.tipoVentaTipo?.clave === 15 || tipoVenta.tipoVentaTipo?.clave === 10) && generacionComprobante.fechaCancelacion == null) {


      if (generacionComprobante.id !== 0) {
        if (tipoVenta.tipoVentaTipo?.clave !== 15) {
          abrirPagos("Guardar", generacionComprobante)
        } else {
          toast.info("La factura ya ha sido Timbrada no se puede actualizar.");
        }
      } else {
        
        if (tipoVenta.tipoVentaTipo?.clave === 15) {

          if(generacionComprobante.usoCfdiId!==0){
            abrirPagos("Guardar", generacionComprobante)
          } else {
            toast.info("Uso de CFDI es obligatorio.");
          }
        }else{
        abrirPagos("Guardar", generacionComprobante)}
      }


    } else if (tipoVenta && tipoVenta.tipoVentaTipo?.clave === 30) {
      abrirNotaCredito("Guardar", generacionComprobante);
    } else if (generacionComprobante.tipoVentaId === 7) {
      openModal(
        <NotaCreditoComprobante
          notaCredito={notaCreditos}
          setNotaCreditos={setNotaCreditos}
          closeModals={closeModalsNotaCredito}
          tabIndex={22283}
          empresaId ={generacionComprobante.empresaId}
        />,
        'Afectados Por Nota Crédito',
        'small'
      )
    } else {
      Guardar(generacionComprobante);
    }

  }
  function Guardar(generacionComprobante: GeneracionComprobante, pagosModal?: any, ncModal?: any,setFieldValue?:any) {
    if (pagos.pagos !== undefined) {
      generacionComprobante.complementoPago = complementoPago;

      if (pagosModal && ncModal === undefined) { generacionComprobante.pago = pagosModal; }
      else {
        if (pagos.condiciondePagoId !== 0 && pagos.formaDePagoId !== 0)
          generacionComprobante.pago = pagos;
      }
      if (ncModal !== undefined) {
        generacionComprobante.notaCredito = ncModal;
      } else {
        generacionComprobante.notaCredito = notaCreditos;
      }
      generacionComprobante.comentario = comentario;


      generacionComprobante.id > 0 ? (updateGeneracionComprobante(generacionComprobante).then(resul => {
        debugger
        if (resul !== undefined && resul !== "No se pudo actualizar") {
       debugger
          //BuscarFolio(setFieldValue,generacionComprobante)
          if (generacionComprobante.tipoVenta?.tipoVentaTipoId === 3) {
            // setImprimiendo(true);
            imprimirComprobante(generacionComprobante.id).then(result => {
              if (result !== undefined && result !== "No se pudo actualizar") {
                let features =
                  'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                window.open(urlApi +urlReports+ result, '_blank', features);
              }
            });

          } else {
            let features =
              'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
            window.open(urlApi + urlReports+ resul, '_blank', features);
          }
        }
      })) : (createGeneracionComprobante(generacionComprobante).then(resul => {
            
        if (resul !== undefined && resul !== "No se pudo actualizar") {
          debugger
          //BuscarFolio(setFieldValue,generacionComprobante)
          var res = resul.split("|");
          var idComp = 0;
          if (res.length === 2) {
            idComp = Number.parseInt(res[1]);
          }
          const tipoVenta = tipoVentaStore.getTipoVenta(generacionComprobante.tipoVentaId);
          if (tipoVenta?.tipoVentaTipoId === 3) {
            // setImprimiendo(true);
            imprimirComprobante(idComp).then(result => {
              if (result !== undefined && result !== "No se pudo actualizar") {
                let features =
                  'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                window.open(urlApi + urlReports + result, '_blank', features);
              }
            });
          } else {
              let features =
              'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
            window.open(urlApi +  resul, '_blank', features);
          }
        }
      }))


    } else { }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElementTable + 3);
  };

  function closeModals() {
    closeModal();
  }
  const closeDescripcionAdicional = () => {
    if (inputProductoRef.current)
      inputProductoRef.current.Focus();
    closeModal();
  }
  const closeModalsAyuda = () => {
    if (inputProductoRef.current)
      inputProductoRef.current.Focus();
    closeModal();
  }

  function closeModalsNotaCredito(valor?: any) {
    closeModal();
    Guardar(valor)
  }


  const onChangeSelectEmpresa = async (empresaId: number, setFieldValue?: any, index?: any, values?: GeneracionComprobante, setValues?: any) => {
    setFieldValue("clienteId", 0);
    setFieldValue("vendedorId", 0);
    setFieldValue("trabajadorId", 0);
    setFieldValue("sucursalId", 0);
    setFieldValue("tipoVentaId", 0);
    setFieldValue("serieFolioDeVentaId", 0);
    setFieldValue("folio", 0);
    setCliente(undefined);
    setClientes([new TipoDropDownValues()]);
    setVendedor([new TipoDropDownValues()]);
    setTrabajador([new TipoDropDownValues()]);
    
    comprobante.empresaId = empresaId;
    comprobante.sucursalId = 0;
    comprobante.clienteId = 0;
    cargarEmpresaPorId(comprobante).then((result) => {
      setEmpresa(result);
    });
    if (empresaId) {
      const sucursalId = await cargarSucursales(empresaId, setValues);
      if (sucursalId) {
        await cargarClientes(empresaId, sucursalId);
        await cargarUsuarios(empresaId, sucursalId)
        const tipoVentaId = await cargarTiposDeVenta(empresaId, sucursalId, setValues);
        if (tipoVentaId) {
          const tipoVenta = tipoVentaStore.getTipoVenta(tipoVentaId);
          setesNotaCredito(tipoVenta?.tipoVentaTipo?.clave === 30);
          setesREP(tipoVenta?.tipoVentaTipo?.clave === 40);
          const serieFolioDeVentaId = await cargarSerie(empresaId, sucursalId, tipoVentaId, setValues);
          if (serieFolioDeVentaId) {
            await obtenerFolio(empresaId, sucursalId, comprobante.tipoVentaId, serieFolioDeVentaId, setValues);
            if (clienteRef.current) clienteRef.current.Focus();
          }
        }
      } else if (sucursalRef.current) sucursalRef.current.Focus();
      setFieldValue("detalles", [new GeneracionComprobanteDetalleFormValues()])
    }
  }

  const onChangeSelectSucursal = async (sucursalId: number, setFieldValue: any, index: any, values: any, setValue?: any) => {
    try {
      setFieldValue("clienteId", 0);
      setFieldValue("serieFolioDeVentaId", 0);
      setFieldValue("folio", 0);
      setFieldValue("vendedorId", 0);
      setFieldValue("trabajadorId", 0);
      setFieldValue("tipoVentaId", 0);
      setCliente(undefined);
      setClientes([new TipoDropDownValues()]);
      setVendedor([new TipoDropDownValues()]);
      setTrabajador([new TipoDropDownValues()]);

      comprobante.clienteId = 0;
      comprobante.sucursalId = sucursalId;

      if (comprobante.empresaId > 0 && comprobante.sucursalId > 0) {
        // Cargar clientes, tipos de venta y series al seleccionar una sucursal
        await cargarClientes(generacionComprobante.empresaId, sucursalId);
        await cargarUsuarios(comprobante.empresaId, sucursalId)
        const tipoVentaId = await cargarTiposDeVenta(comprobante.empresaId, sucursalId, setValue);
        if (tipoVentaId) {
          const tipoVenta = tipoVentaStore.getTipoVenta(tipoVentaId);
          setesNotaCredito(tipoVenta?.tipoVentaTipo?.clave === 30);
          setesREP(tipoVenta?.tipoVentaTipo?.clave === 40);
          const serieFolioDeVentaId = await cargarSerie(comprobante.empresaId, sucursalId, tipoVentaId, setValue);
          if (serieFolioDeVentaId) {
            await obtenerFolio(comprobante.empresaId, sucursalId, comprobante.tipoVentaId, serieFolioDeVentaId, setValue);
            if (clienteRef.current) clienteRef.current.Focus();
          }
        } else if (tipoVentaRef.current) tipoVentaRef.current.Focus();

      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function idSelectMoneda(idSelectedMoneda: number) {
    const moneda = monedaStore.getMoneda(idSelectedMoneda);
    setMonedaExtranjera(moneda?.monedaTipoId === 1);
  }

  const idSelectTipo = async (tipoVentaId: number, setFieldValue: any, index: any, values: any, setValue?: any) => {
    try {
      setFieldValue("serieFolioDeVentaId", 0);
      setFieldValue("folio", 0);
      comprobante.tipoVentaId = tipoVentaId;
      if (tipoVentaId) {
        const tipoVenta = tipoVentaStore.getTipoVenta(tipoVentaId);
        setesNotaCredito(tipoVenta?.tipoVentaTipo?.clave === 30);
        setesREP(tipoVenta?.tipoVentaTipo?.clave === 40);
        const serieFolioDeVentaId = await cargarSerie(comprobante.empresaId, comprobante.sucursalId, tipoVentaId, setValue);
        if (serieFolioDeVentaId) {
          await obtenerFolio(comprobante.empresaId, comprobante.sucursalId, comprobante.tipoVentaId, serieFolioDeVentaId, setValue);
          if (clienteRef.current) clienteRef.current.Focus();
        } else if (serieRef.current) serieRef.current.Focus();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function idSelectSerie(idSelectedSerie: number, setFieldValue: any) {
    setFieldValue("folio", 0);
    comprobante.serieId = idSelectedSerie;
    if (idSelectedSerie) {
      obtenerSiguienteFolio(comprobante).then((clave) => {
        if (clienteRef.current) clienteRef.current.Focus();
        setFieldValue("folio", clave);
        generacionComprobante.id = 0;
      });
    }
  }

  const onBlueInputFolio = (setFieldValue?: any, values?: any, index?: any, folio?: any) => {
    comprobante.folio = parseInt(folio);
    BuscarFolio(setFieldValue,values)
  }
  function BuscarFolio(setFieldValue?: any,values?: any){
    if (values.empresaId && values.sucursalId && values.tipoVentaId && values.serieFolioDeVentaId && comprobante.folio) {
      cargarComprobante(comprobante).then((result) => {
        setGeneracionComprobante(new GeneracionComprobanteFormValues(result));
        setComplementoPago(result?.complementoPago ? result?.complementoPago : new GeneracionComprobanteComplementoPagoFormValues());
        if (result?.id === 0) {
          setFieldValue("detalles", [new GeneracionComprobanteDetalleFormValues()])
        }
        if (result !== null) {
          if (result?.detalles !== null) {
            setFieldValue("detalles", result?.detalles);
          } else {
            setFieldValue("detalles", [new GeneracionComprobanteDetalleFormValues()])
          }
          if (result?.pago !== null) {
            setFieldValue("pago", result?.pago);
          }
        }
      })
    }

  }

  function OnchangeSelectCliente(idSelectedCliente: number,
    setfieldAny: any,
    index?: any,
    values?: GeneracionComprobante) {
    //
    comprobante.clienteId = idSelectedCliente;
    if (comprobante.empresaId && comprobante.sucursalId && comprobante.clienteId) {
      void loadProductoByEmpresaSucursal(comprobante);
      cargarClientePorId(comprobante).then((result) => {
        setCliente(result);
        var idRegimenFiscal = result?.regimenFiscalId ? result?.regimenFiscalId : 0;
        if (idRegimenFiscal !== undefined && idRegimenFiscal !== 0) {
          obtenerusosCFDIsPorRegimenFiscalCliente(idRegimenFiscal === undefined ? 0 : idRegimenFiscal).then(resul3 => {
            /*if (resul !== undefined && resul !== "No se pudo actualizar") {
              let features =
                'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
              window.open(urlApi + resul, '_blank', features);
            }*/
          });
        }
      });;
    }
    else { setCliente(undefined); }
    //
    if (idSelectedCliente !== 0) {
      setEsExtranjero(false);
      if (clienteByEmpresaSucursalRegistry.values()) {
        clienteByEmpresaSucursalRegistry.forEach((cliente) => {
          if (idSelectedCliente === cliente.id)
            if (cliente.rfc === 'XEXX010101000') {
              setEsExtranjero(true);
            }
        })
      }
      if (clienteRegistry.values()) {
        clienteRegistry.forEach((cliente) => {
          if (idSelectedCliente === cliente.id)
            if (cliente.rfc === 'XEXX010101000') {
              setEsExtranjero(true);
            }
        })
      }
    }
  }

  const onChangeSelectCuentaDeVenta = (cuentaVentaId: number, setFieldValue: any) => {
    if (cuentaVentaId) {
      const cuentaVenta = getCuentaDeVenta(cuentaVentaId);
      if (cuentaVenta && cuentaVenta.cuentaDeVentaTipo?.clave === 10) {
        const metodoPago = getMetodoPagoPorClave("PUE");
        setFieldValue("metodoPagoId", metodoPago ? metodoPago.id : 0);
        setDeshabilitadoMetodoPago(true);
      } else if (cuentaVenta && cuentaVenta.cuentaDeVentaTipo?.clave === 11) {
        const metodoPago = getMetodoPagoPorClave("PPD");
        setFieldValue("metodoPagoId", metodoPago ? metodoPago.id : 0)
        setDeshabilitadoMetodoPago(true);
      } else {
        setFieldValue("metodoPagoId", 0)
        setDeshabilitadoMetodoPago(false);
      }
    }
    else {
      setFieldValue("metodoPagoId", 0)
      setDeshabilitadoMetodoPago(false);
    }
  }

  const onKeyDownSelectProducto = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue, index } = props;
    if (event.key === "F2") {
      event.preventDefault();
      values.empresaId && values.sucursalId &&
        abrirAyuda(values, setFieldValue, index, "Producto");
    }
  }
  function OnChangeSelectProducto(
    idSelectedProducto: number,
    setfieldAny: any,
    index?: any,
    values?: GeneracionComprobante
  ) {
    if (idSelectedProducto === 0) {
      setfieldAny(`detalles[${index}].producto.nombre`, "");
      setfieldAny(`detalles[${index}].precio`, 0);
    }
    else {
      if (productoByEmpresaSucursalRegistry.values()) {
        productoByEmpresaSucursalRegistry.forEach((producto) => {
          if (idSelectedProducto)
            if (idSelectedProducto === producto.id) {
              setfieldAny(`detalles[${index}].producto.nombre`, producto.nombre);
              setfieldAny(`detalles[${index}].descripcion`, producto.nombre);
              setfieldAny(`detalles[${index}].unidadMedida`, producto.unidadMedida1);
              setfieldAny(`detalles[${index}].descuento`, cliente?.descuentoVentas);
              setImpuesto(producto.impuesto);
              setExistencia(producto.existencia === undefined ? 0 : producto.existencia);
              setCosto(producto.costo === undefined ? 0 : producto.costo);
              if (producto.usaDescripcionAdicional) {
                abrirDescripcionAdicional(producto, setfieldAny, index)
              }
              else {
                if (producto.controlListaDePrecio)
                  if (producto.controlListaDePrecio?.length > 1) {
                    abrirAyuda(producto, setfieldAny, index, "ListaPrecio");
                  }
                  else {
                    setfieldAny(`detalles[${index}].precio`, producto.controlListaDePrecio[0]?.precio ? producto.controlListaDePrecio[0]?.precio : 0);
                  }
              }
            }
        });
      }
      return true;
    }
  }

  const calcularDataGrid = (values: GeneracionComprobante) => {
   
    let subTotalPProd = 0, Iva = 0;
      values.subtotal =0;
      values.iva=0;
      values.total=0;

    values?.detalles?.forEach((detalle) => {
      debugger
        
      loadProducto(detalle.productoId).then((res) => {
        debugger
        let cuotaT =  res?.impuesto?.ivaTrasladado?.porcentajeCuota;
        let descValor2 = detalle.cantidad * detalle.precio * detalle.descuento / 100;
        detalle.subtotal = detalle.cantidad * detalle.precio - descValor2;
        subTotalPProd += detalle.subtotal;
        debugger
        if (esExtranjero) {
          Iva = 0;
        } else {
          Iva = detalle.subtotal * ((cuotaT?cuotaT:0) / 100);
        }
        
        values.subtotal = subTotalPProd;
        values.iva += Iva;
        values.total = values.subtotal +values.iva;
        if (esExtranjero) {
          impuestoD.base = detalle.subtotal;
          impuestoD.importe = 0;
          impuestoD.tasa = 0;
          impuestoD.tipo = "G";
          detalle.impuestosDetalles = new Array(impuestoD);
        } else {
          debugger
          if ((impuesto.ivaTrasladado?.porcentajeCuota?(impuesto.ivaTrasladado?.porcentajeCuota):0)>0) {
            impuestoD.base = detalle.subtotal;
            impuestoD.importe = Iva;
            impuestoD.tasa = impuesto.ivaTrasladado?.porcentajeCuota?0:0;
            impuestoD.tipo = "G";
            detalle.impuestosDetalles = new Array(impuestoD);
          }
          if(empresa?.regimenFiscal?.clave ==="626") {
            let impRetResico = new ImpuestoDetalleFormValues();
            impuestoD.base = detalle.subtotal;
            impuestoD.tasa = 1.25;
            impuestoD.importe = detalle.subtotal *0.0125;
            impuestoD.tipo = "ISR";
            detalle.impuestosDetalles?.push(impRetResico);
  
          }
        }
    });

    
    })
  }

  function onKeyDownSelectCantidad(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobante): void {
    //calcularDataGrid(values);
  }
  function onBlourSelectCantidad(setFieldValue: any, values: GeneracionComprobante, index: any, text?: any, tabIndex?: any): void {
    calcularDataGrid(values);
  }

  /*function onBlourSelectPrecio(setFieldValue: any, values: GeneracionComprobante, index: any, text?: any, tabIndex?: any): void {
    //if (costo < clave) {
    calcularDataGrid(values);
  //}
  }*/
    function onKeyDownSelectPrecio(clave: number, setFiledValue: any, evetn: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobante) {
      if (costo < clave) {
        //calcularDataGrid(values);
      }
    }

    function onBlourSelectDescuento(setFieldValue: any, values: GeneracionComprobante, index: any, text?: any, tabIndex?: any): void {
  //m,    calcularDataGrid(values);
    }
  const onKeyDownSelectDescuento = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobante, isValid: any) => {
    
    //calcularDataGrid(values);
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    if (isValid) {
      arrayHelpers.push(new GeneracionComprobanteDetalleFormValues());
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable + numberControlsTable
      );
    }
    setTimeout(() => {
      if (inputProductoRef.current) {
        inputProductoRef.current.Focus();
      }
    }, 0);
  }
  function deletedeclined() {
    closeModal();
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deleteGeneracionComprobante(id).then((respuest) => {
      arrayHelpers.remove(index);
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable - numberControlsTable
      );
    });
    setTarget(e);
    closeModal();
  }
  const [errorButon, setErrorButon] = useState("");
  const abrirCopiarComprobante = (values: GeneracionComprobante, setFieldValue: any) => {
    openModal(
      <CopiarComprobante
        closeModals={closeModals}
        tabIndex={2228}
        empresaId={values.empresaId}
        onCopy={(data) => {
          if (data) {
            setFieldValue("clienteId", data.clienteId);
            setFieldValue("monedaId", data.monedaId);
            setFieldValue("tipoCambio", data.tipoCambio);
            setFieldValue("cuentaDeVentaId", data.cuentaDeVentaId);
            setFieldValue("vendedorId", data.vendedorId);//cargar select
            setFieldValue("trabajadorId", data.trabajadorId);//cargar select
            setFieldValue("metodoPagoId", data.metodoPagoId);
            setFieldValue("usoCfdiId", data.usoCfdiId);
            setFieldValue("comentario", data.comentario);
            setFieldValue("pago", data.pago);
            if (data.pago) {
              setPagos(data.pago)
            }
            if (data.comentario) {
              setComentario(data.comentario)
            }
            if (data.detalles?.length! > 0) {
              if (values.empresaId && values.sucursalId && data.clienteId) {
                void loadProductoByEmpresaSucursal(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, clienteId: data.clienteId }));
              }
              setFieldValue("detalles", data.detalles); // Actualiza los valores del formulario con los datos copiados
            }
            setFieldValue("iva", data.iva);
            setFieldValue("subtotal", data.subtotal);
            setFieldValue("total", data.total)
          }
        }}
      />,
      'Copiar Comprobantes',
      'small'
    )
  }

  const relacion = (values: GeneracionComprobante, setFieldValue: any) => {
    openModal(
      <RelacionarComprobante
        closeModals={closeModals}
        tabIndex={22283}
        empresaId={1}
        onCopy={(data) => {
          if (data) {
            setFieldValue("uuidsRelacionados", data);
          }
        }}
      />,
      'Relacionar UUID al comprobante',
      'large'
    )
  }
  const abrirDescripcionAdicional = (values: any, setFieldValue: any, index: any,) => {
    openModal(
      <DescripcionAdicional
        closeModals={() => {
          closeDescripcionAdicional();
          if (values.controlListaDePrecio)
            if (values.controlListaDePrecio?.length > 1) {
              abrirAyuda(values, setFieldValue, index, "ListaPrecio");
            }
            else {
              setFieldValue(`detalles[${index}].precio`, values.controlListaDePrecio[0]?.precio ? values.controlListaDePrecio[0]?.precio : 0);
            }
        }}
        tabIndex={2228}
        onDescripcion={(data) => {
          if (data) {
            setFieldValue(`detalles[${index}].descripcion`, values.nombre + " " + data);
          }
        }}
      />,
      'Descripcion Adicional',
      'mini'
    )
  }
  const abrirAyuda = (values: any, setFieldValue: any, index: any, name?: string) => {
    openModal(
      <Ayuda
        name={name}
        values={values}
        tabIndex={32231213}
        closeModals={closeModalsAyuda}
        onCopy={(data) => {
          if (data) {
            if (name === "Producto")
              setFieldValue(`detalles[${index}].productoId`, data);
            else if (name === "ListaPrecio")
              setFieldValue(`detalles[${index}].precio`, data?.precio);
          }
        }}
      />,
      name === "ListaPrecio" ? "Lista de Precios" :
        enumHeaderModals.ProductoAyuda
      , "small", true, "search"
    );
  }
  const enviarCorreo = (values: any) => {
    openModal(
      <EnviarCorreoModal
        closeModals={closeModals}
        values={values}
        tabIndex={22283}
      />,
      'Enviar correo',
      'large'
    )
  }
  const abrirNotaCredito = (boton: string = "NCRelaciones", generacionComprobante?: any) => {
    openModal(
      <NotaCreditoComprobante
        notaCredito={notaCreditos}
        setNotaCreditos={setNotaCreditos}
        closeModals={() => { closeModal(); }}
        onCopy={(data) => {
          if (data) {
            if (boton === "Guardar") {
              Guardar(generacionComprobante, data, data);
            }
          }
        }}
        tabIndex={22283}
        empresaId ={generacionComprobante.empresaId}
      />,
      'Afectados Por Nota Crédito',
      'small'
    )
  }

  const selectInputPrecioOnBluer = (setFieldValue: any, values: any, index: any, text?: any, tabIndex?: any) => {
    if (text !== undefined)
      if (costo !== undefined)
        if (+text < costo) {
          setFieldValue(0);
          setFieldValue(`detalles[${index}].precio`, 0);
          toast.info("El precio no puede ser menor al costo del producto.");
        }else{
          calcularDataGrid(values);
        }
  }

  const obtenerPagoCliente = async (generacionComprobante: any, pagos: any) => {
    if (pagos.pagos.length === 1) {
      const pago = pagos.pagos[0];
      if (pago.condiciondePagoId === 0 && pago.formaDePagoId === 0 && pago.importe === 0) {
        //const cliente = await cargarClientePorId(new PaginationFormValues({ clienteId: generacionComprobante.clienteId }));
        if (cliente) {
          return {
            ...pagos,
            pagos: pagos.pagos.map((p: any, index: any) => index === 0 ? {
              ...p,
              condiciondePagoId: cliente.condicionDePagoId || 0,
              formaDePagoId: cliente.formaDePagoId || 0,
              importe: generacionComprobante.total || 0
            } : p
            )
          };
        }
        return pagos;
      }
    }
    return Promise.resolve(pagos);
  };

  const abrirPagos = async (boton: string = "Pagos", generacionComprobante?: any) => {
    const pagosActualizados = await obtenerPagoCliente(generacionComprobante, pagos);
    openModal(
      <PagoComprobantes
        pago={pagosActualizados}
        setPagos={setPagos}
        cuentasDeVentasTipos={getCuentaDeVenta(generacionComprobante.cuentaDeVentaId)?.cuentaDeVentaTipo?.clave}
        closeModals={() => {
          closeModal();
        }
        }
        onCopy={(data) => {
          if (data) {
            if (boton === "Guardar") {
              Guardar(generacionComprobante, data);
            }
          }
        }}
        tabIndex={2228}
      />,
      'Pagos',
      'tiny'
    )
  }

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        initialValues={generacionComprobante}
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}

        enableReinitialize
      >
        {({ values, isValid, dirty, setFieldValue, touched, errors, validateForm }) => (
          <Form className='ui form' >

            <div></div>
            {generacionComprobante.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <div className='colsp' />
            <Grid columns='equal'>
              <Grid.Row>
                <Header as='h2' icon='compass' content='Generacion de Comprobantes' color='blue' />

              </Grid.Row>
              <Segment>

                <Grid columns='equal'>
                  <GridRow>
                    <GridColumn width={2}>
                      <div className='col14'>
                        <MySelectInput
                          ref={empresaRef}
                          autoFocus={true}
                          tabIndex={1}
                          placeholder={"Seleccionar"}
                          label="Empresa"
                          name="empresaId"
                          enterInput={enterInput}
                          onChange={onChangeSelectEmpresa}
                          options={EmpresasByDropdownByClave}
                          inputWidth="85px"
                          menuWidth="300px"
                        />
                      </div>
                    </GridColumn>

                    <GridColumn width={2}>
                      <div className='col14'>
                        <MySelectInput
                          ref={sucursalRef}
                          tabIndex={2}
                          placeholder={"Seleccionar"}
                          label="Sucursal"
                          name="sucursalId"
                          options={sucursalesByEmpresaByDropdownByClave}
                          enterInput={enterInput}
                          onChange={onChangeSelectSucursal}
                          inputWidth="85px"
                          menuWidth="300px"
                        />
                      </div>
                    </GridColumn>

                    <GridColumn width={2}>
                      <MySelectInput
                        ref={tipoVentaRef}
                        tabIndex={3}
                        placeholder={"Seleccionar"}
                        label="TipoVenta"
                        name="tipoVentaId"
                        options={TiposVentasByDropdownByClave}
                        enterInput={enterInput}
                        onChange={idSelectTipo}
                        inputWidth="85px"
                        menuWidth="300px"
                      />
                    </GridColumn>

                    <GridColumn width={2}>
                      <MySelectInput
                        ref={serieRef}
                        tabIndex={4}
                        placeholder="Serie"
                        label="Serie"
                        name="serieFolioDeVentaId"
                        options={SeriesFoliosByEmpresaByDropdownByClave}
                        enterInput={enterInput}
                        onChange={idSelectSerie}
                        inputWidth="85px"
                        menuWidth="300px"
                      />
                    </GridColumn>

                    <GridColumn width={2}>

                      <MyTextInput
                        ref={folioRef}
                        className="textAlingRight widthtext heighttext input"
                        tabIndex={5}
                        placeholder=''
                        label="Folio"
                        name='folio'
                        maxLength={7}
                        enterInput={enterInput}
                        onBlur={onBlueInputFolio}
                        tipoDato={enumTipoDato.Numerico}
                      />
                    </GridColumn>

                    <GridColumn width={3}>
                      <div className='col75'>
                        F. Emision
                        <MyDateInput
                          tabIndex={6}
                          placeholderText="Fecha Emision"
                          name="fecha"
                          dateFormat="dd/MM/yyyy"
                          enterInput={enterInput}
                        />
                      </div>
                    </GridColumn>

                    <GridColumn width={3}>
                      <div className='col75'>
                        F. Cancelacion
                        <MyDateInput
                          disabled={generacionComprobante.id === 0 ? true : false}
                          tabIndex={7}
                          placeholderText="Fecha Cancelacion"
                          name="fechaCancelacion"
                          dateFormat="dd/MM/yyyy"
                          enterInput={enterInput}

                        />
                      </div>
                    </GridColumn>

                  </GridRow>
                </Grid>
              </Segment>
              <Grid.Row className=" RowPadding0  ">

                <div className='colsp' />
                <div className='colsp' />
                <div className='col20'>
                  <MySelectInput
                    tabIndex={8}
                    placeholder="Cliente"
                    label="Cliente"
                    name="clienteId"
                    options={clientes}
                    ref={clienteRef}
                    enterInput={enterInput}
                    onChange={OnchangeSelectCliente}
                    inputWidth="140px"
                    menuWidth="250px"
                  />
                </div>
                <div className='col35' />

                <div className='col25'>
                  <Button
                    type="button"
                    className="ui primary button"
                    tabIndex={100}
                    onKeyDown={tabuladorButton}
                    loading={loading}
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (errors.empresaId || values.empresaId <= 0
                          || errors.sucursalId || values.sucursalId <= 0
                          || errors.tipoVentaId || values.tipoVentaId <= 0
                          || errors.serieFolioDeVentaId || values.serieFolioDeVentaId <= 0
                          || errors.folio || values.folio <= 0
                        ) {
                          setErrorButon("Favor de llenar los campos requeridos(Empresa,Sucursal,Tipo Venta y Serie)")
                          //   alert('El valor debe ser mayor que 0 y no puede estar vacío');
                        } else {
                          setErrorButon("")
                          abrirCopiarComprobante(values, setFieldValue);
                        }
                      });
                    }
                    } floated="left"
                    color="blue"
                  >
                    <Icon name="map marker alternate" />
                    Copiar Comprobantes
                  </Button>

                  {errorButon ? (
                    <Label basic color="red">
                      {errorButon}
                    </Label>
                  ) : null}

                </div>

                <GridColumn width={2}>
                  <div className='col6'>
                    <Menu attached='top'>
                      <Dropdown item icon='wrench' simple>
                        <DropdownMenu>
                          <DropdownItem onClick={() => values.id > 0 && enviarCorreo(values)}>Enviar Correo</DropdownItem>
                          <DropdownDivider />
                          <DropdownItem onClick={() => { relacion(values, setFieldValue) }}>Relacionar Comprobante</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Menu>
                  </div>
                </GridColumn>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={9}
                    placeholder="Moneda"
                    label="Moneda"
                    name="monedaId"
                    options={MonedasByDropdown}
                    enterInput={enterInput}
                    onChange={idSelectMoneda}
                    inputWidth="140px"
                    menuWidth="180px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MyTextInput
                    tabIndex={10}
                    disabled={monedaExtranjera}
                    placeholder='Tipo de Cambio'
                    label="TC"
                    name='tipoCambio'
                    maxLength={9}
                    enterInput={enterInput}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={11}
                    placeholder="Cuenta de Venta"
                    label="Cuenta de Venta"
                    name="cuentaDeVentaId"
                    options={cuentasDeVentasByDropdown}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="250px"
                    onChange={onChangeSelectCuentaDeVenta}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={12}
                    placeholder="Vendedor"
                    label="Vendedor"
                    name="vendedorId"
                    options={vendedor}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="300px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={13}
                    placeholder="Trabajador/Cajero(a)"
                    label="Trabajador/Cajero(a)"
                    name="trabajadorId"
                    options={trabajador}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="300px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={14}
                    placeholder="Metodo de pago"
                    label="Metodo Pago"
                    name="metodoPagoId"
                    options={metodosPagosByDropdown}
                    disabled={deshabilitadoMetodoPago}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="300px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={16}
                    label="Uso CFDI"
                    placeholder="Uso CFDI"
                    name="usoCfdiId"
                    options={usosCFDIsByDropdown}
                    enterInput={enterInput}
                    inputWidth="130px"
                    menuWidth="450px"
                  />
                </Grid.Column>
                {esNotaCredito ? (
                  <Grid.Column width={6}>
                    <div className='col50'>
                      <Button
                        type="button"
                        className="ui primary button"
                        tabIndex={101}
                        onKeyDown={tabuladorButton}
                        loading={loading}
                        onClick={() =>
                          abrirNotaCredito
                        }
                        floated="left"
                        color="blue"
                      >
                        <Icon name="money bill alternate" />
                        Afectados NC
                      </Button>
                    </div>

                  </Grid.Column>) : ("")
                }

              </Grid.Row>
              {esREP ? (
                <Button.Group>
                  <Button
                    tabIndex={102}
                    onClick={() =>
                      openModal(
                        <FacturasComplementoPagoForm
                          pago={pagos}
                          setPagos={setPagos}
                          closeModals={closeModals}
                          tabIndex={2228}
                        />,
                        'Factura del Complemento pago',
                        'large'
                      )
                    }
                    type="submit"
                  >
                    <Icon size="large" color="green" name="plus circle" />
                    Facturas
                  </Button>
                  <Button
                    tabIndex={16}
                    onClick={() =>
                      openModal(
                        <ComplementoPagoForm
                          complementoPago={complementoPago}
                          setComplementoPago={setComplementoPago}
                          closeModals={closeModals}
                          tabIndex={22283}
                        />,
                        'Complemento pago',
                        'large'
                      )
                    }
                    onKeyDown={tabuladorButton}
                  >
                    <Icon size="large" color="green" name="plus circle" />
                    Complemento pago
                  </Button>
                </Button.Group>
              ) : ("")
              }


              <FieldArray
                name="detalles"
                render={(arrayHelpers) => (
                  /////
                  <Segment basic className="tabla-container">
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Producto</Table.HeaderCell>
                          <Table.HeaderCell>Nombre</Table.HeaderCell>
                          <Table.HeaderCell>U. Med.</Table.HeaderCell>
                          <Table.HeaderCell>Cantidad</Table.HeaderCell>
                          <Table.HeaderCell>Precio</Table.HeaderCell>
                          <Table.HeaderCell>Desc.</Table.HeaderCell>
                          <Table.HeaderCell>importe</Table.HeaderCell>

                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {values.detalles?.map(
                          (detalles, index) => (

                            <Table.Row key={index.valueOf()} className="paddinTable">
                              <Table.Cell className="paddinTable">
                                {" "}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <MySelectInput
                                    tabIndex={index * 8 + 17}
                                    placeholder="Producto"
                                    name={`detalles[${index}].productoId`}
                                    options={productosByEmpresaSucursalDropdownByClave}
                                    enterInput={enterInput}
                                    index={index}
                                    onChange={OnChangeSelectProducto}
                                    inputWidth="140px"
                                    menuWidth="250px"
                                    ref={inputProductoRef}
                                    onKeyDownProps={onKeyDownSelectProducto}
                                  />
                                  {existencia !== 0 ? (
                                    <Label color='red' floating corner="left">
                                      {existencia}
                                    </Label>
                                  ) : ("")}

                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                {" "}

                                <MyTextInput
                                  disabled={true}
                                  tabIndex={-1}
                                  className="widthTextTable200 heighttext input "
                                  placeholder="Nombre"
                                  name={`detalles[${index}].descripcion`}
                                  enterInput={enterInput}
                                  index={index}
                                  columna={1}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}

                                <MyTextInput
                                  disabled={true}
                                  tabIndex={-1}
                                  className="widthTextTable90 heighttext input "
                                  placeholder="Nombre"
                                  name={`detalles[${index}].unidadMedida.nombre`}
                                  enterInput={enterInput}
                                  index={index}
                                  columna={1}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={index * 8 + 18}
                                  className="widthTextTable90 heighttext input textAlingRight"
                                  placeholder="Cantidad"
                                  name={`detalles[${index}].cantidad`}
                                  pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                  enterInput={enterInput}
                                  onKeyDown={onKeyDownSelectCantidad}
                                  onBlur={onBlourSelectCantidad}
                                  index={index}
                                  columna={2}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={index * 8 + 19}
                                  className="widthTextTable90 heighttext input textAlingRight"
                                  placeholder="Precio"
                                  name={`detalles[${index}].precio`}
                                  pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                  enterInput={enterInput}
                                  onKeyDown={onKeyDownSelectPrecio}
                                  onBlur={selectInputPrecioOnBluer}
                                  index={index}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={index * 8 + 20}
                                  className="widthTextTable55 heighttext input textAlingRight"
                                  placeholder="% Desc."
                                  name={`detalles[${index}].descuento`}
                                  pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onBlur={ onBlourSelectDescuento }
                                  onKeyDown={
                                    index === values.detalles?.length! - 1 ? isValid ? (onKeyDownSelectDescuento) : undefined : undefined
                                  }
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  disabled={true}
                                  tabIndex={-1}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Importe"
                                  name={`detalles[${index}].subtotal`}
                                  pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  index={index}
                                  columna={5}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                <Button icon
                                  tabIndex={index * 8 + 21}
                                  name={detalles.id}
                                  type="Button"
                                  disabled={loading && target === String(detalles.id)}
                                  loading={loading && target === String(detalles.id)}
                                  onClick={(e) => {
                                    openModal(
                                      <DeleteModals
                                        deleteConfirmed={deleteConfirmed}
                                        deletedeclined={deletedeclined}
                                        e={e.currentTarget.name.toString()}
                                        id={detalles.id}
                                        nombre={values.detalles ? values.detalles[index].producto?.nombre : "Fila"}
                                        arrayHelpers={arrayHelpers}
                                        index={index}
                                      />,
                                      enumHeaderModals.DetallesComprobante
                                    );
                                  }}
                                  floated="right"
                                  color="red"
                                >
                                  <Icon name='delete' />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </Segment>
                  /////
                )}
              />


            </Grid>
            <div className='colsp' />

            <Segment>
              <div className='col20'>
                <Button
                  type="button"
                  className="ui primary button"
                  tabIndex={104}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <ComentarioComprobante
                        comentario={comentario}
                        setComentario={setComentario}
                        closeModals={closeModals}
                        tabIndex={2228}
                      />,
                      'Comentario del comprobante',
                      'small'
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="money bill alternate" />
                  Comentario
                </Button>
              </div>
              <div className='col20'>
                <Button
                  type="button"
                  className="ui primary button"
                  tabIndex={8}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    abrirPagos("Pagos", values)
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="money bill alternate" />
                  Pagos
                </Button>
              </div>

              {(generacionComprobante.importePagoREP > 0 || generacionComprobante.tipoVenta?.tipoVentaTipoId === 9) ? (<Grid className="ui grid lineheight">
                <div className='col60'></div>
                <label></label>
                <div className='col15'>

                  <label>Importe REP</label></div>
                <div className='col20'>
                  <MyTextInput
                    disabled={true}
                    className=" heighttext input textAlingRight"
                    placeholder=''
                    name='importePagoREP' />
                </div>
              </Grid>
              ) : (
                <Grid className="ui grid lineheight">
                  <div className='col60'></div>
                  <label></label>
                  <div className='col15'>

                    <label>Subtotal</label></div>
                  <div className='col20'>
                    <MyTextInput className=" heighttext input textAlingRight"
                      placeholder=''
                      name='subtotal' />
                  </div>
                  <div className='col60'></div>
                  <label></label>
                  <div className='col15'>
                    <label>Iva</label></div>
                  <div className='col20'>
                    <MyTextInput className=" heighttext input textAlingRight"
                      placeholder=''
                      name='iva' />
                  </div>

                  <div className='col60'></div>
                  <label>   </label>
                  <div className='col15'>
                    <label>Total</label></div>
                  <div className='col20'>
                    <MyTextInput className=" heighttext input textAlingRight"
                      placeholder=''
                      name='total' />
                  </div>

                </Grid>)}
            </Segment>

            <Segment>
              <BarraBotones
                toLink="/vehiculos"
                loading={loading}
                tabIndex={lastElementTable + 2}
                id={id}
                firstElement={firstElement}
                lastElement={lastElementTable + 4}
              ></BarraBotones>

              <Button
                type="button"
                className="ui primary button"
                tabIndex={8}
                onKeyDown={tabuladorButton}
                loading={loading}
                disabled={generacionComprobante.id === 0 ? true : false}
                onClick={() => {
                  //  setImprimiendo(true);
                  imprimirComprobante(generacionComprobante.id).then(resul => {
                    if (resul !== undefined && resul !== "No se pudo actualizar") {
                      let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                      window.open(urlApi + "Reports--Pdfs--"+ resul, '_blank', features);
                    }
                  });
                }
                }
                floated="left"
                color="blue"
              >
                <Icon name="print" />
                Imprimir
              </Button>

            </Segment>
          </Form>
        )}

      </Formik>

    </Segment >

  )
})