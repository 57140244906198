import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { ConceptoClienteTipo } from "../../models/gestionCliente/ConceptoClienteTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ConceptoClienteTipoStore {
  conceptoClienteTipoRegistry = new Map<number, ConceptoClienteTipo>();
  selectedConceptoClienteTipo: ConceptoClienteTipo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get conceptosClienteTiposByDate() {
    return Array.from(this.conceptoClienteTipoRegistry.values()).sort(
      (a) => a.id
    );
  }

  loadConceptosClientesTipos = async () => {
    this.setLoadingInitial(true);
    try {
      const conceptosClientesTipos = await agent.ConceptosClientesTipos.list();
      conceptosClientesTipos.forEach((conceptoClienteTipo) => {
        this.setConceptoClienteTipo(conceptoClienteTipo);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getConceptosClientesTipos = async () => {
    this.setLoadingInitial(true);
    const conceptosClientesTipos = await agent.ConceptosClientesTipos.list();
    try {
      conceptosClientesTipos.forEach((conceptoClienteTipo) => {
        this.setConceptoClienteTipo(conceptoClienteTipo);
      });
      this.setLoadingInitial(false);
      return conceptosClientesTipos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return conceptosClientesTipos;
    }
  };
  get conceptosClientesTipoByDropdown() {
    return entityByDropdown<ConceptoClienteTipo>(
      Array.from(this.conceptoClienteTipoRegistry.values())
    );
  }

  loadConceptoClienteTipo = async (id: number) => {
    let conceptoClienteTipo = this.getConceptoClienteTipo(id);
    if (conceptoClienteTipo) {
      this.selectedConceptoClienteTipo = conceptoClienteTipo;
      return conceptoClienteTipo;
    } else {
      this.loadingInitial = true;
      try {
        conceptoClienteTipo = await agent.ConceptosClientesTipos.details(id);
        this.setConceptoClienteTipo(conceptoClienteTipo);
        this.selectedConceptoClienteTipo = conceptoClienteTipo;
        this.setLoadingInitial(false);
        return conceptoClienteTipo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setConceptoClienteTipo = (
    conceptoClienteTipo: ConceptoClienteTipo
  ) => {
    this.conceptoClienteTipoRegistry.set(
      conceptoClienteTipo.id,
      conceptoClienteTipo
    );
  };

  private getConceptoClienteTipo = (id: number) => {
    return this.conceptoClienteTipoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createConceptoClienteTipo = async (
    conceptoClienteTipo: ConceptoClienteTipo
  ) => {
    this.loading = true;
    try {
      await agent.ConceptosClientesTipos.create(conceptoClienteTipo);
      runInAction(() => {
        this.conceptoClienteTipoRegistry.set(
          conceptoClienteTipo.id,
          conceptoClienteTipo
        );
        this.selectedConceptoClienteTipo = conceptoClienteTipo;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  

  deleteConceptoClienteTipo = async (id: number) => {
    this.loading = true;
    try {
      await agent.ConceptosClientesTipos.delete(id);
      runInAction(() => {
        this.conceptoClienteTipoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
