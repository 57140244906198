import { FechaObservacion, FechaObservacionValues } from "./FechaObservacion";
import { ImpuestoTipo } from "./ImpuestoTipo";

export interface Impuesto {
    id: number;
    clave:number;
    nombre: string;
    impuestoTipoId:number;
    impuestoTipo?:ImpuestoTipo;
    fechaObservacionId: number;
    porcentajeCuota: number;
    fechaObservacion: FechaObservacion;
}

export interface IImpuestoFormValues extends Partial<Impuesto> {
    selectedIdTipo: any;
}

export class ImpuestoFormValues implements Impuesto {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    impuestoTipoId:number= 0;
    impuestoTipo?:ImpuestoTipo;
    porcentajeCuota: number= 0;
    selectedIdTipo?: undefined;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    
    constructor(init?: Impuesto) {
        Object.assign(this, init);
    }
}