import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import AlmacenForm from "../form/AlmacenForm";

export default observer(function AlmacenList() {
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { almacenStore } = useStore();
  const { deleteAlmacen, almacenesById, loading } = almacenStore;
  const [target, setTarget] = useState("");

  function deleteConfirmed(e: string, id: number) {
    deleteAlmacen(id);
    setTarget(e);
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }

  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>CLAVE</Table.HeaderCell>
            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
            {/* <Table.HeaderCell>TIPO DE MONEDA</Table.HeaderCell> */}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {almacenesById.map((almacen) => (
            <Table.Row key={almacen.id}>
              <Table.Cell>{almacen.clave}</Table.Cell>
              <Table.Cell>{almacen.nombre}</Table.Cell>
              <Table.Cell>
                <Button onClick={() => tabsStore.addTab('Crear Almacen', <AlmacenForm id={almacen.id} />)}
                  floated="right"
                  content="Editar"
                  color="blue"
                />
                <Button
                  name={almacen.id}
                  disabled={loading && target === String(almacen.id)}
                  loading={loading && target === String(almacen.id)}
                  onClick={(e) =>
                    openModal(
                      <DeleteModals
                        deleteConfirmed={deleteConfirmed}
                        deletedeclined={deletedeclined}
                        e={e.currentTarget.name.toString()}
                        id={almacen.id}
                        nombre={almacen.nombre}
                      />,
                      enumHeaderModals.Almacen
                    )
                  }
                  floated="right"
                  content="Eliminar"
                  color="red"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
});