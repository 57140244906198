import { useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label, Accordion, Header, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Producto,
  ProductoFormValues,
} from "../../../../app/models/inventario/Producto";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import FileWidgetDropzone from "../../../../app/common/form/FileWidgetDropzone";
import BarraBotones from "../../../Component/BarraBotones";
import { TipoDropdown, TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import { OnChangeProps, OnKeyDownProps } from "../../../../app/models/myProps";
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import Busqueda from "../../../General/Busqueda";


interface idProps {
  id: number
}

export default observer(function ProductoForm({ id }: idProps) {
  ///acordion
  const [activeIndex, setActiveIndex] = useState();
  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  //

  const firstElement = 0;
  const lastElement = 13;
  const {
    productoStore,
    empresaStore,
    sucursalStore,
    productoTipoStore,
    monedaStore,
    unidadMedidaStore,
    categoriaProductoStore,
    modalStore,
    politicaDescuento,
    proveedorStore,
    impuestoProductoStore,
    clasificacionStore,
    almacenStore,
  } = useStore();
  const { almacenesByDropdown,almacenesByDropdownClave, loadAlmacenes } = almacenStore;
  //const { ImpuestosByDropdown, loadImpuestos } = impuestoStore;
  const { ImpuestosProductosByDropdown, loadImpuestosProductos } = impuestoProductoStore;
  const { clasificacionByDropdown, loadClasificaciones } = clasificacionStore;
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore;
  const { politicaDescuentoByDropdown, loadPoliticaDescuentos } =
    politicaDescuento;
  const { fetchSucursales } = sucursalStore
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const {
    loadProveedorByIdEmpresa,
    proveedoresByEmpresaByDropdown,
    setProveedorByEmpSucRegClear
  } = proveedorStore;
  const { loadProductoTipos, productosTipoByDropdown } = productoTipoStore;

  const { loadEnityMoneda, monedasByDropdown } = monedaStore;
  const { loadUnidadesMedidas, unidadesMedidasTipoByDropdown } =
    unidadMedidaStore;
  const { loadCategoriasProductos, categoriaByDropdown } =
    categoriaProductoStore;

  const { openModal, closeModal } = modalStore;
  const {
    createProducto,
    updateProducto,
    loadProducto,
    loadingInitial,
    loading,
    cargarProducto
  } = productoStore;
  const inputClaveSatRef = useRef<any>(null);
  const inputClaveSATCPRef = useRef<any>(null);
  const [producto, setProducto] = useState(new ProductoFormValues());
  const validationSchema = Yup.object({
    // clave: Yup.number().required("la clave de producto/servicios es requerida."),
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    clave: Yup.string()
      .required('La clave de producto/servicios es requerido.')
      .test('not-only-spaces', 'El campo no puede estar vacío o contener solo espacios en blanco',
        value => value?.trim() !== ''
      ),
    nombre: Yup.string().required(
      "El nombre de producto/servicios es requerida."
    ),
    nombreCorto: Yup.string().required(
      "El nombre de producto/servicios es requerida."
    ),
    unidadMedida1Id: Yup.number()
      .min(1, "Favor de seleccionar una Unidad de medida.")
      .required("Unidad de medida es requerido."),

    numeroDecimales1: Yup.number().positive().integer().min(0).max(9),
    numeroDecimales2: Yup.number().positive().integer().min(0).max(9),
    impuestoId: Yup.number()
      .min(1, "Favor de seleccionar un impuesto.")
      .required("Impuesto es requerido."),
    almacenId: Yup.number()
      .min(1, "Favor de seleccionar un almacen.")
      .required("Almacen es requerido."),
    clasificacionId: Yup.number()
      .min(1, "Favor de seleccionar un clasificacion.")
      .required("Clasificacion es requerido."),
    monedaId: Yup.number()
      .min(1, "Favor de seleccionar una moneda.")
      .required("Moneda es requerido."),
  });

  useEffect(() => {
    void loadEntityEmpresas();
    void loadProductoTipos();
    void loadUnidadesMedidas();
    void loadCategoriasProductos();
    void loadPoliticaDescuentos();
    void loadImpuestosProductos();
    void loadClasificaciones();
    void loadAlmacenes();
    void loadEnityMoneda();
  }, []);

  useEffect(() => {
    if (id) {
      void loadProducto(id).then((producto) => {
        setProducto(new ProductoFormValues(producto));
        if (producto) {
          fetchSucursales(producto.empresaId).then((response) => {
            if (response) {
              setSucursales(response);
            }
          });
          void loadProveedorByIdEmpresa(new PaginationFormValues({ empresaId: producto.empresaId, sucursalId: producto.sucursalId }));
        }
      });
    }
  }, []);

  function handleSubmit(producto: Producto) {
    producto.id > 0
      ? void updateProducto(producto)
      : void createProducto(producto);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    if (event.key !== "Tab") {
      return;
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    enterInput(tabIndex);
    event.preventDefault();
  };

  function closeModals() {
    closeModal();
  }

  function onChangeSelectEmpresa(idSelected: number, setFieldAny: any) {
    setFieldAny("sucursalId", 0);
    setFieldAny("proveedorId", 0);
    setProveedorByEmpSucRegClear();
    fetchSucursales(idSelected).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
  }

  function onChangeSelectSucursal({ value, values, setFieldValue }: OnChangeProps) {
    if (values.empresaId > 0 && value > 0) {
      setFieldValue("proveedorId", 0);
      void loadProveedorByIdEmpresa(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: value }));
    }
  }

  function selectedMenu(index: any) {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  const selectInputClaveOnKeyDown = ({ value, event, values }: OnKeyDownProps) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (values.empresaId > 0 && values.sucursalId > 0 && value && value.trim() !== "") {
        const paginacionProdcuto: PaginationSpecParams = new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, claveString: value });
        cargarProducto(paginacionProdcuto).then((result) => {
          if (result) {
            setProducto(new ProductoFormValues(result))
          } else {
            setProducto(new ProductoFormValues({
              ...new ProductoFormValues(), empresaId: paginacionProdcuto.empresaId, sucursalId: paginacionProdcuto.sucursalId,
              clave: value,
            }));
          }
        })
      }
    }
  }

  const closeModalsAyuda = (name?: string) => {
    if (name === "claveSat" && inputClaveSatRef.current)
      inputClaveSatRef.current.Focus();
    if (name === "claveSATCartaPorte" && inputClaveSATCPRef.current)
      inputClaveSATCPRef.current.Focus();
    closeModal();
  }

  const abrirAyuda = (values: any, setFieldValue: any, index: any, name?: string) => {
    openModal(
      <Busqueda
        name={name}
        values={values}
        tabIndex={32231213}
        closeModals={() => closeModalsAyuda(name)}
        onCopy={(data) => {
          if (data) {
            setFieldValue(name, data.clave);
          }
        }}
      />,
      "Claves Sat", "small", true, "search"
    );
  }

  const onKeyDownInputClaveSAT = ({ event, values, setFieldValue, index, name }: OnKeyDownProps) => {
    if (event.key === "F2") {
      event.preventDefault();
      abrirAyuda(values, setFieldValue, index, name);
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading producto" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={producto}
      >
        {({ values, setFieldValue }) => (
          <Form className="ui form">
            {values.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <Header
              as="h2"
              icon="dropbox"
              content="Productos y Servicios"
              color="blue"
            ></Header>
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Identificación del Producto/Servicio
              </Label>

              <Grid columns={2} stackable>
                {/* Columna de la tabla */}
                <Grid.Column width={7}>
                  <div className="col30Block">
                    <MySelectInput
                      autoFocus={true}
                      tabIndex={0}
                      placeholder="Empresa"
                      label="Empresa"
                      name="empresaId"
                      options={EmpresasByDropdown}
                      enterInput={enterInput}
                      onChange={onChangeSelectEmpresa}
                      inputWidth="170px"
                      menuWidth="300px"
                    />
                  </div>
                  <div className="col30Block">
                    <MySelectInput
                      tabIndex={1}
                      placeholder="Sucursal"
                      label="Sucursal"
                      name="sucursalId"
                      options={sucursales}
                      enterInput={enterInput}
                      onChangeProps={onChangeSelectSucursal}
                      inputWidth="170px"
                      menuWidth="300px"
                    />
                  </div>
                </Grid.Column>

                {/* Columna del gráfico */}
                <Grid.Column width={9}>
                  <div className="col50Block">
                    <FileWidgetDropzone
                      label="Imagen"
                      files={values.productoFile}
                      className="uiAnchoCombo heightFileWidgetDropzone input float"
                      acceptFileDrop={".jpg"}
                      name="productoImagen"
                      tabIndex={5}
                    />
                  </div>
                </Grid.Column>
              </Grid>

              <div className="col30Block">
                <MyTextInput
                  placeholder="Clave"
                  label="Clave"
                  name="clave"
                  onKeyDownProps={selectInputClaveOnKeyDown}
                  tabIndex={2}
                  maxLength={50}
                  enterInput={enterInput}
                //pattern="[A-Za-z0-9]+"
                />
              </div>
              <div className="col30Block"></div>

              <div className="displayInLine">
                <div className="col60">
                  <MyTextInput
                    placeholder="Nombre"
                    label="Nombre"
                    name="nombre"
                    tabIndex={3}
                    enterInput={enterInput}
                    maxLength={500}
                  />
                </div>
                <div className="col30">
                  <MyTextInput
                    placeholder="Nombre corto"
                    label="Nombre corto"
                    name="nombreCorto"
                    tabIndex={4}
                    enterInput={enterInput}
                    maxLength={15}
                  />
                </div>
              </div>

              <div className="displayInLine">
                <div className="col30">
                  <MyTextInput
                    placeholder="Codigo de barra"
                    label="Codigo de barra"
                    name="codigoBarra"
                    tabIndex={6}
                    enterInput={enterInput}
                    maxLength={100}
                  />
                </div>
                <div className="col30Block">
                  <MySelectInput
                    tabIndex={7}
                    placeholder="Clasificacion"
                    label="Clasificacion"
                    name="clasificacionId"
                    options={clasificacionByDropdown}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="300px"
                  //   onChange={idSelectSucursal}
                  />
                </div>
                <div className="col30Block">
                  <MySelectInput
                    tabIndex={8}
                    placeholder="Almacen"
                    label="Almacen"
                    name="almacenId"
                    options={almacenesByDropdownClave}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="300px"
                  //   onChange={idSelectSucursal}
                  />
                </div>
                <div className="col30Block">
                  <MySelectInput
                    tabIndex={9}
                    placeholder="Moneda"
                    label="Moneda"
                    name="monedaId"
                    options={monedasByDropdown}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="300px"
                  />
                </div>
                <div className="col20">
                  <MyTextInput
                    placeholder="Descuento"
                    label="Decuento"
                    name="descuento"
                    tabIndex={10}
                    enterInput={enterInput}
                    pattern="^([0-9]{1,2}(\.[0-9]{0,4})?)$"
                  />
                </div>
              </div>
              <div className="col30">
                <MySelectInput
                  tabIndex={11}
                  placeholder="Impuesto"
                  label="Impuesto"
                  name="impuestoId"
                  options={ImpuestosProductosByDropdown}
                  enterInput={enterInput}
                  inputWidth="170px"
                  menuWidth="300px"
                />
              </div>
            </Segment>

            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 1}
                content="Clase y Tipo"
                onClick={handleClick}
                index={1}
                onKeyDown={(event: any) =>
                  event.key === "Enter" ? selectedMenu(1) : null
                }
              />
              <Accordion.Content active={activeIndex === 1}>
                <div className="col80">
                  <MyTextInput
                    placeholder="Ficha Técnica"
                    label="Ficha Técnica"
                    name="fichaTecnica"
                    enterInput={enterInput}
                    maxLength={200}
                  />
                </div>
                <div className="displayInLine">
                  <div className="col100">
                    <MyCheckInput
                      // label="&nbsp;"
                      placeholder="Descripción adicional al facturar"
                      name="usaDescripcionAdicional"
                      enterInput={enterInput}
                    />
                  </div>

                  <div className="col80">
                    <MyCheckInput
                      //label="&nbsp;"
                      placeholder="Información aduanera"
                      name="usaInformacionAduanera"
                      enterInput={enterInput}
                    />
                  </div>
                  <div className="col80">
                    <MyCheckInput
                      //label="&nbsp;"
                      placeholder="Número de Serie"
                      name="usaSerie"
                      enterInput={enterInput}
                    />
                  </div>
                  <div className="col80">
                    <MyCheckInput
                      //label="&nbsp;"
                      placeholder="Lotes"
                      name="usaLotes"
                      enterInput={enterInput}
                    />
                  </div>
                </div>
                <div className="displayInLine">
                  <div className="col30Block">
                    <MySelectInput
                      tabIndex={17}
                      placeholder="Tipo"
                      label="Tipo"
                      name="productoTipoId"
                      options={productosTipoByDropdown}
                      enterInput={enterInput}
                    />
                  </div>
                  <div className="col30Block">
                    <MySelectInput
                      placeholder="Categoría"
                      label="Categoría"
                      name="categoriaProductoId"
                      options={categoriaByDropdown}
                      enterInput={enterInput}
                    />
                  </div>
                </div>
                <Segment clearing>
                  <Label as="a" color="red" ribbon>
                    Configurar como anticipo
                  </Label>
                  <MyCheckInput
                    label="&nbsp;"
                    placeholder="Es Anticipo"
                    name="esAnticipo"
                    enterInput={enterInput}
                  />
                </Segment>
              </Accordion.Content>
              {/* </Menu.Item> */}
              {/* <Menu.Item> */}
              <Accordion.Title
                tabIndex={20}
                active={activeIndex === 2}
                index={2}
                content="Ventas"
                onClick={handleClick}
                onKeyDown={(event: any) =>
                  event.key === "Enter" ? selectedMenu(2) : null
                }
              />
              <Accordion.Content active={activeIndex === 2}>
                <div className="col30">
                  <MySelectInput
                    placeholder="Unidad Medida 1"
                    label="Unidad Medida 1"
                    name="unidadMedida1Id"
                    options={unidadesMedidasTipoByDropdown}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="250px"
                  />
                </div>
                <div className="col30">
                  <MySelectInput
                    placeholder="Unidad Medida 2"
                    label="Unidad Medida 2"
                    name="unidadMedida2Id"
                    options={unidadesMedidasTipoByDropdown}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="300px"
                  />
                </div>
                <div className="col30">
                  <MySelectInput
                    placeholder="Politíca de Decuento"
                    label="Politíca de Decuento"
                    name="politicaDescuentoId"
                    options={politicaDescuentoByDropdown}
                    enterInput={enterInput}
                    inputWidth="170px"
                    menuWidth="300px"
                  />
                </div>
                <div className="displayInLine">
                  <div className="col40">
                    <MyTextInput
                      placeholder="Localización dentro del almacén"
                      label="Localización dentro del almacén"
                      name="almacenUbicacion"
                      enterInput={enterInput}
                      maxLength={15}
                    />
                  </div>
                  <div className="col25" />
                  <div className="col40">
                    <MyCheckInput
                      label="&nbsp;"
                      placeholder="Permitir salida sin existencia"
                      name="permitirVenderSinExistencia"
                      enterInput={enterInput}
                    />
                  </div>
                </div>
                <div className="col20">
                  <MyTextInput
                    placeholder="Número de decimales"
                    label="Número de decimales"
                    name="numeroDecimales1"
                    enterInput={enterInput}
                    pattern="[0-9]{0,1}"
                  />
                </div>
                <div className="colsp" />
                <div className="col20">
                  <MyTextInput
                    placeholder="Número de decimales"
                    label="Número de decimales"
                    name="numeroDecimales2"
                    enterInput={enterInput}
                    pattern="[0-9]{0,1}"
                  />
                </div>
                <div className="colsp" />
                <div className="col20">
                  <MyTextInput
                    placeholder="Máx. % Asignado"
                    label="Máx. % Asignado"
                    name="maximoPorcentajeDesc"
                    enterInput={enterInput}
                    pattern="^([0-9]{1,2}(\.[0-9]{0,4})?)$"
                  />
                </div>
                <div className="colsp" />
                <div className="col20">
                  <MyTextInput
                    placeholder="Peso en Kilos(teórico)"
                    label="Peso en Kilos(teórico)"
                    name="pesoKilos"
                    enterInput={enterInput}
                    pattern="^([0-9]{1,10}(\.[0-9]{0,4})?)$"
                  />
                </div>

                <Segment clearing>
                  <Label as="a" color="red" ribbon>
                    Código de producto especial por Cliente
                  </Label>
                  <div className="displayInLine">
                    <div className="col40">
                      <MyTextInput
                        placeholder="Clave SAT"
                        label="Clave SAT"
                        name="claveSat"
                        enterInput={enterInput}
                        onKeyDownProps={onKeyDownInputClaveSAT}
                        fieldName="claveSat"
                        ref={inputClaveSatRef}
                      //  maxLength={15}
                      />
                    </div>
                    <div className="col40">
                      <MyTextInput
                        placeholder="Clave SAT CP"
                        label="Clave SAT CP"
                        name="claveSATCartaPorte"
                        enterInput={enterInput}
                        onKeyDownProps={onKeyDownInputClaveSAT}
                        fieldName="claveSATCartaPorte"
                        ref={inputClaveSATCPRef}
                      //  maxLength={15}
                      />
                    </div>
                  </div>
                </Segment>
              </Accordion.Content>
              {/* menu compras */}
              <Accordion.Title
                active={activeIndex === 3}
                content="Compras"
                index={3}
                onClick={handleClick}
                onKeyDown={(event: any) =>
                  event.key === "Enter" ? selectedMenu(3) : null
                }
              />
              <Accordion.Content active={activeIndex === 3}>
                <div className="displayInLine">
                  <div className="col40">
                    <MyTextInput
                      placeholder="Máximo"
                      label="Máximo"
                      name="maximo"
                      enterInput={enterInput}
                      pattern="^([0-9]{1,9}(\.[0-9]{0,2})?)$"
                    />
                  </div>

                  <div className="col40">
                    <MyTextInput
                      placeholder="Minimo"
                      label="Minimo"
                      name="minimo"
                      enterInput={enterInput}
                      pattern="^([0-9]{1,9}(\.[0-9]{0,2})?)$"
                    />
                  </div>
                  <div className="col40">
                    <MyTextInput
                      placeholder="Punto de reorden"
                      label="Punto de reorden"
                      name="puntoReorden"
                      enterInput={enterInput}
                      pattern="^([0-9]{1,9}(\.[0-9]{0,2})?)$"
                    />
                  </div>
                </div>
                <div className="col60">
                  <MyTextInput
                    placeholder="Código Proveedor"
                    label="Código Proveedor"
                    name="codigoProveedor"
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col30">
                  <MySelectInput
                    placeholder="Proveedor Asignado"
                    label="Proveedor Asignado"
                    name="proveedorId"
                    options={proveedoresByEmpresaByDropdown}
                    enterInput={enterInput}
                  />
                </div>
                <div className="col30">
                  <MySelectInput
                    placeholder="Unidad de medida de compra"
                    label="Unidad de medida de compra"
                    name="unidadMedidaCompraId"
                    options={unidadesMedidasTipoByDropdown}
                    enterInput={enterInput}
                  />
                </div>
                <div className="col20">
                  <MyTextInput
                    placeholder="Número de decimales"
                    label="Número de decimales"
                    name="numeroDecimalesCompra"
                    enterInput={enterInput}
                    pattern="[0-9]{0,1}"
                  />
                </div>
                <div className="col80">
                  <MyCheckInput
                    // label="&nbsp;"
                    placeholder="Descripción adicional al comprar"
                    name="usaDescripcionAdicionalCompra"
                    enterInput={enterInput}
                  />
                </div>
              </Accordion.Content>
            </Accordion>

            <Segment clearing>
              <div className="col30Block">
                <Button
                  id="fechaObservacion"
                  type="button"
                  tabIndex={12}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(fehcaO) => { setFieldValue("fechaObservacion", fehcaO) }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Producto
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/productos"
                loading={loading}
                tabIndex={13}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});