import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { MovimientoCliente } from "../../models/gestionCliente/MovimientoCliente";
import { Entity } from "../../models/Entity";
import agent from "../../api/agent";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { setDate } from "../../common/util/util";
export default class MovimientoClienteStore {
    movimientoClienteRegistry = new Map<number, MovimientoCliente>();
    movimientoClienteEntity = new Map<number, Entity>();
    selectedMovimientoCliente: MovimientoCliente[] | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    constructor() {
        makeAutoObservable(this);
    }

    get movimientoClientesById() {
        return Array.from(this.movimientoClienteRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    createMovimientoCliente = async (movimientoCliente: MovimientoCliente[]) => {
        this.loading = true;
        try {
            await agent.MovimientoClientes.create(movimientoCliente);
            runInAction(() => {
                this.selectedMovimientoCliente = movimientoCliente;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("MovimientoCliente creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };


    deleteMovimientoCliente = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.MovimientoClientes.delete(id);
                runInAction(() => {
                    this.movimientoClienteRegistry.delete(id);
                    this.loading = false;
                });
                toast.success("Movimiento Cliente eliminada con éxito");
            } catch (error) {
                toast.error("Problema al eliminar movimientoCliente");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    };
    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
            try {
                const movimientoClientes = await agent.MovimientoClientes.obtenerFolio(filtros);
                return movimientoClientes;

            } catch (error) {
                console.log(error);
            }
        } else {
        }
    };

    cargarMovimiento = async (filtros: PaginationSpecParams) => {
        try {
            let movimientoCliente = await agent.MovimientoClientes.obtenerMovimientoPorClave(filtros);
            // if (movimientoCliente) {
            //     movimientoCliente.fecha = new Date(movimientoCliente.fecha)
            //     // setDate<MovimientoCliente>(movimientoCliente);

            // }
            return movimientoCliente;
        } catch (error) {
            console.log(error);
        }
    };
}
