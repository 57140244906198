import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import MonedaForm from "../form/MonedaForm";

export default observer(function MonedaList() {
  const { tabsStore, modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { monedaStore } = useStore();
  const { deleteMoneda, monedasById, loading } = monedaStore;
  const [target, setTarget] = useState("");

  function deleteConfirmed(e: string, id: number) {
    deleteMoneda(id);
    setTarget(e);
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }

  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>CLAVE</Table.HeaderCell>
            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
            <Table.HeaderCell>TIPO DE MONEDA</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {monedasById.map((moneda) => (
            <Table.Row key={moneda.id}>
              <Table.Cell>{moneda.clave}</Table.Cell>
              <Table.Cell>{moneda.nombre}</Table.Cell>
              <Table.Cell>{moneda.tipo?.nombre}</Table.Cell>
              <Table.Cell>
                <Button
                  onClick={() => tabsStore.addTab('Editar Monedas', <MonedaForm id={moneda.id} />)}
                  floated="right" content="Editar" color="blue" />
                <Button
                  name={moneda.id}
                  disabled={loading && target === String(moneda.id)}
                  loading={loading && target === String(moneda.id)}
                  onClick={(e) =>
                    openModal(
                      <DeleteModals
                        deleteConfirmed={deleteConfirmed}
                        deletedeclined={deletedeclined}
                        e={e.currentTarget.name.toString()}
                        id={moneda.id}
                        nombre={moneda.nombre}
                      />,
                      enumHeaderModals.Moneda
                    )
                  }
                  floated="right"
                  content="Eliminar"
                  color="red"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
});