import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import TipoCompraList from './SerieFolioCompraList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import SerieFolioCompraForm from '../form/SerieFolioCompraForm';
export default observer(function TipoCompraDashboard() {
  const { serieFolioCompraStore, tabsStore } = useStore();
  useEffect(() => {
    serieFolioCompraStore.loadSeriesFolios();
  }, [serieFolioCompraStore]);

  if (serieFolioCompraStore.loadingInitial)
    return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' icon='warehouse' content='Series y Folios de Compras' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Serie y Folio', <SerieFolioCompraForm id={0} />)}
              content="Crear Serie y Folio"
              color="blue"
            />
            <Button onClick={() => serieFolioCompraStore.loadSeriesFolios()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <TipoCompraList />
      </Grid.Column>
    </Grid>
  )
})