import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { Usuario } from "../configuracion/Usuario";
import { Cliente } from "../gestionCliente/Cliente";
import { c_Mes } from "../sat/c_Mes";
import { c_Periodicidad } from "../sat/c_Periodicidad";
import { GeneracionComprobante } from "./GeneracionComprobante";
import { SerieFolioDeVenta } from "./SerieFolioDeVenta";
import { TipoVenta } from "./TipoVenta";
export interface Tickets extends GeneracionComprobante {
    checkList: boolean;
}
export interface GeneracionCompronanteTickets {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoVentaId: number;
    tipoVenta?: TipoVenta;
    serieFolioDeVentaId: number;
    serieFolioDeVenta?: SerieFolioDeVenta;
    tipoVenta2Id: number;
    tipoVenta2?: TipoVenta;
    serieFolioDeVenta2Id: number;
    serieFolioDeVenta2?: SerieFolioDeVenta;
    folio: number;
    fecha: Date;
    clienteId: number;
    cliente?: Cliente;
    elaboroId: number;
    elaboro?: Usuario;
    cobradorId: number;
    cobrador?: Usuario;
    fechaInicio?: Date;
    fechaFin?: Date;
    folios: string;
    mesId?: number;
    mes?: c_Mes;
    periodicidadId?: number;
    periodo?: c_Periodicidad;
    tickets: Tickets[],
    subtotal: number;
    descuento: number;
    iEPS: number;
    iva: number;
    total: number;
    porFecha: boolean;
    porFolio: boolean;
    checkListAll: boolean;
}
export class GeneracionCompronanteTicketsFormValues implements GeneracionCompronanteTickets {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoVentaId: number = 0;
    tipoVenta?: TipoVenta;
    serieFolioDeVentaId: number = 0;
    serieFolioDeVenta?: SerieFolioDeVenta;
    tipoVenta2Id: number = 0;
    tipoVenta2?: TipoVenta;
    serieFolioDeVenta2Id: number = 0;
    serieFolioDeVenta2?: SerieFolioDeVenta;
    folio: number = 0;
    fecha: Date = new Date();
    clienteId: number = 0;
    cliente?: Cliente;
    elaboroId: number = 0;
    elaboro?: Usuario;
    cobradorId: number = 0;
    cobrador?: Usuario;
    fechaInicio?: Date;
    fechaFin?: Date;
    folios: string = "";
    mesId?: number = 0;
    mes?: c_Mes;
    periodicidadId?: number = 0;
    periodo?: c_Periodicidad;
    año?: number = new Date().getFullYear();
    tickets: Tickets[] = [];
    subtotal: number = 0;
    descuento: number = 0;
    iEPS: number = 0;
    iva: number = 0;
    total: number = 0;
    porFecha: boolean = false;
    porFolio: boolean = false;
    checkListAll: boolean = false;
}
