import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Image, Button, Container } from 'semantic-ui-react';
import { Producto } from '../../../../app/models/inventario/Producto';
import { urlApiImagenes } from '../../../../app/api/agent';
import { formatDecimal } from '../../../../app/base/Tools';

interface ProductListProps {
  products: Producto[];
  addToCart: (product: Producto) => void;
}



export const ProductList: React.FC<ProductListProps> = observer(({ products, addToCart }) => {
  const containerStyle: React.CSSProperties = {
    maxHeight: products.length > 10 ? '600px' : 'none',
    overflowY: products.length > 10 ? 'auto' : 'visible',
    padding: '1rem',
  };
  return (
    <Container style={containerStyle}>
      <Card.Group itemsPerRow={3} stackable>
        {products.map((product) => (
          <Card key={product.id}>
            <Image
              src={
                product.productoFile?.url
                  ? `${urlApiImagenes}${product.productoFile.url.replace(/\\/g, '/')}`
                  : "/assets/images/default-image.jpg"
              }
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>{product.nombre}</Card.Header>
              <Card.Meta>
                <span className='price'>
                  ${formatDecimal(product.controlListaDePrecio?.length > 0 ? product.controlListaDePrecio[0]?.precio : 0)}
                </span>
              </Card.Meta>
            </Card.Content>
            <Card.Content extra>
              <Button primary fluid onClick={() => addToCart(product)}>
                Agregar al carrito
              </Button>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Container>
  );
});