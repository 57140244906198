import { Producto } from "./Producto";

export interface TraspasoEntreSucursalDetalle {
    id: number;
    productoId: number;
    producto?: Producto;
    cantidad: number;
    unidadMedida: string;
    conversion: number;
    costo: number;
    cargoExtra: number;
    totalCosto: number;
}

export class TraspasoEntreSucursalDetalleFormValues implements TraspasoEntreSucursalDetalle {
    id: number = 0;
    productoId: number = 0;
    producto?: Producto;
    cantidad: number = 0;
    unidadMedida: string = "";
    conversion: number = 0;
    costo: number = 0;
    cargoExtra: number = 0;
    totalCosto: number = 0;

    constructor(init?: TraspasoEntreSucursalDetalle) {
        Object.assign(this, init);
    }
}


