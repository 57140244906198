import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ImpuestoComprobante } from "../../models/configuracion/ImpuestoComprobante";

export default class ImpuestoComprobanteStore {
    impuestoComprobanteRegistry = new Map<number, ImpuestoComprobante>();
    selectedImpuestoComprobante: ImpuestoComprobante | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get impuestosComprobantesByDate() {
        return Array.from(this.impuestoComprobanteRegistry.values()).sort((a) => a.id);
    }


    loadImpuestosComprobantes = async () => {
        this.setLoadingInitial(true);
        try {
            const impuestosComprobantes = await agent.ImpuestosComprobantes.list();
            impuestosComprobantes.forEach(impuestoComprobante => {
                this.setImpuestoComprobante(impuestoComprobante);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    getImpuestosComprobantes = async () => {
        this.setLoadingInitial(true);
        const impuestosComprobantes = await agent.ImpuestosComprobantes.list();
        try {
            impuestosComprobantes.forEach(impuestoComprobante => {
                this.setImpuestoComprobante(impuestoComprobante);
            })
            this.setLoadingInitial(false);
            return impuestosComprobantes;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return impuestosComprobantes;
        }
    }

    loadImpuesto = async (id: number) => {
        let impuestoComprobante = this.getImpuestoComprobante(id);
        if (impuestoComprobante) {
            this.selectedImpuestoComprobante = impuestoComprobante;
            return impuestoComprobante;

        } else {
            this.loadingInitial = true;
            try {
                impuestoComprobante = await agent.ImpuestosComprobantes.details(id);
                this.setImpuestoComprobante(impuestoComprobante);
                this.selectedImpuestoComprobante = impuestoComprobante;
                this.setLoadingInitial(false);
                return impuestoComprobante;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setImpuestoComprobante = (impuestoComprobante: ImpuestoComprobante) => {
        this.impuestoComprobanteRegistry.set(impuestoComprobante.id, impuestoComprobante);
    }

    private getImpuestoComprobante = (id: number) => {
        return this.impuestoComprobanteRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createImpuestoComprobante = async (impuestoComprobante: ImpuestoComprobante) => {
        this.loading = true;
        try {
            await agent.ImpuestosComprobantes.create(impuestoComprobante);
            runInAction(() => {
                this.impuestoComprobanteRegistry.set(impuestoComprobante.id, impuestoComprobante);
                this.selectedImpuestoComprobante = impuestoComprobante;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteImpuestoComprobante = async (id: number) => {
        this.loading = true;
        try {
            await agent.ImpuestosComprobantes.delete(id);
            runInAction(() => {
                this.impuestoComprobanteRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
