import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import CuentaDeClienteList from './CuentaDeClienteList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CuentaDeClienteForm from '../form/CuentaDeClienteForm';

export default observer(function CuentaDeClienteDashboard() {
  const { cuentaDeClienteStore, tabsStore } = useStore();
  useEffect(() => {
    cuentaDeClienteStore.loadCuentasClientes();
  }, [cuentaDeClienteStore]);

  if (cuentaDeClienteStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' icon='list alternate' content='Cuentas de Clientes' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear cuenta de Clientes', <CuentaDeClienteForm id={0} />)}
              content='Crear cuenta de clientes' color='blue' />
            <Button onClick={() => cuentaDeClienteStore.loadCuentasClientes()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <CuentaDeClienteList />
      </Grid.Column>
    </Grid>
  )
})