import { observer } from 'mobx-react-lite';
import { Tab, Button } from 'semantic-ui-react';
import { useStore } from '../../stores/store';


const DynamicTabs = observer(() => {
  const { tabsStore } = useStore();

  const panes = tabsStore?.tabs?.map((tab, index) => ({
    menuItem: { key: index, content: (<div>{tab.title} <Button
      icon="close"
      size="mini"
      onClick={(e) => {
        e.stopPropagation();
        tabsStore.removeTab(index);
      }}
    /></div>) },
    pane: (
      <Tab.Pane key={index}>
        {tab.content}
      </Tab.Pane>
    ),
  }));

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}//estilo del tap
      panes={panes}
      renderActiveOnly={false}
      activeIndex={tabsStore.activeIndex}
      onTabChange={(e, { activeIndex }) => tabsStore.setActiveIndex(activeIndex)}
    />
  );
});

export default DynamicTabs;
