export interface GeneracionComprobanteNotaCreditoDetalle {
    id: number;
    serie : string;
    folio : number;
    importe:number;
    uuid:string;
}
export interface IGeneracionComprobanteNotaCreditoDetalleFormValues extends Partial<GeneracionComprobanteNotaCreditoDetalle> {
    id: any;
}
export class GeneracionComprobanteNotaCreditoDetalleFormValues implements IGeneracionComprobanteNotaCreditoDetalleFormValues {
    id: number =0;
    serie : string="";
    folio : number =0;
    importe:number=0;   
    uuid:string="";
    constructor(init?: GeneracionComprobanteNotaCreditoDetalle) {
        Object.assign(this, init);
    }
}