import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ProductoTipo } from "../../models/inventario/ProductoTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ProductoTipoStore {
  productoTipoRegistry = new Map<number, ProductoTipo>();
  selectedProductoTipo: ProductoTipo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  /*get monedasByDate() {
        return Array.from(this.monedaRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }*/
  get productosTipoByDropdown() {
    return entityByDropdown<ProductoTipo>(
      Array.from(this.productoTipoRegistry.values())
    );
  }

  loadProductoTipos = async () => {
    this.setLoadingInitial(true);
    try {
      const productoTipos = await agent.ProductoTipos.list();
      productoTipos.forEach((productoTipo) => {
        this.setProductoTipo(productoTipo);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getProductoTipos = async () => {
    this.setLoadingInitial(true);
    const productoTipos = await agent.ProductoTipos.list();
    try {
      productoTipos.forEach((productoTipo) => {
        this.setProductoTipo(productoTipo);
      });
      this.setLoadingInitial(false);
      return productoTipos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return productoTipos;
    }
  };

  loadProductoTipo = async (id: number) => {
    let productoTipo = this.getProductoTipo(id);
    if (productoTipo) {
      this.selectedProductoTipo = productoTipo;
      return productoTipo;
    } else {
      this.loadingInitial = true;
      try {
        productoTipo = await agent.ProductoTipos.details(id);
        this.setProductoTipo(productoTipo);
        this.selectedProductoTipo = productoTipo;
        this.setLoadingInitial(false);
        return productoTipo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setProductoTipo = (productoTipo: ProductoTipo) => {
    this.productoTipoRegistry.set(productoTipo.id, productoTipo);
  };
  private setProductoTipos = (productoTipo: ProductoTipo[]) => {
    // this.monedaTipoRegistry.set(monedaTipo.id, monedaTipo);
  };

  private getProductoTipo = (id: number) => {
    return this.productoTipoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createProductoTipo = async (productoTipo: ProductoTipo) => {
    this.loading = true;
    try {
      await agent.ProductoTipos.create(productoTipo);
      runInAction(() => {
        this.productoTipoRegistry.set(productoTipo.id, productoTipo);
        this.selectedProductoTipo = productoTipo;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };


  deleteProductoTipo = async (id: number) => {
    this.loading = true;
    try {
      await agent.ProductoTipos.delete(id);
      runInAction(() => {
        this.productoTipoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
