import React, { useEffect, useState, useRef } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import MyCheckInput from '../../../../app/common/form/MyCheckInput';
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Modal, ModalActions, ModalContent, ModalHeader, Segment, Table, Accordion } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import { GeneracionComprobantePago, GeneracionComprobantePagoFormValues } from '../../../../app/models/venta/GeneracionComprobantePagos';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import { GeneracionComprobantePagoDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobantePagosDetalle';
import { observer } from 'mobx-react-lite';

//import { GeneracionComprobantePago, GeneracionComprobantePagoFormValues } from '../../../../app/models/venta/GeneracionComprobantePagos';


interface Props {
  pago: GeneracionComprobantePago;
  closeModals: () => void;
  setPagos: (setFO: GeneracionComprobantePagoFormValues) => void;
  tabIndex: number;
}

export default observer(function FacturasComplementoPagoForm(props: Props) {

  ///acordion
  const [activeIndex, setActiveIndex] = useState();
  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  //
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { condicionPagoStore, formaPagoStore, proveedorStore, sucursalStore, tipoVentaStore } = useStore();
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown } = sucursalStore
  const { TiposVentasByDropdown } = tipoVentaStore

  const { formasPagosByDropdown } = formaPagoStore;
  const { empresaSucursal } = proveedorStore
  const [GeneracionComprobantePago, setGeneracionComprobantePago] = useState(new GeneracionComprobantePagoFormValues());
  const validationSchema = Yup.object({
    importe: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
  });
  useEffect(() => {
    condicionPagoStore.getCondicionesPagos();
    selectedMenu(0)
    empresaSucursal.empresaId = 1;
    void loadSucursalByIdEmpresa(empresaSucursal.empresaId);
  }, [])

  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    props.setPagos(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
  }


  function agregarPago(arrayHelpers: any) {
    arrayHelpers.push(new GeneracionComprobantePagoDetalleFormValues())


  }

  function deletedeclined() {
    setOpen({ abrir: false, index: 0 })
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    //deleteGeneracionComprobante(id).then((respuest) => {
    console.log("entro", index);
    arrayHelpers.remove(index);
    setOpen({ abrir: false, index: 0 })
    // setLastElementTable(
    //   (prevLastElementTable) =>
    //     prevLastElementTable - numberControlsTable
    // );

    // });
    //setTarget(e);
    //closeModal();
  }

  function selectedMenu(index: any) {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }
  const cbotipoVentaRef = useRef;
  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };
  return (

    <Formik
      //validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.pago}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <Segment>
            <div className="col100">
              <FieldArray
                name="pagos"
                render={(arrayHelpers) => (
                  /////
                  <Segment>
                    <Accordion fluid styled>
                      <Accordion.Title
                        tabIndex={12}
                        active={activeIndex === 1}
                        content="Facturas del Emitidas en el Sistema"
                        index={0}
                        onClick={handleClick}
                        onKeyDown={(event: any) =>
                          event.key === "Enter" ? selectedMenu(0) : null
                        }
                      />
                      <Accordion.Content active={activeIndex === 0}>
                        <Table
                          style={{
                            overflow: "auto",
                            display: "inline-block",
                            maxHeight: "inherit",
                            width: 930,
                            height: 350
                          }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Suc</Table.HeaderCell>
                              <Table.HeaderCell>Tipo Venta</Table.HeaderCell>
                              <Table.HeaderCell>SerieFolio</Table.HeaderCell>
                              <Table.HeaderCell>Fecha</Table.HeaderCell>
                              <Table.HeaderCell>Total</Table.HeaderCell>
                              <Table.HeaderCell>Saldo</Table.HeaderCell>
                              <Table.HeaderCell>Abono</Table.HeaderCell>
                              <Table.HeaderCell>#pago</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {values.pagos?.map(
                              (pago, index) => (

                                <Table.Row key={index.valueOf()} className="paddinTable">

                                  <Table.Cell className="paddinTable">
                                    {" "}
                                    <MySelectInput
                                      tabIndex={14}
                                      placeholder="Sucursal"
                                      label=""
                                      name={`pagos[${index}].condiciondePagoId`}
                                      options={sucursalesByEmpresaByDropdown}
                                      enterInput={enterInput}
                                      index={index}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      tabIndex={3}
                                      placeholder={"Seleccionar"}
                                      label="Tipo Venta"
                                      name={`pagos[${index}].tipoVentaId`}
                                      options={TiposVentasByDropdown}
                                      enterInput={enterInput}
                                      //onChange={idSelectTipo}
                                      ref={cbotipoVentaRef}
                                      index={index}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}

                                    <MySelectInput
                                      tabIndex={15}
                                      placeholder="SerieFolio"
                                      label=""
                                      name={`pagos[${index}].formaDePagoId`}
                                      options={formasPagosByDropdown}
                                      enterInput={enterInput}
                                      index={index}

                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <div className='col12'>
                                      <MyDateInput
                                        tabIndex={6}
                                        placeholderText="Fecha Emision"
                                        name="fecha"
                                        dateFormat="dd/MM/yyyy"
                                        enterInput={enterInput}
                                      />
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Total"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Saldo"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Abono"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>



                                  <Modal
                                    onClose={() => setOpen({ abrir: false, index: 0 })}
                                    open={open.abrir}
                                    size='mini'
                                  >
                                    <ModalHeader>{enumHeaderModals.DetallesComprobante}</ModalHeader>
                                    <ModalContent>
                                      <DeleteModals
                                        deleteConfirmed={deleteConfirmed}
                                        deletedeclined={deletedeclined}
                                        e={""}
                                        id={pago.id}
                                        nombre={"Fila"}
                                        arrayHelpers={arrayHelpers}
                                        index={open.index}
                                      />
                                    </ModalContent>
                                  </Modal>
                                </Table.Row>
                              )
                            )
                            }

                            {!values.pagos?.length ? (
                              arrayHelpers.push(new GeneracionComprobantePagoDetalleFormValues())
                            ) : null}



                          </Table.Body>
                        </Table>
                      </Accordion.Content>


                      <Accordion.Title
                        tabIndex={12}
                        active={activeIndex === 1}
                        content="Facturas No Emitidas en el Sistema"
                        index={1}
                        onClick={handleClick}
                        onKeyDown={(event: any) =>
                          event.key === "Enter" ? selectedMenu(1) : null
                        }
                      />
                      <Accordion.Content active={activeIndex === 1}>
                        <Table
                          style={{
                            overflow: "auto",
                            display: "inline-block",
                            maxHeight: "inherit",
                            width: 830,
                            height: 350
                          }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Serie</Table.HeaderCell>
                              <Table.HeaderCell>Folio</Table.HeaderCell>
                              <Table.HeaderCell>UUID</Table.HeaderCell>
                              <Table.HeaderCell>Moneda</Table.HeaderCell>
                              <Table.HeaderCell>Método Pago</Table.HeaderCell>
                              <Table.HeaderCell>#Parcialidad</Table.HeaderCell>
                              <Table.HeaderCell>Saldo Anterior</Table.HeaderCell>
                              <Table.HeaderCell>Importe Pago</Table.HeaderCell>
                              <Table.HeaderCell>Saldo Insoluto</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {values.pagos?.map(
                              (pago, index) => (

                                <Table.Row key={index.valueOf()} className="paddinTable">

                                  <Table.Cell className="paddinTable">
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Serie"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Folio"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="UUID"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <div className='col12'>
                                      <MyTextInput
                                        tabIndex={getIndexTable(2, 6)}
                                        className="widthTextTable100 heighttext input textAlingRight"
                                        placeholder="Moneda"
                                        name={`pagos[${index}].importe`}
                                        enterInput={enterInput}
                                        arrayHelpers={arrayHelpers}
                                        onKeyDown={onKeyDownSelectImporte}
                                        // index={index}
                                        columna={3}
                                      />
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Total"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Saldo"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Abono"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Abono"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(2, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Abono"
                                      name={`pagos[${index}].importe`}
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectImporte}
                                      // index={index}
                                      columna={3}
                                    />
                                  </Table.Cell>

                                  <Modal
                                    onClose={() => setOpen({ abrir: false, index: 0 })}
                                    open={open.abrir}
                                    size='mini'
                                  >
                                    <ModalHeader>{enumHeaderModals.DetallesComprobante}</ModalHeader>
                                    <ModalContent>
                                      <DeleteModals
                                        deleteConfirmed={deleteConfirmed}
                                        deletedeclined={deletedeclined}
                                        e={""}
                                        id={pago.id}
                                        nombre={"Fila"}
                                        arrayHelpers={arrayHelpers}
                                        index={open.index}
                                      />
                                    </ModalContent>
                                  </Modal>
                                </Table.Row>
                              )
                            )
                            }

                            {!values.pagos?.length ? (
                              arrayHelpers.push(new GeneracionComprobantePagoDetalleFormValues())
                            ) : null}

                          </Table.Body>
                        </Table>


                      </Accordion.Content>

                    </Accordion>




                  </Segment>
                  /////
                )}
              />
            </div>
          </Segment>
          <Segment>
            <Button.Group>
              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form >
      )}
    </Formik >

  )
})