import { runInAction } from "mobx";
import agent from "../../api/agent";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { GeneracionCompronanteTickets, Tickets } from "../../models/venta/GeneracionCompronanteTickets";
import { history } from "../../..";
import { toast } from "react-toastify";
export default class FacturaGlobalStore {
    ticketRegistry = new Map<number, Tickets>();
    facturaGlobalParams: PaginationSpecParams = new PaginationFormValues();
    facturaGlobalParams2: PaginationSpecParams = new PaginationFormValues();
    loading = false;
    editMode = false;
    loadingInitial = false;
    selectedFacturaGlobal: GeneracionCompronanteTickets | undefined = undefined;
    get ticketsById() {
        return Array.from(this.ticketRegistry.values()).sort(
            (a, b) => a.id - b.id
        );
    }

    getTickets = async (filtoTickets: PaginationSpecParams) => {
        try {
            const tickets = await agent.FacturaGlobal.getTickets(
                filtoTickets
            );
            tickets.forEach((ticket) => {
                this.setTicket(ticket);
            });

            return tickets;
        } catch (error) {
            console.error(error);
        }
    }
    createFacturaGlobal = async (facturaGlobal: GeneracionCompronanteTickets) => {
        this.loading = true;
        try {
            await agent.FacturaGlobal.create(facturaGlobal);
            runInAction(() => {
                this.selectedFacturaGlobal = facturaGlobal;
                this.editMode = false;
                this.loading = false;
            });

            
            toast.success("Factura Global creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };
    private setTicket = (ticket: Tickets) => {
        this.ticketRegistry.set(ticket.id, ticket);
    };
    public setTicketRegClear = () => {
        this.ticketRegistry.clear();
    }
}
