import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { CuentaDeProveedor, CuentaDeProveedorFormValues } from "../../../../app/models/gestionProveedor/CuentaDeProveedor";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import * as Yup from "yup";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}


export default observer(function CuentaDeProveedorForm({ id }: idProps) {
  const { cuentaDeProveedorStore, cuentaDeProveedorTipoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createCuentaDeProveedor, updateCuentaDeProveedor, loadCuentaDeProveedor, loadingInitial, cuentasDeProveedoresById, loading } = cuentaDeProveedorStore;
  const { cuentasDeProveedoresTipoByDropdown, getCuentasDeProveedoresTipos } = cuentaDeProveedorTipoStore;
  //const { id } = useParams<{ id: string }>();
  const [cuentaDeProveedor, setCuentasDeProveedores] = useState(new CuentaDeProveedorFormValues());
  const [CuentaDeProveedorTipos, setCuentaDeProveedorTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const firstElement = 0;
  const lastElement = 6;

  useEffect(() => {
    getCuentasDeProveedoresTipos().then(cuenProvTTips => {
      cuenProvTTips.forEach(element => {
        let CuentaProvTipOpt = { key: 999, value: 999, text: "" }
        CuentaProvTipOpt.key = element.id;
        CuentaProvTipOpt.value = element.clave;
        CuentaProvTipOpt.text = element.nombre;
        CuentaDeProveedorTipos.push(CuentaProvTipOpt);
      });
    })
  }, [getCuentasDeProveedoresTipos, CuentaDeProveedorTipos, setCuentaDeProveedorTipos])

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de el cuenta de proveedor es requerida."),
    nombre: Yup.string().required("El nombre de el cuenta de proveedor es requerida."),
    cuentaDeProveedorTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo de cuenta de inventario.")
      .required("El tipo de cuenta de inventario es requerido.")
  });

  useEffect(() => {
    if (id) {
      loadCuentaDeProveedor(id).then((cuentaDeProveedor) => {
        setCuentasDeProveedores(new CuentaDeProveedorFormValues(cuentaDeProveedor))
      });
    }
    else{
      cuentaDeProveedor.clave =
        cuentasDeProveedoresById.map((cuentaDeProveedor) => cuentaDeProveedor.clave).length > 0
          ? Math.max(...cuentasDeProveedoresById.map((cuentaDeProveedor) => cuentaDeProveedor.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(cuentaDeProveedor: CuentaDeProveedor) {
    if (cuentaDeProveedor.cuentaDeProveedorTipo) {
      const tipoCuentaDeProveedorSelected = CuentaDeProveedorTipos.find(
        (a) => a.key === cuentaDeProveedor.cuentaDeProveedorTipoId
      );
      if (tipoCuentaDeProveedorSelected) {
        cuentaDeProveedor.cuentaDeProveedorTipo.nombre = tipoCuentaDeProveedorSelected.text;
        cuentaDeProveedor.cuentaDeProveedorTipo.id = tipoCuentaDeProveedorSelected.key;
        cuentaDeProveedor.cuentaDeProveedorTipo.clave = tipoCuentaDeProveedorSelected.value;
      }
    }
    if (cuentaDeProveedor.cuentaDeProveedorTipoId.toString() !== "0" && cuentaDeProveedor.nombre !== null) {
      cuentaDeProveedor.id > 0 ? updateCuentaDeProveedor(cuentaDeProveedor) : createCuentaDeProveedor(cuentaDeProveedor);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Cuenta Proveedor' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values)}}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={cuentaDeProveedor}
      >

        {({ handleSubmit, isValid, dirty }) => {
          return (
            <Form className='ui form'>
              <Segment clearing>
                <Label as='a' color='red' ribbon>
                  Datos del cuenta de proveedor
                </Label>
                <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
              </Segment>
              <Segment clearing>
                <Label as='a' color='blue' ribbon>
                  Datos especí­ficos
                </Label>

                <MySelectInput 
                  placeholder="Cuenta Proveedor Tipo" 
                  label="Tipo de Cuenta de Proveedor"
                  name="cuentaDeProveedorTipo"
                  enterInput={enterInput}
                  tabIndex={2}
                  options={cuentasDeProveedoresTipoByDropdown} 
                  />
              </Segment>

              <Segment>
                <BarraBotones
                toLink="/cuentasDeProveedores"
                loading={loading}
                tabIndex={3}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
                ></BarraBotones>
              </Segment>

            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
})