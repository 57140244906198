import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { CuentaDeCliente, CuentaDeClienteFormValues } from "../../../../app/models/gestionCliente/CuentaDeCliente";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import * as Yup from "yup";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function CuentaDeClienteForm({ id }: idProps) {
  const { cuentaDeClienteStore, cuentaDeClienteTipoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createCuentaDeCliente, updateCuentaDeCliente, loadCuentaDeCliente, loadingInitial, cuentaDeClienteById, loading } = cuentaDeClienteStore;
  const { cuentasClientesTipoByDropdown, getCuentasClientesTipos } = cuentaDeClienteTipoStore;
  //const { id } = useParams<{ id: string }>();
  const [cuentaDeCliente, setCuentaDeCliente] = useState(new CuentaDeClienteFormValues);
  const [cuentaDeClienteTipos, setCuentaDeClienteTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  const firstElement = 0;
  const lastElement = 7;

  useEffect(() => {
    getCuentasClientesTipos().then((cuenClienTTips) => {
      cuenClienTTips.forEach((element) => {
        let satcuenCliTipOpt = { key: 999, value: 999, text: "" };
        satcuenCliTipOpt.key = element.id;
        satcuenCliTipOpt.value = element.clave;
        satcuenCliTipOpt.text = element.nombre;
        cuentaDeClienteTipos.push(satcuenCliTipOpt);
      });
    });
  }, [getCuentasClientesTipos, cuentaDeClienteTipos, setCuentaDeClienteTipos]);

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de la cuenta del cliente es requerida."),
    nombre: Yup.string().required("El nombre de la cuenta de cliente es requerida."),
    abreviacion: Yup.string().required("La abreviación de la cuenta de cliente es requerida."),
    cuentaDeClienteTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo la cuenta de cliente.")
      .required("El tipo de la cuenta de cliente es requerida.")
  });

  useEffect(() => {
    if (id) {
      loadCuentaDeCliente(id).then((cuentaDeCliente) => {
        setCuentaDeCliente(new CuentaDeClienteFormValues(cuentaDeCliente))
        setFechaObservacion(
          new FechaObservacionValues(cuentaDeCliente?.fechaObservacion)
        );
      });
    }
    else {
      cuentaDeCliente.clave =
        cuentaDeClienteById.map((cuentaDeCliente) => cuentaDeCliente.clave).length > 0
          ? Math.max(...cuentaDeClienteById.map((cuentaDeCliente) => cuentaDeCliente.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(cuentaDeCliente: CuentaDeCliente) {
    if (cuentaDeCliente.cuentaDeClienteTipo) {
      const tipoCuentaDeClienteSelected = cuentaDeClienteTipos.find(
        (a) => a.key === cuentaDeCliente.cuentaDeClienteTipoId
      );
      if (tipoCuentaDeClienteSelected) {
        cuentaDeCliente.cuentaDeClienteTipo.nombre = tipoCuentaDeClienteSelected.text;
        cuentaDeCliente.cuentaDeClienteTipo.id = tipoCuentaDeClienteSelected.key;
        cuentaDeCliente.cuentaDeClienteTipo.clave = tipoCuentaDeClienteSelected.value;
      }
    }
    if (cuentaDeCliente.cuentaDeClienteTipoId.toString() !== "0" && cuentaDeCliente.nombre !== null) {
      cuentaDeCliente.id > 0 ? updateCuentaDeCliente(cuentaDeCliente) : createCuentaDeCliente(cuentaDeCliente);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Cuenta de Cliente' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={cuentaDeCliente}
      >

        {({ isValid, dirty, handleSubmit,setFieldValue,values }) => {
          return (
            <Form className='ui form'>
              <Segment clearing>
                <Label as='a' color='red' ribbon>
                  Cuenta de Cliente
                </Label>
                <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" maxLength={50}/>
                <MyTextInput
                  label="Abreviacion"
                  placeholder="Abreviacion"
                  name="abreviacion"
                  tabIndex={2}
                  enterInput={enterInput}
                  maxLength={30}
                />
              </Segment>
              <Segment clearing>
                <Label as='a' color='blue' ribbon>
                  Datos especí­ficos
                </Label>
                <div></div>

                <MySelectInput
                  placeholder={"Seleccionar"}
                  label="Seleccione el tipo de cliente"
                  name="cuentaDeClienteTipoId"
                  enterInput={enterInput}
                  tabIndex={3}
                  options={cuentasClientesTipoByDropdown}
                  inputWidth="200px"
                  menuWidth="200px"
                />
              </Segment>

              <Segment clearing>
                <Button
                  id="fechaObservacion"
                  type="button"
                  class="ui primary button"
                  tabIndex={4}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => setFieldValue("fechaObservacion", data)}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.cuentaDeCliente
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
                <div className="col80">
                  <BarraFechaObservacion
                    fechaObservacion={values.fechaObservacion}
                  ></BarraFechaObservacion>
                </div>
              </Segment>
              <Segment>
                <BarraBotones
                  toLink="/CuentasDeClientes"
                  loading={loading}
                  tabIndex={5}
                  //id={id}
                  firstElement={firstElement}
                  lastElement={lastElement}
                ></BarraBotones>
              </Segment>
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
})