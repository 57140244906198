import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoMovimiento } from "../TipoMovimiento";
import { ConceptoDeProveedor } from "./ConceptoDeProveedor";
import { CuentaDeProveedor } from "./CuentaDeProveedor";
import { Proveedor } from "./Proveedor";
import { Moneda } from "../configuracion/Moneda";



export interface MovimientoDeProveedorDetalle {
    id: number;
    cuentaDeProveedorId: number;
    cuentaDeProveedor?: CuentaDeProveedor;
    conceptoDeProveedorId: number;
    conceptoDeProveedor?: ConceptoDeProveedor;
    proveedorId: number;
    proveedor?: Proveedor;
    facturaProveedor: string;
    serie: string;
    folio: number;
    serieAfecta: string;
    folioAfecta: number;
    importe: number;
    monedaId:number;
    moneda?: Moneda;
    tipoCambio: number;
    automatico?: boolean | number;
    movimientoDeProveedorId: number;

}
export class MovimientoDeProveedorDetalleFormValues implements MovimientoDeProveedorDetalle {
    id: number = 0;
    cuentaDeProveedorId: number = 0;
    cuentaDeProveedor?: CuentaDeProveedor;
    conceptoDeProveedorId: number = 0;
    conceptoDeProveedor?: ConceptoDeProveedor;
    proveedorId: number = 0;
    proveedor?: Proveedor;
    serie: string = "";
    folio: number = 0;
    serieAfecta: string = "";
    folioAfecta: number = 0;
    importe: number = 0;
    monedaId: number = 0;
    moneda?: Moneda;
    tipoCambio: number = 0;
    facturaProveedor: string = "";
    automatico?: boolean | number = 0;
    movimientoDeProveedorId: number = 0;
    constructor(init?: MovimientoDeProveedorDetalle) {
        Object.assign(this, init);
    }
}

export interface MovimientoDeProveedor {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoMovimientoId: number;
    tipoMovimiento?: TipoMovimiento;
    clave: number;
    fecha: Date;
    comentarios: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    movimientoDeProveedorDetalle?: MovimientoDeProveedorDetalle[];
}
export class MovimientoDeProveedorFormValues implements MovimientoDeProveedor {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoMovimientoId: number = 0;
    tipoMovimiento?: TipoMovimiento;
    clave:number = 0;
    fecha: Date= new Date();;
    comentarios:string ="";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    movimientoDeProveedorDetalle: MovimientoDeProveedorDetalle[] = [new MovimientoDeProveedorDetalleFormValues()]

    constructor(init?: MovimientoDeProveedor) {
        Object.assign(this, init);
    }
}