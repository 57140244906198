import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ConceptoDeInventarioTipo } from "../../models/inventario/ConceptoDeInventarioTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ConceptoDeInventarioTipoStore {
    conceptoDeInventarioTipoRegistry = new Map<number, ConceptoDeInventarioTipo>();
    selectedConceptoDeInventarioTipo: ConceptoDeInventarioTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    
    loadConceptosDeInventariosTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const conceptosDeInventariosTipos = await agent.ConceptosDeInventariosTipos.list();
            conceptosDeInventariosTipos.forEach(conceptoDeInventarioTipo => {
                this.setConceptoDeInventarioTipo(conceptoDeInventarioTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getConceptosDeInventariosTipos = async () => {
        this.setLoadingInitial(true);
        const conceptosDeInventariosTipos = await agent.ConceptosDeInventariosTipos.list();
        try {
            conceptosDeInventariosTipos.forEach(conceptoDeInventarioTipo => {
                this.setConceptoDeInventarioTipo(conceptoDeInventarioTipo);
            })
            this.setLoadingInitial(false);
            return conceptosDeInventariosTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosDeInventariosTipos;
        }
    }

    loadConceptoDeInventarioTipo = async (id: number) => {
        let conceptoDeInventarioTipo = this.getConceptoDeInventarioTipo(id);
        if (conceptoDeInventarioTipo) {
            this.selectedConceptoDeInventarioTipo = conceptoDeInventarioTipo;
            return conceptoDeInventarioTipo;
        } else {
            this.loadingInitial = true;
            try {
                conceptoDeInventarioTipo = await agent.ConceptosDeInventariosTipos.details(id);
                this.setConceptoDeInventarioTipo(conceptoDeInventarioTipo);
                this.selectedConceptoDeInventarioTipo = conceptoDeInventarioTipo;
                this.setLoadingInitial(false);
                return conceptoDeInventarioTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoDeInventarioTipo = (conceptoDeInventarioTipo: ConceptoDeInventarioTipo) => {
        this.conceptoDeInventarioTipoRegistry.set(conceptoDeInventarioTipo.id, conceptoDeInventarioTipo);
    }
    private getConceptoDeInventarioTipo = (id: number) => {
        return this.conceptoDeInventarioTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoInventarioTipo = async (conceptoDeInventarioTipo: ConceptoDeInventarioTipo) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventariosTipos.create(conceptoDeInventarioTipo);
            runInAction(() => {
                this.conceptoDeInventarioTipoRegistry.set(conceptoDeInventarioTipo.id, conceptoDeInventarioTipo);
                this.selectedConceptoDeInventarioTipo = conceptoDeInventarioTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateConceptoDeInventarioTipo = async (conceptoDeInventarioTipo: ConceptoDeInventarioTipo) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventariosTipos.update(conceptoDeInventarioTipo)
            runInAction(() => {
                this.conceptoDeInventarioTipoRegistry.set(conceptoDeInventarioTipo.id, conceptoDeInventarioTipo);
                this.selectedConceptoDeInventarioTipo = conceptoDeInventarioTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteConceptoDeInventarioTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosDeInventariosTipos.delete(id);
            runInAction(() => {
                this.conceptoDeInventarioTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get conceptosDeInventariosTipoByDropdown() {
        return entityByDropdown<ConceptoDeInventarioTipo>(
          Array.from(this.conceptoDeInventarioTipoRegistry.values())
        );
    }
    get conceptosDeInventariosTipoByDropdownByClave() {
        return entityByDropdown<ConceptoDeInventarioTipo>(
          Array.from(this.conceptoDeInventarioTipoRegistry.values()),true
        );
    }
        
}