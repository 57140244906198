import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import ConceptoDeProveedorList from './ConceptoDeProveedorList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import ConceptoDeProveedorForm from '../form/ConceptoDeProveedorForm';

export default observer(function ConceptoDeProveedorDashboard() {
  const { conceptoDeProveedorStore, tabsStore } = useStore();
  useEffect(() => {
    conceptoDeProveedorStore.loadConceptosDeProveedores();
  }, [conceptoDeProveedorStore]);

  if (conceptoDeProveedorStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' content='Conceptos de Proveedor' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Concepto de Proveedor', <ConceptoDeProveedorForm id={0} />)}
              content='Crear Concepto de Proveedor' color='blue' />
            <Button onClick={() => conceptoDeProveedorStore.loadConceptosDeProveedores()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <ConceptoDeProveedorList />
      </Grid.Column>
    </Grid>
  )
})