
import { Empresa } from ".././configuracion/Empresa";
import { Direccion, DireccionValues } from "../configuracion/Direccion";
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Moneda } from "../configuracion/Moneda";
import { Sucursal } from "../configuracion/Sucursal";
import { Usuario } from "../configuracion/Usuario";
import { ListaDePrecio } from "../controlPrecio/ListaDePrecio";
import { UsoCFDI } from "../sat/UsoCFDI";
import { MetodoPago } from "../sat/c_MetodoPago";
import { cRegimenFiscal } from "../sat/cRegimenFiscal";
import { CuentaDeVenta } from "../venta/CuentaDeVenta";
import { ClienteTipo } from "./ClienteTipo";
import { CondicionDePago } from "./CondicionDePago";
import { FormaDePago } from "./FormaDePago";

export interface Cliente {
    id: number;
    clave: number;
    nombre: string;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    rfc: string;
    curp: string;
    clienteTipoId?: number;
    clienteTipo?: ClienteTipo;
    cuentaDeVentaId?: number;
    cuentaDeVenta?: CuentaDeVenta;
    monedaId?: number;
    moneda?: Moneda;
    condicionDePagoId?: number;
    condicionDePago?: CondicionDePago;
    formaDePagoId?: number;
    formaDePago?: FormaDePago;
    listaDePrecioId: number;
    listaDePrecio?: ListaDePrecio;
    listaDePrecio2Id: number;
    listaDePrecio2?: ListaDePrecio;
    usoCfdiId: number;
    usoCfdi?: UsoCFDI;
    regimenFiscalId: number;
    regimenFiscal?: cRegimenFiscal;
    metodoDepagoId?: number;
    metodoDepago?: MetodoPago;
    vendedorId: number;
    vendedor?: Usuario;
    limiteDeCredito: number;
    codigoContable?: string;
    centroCosto?: string;
    correoParaEnvioComprobantes?: string;
    direccionId: number;
    direccion: Direccion;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    descuentoVentas: number;
}

export class ClienteFormValues implements ClienteFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    rfc: string = "";
    curp: string = "";
    clienteTipoId: number = 0;
    clienteTipo?: ClienteTipo;
    cuentaDeVentaId: number = 0;
    cuentaDeVenta?: CuentaDeVenta;
    monedaId: number = 0;
    moneda?: Moneda;
    condicionDePagoId?: number = 0;
    condicionDePago?: CondicionDePago;
    formaDePagoId?: number = 0;
    formaDePago?: FormaDePago;
    listaDePrecioId: number = 0;
    listaDePrecio?: ListaDePrecio;
    listaDePrecio2Id: number = 0;
    listaDePrecio2?: ListaDePrecio;
    usoCfdiId: number = 0;
    usoCfdi?: UsoCFDI;
    regimenFiscalId: number = 0;
    regimenFiscal?: cRegimenFiscal;
    metodoDepagoId: number = 0;
    metodoDepago?: MetodoPago;
    vendedorId: number = 0;
    vendedor?: Usuario;
    limiteDeCredito: number = 0;
    codigoContable?: string = "";
    centroCosto?: string = "";
    correoParaEnvioComprobantes?: string = "";
    direccionId: number = 0;
    direccion: Direccion = new DireccionValues();
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    descuentoVentas: number = 0;
    constructor(init?: Cliente) {
        Object.assign(this, init);
    }
}