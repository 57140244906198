import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pac } from "../../models/configuracion/Pac";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class PacStore {
  pacRegistry = new Map<number, Pac>();
  selectedPac: Pac | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get pacById() {
    return Array.from(this.pacRegistry.values()).sort((a, b) => a.clave - b.clave);
  }
  get PacsByDropdown() {
    let PacsArreg = [new TipoDropDownValues()];
    let pacsOpt = new TipoDropDownValues();
    const emps = Array.from(this.pacRegistry.values()).sort((a, b) => {
      if (a.nombre > b.nombre) return 1;
      if (a.nombre < b.nombre) return -1;
      return 0;
    });
    emps.forEach((element) => {
      pacsOpt.key = element.id;
      pacsOpt.text = element.nombre;
      pacsOpt.value = element.id;
      pacsOpt.content = element.nombre;
      PacsArreg.push(pacsOpt);
      pacsOpt = new TipoDropDownValues();
    });
    return PacsArreg;
  }
  loadPacs = async () => {
    this.setLoadingInitial(true);
    try {
      const pacs = await agent.Pacs.list();
      pacs.forEach((pac) => {
        this.setPac(pac);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getPacs = async () => {
    this.setLoadingInitial(true);
    const pacs = await agent.Pacs.list();
    try {
      pacs.forEach((pac) => {
        this.setPac(pac);
      });
      this.setLoadingInitial(false);
      return pacs;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return pacs;
    }
  };

  loadPac = async (id: number) => {
    let pac = this.getPac(id);
    if (pac) {
      this.selectedPac = pac;
      return pac;
    } else {
      this.loadingInitial = true;
      try {
        pac = await agent.Pacs.details(id);
        this.setPac(pac);
        this.selectedPac = pac;
        this.setLoadingInitial(false);
        return pac;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setPac = (pac: Pac) => {
    this.pacRegistry.set(pac.id, pac);
  };

  private getPac = (id: number) => {
    return this.pacRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createPac = async (pac: Pac) => {
    this.loading = true;
    try {
      await agent.Pacs.create(pac);
      runInAction(() => {
        this.selectedPac = pac;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Pac creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updatePac = async (pac: Pac) => {
    this.loading = true;
    try {
      
      await agent.Pacs.update(pac);
      runInAction(() => {
        this.selectedPac = pac;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Pac modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePac = async (id: number) => {
    this.loading = true;
    try {
      await agent.Pacs.delete(id);
      runInAction(() => {
        this.pacRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Pac eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar moneda");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
