import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { PersonalTipo } from "../../models/gestionFlotilla/PersonalTipo";

export default class PersonalTipoStore {
    personalTipoRegistry = new Map<number, PersonalTipo>();
    selectedPersonalTipo: PersonalTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get personalTiposByDropdown() {
        debugger
        let personalTiposArreg = [new TipoDropDownValues()];
        let personalTiposOpt = new TipoDropDownValues();
        const cvts = Array.from(this.personalTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            personalTiposOpt.key = element.id;
            personalTiposOpt.text = element.nombre;
            personalTiposOpt.value = element.id;
            personalTiposOpt.content = element.nombre;
            personalTiposArreg.push(personalTiposOpt);
            personalTiposOpt = new TipoDropDownValues();

        });
        return personalTiposArreg;
    }
    get tipoVentaTiposByDate() {
        return Array.from(this.personalTipoRegistry.values()).sort((a) => a.id);
    }


    loadPersonalTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const personalTipos = await agent.PersonalTipos.list();

            personalTipos.forEach(personalTipo => {
                this.setPersonalTipo(personalTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getPersonalTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        const personalTipos = await agent.PersonalTipos.list();
        try {
            personalTipos.forEach(personalTipo => {
                this.setPersonalTipo(personalTipo);
            })
            this.setLoadingInitial(false);
            return personalTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return personalTipos;
        }
    }

    loadPersonalTipo = async (id: number) => {
        let personalTipo = this.getPersonalTipo(id);
        if (personalTipo) {
            this.selectedPersonalTipo = personalTipo;
            return personalTipo;
        } else {
            this.loadingInitial = true;
            try {
                personalTipo = await agent.PersonalTipos.details(id);
                this.setPersonalTipo(personalTipo);
                this.selectedPersonalTipo = personalTipo;
                this.setLoadingInitial(false);
                return personalTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setPersonalTipo = (personalTipo: PersonalTipo) => {
        this.personalTipoRegistry.set(personalTipo.id, personalTipo);
    }

    private getPersonalTipo = (id: number) => {
        return this.personalTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createPersonalTipo = async (personalTipo: PersonalTipo) => {
        this.loading = true;
        try {
            await agent.TipoVentaTipos.create(personalTipo);
            runInAction(() => {
                this.personalTipoRegistry.set(personalTipo.id, personalTipo);
                this.selectedPersonalTipo = personalTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   
    deletePersonalTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.PersonalTipos.delete(id);
            runInAction(() => {
                this.personalTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
