import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeCliente } from "../../models/gestionCliente/CuentaDeCliente";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CuentaClienteStore {
    cuentaDeClienteRegistry = new Map<number, CuentaDeCliente>();
    selectedCuentaDeCliente: CuentaDeCliente | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentaClienteByDropdown() {
        return entityByDropdown<CuentaDeCliente>(Array.from(this.cuentaDeClienteRegistry.values()));
    }
    get cuentaClienteByDropdownByClave() {
        return entityByDropdown<CuentaDeCliente>(Array.from(this.cuentaDeClienteRegistry.values()), true);
    }
    get cuentasClientesByDate() {
        return Array.from(this.cuentaDeClienteRegistry.values()).sort((a) => a.id);
    }

    get cuentaDeClienteById() {
        return Array.from(this.cuentaDeClienteRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get cuentasClientesByDropdown() {
        let CuentaClienteArreg = [new TipoDropDownValues()];
        let cuentaClienteOpt = new TipoDropDownValues();
        const cvts = Array.from(this.cuentaDeClienteRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            cuentaClienteOpt.key = element.id;
            cuentaClienteOpt.text = element.nombre;
            cuentaClienteOpt.value = element.id;
            cuentaClienteOpt.content = element.nombre;
            CuentaClienteArreg.push(cuentaClienteOpt);
            cuentaClienteOpt = new TipoDropDownValues();

        });
        return CuentaClienteArreg;
    }

    getCuentasClientes = async () => {
        this.setLoadingInitial(true);
        const cuentasClientes = await agent.CuentasDeClientes.list();
        try {
            cuentasClientes.forEach(cuentaCliente => {
                this.setCuentaDeCliente(cuentaCliente);
            })
            this.setLoadingInitial(false);
            return cuentasClientes;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasClientes;
        }
    }

    loadCuentasClientes = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasClientes = await agent.CuentasDeClientes.list();
            cuentasClientes.forEach(cuentaDeCliente => {
                setDate<CuentaDeCliente>(cuentaDeCliente);
                this.setCuentaDeCliente(cuentaDeCliente);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCuentaDeCliente = async (id: number) => {
        let cuentaDeCliente = this.getCuentaDeCliente(id);
        if (cuentaDeCliente) {
            setDate<CuentaDeCliente>(cuentaDeCliente);
            this.selectedCuentaDeCliente = cuentaDeCliente;
            return cuentaDeCliente;
        } else {
            this.loadingInitial = true;
            try {
                cuentaDeCliente = await agent.CuentasDeClientes.details(id);
                this.setCuentaDeCliente(cuentaDeCliente);
                this.selectedCuentaDeCliente = cuentaDeCliente;
                this.setLoadingInitial(false);
                return cuentaDeCliente;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeCliente = (cuentaDeCliente: CuentaDeCliente) => {
        this.cuentaDeClienteRegistry.set(cuentaDeCliente.id, cuentaDeCliente);
    }

    private getCuentaDeCliente = (id: number) => {
        return this.cuentaDeClienteRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeCliente = async (cuentaDeCliente: CuentaDeCliente) => {
        this.loading = true;
        try {
            await agent.CuentasDeClientes.create(cuentaDeCliente);
            runInAction(() => {
                this.selectedCuentaDeCliente = cuentaDeCliente;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Cliente creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeCliente = async (cuentaDeCliente: CuentaDeCliente) => {
        this.loading = true;
        try {
            await agent.CuentasDeClientes.update(cuentaDeCliente);
            runInAction(() => {
                this.selectedCuentaDeCliente = cuentaDeCliente;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Cliente editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeCliente = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeClientes.delete(id);
            runInAction(() => {
                this.cuentaDeClienteRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Cuenta de cliente eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar la cuenta de cliente");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
