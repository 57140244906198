export interface TipoDropdown {
  key: number;
  text: string;
  value: number;
  content?: string;
}

export class TipoDropDownValues implements TipoDropdown {
  key: number = 0;
  text: string = "Seleccionar";
  value: number = 0;
  content: string = "Seleccionar";
  constructor(init?: TipoDropdown) {
    Object.assign(this, init);
  }
}
