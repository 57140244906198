import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoVenta } from "../../models/venta/TipoVenta";
import { toast } from "react-toastify";
import { history } from "../../..";
import { entity2ByDropdown, entityByDropdown } from "../../common/funciones/entityByDropdown";
import { setDate } from "../../common/util/util";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";

export default class TipoVentaStore {
    tipoVentaRegistry = new Map<number, TipoVenta>();
    selectedTipoVenta: TipoVenta | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    tipoVentaParams: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this)
    }

    get tipoVentaById() {
        return Array.from(this.tipoVentaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get tiposVentasByDate() {
        return Array.from(this.tipoVentaRegistry.values()).sort((a) => a.id);
    }

    get TiposVentasByDropdown() {
        return entityByDropdown<TipoVenta>(Array.from(this.tipoVentaRegistry.values()));
    }
    get TiposVentasByDropdownByClave() {
        return entityByDropdown<TipoVenta>(Array.from(this.tipoVentaRegistry.values()),true);
    }
    fetchsTiposVentas = async () => {
        try {
          const response = await agent.TiposVentas.list();
          return response;
        } catch (error) {
          console.error("Error al buscar Empresa", error);
        }
      };
    loadTiposVentas = async () => {
        this.setLoadingInitial(true);
        try {
            const tiposVentas = await agent.TiposVentas.list();
            tiposVentas.forEach(tipoVenta => {
                setDate<TipoVenta>(tipoVenta);
                this.setTipoVenta(tipoVenta);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    loadTiposVentasByParams = async (filtros: PaginationSpecParams) => {
        try {
            const tiposVentas = await agent.TiposVentas.getTipoVentaByParams(filtros);
            // const tipo = tiposVentas ? tiposVentas?.filter((tipos) => {
            //     return (tipos.tipoVentaTipoId === filtros?.tipoVentaTipoId)
            // }) : [];
            return entityByDropdown<TipoVenta>(Array.from(tiposVentas));
        } catch (error) {
            toast.error("Problema al cargar Tipo venta");
            console.log("error", error);
            // return;
        }
    }

    getTiposVentas = async () => {
        this.setLoadingInitial(true);
        const tiposVentas = await agent.TiposVentas.list();
        try {

            tiposVentas.forEach(tipoVenta => {
                setDate<TipoVenta>(tipoVenta);
                this.setTipoVenta(tipoVenta);
            })
            this.setLoadingInitial(false);
            return tiposVentas;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return tiposVentas;
        }
    }
    loadTipoVentaByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
        try {
            const TipoVenta = await agent.TiposVentas.listByParams(filtros, options);
            return entityByDropdown<TipoVenta>(Array.from(TipoVenta));
        } catch (error) {
            console.error("error", error);
        }
    }
    loadTipoVenta = async (id: number) => {
        let tipoVenta = this.getTipoVenta(id);
        if (tipoVenta) {
            this.selectedTipoVenta = tipoVenta;
            return tipoVenta;
        } else {
            this.loadingInitial = true;
            try {
                tipoVenta = await agent.TiposVentas.details(id);
                setDate<TipoVenta>(tipoVenta);
                this.setTipoVenta(tipoVenta);
                this.selectedTipoVenta = tipoVenta;
                this.setLoadingInitial(false);
                return tipoVenta;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setTipoVenta = (tipoVenta: TipoVenta) => {
        this.tipoVentaRegistry.set(tipoVenta.id, tipoVenta);
    }

    public getTipoVenta = (id: number): TipoVenta | undefined => {
        return this.tipoVentaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoVenta = async (tipoVenta: TipoVenta) => {
        this.loading = true;
        try {
            await agent.TiposVentas.create(tipoVenta);

            runInAction(() => {
                this.selectedTipoVenta = tipoVenta;
                this.editMode = false;
                this.loading = false;
            })

            
            toast.success("Tipo de Venta creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateTipoVenta = async (tipoVenta: TipoVenta) => {
        this.loading = true;
        try {

            tipoVenta.tipoVentaTipo = undefined;
            tipoVenta.almacen = undefined;
            tipoVenta.conceptoDeInventario = undefined;
            tipoVenta.cuentaDeCliente = undefined;
            tipoVenta.conceptoDeCliente = undefined;
            await agent.TiposVentas.update(tipoVenta);
            runInAction(() => {
                this.selectedTipoVenta = tipoVenta;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Tipo Venta Actualizada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteTipoVenta = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposVentas.delete(id);
            runInAction(() => {
                this.tipoVentaRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Tipo Venta eliminada con éxito");

        } catch (error) {
            toast.error("Problema al eliminar Tipo Venta");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
