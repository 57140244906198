import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { history } from "../../..";
import agent from "../../api/agent";
import { ConceptoCliente } from "../../models/gestionCliente/ConceptoCliente";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ConceptoClienteStore {
  conceptoClienteRegistry = new Map<number, ConceptoCliente>();
  selectedConceptoCliente: ConceptoCliente | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }
  get conceptosClientesByDropdown() {
    return entityByDropdown<ConceptoCliente>(Array.from(this.conceptoClienteRegistry.values()));
  }

  get conceptosClientesById() {
    return Array.from(this.conceptoClienteRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  loadConceptosClientes = async () => {
    this.setLoadingInitial(true);
    try {
      const conceptosClientes = await agent.ConceptosClientes.list();
      conceptosClientes.forEach((conceptoCliente) => {
        setDate<ConceptoCliente>(conceptoCliente);
        this.setConceptoCliente(conceptoCliente);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getConceptosClientes = async () => {
    this.setLoadingInitial(true);
    const conceptosClientes = await agent.ConceptosClientes.list();
    try {
      conceptosClientes.forEach((conceptoCliente) => {
        setDate<ConceptoCliente>(conceptoCliente);
        this.setConceptoCliente(conceptoCliente);
      });
      this.setLoadingInitial(false);
      return conceptosClientes;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return conceptosClientes;
    }
  };

  loadConceptoCliente = async (id: number) => {
    let conceptoCliente = this.getConceptoCliente(id);
    if (conceptoCliente) {
      this.selectedConceptoCliente = conceptoCliente;
      return conceptoCliente;
    } else {
      this.loadingInitial = true;
      try {
        conceptoCliente = await agent.ConceptosClientes.details(id);
        setDate<ConceptoCliente>(conceptoCliente);
        this.setConceptoCliente(conceptoCliente);
        this.selectedConceptoCliente = conceptoCliente;
        this.setLoadingInitial(false);
        return conceptoCliente;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setConceptoCliente = (conceptoCliente: ConceptoCliente) => {
    this.conceptoClienteRegistry.set(conceptoCliente.id, conceptoCliente);
  };

  private getConceptoCliente = (id: number) => {
    return this.conceptoClienteRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createConceptoCliente = async (conceptoCliente: ConceptoCliente) => {
    this.loading = true;
    try {
      await agent.ConceptosClientes.create(conceptoCliente);
      runInAction(() => {
        this.selectedConceptoCliente = conceptoCliente;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Concepto cliente creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateConceptoCliente = async (conceptoCliente: ConceptoCliente) => {
    this.loading = true;
    try {
      await agent.ConceptosClientes.update(conceptoCliente);
      runInAction(() => {
        this.selectedConceptoCliente = conceptoCliente;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Concepto cliente modificado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteConceptoCliente = async (id: number) => {
    this.loading = true;
    try {
      await agent.ConceptosClientes.delete(id);
      runInAction(() => {
        this.conceptoClienteRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Concepto cliente eliminado con éxito");
    } catch (error) {
      toast.error("Problema al eliminar moneda");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
