import React, { useState } from 'react'
import { Button, Header, Image, Segment } from 'semantic-ui-react'
import { Formik, Form } from "formik";
import MyTextInput from '../../app/common/form/MyTextInput';
import { LoginFormValues } from '../../app/models/account/Login';
import * as Yup from "yup";
import agent from '../../app/api/agent';
import { router } from '../../app/router/Routers';
import { useAuth } from '../../app/router/AuthContext';
import { User } from '../../app/models/types';

export default function Login() {
    const { login } = useAuth();
    const validationSchema = Yup.object({
        username: Yup.string().required("El username es requerido"),
        password: Yup.string().required("El password es requerido.")
    });

    async function  handleSubmit(values: LoginFormValues) {
        const userVar = await agent.Account.login(values) as User;
        console.log({ userVar })
        const user = {
            id: userVar.id,
            userName: userVar.userName,
            email: userVar.email,
            token: userVar.token,
          };

        login(userVar);
        
        router.navigate("/home");
    }

    const [values, setValues] = useState(new LoginFormValues());

    return ( 
        <Segment clearing className='ui one column stackable centered page grid'>
            <Segment >
                <Header as='h2' textAlign='center'>
                    <Image src='/assets/images/logo.png' />Bienvenido
                </Header>
                <Formik className="ui form"
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    enableReinitialize
                    initialValues={values}
                >
                    {({ setValues, values }) => (
                        <Form className="ui form">
                            <Segment stacked>
                                <MyTextInput
                                    label='username'
                                    name='username'
                                    placeholder='username'
                                    tabIndex={1}
                                />
                                <MyTextInput
                                    label='Password'
                                    name='password'
                                    placeholder='Password'
                                    type='password'
                                    tabIndex={2}
                                />

                                <Segment stacked>
                                    <Button type="submit" tabIndex={3} className='primary' fluid size='large' >
                                        Login
                                    </Button>
                                </Segment>
                            </Segment>
                        </Form>

                    )}
                </Formik>
            </Segment>
        </Segment>
    )
}