import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeCompra } from "../../models/compra/CuentaDeCompra";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CuentaDeCompraStore {
    cuentaDeCompraRegistry = new Map<number, CuentaDeCompra>();
    selectedCuentaDeCompra: CuentaDeCompra | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentasDeComprasByDropdown() {
        return entityByDropdown<CuentaDeCompra>(Array.from(this.cuentaDeCompraRegistry.values()));
    }


    get cuentaDeCompraById() {
        return Array.from(this.cuentaDeCompraRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        )
    }
    get cuentasDeComprasByDate() {
        return Array.from(this.cuentaDeCompraRegistry.values()).sort((a) => a.id);
    }

    fetchsCuentasDeCompras = async () => {
        try {
            const response = await agent.CuentasDeCompras.list();
            return response;
        } catch (error) {
            console.error("Error al buscar Tipo de Cuentas De Compras", error);
        }
    };

    loadCuentasDeCompras = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasCompras = await agent.CuentasDeCompras.list();
            cuentasCompras.forEach(cuentaDeCompra => {
                setDate<CuentaDeCompra>(cuentaDeCompra);
                this.setCuentaDeCompra(cuentaDeCompra);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasCompras = async () => {
        this.setLoadingInitial(true);
        const cuentasCompras = await agent.CuentasDeCompras.list();
        try {
            cuentasCompras.forEach(cuentaDeCompra => {
                this.setCuentaDeCompra(cuentaDeCompra);
            })
            this.setLoadingInitial(false);
            return cuentasCompras;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasCompras;
        }
    };

    loadCuentaDeCompra = async (id: number) => {
        let cuentaDeCompra = this.getCuentaDeCompra(id);
        if (cuentaDeCompra) {
            setDate<CuentaDeCompra>(cuentaDeCompra);
            this.selectedCuentaDeCompra = cuentaDeCompra;
            return cuentaDeCompra;
        } else {
            this.loadingInitial = true;
            try {
                cuentaDeCompra = await agent.CuentasDeCompras.details(id);
                this.setCuentaDeCompra(cuentaDeCompra);
                this.selectedCuentaDeCompra = cuentaDeCompra;
                this.setLoadingInitial(false);
                return cuentaDeCompra;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeCompra = (cuentaDeCompra: CuentaDeCompra) => {
        this.cuentaDeCompraRegistry.set(cuentaDeCompra.id, cuentaDeCompra);
    };

    private getCuentaDeCompra = (id: number) => {
        return this.cuentaDeCompraRegistry.get(id);
    };

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    };

    createCuentaDeCompra = async (cuentaDeCompra: CuentaDeCompra) => {
        this.loading = true;
        try {
            await agent.CuentasDeCompras.create(cuentaDeCompra);
            runInAction(() => {
                this.selectedCuentaDeCompra = cuentaDeCompra;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Cuenta de compra creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeCompra = async (cuentaDeCompra: CuentaDeCompra) => {
        this.loading = true;
        try {
            await agent.CuentasDeCompras.update(cuentaDeCompra);
            runInAction(() => {
                this.selectedCuentaDeCompra = cuentaDeCompra;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Cuenta de compra modificada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeCompra = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeCompras.delete(id);
            runInAction(() => {
                this.cuentaDeCompraRegistry.delete(id);
                this.loading = false;
            });
            toast.success("Cuenta de compra eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar cuenta de compra");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
