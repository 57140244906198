import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { CuentaDeClienteTipo } from "./CuentaDeClienteTipo";

export interface CuentaDeCliente {
    id: number;
    clave: number;
    nombre: string;
    abreviacion: string;
    cuentaDeClienteTipoId: number;
    cuentaDeClienteTipo?: CuentaDeClienteTipo;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export interface ICuentaDeClienteFormValues extends Partial<CuentaDeCliente> {
    cuentaDeClienteTipoId: any;
}

export class CuentaDeClienteFormValues implements ICuentaDeClienteFormValues {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    abreviacion:string="";
    cuentaDeClienteTipoId:number= 0;
    cuentaDeClienteTipo?:CuentaDeClienteTipo;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    constructor(init?: CuentaDeCliente) {
        Object.assign(this, init);
    }
}