import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa, IFile } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoVenta } from "./TipoVenta";
import { SerieFolioTipo } from "./SerieFolioTipo";


export interface SerieFolioDeVenta {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoVentaId: number;
    tipoVenta?: TipoVenta;
    clave: string;
    nombre: string;
    serieFolioDeVentaTipoId: number;
    serieFolioDeVentaTipo?: SerieFolioTipo;
    ultimoFolio: number;
    
    fileLogo?:IFile;
    fileLogoId:number;
    logo?: null;

    fileLogoTicket?:IFile;
    fileLogoTicketId:number;
    logoTicket?: null;
    
    lemaEmpresa: string
    codigoContable?: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;


}
export interface ISerieFolioFormValues extends Partial<SerieFolioDeVenta> {
    selectedIdEmpresa: any;

}
export class SerieFolioFormValues implements ISerieFolioFormValues {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoVentaId: number = 0;
    tipoVenta?: TipoVenta;
    clave: string = '';
    nombre: string = '';
    serieFolioDeVentaTipoId: number =0;
    serieFolioDeVentaTipo?: SerieFolioTipo;
    ultimoFolio: number = 0;
    fileLogo?:IFile;
    fileLogoId:number=0;
    logo?: null;
    fileLogoTicket?:IFile;
    fileLogoTicketId:number=0;
    logoTicket?: null;
    fileFormato?:IFile;
    fileFormatoId:number=0;
    formato?: null;
    lemaEmpresa: string = '';
    codigoContable: string = '';
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    constructor(init?: SerieFolioDeVenta) {
        Object.assign(this, init);

    }
}


