import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Segment, Header } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { GeneralFormValues } from "../../../../app/models/configuracion/General";
import GeneralesHeader from "./GeneralesHeader";
import GenealesBody from "./GenealesBody";
import GeneralesFooter from "./GeneralesFooter";
export default observer(function GeneralesForm() {
  const firstElement = 0;
  const lastElement = 9;
  const { generalStore } = useStore();
  const { createGeneral, loadingInitial } = generalStore
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),
      
    
  });
  const initialValues = {
    empresaId: '',
    sucursalId: '',
    generales: new GeneralFormValues() // Este objeto se llenará con los datos de la API
  };
  const handleSubmit = (generalForm: any) => {
    const generalf = { ...generalForm.generales };
    generalf.empresaId = generalForm.empresaId;
    generalf.sucursalId = generalForm.sucursalId;
    createGeneral(generalf).then(() => { });
  }

  const tabuladorEnterDom = (tabIndex: number) => {
    enterInputs(tabIndex, firstElement, lastElement + 3);
  }
  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ handleSubmit }) => (
          <Form className='ui form' onSubmit={handleSubmit}>
            <Header as='h2' icon='compass' content='Configuraciones Generales' color='blue' />
            <GeneralesHeader tabuladorEnterDom={tabuladorEnterDom} />
            <GenealesBody tabuladorEnterDom={tabuladorEnterDom} />
            <GeneralesFooter lastElement={lastElement} />
          </Form>
        )}
      </Formik>
    </Segment>
  )
})