import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CategoriaProducto } from "../../models/inventario/CategoriaProducto";
import { entity2ByDropdown } from "../../common/funciones/entityByDropdown";
//import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CategoriaProductoStore {
  categoriaProductoRegistry = new Map<number, CategoriaProducto>();
  selectedCategoriaProducto: CategoriaProducto | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get CategoriasProductosByDate() {
    return Array.from(this.categoriaProductoRegistry.values()).sort(
      (a) => a.id
    );
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }
  get categoriaByDropdown() {
    return entity2ByDropdown<CategoriaProducto>(
      Array.from(this.categoriaProductoRegistry.values())
    );
  }

  loadCategoriasProductos = async () => {
    this.setLoadingInitial(true);
    try {
      const categoriasProductos = await agent.CategoriasProductos.list();
      categoriasProductos.forEach((categoriaProducto) => {
        this.setCategoriaProducto(categoriaProducto);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getCategoriasProductos = async () => {
    this.setLoadingInitial(true);
    const categoriasProductos = await agent.CategoriasProductos.list();
    try {
      categoriasProductos.forEach((categoriaProducto) => {
        this.setCategoriaProducto(categoriaProducto);
      });
      this.setLoadingInitial(false);
      return categoriasProductos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return categoriasProductos;
    }
  };

  loadCategoriaProducto = async (id: number) => {
    let categoriaProducto = this.getCategoriaProducto(id);
    if (categoriaProducto) {
      this.selectedCategoriaProducto = categoriaProducto;
      return categoriaProducto;
    } else {
      this.loadingInitial = true;
      try {
        categoriaProducto = await agent.CategoriasProductos.details(id);
        this.setCategoriaProducto(categoriaProducto);
        this.selectedCategoriaProducto = categoriaProducto;
        this.setLoadingInitial(false);
        return categoriaProducto;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setCategoriaProducto = (categoriaProducto: CategoriaProducto) => {
    this.categoriaProductoRegistry.set(categoriaProducto.id, categoriaProducto);
  };

  private getCategoriaProducto = (id: number) => {
    return this.categoriaProductoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createCategoriaProducto = async (categoriaProducto: CategoriaProducto) => {
    this.loading = true;
    try {
      await agent.CategoriasProductos.create(categoriaProducto);
      runInAction(() => {
        this.categoriaProductoRegistry.set(
          categoriaProducto.id,
          categoriaProducto
        );
        this.selectedCategoriaProducto = categoriaProducto;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  

  deleteCategoriaProducto = async (id: number) => {
    this.loading = true;
    try {
      await agent.CategoriasProductos.delete(id);
      runInAction(() => {
        this.categoriaProductoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
