import { useEffect } from 'react'
import { Formik, Form } from 'formik';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import MyTextArea from '../../../../app/common/form/MyTextArea';


interface Props {
  comentario: string;
  closeModals: () => void;
  setComentario: (setFO: string) => void;
  tabIndex: number;
}

export default function ComentarioComprobante(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  useEffect(() => {
  }, [])


  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: string) {

    props.setComentario(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }


  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values.comentario);
      }}
      enableReinitialize
      initialValues={{
        comentario: props.comentario,
      }}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">

          
            <Segment>
              <MyTextArea placeholder='Descripcion adicional' name="comentario" />
            </Segment>
          

          <Segment>
            <Button.Group>

              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit">
                <Icon name="save" />
                Aceptar
              </Button>


              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton} >
                <Icon name="backward" />
                Cancelar
              </Button>

            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
}