import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import CuentaVentaForm from '../form/CuentaVentaForm';

export default observer(function CuentaVentaList() {
  ///modal
  const { modalStore, tabsStore} = useStore();
  const { openModal, closeModal } = modalStore;
  //final modal
  const { cuentaDeVentaStore } = useStore();
  const { deleteCuentaDeVenta, cuentasDeVentasByDate, loading } = cuentaDeVentaStore;
  const [target, setTarget] = useState('');

  function deleteConfirmed(e: string, id: number) {
    deleteCuentaDeVenta(id);
    setTarget(e);
    closeModal();
  }
  function deletedeclined() {
    closeModal();
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {cuentasDeVentasByDate.map(cuentaDeVenta => (
          <Table.Row key={cuentaDeVenta.clave}>
            <Table.Cell>{cuentaDeVenta.clave}</Table.Cell>
            <Table.Cell>{cuentaDeVenta.nombre}</Table.Cell>
            <Table.Cell>{cuentaDeVenta.cuentaDeVentaTipo?.nombre}</Table.Cell>
            <Table.Cell>

              <Button onClick={() => tabsStore.addTab('Crear Cuentas de Ventas', <CuentaVentaForm id={cuentaDeVenta.id} />)}
                floated='right' content='Editar' color='blue' />
              <Button
                name={cuentaDeVenta.id}
                loading={loading && target === String(cuentaDeVenta.id)}
                onClick={(e) =>
                  openModal(
                    <DeleteModals
                      deleteConfirmed={deleteConfirmed}
                      deletedeclined={deletedeclined}
                      e={e.currentTarget.name.toString()}
                      id={cuentaDeVenta.id}
                      nombre={cuentaDeVenta.nombre}
                    />,
                    enumHeaderModals.cuentaDeVenta
                  )
                }
                floated='right'
                content='Eliminar'
                color='red'
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})