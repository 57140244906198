import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import SerieFolioCompraForm from "../form/SerieFolioCompraForm";

export default observer(function TipoCompraList() {
    const { modalStore, tabsStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { serieFolioCompraStore } = useStore();
    const { deleteSerieFolioCompra, seriesFoliosComprasById, loading } = serieFolioCompraStore;
    const [target, setTarget] = useState("");

    function deleteConfirmed(e: string, id: number) {
        deleteSerieFolioCompra(id);
        setTarget(e);
        closeModal();
    }

    function deletedeclined() {
        closeModal();
    }

    return (
        <Segment>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Empresa</Table.HeaderCell>
                        <Table.HeaderCell>Sucursal</Table.HeaderCell>
                        <Table.HeaderCell>Tipos de Compra</Table.HeaderCell>
                        <Table.HeaderCell>Clave</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {seriesFoliosComprasById.map((serieFolioCompra) => (
                        <Table.Row key={serieFolioCompra.id}>
                            <Table.Cell>{serieFolioCompra.empresa?.clave}</Table.Cell>
                            <Table.Cell>{serieFolioCompra.sucursal?.clave}</Table.Cell>
                            <Table.Cell>{serieFolioCompra.tipoCompra?.clave}</Table.Cell>
                            <Table.Cell>{serieFolioCompra.clave}</Table.Cell>
                            <Table.Cell>{serieFolioCompra.nombre}</Table.Cell>
                            <Table.Cell>
                                <Button onClick={() => tabsStore.addTab('Crear Serie y Folio', <SerieFolioCompraForm id={serieFolioCompra.id} />)}
                                    floated="right" content="Editar" color="blue" />
                                <Button
                                    name={serieFolioCompra.id}
                                    disabled={loading && target === String(serieFolioCompra.id)}
                                    loading={loading && target === String(serieFolioCompra.id)}
                                    onClick={(e) =>
                                        openModal(
                                            <DeleteModals
                                                deleteConfirmed={deleteConfirmed}
                                                deletedeclined={deletedeclined}
                                                e={e.currentTarget.name.toString()}
                                                id={serieFolioCompra.id}
                                                nombre={serieFolioCompra.nombre}
                                            />,
                                            enumHeaderModals.Moneda
                                        )
                                    }
                                    floated="right"
                                    content="Eliminar"
                                    color="red"
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
});