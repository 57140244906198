import { makeAutoObservable, runInAction } from 'mobx';
import agent, { urlApi } from '../../api/agent';
import { history } from '../../..';
import { toast } from 'react-toastify';
import { PaginationSpecParams } from '../../models/Especificaciones/PaginationSpecParams';

export default class ReporteProductosVendidosStore {
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }
    imprimirReporteProductosVendidos = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            const namePdf = await agent.ReporteProductosVendidos.imprimirReporteProductosVendidos(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    const blob = new Blob([namePdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                    // Limpiar el objeto URL después de un tiempo
                    // setTimeout(() => {
                    //     URL.revokeObjectURL(url);
                    // }, 1000);
                }
                this.loading = false;
            });
        } catch (error) {
            // toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

}