import { UserContext } from "./UserContext"

const user = {
    id:1,
    name:'  Jose Oliver Burgos',
    email:'JoseOliver@gmail.com'
}
const empresaLog = {
    id:1,
    name:'  DEMOSTRACION S.A. DE C.V.',
    email:' ASOCIACIONFENIX@gmail.com'
}


export const UserProvider = ({ children }) => {
    return (
        <UserContext.Provider value={{hola:'Mundo',user,empresaLog}}>
            {children}
        </UserContext.Provider>
    )
}
