import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";
import { InventarioFisico } from "../../models/inventario/InventarioFisicoDetalle";

export default class InventarioFisicoStore {
  inventarioFisicoRegistry = new Map<number, InventarioFisico>();
  selectedInventarioFisico: InventarioFisico | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  inventarioFisicoFiltros: PaginationSpecParams = new PaginationFormValues();
  constructor() {
    makeAutoObservable(this);
  }

  get InventariosFisicosByDate() {
    return Array.from(this.inventarioFisicoRegistry.values()).sort((a) => a.id);
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }

  cargarInventarioFisico = async (filtros: PaginationSpecParams) => {
    try {

      const inventarioFisico = await agent.InventariosFisicos.obtenerMovimientoPorClave(filtros);
      if (inventarioFisico) {
        this.setInventarioFisico(inventarioFisico);
        //this.selectedGeneracionComprobante = generacionComprobante
        return inventarioFisico;
      }
      // else {
      //     this.loadingInitial = true;
      // }
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
    if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
      try {
        const inventarioFisico = await agent.InventariosFisicos.obtenerFolio(filtros);
        return inventarioFisico;

      } catch (error) {
        console.log(error);
      }
    } else {
      // this.serieFolioByTipoVentaRegistry.clear();
    }
  };

  loadInventariosFisicos = async () => {
    this.setLoadingInitial(true);
    try {
      const inventariosFisicos = await agent.InventariosFisicos.list();
      inventariosFisicos.forEach((inventarioFisico) => {
        this.setInventarioFisico(inventarioFisico);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get inventarioFisicoByDropdown() {
    let movInvArreg = [new TipoDropDownValues()];
    let movInvOpt = new TipoDropDownValues();
    const inventariosFisicos = Array.from(this.inventarioFisicoRegistry.values()).sort(
      (a, b) => {
        if (a.clave > b.clave) return 1;
        if (a.clave < b.clave) return -1;
        return 0;
      }
    );
    inventariosFisicos.forEach((element) => {
      movInvOpt.key = element.id;

      movInvOpt.value = element.id;

      movInvArreg.push(movInvOpt);
      movInvOpt = new TipoDropDownValues();
    });
    return movInvArreg;
  }

  getInventariosFisicos = async () => {
    this.setLoadingInitial(true);
    const inventariosFisicos = await agent.InventariosFisicos.list();
    try {
      inventariosFisicos.forEach((inventarioFisico) => {
        this.setInventarioFisico(inventarioFisico);
      });
      this.setLoadingInitial(false);
      return inventariosFisicos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return inventariosFisicos;
    }
  };

  loadInventarioFisico = async (id: number) => {
    let inventarioFisico = this.getInventarioFisico(id);
    if (inventarioFisico) {
      this.selectedInventarioFisico = inventarioFisico;
      return inventarioFisico;
    } else {
      this.loadingInitial = true;
      try {
        inventarioFisico = await agent.InventariosFisicos.details(id);
        this.setInventarioFisico(inventarioFisico);
        this.selectedInventarioFisico = inventarioFisico;
        this.setLoadingInitial(false);
        return inventarioFisico;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setInventarioFisico = (inventarioFisico: InventarioFisico) => {
    this.inventarioFisicoRegistry.set(inventarioFisico.id, inventarioFisico);
  };

  private getInventarioFisico = (id: number) => {
    return this.inventarioFisicoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createInventarioFisico = async (inventarioFisico: InventarioFisico) => {
    this.loading = true;
    try {
      await agent.InventariosFisicos.create(inventarioFisico);
      runInAction(() => {
        this.inventarioFisicoRegistry.set(inventarioFisico.id, inventarioFisico);
        this.selectedInventarioFisico = inventarioFisico;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Inventario Fisico creada con éxito");
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateInventarioFisico = async (inventarioFisico: InventarioFisico) => {
    this.loading = true;
    try {
      await agent.InventariosFisicos.update(inventarioFisico);
      runInAction(() => {
        this.inventarioFisicoRegistry.set(inventarioFisico.id, inventarioFisico);
        this.selectedInventarioFisico = inventarioFisico;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteInventarioFisico = async (id: number) => {
    this.loading = true;
    try {
      await agent.InventariosFisicos.delete(id);
      runInAction(() => {
        this.inventarioFisicoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
