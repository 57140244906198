import { useEffect, useState } from 'react';
import { Form, Button, Segment, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { TipoDropDownValues, TipoDropdown } from '../../../../app/models/configuracion/TipoDropdown';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { useStore } from '../../../../app/stores/store';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import MyTextArea from '../../../../app/common/form/MyTextArea';
import { EnviarCorreo, EnviarCorreoFormValues } from '../../../../app/models/EnviarCorreo';
//import MySelectInput from '../../../../app/common/form/MyDropdown';
//import MyTextInput from './MyTextInput'; // Asegúrate de importar tu componente MyTextInput

interface Props {
  closeModals: () => void;
  tabIndex: number;
  values: any;
  //  onCopy: (data: GeneracionComprobante) => void;
}

export default observer(function EnviarCorroModal(props: Props) {
  // const { values, setFieldValue, isValid, dirty } = useFormikContext<any>();
  const [correos, setCorreos] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { enviarCorreoStore } = useStore();
  const { loadCorreosByParamsDropdown, createEnviarCorreo, loading } = enviarCorreoStore
  const [initialValues, setInitialValues] = useState(new EnviarCorreoFormValues());

  useEffect(() => {
    const abortController = new AbortController();
    const loadCorreo = async () => {
      try {
        if (props.values?.empresaId > 0 && props.values?.sucursalId > 0 && props.values?.clienteId > 0) {
          const result = await loadCorreosByParamsDropdown(new PaginationFormValues({ generacionComprobanteId: props.values.id, clienteId: props.values.clienteId, empresaId: props.values.empresaId, sucursalId: props.values.sucursalId }), { signal: abortController.signal });
          if (!abortController.signal.aborted && result) {
            setInitialValues({ ...result, empresaId: props.values?.empresaId, sucursalId: props.values?.sucursalId, mensaje: result.mensaje ? result.mensaje : "" })
          }
        }
      } catch (error) {
        console.error("Failed to load series:", error);
      }
    };
    loadCorreo();
    return () => {
      abortController.abort();
    };
  }, []);




  const [errorButon, setErrorButon] = useState("");
  const validationSchema = Yup.object({
    para: Yup.string()
      .required('El campo es obligatorio')
      .test(
        'isValidEmails',
        'Uno o más correos electrónicos no son válidos',
        value => {
          if (!value) return true;
          const emails = value.split(/[,;]/).map(email => email.trim());
          return emails.every(email => Yup.string().email().isValidSync(email));
        }
      ),
    adjuntar: Yup.string().required("Adjuntar es requerido."),
    mensaje: Yup.string().required("El mensaje es requerido."),
  });
  // const [generacionComprobanteCot, setGeneracionComprobanteCot] = useState<GeneracionComprobante | null>(null);
  const firstElement = props.tabIndex;
  const lastElement = props.tabIndex + 6;


  const [copiarComprobanteParams, setCopiarComprobanteParams] = useState<PaginationSpecParams>(new PaginationFormValues());


  // useEffect(() => {
  //   return () => {
  //     generacionComprobanteStore.resetGeneracionComprobanteParams();
  //   };
  // }, []);
  // useEffect(() => {
  //   const fetchSucuarsalData = async () => {
  //     try {
  //       await loadSucursalByIdEmpresa(props.empresaId);
  //     } catch (error) {
  //       console.error('Error al obtener datos de la empresa:', error);
  //     }
  //   };
  //   fetchSucuarsalData();
  // }, [props.empresaId]);

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  const handleSubmit = (valuesEnviarCorreo: EnviarCorreo) => {
    createEnviarCorreo(valuesEnviarCorreo);
    //   if (generacionComprobanteCot) {
    //     props.onCopy(generacionComprobanteCot);
    props.closeModals();

  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  const handleTipoChange = async (idSelectedTipoVenta: number, setFieldAny: any) => {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      tipoVentaId: idSelectedTipoVenta,
    }));
    // setSerie([new TipoDropDownValues()]);
    setFieldAny("serieFolioDeVentaId", 0);
  }

  function idSelectSerie(idSelectedSerie: number) {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      serieId: idSelectedSerie,
    }));
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ handleSubmit, isValid, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className='col100'>
            <MyTextInput
              tabIndex={props.tabIndex + 1}
              autoFocus={true}
              placeholder='Para'
              label="Para"
              name="para"
              enterInput={enterInput}
            //onBlur={selectInputFolioOnBluer}
            />
          </div>
          <div className='col100'>
            <MyTextInput
              tabIndex={props.tabIndex + 2}
              placeholder='Asunto'
              label="Asunto"
              name="asunto"
              enterInput={enterInput}
            //onBlur={selectInputFolioOnBluer}
            />
          </div>
          <div className='col100'>
            <MyTextInput
              tabIndex={props.tabIndex + 3}
              placeholder='Adjuntar'
              label="Adjuntar"
              name="adjuntar"
              enterInput={enterInput}
              disabled
            //onBlur={selectInputFolioOnBluer}
            />
          </div>
          <div className='col100'>
            <MyTextArea
              tabIndex={props.tabIndex + 4}
              type='textarea'
              placeholder='Mensaje'
              label="Mensaje"
              name="mensaje"
              rows="4"
            // enterInput={enterInput}
            //onBlur={selectInputFolioOnBluer}
            />
          </div>
          <Segment>
            <Button.Group>
              <Button
                loading={loading}
                // disabled={ !isValid || loading}
                tabIndex={props.tabIndex + 5}
                type="submit"
              >
                <Icon name="save" />
                Enviar
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                tabIndex={props.tabIndex + 6}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Regresar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})