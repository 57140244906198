import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { Cliente, ClienteFormValues } from "../../../../app/models/gestionCliente/Cliente";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsDirecciones, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import Direccion from "../../../Component/Direccion";
import { DireccionValues } from "../../../../app/models/configuracion/Direccion";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import { OnChangeProps, OnKeyDownProps } from "../../../../app/models/myProps";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import BarraDireccion from "../../../Component/BarraDireccion";

interface idProps {
  id: number
}

export default observer(function ClienteForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 21;
  const { clienteStore, empresaStore, sucursalStore, clienteTipoStore, monedaStore, condicionPagoStore, formaPagoStore
    , listaDePrecioStore, cuentaDeVentaStore, metodoPagoStore, usoCFDIStore, modalStore, proveedorStore, usuarioStore
    , c_RegimenFiscalStore } = useStore();
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { ListaDePreciosByDropdown } = listaDePrecioStore
  const { cuentasDeVentasByDropdown } = cuentaDeVentaStore
  const { clientesTiposByDropdown } = clienteTipoStore
  const { condicionesPagosByDropdown } = condicionPagoStore;
  const { formasPagosByDropdown } = formaPagoStore;
  const { usosCFDIsByDropdown } = usoCFDIStore;
  const { regimenesFiscalesByDropdown } = c_RegimenFiscalStore
  const { metodosPagosByDropdown } = metodoPagoStore;
  const { usuariosByDropdown } = usuarioStore;
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown } = sucursalStore;
  const { MonedasByDropdown } = monedaStore
  const { empresaSucursal } = proveedorStore
  const { createCliente, updateCliente,
    loadCliente,
    loadingInitial,
    loading, obtenerSiguienteClave, cargarCliente
  } = clienteStore;
  const { openModal, closeModal } = modalStore;
  //const { id } = useParams<{ id: string }>();
  const [cliente, setCliente] = useState(new ClienteFormValues());
  const [empresas] = useState([{ key: '0', value: 'Sel', text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(new FechaObservacionValues());
  const [direccion, setDireccion] = useState(new DireccionValues());
  useEffect(() => {
    empresaStore.getEmpresas();
    sucursalStore.getSucursales();
    clienteTipoStore.getClienteTipos();
    monedaStore.getMonedas();
    condicionPagoStore.getCondicionesPagos();
    formaPagoStore.getFormasPagos();
    listaDePrecioStore.getListasDePrecios();
    cuentaDeVentaStore.getCuentasDeVentas();
    metodoPagoStore.getMetodosPagos();
    usoCFDIStore.getUsosCFDIs();
    c_RegimenFiscalStore.getRegimenesFiscales();
    usuarioStore.getUsuarios();
    void loadEntityEmpresas();
  }, [])

  useEffect(() => {
    if (id) {
      loadCliente(id).then(cliente => {
        setCliente(new ClienteFormValues(cliente))
        setFechaObservacion(new FechaObservacionValues(cliente?.fechaObservacion));
        setDireccion(new DireccionValues(cliente?.direccion));
        if (cliente)
          void loadSucursalByIdEmpresa(cliente.empresaId);
      }
      );
    }
  }, [])

  const validationSchema = Yup.object({
    empresaId: Yup.number().min(1, "Favor de seleccionar la Empresa.").required("La Empresa es requerida."),
    sucursalId: Yup.number().min(1, "Favor de seleccionar la Sucursal.").required("La Sucursal es requerida."),
    clave: Yup.number().min(1, "Clave no puede ser 0.").required('La clave del tipo de venta es requerida.'),
    nombre: Yup.string().required('El nombre del cliente es requerido.'),
    rfc: Yup.string().required('El RFC es requerido.'),
    clienteTipoId: Yup.number().min(1, "Favor de seleccionar un Tipo de Cliente.").required("El tipo de Cliente es requerido."),
    monedaId: Yup.number().min(1, "Favor de seleccionar la Moneda.").required("La Moneda es requerida."),
    usoCfdiId: Yup.number().min(1, "Favor de seleccionar uso de CFDI.").required("El Uso de CFDI es requerido."),
    regimenFiscalId: Yup.number().min(1, "Favor de seleccionar el Regimen Fiscal.").required("El Regimen Fiscal es requerido."),
    metodoDepagoId: Yup.number().min(1, "Favor de seleccionar el Metodo de Pago.").required("El Metodo de Pago es requerido."),
  });

  function handleSubmit(cliente: Cliente) {
    if (cliente.fechaObservacion) {
      cliente.fechaObservacion = fechaObservacion;
    }
    if (cliente.empresa) {
      const empresaSelected = empresas.find(
        (a) => a.key === cliente.empresaId.toString()
      );
      if (empresaSelected) {
        cliente.empresa.nombre = empresaSelected.text;
        cliente.empresa.id = Number.parseInt(empresaSelected.key);
        cliente.empresa.clave = Number.parseInt(empresaSelected.value);
      }
    }
    cliente.direccion = direccion;
    cliente.empresa = undefined;
    cliente.sucursal = undefined;
    cliente.clienteTipo = undefined;
    cliente.moneda = undefined;
    cliente.formaDePago = undefined;
    cliente.condicionDePago = undefined;
    cliente.direccion = direccion;
    if (cliente.empresaId.toString() !== "0" && cliente.nombre !== null) {
      if (cliente.direccion?.paisId !== 0 && cliente.direccion?.estadoId !== 0) {
        cliente.id > 0 ? void updateCliente(cliente) : void createCliente(cliente);
      } else {
        toast.info("Favor de llenar campos Direccion obligatorios");
      }
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }
  const selectInputSucursalOnChange = ({ value, setFieldValue, values }: OnChangeProps) => {
    if (values.empresaId > 0 && value > 0)
      obtenerSiguienteClave(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: value })).then((clave) => {
        setFieldValue("clave", clave);
        setFieldValue("id", 0);
      });
  }

  function idSelectEmpresa(idSelected: number, setFieldValue: any) {
    setFieldValue("sucursalId", 0);
    empresaSucursal.empresaId = idSelected;
    void loadSucursalByIdEmpresa(empresaSucursal.empresaId);
  }

  const selectInputClaveOnKeyDown = ({ value, event, values }: OnKeyDownProps) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (values.empresaId > 0 && values.sucursalId > 0 && value > 0) {
        const paginacionCliente: PaginationSpecParams = new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, clave: parseInt(value) });
        cargarCliente(paginacionCliente).then((result) => {
          if (result) {
            setCliente(new ClienteFormValues(result))
            setFechaObservacion(new FechaObservacionValues(result?.fechaObservacion));
            setDireccion(new DireccionValues(result?.direccion));
          } else {
            setCliente(new ClienteFormValues({
              ...new ClienteFormValues(), empresaId: paginacionCliente.empresaId, sucursalId: paginacionCliente.sucursalId,
              clave: value,
            }));
            setFechaObservacion(new FechaObservacionValues());
            setDireccion(new DireccionValues());
          }
        })
      }
    }
  }
  if (loadingInitial) return <LoadingComponent content='Loading cliente' />

  return (
    <Segment clearing>
      <Formik
        onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize initialValues={cliente}>
        {({ values, setFieldValue }) => (
          <Form className='ui form' >
            {values.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <div className="col100" />
            <div className="col100" />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Cliente
              </Label>
              <div></div>
              <MySelectInput
                tabIndex={0}
                placeholder={"Seleccionar"}
                label="Empresa"
                name="empresaId"
                options={EmpresasByDropdown}
                enterInput={enterInput}
                onChange={idSelectEmpresa}
                inputWidth="170px"
                menuWidth="300px"
                autoFocus={true}
              />

              <MySelectInput
                tabIndex={1}
                label="Sucursal"
                placeholder={"Seleccionar"}
                name="sucursalId"
                options={sucursalesByEmpresaByDropdown}
                onChangeProps={selectInputSucursalOnChange}
                enterInput={enterInput}
                inputWidth="170px"
                menuWidth="300px"
              />

              <div className='col20'>
                <MyTextInput
                  tabIndex={2}
                  label="Clave"
                  placeholder="Clave"
                  name="clave"
                  maxLength={7}
                  onKeyDownProps={selectInputClaveOnKeyDown}
                  tipoDato={enumTipoDato.Numerico}
                  enterInput={enterInput} />
              </div>
              <div className='colsp' />
              <div className='col50'>
                <MyTextInput
                  tabIndex={3}
                  label="Nombre"
                  placeholder="Nombre"
                  name="nombre"
                  enterInput={enterInput}
                  maxLength={200} />
              </div>
            </Segment>
            <Segment clearing>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={4}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <Direccion
                      direccion={direccion}
                      setDireccion={(data) => {
                        setFieldValue("direccion", data)
                        cliente.direccion = data;
                        setDireccion(new DireccionValues(data));
                      }}
                      closeModals={closeModals}
                      tabIndex={4}
                    />,
                    enumHeaderModalsDirecciones.cliente
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="map marker alternate" />
                Direccion
              </Button>
              <div className="col70">
                <BarraDireccion
                  direccion={values.direccion}
                ></BarraDireccion>
              </div>
            </Segment>

            <Segment clearing>
              <div className='col17'>
                <MyTextInput tabIndex={5}
                  placeholder="RFC"
                  label="RFC"
                  name="rfc"
                  enterInput={enterInput}
                  maxLength={13}
                /></div>

              <div className='colsp' />
              <div className='colsp' />
              <div className='colsp' />
              <div className='col26'>
                <MyTextInput tabIndex={6}
                  placeholder="CURP"
                  label="CURP"
                  name="curp"
                  enterInput={enterInput}
                  maxLength={18} /> </div>
              <div className='colsp' /> <div className='colsp' /> <div className='colsp' /><div className='colsp' />
              <div className='colsp' /> <div className='colsp' /> <div className='colsp' /><div className='colsp' />
              <div className='colsp' /> <div className='colsp' />
              <div className='col20'>
                <MySelectInput
                  tabIndex={7}
                  label="Tipo"
                  placeholder={"Seleccionar"}
                  name="clienteTipoId"
                  options={clientesTiposByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div className='col20'>
                <MySelectInput
                  tabIndex={8}
                  label="Moneda"
                  placeholder="Moneda Para Facturar"
                  name="monedaId"
                  options={MonedasByDropdown}
                  inputWidth="142px"
                  menuWidth="180px"
                  enterInput={enterInput} />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={9}
                  label="Cuenta de Venta"
                  placeholder="Cuenta de Venta"
                  name="cuentaDeVentaId"
                  options={cuentasDeVentasByDropdown}
                  inputWidth="142px"
                  menuWidth="180px"
                  enterInput={enterInput} />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={10}
                  label="Condicion de pago"
                  placeholder="Condicion de pago"
                  name="condicionDePagoId"
                  options={condicionesPagosByDropdown}
                  enterInput={enterInput} />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={11}
                  label="Forma de pago"
                  placeholder="Forma de pago"
                  name="formaDePagoId"
                  options={formasPagosByDropdown}
                  inputWidth="142px"
                  menuWidth="220px"
                  enterInput={enterInput} />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={12}
                  label="Lista de Precio"
                  placeholder="Lista de Precio"
                  name="listaDePrecioId"
                  inputWidth="142px"
                  menuWidth="180px"
                  options={ListaDePreciosByDropdown}
                  enterInput={enterInput} />
              </div>
              <div className='col20'>
                <MySelectInput
                  tabIndex={13}
                  label="Lista de Precio 2"
                  placeholder="Lista de Precio"
                  name="listaDePrecio2Id"
                  inputWidth="142px"
                  menuWidth="180px"
                  options={ListaDePreciosByDropdown}
                  enterInput={enterInput} />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={14}
                  label="Uso CFDI"
                  placeholder="Uso CFDI"
                  name="usoCfdiId"
                  options={usosCFDIsByDropdown}
                  enterInput={enterInput}
                  inputWidth="140px"
                  menuWidth="300px"
                />
              </div>

              <div className='col20'>
                <MySelectInput
                  tabIndex={15}
                  label="Regimen Fiscal"
                  placeholder="Regimen Fiscal"
                  name="regimenFiscalId"
                  options={regimenesFiscalesByDropdown}
                  enterInput={enterInput}
                  inputWidth="140px"
                  menuWidth="300px"
                />
              </div>
              <div className='col20'>
                <MySelectInput
                  tabIndex={16}
                  label="Metodo de pago"
                  placeholder="Metodo de pago"
                  name="metodoDepagoId"
                  options={metodosPagosByDropdown}
                  enterInput={enterInput}
                  inputWidth="140px"
                  menuWidth="300px"
                />
              </div>
              <div className='col20'>
                <MySelectInput
                  tabIndex={17}
                  label="Vendedor"
                  placeholder="Vendedor"
                  name="vendedorId"
                  options={usuariosByDropdown}
                  enterInput={enterInput} />
              </div>
              <div className='col20'>
                <MyTextInput
                  tabIndex={18}
                  label="Limite de Credito"
                  placeholder="Limite de credito"
                  name="limiteDeCredito"
                  enterInput={enterInput} />
              </div>
              <div className='col37'>
                <MyTextInput
                  tabIndex={19}
                  label="Codigo Contable"
                  placeholder="Codigo contable"
                  name="codigoContable"
                  enterInput={enterInput} />
              </div>
              <div className='colsp' /> <div className='colsp' />
              <div className='col37'>
                <MyTextInput
                  tabIndex={20}
                  label="Centro de costo"
                  placeholder="Centro de costo"
                  name="centroCosto"
                  enterInput={enterInput} />
              </div>
              <div className='colsp' /> <div className='colsp' />
              <div className='col37'>
                <MyTextInput
                  tabIndex={20}
                  label="Descuento Ventas"
                  placeholder="Descuento Ventas"
                  name="descuentoVentas"
                  decimalDigits={"1,2"}
                  tipoDato={enumTipoDato.Numerico}
                  enterInput={enterInput} />
              </div>
              <div className='col37'>
                <MyTextInput
                  tabIndex={20}
                  label="Correo para envio de comprobantes"
                  placeholder="Correo para envio de comprobantes"
                  name="correoParaEnvioComprobantes"
                  enterInput={enterInput} />
              </div>
            </Segment>

            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={21}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => {
                          setFieldValue("fechaObservacion", data)
                          cliente.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                        }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Cliente
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>

            <Segment>
              <BarraBotones
                toLink="/Clientes"
                loading={loading}
                tabIndex={22}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})