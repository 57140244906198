import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { Entity } from "../../models/Entity";
import agent from "../../api/agent";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { setDate } from "../../common/util/util";
import { MovimientoFinanza } from "../../models/Finanza/MovimientoFinanza";
export default class MovimientoFinanzaStore {
    movimientoFinanzaRegistry = new Map<number, MovimientoFinanza>();
    movimientoFinanzaEntity = new Map<number, Entity>();
    selectedMovimientoFinanza: MovimientoFinanza | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    constructor() {
        makeAutoObservable(this);
    }

    get movimientoFinanzasById() {
        return Array.from(this.movimientoFinanzaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    createMovimientoFinanza = async (movimientoFinanza: MovimientoFinanza) => {
        this.loading = true;
        try {
            await agent.MovimientosFinanzas.create(movimientoFinanza);
            runInAction(() => {
                this.selectedMovimientoFinanza = movimientoFinanza;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Movimiento Finanza creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };


    deleteMovimientoFinanza = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.MovimientosFinanzas.delete(id);
                runInAction(() => {
                    this.movimientoFinanzaRegistry.delete(id);
                    this.loading = false;
                });
                toast.success("Movimiento Finanza eliminada con éxito");
            } catch (error) {
                toast.error("Problema al eliminar movimiento Finanza");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    };
    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        debugger
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
            try {
                const movimientoFinanza = await agent.MovimientosFinanzas.obtenerFolio(filtros);
                return movimientoFinanza;

            } catch (error) {
                console.log(error);
            }
        } else {
        }
    };

    cargarMovimiento = async (filtros: PaginationSpecParams) => {
        try {
            let movimientoFinanza = await agent.MovimientosFinanzas.obtenerMovimientoPorClave(filtros);
            if (movimientoFinanza) {
                movimientoFinanza.fecha = new Date(movimientoFinanza.fecha)
                setDate<MovimientoFinanza>(movimientoFinanza);
            }
            return movimientoFinanza;
        } catch (error) {
            console.log(error);
        }
    };
}
