
import { c_MotivoCancelacion } from "../sat/c_MotivoCancelacion";
export interface CancelarGeneracionComprobante {
    id: number;
    clave : number;
    nombre : string;
    c_MotivoCancelacionId?:number;
    c_MotivoCancelacion?:c_MotivoCancelacion;
    motivoCancelacion : string;
    cancelacionCero:number;
    cancelacionRojo:number;
    uuidsRelacionados: string;

}
export interface ICancelarGeneracionComprobanteFormValues extends Partial<CancelarGeneracionComprobante> {
    id: any;

}
export class CancelarGeneracionComprobanteFormValues implements ICancelarGeneracionComprobanteFormValues {
    id: number =0;
    clave : number=0;
    nombre : string="";
    c_MotivoCancelacionId?:number =0;
    c_MotivoCancelacion?:c_MotivoCancelacion;
    motivoCancelacion : string="";
    cancelacionCero:number =0;
    cancelacionRojo:number =0;
    uuidsRelacionados: string ="";
    constructor(init?: CancelarGeneracionComprobante) {
        Object.assign(this, init);
    }
}