import { Producto } from "../inventario/Producto";
import { UnidadMedida } from "../inventario/UnidadMedida";
import { ImpuestoDetalle } from "./ImpuestoDetalle";

export interface GeneracionComprobanteDetalle {
    id: number;
    productoId: number;
    producto?: Producto;
    unidadMedidaId: number;
    unidadMedida?: UnidadMedida;
    cantidad: number;
    precio: number
    descuento: number
    subtotal: number;
    importeDescuento: number;
    impuestosDetalles?: ImpuestoDetalle[];
}
export interface IGeneracionComprobanteDetalleFormValues extends Partial<GeneracionComprobanteDetalle> {
    selectedIdProducto: any;

}
export class GeneracionComprobanteDetalleFormValues implements IGeneracionComprobanteDetalleFormValues {
    id: number = 0;
    selectedIdProducto: undefined;
    productoId: number = 0;
    producto?: Producto;
    unidadMedidaId: number = 0;
    unidadMedida?: UnidadMedida;
    cantidad: number = 0;
    precio: number = 0;
    descuento: number = 0;
    subtotal: number = 0;
    importeDescuento: number = 0;
    impuestosDetalles?: ImpuestoDetalle[];
    constructor(init?: GeneracionComprobanteDetalle) {
        Object.assign(this, init);
    }
}