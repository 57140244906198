import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { c_Periodicidad } from "../../models/sat/c_Periodicidad";

export default class c_PeriodicidadStore {
    c_PeriodicidadRegistry = new Map<number, c_Periodicidad>();
    selectedC_Periodicidad: c_Periodicidad | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get c_periodicidadesByDropdown() {
        let c_PeriodicidadesArreg = [new TipoDropDownValues()];
        let c_PeriodicidadessOpt = new TipoDropDownValues();
        const cvts = Array.from(this.c_PeriodicidadRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            c_PeriodicidadessOpt.key = element.id;
            c_PeriodicidadessOpt.text = element.clave;
            c_PeriodicidadessOpt.value = element.id;
            c_PeriodicidadessOpt.content = element.nombre;
            c_PeriodicidadesArreg.push(c_PeriodicidadessOpt);
            c_PeriodicidadessOpt = new TipoDropDownValues();

        });
        return c_PeriodicidadesArreg;
    }

    get c_PeriodicidadesByDate() {
        return Array.from(this.c_PeriodicidadRegistry.values()).sort((a) => a.id);
    }


    loadc_Periodicidades = async () => {
        this.setLoadingInitial(true);
        try {
            const c_Periodicidades = await agent.C_Periodicidades.list();
            c_Periodicidades.forEach(c_Periodicidad => {
                this.setC_Periodicidad(c_Periodicidad);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
        }
    }

    getc_Periodicidades = async () => {
        this.setLoadingInitial(true);
        const c_Periodicidades = await agent.C_Periodicidades.list();
        try {
            c_Periodicidades.forEach(c_Periodicidad => {
                this.setC_Periodicidad(c_Periodicidad);
            })
            this.setLoadingInitial(false);
            return c_Periodicidades;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return c_Periodicidades;
        }
    }







    loadC_Periodicidad = async (id: number) => {
        let c_Periodicidad = this.getC_Periodicidad(id);
        if (c_Periodicidad) {
            this.selectedC_Periodicidad = c_Periodicidad;
            return c_Periodicidad;
        } else {
            this.loadingInitial = true;
            try {
                c_Periodicidad = await agent.C_Periodicidades.details(id);
                this.setC_Periodicidad(c_Periodicidad);
                this.selectedC_Periodicidad = c_Periodicidad;
                this.setLoadingInitial(false);
                return c_Periodicidad;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setC_Periodicidad = (c_Periodicidad: c_Periodicidad) => {
        this.c_PeriodicidadRegistry.set(c_Periodicidad.id, c_Periodicidad);
    }

    private getC_Periodicidad = (id: number) => {
        return this.c_PeriodicidadRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createC_Periodicidad = async (c_Periodicidad: c_Periodicidad) => {
        this.loading = true;
        try {
            await agent.C_Periodicidades.create(c_Periodicidad);
            runInAction(() => {
                this.c_PeriodicidadRegistry.set(c_Periodicidad.id, c_Periodicidad);
                this.selectedC_Periodicidad = c_Periodicidad;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deletePeriodicidad = async (id: number) => {
        this.loading = true;
        try {
            await agent.C_Periodicidades.delete(id);
            runInAction(() => {
                this.c_PeriodicidadRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
