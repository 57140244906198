import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { TipoNotaCredito } from "../../models/venta/TipoNotaCredito";

export default class TipoNotaCreditoStore {
    tipoNotaCreditoRegistry = new Map<number, TipoNotaCredito>();
    selectedTipoNotaCredito: TipoNotaCredito | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tiposNotasCreditosByDropdown() {
        let tiposNotasCreditosArreg = [new TipoDropDownValues()];
        let tiposNotasCreditosOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoNotaCreditoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tiposNotasCreditosOpt.key = element.id;
            tiposNotasCreditosOpt.text = element.clave;
            tiposNotasCreditosOpt.value = element.id;
            tiposNotasCreditosOpt.content = element.nombre;
            tiposNotasCreditosArreg.push(tiposNotasCreditosOpt);
            tiposNotasCreditosOpt = new TipoDropDownValues();

        });
        return tiposNotasCreditosArreg;
    }

    get tiposNotasCreditosByDate() {
        return Array.from(this.tipoNotaCreditoRegistry.values()).sort((a) => a.id);
    }


    loadTiposNotasCreditos = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const tiposNotasCreditos = await agent.TiposNotasCreditos.list();
            tiposNotasCreditos.forEach(tipoNotaCredito => {
                this.setTipoNotaCredito(tipoNotaCredito);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
        }
    }

    getTiposNotasCreditos = async () => {
        debugger
        this.setLoadingInitial(true);
        const TiposNotasCreditos = await agent.TiposNotasCreditos.list();
        try {
            TiposNotasCreditos.forEach(tipoNotaCredito => {
                this.setTipoNotaCredito(tipoNotaCredito);
            })
            this.setLoadingInitial(false);
            return TiposNotasCreditos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return TiposNotasCreditos;
        }
    }







    loadTipoNotaCredito = async (id: number) => {
        let tipoNotaCredito = this.getTipoNotaCredito(id);
        if (tipoNotaCredito) {
            this.selectedTipoNotaCredito = tipoNotaCredito;
            return tipoNotaCredito;
        } else {
            this.loadingInitial = true;
            try {
                tipoNotaCredito = await agent.TiposNotasCreditos.details(id);
                this.setTipoNotaCredito(tipoNotaCredito);
                this.selectedTipoNotaCredito = tipoNotaCredito;
                this.setLoadingInitial(false);
                return tipoNotaCredito;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setTipoNotaCredito = (tipoNotaCredito: TipoNotaCredito) => {
        this.tipoNotaCreditoRegistry.set(tipoNotaCredito.id, tipoNotaCredito);
    }

    private getTipoNotaCredito = (id: number) => {
        return this.tipoNotaCreditoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoNotaCredito = async (tipoNotaCredito: TipoNotaCredito) => {
        this.loading = true;
        try {
            await agent.TiposNotasCreditos.create(tipoNotaCredito);
            runInAction(() => {
                this.tipoNotaCreditoRegistry.set(tipoNotaCredito.id, tipoNotaCredito);
                this.selectedTipoNotaCredito = tipoNotaCredito;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateTipoNotaCredito = async (tipoNotaCredito: TipoNotaCredito) => {
        this.loading = true;
        try {
            await axios.post(`http://ais-online.com.mx:8081/api/metodosPagos`, tipoNotaCredito).then(Response => {
            }).catch(error => {
                console.log(error);
            }
            );
            runInAction(() => {
                this.tipoNotaCreditoRegistry.set(tipoNotaCredito.id, tipoNotaCredito);
                this.selectedTipoNotaCredito = tipoNotaCredito;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteTipoNotaCredito = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposNotasCreditos.delete(id);
            runInAction(() => {
                this.tipoNotaCreditoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
