import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { tabuladorButtons } from "../../app/common/funciones/tabuladorEnter";

interface Props {
  className?: string;
  toLink?: string;
  loading?: boolean;
  tabIndex?: number;
  id?: string;
  firstElement?: number;
  lastElement?: number;
  mostrarBotonImprimir?:boolean;
  mostrarBotonRegresar?:boolean;
}
const tabuladorButton = (
  event: any,
  firstElement?: number,
  lastElement?: number
) => {
  if (firstElement !== undefined && lastElement !== undefined)
    tabuladorButtons(event, firstElement, lastElement);
};
export default function BarraBotones(props: Props) {
  return (
    <>
      <Button.Group>
        <Button
          tabIndex={props.tabIndex}
          type="submit"
          disabled={props.loading}
          loading={props.loading}
          color="blue"
        >
          <Icon name="save" />
          Guardar
        </Button>
        {props.id ? null : (
          <Button
            type="reset"
            tabIndex={props.tabIndex && props.tabIndex + 1}
            color="grey"
          >
            <Icon name="undo" />
            Limpiar
          </Button>
        )}

        {props.mostrarBotonRegresar===true?(
        <Button
          as={Link}
          to={props.toLink || ""}
          tabIndex={props.tabIndex && props.tabIndex + 2}
          onKeyDown={(e: any) =>
            tabuladorButton(e, props.firstElement, props.lastElement)
          }
        >
          <Icon name="backward" />
          Regresar
        </Button>
        ):""}
    
        {/* <Button
          as={Link}
          to={props.toLink || ""}
          tabIndex={props.tabIndex && props.tabIndex + 2}
          onKeyDown={(e: any) =>
            tabuladorButton(e, props.firstElement, props.lastElement)
          }
          color="grey"
        >
          <Icon name="print" />
          Imprimir
        </Button> */}
      </Button.Group>
    </>
  );
}
