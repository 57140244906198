import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { Table, Button, Segment } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import MyTextInput from "../../app/common/form/MyTextInput";
import { OnKeyDownProps } from "../../app/models/myProps";
import { enterInputs } from "../../app/common/funciones/tabuladorEnter";

interface Props {
  closeModals: () => void;
  tabIndex: number;
  values: any;
  onCopy: (data: any) => void;
  name?: string;
}
const Busqueda: React.FC<Props> = observer((props: Props) => {
  const [filteredData, setFilteredData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const { productoStore, empresaStore, sucursalStore, tipoVentaStore, serieFolioStore, clienteStore, cuentaDeVentaStore, usuarioStore, monedaStore, tipoDeCompraStore,
    cuentaDeCompraStore, proveedorStore, almacenStore, conceptoDeInventarioStore } = useStore();
  const { fetchsProductos, fetchsProductosSinFiltos, fetchsClaveSat } = productoStore;
  const { fetchsEmpresas } = empresaStore;
  const { fetchSucursalesSinEmpresa } = sucursalStore;
  const { fetchsTiposVentas } = tipoVentaStore;
  const { fetchsClientes } = clienteStore;
  const { fetchsSeriesFolios } = serieFolioStore;
  const { fetchsCuentaDeVentas } = cuentaDeVentaStore;
  const { fetchsUsuariosVendedor, fetchsUsuariosTrabajador, fetchsUsuariosComprador } = usuarioStore;
  const { fetchsMonedas } = monedaStore;
  const { fetchsTiposCompras } = tipoDeCompraStore;
  const { fetchsCuentasDeCompras } = cuentaDeCompraStore;
  const { fetchsProveedor } = proveedorStore;
  const { fetchsAlmacenes } = almacenStore;
  const { fetchsConceptoInventarios } = conceptoDeInventarioStore

  const inputRef = useRef<any>(null);

  const setSearchQuery = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    setFilteredData(data.filter(
      (data: any) =>
        data.clave?.toString().toLowerCase().includes(lowerCaseQuery) ||
        data.nombre?.toLowerCase().includes(lowerCaseQuery)
    ));
  };

  const clearSearch = () => {
    setFilteredData(data);
  };
  const clearFilteredData = () => {
    setFilteredData([]);
    setData([]);
  }

  useEffect(() => {
    const abortController = new AbortController();
    const loadInformacion = async () => {
      try {
        switch (props.name) {
          case "Producto":
            const resultProductos = await fetchsProductosSinFiltos();
            if (resultProductos) {
              setFilteredData(resultProductos);
              setData(resultProductos);
            }
            break;
          case "Empresa":
            const result = await fetchsEmpresas();
            if (result) {
              setFilteredData(result);
              setData(result);
            }
            break;
          case "Sucursal":
            const resultSuc = await fetchSucursalesSinEmpresa();
            if (resultSuc) {
              setFilteredData(resultSuc);
              setData(resultSuc);
            }
            break;

          case "TipoVenta":
            const resultTV = await fetchsTiposVentas();
            if (resultTV) {

              setFilteredData(resultTV);
              setData(resultTV);
            }
            break;

          case "Serie":
            const resultSr = await fetchsSeriesFolios();
            if (resultSr) {

              setFilteredData(resultSr);
              setData(resultSr);
            }
            break;

          case "Cliente":
            const resultCl = await fetchsClientes();
            if (resultCl) {

              setFilteredData(resultCl);
              setData(resultCl);
            }
            break;

          case "CuentaDeVenta":
            const resultCdV = await fetchsCuentaDeVentas();
            if (resultCdV) {

              setFilteredData(resultCdV);
              setData(resultCdV);
            }
            break;

          case "Vendedor":
            const resultUsVen = await fetchsUsuariosVendedor();
            if (resultUsVen) {
              setFilteredData(resultUsVen);
              setData(resultUsVen);
            }
            break;

          case "Trabajador":
            const resultUsTra = await fetchsUsuariosTrabajador();
            if (resultUsTra) {
              setFilteredData(resultUsTra);
              setData(resultUsTra);
            }
            break;


          case "Moneda":
            const resultMnd = await fetchsMonedas();
            if (resultMnd) {
              setFilteredData(resultMnd);
              setData(resultMnd);
            }
            break;

          case "TipoCompra":
            const resultTC = await fetchsTiposCompras();
            if (resultTC) {
              setFilteredData(resultTC);
              setData(resultTC);
            }
            break;

          case "CuentaDeCompra":
            const resultCC = await fetchsCuentasDeCompras();
            if (resultCC) {
              setFilteredData(resultCC);
              setData(resultCC);
            }
            break;

          case "Proveedor":
            const resultProv = await fetchsProveedor();
            if (resultProv) {
              setFilteredData(resultProv);
              setData(resultProv);
            }
            break;

          case "Comprador":
            const resultCompra = await fetchsUsuariosComprador();
            if (resultCompra) {
              setFilteredData(resultCompra);
              setData(resultCompra);
            }
            break;
          case "Almacen":
            const resultAlmacen = await fetchsAlmacenes();
            if (resultAlmacen) {
              setFilteredData(resultAlmacen);
              setData(resultAlmacen);
            }
            break;
          case "ConceptoInventario":
            const resultConceptoInv = await fetchsConceptoInventarios();
            if (resultConceptoInv) {
              setFilteredData(resultConceptoInv);
              setData(resultConceptoInv);
            }
            break;

          case "claveSat":
          case "claveSATCartaPorte":
            const resultClaveSat = await fetchsClaveSat();
            if (resultClaveSat) {
              setFilteredData(resultClaveSat);
              setData(resultClaveSat);
            }
            break;

          default:
            break;
        }
      } catch (error) {
        console.error("No se pudo cargar la información:", error);
      }
    };
    loadInformacion();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    return () => {
      clearFilteredData();
      props.closeModals();
    }
  }, [])

  // const handleClear = (resetForm: any) => {
  //   resetForm();
  //   clearSearch();
  // };

  const handleDoubleClick = (info: any) => {
    switch (props.name) {
      case "Producto":
      case "Empresa":
      case "Sucursal":
      case "TipoVenta":
      case "Serie":
      case "Cliente":
      case "CuentaDeVenta":
      case "Usuario":
      case "Vendedor":
      case "Trabajador":
      case "Moneda":
      case "TipoCompra":
      case "CuentaDeCompra":
      case "Proveedor":
      case "Comprador":
      case "Almacen":
      case "ConceptoInventario":
      case "claveSat":
      case "claveSATCartaPorte":
        props.onCopy(info);
        props.closeModals();
        break
      default:
        break;
    }
  };
  const handleRowKeyDown = (e: any, info: any) => {
    if (e.key === "Enter") {
      handleDoubleClick(info);
    }
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      if (inputRef.current)
        inputRef.current.Focus(); // Llamar al método focus en lugar de Focus

    }
  };
  const handlButtonDown = (e: any) => {
    if (filteredData?.length < 1)
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        if (inputRef.current)
          inputRef.current.Focus(); // Llamar al método focus en lugar de Focus
      }
  };
  const handleInputKeyDown = (porps: OnKeyDownProps) => {
    const { event, values } = porps;
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchQuery(values.search);
    }
  };
  const enterInput = (tabIndex: number) => {
    enterInputs(tabIndex, 0, 55500);
  }
  return (
    <Segment clearing>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={values => {
          setSearchQuery(values.search);
        }}
      >
        {({ resetForm }) => (
          <Form className='ui form' >
            <div className='col65'>
              <MyTextInput
                ref={inputRef}
                autoFocus
                type="text"
                name="search"
                enterInput={enterInput}
                onKeyDownProps={handleInputKeyDown}
                placeholder="Buscar..."
                tabIndex={props.tabIndex + 1}
              />
            </div>
            <Button type="submit" onKeyDown={(e: any) => handlButtonDown(e)} primary tabIndex={props.tabIndex + 2}>Buscar</Button>
            {/* <Button type="button" onKeyDown={(e: any) => handlButtonDown(e)} onClick={() => handleClear(resetForm)} secondary tabIndex={props.tabIndex + 3}>Borrar Búsqueda</Button> */}
          </Form>
        )}
      </Formik>
      <Table celled selectable >
        <Table.Header>
          <Table.Row>
            {props.name === "Sucursal" && <Table.HeaderCell>Empresa</Table.HeaderCell>}
            <Table.HeaderCell>Clave</Table.HeaderCell>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            {props.name === "Producto" && <Table.HeaderCell>Existencia</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredData.map((data: any, index: any) => {
            return (
              <Table.Row key={data.id}
                onDoubleClick={() => handleDoubleClick(data)}
                tabIndex={props.tabIndex + index + 3}
                onKeyDown={(e: any) => handleRowKeyDown(e, data)}
              >
                {props.name === "Sucursal" && <Table.Cell>{data?.empresa?.clave}</Table.Cell>}
                <Table.Cell>{data.clave}</Table.Cell>
                <Table.Cell>{data.nombre}</Table.Cell>
                {props.name === "Producto" && <Table.Cell>{data?.existencia}</Table.Cell>}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Segment>
  );
});
export default Busqueda;