import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Impuesto } from "../../models/configuracion/Impuesto";
import { history } from "../../..";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ImpuestoStore {
  impuestoRegistry = new Map<number, Impuesto>();
  selectedImpuesto: Impuesto | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get impuestosByDate() {
    return Array.from(this.impuestoRegistry.values()).sort((a) => a.id);
  }

  get impuestosById() {
    return Array.from(this.impuestoRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }
  get ImpuestosByDropdown() {
    return entityByDropdown<Impuesto>(
      Array.from(this.impuestoRegistry.values())
    );
  }

  loadImpuestos = async () => {
    this.setLoadingInitial(true);
    try {
      const impuestos = await agent.Impuestos.list();
      impuestos.forEach((impuesto) => {
        setDate<Impuesto>(impuesto);
        this.setImpuesto(impuesto);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadImpuesto = async (id: number) => {
    let impuesto = this.getImpuesto(id);
    if (impuesto) {
      setDate<Impuesto>(impuesto);
      this.selectedImpuesto = impuesto;
      return impuesto;
    } else {
      this.loadingInitial = true;
      try {
        impuesto = await agent.Impuestos.details(id);
        this.setImpuesto(impuesto);
        this.selectedImpuesto = impuesto;
        this.setLoadingInitial(false);
        return impuesto;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setImpuesto = (impuesto: Impuesto) => {
    this.impuestoRegistry.set(impuesto.id, impuesto);
  };

  private getImpuesto = (id: number) => {
    return this.impuestoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createImpuesto = async (impuesto: Impuesto) => {
    this.loading = true;
    try {
      await agent.Impuestos.create(impuesto);
      runInAction(() => {
        this.selectedImpuesto = impuesto;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Impuesto creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateImpuesto = async (impuesto: Impuesto) => {
    this.loading = true;
    try {
      await agent.Impuestos.update(impuesto);
      runInAction(() => {
        this.selectedImpuesto = impuesto;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Impuesto editado con éxito");
    } catch (error) {
      toast.error("Problema al actualizar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteImpuesto = async (id: number) => {
    this.loading = true;
    try {
      await agent.Impuestos.delete(id);
      runInAction(() => {
        this.impuestoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Impuesto eliminado con éxito");
    } catch (error) {
      toast.error("Problema al eliminar el impuesto");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
