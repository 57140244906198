import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";
import { Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from "../../../../app/common/form/MyDateInput";
import { UserContext } from "../../../../app/context/UserContext";
import { TipoDropDownValues, TipoDropdown } from "../../../../app/models/configuracion/TipoDropdown";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import { OnChangeProps } from "../../../../app/models/myProps";

const TraspasoEntresSucursalHeader: React.FC<{ tabuladorEnterDom?: (e: number) => void; }> = (tabuladorEnterDom) => {
  const { traspasoEntreSucursalStore, almacenStore } = useStore();
  const { almacenesByDropdown, loadAlmacenes } = almacenStore;
  const { getNextFolio } = traspasoEntreSucursalStore
  const { empresaLog } = useContext(UserContext);
  const { sucursalStore, serieFolioStore, tipoVentaStore } = useStore();
  const { fetchSucursales } = sucursalStore
  const { values, setFieldValue } = useFormikContext<any>();
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [sucursalesDestino, setSucursalesDestino] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [serie, setSerie] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [tipoVenta, setTipoVenta] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [mostrarCuentaInventario, setMostrarCuentaInventario] = useState<Boolean>(false);

  const [headerParams, setHeaderParams] = useState<PaginationSpecParams>(new PaginationFormValues());
  const { loadSerieByParamsDropdown } = serieFolioStore
  const { loadTipoVentaByParamsDropdown } = tipoVentaStore
  useEffect(() => {
    let isMounted = true;
    const fetchSucuarsalData = async () => {
      if (empresaLog?.id) {
        try {
          setSucursales([new TipoDropDownValues()]);
          setSucursalesDestino([new TipoDropDownValues()]);
          setSerie([new TipoDropDownValues()]);
          setFieldValue("empresaId", empresaLog.id);
          setFieldValue("sucursalId", 0);
          setFieldValue("serieFolioDeVentaId", 0);
          const response = await fetchSucursales(empresaLog.id);
          if (isMounted && response) {
            setSucursales(response);
            setSucursalesDestino(response);
          }
        } catch (error) {
          console.error('Error al obtener datos de la empresa:', error);
        }
      }
    }
    fetchSucuarsalData();
    return () => {
      isMounted = false;
    };
  }, [empresaLog?.id]);
  useEffect(() => {
    let isMounted = true;
    const getNextFolioTraspaso = async (params: PaginationSpecParams) => {
      try {
        if (empresaLog?.id && values.sucursalId && values.serieFolioDeVentaId) {
          const folio = await getNextFolio({ ...params, empresaId: empresaLog.id, sucursalId: values.sucursalId, serieId: values.serieFolioDeVentaId });
          if (isMounted && folio) {
            setFieldValue("folio", folio);
          }
        }
      } catch (error) {
        setFieldValue("folio", 0);
      }
    }

    getNextFolioTraspaso(headerParams);
    return () => {
      isMounted = false;
    };
  }, [empresaLog?.id, values.sucursalId, values.serieFolioDeVentaId]);

  useEffect(() => {
    const loadSerie = async (params: PaginationSpecParams) => {
      try {
        setSerie([new TipoDropDownValues()]);
        setFieldValue("serieFolioDeVentaId", 0);
        setFieldValue("folio", 0);

        if (empresaLog.id > 0 && values.sucursalId > 0) {

          const result = await loadSerieByParamsDropdown({ ...params, empresaId: empresaLog.id, sucursalId: values.sucursalId, tipoVentaId: values.tipoVentaId, tipoVentaTipoClave: 0 });
          if (result)
            setSerie(result);



        }
      } catch (error) {
        console.error("Failed to load series:", error);
      }
    };
    loadSerie(headerParams);
    return () => {
      //
    };
  }, [values.sucursalId, empresaLog?.id, values.tipoVentaId]);
  useEffect(() => {

    const loadTipoVenta = async (params: PaginationSpecParams) => {
      try {
        setTipoVenta([new TipoDropDownValues()]);
        const resulTipoVenta = await loadTipoVentaByParamsDropdown({ ...headerParams, tipoVentaTipoId: 0, tipoVentaTipoClave: 35 });
        if (resulTipoVenta)
          setTipoVenta(resulTipoVenta);
      } catch (error) {
        console.error("Failed to load series:", error);
      };
    }
    loadTipoVenta(headerParams);
  }, []);
  useEffect(() => {
    setMostrarCuentaInventario(false);
    setFieldValue("almacenOrigenId", 0)
    setFieldValue("almacenDestinoId", 0)
    if ((values.sucursalId > 0 && values.sucursalDestinoId > 0) && values.sucursalId === values.sucursalDestinoId) {
      setMostrarCuentaInventario(true);
    }
  }, [values.sucursalDestinoId, values.sucursalId]);

  useEffect(() => {
    void loadAlmacenes();
  }, []);
  const handleFieldChange = (props: OnChangeProps) => {


    //  setFieldValue('TraspasoEntresSucursalDetalles', detalles);
  }
  return (
    <Segment clearing>
      <div>
        <Label as="a" color="red" ribbon>
          Filtros
        </Label>

      </div>
      <div className='col18'>
        <MySelectInput
          tabIndex={1}
          autoFocus={true}
          placeholder={"Seleccionar"}
          label="Suc. Origen"
          name="sucursalId"
          onChangeProps={handleFieldChange}
          options={sucursales}
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        // inputWidth="50px"
        // menuWidth="300px"
        />
      </div>
      <div className='col18'>
        <MySelectInput
          tabIndex={2}
          placeholder="Tipo venta"
          label="Tipo Venta"
          name="tipoVentaId"
          options={tipoVenta}
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>
      <div className='col18'>
        <MySelectInput
          tabIndex={3}
          placeholder="Serie"
          label="Serie"
          name="serieFolioDeVentaId"
          options={serie}
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>
      <div className='col10'>
        <MyTextInput
          className="textAlingRight widthtext heighttext input"
          tabIndex={4}
          placeholder=''
          label="Folio"
          name='folio'
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>

      <div className='col18'>
        <MySelectInput
          tabIndex={5}
          placeholder={"Seleccionar"}
          label="Suc. Destino"
          onChangeProps={handleFieldChange}
          name="sucursalDestinoId"
          options={sucursalesDestino}
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>
      <div className='col15'>
        Fecha Emision
        <MyDateInput
          tabIndex={6}
          placeholderText="Fecha Emision"
          name="fechaEmision"
          dateFormat="dd/MM/yyyy"
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>
      {/* <div className='col5'></div> */}
      <div className='col15'>
        Fec Cancelacion
        <MyDateInput
          tabIndex={7}
          placeholderText="Fecha Emision"
          name="fechaCancelacion"
          dateFormat="dd/MM/yyyy"
          enterInput={tabuladorEnterDom.tabuladorEnterDom}
        />
      </div>
      {mostrarCuentaInventario &&
        <>
          <div className="col30">
            <MySelectInput
              tabIndex={mostrarCuentaInventario ? 8 : -1}
              placeholder="Cuenta inventrio origen"
              label="Cuenta inventrio origen"
              name="almacenOrigenId"
              options={almacenesByDropdown}
              enterInput={tabuladorEnterDom.tabuladorEnterDom}
            //   onChange={idSelectSucursal}
            />
          </div>
          <div className="col30">
            <MySelectInput
              tabIndex={mostrarCuentaInventario ? 9 : -1}
              placeholder="Cuenta inventrio destino"
              label="Cuenta inventrio destino"
              name="almacenDestinoId"
              options={almacenesByDropdown}
              enterInput={tabuladorEnterDom.tabuladorEnterDom}
            //   onChange={idSelectSucursal}
            />
          </div>
        </>
      }
    </Segment>
  );
};
export default observer(TraspasoEntresSucursalHeader);