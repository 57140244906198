export const enumTipoRfc = {
    Fisico: '[TipoRFC Fisico]',
    Moral: '[TipoRFC Moral]',
    Ambos: '[TipoRFC Ambos]'
}

export const enumTipoDato = {
    Alfabetico: '[TipoDato] Alfabetico',
    Alfanumerico: '[TipoDato] Alfanumerico',
    AlfanumericoCURP: '[TipoDato] AlfanumericoCURP',
    AlfanumericoGuion: '[TipoDato] AlfanumericoGuion',
    AlfanumericoRFC: '[TipoDato] AlfanumericoRFC',
    Caracteres: '[TipoDato] Caracteres',
    CaracteresPipe: '[TipoDato] CaracteresPipe',
    Email: '[TipoDato] Email',
    Hora: '[TipoDato] Hora',
    Estructura: '[TipoDato] Estructura',
    Fecha: '[TipoDato] Fecha',
    Numerico: '[TipoDato] Numerico',
    NumericoComa: '[TipoDato] NumericoComa',
    NumericoEspacio: '[TipoDato] NumericoEspacio',
    NumericoFiltro: '[TipoDato] NumericoFiltro',
    NumericoGuion: '[TipoDato] NumericoGuion',
    NumericoSinPunto: '[TipoDato] NumericoSinPunto',
    Contrasena: '[TipoDato] Contrasena',
    Ninguno: '[TipoDato] Ninguno'
}