import { makeAutoObservable } from 'mobx';

class TabsStore {
  //tabs = [{ title: 'Home', content: "" }];
  tabs: { title: string; content: string }[]=[];
  activeIndex = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addTab(title:any, content:any) {
    const existingTab = this.tabs.find((tab) => tab.title === title);
    if (!existingTab) {
      this.tabs.push({ title, content });
      this.activeIndex = this.tabs.length - 1;
    } else {
      this.activeIndex = this.tabs.indexOf(existingTab);
    }
  }

  removeTab(index:any) {
    this.tabs.splice(index, 1);
    if (this.activeIndex >= this.tabs.length) {
      this.activeIndex = this.tabs.length - 1;
    }
  }

  setActiveIndex(index:any) {
    if (typeof index === 'number') {
      this.activeIndex = index;
    }
  }
}

export default TabsStore;
