import { Producto } from "../inventario/Producto";
import { UnidadMedida } from "../inventario/UnidadMedida";


export interface GeneracionComprobanteCompraDetalle {
    id: number;
    productoId: number;
    producto?: Producto;
    unidadMedidaId:number;
    unidadMedida?:UnidadMedida;
    cantidad:number;
    precio:number
    descuento:number
    subtotal: number;
    importeDescueto: number;
    impuestosDetalles?: any
}
export interface IGeneracionComprobanteCompraDetalleFormValues extends Partial<GeneracionComprobanteCompraDetalle> {
    selectedIdProducto: any;

}
export class GeneracionComprobanteCompraDetalleFormValues implements IGeneracionComprobanteCompraDetalleFormValues {
    id: number =0;
    selectedIdProducto: undefined;
    productoId: number =0;
    producto?: Producto;
    unidadMedidaId:number=0;
    unidadMedida?:UnidadMedida;
    cantidad:number =0;
    precio:number=0;
    descuento:number=0;
    subtotal: number =0;
    importeDescueto: number =0;
    impuestosDetalles?: any
    constructor(init?: GeneracionComprobanteCompraDetalle) {
        Object.assign(this, init);
    }
}


