import { useEffect, useState } from 'react';
import { Table, Grid, Segment, Label, Button, Icon } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import MyDateInput from '../../../app/common/form/MyDateInput';
import { enterInputs } from '../../../app/common/funciones/tabuladorEnter';
import { OnChangeProps } from '../../../app/models/myProps';
import { Form, Formik } from 'formik';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { useStore } from '../../../app/stores/store';
import { TipoDropdown, TipoDropDownValues } from '../../../app/models/configuracion/TipoDropdown';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
export default observer(function VentasPorCategoria() {
  const firstElement = 0;
  const lastElement = 19;
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { empresaStore, sucursalStore, generacionComprobanteStore } = useStore();
  const { loadEntityEmpresas, EmpresasByDropdownByClave } = empresaStore;
  const { fetchSucursales } = sucursalStore
  const { obtenerVentasPorCategoria } = generacionComprobanteStore;

  useEffect(() => {
    void loadEntityEmpresas();
  }, []);

  const [ventasData, setVentasData] = useState<any[]>([]);

  // Datos para el gráfico de barras
  const chartData = {
    labels: ventasData.map((venta) => venta.nombre),
    datasets: [
      {
        label: 'Ventas',
        data: ventasData.map((venta) => parseFloat(venta.subtotal?.toString().replace(/,/g, ''))),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Opciones para el gráfico
  const chartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  function onChangeSelectEmpresa({ value, setFieldValue }: OnChangeProps) {
    setFieldValue("sucursalId", 0);
    fetchSucursales(value).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
  }

  const handleSubmit = (reporteVentas: any) => {
    obtenerVentasPorCategoria(reporteVentas).then(result => {
      if (result !== undefined) {
        setVentasData(result);
      }
    })
  }

  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),
    fechaInicio: Yup.date().typeError("La fecha inicio debe ser fecha.").required("La fecha inicio es requerido"),
    fechaFin: Yup.date().typeError("La fecha fin debe ser fecha.").required("La fecha fin es requerido").min(Yup.ref('fechaInicio'), 'La fecha fin debe ser mayor a la fecha de inicio.'),
  });
  return (
    <Segment>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={{
          empresaId: 0,
          sucursalId: 0,
        }}
      >
        {({ }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>
              <div className="col25">
                <MySelectInput
                  autoFocus={true}
                  tabIndex={0}
                  placeholder="Empresa"
                  label="Empresa"
                  name="empresaId"
                  options={EmpresasByDropdownByClave}
                  enterInput={enterInput}
                  onChangeProps={onChangeSelectEmpresa}
                  inputWidth="170px"
                  menuWidth="300px"
                />
              </div>
              <div className="col25">
                <MySelectInput
                  tabIndex={1}
                  placeholder="Sucursal"
                  label="Sucursal"
                  name="sucursalId"
                  options={sucursales}
                  enterInput={enterInput}
                  inputWidth="170px"
                  menuWidth="300px"
                />
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={1}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}
                />
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={2}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>
            </Segment>
            <Segment>
              <Button tabIndex={14} type="submit" color="blue">
                <Icon name="save" />
                Aceptar
              </Button>
            </Segment>
            <Grid columns={2} stackable>
              {/* Columna de la tabla */}
              <Grid.Column width={7}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Clave</Table.HeaderCell>
                      <Table.HeaderCell>Categoria</Table.HeaderCell>
                      <Table.HeaderCell>Ventas</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {ventasData.map((venta, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{venta.clave}</Table.Cell>
                        <Table.Cell>{venta.nombre}</Table.Cell>
                        <Table.Cell>{venta.subtotal}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>

              {/* Columna del gráfico */}
              <Grid.Column width={9}>
                <div style={{ height: '400px' }}>
                  <Bar data={chartData} options={chartOptions} />
                </div>
              </Grid.Column>
            </Grid>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})