import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Table, Segment, Header, Icon, Label, TableCell } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import BarraBotones from '../../../Component/BarraBotones';
import {
  enterInputs,
} from "../../../../app/common/funciones/tabuladorEnter";
import { GeneracionCompronanteTickets, GeneracionCompronanteTicketsFormValues, Tickets } from "../../../../app/models/venta/GeneracionCompronanteTickets";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import MyDataGridRows from "../../../../app/common/form/MyDataGridRows";
import moment from "moment";
import { formatDecimal } from "../../../../app/base/Tools";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";
import { TipoDropDownValues, TipoDropdown } from "../../../../app/models/configuracion/TipoDropdown";

export default observer(function FacturaGlobalForm() {
  const firstElement = 0;
  const [errorButon, setErrorButon] = useState("");
  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable] = useState(numberControlsTable + initialIndexTable);
  const { usuarioStore, empresaStore, sucursalStore, tipoVentaStore, serieFolioStore, clienteStore,
    generacionComprobanteStore, c_periodicidadStore, c_mesStore, facturaGlobalStore } = useStore();
  const { getTickets, facturaGlobalParams, facturaGlobalParams2, createFacturaGlobal } = facturaGlobalStore;
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { c_mesesByDropdown, loadc_Meses } = c_mesStore
  const { c_periodicidadesByDropdown, loadc_Periodicidades } = c_periodicidadStore
  const { fetchSucursales } = sucursalStore
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { loadTiposVentasByParams } = tipoVentaStore
  const { loadSerieByParamsDropdown } = serieFolioStore
  const [serie, setSerie] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [serie2, setSerie2] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { loadClienteByParamsDropdown } = clienteStore
  const [cliente, setCliente] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { loadUsuarioByParamsDropdown } = usuarioStore;
  const [usuario, setUsuario] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { obtenerSiguienteFolio,
    loadingInitial,
    loading,

  } = generacionComprobanteStore
  const variable: PaginationSpecParams = new PaginationFormValues();
  const [tipoVenta, setTipoVenta] = useState<any[]>([]);
  const [tipoVenta2, setTipoVenta2] = useState<any[]>([]);
  const [generacionCompronanteTickets, setGeneracionCompronanteTickets] = useState(new GeneracionCompronanteTicketsFormValues());
  const currentYear = new Date().getFullYear();
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),
    tipoVentaId: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
    serieFolioDeVentaId: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de venta es requerido."),
    tipoVenta2Id: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
    serieFolioDeVenta2Id: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de venta es requerido."),
    folio: Yup.number().required("El folio es requerido."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha Emision es requerida"),
    clienteId: Yup.number()
      .min(1, "Favor de seleccionar el Cliente.")
      .required("El Cliente es requerido."),
    elaboroId: Yup.number()
      .min(1, "Favor de seleccionar el Elaborado Por.")
      .required("El Elaborado Por es requerido."),
    cobradorId: Yup.number()
      .min(1, "Favor de seleccionar el Cobrador.")
      .required("El Cobrador Por es requerido."),
    fechaInicio: Yup.date().when("porFecha", {
      is: (porFecha: boolean) => porFecha === true,
      then: Yup.date().typeError("La fecha inicio debe ser fecha.").required("La fecha inicio es requerido"),
      otherwise: Yup.date()
    }),
    fechaFin: Yup.date().when("porFecha", {
      is: (porFecha: boolean) => porFecha === true,
      then: Yup.date().typeError("La fecha fin debe ser fecha.").required("La fecha fin es requerido").min(Yup.ref('fechaInicio'), 'La fecha fin debe ser mayor a la fecha de inicio.'),
      otherwise: Yup.date()
    }),
    mesId: Yup.number()
      .min(1, "Favor de seleccionar el mes.")
      .required("El mes es requerido."),
    periodicidadId: Yup.number()
      .min(1, "Favor de seleccionar el periodo.")
      .required("El periodo es requerido."),
    año: Yup.number()
      .required("El año es requerido.")
      .test(
        "año-valido",
        `El año debe ser ${currentYear} o ${currentYear - 1}.`,
        (value) => {
          return value === currentYear || value === currentYear - 1;
        }
      ),
  });

  useEffect(() => {
    void loadEntityEmpresas();
    loadTiposVentasByParams({ ...variable, tipoVentaTipoId: 1 }).then((resul) => {
      if (resul !== undefined)
        setTipoVenta(resul);
    }
    );
    loadTiposVentasByParams({ ...variable, tipoVentaTipoId: 4 }).then((resul) => {
      if (resul !== undefined)
        setTipoVenta2(resul);
    }
    );
    void loadc_Meses();
    void loadc_Periodicidades();
  }, [loadTiposVentasByParams, loadEntityEmpresas, loadc_Meses, loadc_Periodicidades]);
  function handleSubmit(generacionComprobante: GeneracionCompronanteTickets) {
    const newTickets = generacionComprobante?.tickets
      ?.filter((item: Tickets) => item.checkList)
      .map((item: Tickets) => item);
    if (newTickets?.length > 0) {
      const newgeneracionComprobante = { ...generacionComprobante };
      newgeneracionComprobante.tipoVentaId = generacionComprobante.tipoVenta2Id;
      newgeneracionComprobante.serieFolioDeVentaId = generacionComprobante.serieFolioDeVenta2Id;
      newgeneracionComprobante.tickets = newTickets;
      createFacturaGlobal(newgeneracionComprobante);
    }
    else {
      toast.info("Debe seleccionar un tickets", { position: "bottom-right" })
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  const selectInputEmpresaOnChange = (idSelected: number, setFieldAny: any, index: any, values: any, setValues: any) => {
    setCliente([new TipoDropDownValues()]);
    setSerie([new TipoDropDownValues()]);
    setSerie2([new TipoDropDownValues()]);
    setUsuario([new TipoDropDownValues()]);
    setValues(new GeneracionCompronanteTicketsFormValues())
    facturaGlobalParams.empresaId = 0;
    facturaGlobalParams.sucursalId = 0;
    facturaGlobalParams.clienteId = 0;
    fetchSucursales(idSelected).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });

    facturaGlobalParams.empresaId = idSelected;
  }

  const selectInputSucursalOnChange = (idSelected: number, setFieldAny: any, index: any, values: any) => {
    setCliente([new TipoDropDownValues()]);
    setSerie([new TipoDropDownValues()]);
    setSerie2([new TipoDropDownValues()]);
    setUsuario([new TipoDropDownValues()]);
    setFieldAny("clienteId", 0);
    setFieldAny("elaboroId", 0);
    setFieldAny("cobradorId", 0);
    setFieldAny("tipoVentaId", 0);
    setFieldAny("tipoVenta2Id", 0);
    setFieldAny("serieFolioDeVentaId", 0);
    setFieldAny("serieFolioDeVenta2Id", 0);
    facturaGlobalParams.clienteId = 0;
    facturaGlobalParams.sucursalId = idSelected;
    if (facturaGlobalParams.empresaId > 0 && facturaGlobalParams.sucursalId > 0) {
      loadClienteByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: idSelected, clave: 1 }))
        .then((result) => {
          if (result)
            setCliente(result);
        })
      loadUsuarioByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: idSelected }))
        .then((result) => {
          if (result)
            setUsuario(result);
        })
    }
  }

  const selectInputTipoVentaOnChange = (idSelectedTipoVenta: number, setFieldAny: any, index: any, values: any) => {
    facturaGlobalParams.tipoVentaId = idSelectedTipoVenta;
    setFieldAny("serieFolioDeVentaId", 0);
    loadSerieByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, tipoVentaId: idSelectedTipoVenta }))
      .then((result) => {
        if (result)
          setSerie(result);
      })

  }

  const selectInputTipoVenta2OnChange = (idSelectedTipoVenta: number, setFieldValue: any, index: any, values: any) => {
    facturaGlobalParams2.empresaId = facturaGlobalParams.empresaId;
    facturaGlobalParams2.sucursalId = facturaGlobalParams.sucursalId;
    facturaGlobalParams2.tipoVentaId = idSelectedTipoVenta;
    setFieldValue("folio", 0);
    setFieldValue("serieFolioDeVenta2Id", 0);
    loadSerieByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, tipoVentaId: idSelectedTipoVenta }))
      .then((result) => {
        if (result)
          setSerie2(result);
      })
  }

  const selectInputSerie2OnChange = (idSelectedSerie: number, setFieldValue: any) => {
    facturaGlobalParams2.serieId = idSelectedSerie;
    if (facturaGlobalParams2.empresaId && facturaGlobalParams2.sucursalId && facturaGlobalParams2.tipoVentaId && facturaGlobalParams2.serieId) {
      obtenerSiguienteFolio(facturaGlobalParams2).then((clave) => {
        setFieldValue("folio", clave);
        //  generacionCompronanteTickets.id = 0;
      });
    } else {
      setFieldValue("folio", 0);
    }
  }
  const selectInputClienteOnChange = (idSelected: any) => {
    facturaGlobalParams.clienteId = parseInt(idSelected);
  }

  const selectInputClienteOnBluer = () => {
    //asignarDatos(setFieldValue);

    //  asignarDatos(clienteId,setFieldValue);
  }
  const PorFolioCheckOnChange = (idSelected?: any, setFieldValue?: any, values?: any) => {
    if (values?.porFecha)
      setFieldValue("porFecha", !idSelected)
  }
  const PorFechaCheckOnChange = (idSelected?: any, setFieldValue?: any, values?: any) => {
    if (values?.porFolio)
      setFieldValue("porFolio", !idSelected)
  }
  const obtenerTickets = (values: GeneracionCompronanteTickets, setFieldValue: any) => {
    facturaGlobalParams.serieId = values?.serieFolioDeVentaId;
    if (values?.porFecha) {
      facturaGlobalParams.folios = undefined;
      facturaGlobalParams.fechaInicio = values?.fechaInicio;
      facturaGlobalParams.fechaFin = values?.fechaFin;
    } else {
      facturaGlobalParams.fechaInicio = undefined;
      facturaGlobalParams.fechaFin = undefined;
    }
    if (values?.porFolio) {
      facturaGlobalParams.fechaInicio = undefined;
      facturaGlobalParams.fechaFin = undefined;
      facturaGlobalParams.folios = values?.folios;
    } else {
      facturaGlobalParams.folios = undefined;
    }
    facturaGlobalParams.mesId = values?.mesId;
    facturaGlobalParams.periodicidadId = values?.periodicidadId;
    getTickets(facturaGlobalParams).then((result) => {
      setFieldValue("tickets", result)
      if (result !== undefined && result?.length <= 0)
        toast.info("No hay tickets con esos Filtros", { position: "bottom-right" })
    })
  }
  const TicketInformationConsult = () => {
    return (
      <Segment clearing>
        <div><Label as='a' color='red' ribbon>1.- Informacion de Ticket a consultar</Label></div>
        <div className='col25'>
          <MySelectInput
            tabIndex={1}
            placeholder={"Seleccionar"}
            label="Empresa"
            name="empresaId"
            options={EmpresasByDropdown}
            enterInput={enterInput}
            onChange={selectInputEmpresaOnChange}
          />
        </div>
        <div className='col25'>
          <MySelectInput
            tabIndex={2}
            placeholder={"Seleccionar"}
            label="Sucursal"
            name="sucursalId"
            options={sucursales}
            enterInput={enterInput}
            onChange={selectInputSucursalOnChange}
          />
        </div>
        <div className='col25'>
          <MySelectInput
            tabIndex={3}
            placeholder={"Seleccionar"}
            label="Tipo Venta"
            name="tipoVentaId"
            options={tipoVenta}
            enterInput={enterInput}
            onChange={selectInputTipoVentaOnChange}
          />
        </div>
        <div className='col20'>
          <MySelectInput
            tabIndex={4}
            placeholder="Serie"
            label="Serie"
            name="serieFolioDeVentaId"
            options={serie}
            enterInput={enterInput}
          //   onChange={idSelectSerie}
          />
        </div>
      </Segment>);
  }
  const InformationInvoiceGenerated = () => {
    return (
      <Segment>
        <div>  <Label as='a' color='red' ribbon>2.- Informacion de la factura a generar</Label></div>
        <div className='col25'>
          <MySelectInput
            tabIndex={5}
            placeholder={"Seleccionar"}
            label="Tipo Venta"
            name="tipoVenta2Id"
            options={tipoVenta2}
            enterInput={enterInput}
            onChange={selectInputTipoVenta2OnChange}
          />
        </div>
        <div className='col25'>
          <MySelectInput
            tabIndex={6}
            placeholder="Serie"
            label="Serie"
            name="serieFolioDeVenta2Id"
            options={serie2}
            enterInput={enterInput}
            onChange={selectInputSerie2OnChange}
          />
        </div>
        <div className='col25'>
          <MyTextInput
            className="textAlingRight widthtext heighttext input"
            tabIndex={7}
            placeholder=''
            maxLength={10}
            tipoDato={enumTipoDato.NumericoSinPunto}
            //ref={txtFolioRef}
            label="Folio"
            name='folio'
            enterInput={enterInput}
          // onKeyDown={onKeyUpFolio}
          // change={chageEnter}
          />
        </div>
        <div className='col20'>
          Fecha Emision
          <MyDateInput
            tabIndex={8}
            placeholderText="Fecha Emision"
            name="fecha"
            dateFormat="dd/MM/yyyy"
            enterInput={enterInput}
          />
        </div>

        <div className="col30">
          <MySelectInput
            tabIndex={9}
            placeholder="Cliente"
            label="Cliente"
            name="clienteId"
            options={cliente}
            enterInput={enterInput}
            onChange={selectInputClienteOnChange}
            selectWithLabel={true}
            onBlur={selectInputClienteOnBluer}

          />
        </div>

        <div className='col25'>
          <MySelectInput
            tabIndex={10}
            placeholder="Elaborado Por"
            label="Elaborado Por"
            name="elaboroId"
            options={usuario}
            enterInput={enterInput}
          />
        </div>

        <div className='col30'>
          <MySelectInput
            tabIndex={11}
            placeholder="Cobrador"
            label="Cobrador"
            name="cobradorId"
            options={usuario}
            enterInput={enterInput}
          />
        </div>
        <div className='col25'>
          <MySelectInput
            tabIndex={12}
            placeholder={"Seleccionar"}
            label="Mes"
            name="mesId"
            options={c_mesesByDropdown}
            enterInput={enterInput}
          //   onChange={idSelectTipo}
          />
        </div>

        <div className='col25'>
          <MySelectInput
            tabIndex={13}
            placeholder={"Seleccionar"}
            label="Periodicidad"
            name="periodicidadId"
            options={c_periodicidadesByDropdown}
            enterInput={enterInput}
          // onChange={idSelectTipo}
          />
        </div>
        <div className='col25'>
          <MyTextInput
            tabIndex={14}
            placeholder="Año"
            label="Año"
            name="año"
            maxLength={4}
            tipoDato={enumTipoDato.Numerico}
            enterInput={enterInput}
          />
        </div>

      </Segment>);
  }
  const FiltersTicketsBilled = (values: GeneracionCompronanteTickets, setFieldValue: any, isValid: any, dirty: any) => {
    return (
      <Segment>
        <div><Label as='a' color='red' ribbon>3.- Filtros para Tickets a facturar</Label></div>

        <div className="col50">
          <MyCheckInput
            //disabled={values.porFolio}
            placeholder="Por Fecha"
            name="porFecha"
            tabIndex={15}
            enterInput={enterInput}
            onChange={PorFechaCheckOnChange}
          />

        </div>
        <div className="col30">
          <MyCheckInput
            // disabled={values.porFecha}
            placeholder="Por Folio"
            name="porFolio"
            tabIndex={16}
            enterInput={enterInput}
            onChange={PorFolioCheckOnChange}
          />
        </div>
        {/* <div className='col100'></div> */}
        <div className='col25'>
          Fecha Inicio
          <MyDateInput
            disabled={!values.porFecha}
            tabIndex={17}
            placeholderText="Fecha Inicio"
            name="fechaInicio"
            dateFormat="dd/MM/yyyy"
            enterInput={enterInput}

          />
        </div>
        <div className='col25'>
          Fecha Fin
          <MyDateInput
            disabled={!values.porFecha}
            tabIndex={18}
            placeholderText="Fecha Fin"
            name="fechaFin"
            dateFormat="dd/MM/yyyy"
            enterInput={enterInput}

          />
        </div>

        <div className='col10'></div>
        <div className='col30'>
          <MyTextInput

            disabled={!values.porFolio}
            className="textAlingRight widthtext heighttext input"
            tabIndex={19}
            placeholder=''
            label="Folio´s"
            name='folios'
            tipoDato={enumTipoDato.NumericoComa}
            enterInput={enterInput}
          //  onKeyDown={onKeyUpFolio}
          //  change={chageEnter}
          />
        </div>

        <div className='col10' />
        <div className='col30'>
          <Button
            tabIndex={20}
            type="button"
            name="buscar"
            text-align="center"
            disabled={loading}
            loading={loading}
            onClick={() => isValid && dirty ? (obtenerTickets(values, setFieldValue), setErrorButon("")) : setErrorButon("Favor de llenar los campos requeridos")}
            color="blue"
          >
            <Icon name="search" />
            Buscar
          </Button>
          {errorButon ? (
            <Label basic color="red">
              {errorButon}
            </Label>
          ) : null}

        </div>
      </Segment>
    );
  }
  const calculateTotales = (items: any) => {
    return items?.reduce((acc: any, item: any) => {
      if (item.checkList) {
        const subtotal = parseFloat(item?.subtotal || 0);
        const iEPS = parseFloat(item?.iEPS || 0);
        const descuento = parseFloat(item?.descuento || 0);
        const iva = parseFloat(item?.iva || 0);
        acc.subtotal += subtotal;
        acc.iEPS += iEPS;
        acc.descuento += descuento;
        acc.iva += iva;
        acc.total += subtotal - descuento + iva + iEPS;
      }
      return acc;
    }, { subtotal: 0, iEPS: 0, descuento: 0, iva: 0, total: 0 });
  };

  const checkCheckOnChange = (idSelected: any, setFieldValue?: any, values?: any, index?: any, fieldName?: any) => {
    let totals = calculateTotales(values.tickets);
    let subtotal = totals.subtotal;
    let descuento = totals.descuento;
    let iva = totals.iva;
    let iEPS = totals.iEPS;
    let total = totals.total;
    if (idSelected) {
      subtotal += (values?.tickets[index]?.subtotal || 0);
      descuento += (values?.tickets[index]?.descuento || 0);
      iva += (values?.tickets[index]?.iva || 0);
      iEPS += (values?.tickets[index]?.iEPS || 0);
      const totales = ((values?.tickets[index]?.subtotal || 0) - (values?.tickets[index]?.descuento || 0) + (values?.tickets[index]?.iva || 0) + (values?.tickets[index]?.iEPS || 0));
      total += totales;
    }
    else {
      subtotal -= (values?.tickets[index]?.subtotal || 0);
      descuento -= (values?.tickets[index]?.descuento || 0);
      iva -= (values?.tickets[index]?.iva || 0);
      iEPS -= (values?.tickets[index]?.iEPS || 0);
      const totales = ((values?.tickets[index]?.subtotal || 0) - (values?.tickets[index]?.descuento || 0) + (values?.tickets[index]?.iva || 0) + (values?.tickets[index]?.iEPS || 0));
      total -= totales;
    }
    setFieldValue("subtotal", formatDecimal(subtotal, 2));
    setFieldValue("iEPS", formatDecimal(iEPS, 2));
    setFieldValue("descuento", formatDecimal(descuento, 2));
    setFieldValue("iva", formatDecimal(iva, 2));
    setFieldValue("total", formatDecimal(total, 2));
  }
  const checkListAllCheckOnChange = (idSelected?: any, setFieldValue?: any, values?: any) => {
    const newData = values.tickets.map((item: any) => ({
      ...item,
      checkList: idSelected,
    }));
    const totales = calculateTotales(newData)
    //const descuento=calculateDescuento(newData);
    //const iEPS=calculateIEPS(newData)

    setFieldValue("subtotal", formatDecimal(totales.subtotal, 2));
    setFieldValue("descuento", formatDecimal(totales.descuento, 2));
    setFieldValue("iva", formatDecimal(totales.iva, 2));
    setFieldValue("iEPS", formatDecimal(totales.iEPS, 2));
    setFieldValue("total", formatDecimal(totales.total, 2));
    setFieldValue("tickets", newData);
  }
  const TicketsBilled = (values?: any) => {
    return (
      <Segment>
        <div><Label as='a' color='red' ribbon>Tickets a Facturar</Label></div>
        <FieldArray
          name="tickets"
          render={() => (
            <Segment basic className="tabla-container">
              <Table celled
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {values.tickets.length > 0 && <MyCheckInput
                        placeholder=""
                        name="checkListAll"
                        enterInput={enterInput}
                        onChange={checkListAllCheckOnChange}
                      //tabIndex={getIndexTable()}
                      />}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Serie</Table.HeaderCell>
                    <Table.HeaderCell>Folio</Table.HeaderCell>
                    <Table.HeaderCell>Fecha</Table.HeaderCell>
                    <Table.HeaderCell>Subtotal</Table.HeaderCell>
                    <Table.HeaderCell>Descuento</Table.HeaderCell>
                    <Table.HeaderCell>IEPS</Table.HeaderCell>
                    <Table.HeaderCell>IVA</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    {/* <Table.HeaderCell></Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values.tickets?.map(
                    (item: Tickets, index: any) => (
                      <Table.Row key={index.valueOf()} className="paddinTable">
                        <TableCell textAlign="center">
                          <MyCheckInput
                            placeholder=""
                            name={`tickets.${index}.checkList`}
                            enterInput={enterInput}
                            //tabIndex={getIndexTable()}
                            index={index}
                            fieldName="checkList"
                            onChange={checkCheckOnChange}
                          />
                        </TableCell>
                        <MyDataGridRows width="150px" value={item.serieFolioDeVenta?.nombre} alineacion="center" />
                        <MyDataGridRows width="150px" value={item.folio} alineacion="center" />
                        <MyDataGridRows width="100px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
                        <MyDataGridRows width="100px" value={formatDecimal(item.subtotal, 2)} alineacion="right" />
                        <MyDataGridRows width="150px" value={formatDecimal(item?.detalles?.reduce((a, b) => a + b.importeDescuento, 0))} alineacion="right" />
                        <MyDataGridRows width="150px" value={formatDecimal(item.iva, 2)} alineacion="right" />
                        <MyDataGridRows width="150px" value={formatDecimal(item.iva, 2)} alineacion="right" />
                        <MyDataGridRows width="150px" value={formatDecimal(item.total, 2)} alineacion="right" />
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Segment>
          )}
        />
      </Segment>
    );

  }
  const Totals = () => {
    return (<Segment>
      <div className='col60'></div>

      <div className='col15'>

        <label>Subtotal:</label></div>
      <div className='col20'>
        <MyTextInput disabled={true} className=" heighttext input textAlingRight"
          placeholder=''
          name='subtotal' />
      </div>
      <div className='col60'></div>

      <div className='col15'>

        <label>Descuento:</label></div>
      <div className='col20'>
        <MyTextInput disabled={true} className=" heighttext input textAlingRight"
          placeholder=''
          name='descuento' />
      </div>
      <div className='col60'></div>

      <div className='col15'>

        <label>IEPS</label></div>
      <div className='col20'>
        <MyTextInput disabled={true} className=" heighttext input textAlingRight"
          placeholder=''
          name='iEPS' />
      </div>
      <div className='col60'></div>

      <div className='col15'>
        <label>Iva</label></div>
      <div className='col20'>
        <MyTextInput disabled={true} className=" heighttext input textAlingRight"
          placeholder=''
          name='iva' />
      </div>

      <div className='col60'></div>

      <div className='col15'>
        <label>Total</label>
      </div>
      <div className='col20'>
        <MyTextInput disabled={true} className=" heighttext input textAlingRight"
          placeholder=''
          name='total' />
      </div>
    </Segment>);
  }
  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        initialValues={generacionCompronanteTickets}
        validationSchema={validationSchema}
        onSubmit={
          handleSubmit
        }
      >
        {({ values, setFieldValue, isValid, dirty }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Generacion de Factura Global' color='blue' />
            {TicketInformationConsult()}
            {InformationInvoiceGenerated()}
            {FiltersTicketsBilled(values, setFieldValue, isValid, dirty)}
            {TicketsBilled(values)}
            {Totals()}
            <Segment>
              <BarraBotones
                toLink="/"
                loading={loading}
                tabIndex={lastElementTable + 2}
                firstElement={firstElement}
                lastElement={lastElementTable + 4}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})