import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Container, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import { ProductList } from '../../PuntoVenta/dashboard/ProductList';
import { ShoppingCart } from '../../PuntoVenta/dashboard/ShoppingCart';
import { PaginationFormValues } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { Producto } from '../../../../app/models/inventario/Producto';
import { formatDecimal } from '../../../../app/base/Tools';

const PedidoForm: React.FC = observer(() => {
    const { productoStore, cartStore } = useStore();
    const [productosFiltrados, setProductosFiltrados] = useState<Producto[]>([]);
    useEffect(() => {
        productoStore.fetchsProductos(new PaginationFormValues({ empresaId: 1, sucursalId: 1 })).then((productos) => {
            if (productos)
                setProductosFiltrados(productos);
        });
    }, []);
    return (
        <div className="App">
            <Segment inverted vertical textAlign="center" style={{ padding: '2em 0em' }}>
                <Container>
                    <Header as="h1" inverted>Pedidos</Header>
                </Container>
            </Segment>
            <Container style={{ marginTop: '2em' }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <ProductList products={productosFiltrados} addToCart={cartStore.addToCart} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <ShoppingCart items={cartStore.items} removeFromCart={cartStore.removeFromCart} />
                            <Segment>
                                <Message>
                                    <Message.Header>Total a pagar: ${formatDecimal(cartStore.total)}</Message.Header>
                                </Message>
                                <Button primary fluid type="button">Enviar</Button>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    );
});

export default PedidoForm;