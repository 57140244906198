import { observer } from "mobx-react-lite";
import { Button, Grid, Header } from "semantic-ui-react";
import UsuarioList from "./UsuarioList";
import { useStore } from "../../../../app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ReporteUsuarioForm from "../form/ReporteUsuarioForm";
import UsuarioForm from "../form/UsuarioForm";

export default observer(function UsuarioDashboard() {
  const { usuarioStore, tabsStore } = useStore();
  useEffect(() => {
    usuarioStore.loadUsuarios();
  }, [usuarioStore]);

  if (usuarioStore.loadingInitial)
    return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2" icon="user" content="Usuarios" color="blue"></Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Usuario', <UsuarioForm id={0} />)}
              content="Crear Usuario" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Usuario', <ReporteUsuarioForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => usuarioStore.loadUsuarios()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <UsuarioList />
      </Grid.Column>
    </Grid>
  );
});