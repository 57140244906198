import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoVentaTipo } from "../../models/venta/TipoVentaTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class TipoVentaTipoStore {
    tipoVentaTipoRegistry = new Map<number, TipoVentaTipo>();
    selectedTipoVentaTipo: TipoVentaTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadTipoVentaTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const tipoVentaTipos = await agent.TipoVentaTipos.list();

            tipoVentaTipos.forEach(tipoVentaTipo => {
                this.setTipoVentaTipo(tipoVentaTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    get tipoVentaTiposByDropdown() {
        
        let tipoVentaTiposArreg = [new TipoDropDownValues()];
        let tipoVentaTiposOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoVentaTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tipoVentaTiposOpt.key = element.id;
            tipoVentaTiposOpt.text = element.nombre;
            tipoVentaTiposOpt.value = element.id;
            tipoVentaTiposOpt.content = element.nombre;
            tipoVentaTiposArreg.push(tipoVentaTiposOpt);
            tipoVentaTiposOpt = new TipoDropDownValues();

        });
        return tipoVentaTiposArreg;
    }

   

    getTipoVentaTipos = async () => {
        debugger
        this.setLoadingInitial(true);
        const tipoVentaTipos = await agent.TipoVentaTipos.list();
        try {
            tipoVentaTipos.forEach(tipoVentaTipo => {
                this.setTipoVentaTipo(tipoVentaTipo);

            })
            this.setLoadingInitial(false);
            return tipoVentaTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return tipoVentaTipos;
        }
    }

    loadTipoVentaTipo = async (id: number) => {
        let tipoVentaTipo = this.getTipoVentaTipo(id);
        if (tipoVentaTipo) {
            this.selectedTipoVentaTipo = tipoVentaTipo;
            return tipoVentaTipo;
        } else {
            this.loadingInitial = true;
            try {
                tipoVentaTipo = await agent.TipoVentaTipos.details(id);
                this.setTipoVentaTipo(tipoVentaTipo);
                this.selectedTipoVentaTipo = tipoVentaTipo;
                this.setLoadingInitial(false);
                return tipoVentaTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setTipoVentaTipo = (tipoVentaTipo: TipoVentaTipo) => {
        this.tipoVentaTipoRegistry.set(tipoVentaTipo.id, tipoVentaTipo);
    }

    private getTipoVentaTipo = (id: number) => {
        return this.tipoVentaTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoVentaTipo = async (tipoVentaTipo: TipoVentaTipo) => {
        this.loading = true;
        try {
            await agent.TipoVentaTipos.create(tipoVentaTipo);
            runInAction(() => {
                this.tipoVentaTipoRegistry.set(tipoVentaTipo.id, tipoVentaTipo);
                this.selectedTipoVentaTipo = tipoVentaTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteTipoVentaTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.TipoVentaTipos.delete(id);
            runInAction(() => {
                this.tipoVentaTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
