import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoVenta } from "../../models/venta/TipoVenta";
import { toast } from "react-toastify";
import { history } from "../../..";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { GeneracionComprobante } from "../../models/venta/GeneracionComprobante";
import { CorteDeCaja, CorteDeCajaFormValues } from "../../models/venta/CorteDeCaja";
import { CorteDeCajaFiltro,CorteDeCajaFiltroFormValues } from "../../models/venta/CorteDeCajaFiltro";
//import { entityByDropdown } from "../common/funciones/entityByDropdown";

export default class CorteDeCajaStore {
    corteDeCajaRegistry = new Map<number, CorteDeCaja>();
    selectedCorteDeCaja: CorteDeCaja | undefined = undefined;

    corteDeCajaFiltro: CorteDeCajaFiltro = new CorteDeCajaFiltroFormValues();
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get corteDeCajaById() {
        return Array.from(this.corteDeCajaRegistry.values()).sort();
    }
   

    cargarComprobante = async (filtros: PaginationSpecParams) => {
        try {

            const corteDeCaja = await agent.CortesDeCajas.obtenerCorteDeCajaPorFecha(filtros);

            if (corteDeCaja) {
                this.setCorteDeCaja(corteDeCaja);
                //this.selectedGeneracionComprobante = generacionComprobante
                return corteDeCaja;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };


    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoVentaId && filtros.serieId) {
            try {
                const generacionComprobante = await agent.GeneracionComprobantes.obtenerFolio(filtros);

                return generacionComprobante;

            } catch (error) {
                console.log(error);
            }
        } else {
            // this.serieFolioByTipoVentaRegistry.clear();
        }
    };


    get cortesDeCajasByDate() {
        return Array.from(this.corteDeCajaRegistry.values()).sort((a) => a.id);
    }


    loadCortesDeCajas = async () => {
        this.setLoadingInitial(true);
        try {
            const cortesDeCajas = await agent.CortesDeCajas.list();
            cortesDeCajas.forEach(corteDeCaja => {
                this.setCorteDeCaja(corteDeCaja);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCortesDeCajas = async () => {
        this.setLoadingInitial(true);
        const cortesDeCajas = await agent.CortesDeCajas.list();
        try {
            cortesDeCajas.forEach(corteDeCaja => {
                this.setCorteDeCaja(corteDeCaja);
            })
            this.setLoadingInitial(false);
            return cortesDeCajas;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cortesDeCajas;
        }
    }

    loadGeneracionComprobante = async (id: number) => {
        let corteDeCaja = this.getCorteDeCaja(id);
        if (corteDeCaja) {
            this.selectedCorteDeCaja = corteDeCaja;
            return corteDeCaja;
        } else {
            this.loadingInitial = true;
            try {
                corteDeCaja = await agent.CortesDeCajas.details(id);
                this.setCorteDeCaja(corteDeCaja);
                this.selectedCorteDeCaja = corteDeCaja;
                this.setLoadingInitial(false);
                return corteDeCaja;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCorteDeCaja = (corteDeCaja: CorteDeCaja) => {
        this.corteDeCajaRegistry.set(corteDeCaja.id, corteDeCaja);
    }

    private getCorteDeCaja = (id: number) => {
        return this.corteDeCajaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCorteDeCaja = async (corteDeCaja: CorteDeCaja) => {
debugger
        this.loading = true;
        try {
            var nom =  await agent.CortesDeCajas.create(corteDeCaja);
            runInAction(() => {

                this.selectedCorteDeCaja = corteDeCaja;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Corte de caja creado con éxito");
            return nom;
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCorteDeCaja = async (corteDeCaja: CorteDeCaja) => {
        debugger
        this.loading = true;
        try {
            await agent.CortesDeCajas.create(corteDeCaja);
            runInAction(() => {
                this.selectedCorteDeCaja = corteDeCaja;
                this.editMode = false;
                this.loading = false;
            })
            toast.success("Corte De caja Actualizado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCorteDeCaja = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.CortesDeCajas.delete(id);
                runInAction(() => {
                    this.corteDeCajaRegistry.delete(id);
                    this.loading = false;
                })
                toast.success("Corte De Caja eliminado con éxito");

            } catch (error) {
                toast.success("Problema al eliminar el Corte de Caja");
                console.log(error);
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    }
}
