import { ConceptoDeProveedorTipo } from "./ConceptoDeProveedorTipo";

export interface ConceptoDeProveedor {
    id: number;
    clave:number;
    nombre: string;
    abreviacion: string;
    conceptoDeProveedorTipoId: number;
    conceptoDeProveedorTipo?:ConceptoDeProveedorTipo;
}

export interface IConceptoDeProveedorFormValues extends Partial<ConceptoDeProveedor>{
    selectedIdTipo: any;
}

export class ConceptoDeProveedorFormValues implements IConceptoDeProveedorFormValues{
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    abreviacion: string="";
    conceptoDeProveedorTipoId: number=0;
    conceptoDeProveedorTipo?:ConceptoDeProveedorTipo;
    selectedIdTipo: undefined;

    constructor(init?: ConceptoDeProveedor){
        Object.assign(this,init);
    }
}