import { observer } from 'mobx-react-lite'
import { useEffect } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import TipoCompraList from './TipoDeCompraList'
import TipoDeCompraForm from '../form/TipoDeCompraForm';

export default observer(function TipoCompraDashboard() {
  const { tipoDeCompraStore, tabsStore } = useStore();
  const { loadTiposDeCompras } = tipoDeCompraStore;
  useEffect(() => {
    tipoDeCompraStore.loadTiposDeCompras();
  }, [tipoDeCompraStore]);

  useEffect(() => {
    loadTiposDeCompras();
  }, [loadTiposDeCompras])

  if (tipoDeCompraStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' content='Tipos Compras' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Crear Tipo de Compra', <TipoDeCompraForm id={0} />)}
              content="Crear Tipo de Compra" color="blue" />
            <Button onClick={() => tipoDeCompraStore.loadTiposDeCompras()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <TipoCompraList />
      </Grid.Column>
    </Grid>
  )
})