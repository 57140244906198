import { CondicionDePago } from "../gestionCliente/CondicionDePago";
import { FormaDePago } from "../gestionCliente/FormaDePago";

export interface GeneracionComprobantePagoDetalle {
    id: number;
    condiciondePagoId?:number;
    condiciondePago?:CondicionDePago;
    formaDePagoId:number;
    formaDePago?:FormaDePago;
    importe:number;
}
export interface IGeneracionComprobantePagoDetalleFormValues extends Partial<GeneracionComprobantePagoDetalle> {
    id: any;
}
export class GeneracionComprobantePagoDetalleFormValues implements IGeneracionComprobantePagoDetalleFormValues {
    id: number =0;
    condiciondePagoId?:number=0;
    condiciondePago?:CondicionDePago
    formaDePagoId:number =0;
    formaDePago?:FormaDePago;
    importe:number=0;   
    constructor(init?: GeneracionComprobantePagoDetalle) {
        Object.assign(this, init);
    }
}