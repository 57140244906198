import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { Sucursal, SucursalFormValues } from "../../../../app/models/configuracion/Sucursal";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsDirecciones, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import Direccion from "../../../Component/Direccion";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { OnChangeProps } from "../../../../app/models/myProps";
import { PaginationFormValues } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import BarraDireccion from "../../../Component/BarraDireccion";

interface idProps {
  id: number
}

export default observer(function SucursalForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 7;
  const { sucursalStore, empresaStore, listaDePrecioStore, modalStore, proveedorStore } = useStore();
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore;
  const { loadSucursalByIdEmpresa, obtenerSiguienteClave } = sucursalStore
  const { empresaSucursal } = proveedorStore
  const { openModal, closeModal } = modalStore;
  const {
    createSucursal,
    loadSucursal,
    loadingInitial,
    sucursalById,
    loading,
  } = sucursalStore;

  const { ListaDePreciosByDropdown, loadListaDePrecio } = listaDePrecioStore;
  const [sucursal, setSucursal] = useState(new SucursalFormValues());
  const [empresas, setempresas] = useState([
    { key: '0', value: '0', text: 'Seleccionar' }
  ])

  useEffect(() => {
    empresaStore.getEmpresas();
    void loadEntityEmpresas();
  }, [])

  useEffect(() => {
    listaDePrecioStore.getListasDePrecios();
  }, [])

  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave de la Sucursal es requerida.'),
    nombre: Yup.string().required('El nombre de la Sucursal es requerido.'),
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerida."),
    listaDePrecioId: Yup.number()
      .min(1, "Favor de seleccionar una lista de precio.")
      .required("La Lista de precios es requerida.")
  });

  useEffect(() => {
    if (id) {
      loadSucursal(id).then(sucursal => {
        setSucursal(new SucursalFormValues(sucursal))
      });
    }
  }, [])

  function handleSubmit(sucursal: Sucursal) {
    if (sucursal.direccion.paisId > 0) {
      if (sucursal.direccion.estadoId > 0) {
        if (sucursal.empresa) {
          const empresaSelected = empresas.find(
            (a) => a.key === sucursal.empresaId.toString()
          );
          if (empresaSelected) {
            sucursal.empresa.nombre = empresaSelected.text;
            sucursal.empresa.id = Number.parseInt(empresaSelected.key);
            sucursal.empresa.clave = Number.parseInt(empresaSelected.value);
          }
        }
        if (sucursal.empresaId.toString() !== "0" && sucursal.nombre !== null) {
          createSucursal(sucursal);
        } else {
          toast.info("Favor de llenar campos");
        }
      } else {
        toast.info("Favor de llenar el campo Estado.");
      }
    } else {
      toast.info("Favor de llenar el campo Pais.");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  }

  function closeModals() {
    closeModal();
  }

  const selectEmpresaOnChange = ({ value, setFieldValue, values }: OnChangeProps) => {
    empresaSucursal.empresaId = value;
    if (value > 0)
      obtenerSiguienteClave(new PaginationFormValues({ empresaId: value })).then((clave) => {
        setFieldValue("clave", clave);
        setFieldValue("id", 0);
      });
    void loadSucursalByIdEmpresa(empresaSucursal.empresaId);
  }

  if (loadingInitial) return (<LoadingComponent content='Loading sucursal' />)

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={sucursal}
      >
        {({ values, setFieldValue }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Sucursal
              </Label>
              <div></div>
              <div className='col80 '>
                <MySelectInput tabIndex={1}
                  placeholder={"Seleccionar"}
                  label="Empresa"
                  name="empresaId"
                  options={EmpresasByDropdown}
                  enterInput={enterInput} onChangeProps={selectEmpresaOnChange} />
              </div>
              <div className='col20'>
                <MyTextInput placeholder="Clave" label="Clave" name="clave" tabIndex={0} disabled={true} />
              </div>

              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput placeholder="Nombre" autoFocus={true} label="Nombre" name="nombre" tabIndex={2} enterInput={enterInput} />
              </div>
            </Segment>
            <Segment clearing>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={3}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <Direccion
                      direccion={values.direccion}
                      setDireccion={(data) => {
                        setFieldValue("direccion", data)
                        sucursal.direccion = data;
                      }}
                      closeModals={closeModals}
                      tabIndex={lastElement}
                    />,
                    enumHeaderModalsDirecciones.sucursal,
                    'mini'
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="map marker alternate" />
                Direccion
              </Button>
              <div className="col70">
                <BarraDireccion
                  direccion={values.direccion}
                ></BarraDireccion>
              </div>
            </Segment>
            <Segment clearing>
              <div className='col20'>
                <MySelectInput
                  tabIndex={4}
                  placeholder={"Seleccionar"}
                  label="Lista de Precio"
                  name="listaDePrecioId"
                  options={ListaDePreciosByDropdown}
                  enterInput={enterInput}
                  inputWidth="140px"
                  menuWidth="300px"
                />
              </div>
              <div >
                <MyTextInput tabIndex={5} label="Codígo contable" placeholder="Codigo contable" name="codigoContable" enterInput={enterInput} />
              </div>
              <div >
                <MyTextInput tabIndex={6} label="Centro de Costo" placeholder="Centro de Costo" name="centroCosto" enterInput={enterInput} />
              </div>
            </Segment>

            <Segment clearing>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={7}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <FechaObservaciones
                      fechaObservacion={values.fechaObservacion}
                      setFechaObservacion={(data) => setFieldValue("fechaObservacion", data)}
                      closeModals={closeModals}
                      tabIndex={lastElement}
                    />,
                    enumHeaderModalsFechasObservaciones.Sucursal
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="calendar alternate outline" />
                Fechas y Observaciones
              </Button>
              <div className="col70">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/Sucursales"
                loading={loading}
                tabIndex={8}
                id={`id`}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})