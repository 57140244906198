import React, { useEffect, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Modal, ModalContent, ModalHeader, Segment, Table } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import { GeneracionComprobantePago, GeneracionComprobantePagoFormValues } from '../../../../app/models/venta/GeneracionComprobantePagos';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import { GeneracionComprobantePagoDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobantePagosDetalle';

interface Props {
  cheques: GeneracionComprobantePago;
  closeModals: () => void;
  setPagos: (setFO: GeneracionComprobantePagoFormValues) => void;
  tabIndex: number;
}

export default function CapturaCheques(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { condicionPagoStore, formaPagoStore } = useStore();
  const { condicionesPagosByDropdown } = condicionPagoStore;
  const { formasPagosByDropdown } = formaPagoStore;
  const [GeneracionComprobantePago, setGeneracionComprobantePago] = useState(new GeneracionComprobantePagoFormValues());
  const validationSchema = Yup.object({
    importe: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
  });
  useEffect(() => {
    condicionPagoStore.getCondicionesPagos();
  }, [condicionPagoStore])

  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    props.setPagos(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  function deletedeclined() {
    setOpen({ abrir: false, index: 0 })
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    //deleteGeneracionComprobante(id).then((respuest) => {
    arrayHelpers.remove(index);
    setOpen({ abrir: false, index: 0 })
    // setLastElementTable(
    //   (prevLastElementTable) =>
    //     prevLastElementTable - numberControlsTable
    // );

    // });
    //setTarget(e);
    //closeModal();
  }
  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };
  return (

    <Formik
      //validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={GeneracionComprobantePago}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <Segment>
            <div className="col100">
              <FieldArray
                name="pagos"
                render={(arrayHelpers) => (
                  /////
                  <Segment>
                    <Table
                      style={{
                        overflow: "auto",
                        display: "inline-block",
                        maxHeight: "inherit",
                        width: 830,
                        height: 350
                      }}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>TC</Table.HeaderCell>
                          <Table.HeaderCell>Serie</Table.HeaderCell>
                          <Table.HeaderCell>Folio</Table.HeaderCell>
                          <Table.HeaderCell>Nombre</Table.HeaderCell>
                          <Table.HeaderCell>Fecha</Table.HeaderCell>
                          <Table.HeaderCell>No.Cheque</Table.HeaderCell>
                          <Table.HeaderCell>Banco</Table.HeaderCell>
                          <Table.HeaderCell>Importe</Table.HeaderCell>
                          <Table.HeaderCell>No. Aut</Table.HeaderCell>
                          <Table.HeaderCell>Cta.Protección</Table.HeaderCell>

                          <Table.HeaderCell>Observaciones</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {values.pagos?.map(
                          (pago, index) => (

                            <Table.Row key={index.valueOf()} className="paddinTable">

                              <Table.Cell className="paddinTable">
                                {" "}
                                <MySelectInput
                                  tabIndex={14}
                                  placeholder="TC"
                                  label=""
                                  name={`pagos[${index}].condiciondePagoId`}
                                  options={condicionesPagosByDropdown}
                                  enterInput={enterInput}
                                  index={index}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}

                                <MySelectInput
                                  tabIndex={15}
                                  placeholder="Serie"
                                  label=""
                                  name={`pagos[${index}].formaDePagoId`}
                                  options={formasPagosByDropdown}
                                  enterInput={enterInput}
                                  index={index}

                                />
                              </Table.Cell>



                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Folio"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Nombre"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Fecha"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="No Cheque"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell className="paddinTable">
                                {" "}
                                <MySelectInput
                                  tabIndex={14}
                                  placeholder="Banco"
                                  label=""
                                  name={`pagos[${index}].condiciondePagoId`}
                                  options={condicionesPagosByDropdown}
                                  enterInput={enterInput}
                                  index={index}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Importe"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="No. Aut"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Cta. Proteccion"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>

                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Observaciones"
                                  name={`pagos[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Button icon
                                  tabIndex={getIndexTable(2, 7)}
                                  name={pago.id}
                                  type="Button"
                                  disabled={false}
                                  onClick={() => setOpen({ abrir: true, index: index })}
                                  floated="right"
                                  //    content="Eliminar"
                                  color="red"
                                >
                                  <Icon name='delete' />
                                </Button>
                              </Table.Cell>
                              <Modal
                                onClose={() => setOpen({ abrir: false, index: 0 })}
                                open={open.abrir}
                                size='mini'
                              >
                                <ModalHeader>{enumHeaderModals.DetallesComprobante}</ModalHeader>
                                <ModalContent>
                                  <DeleteModals
                                    deleteConfirmed={deleteConfirmed}
                                    deletedeclined={deletedeclined}
                                    e={""}
                                    id={pago.id}
                                    nombre={"Fila"}
                                    arrayHelpers={arrayHelpers}
                                    index={open.index}
                                  />
                                </ModalContent>
                                {/* <ModalActions>
                            <Button
                              icon='check'
                              content='All Done'
                              onClick={() => setOpen(false)}
                            />
                          </ModalActions> */}
                              </Modal>
                            </Table.Row>


                          )
                        )

                        }

                        {!values.pagos?.length ? (
                          arrayHelpers.push(new GeneracionComprobantePagoDetalleFormValues())
                        ) : null}



                      </Table.Body>
                    </Table>
                  </Segment>
                  /////
                )}
              />
            </div>
          </Segment>
          <Segment>
            <Button.Group>
              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>

  )
}