import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormikContext, FieldArray } from 'formik';
import { Table, Button, Icon, Segment, Label } from 'semantic-ui-react';
import MySelectInput from '../../../../app/common/form/MySelectInput';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import { useStore } from '../../../../app/stores/store';
import { UserContext } from '../../../../app/context/UserContext';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { OnChangeProps, OnKeyDownProps } from '../../../../app/models/myProps';
import { TipoDropDownValues, TipoDropdown } from '../../../../app/models/configuracion/TipoDropdown';
import { Producto, ProductoFormValues } from '../../../../app/models/inventario/Producto';
import { enumTipoDato } from '../../../../app/common/form/types/myTextinput-types';
import { TraspasoEntreSucursalDetalleFormValues } from '../../../../app/models/inventario/TraspasoEntreSucursalDetalle';





const TraspasoEntresSucursalBody: React.FC<{ tabuladorEnterDom?: (e: number) => void; }> = observer((tabuladorEnterDom) => {

    const [bodyParams] = useState<PaginationSpecParams>(new PaginationFormValues());
    const { values, setFieldValue, isValid, dirty } = useFormikContext<any>();
    const { productoStore, modalStore, traspasoEntreSucursalStore } = useStore();
    // const { lastElementTable, setLastElementTable } = traspasoEntreSucursalStore
    const lastInputRef = useRef<any>(null);
    //const [lastElementTable, setLastElementTable] = useState(numberControlsInTable);
    const { fetchProductos, getProductoById } = productoStore
    const handleAddRow = (arrayHelpers: any) => {
        // const newRow = {
        //     productoId: 0,
        //     producto: new ProductoFormValues(),
        //     cantidad: 0,
        //     unidadMedida1Id: 0,
        //     nombreProducto: "",
        //     conversion: 0,
        //     costo: 0,
        //     cargoExtra: 0,
        //     totalCosto: 0,
        //     tipoVentaId: 0,
        // };
        arrayHelpers.push(new TraspasoEntreSucursalDetalleFormValues());
        traspasoEntreSucursalStore.setLastElementTable(traspasoEntreSucursalStore.lastElementTable + 3);
        // Delay the focus setting to ensure the new row is rendered
        //  index === values.TraspasoEntresSucursalDetalles.length - 1 
        setTimeout(() => {
            if (lastInputRef.current) {
                lastInputRef.current?.Focus();
            }
        }, 0);
    };

    const handleFieldKeyDown = (props: OnKeyDownProps) => {
        if (props.event.key !== "Enter" && props.event.key !== "Tab") {
            return;
        }

        if (isValid)
            handleAddRow(props.arrayHelpers);
    }
    const handleFieldChange = (props: OnChangeProps) => {
        const { index, name, value } = props;
        const detalles = [...values.TraspasoEntresSucursalDetalles];
        if (index !== undefined && name !== undefined) {
            detalles[index][name] = value;
            setFieldValue('TraspasoEntresSucursalDetalles', detalles);
            setFieldValue('tipoVentaId', 8);
            // Update calculated fields
            if (name === 'cantidad' || name === 'cargoExtra') {
                const cantidad = parseFloat(detalles[index].cantidad) || 0;
                const costo = parseFloat(detalles[index]?.producto.controlListaDePrecio?.costo) || 0;
                const cargoExtra = parseFloat(detalles[index].cargoExtra) || 0;
                detalles[index].totalCosto = cantidad * costo + cargoExtra;

            }
            else if (name === 'productoId') {
                if (detalles[index].productoId) {
                    const productoById = getProductoById(detalles[index].productoId);
                    if (productoById) {
                        if (detalles[index].productoId === productoById?.id) {
                            detalles[index].producto = productoById;
                            detalles[index].nombreProducto = productoById.nombre;
                            detalles[index].unidadMedida1Id = productoById.unidadMedida1Id;
                            detalles[index].costo = productoById.controlListaDePrecio[0]?.costo ? productoById.controlListaDePrecio[0].costo : 0;
                            //  detalles[index].producto.controlListaDePrecio.costo = productoById.controlListaDePrecio?.costo ? productoById?.controlListaDePrecio?.costo : 0;
                            //                  producto.controlListaDePrecio.costo
                            //ssetFieldValue(`TraspasoEntresSucursalDetalles[${index}].producto.controlListaDePrecio.costo`,(productoById.controlListaDePrecio||productoById.controlListaDePrecio!==null||productoById.controlListaDePrecio!==undefined) ? productoById?.controlListaDePrecio?.costo : "0ss");
                            // setFieldValue(`TraspasoEntresSucursalDetalles[${index}].producto.unidadMedida1.nombre`, producto.unidadMedida1?.nombre);
                        }

                    }
                }
            }
        }
        setFieldValue('TraspasoEntresSucursalDetalles', detalles);
    }
    const loading = false;
    const { empresaLog } = useContext(UserContext);
    //const lastElementTable = 242;
    const [target, setTarget] = useState("");
    const initialIndexTable = 9;
    const { closeModal } = modalStore;
    // const enterInput = (tabIndex: number) => {
    //     enterInputs(tabIndex, firstElement, lastElementTable + 3);
    // }
    const [productos, setProductos] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
    useEffect(() => {
        let isMounted = true;
        const fetchSucuarsalData = async () => {
            if (empresaLog?.id) {
                try {
                    setProductos([new TipoDropDownValues()]);
                    // if (values?.TraspasoEntresSucursalDetalles?.length > 0)
                    //     values?.TraspasoEntresSucursalDetalles?.forEach((traspaso: any, index: any) => {
                    //         setFieldValue(`TraspasoEntresSucursalDetalles[${index}].productoId`, 0);
                    //     })
                    if (values?.TraspasoEntresSucursalDetalles?.length > 0) {
                        const detallesActualizados = values.TraspasoEntresSucursalDetalles.map((traspaso: any) => ({
                            ...traspaso,
                            productoId: 0,
                        }));
                        setFieldValue('TraspasoEntresSucursalDetalles', detallesActualizados);
                    }
                    if (values.sucursalId > 0) {
                        const response = await fetchProductos({ ...bodyParams, empresaId: empresaLog.id, sucursalId: values.sucursalId });
                        if (isMounted && response) {
                            setProductos(response);
                        }
                    }
                } catch (error) {
                    console.error('Error al obtener datos de la empresa:', error);
                }

            }
        }
        fetchSucuarsalData();
        return () => {
            isMounted = false;
        };



    }, [empresaLog?.id, values.sucursalId]);
    return (
        <Segment>
            <div><Label as='a' color='red' ribbon>Productos</Label></div>
            <FieldArray
                name="TraspasoEntresSucursalDetalles"
                render={(arrayHelpers) => (
                    <Segment basic className="tabla-container">
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Producto</Table.HeaderCell>
                                    <Table.HeaderCell>Nombre Producto</Table.HeaderCell>
                                    <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                    <Table.HeaderCell>Unidad Medida</Table.HeaderCell>
                                    <Table.HeaderCell>Conversión</Table.HeaderCell>
                                    <Table.HeaderCell>Costo</Table.HeaderCell>
                                    <Table.HeaderCell>Cargo Extra</Table.HeaderCell>
                                    <Table.HeaderCell>Total Costo</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {values.TraspasoEntresSucursalDetalles.map((detalle: any, index: any) => (
                                    <Table.Row key={index} className="paddinTable">
                                        <Table.Cell>
                                            <MySelectInput
                                                placeholder='Seleccionar'
                                                name={`TraspasoEntresSucursalDetalles[${index}].productoId`}
                                                options={productos}
                                                tabIndex={initialIndexTable + index * 3 + 1}
                                                index={index}
                                                onChangeProps={handleFieldChange}
                                                fieldName='productoId'
                                                enterInput={tabuladorEnterDom.tabuladorEnterDom}
                                                ref={lastInputRef}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].producto.nombre`}
                                                disabled
                                                tabIndex={-1}

                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].cantidad`}
                                                //   type="number"
                                                tabIndex={initialIndexTable + index * 3 + 2}
                                                onChangeProps={handleFieldChange}
                                                index={index}
                                                fieldName='cantidad'
                                                enterInput={tabuladorEnterDom.tabuladorEnterDom}
                                                tipoDato={enumTipoDato.NumericoSinPunto}
                                                arrayHelpers={arrayHelpers}
                                                onKeyDownProps={
                                                    index === values.TraspasoEntresSucursalDetalles.length - 1 ? handleFieldKeyDown : undefined

                                                    //     (e) => {
                                                    //     if (e.key === 'Tab') {
                                                    //         e.preventDefault();
                                                    //         handleAddRow(arrayHelpers);
                                                    //     }
                                                    // }
                                                }
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].producto.unidadMedida1.nombre`}
                                                disabled
                                                tabIndex={-1}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].conversion`}
                                                disabled
                                                tabIndex={-1}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].costo`}
                                                disabled
                                                tabIndex={-1}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].cargoExtra`}
                                                tipoDato={enumTipoDato.Numerico}
                                                disabled
                                                //   tabIndex={initialIndexTable + index * 10 + 3}
                                                tabIndex={-1}
                                                onChangeProps={handleFieldChange}
                                                index={index}
                                                enterInput={tabuladorEnterDom.tabuladorEnterDom}
                                                fieldName='cargoExtra'
                                            // arrayHelpers={arrayHelpers}
                                            // onKeyDownProps={
                                            //     index === values.TraspasoEntresSucursalDetalles.length - 1 ? handleFieldKeyDown : undefined

                                            //     //     (e) => {
                                            //     //     if (e.key === 'Tab') {
                                            //     //         e.preventDefault();
                                            //     //         handleAddRow(arrayHelpers);
                                            //     //     }
                                            //     // }
                                            // }
                                            // ref={index === values.TraspasoEntresSucursalDetalles.length - 1 ? lastInputRef : null}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MyTextInput
                                                name={`TraspasoEntresSucursalDetalles[${index}].totalCosto`}
                                                disabled
                                                tabIndex={-1}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            {/* <Button
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                        icon="trash"
                                        tabIndex={initialIndexTable + index * 10 + 4}
                                    /> */}
                                            <Button icon
                                                tabIndex={initialIndexTable + index * 3 + 3}
                                                name={index}
                                                type="Button"
                                                disabled={
                                                    loading
                                                    &&
                                                    target ===
                                                    String(index.id)

                                                }
                                                loading={
                                                    loading &&
                                                    target ===
                                                    String(index.id)

                                                }
                                                onClick={() => {
                                                    // openModal(
                                                    //     <DeleteModals
                                                    //         deleteConfirmed={deleteConfirmed}
                                                    //         deletedeclined={deletedeclined}
                                                    //         e={e.currentTarget.name.toString()}
                                                    //         id={detalle?.productoId}
                                                    //         nombre={values.detalles ? values.detalles[index].producto?.nombre : "Fila"}
                                                    //         arrayHelpers={arrayHelpers}
                                                    //         index={index}
                                                    //     />,
                                                    //     enumHeaderModals.DetallesComprobante
                                                    // );
                                                    arrayHelpers.remove(index);
                                                    traspasoEntreSucursalStore.setLastElementTable(traspasoEntreSucursalStore.lastElementTable - 3);
                                                }}
                                                floated="right"
                                                //    content="Eliminar"
                                                color="red"
                                            >
                                                <Icon name='delete' />
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="9">
                                        <Button tabIndex={traspasoEntreSucursalStore.lastElementTable + 1} type="button" onClick={() => isValid && dirty ? handleAddRow(arrayHelpers) : null}>
                                            Agregar Renglón
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Segment>
                )}
            />
        </Segment>
    );
});

export default TraspasoEntresSucursalBody;
