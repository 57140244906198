import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react'
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { useStore } from "../../../app/stores/store";
import { PaginationFormValues, PaginationSpecParams } from "../../../app/models/Especificaciones/PaginationSpecParams";
import { enterInputs, tabuladorButtons } from "../../../app/common/funciones/tabuladorEnter";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyDateInput from "../../../app/common/form/MyDateInput";
import Busqueda from "../../General/Busqueda";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { enumHeaderModalsBusqueda } from "../../modals/MensajeModals";
import { OnKeyDownProps } from "../../../app/models/myProps";
import { enumTipoDato } from "../../../app/common/form/types/myTextinput-types";
import { updateInputValue } from "../../../app/common/util/util";

export default observer(function ReporteProductosCompradosForm() {
  const firstElement = 0;
  const lastElement = 21;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { reporteProductosCompradosStore } = useStore();
  const { imprimirReporteProductosComprados, loading, loadingInitial } = reporteProductosCompradosStore
  useParams<{ id: string; }>();
  const [reporteProductosComprados, setReporteProductosComprados] = useState(new PaginationFormValues());
  const TipoReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];
  const OrdenReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "Cantidad(menor a mayor)",
      value: 1,
      contex: "Cantidad(menor a mayor)",
    },
    {
      key: 2,
      text: "Cantidad(mayor a menor)",
      value: 2,
      contex: "Cantidad(mayor a menor)",
    },
    {
      key: 3,
      text: "Importe(menor a mayor)",
      value: 3,
      contex: "Importe(menor a mayor)",
    },
    {
      key: 4,
      text: "Importe(mayor a menor)",
      value: 4,
      contex: "Importe(mayor a menor)",
    },
    {
      key: 5,
      text: "No Comprados",
      value: 5,
      contex: "No Comprados",
    },
  ];
  const EstadoByDropdown = [
    {
      key: 0,
      text: "Todos",
      value: 0,
      contex: "Todos",
    },
    {
      key: 1,
      text: "Vigentes",
      value: 1,
      contex: "Vigentes",
    },
    {
      key: 2,
      text: "Cancelados",
      value: 2,
      contex: "Cancelados",
    },
  ];

  const inputEmpresaRef = useRef<any>(null);
  const inputSucursalRef = useRef<any>(null);
  const inputTipoCompraRef = useRef<any>(null);
  const inputSerieRef = useRef<any>(null);
  const inputCuentaDeCompraRef = useRef<any>(null);
  const inputProveedorRef = useRef<any>(null);
  const inputMonedaRef = useRef<any>(null);
  const inputUsuarioTrabajadorRef = useRef<any>(null);
  const inputUsuarioCompradorRef = useRef<any>(null);

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().typeError("La fecha inicio debe ser fecha.").required("La fecha inicio es requerido"),
    fechaFin: Yup.date().typeError("La fecha fin debe ser fecha.").required("La fecha fin es requerido").min(Yup.ref('fechaInicio'), 'La fecha fin debe ser mayor a la fecha de inicio.'),
    // tipoReporte: Yup.number()
    //   .min(1, "Favor de seleccionar un tipo Reporte.")
    //   .required("El tipo Reporte es requerido."),
    // ordenado: Yup.number()
    //   .min(1, "Favor de seleccionar un Ordenado.")
    //   .required("El Ordenado es requerido."),
    importe: Yup.number(),
  });

  function closeModals() {
    closeModal();
  }

  const closeModalsEmpresaAyuda = () => {
    if (inputEmpresaRef.current)
      inputEmpresaRef.current.Focus();
    closeModal();
  }

  const closeModalsSucursalAyuda = () => {
    if (inputSucursalRef.current)
      inputSucursalRef.current.Focus();
    closeModal();
  }

  const closeModalsTipoCompraAyuda = () => {
    if (inputTipoCompraRef.current)
      inputTipoCompraRef.current.Focus();
    closeModal();
  }

  const closeModalSerieAyuda = () => {
    if (inputSerieRef.current)
      inputSerieRef.current.Focus();
    closeModal();
  }
  const closeModalProveedorAyuda = () => {
    if (inputProveedorRef.current)
      inputProveedorRef.current.Focus();
    closeModal();
  }

  const closeModalMonedaAyuda = () => {
    if (inputMonedaRef.current)
      inputMonedaRef.current.Focus();
    closeModal();
  }

  const closeModalCuentaDeCompraAyuda = () => {
    if (inputCuentaDeCompraRef.current)
      inputCuentaDeCompraRef.current.Focus();
    closeModal();
  }

  const closeModalUsuarioCompradorAyuda = () => {
    if (inputUsuarioCompradorRef.current)
      inputUsuarioCompradorRef.current.Focus();
    closeModal();
  }
  const closeModalUsuarioTrabajadorAyuda = () => {
    if (inputUsuarioTrabajadorRef.current)
      inputUsuarioTrabajadorRef.current.Focus();
    closeModal();
  }

  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setReporteProductosComprados((state) => ({
      ...state,
      nombre: "REPORTE DE PRODUCTOS MÁS,MENOS Y NO COMPRADOS ",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      noArticluosIncluir: 0,
      Estado: 0,
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  const abrirBusqueda = (values: any, setFieldValue: any, name: string) => {
    openModal(
      <Busqueda
        name={name}
        values={values}
        tabIndex={32231213}
        closeModals={
          name === "Empresa" ? closeModalsEmpresaAyuda :
            (name === "Sucursal" ? closeModalsSucursalAyuda :
              (name === "TipoCompra" ? closeModalsTipoCompraAyuda :
                (name === "Serie" ? closeModalSerieAyuda :
                  (name === "Proveedor" ? closeModalProveedorAyuda :
                    (name === "CuentaDeCompra" ? closeModalCuentaDeCompraAyuda :
                      (name === "Comprador" ? closeModalUsuarioCompradorAyuda :
                        (name === "Trabajador" ? closeModalUsuarioTrabajadorAyuda :
                          (name === "Moneda" ? closeModalMonedaAyuda :
                            closeModals))))))))
        }
        onCopy={(data) => {
          if (data) {
            if (name === "Empresa") {
              setFieldValue("empresa", updateInputValue(values.empresa, data.clave));
            }
            if (name === "Sucursal")
              setFieldValue("sucursal", (inputSucursalRef.current.GetText() === "" ? "" : inputSucursalRef.current.GetText() + ",") + data.clave);
            if (name === "TipoCompra")
              setFieldValue("tipoCompra", (inputTipoCompraRef.current.GetText() === "" ? "" : inputTipoCompraRef.current.GetText() + ",") + data.clave);
            if (name === "Serie")
              setFieldValue("serie", (inputSerieRef.current.GetText() === "" ? "" : inputSerieRef.current.GetText() + ",") + data.clave);
            if (name === "Proveedor")
              setFieldValue("proveedor", (inputProveedorRef.current.GetText() === "" ? "" : inputProveedorRef.current.GetText() + ",") + data.clave);
            if (name === "CuentaDeCompra")
              setFieldValue("cuentaCompra", (inputCuentaDeCompraRef.current.GetText() === "" ? "" : inputCuentaDeCompraRef.current.GetText() + ",") + data.clave);
            if (name === "Comprador")
              setFieldValue("comprador", (inputUsuarioCompradorRef.current.GetText() === "" ? "" : inputUsuarioCompradorRef.current.GetText() + ",") + data.clave);
            if (name === "Trabajador")
              setFieldValue("trabajador", (inputUsuarioTrabajadorRef.current.GetText() === "" ? "" : inputUsuarioTrabajadorRef.current.GetText() + ",") + data.clave);
            if (name === "Moneda")
              setFieldValue("moneda", (inputMonedaRef.current.GetText() === "" ? "" : inputMonedaRef.current.GetText() + ",") + data.clave);
          }
        }}
      />,

      name === "Empresa" ? enumHeaderModalsBusqueda.EmpresaBusqueda :
        name === "Sucursal" ? enumHeaderModalsBusqueda.SucursalBusqueda :
          name === "TipoCompra" ? enumHeaderModalsBusqueda.TipoCompraBusqueda :
            name === "Serie" ? enumHeaderModalsBusqueda.SerieBusqueda :
              name === "Proveedor" ? enumHeaderModalsBusqueda.ProveedorBusqueda :
                name === "CuentaDeCompra" ? enumHeaderModalsBusqueda.CuentaDeCompraBusqueda :
                  name === "Comprador" ? enumHeaderModalsBusqueda.CompradorBusqueda :
                    name === "Trabajador" ? enumHeaderModalsBusqueda.TrabajadorBusqueda :
                      name === "Moneda" ? enumHeaderModalsBusqueda.MonedaBusqueda :
                        (enumHeaderModalsBusqueda.TrabajadorBusqueda),

      (name === "Empresa" || name === "Sucursal") ? "tiny" : "small",
      true, "search"
    );
  }

  const onKeyDownInputEmpresa = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Empresa");
    }
  }

  const onKeyDownInputSucursal = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Sucursal");
    }
  }

  const onKeyDownInputTipoCompra = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "TipoCompra");
    }
  }

  const onKeyDownInputSerie = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Serie");
    }
  }

  const onKeyDownInputProveedor = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Proveedor");
    }
  }

  const onKeyDownInputCuentaDeCompra = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "CuentaDeCompra");
    }
  }

  const onKeyDownInputUsuarioComprador = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Comprador");
    }
  }
  const onKeyDownInputUsuarioTrabajador = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Trabajador");
    }
  }

  const onKeyDownInputMoneda = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Moneda");
    }
  }

  function handleSubmit(reporteProductosComprados: PaginationSpecParams) {
    void imprimirReporteProductosComprados(reporteProductosComprados);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (
    event: any,
    firstElement?: number,
    lastElement?: number
  ) => {
    if (firstElement !== undefined && lastElement !== undefined)
      tabuladorButtons(event, firstElement, lastElement);
  };
  if (loadingInitial) return <LoadingComponent />
  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteProductosComprados}
      >
        {({ }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Reporte de productos más,menos y no comprados' color='blue' />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  autoFocus={true}
                  tabIndex={1}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                />
              </div>

              <div></div>

              <div className='col35'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={2}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}

                />
              </div>

              <div className='col35'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={3}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={5}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={6}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputEmpresa}
                  ref={inputEmpresaRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={7}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputSucursal}
                  ref={inputSucursalRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='No. Artícluos incluir'
                  label="No. Artícluos incluir:"
                  name='noArticluosIncluir'
                  tipoDato={enumTipoDato.NumericoSinPunto}
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Tipo de Compra'
                  label="Tipo de Compra"
                  name='tipoCompra'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputTipoCompra}
                  ref={inputTipoCompraRef} />
              </div>

              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Serie'
                  label="Serie"
                  name='serie'
                  maxLength={50}
                  tabIndex={12}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputSerie}
                  ref={inputSerieRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Folio'
                  label="Folio"
                  name='folios'
                  maxLength={50}
                  tabIndex={13}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MySelectInput
                  placeholder='Estado'
                  label="Estado"
                  name='Estado'
                  options={EstadoByDropdown}
                  //maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Cuenta de Compra'
                  label="Cuenta de Compra"
                  name='cuentaCompra'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputCuentaDeCompra}
                  ref={inputCuentaDeCompraRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Proveedor'
                  label="Proveedor"
                  name='proveedor'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputProveedor}
                  ref={inputProveedorRef} />
              </div>
              <div className='col10'></div>
            
              <div className='col35'>
                <MyTextInput
                  placeholder='Moneda'
                  label="Moneda"
                  name='moneda'
                  maxLength={50}
                  tabIndex={14}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputMoneda}
                  ref={inputMonedaRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Comprador'
                  label="Comprador"
                  name='comprador'
                  maxLength={50}
                  tabIndex={19}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputUsuarioComprador}
                  ref={inputUsuarioCompradorRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Trabajador'
                  label="Trabajador"
                  name='trabajador'
                  maxLength={50}
                  tabIndex={20}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputUsuarioTrabajador}
                  ref={inputUsuarioTrabajadorRef} />
              </div>

            </Segment>
            <Segment>
              <Button disabled={loading} loading={loading} tabIndex={21} type="submit" color="blue" onKeyDown={(e: any) =>
                tabuladorButton(e, firstElement, lastElement)
              }>
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})