import {
  FechaObservacion,
  FechaObservacionValues,
} from "../configuracion/FechaObservacion";
import { AlmacenTipo } from "./AlmacenTipo";

export interface Almacen {
  id: number;
  clave: number;
  nombre: string;
  nombreCorto: string;
  almacenTipoId: number;
  almacenTipo?: AlmacenTipo;
  costeoTipoId: number;
  costeoTipo?: CosteoTipo;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}

export class AlmacenFormValues implements Almacen {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  nombreCorto: string = "";
  almacenTipoId: number = 0;
  almacenTipo?: AlmacenTipo;
  costeoTipoId: number = 0;
  //selectedAlmacenTipoId: undefined;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  //codigoContable: string = "";
  constructor(init?: Almacen) {
    Object.assign(this, init);
  }
}
export interface CosteoTipo {
  id: number;
  clave: number;
  nombre: string;
}
