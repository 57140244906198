import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Producto } from "../../models/inventario/Producto";
import { setDate } from "../../common/util/util";
import { toast } from "react-toastify";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { entity2ByDropdown } from "../../common/funciones/entityByDropdown";

export default class ProductoStore {
  productoRegistry = new Map<number, Producto>();
  productoByEmpresaSucursalRegistry = new Map<number, Producto>();
  productoByEmpresaSucursalFetchRegistry = new Map<number, Producto>();
  tableProductoByEmpresaSucursal = new Map<string, Producto>();
  selectedProducto: Producto | undefined = undefined;

  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get productosById() {
    return Array.from(this.productoRegistry.values()).sort(
      (a, b) => a.id - b.id
    );
  }

  loadProductos = async () => {
    this.setLoadingInitial(true);
    try {
      const productos = await agent.Productos.list();
      productos.forEach((producto) => {
        setDate<Producto>(producto);
        this.setProducto(producto);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  public loadProducto = async (id: number) => {
    let producto = this.getProducto(id);
    if (producto) {
      this.selectedProducto = producto;
      return producto;
    } else {
      this.loadingInitial = true;
      try {
        producto = await agent.Productos.details(id);
        debugger
        setDate<Producto>(producto);
        this.setProducto(producto);
        this.selectedProducto = producto;
        this.setLoadingInitial(false);
        return producto;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setProducto = (producto: Producto) => {
    this.productoRegistry.set(producto.id, producto);
    this.productoByEmpresaSucursalFetchRegistry.set(producto.id, producto);
  };

  public getProductoByEmpresaSucursalById = (id: number): Producto | undefined => {
    return this.productoByEmpresaSucursalRegistry.get(id);
  };

  public getProducto = (id: number): Producto | undefined => {
    return this.productoByEmpresaSucursalFetchRegistry.get(id);
  };
  public getProductoById = (id: number): Producto | undefined => {
    return this.productoByEmpresaSucursalFetchRegistry.get(id);
  };


  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
  private getFormProducto = (producto: Producto) => {
    let formData = new FormData();
    formData.append("id", producto.id?.toString());
    formData.append("clave", producto.clave);
    formData.append("nombre", producto.nombre ? producto.nombre : "");
    formData.append("empresaId", producto.empresaId.toString());
    formData.append("sucursalId", producto.sucursalId.toString());
    formData.append(
      "nombreCorto",
      producto.nombreCorto ? producto.nombreCorto : ""
    );
    formData.append(
      "productoTipoId",
      producto.productoTipoId ? producto.productoTipoId.toString() : ""
    );
    formData.append(
      "codigoBarra",
      producto.codigoBarra ? producto.codigoBarra : ""
    );
    formData.append(
      "usaDescripcionAdicional",
      producto.usaDescripcionAdicional
        ? producto.usaDescripcionAdicional.toString()
        : ""
    );
    formData.append(
      "usaDescripcionAdicionalCompra",
      producto.usaDescripcionAdicionalCompra
        ? producto.usaDescripcionAdicionalCompra.toString()
        : ""
    );
    formData.append(
      "usaSerie",
      producto.usaSerie ? producto.usaSerie.toString() : ""
    );
    formData.append(
      "usaLotes",
      producto.usaLotes ? producto.usaLotes.toString() : ""
    );
    formData.append(
      "usaInformacionAduanera",
      producto.usaInformacionAduanera
        ? producto.usaInformacionAduanera.toString()
        : ""
    );
    formData.append(
      "esAnticipo",
      producto.esAnticipo ? producto.esAnticipo.toString() : ""
    );
    formData.append(
      "categoriaProductoId",
      producto.categoriaProductoId
        ? producto.categoriaProductoId.toString()
        : ""
    );
    formData.append("almacenId", producto.almacenId.toString());
    formData.append(
      "fichaTecnica",
      producto.fichaTecnica ? producto.fichaTecnica : ""
    );
    formData.append("clasificacionId", producto.clasificacionId.toString());
    formData.append("impuestoId", producto.impuestoId.toString());
    formData.append("monedaId", producto.monedaId.toString());
    formData.append("unidadMedida1Id", producto.unidadMedida1Id.toString());
    formData.append("numeroDecimales1", producto.numeroDecimales1.toString());
    formData.append(
      "unidadMedida2Id",
      producto.unidadMedida2Id ? producto.unidadMedida2Id.toString() : ""
    );
    formData.append("numeroDecimales2", producto.numeroDecimales2.toString());
    formData.append(
      "unidadMedidaCompraId",
      producto.unidadMedidaCompraId
        ? producto.unidadMedidaCompraId.toString()
        : ""
    );
    formData.append(
      "numeroDecimalesCompra",
      producto.numeroDecimalesCompra
        ? producto.numeroDecimalesCompra.toString()
        : ""
    );
    formData.append(
      "permitirVenderSinExistencia",
      producto.permitirVenderSinExistencia
        ? producto.permitirVenderSinExistencia.toString()
        : ""
    );
    formData.append(
      "descuento",
      producto.descuento ? producto.descuento.toString() : ""
    );
    formData.append(
      "maximoPorcentajeDesc",
      producto.maximoPorcentajeDesc
        ? producto.maximoPorcentajeDesc.toString()
        : ""
    );
    formData.append(
      "pesoKilos",
      producto.pesoKilos ? producto.pesoKilos.toString() : ""
    );
    formData.append(
      "almacenUbicacion",
      producto.almacenUbicacion ? producto.almacenUbicacion : ""
    );
    formData.append(
      "politicaDescuentoId",
      producto.politicaDescuentoId
        ? producto.politicaDescuentoId.toString()
        : ""
    );
    formData.append("claveSat", producto.claveSat ? producto.claveSat : "");
    formData.append(
      "claveSATCartaPorte",
      producto.claveSATCartaPorte ? producto.claveSATCartaPorte : ""
    );
    formData.append(
      "maximo",
      producto.maximo ? producto.maximo.toString() : ""
    );
    formData.append(
      "minimo",
      producto.minimo ? producto.minimo.toString() : ""
    );
    formData.append(
      "puntoReorden",
      producto.puntoReorden ? producto.puntoReorden.toString() : ""
    );
    formData.append(
      "esProrrateable",
      producto.esProrrateable ? producto.esProrrateable.toString() : ""
    );
    formData.append(
      "codigoProveedor",
      producto.codigoProveedor ? producto.codigoProveedor : ""
    );
    formData.append(
      "proveedorId",
      producto.proveedorId ? producto.proveedorId.toString() : ""
    );
    formData.append(
      "fechaObservacionId",
      producto.fechaObservacionId?.toString()!
    );
    formData.append(
      "fechaObservacion.observaciones",
      producto.fechaObservacion.observaciones
        ? producto.fechaObservacion.observaciones
        : ""
    );
    formData.append(
      "fechaObservacion.baja",
      producto.fechaObservacion?.baja
        ? producto.fechaObservacion.baja?.toDateString()
        : ""
    );
    formData.append(
      "productoFileId",
      producto.productoFileId ? producto.productoFileId.toString() : ""
    );
    formData.append("productoImagen", producto.productoImagen!);
    return formData;
  };

  createProducto = async (producto: Producto) => {
    this.loading = true;
    try {
      await agent.Productos.create(this.getFormProducto(producto));
      runInAction(() => {
        this.selectedProducto = producto;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Producto creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateProducto = async (producto: Producto) => {
    this.loading = true;
    try {
      await agent.Productos.update(this.getFormProducto(producto), producto.id);
      runInAction(() => {
        this.selectedProducto = producto;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Producto modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteProducto = async (id: number) => {
    this.loading = true;
    try {
      await agent.Productos.delete(id);
      runInAction(() => {
        this.productoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Producto eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar Producto");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  get productosByEmpresaSucursalDropdown() {
    return entity2ByDropdown<Producto>(
      Array.from(this.productoByEmpresaSucursalRegistry.values())
    );
  }

  get productosByEmpresaSucursalDropdownByClave() {
    return entity2ByDropdown<Producto>(
      Array.from(this.productoByEmpresaSucursalRegistry.values()), true 
    );
  }

  private setProductobyEmpresaSucursal = (producto: Producto) => {
    this.productoRegistry.set(producto.id, producto);
    this.productoByEmpresaSucursalRegistry.set(producto.id, producto);
  };
  private setFetchProductobyEmpresaSucursal = (producto: Producto) => {
    this.productoByEmpresaSucursalFetchRegistry.set(producto.id, producto);
  };
  fetchProductos = async (productoByEmpresaSucursal: PaginationSpecParams) => {
    try {
      const productos = await agent.Productos.listByEmpresaSucursal(
        productoByEmpresaSucursal
      );
      productos.forEach((producto) => {
        this.setFetchProductobyEmpresaSucursal(producto);
      });
      return entity2ByDropdown<Producto>(Array.from(productos));
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      console.error("Failed to load sucursales", error);
    }
  };


  loadProductoByEmpresaSucursal = async (
    empresaSucursal: PaginationSpecParams
  ) => {
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        this.productoByEmpresaSucursalRegistry.clear();
        this.productoRegistry.clear();
        const productos = await agent.Productos.listByEmpresaSucursal(
          empresaSucursal
        );
        productos.forEach((producto) => {
          this.setProductobyEmpresaSucursal(producto);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.productoByEmpresaSucursalRegistry.clear();
    }
  };
  loadClaveProductoByEmpresaSucursal = async (
    empresaSucursal: PaginationSpecParams
  ) => {
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        const productoClave = await agent.Productos.claveByEmpresaSucursal(
          empresaSucursal
        );
        return productoClave;
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };
  private setTableProductoByEmpresaSucursal = (
    index: any,
    producto: Producto
  ) => {
    this.tableProductoByEmpresaSucursal.set(index, producto);
  };
  loadTableProductoByEmpresaSucursal = async (
    empresaSucursal: PaginationSpecParams
  ) => {
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        const productos = await agent.Productos.listByEmpresaSucursal(
          empresaSucursal
        );
        productos.forEach((producto) => {
          this.setTableProductoByEmpresaSucursal(
            `empresa${producto.empresaId}sucursal${producto.sucursalId}producto${producto.id}`,
            producto
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  get tablaproductosByEmpresaSucursalDropdown() {
    return Array.from(this.tableProductoByEmpresaSucursal.values());
  }

  fetchsProductos = async (filtros: PaginationSpecParams, options: any = {}) => {
    try {
     const response = await agent.Productos.listByEmpresaSucursalExistencia(filtros, options);
      return response;
    } catch (error) {
      console.error("Error al obtener los productos", error);
    }
  };

  fetchsClaveSat = async (options: any = {}) => {
    try {
      const response = await agent.Productos.listClaveSat(options);
      return response;
    } catch (error) {
      console.error("Error al obtener Claves Sat", error);
    }
  };

  fetchsProductosSinFiltos = async () => {
    try {
      const response = await agent.Productos.list();
      return response;
    } catch (error) {
      console.error("Error al obtener los productos", error);
    }
  };

  cargarProducto = async (filtros: PaginationSpecParams) => {
    try {
      const producto = await agent.Productos.obtenerProductoPorClave(filtros);
      if (producto) {
        return producto;
      }
    } catch (error) {
      console.log(error);
    }
  };
}