import { Pac } from "./Pac";
import { ListaDePrecio, ListaDePrecioFormValues } from "../controlPrecio/ListaDePrecio";
import { FechaObservacion, FechaObservacionValues } from "./FechaObservacion";
import { Direccion, DireccionValues } from "./Direccion";
import { cRegimenFiscal, cRegimenFiscalFormValues } from "../sat/cRegimenFiscal";
export interface Empresa {
  id: number;
  clave: number;
  nombre: string;
  demo: boolean;
  rfc: string;
  curp: string;
  codigoContable?: string;
  contrasenaClavePrivada?: string;
  contrasenaFirmaPrivada?:string;
  fechaInicioVigenciaCFD: Date;
  cFechaInicioVigenciaCFD: string;
  fechaFinVigenciaCFD: Date;
  cFechaFinVigenciaCFD: string;
  fileCer?:IFile;
  fileKey?:IFile;
  pacId: number;
  pac?: Pac;
  usuarioPac?:string;
  usuarioPacPassword?:string ;
  ciec?:string ;
  tokenInfinito?:string ;
  fileCerId:number;
  cer?: null;
  fileKeyId:number;
  key?: null;
  direccionId: number;
  direccion: Direccion;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
  listaDePrecioId: number;
  listaDePrecio: ListaDePrecio;
  regimenFiscalId: number;
  regimenFiscal: cRegimenFiscal;
}

export interface IEmpresaFormValues extends Partial<Empresa> {
  selectedIdEmpresa: any;
}

export class EmpresaFormValues implements IEmpresaFormValues {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  demo: boolean = false;
  rfc: string = "";
  curp: string = "";
  codigoContable?: string = "";
  contrasenaClavePrivada?: string;
  contrasenaFirmaPrivada?: string;
  fechaInicioVigenciaCFD: Date = new Date();
  cFechaInicioVigenciaCFD: string ="";
  fechaFinVigenciaCFD: Date= new Date();
  cFechaFinVigenciaCFD: string="";
  
  pacId: number = 0;
  pac?: Pac;
  usuarioPac?:string;
  usuarioPacPassword?:string ="";
  ciec?:string ="";
  tokenInfinito?:string ="";
  fileCer?:IFile;
  fileKey?:IFile;
  fileCerId:number=0;
  cer?: null;
  fileKeyId:number=0;
  key?: null;
  direccionId: number = 0;
  direccion: Direccion=new DireccionValues();
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  listaDePrecioId: number = 0;
  listaDePrecio: ListaDePrecio = new ListaDePrecioFormValues();
  regimenFiscalId: number = 0;
  regimenFiscal: cRegimenFiscal = new cRegimenFiscalFormValues();
  selectedFileCer: string = "";
  selectedIdEmpresa: undefined;
  constructor(init?: Empresa) {
    Object.assign(this, init);
  }
}
export interface IFile {
  id: string;
  clave:string;
  nombre:string;
  url: string;
  type:string;
  size:string;
  //name: string;
}
