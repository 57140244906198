import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TipoDeCompra,
  TipoDeCompraFormValues
} from "../../../../app/models/compra/TipoDeCompra";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons
} from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function TipoCompraForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 10;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { tipoDeCompraStore, tipoDeCompraTipoStore, almacenStore, conceptoDeInventarioStore, cuentaDeProveedorStore, conceptoDeProveedorStore } = useStore();

  const {
    createTipoDeCompra,
    updateTipoDeCompra,
    loadTipoDeCompra,
    loadingInitial,
    tipoDeCompraById,
    loading
  } = tipoDeCompraStore;
  const { tipoDeCompraTiposByDropdown, getTipoDeCompraTipos } = tipoDeCompraTipoStore;
  const { almacenesByDropdown, getAlmacenes } = almacenStore;
  const { conceptosInventariosByDropdown, getConceptosDeInventarios } = conceptoDeInventarioStore;
  const { cuentasDeProveedoresByDropdown, getCuentasDeProveedores } = cuentaDeProveedorStore;
  const { conceptosDeProveedoresByDropdown, getConceptosDeProveedores } = conceptoDeProveedorStore;
  const [tipoDeCompra, setTipoDeCompra] = useState(new TipoDeCompraFormValues());

  useEffect(() => {
    getTipoDeCompraTipos()
    getAlmacenes()
    getConceptosDeInventarios()
    getCuentasDeProveedores()
    getConceptosDeProveedores()
  }, [])

  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave del tipo de compra es requerida.'),
    nombre: Yup.string().required('El nombre del tipo de compra es requerido.'),
    tipoDeCompraTipoId: Yup.number()
      .min(1, "Favor de seleccionar un Tipo de Tipo de Compra.")
      .required("El tipo de Tipo de Compra es requerido."),
  });

  useEffect(() => {
    if (id) {
      loadTipoDeCompra(id).then(tipoDeCompra => {
        setTipoDeCompra(new TipoDeCompraFormValues(tipoDeCompra))
      });
    } else {
      tipoDeCompra.clave =
        tipoDeCompraById.map((tipoDeCompra) => tipoDeCompra.clave).length > 0
          ? Math.max(...tipoDeCompraById.map((tipoDeCompra) => tipoDeCompra.clave)) + 1
          : 1;
    }
  }, []);

  function handleSubmit(tipoDeCompra: TipoDeCompra) {
    if (/*cuentaDeVenta.cuentaDeVentaTipoId.toString() !== "0" &&*/ tipoDeCompra.nombre !== null) {
      tipoDeCompra.id > 0 ? updateTipoDeCompra(tipoDeCompra) : createTipoDeCompra(tipoDeCompra);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Tipo de Compra' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={tipoDeCompra}>

        {({ values, setFieldValue }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Tipos de Compras
              </Label>
              <div></div>
              <div className='cols'>
                <div className='col15'>
                  <MyTextInput disabled={true} tabIndex={0} placeholder="Clave" label="Clave" name="clave" />
                </div>
                <div className='col50' />
              </div>
              <div className='col60'>
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} placeholder="Nombre" label="Nombre" name="nombre" />
              </div>
              <MySelectInput
                tabIndex={2}
                placeholder="Seleccionar"
                label="Tipo"
                name="tipoDeCompraTipoId"
                options={tipoDeCompraTiposByDropdown}
                enterInput={enterInput} />
            </Segment>

            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Afectación de Inventario
              </Label>
              <div ></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Seleccionar"
                  label="Almacen"
                  name="almacenId"
                  options={almacenesByDropdown}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col35'>
                <div className='col35'>
                  <MySelectInput
                    tabIndex={4}
                    placeholder="Seleccionar"
                    label="Concepto"
                    name="conceptoDeInventarioId"
                    options={conceptosInventariosByDropdown}
                    enterInput={enterInput} />
                </div>
              </div>
            </Segment>

            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Afectación de Cartera de Proveedores
              </Label>
              <div ></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={5}
                  placeholder="Seleccionar"
                  label="Cuenta"
                  name="cuentaDeProveedorId"
                  options={cuentasDeProveedoresByDropdown}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={6}
                  placeholder="Seleccionar"
                  label="Concepto"
                  name="conceptoDeProveedorId"
                  options={conceptosDeProveedoresByDropdown}
                  enterInput={enterInput} />
              </div>


            </Segment>
            <Segment clearing>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={7}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <FechaObservaciones
                      fechaObservacion={values.fechaObservacion}
                      setFechaObservacion={(data) => {
                        setFieldValue("fechaObservacion", data)
                      }}
                      closeModals={closeModals}
                      tabIndex={lastElement}
                    />,
                    enumHeaderModalsFechasObservaciones.TipoDeCompra
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="calendar alternate outline" />
                Fechas y Observaciones
              </Button>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/tiposDeCompras"
                loading={loading}
                tabIndex={8}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})