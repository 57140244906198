import { CondicionDePago } from "../gestionCliente/CondicionDePago";
import { FormaDePago } from "../gestionCliente/FormaDePago";
import { GeneracionComprobantePagoDetalle, GeneracionComprobantePagoDetalleFormValues } from "./GeneracionComprobantePagosDetalle";

export interface GeneracionComprobantePago {
    id: number;
    clave: number;
    nombre: string;
    condiciondePagoId?: number;
    condiciondePago?: CondicionDePago;
    formaDePagoId: number;
    formaDePago?: FormaDePago;
    importe: number;
    pagos?: GeneracionComprobantePagoDetalle[];
}
export interface IGeneracionComprobantePagoFormValues extends Partial<GeneracionComprobantePago> {
    id: any;

}
export class GeneracionComprobantePagoFormValues implements IGeneracionComprobantePagoFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    condiciondePagoId?: number = 0;
    condiciondePago?: CondicionDePago
    formaDePagoId: number = 0;
    formaDePago?: FormaDePago;
    importe: number = 0;
    pagos?: GeneracionComprobantePagoDetalle[] = [new GeneracionComprobantePagoDetalleFormValues()];
    constructor(init?: GeneracionComprobantePago) {
        Object.assign(this, init);
    }
}