import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { MetodoPago } from "../../models/sat/c_MetodoPago";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class MetodoPagoStore {
    metodoPagoRegistry = new Map<number, MetodoPago>();
    selectedMetodoPago: MetodoPago | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get metodosPagosByDropdown() {
        let metodosPagosArreg = [new TipoDropDownValues()];
        let metodosPagosOpt = new TipoDropDownValues();
        const cvts = Array.from(this.metodoPagoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            metodosPagosOpt.key = element.id;
            metodosPagosOpt.text = element.clave;
            metodosPagosOpt.value = element.id;
            metodosPagosOpt.content = element.clave + " " + element.nombre;
            metodosPagosArreg.push(metodosPagosOpt);
            metodosPagosOpt = new TipoDropDownValues();

        });
        return metodosPagosArreg;
    }

    get metodosPagosByDate() {
        return Array.from(this.metodoPagoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadMetodosPagos = async () => {
        this.setLoadingInitial(true);
        try {
            const metodosPagos = await agent.MetodosPagos.list();
            metodosPagos.forEach(metodoPago => {
                this.setMetodoPago(metodoPago);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getMetodosPagos = async () => {
        this.setLoadingInitial(true);
        const metodosPagos = await agent.MetodosPagos.list();
        try {
            metodosPagos.forEach(metodoPago => {
                this.setMetodoPago(metodoPago);
            })
            this.setLoadingInitial(false);
            return metodosPagos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return metodosPagos;
        }
    }


    loadMetodoPago = async (id: number) => {
        let metodoPago = this.getMetodoPago(id);
        if (metodoPago) {
            this.selectedMetodoPago = metodoPago;
            return metodoPago;
        } else {
            this.loadingInitial = true;
            try {
                metodoPago = await agent.MetodosPagos.details(id);
                this.setMetodoPago(metodoPago);
                this.selectedMetodoPago = metodoPago;
                this.setLoadingInitial(false);
                return metodoPago;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setMetodoPago = (metodoPago: MetodoPago) => {
        this.metodoPagoRegistry.set(metodoPago.id, metodoPago);
    }

    public getMetodoPago = (id: number) => {
        return this.metodoPagoRegistry.get(id);
    }

    public getMetodoPagoPorClave = (clave: string) => {
        return Array.from(this.metodoPagoRegistry.values()).find(metodoPago => metodoPago.clave === clave) || null;
    };

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createMetodoPago = async (metodoPago: MetodoPago) => {
        this.loading = true;
        try {
            await agent.MetodosPagos.create(metodoPago);
            runInAction(() => {
                this.metodoPagoRegistry.set(metodoPago.id, metodoPago);
                this.selectedMetodoPago = metodoPago;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteMetodoPago = async (id: number) => {
        this.loading = true;
        try {
            await agent.MetodosPagos.delete(id);
            runInAction(() => {
                this.metodoPagoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
