import { makeAutoObservable } from "mobx";
import { Clasificacion } from "../../models/inventario/Clasificacion";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import agent from "../../api/agent";
export default class ClasificacionStore {
  clasificacionRegistry = new Map<number, Clasificacion>();
  selectedMoneda: Clasificacion | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  constructor() {
    makeAutoObservable(this);
  }

  get monedasById() {
    return Array.from(this.clasificacionRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  loadClasificaciones = async () => {
    this.setLoadingInitial(true);
    try {
      const claisificaciones = await agent.Clasificaciones.list();
      claisificaciones.forEach((clasificacion) => {
        // setDate<Moneda>(clasificacion);
        this.setMoneda(clasificacion);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get clasificacionByDropdown() {
    return entityByDropdown<Clasificacion>(
      Array.from(this.clasificacionRegistry.values())
    );
  }
  private setMoneda = (clasificacion: Clasificacion) => {
    this.clasificacionRegistry.set(clasificacion.id, clasificacion);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
