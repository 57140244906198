import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { ImpuestoProducto } from "../../models/inventario/ImpuestoProducto";

export default class ImpuestoProductoStore {
  impuestoProductoRegistry = new Map<number, ImpuestoProducto>();
  selectedImpuestoProducto: ImpuestoProducto | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get impuestosProductosByDate() {
    return Array.from(this.impuestoProductoRegistry.values()).sort((a) => a.id);
  }

  get impuestosProductosById() {
    return Array.from(this.impuestoProductoRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }
  get ImpuestosProductosByDropdown() {
    return entityByDropdown<ImpuestoProducto>(
      Array.from(this.impuestoProductoRegistry.values())
    );
  }

  loadImpuestosProductos = async () => {
    this.setLoadingInitial(true);
    try {
      const impuestosProductos = await agent.ImpuestosProductos.list();
      impuestosProductos.forEach((impuestoProducto) => {
        setDate<ImpuestoProducto>(impuestoProducto);
        this.setImpuestoProducto(impuestoProducto);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadImpuestoProducto = async (id: number) => {
    let impuestoProducto = this.getImpuestoProducto(id);
    if (impuestoProducto) {
      setDate<ImpuestoProducto>(impuestoProducto);
      this.selectedImpuestoProducto = impuestoProducto;
      return impuestoProducto;
    } else {
      this.loadingInitial = true;
      try {
        impuestoProducto = await agent.ImpuestosProductos.details(id);
        this.setImpuestoProducto(impuestoProducto);
        this.selectedImpuestoProducto = impuestoProducto;
        this.setLoadingInitial(false);
        return impuestoProducto;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setImpuestoProducto = (impuestoProducto: ImpuestoProducto) => {
    this.impuestoProductoRegistry.set(impuestoProducto.id, impuestoProducto);
  };

  private getImpuestoProducto = (id: number) => {
    return this.impuestoProductoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createImpuestoProducto = async (impuestoProducto: ImpuestoProducto) => {
    this.loading = true;
    try {
      debugger
      await agent.ImpuestosProductos.create(impuestoProducto);
      runInAction(() => {
        this.selectedImpuestoProducto = impuestoProducto;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Impuesto Producto creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateImpuestoProducto = async (impuestoProducto: ImpuestoProducto) => {
    this.loading = true;
    try {
      await agent.ImpuestosProductos.update(impuestoProducto);
      runInAction(() => {
        this.selectedImpuestoProducto = impuestoProducto;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Impuesto Producto editado con éxito");
    } catch (error) {
      toast.error("Problema al actualizar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteImpuestoProducto = async (id: number) => {
    this.loading = true;
    try {
      await agent.ImpuestosProductos.delete(id);
      runInAction(() => {
        this.impuestoProductoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Impuesto producto eliminado con éxito");
    } catch (error) {
      toast.error("Problema al eliminar el impuesto");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
