import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";

import { setDate } from "../../common/util/util";
import { SerieFolioDeVenta } from "../../models/venta/SerieFolioDeVenta";
import { entity2ByDropdown, } from "../../common/funciones/entityByDropdown";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import {  Entity2 } from "../../models/Entity";

export default class SerieFolioStore {
  serieFolioRegistry = new Map<number, SerieFolioDeVenta>();
  serieFolioEntity = new Map<number, Entity2>();
  serieFolioByTipoVentaRegistry = new Map<number, SerieFolioDeVenta>();
  serieFolioByTipoVentaCopiaRegistry = new Map<number, SerieFolioDeVenta>();
  selectedSerieFolio: SerieFolioDeVenta | undefined = undefined;

  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this)
  }

  get seriesFoliosByDate() {
    return Array.from(this.serieFolioRegistry.values()).sort((a) => a.id);
  }

  cleanSerieFolioByTipoVenta = async () => {
    this.serieFolioByTipoVentaRegistry.clear();
  };
  cleanSerieFolioByTipoVentaCopia = async () => {
    this.serieFolioByTipoVentaCopiaRegistry.clear();
  };
  loadSerieByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
    try {
      const serieFolioDeVenta = await agent.SeriesFoliosDeVentas.listByParams(filtros, options);
      return entity2ByDropdown<SerieFolioDeVenta>(Array.from(serieFolioDeVenta));
    } catch (error) {
      console.error("error", error);
    }
  }

  fetchsSeriesFolios = async () => {
    try {
      const response = await agent.SeriesFoliosDeVentas.list();
      return response;
    } catch (error) {
      console.error("Error al buscar Serie", error);
    }
  };

  loadSerieByParamsDropdownByClave = async (filtros: PaginationSpecParams, options: any = {}) => {
    try {
      
       await agent.SeriesFoliosDeVentas.listByParams(filtros, options);
      return entity2ByDropdown<SerieFolioDeVenta>(Array.from(this.serieFolioEntity.values()), true);
      //return entity2ByDropdown<SerieFolioDeVenta>(Array.from( serieFolioDeVenta));
      //return entityByDropdown<Empresa>           (Array.from(this.empresaEntity.values()), true);
    } catch (error) {
      console.error("error", error);
    }
  }

  loadSerieByIdTipoVenta = async (empresaSucursal: PaginationSpecParams) => {

    //const { tipoVentaStore } = store;
    
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        // void tipoVentaStore.cleanProvedorByEmpresa();
        this.serieFolioByTipoVentaRegistry.clear();
        const serieFolioDeVenta = await agent.SeriesFoliosDeVentas.listByTipoVenta(empresaSucursal);
        serieFolioDeVenta.forEach((serieFolioDeVenta) => {
          this.setSerieFoliobyTipoVenta(serieFolioDeVenta);
        });
        return serieFolioDeVenta;
      } catch (error) {
        console.log(error);
      }
    } else {
      //proveedorStore.cleanProvedorByEmpresa();
      this.serieFolioByTipoVentaRegistry.clear();
    }
  };

  loadSerieByIdTipoVentaCopia = async (empresaSucursal: PaginationSpecParams) => {
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
      try {
        // void tipoVentaStore.cleanProvedorByEmpresa();

        this.serieFolioByTipoVentaCopiaRegistry.clear();
        const serieFolioDeVentaCopia = await agent.SeriesFoliosDeVentas.listByTipoVenta(empresaSucursal);
        serieFolioDeVentaCopia.forEach((serieFolioDeVenta) => {
          this.setSerieFoliobyTipoVentaCopia(serieFolioDeVenta);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.serieFolioByTipoVentaCopiaRegistry.clear();
    }
  };

  getSeriesFolios = async () => {
    this.setLoadingInitial(true);
    const seriesFolios = await agent.SeriesFoliosDeVentas.list();
    try {
      seriesFolios.forEach(serie => {
        this.setSerieFolio(serie);
      })
      this.setLoadingInitial(false);
      return seriesFolios;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return seriesFolios;
    }
  }


  loadSeriesFolios = async () => {
    this.setLoadingInitial(true);
    try {

      const seriesFoliosDeventas = await agent.SeriesFoliosDeVentas.list();
      seriesFoliosDeventas.forEach(serieFoliodeVenta => {
        setDate<SerieFolioDeVenta>(serieFoliodeVenta);
        this.setSerieFolio(serieFoliodeVenta);
      })
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  }

  loadSerieFolio = async (id: number) => {

    let serieFolio = this.getSerieFolio(id);
    if (serieFolio) {
      this.selectedSerieFolio = serieFolio;
      return serieFolio;

    } else {
      this.loadingInitial = true;
      try {
        serieFolio = await agent.SeriesFoliosDeVentas.details(id);
        this.setSerieFolio(serieFolio);
        this.selectedSerieFolio = serieFolio;
        this.setLoadingInitial(false);
        return serieFolio;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }

  }

  private setSerieFolio = (serieFolio: SerieFolioDeVenta) => {
    this.serieFolioRegistry.set(serieFolio.id, serieFolio);
  }
  private setSerieFoliobyTipoVenta = (serieFolio: SerieFolioDeVenta) => {
    this.serieFolioByTipoVentaRegistry.set(serieFolio.id, serieFolio);
  };
  public setSerieFoliosRegClear = () => {
    this.serieFolioByTipoVentaRegistry.clear();
  }
  private setSerieFoliobyTipoVentaCopia = (serieFolio: SerieFolioDeVenta) => {
    this.serieFolioByTipoVentaCopiaRegistry.set(serieFolio.id, serieFolio);
  };
  public setSerieFolios2RegClear = () => {
    this.serieFolioByTipoVentaCopiaRegistry.clear();
  }

  get SeriesFoliosByDropdown() {
    return entity2ByDropdown<SerieFolioDeVenta>(Array.from(this.serieFolioRegistry.values()));
  }

  get SeriesFoliosByEmpresaByDropdown() {
    return entity2ByDropdown<SerieFolioDeVenta>(
      Array.from(this.serieFolioByTipoVentaRegistry.values())
    );
  }

  get SeriesFoliosByEmpresaByDropdownByClave() {
    return entity2ByDropdown<SerieFolioDeVenta>(Array.from(this.serieFolioByTipoVentaRegistry.values()), true);
  }

  get SeriesFoliosByEmpresaCopiaByDropdown() {
    return entity2ByDropdown<SerieFolioDeVenta>(
      Array.from(this.serieFolioByTipoVentaCopiaRegistry.values())
    );
  }

  private getFormSerieFolio = (serieFolio: SerieFolioDeVenta) => {


    let formData = new FormData();
    formData.append("id", serieFolio.id?.toString()!);
    formData.append("empresaId", serieFolio.empresaId.toString());
    formData.append("sucursalId", serieFolio.sucursalId.toString());
    formData.append("tipoVentaId", serieFolio.tipoVentaId.toString());
    formData.append("clave", serieFolio.clave.toString()!);
    formData.append("nombre", serieFolio.nombre);
    formData.append("SerieFolioDeVentaTipoId", serieFolio.serieFolioDeVentaTipoId.toString());

    formData.append("ultimoFolio", serieFolio.ultimoFolio.toString());
    formData.append("lemaEmpresa", serieFolio.lemaEmpresa === null ? "" : serieFolio.lemaEmpresa.toString());

    formData.append("fileLogoId", serieFolio.fileLogoId === null ? "" : serieFolio.fileLogoId.toString());
    formData.append("fileLogoTicketId", serieFolio.fileLogoTicketId === null ? "" : serieFolio.fileLogoTicketId.toString());

    formData.append("logo", serieFolio.logo!);
    formData.append("logoTicket", serieFolio.logoTicket!);



    formData.append("codigoContable", serieFolio.codigoContable === null ? "" : serieFolio.codigoContable!.toString());
    formData.append("fechaObservacion.observaciones", serieFolio.fechaObservacion.observaciones);
    formData.append("fechaObservacion.baja", serieFolio.fechaObservacion.baja! ? serieFolio.fechaObservacion.baja.toDateString() : "");

    return formData;
  };

  private getSerieFolio = (id: number) => {
    return this.serieFolioRegistry.get(id);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }

  createSerieFolio = async (serieFolioDeVenta: SerieFolioDeVenta) => {
    this.loading = true;
    try {
      await agent.SeriesFoliosDeVentas.create(this.getFormSerieFolio(serieFolioDeVenta));
      runInAction(() => {
        this.selectedSerieFolio = serieFolioDeVenta;
        this.editMode = false;
        this.loading = false;
      })

      toast.success("Series y folios creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  updateSerieFolio = async (serieFolioDeVenta: SerieFolioDeVenta) => {
    this.loading = true;
    try {
      await agent.SeriesFoliosDeVentas.update(this.getFormSerieFolio(serieFolioDeVenta), serieFolioDeVenta.id);
      runInAction(() => {
        this.serieFolioRegistry.set(serieFolioDeVenta.id, serieFolioDeVenta);
        this.selectedSerieFolio = serieFolioDeVenta;
        this.editMode = false;
        this.loading = false;
      })

      toast.success("Series y folios modificada con éxito");

    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  deleteSerieFolio = async (id: number) => {
    this.loading = true;
    try {
      await agent.SeriesFoliosDeVentas.delete(id);
      runInAction(() => {
        this.serieFolioRegistry.delete(id);
        this.loading = false;
      })
      toast.success("Series y folios modificada con éxito");
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
