import React, { useEffect, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Label, Modal, ModalContent, ModalHeader, Segment, Table } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import { observer } from 'mobx-react-lite';
import { GeneracionComprobanteNotaCreditoDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobanteNotaCreditoDetalle';
import MyDateInput from '../../../../app/common/form/MyDateInput';
import { GeneracionComprobanteComplementoPago, GeneracionComprobanteComplementoPagoFormValues } from '../../../../app/models/venta/GeneracionComprobanteComplementoPago';

interface Props {
  complementoPago: GeneracionComprobanteComplementoPago;
  closeModals: (valor?: any) => void;
  setComplementoPago: (setFO: GeneracionComprobanteComplementoPagoFormValues) => void;
  tabIndex: number;
  onCopy?: (data: any) => void;
  //valor?: any;
}

export default observer(function ComplementoPagoForm(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { bancoStore, proveedorStore, formaPagoStore, sucursalStore, monedaStore,cuentaBancariaStore,  c_TipoRelacionStore, tipoNotaCreditoStore, generacionComprobanteStore } = useStore();
  const { bancosByDropdown } = bancoStore;
  const {cuentaBancariaByDropdown}=cuentaBancariaStore;
  const { MonedasByDropdown } = monedaStore
  const { formasPagosByDropdown }= formaPagoStore
  const { empresaSucursal } = proveedorStore
  const { cargarComprobante
  } = generacionComprobanteStore

  const validationSchema = Yup.object({
    importe: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
  });


  useEffect(() => {
    empresaSucursal.sucursalId = 1;
    sucursalStore.loadSucursalByIdEmpresa(1);
    tipoNotaCreditoStore.getTiposNotasCreditos();
    c_TipoRelacionStore.getc_TiposRelaciones();
    monedaStore.getMonedas();
    bancoStore.getBancos();
    cuentaBancariaStore.getCuentasBancarias();
    formaPagoStore.getFormasPagos()
  }, [])

  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    debugger
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  /*function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
    
  }*/

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    props.setComplementoPago(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }
  function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
    // void loadProductoByEmpresaSucursal(empresaSucursal);
  }
  function idSelectTipo(idSelectedTipoVenta: number) {
    empresaSucursal.tipoVentaId = idSelectedTipoVenta;
    debugger
    //void loadSerieByIdTipoVenta(empresaSucursal);
  }

  function idSelectSerie(idSelectedSerie: number, setFieldValue: any) {
    empresaSucursal.serieId = idSelectedSerie;
  }

  function deletedeclined() {
    setOpen({ abrir: false, index: 0 })
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    arrayHelpers.remove(index);
    setOpen({ abrir: false, index: 0 })
  }


  function onKeyUpFolio(folio: any, setFieldValue: any, event: any, arrayHelpers: any) {
    empresaSucursal.folio = parseInt(folio);
    if (event.key === "Enter" || event.key === "Tab") {
      cargarComprobante(empresaSucursal).then((result) => {
        debugger

        if (result?.id !== 0) {
          var ddc = new GeneracionComprobanteNotaCreditoDetalleFormValues();
          ddc.serie = result?.serieFolioDeVenta ? (result?.serieFolioDeVenta.clave) : ""
          ddc.folio = folio;
          ddc.importe = result ? (result?.total) : 0
          setFieldValue("notaCreditoDetalles", [ddc])
        } else {

        }
      })
    }




  }

  function chageEnter(tabIndex: number) {
    //obtenerSiguienteFolio(empresaSucursal);
  }
  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };
  return (

    <Formik
      //validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.complementoPago}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <Segment>
            <div className="col100">
              <FieldArray
                name="complementoPago"
                render={(arrayHelpers) => (
                  /////
                  <div>
                    <Segment>
                    <Label as="a" color="blue" ribbon>
                        Información del pago
                      </Label>
                      <div></div>
                      <div>
                        <div className='col13'>
                          Fecha Pago
                          <MyDateInput
                            tabIndex={9}
                            placeholderText="Fecha Pago"
                            name="fechaPago"
                            dateFormat="dd/MM/yyyy"
                            enterInput={enterInput}
                          />
                        </div>
                        <div className='colsp'></div>
                        <div className='colsp'></div>
                        <div className='colsp'></div>
                        
                        
                        <div className='col13'>
                          Hora Pago
                          <MyDateInput
                            tabIndex={9}
                            placeholderText="Hora Pago"
                            name="horaPago"
                            dateFormat="dd/MM/yyyy"
                            enterInput={enterInput}
                          />
                        </div>
                      </div>
                      <div className='col16'>
                        <MySelectInput
                          tabIndex={9}
                          label="Forma de pago"
                          placeholder="Forma de pago"
                          name="formaDePagoId"
                          options={formasPagosByDropdown}
                          //selectWithLabel={true}
                          enterInput={enterInput} />
                      </div>

                      <div className='col25'>
                        <MyTextInput
                          tabIndex={11}
                          placeholder='Monto'
                          label="Monto"
                          name='monto'
                          maxLength={20}
                          enterInput={enterInput}
                        />
                      </div>
                      <div className='colsp'></div>
                        <div className='colsp'></div>
                        <div className='colsp'></div>
                        
                      <div className='col30'>
                        <MyTextInput
                          tabIndex={11}
                          placeholder='Numero Operacion'
                          label="Numero Operacion"
                          name='numeroOperacion'
                          maxLength={20}
                          enterInput={enterInput}
                        />
                      </div>
                     
                      

                    </Segment>
                    <Segment>
                      <Label as="a" color="orange" ribbon>
                        Opcionales
                      </Label>
                      <div></div>
                      <div className='col50'>
                        <MySelectInput
                          tabIndex={3}
                          placeholder="Moneda"
                          label="Moneda"
                          name="monedaId"
                          options={MonedasByDropdown}
                          enterInput={enterInput}
                          onChange={idSelectSerie}
                        />
                      </div>
                      <div className='col50'>
                        <MyTextInput
                          tabIndex={4}
                          placeholder=''
                          label="Tipo de Cambio"
                          name='tipoCambio'
                          enterInput={enterInput}
                        //onKeyDown={onKeyUpFolio}
                        //change={chageEnter}
                        />
                      </div>
                      <Segment>
                      <Label as="a" color="blue" ribbon>
                        Cuenta Beneficiario
                      </Label>
                      <div></div>
                      <div className='col16'>
                        <MySelectInput
                          tabIndex={9}
                          label="Banco"
                          placeholder="Banco"
                          name="bancoBeneficiarioId"
                          options={bancosByDropdown}
                          
                          enterInput={enterInput} />
                      </div>

                      <div className='col16'>
                        <MySelectInput
                          tabIndex={9}
                          label="Cuenta Bancaria"
                          placeholder="Cuenta Bancaria"
                          name="cuentaBancariaBeneficiarioId"
                          options={cuentaBancariaByDropdown}
                          
                          enterInput={enterInput} />
                      </div>

                      </Segment>
                      <Segment>
                      <Label as="a" color="blue" ribbon>
                        Cuenta Ordenante
                      </Label>
                      <div></div>
                      <div className='col16'>
                        <MySelectInput
                          tabIndex={9}
                          label="Banco"
                          placeholder="Banco"
                          name="bancoOrdenanteId"
                          options={bancosByDropdown}
                          
                          enterInput={enterInput} />
                      </div>
                      <div className='col16'>
                        <MySelectInput
                          tabIndex={9}
                          label="Cuenta Bancaria"
                          placeholder="Cuenta Bancaria"
                          name="cuentaBancariaOrdenanteId"
                          options={cuentaBancariaByDropdown}
                          enterInput={enterInput} />
                      </div>
                      </Segment>
                    </Segment>
                  </div>
                )}
              />
            </div>


          </Segment>



          <Segment>
            <Button.Group>
              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>

  )
})