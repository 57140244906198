import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeClienteTipo } from "../../models/gestionCliente/CuentaDeClienteTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class CuentaClienteTipoStore {
    cuentaDeClienteTipoRegistry = new Map<number, CuentaDeClienteTipo>();
    selectedCuentaDeClienteTipo: CuentaDeClienteTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadCuentasClientesTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasClientesTipos = await agent.CuentasDeClientesTipos.list();
            cuentasClientesTipos.forEach(cuentaDeClienteTipo => {
                this.setCuentaDeClienteTipo(cuentaDeClienteTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasClientesTipos = async () => {
        this.setLoadingInitial(true);
        const cuentasClientesTipos = await agent.CuentasDeClientesTipos.list();
        try {
            cuentasClientesTipos.forEach(cuentaDeClienteTipo => {
                this.setCuentaDeClienteTipo(cuentaDeClienteTipo);
            })
            this.setLoadingInitial(false);
            return cuentasClientesTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasClientesTipos;
        }
    }

    loadCuentaDeClienteTipo = async (id: number) => {
        let cuentaDeClienteTipo = this.getCuentaDeClienteTipo(id);
        if (cuentaDeClienteTipo) {
            this.selectedCuentaDeClienteTipo = cuentaDeClienteTipo;
            return cuentaDeClienteTipo;

        } else {
            this.loadingInitial = true;
            try {
                cuentaDeClienteTipo = await agent.CuentasDeClientesTipos.details(id);
                this.selectedCuentaDeClienteTipo = cuentaDeClienteTipo;
                this.setLoadingInitial(false);
                return cuentaDeClienteTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeClienteTipo = (cuentaDeClienteTipo: CuentaDeClienteTipo) => {
        this.cuentaDeClienteTipoRegistry.set(cuentaDeClienteTipo.id, cuentaDeClienteTipo);
    }
    
    private getCuentaDeClienteTipo = (id: number) => {
        return this.cuentaDeClienteTipoRegistry.get(id);
    }
    
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeClienteTipo = async (cuentaDeClienteTipo: CuentaDeClienteTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeClientesTipos.create(cuentaDeClienteTipo);
            runInAction(() => {
                this.cuentaDeClienteTipoRegistry.set(cuentaDeClienteTipo.id, cuentaDeClienteTipo);
                this.selectedCuentaDeClienteTipo = cuentaDeClienteTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteCuentaDeClienteTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeClientesTipos.delete(id);
            runInAction(() => {
                this.cuentaDeClienteTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get cuentasClientesTipoByDropdown(){
        let cuentaDeClienteTipoArreg=[new TipoDropDownValues()];
        let tipoCuentasClientesOpt =new TipoDropDownValues();
        const cucltipos=  Array.from(this.cuentaDeClienteTipoRegistry.values()).sort(
          (a, b) =>  {  if (a.nombre > b.nombre) return 1
            if (a.nombre < b.nombre) return -1
            return 0}
        );
        cucltipos.forEach((element) => {
            tipoCuentasClientesOpt.key = element.id;
            tipoCuentasClientesOpt.text = element.nombre;
            tipoCuentasClientesOpt.value = element.id;
            tipoCuentasClientesOpt.content = element.nombre;
            cuentaDeClienteTipoArreg.push(tipoCuentasClientesOpt);
            tipoCuentasClientesOpt =new TipoDropDownValues();
          
        });
        return cuentaDeClienteTipoArreg;
    }
}
