import { useField, useFormikContext } from "formik";
import { Form, Label } from "semantic-ui-react";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);
interface Props extends Partial<ReactDatePickerProps> {
  enterInput?: (e: number) => void;
  onChangeFecha?: (idSelected: any, setFieldValue?: any) => void;
  disabled?:boolean
}
export default function MyDateInput(props: Props) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(props.name!);
  const enterTabulador = (event: any) => {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    if (event.key === "Tab") {
      event.key = "Escape";
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    if (tabIndex === 0) {
      const elements = document.getElementsByName(field?.name);
      tabIndex = elements[0]?.tabIndex;
    }
    props.enterInput?.(tabIndex);
    event.preventDefault();
  };
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <DatePicker
        disabled={props.disabled?true:false}
        showTimeInput={true}
        locale="es"
        className=" widthtext90 heighttext"
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(value) => {
          helpers.setValue(value);
          props.onChangeFecha?.(value, setFieldValue);
        }}

        dateFormat="dd/MM/yyyy"
        tabIndex={props.tabIndex}
        onKeyDown={enterTabulador}
      />
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
