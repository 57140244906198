import { useEffect, useRef, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Modal, ModalContent, ModalHeader, Segment, Table } from 'semantic-ui-react';
import { enterInputs } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import { GeneracionComprobantePago, GeneracionComprobantePagoFormValues } from '../../../../app/models/venta/GeneracionComprobantePagos';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import { observer } from 'mobx-react-lite';

interface Props {
  pago: GeneracionComprobantePago;
  closeModals: () => void;
  setPagos: (setFO: GeneracionComprobantePagoFormValues) => void;
  tabIndex: number;
  onCopy?: (data: any) => void;
  cuentasDeVentasTipos?: number;
}

export default observer(function PagoComprobantes(props: Props) {
  const inputCondicionPagoRef = useRef<any>([]);
  const firstElement = props.tabIndex;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { condicionPagoStore, formaPagoStore } = useStore();
  const { formasPagosByDropdown } = formaPagoStore;
  const validationSchema = Yup.object({
    pagos: Yup.array().of(
      Yup.object().shape({
        importe: Yup.number()
          .positive("Importe debe de ser mayor a 0")
          .required("Importe es requerido"),
      })
    ),
  });
  useEffect(() => {
    condicionPagoStore.getCondicionesPagos();
  }, [])
  useEffect(() => {
    return () => {
      props.closeModals();
    }
  }, [])
  const condicionesPagosFiltradas = condicionPagoStore.filterCondicionesPagosByTipoVenta(props.cuentasDeVentasTipos);
  const initialIndexTable = props.tabIndex;
  const numberControlsTable = 4;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any) {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    inputCondicionPagoRef.current = inputCondicionPagoRef.current?.slice(0, values.pagos?.length);
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );

    setTimeout(() => {
      inputCondicionPagoRef.current = inputCondicionPagoRef.current.slice(0, values.pagos.length);
      inputCondicionPagoRef.current[values.pagos.length]?.Focus();
    }, 0);
  }
  const handleAddRow = (arrayHelpers: any, values: any) => {
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    inputCondicionPagoRef.current = inputCondicionPagoRef.current?.slice(0, values.pagos?.length);
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );

    setTimeout(() => {
      inputCondicionPagoRef.current = inputCondicionPagoRef.current.slice(0, values.pagos.length);
      inputCondicionPagoRef.current[values.pagos.length]?.Focus();
    }, 0);
  };
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 2);
  }

  function handleSubmit(fo: any) {
    props.onCopy?.(fo);
    props.setPagos(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }
  function deletedeclined() {
    setOpen({ abrir: false, index: 0 })
    closeModalEliminar();
  }
  const closeModalEliminar = () => {
    if (inputCondicionPagoRef.current[0]) {
      inputCondicionPagoRef.current[0].Focus();
    }
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    arrayHelpers.remove(index);
    setOpen({ abrir: false, index: 0 })
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable - numberControlsTable
    );
    closeModalEliminar();
  }
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.pago}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form className="ui form">
          <Segment>
            <FieldArray
              name="pagos"
              render={(arrayHelpers) => (
                <Segment basic className="tabla-container305">
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Condición de pago</Table.HeaderCell>
                        <Table.HeaderCell>Forma de pago</Table.HeaderCell>
                        <Table.HeaderCell>Importe</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {values.pagos?.map(
                        (pago, index) => (
                          <Table.Row key={index.valueOf()} className="paddinTable">
                            <Table.Cell className="paddinTable">
                              {" "}
                              <MySelectInput
                                tabIndex={index * 4 + 800001}
                                placeholder="Condición de pago"
                                label=""
                                name={`pagos[${index}].condiciondePagoId`}
                                options={condicionesPagosFiltradas}
                                enterInput={enterInput}
                                index={index}
                                ref={(el) => (inputCondicionPagoRef.current[index] = el)}
                                autoFocus={true}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {" "}
                              <MySelectInput
                                tabIndex={index * 4 + 800002}
                                placeholder="Forma de pago"
                                label=""
                                name={`pagos[${index}].formaDePagoId`}
                                options={formasPagosByDropdown}
                                enterInput={enterInput}
                                index={index}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {" "}
                              <MyTextInput
                                tabIndex={index * 4 + 800003}
                                className="widthTextTable100 heighttext input textAlingRight"
                                placeholder="Importe"
                                name={`pagos[${index}].importe`}
                                enterInput={enterInput}
                                arrayHelpers={arrayHelpers}
                                onKeyDown={
                                  index === values.pagos?.length! - 1 ? isValid ? onKeyDownSelectImporte : undefined : undefined
                                }
                                index={index}
                                columna={3}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Button icon
                                tabIndex={index * 4 + 800004}
                                name={pago.id}
                                type="Button"
                                disabled={false}
                                onClick={() => setOpen({ abrir: true, index: index })}
                                floated="right"
                                //    content="Eliminar"
                                color="red"
                              >
                                <Icon name='delete' />
                              </Button>
                            </Table.Cell>
                            <Modal
                              onClose={() => { setOpen({ abrir: false, index: 0 }); closeModalEliminar() }}
                              open={open.abrir}
                              size='mini'
                            >
                              <ModalHeader>{enumHeaderModals.DetallesComprobante}</ModalHeader>
                              <ModalContent>
                                <DeleteModals
                                  deleteConfirmed={deleteConfirmed}
                                  deletedeclined={deletedeclined}
                                  e={""}
                                  id={pago.id}
                                  nombre={"Fila"}
                                  arrayHelpers={arrayHelpers}
                                  index={open.index}
                                />
                              </ModalContent>
                            </Modal>
                          </Table.Row>
                        )
                      )
                      }
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="9">
                          <Button disabled={!isValid || isSubmitting} tabIndex={800000} type="button" onClick={() => isValid ? handleAddRow(arrayHelpers, values) : null}>
                            Agregar Renglón
                          </Button>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Segment>
              )}
            />
          </Segment>
          <Segment>
            <Button.Group>
              <Button
                tabIndex={values.pagos?.length! * 4 + 800005}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={values.pagos?.length! * 4 + 800006}
                type="button"
                onClick={(e) => regresar(e)}
                onKeyDown={(e: any) => {
                  if (e.key === "Tab" && !e.shiftKey) {
                    e.preventDefault();  // Prevenir el comportamiento predeterminado
                    if (inputCondicionPagoRef.current[0]) {
                      inputCondicionPagoRef.current[0].Focus();
                    }
                  }
                }}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})