import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Grid, Header, Button } from "semantic-ui-react";
import AlmacenList from "./AlmacenList";
import { useStore } from "../../../../app/stores/store";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import AlmacenForm from "../form/AlmacenForm";
import NotFound from "../../../../app/layout/NotFound";

export default observer(function AlmacenDashboard() {
  const { almacenStore, tabsStore } = useStore();
  useEffect(() => {
    almacenStore.loadAlmacenes();
  }, [almacenStore]);

  if (almacenStore.loadingInitial) return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />

        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2" icon="dropbox" content="Almacen" color="blue" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Almacen', <AlmacenForm id={0} />)}
              content="Crear Almacen" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Almacen', <NotFound />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => almacenStore.loadAlmacenes()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <AlmacenList />
      </Grid.Column>
    </Grid>
  );
});