import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { c_TipoRelacion } from "../../models/sat/c_TipoRelacion";

export default class c_TipoRelacionStore {
    c_TipoRelacionRegistry = new Map<number, c_TipoRelacion>();
    selectedC_TipoRelacion: c_TipoRelacion | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get c_TiposRelacionesByDropdown() {
        let c_TiposRelacionesArreg = [new TipoDropDownValues()];
        let c_TiposRelacionesOpt = new TipoDropDownValues();
        const cvts = Array.from(this.c_TipoRelacionRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            c_TiposRelacionesOpt.key = element.id;
            c_TiposRelacionesOpt.text = element.clave;
            c_TiposRelacionesOpt.value = element.id;
            c_TiposRelacionesOpt.content = element.nombre;
            c_TiposRelacionesArreg.push(c_TiposRelacionesOpt);
            c_TiposRelacionesOpt = new TipoDropDownValues();

        });
        return c_TiposRelacionesArreg;
    }

    get c_TiposRelacionesByDate() {
        return Array.from(this.c_TipoRelacionRegistry.values()).sort((a) => a.id);
    }


    loadc_TiposRelaciones = async () => {
        this.setLoadingInitial(true);
        try {
            const c_TiposRelaciones = await agent.C_TiposRelaciones.list();
            c_TiposRelaciones.forEach(c_TipoRelacion => {
                this.setC_TipoRelacion(c_TipoRelacion);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
        }
    }

    getc_TiposRelaciones = async () => {
        this.setLoadingInitial(true);
        const c_TiposRelaciones = await agent.C_TiposRelaciones.list();
        try {
            c_TiposRelaciones.forEach(c_TipoRelacion => {
                this.setC_TipoRelacion(c_TipoRelacion);
            })
            this.setLoadingInitial(false);
            return c_TiposRelaciones;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return c_TiposRelaciones;
        }
    }


    loadC_TipoRelacion = async (id: number) => {
        let c_TipoRelacion = this.getC_TipoRelacion(id);
        if (c_TipoRelacion) {
            this.selectedC_TipoRelacion = c_TipoRelacion;
            return c_TipoRelacion;
        } else {
            this.loadingInitial = true;
            try {
                c_TipoRelacion = await agent.C_TiposRelaciones.details(id);
                this.setC_TipoRelacion(c_TipoRelacion);
                this.selectedC_TipoRelacion = c_TipoRelacion;
                this.setLoadingInitial(false);
                return c_TipoRelacion;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setC_TipoRelacion = (c_TipoRelacion: c_TipoRelacion) => {
        this.c_TipoRelacionRegistry.set(c_TipoRelacion.id, c_TipoRelacion);
    }

    private getC_TipoRelacion = (id: number) => {
        return this.c_TipoRelacionRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoRelacion = async (c_TipoRelacion: c_TipoRelacion) => {
        this.loading = true;
        try {
            await agent.C_TiposRelaciones.create(c_TipoRelacion);
            runInAction(() => {
                this.c_TipoRelacionRegistry.set(c_TipoRelacion.id, c_TipoRelacion);
                this.selectedC_TipoRelacion = c_TipoRelacion;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }



    deleteTipoRelacion = async (id: number) => {
        this.loading = true;
        try {
            await agent.C_TiposRelaciones.delete(id);
            runInAction(() => {
                this.c_TipoRelacionRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
