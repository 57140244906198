import {
  FechaObservacion,
  FechaObservacionValues,
} from "../configuracion/FechaObservacion";
import { BancoSat } from "./BancoSat";
export interface Banco {
  id: number;
  clave: number;
  nombre: string;
  bancoTipoId: number;
  tipo?: BancoSat;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}
export class BancoFormValues implements Banco {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  bancoTipoId: number = 0;
  tipo?: BancoSat;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  demo: boolean = false;

  constructor(banco?: Banco) {
    Object.assign(this, banco);
  }
}
