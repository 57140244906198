export interface VerificadorGestionCliente {    
    id: number;
    fechaInicio?: Date;
    fechaFin?: Date;
    nombre: string;
    estado: string;
    tipoReporte?: number;
    ordenado?: number;
    empresa: string;
    sucursal: string;
    tipoMovimiento:string;
    serie: string;
    folio: string;
    cliente?: string;
    cuentaCliente?: string;
    conceptoCliente?:string;
    clave?:string;
    nombreFiltro?:string;
  }
  export class VerificadorGestionClienteFormValues implements VerificadorGestionCliente {
    id: number = 0;
    fechaInicio?: Date;
    fechaFin?: Date;
    nombre: string="";
    estado: string ="";
    tipoReporte?: number = 0;
    ordenado?: number = 0;
    empresa: string="";
    sucursal: string="";
    tipoMovimiento:string="";
    serie: string="";
    folio: string="";
    cliente?: string="";
    cuentaCliente?: string="";
    conceptoCliente?:string="";
    clave?:string="";
    nombreFiltro?:string="";
    constructor(init?: VerificadorGestionCliente) {
      Object.assign(this, init);
    }
  }
  