import { Grid, Container } from "semantic-ui-react";
import NavBar from "./NavBar";
import MenuVertical from "./MenuVertical";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalContainer from "../common/modals/ModalContainer";
import { StoreContext, useStore } from "../stores/store";
import DynamicTabs from "../common/form/DynamicTabs";
import { UserProvider } from "../context/UserProvider";
import { useState } from "react";
import { Outlet } from "react-router-dom";

function App() {
    const { tabsStore } = useStore();
    const [menuVisible, setMenuVisible] = useState(true);

    return (
        <UserProvider>
            <StoreContext.Provider value={useStore()}>
                <Container style={{ marginTop: '4em' }}>
                    <NavBar menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
                    <ModalContainer />
                    <ToastContainer />
                    <Grid>
                        {menuVisible && (
                            <Grid.Column width={3}>
                                <MenuVertical />
                            </Grid.Column>
                        )}
                        <Grid.Column width={menuVisible ? 13 : 16}>
                            {tabsStore?.tabs.length > 0 && <DynamicTabs />}
                        </Grid.Column>
                    </Grid>
                </Container>
            </StoreContext.Provider>
        </UserProvider>
    );
};

export default observer(App);