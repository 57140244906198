import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import { SaldoCliente, SaldoClienteFormValues } from "../../../../app/models/gestionCliente/SaldoCliente";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

export default observer(function SaldoClienteForm() {
  const firstElement = 0;
  const lastElement = 11;
  const { saldoClienteStore } = useStore();
  const { imprimirSaldoClientes, loading, loadingInitial } = saldoClienteStore
  useParams<{ id: string; }>();
  const [saldoCliente, setSaldoCliente] = useState(new SaldoClienteFormValues());
  const TipoReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];
  const OrdenReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaFin: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha al es requerida"),
    // tipoReporte: Yup.number()
    //   .min(1, "Favor de seleccionar un tipo Reporte.")
    //   .required("El tipo Reporte es requerido."),
    // ordenado: Yup.number()
    //   .min(1, "Favor de seleccionar un Ordenado.")
    //   .required("El Ordenado es requerido."),
  });
  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setSaldoCliente((state) => ({
      ...state,
      nombre: "REPORTE SALDOS DE CLIENTES ",
      // ordenado: "1",
      tipoReporte: 2,
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(saldoCliente: SaldoCliente) {
    void imprimirSaldoClientes(saldoCliente);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (
    event: any,
    firstElement?: number,
    lastElement?: number
  ) => {
    if (firstElement !== undefined && lastElement !== undefined)
      tabuladorButtons(event, firstElement, lastElement);
  };

  if (loadingInitial) return <LoadingComponent />
  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={saldoCliente}
      >
        {({ }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Saldos de clientes' color='blue' />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  autoFocus={true}
                  tabIndex={1}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                />
              </div>

              <div></div>
              <div className='col35'>
                Fecha Al
                <MyDateInput
                  name="fechaFin"
                  tabIndex={2}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={5}
                  enterInput={enterInput}
                  tipoDato={enumTipoDato.NumericoSinPunto}
                />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={6}
                  enterInput={enterInput}
                  tipoDato={enumTipoDato.NumericoSinPunto}
                />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Cuenta'
                  label="Cuenta"
                  name='cuentaCliente'
                  maxLength={50}
                  tabIndex={7}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Cliente'
                  label="Cliente"
                  name='cliente'
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Serie'
                  label="Serie"
                  name='serie'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Folio'
                  label="Folio"
                  name='folios'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput} />
              </div>
            </Segment>
            <Segment>
              <Button
                disabled={loading}
                loading={loading}
                tabIndex={11} type="submit" color="blue" onKeyDown={(e: any) => {
                  tabuladorButton(e, firstElement, lastElement)
                }
                }>
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})