import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { MonedaTipo } from "../models/configuracion/MonedaTipo";
import { entityByDropdown } from "../common/funciones/entityByDropdown";

export default class MonedaTipoStore {
  monedaTipoRegistry = new Map<number, MonedaTipo>();
  selectedMonedaTipo: MonedaTipo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadMonedaTipos = async () => {
    this.setLoadingInitial(true);
    try {
      const monedaTipos = await agent.MonedaTipos.list();
      runInAction(() => {
        monedaTipos.forEach((monedaTipo) => {
          this.setMonedaTipo(monedaTipo);
        });
        this.setLoadingInitial(false);
      });
    } catch (error) {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };
  get monedasTipoByDropdown() {
    return entityByDropdown<MonedaTipo>(
      Array.from(this.monedaTipoRegistry.values())
    );
  }

  getMonedaTipos = async () => {
    this.setLoadingInitial(true);
    const monedaTipos = await agent.MonedaTipos.list();
    try {
      monedaTipos.forEach((monedaTipo) => {
        this.setMonedaTipo(monedaTipo);
      });
      this.setLoadingInitial(false);
      return monedaTipos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return monedaTipos;
    }
  };

  loadMonedaTipo = async (id: number) => {
    let monedaTipo = this.getMonedaTipo(id);
    if (monedaTipo) {
      this.selectedMonedaTipo = monedaTipo;
      return monedaTipo;
    } else {
      this.loadingInitial = true;
      try {
        monedaTipo = await agent.MonedaTipos.details(id);
        this.setMonedaTipo(monedaTipo);
        this.selectedMonedaTipo = monedaTipo;
        this.setLoadingInitial(false);
        return monedaTipo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setMonedaTipo = (monedaTipo: MonedaTipo) => {
    this.monedaTipoRegistry.set(monedaTipo.id, monedaTipo);
  };
  private setMonedaTipos = (monedaTipo: MonedaTipo[]) => {
    // this.monedaTipoRegistry.set(monedaTipo.id, monedaTipo);
  };

  private getMonedaTipo = (id: number) => {
    return this.monedaTipoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createMonedaTipo = async (monedaTipo: MonedaTipo) => {
    this.loading = true;
    try {
      await agent.MonedaTipos.create(monedaTipo);
      runInAction(() => {
        this.monedaTipoRegistry.set(monedaTipo.id, monedaTipo);
        this.selectedMonedaTipo = monedaTipo;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  

  deleteMonedaTipo = async (id: number) => {
    this.loading = true;
    try {
      await agent.MonedaTipos.delete(id);
      runInAction(() => {
        this.monedaTipoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
