import { TipoDropdown } from "../../models/configuracion/TipoDropdown";
export const meses: TipoDropdown[] = [
    { key: 0, text: "Seleccionar", value: 0, content: "Seleccionar" },
    { key: 1, text: 'Enero', value: 1 },
    { key: 2, text: 'Febrero', value: 2 },
    { key: 3, text: 'Marzo', value: 3 },
    { key: 4, text: 'Abril', value: 4 },
    { key: 5, text: 'Mayo', value: 5 },
    { key: 6, text: 'Junio', value: 6 },
    { key: 7, text: 'Julio', value: 7 },
    { key: 8, text: 'Agosto', value: 8 },
    { key: 9, text: 'Septiembre', value: 9 },
    { key: 10, text: 'Octubre', value: 10 },
    { key: 11, text: 'Noviembre', value: 11 },
    { key: 12, text: 'Diciembre', value: 12 },
];


