import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeCompraTipo } from "../../models/compra/CuentaDeCompraTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CuentaDeCompraTipoStore {
    cuentaDeCompraTipoRegistry = new Map<number, CuentaDeCompraTipo>();
    selectedCuentaDeCompraTipo: CuentaDeCompraTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadCuentasComprasTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasComprasTipos = await agent.CuentasDeComprasTipos.list();

            cuentasComprasTipos.forEach(cuentaDeCompraTipo => {
                this.setCuentaDeCompraTipo(cuentaDeCompraTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCuentaDeCompraTipo = async (id: number) => {
        let cuentaDeCompraTipo = this.getCuentaDeCompraTipo(id);
        if (cuentaDeCompraTipo) {
            this.selectedCuentaDeCompraTipo = cuentaDeCompraTipo;
            return cuentaDeCompraTipo;

        } else {
            this.loadingInitial = true;
            try {
                cuentaDeCompraTipo = await agent.CuentasDeComprasTipos.details(id);
                this.selectedCuentaDeCompraTipo = cuentaDeCompraTipo;
                this.setLoadingInitial(false);
                return cuentaDeCompraTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeCompraTipo = (cuentaDeCompraTipo: CuentaDeCompraTipo) => {
        this.cuentaDeCompraTipoRegistry.set(cuentaDeCompraTipo.id, cuentaDeCompraTipo);
    }

    private getCuentaDeCompraTipo = (id: number) => {
        return this.cuentaDeCompraTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeCompraTipo = async (cuentaDeCompraTipo: CuentaDeCompraTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeComprasTipos.create(cuentaDeCompraTipo);
            runInAction(() => {
                this.cuentaDeCompraTipoRegistry.set(cuentaDeCompraTipo.id, cuentaDeCompraTipo);
                this.selectedCuentaDeCompraTipo = cuentaDeCompraTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeCompraTipo = async (cuentaDeCompraTipo: CuentaDeCompraTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeComprasTipos.update(cuentaDeCompraTipo);
            runInAction(() => {
                this.cuentaDeCompraTipoRegistry.set(cuentaDeCompraTipo.id, cuentaDeCompraTipo);
                this.selectedCuentaDeCompraTipo = cuentaDeCompraTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeCompraTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeComprasTipos.delete(id);
            runInAction(() => {
                this.cuentaDeCompraTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get cuentasDeComprasTipoByDropdown() {
        return entityByDropdown<CuentaDeCompraTipo>(
            Array.from(this.cuentaDeCompraTipoRegistry.values())
        );
    }

}