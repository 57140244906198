import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { SerieFolioDeVenta } from "../venta/SerieFolioDeVenta";
import { TraspasoEntreSucursalDetalle } from "./TraspasoEntreSucursalDetalle";
import { TipoVenta } from "../venta/TipoVenta";

export interface TraspasoEntreSucursal {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoVentaId: number;
    tipoVenta?: TipoVenta;
    serieFolioDeVentaId: number;
    serieFolioDeVenta?: SerieFolioDeVenta;
    folio: number;
    sucursalDestinoId: number;
    sucursalDestino?: Sucursal;
    fechaEmision: Date;
    fechaCancelacion?: Date;
    totalCosto: number;
    almacenOrigenId?: number;
    almacenDestinoId?: number;
    TraspasoEntresSucursalDetalles: TraspasoEntreSucursalDetalle[];
}

export class TraspasoEntreSucursalFormValues implements TraspasoEntreSucursal {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoVentaId: number = 0;
    tipoVenta?: TipoVenta;
    serieFolioDeVentaId: number = 0;
    serieFolioDeVenta?: SerieFolioDeVenta;
    folio: number = 0;
    sucursalDestinoId: number = 0;
    sucursalDestino?: Sucursal;
    fechaEmision: Date = new Date();
    fechaCancelacion?: Date = undefined;
    totalCosto: number = 0;
    almacenOrigenId?: number = 0;
    almacenDestinoId?: number = 0;
    TraspasoEntresSucursalDetalles: TraspasoEntreSucursalDetalle[] = [];
    constructor(init?: TraspasoEntreSucursal) {
        Object.assign(this, init);
    }
}