import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { ReporteVentas, ReporteVentasFormValues } from "../../../../app/models/venta/ReporteVentas";
import { urlApi } from "../../../../app/api/agent";

export default observer(function ReporteMonedaForm() {

  const firstElement = 0;
  const lastElement = 19;
  const { proveedorStore, generacionComprobanteStore, reporteConfiguracionesStore } = useStore();
  const { empresaSucursal } = proveedorStore
  const { obtenerSiguienteFolio, loadingInitial } = generacionComprobanteStore
  const { imprimirReporteMonedas } = reporteConfiguracionesStore
  const [reporteVentas, setReporteVentas] = useState(new ReporteVentasFormValues());
  const TipoReporteByDropdown = [
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];

  const OrdenReporteByDropdown = [
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().required("El folio es requerido."),
    fechaFin: Yup.date().required("El folio es requerido."),
    tipoReporte: Yup.number()
      .min(1, "Favor de seleccionar un tipo Reporte.")
      .required("El tipo Reporte es requerido."),
    ordenado: Yup.number()
      .min(1, "Favor de seleccionar un Ordenado.")
      .required("El Ordenado es requerido."),
  });
  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setReporteVentas((state) => ({
      ...state,
      nombre: "REPORTE DE MONEDAS ", ordenado: "1",
      tipoReporte: "1",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(reporteVentas: ReporteVentas) {
    imprimirReporteMonedas(reporteVentas).then(result => {
      debugger
      if (result !== undefined) {
        let features =
          'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        window.open(urlApi + result, '_blank', features);
      }
    })

    //generacionComprobante.id > 0 ? updateGeneracionComprobante(generacionComprobante) : createGeneracionComprobante(generacionComprobante);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  function chageEnter(tabIndex: number) {
    obtenerSiguienteFolio(empresaSucursal);
  }

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteVentas}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Reporte de Monedas' color='blue' />


            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  tabIndex={0}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                  change={chageEnter}
                />
              </div>

              <div></div>



              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>


              <div className='col50'>
                <MyTextInput
                  placeholder='Clave'
                  label="Clave"
                  name='clave'
                  maxLength={50}
                  tabIndex={5}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Nombre'
                  label="Nombre"
                  name='nombreFiltro'
                  maxLength={50}
                  tabIndex={6}
                  enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col50'>
                <MyTextInput
                  placeholder='Tipo Moneda'
                  label="Tipo Moneda"
                  name='tipoMoneda'
                  maxLength={50}
                  tabIndex={7}
                  enterInput={enterInput} />
              </div>

            </Segment>

            <Segment>
              <Button tabIndex={12} type="submit" color="blue">
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}

      </Formik>

    </Segment>




  )
})