import { observer } from 'mobx-react-lite';
import { Grid, Header, Button } from 'semantic-ui-react';
import ImpuestoProductoList from './ImpuestoProductoList';
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import ImpuestoProductoForm from '../form/ImpuestoProductoForm';
import ReporteImpuestoProductoForm from '../form/ReporteImpuestoProductoForm';

export default observer(function ImpuestoProductoDashboard() {
  const { tabsStore } = useStore();
  const { impuestoProductoStore } = useStore();
  useEffect(() => {
    impuestoProductoStore.loadImpuestosProductos();
  }, [impuestoProductoStore]);

  if (impuestoProductoStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' content='Impuestos Productos' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Impuesto Producto', <ImpuestoProductoForm id={0} />)}
              content='Crear Imp. Prod.' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Impuesto Producto', <ReporteImpuestoProductoForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => impuestoProductoStore.loadImpuestosProductos()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <ImpuestoProductoList />
      </Grid.Column>
    </Grid>
  )
})