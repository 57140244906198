import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeProveedor } from "../../models/gestionProveedor/CuentaDeProveedor";


import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";


export default class CuentaDeProveedorStore {
    cuentaDeProveedorRegistry = new Map<number, CuentaDeProveedor>();
    selectedCuentaDeProveedor: CuentaDeProveedor | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get cuentaDeProveedorByDropdown() {
        return entityByDropdown<CuentaDeProveedor>(Array.from(this.cuentaDeProveedorRegistry.values()));
    }

    get cuentasDeProveedoresByDate() {
        return Array.from(this.cuentaDeProveedorRegistry.values()).sort((a) => a.id);
    }

    get cuentasDeProveedoresById(){
        return Array.from(this.cuentaDeProveedorRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get cuentasDeProveedoresByDropdown() {
        let CuentaDeProveedorArreg = [new TipoDropDownValues()];
        let cuentaDeProveedorOpt = new TipoDropDownValues();
        const cvts = Array.from(this.cuentaDeProveedorRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            cuentaDeProveedorOpt.key = element.id;
            cuentaDeProveedorOpt.text = element.nombre;
            cuentaDeProveedorOpt.value = element.id;
            cuentaDeProveedorOpt.content = element.nombre;
            CuentaDeProveedorArreg.push(cuentaDeProveedorOpt);
            cuentaDeProveedorOpt = new TipoDropDownValues();

        });
        return CuentaDeProveedorArreg;
    }

    getCuentasDeProveedores = async () => {
        this.setLoadingInitial(true);
        const cuentasDeProveedores = await agent.CuentasDeProveedores.list();
        try {
            cuentasDeProveedores.forEach(cuentaDeProveedor => {
                this.setCuentaDeProveedor(cuentaDeProveedor);
            })
            this.setLoadingInitial(false);
            return cuentasDeProveedores;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasDeProveedores;
        }
    }

    loadCuentasDeProveedores = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasDeProveedores = await agent.CuentasDeProveedores.list();
            cuentasDeProveedores.forEach(cuentaDeProveedor => {

                this.setCuentaDeProveedor(cuentaDeProveedor);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCuentaDeProveedor = async (id: number) => {
        let cuentaDeProveedor = this.getCuentaDeProveedor(id);
        if (cuentaDeProveedor) {

            this.selectedCuentaDeProveedor = cuentaDeProveedor;
            return cuentaDeProveedor;
        } else {
            this.loadingInitial = true;
            try {
                cuentaDeProveedor = await agent.CuentasDeProveedores.details(id);
                this.setCuentaDeProveedor(cuentaDeProveedor);
                this.selectedCuentaDeProveedor = cuentaDeProveedor;
                this.setLoadingInitial(false);
                return cuentaDeProveedor;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeProveedor = (cuentaDeProveedor: CuentaDeProveedor) => {
        this.cuentaDeProveedorRegistry.set(cuentaDeProveedor.id, cuentaDeProveedor);
    }

    private getCuentaDeProveedor = (id: number) => {
        return this.cuentaDeProveedorRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeProveedor = async (cuentaDeProveedor: CuentaDeProveedor) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedores.create(cuentaDeProveedor);
            runInAction(() => {
                this.selectedCuentaDeProveedor = cuentaDeProveedor;
                this.editMode = false;
                this.loading = false;
            })


        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeProveedor = async (cuentaDeProveedor: CuentaDeProveedor) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedores.update(cuentaDeProveedor)
            runInAction(() => {
                this.selectedCuentaDeProveedor = cuentaDeProveedor;
                this.editMode = false;
                this.loading = false;
            })

            
        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeProveedor = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedores.delete(id);
            runInAction(() => {
                this.cuentaDeProveedorRegistry.delete(id);
                this.loading = false;
            })

        } catch (error) {

            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}