import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { ListaDePrecioTipo } from "./ListaDePrecioTipo";

export interface ListaDePrecio {
  id: number;
  clave: number;
  nombre: string;
  activa: boolean;
  listaDePrecioTipoId: number;
  listaDePrecioTipo?: ListaDePrecioTipo;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}
export interface IListaDePrecioFormValues extends Partial<ListaDePrecio> {
  listaDePrecioTipoId: any;

}

export class ListaDePrecioFormValues implements IListaDePrecioFormValues {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  activa: boolean = false;
  listaDePrecioTipoId: number = 0;
  listaDePrecioTipo?: ListaDePrecioTipo;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  constructor(init?: ListaDePrecio) {
    Object.assign(this, init);
  }
}