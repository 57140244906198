import { makeAutoObservable } from "mobx";
import { EstadoCuentaDetalles } from "../../models/gestionCliente/EstadoCuenta";
import agent from "../../api/agent";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
export default class EstadoCuentaAlmacenStore {
    detallesRegistry = new Map<number, EstadoCuentaDetalles>();
    editMode = false;
    loading = false;
    loadingInitial = false;
    estadoCuentaParams: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this);
    }

    loadEstadoCuentaAlmacen = async (filtroEstadoCuentaInventario: PaginationSpecParams) => {
        try {
            debugger
            let estadoCuenta = await agent.EstadoCuentasAlmacenes.getEstadoCuentaByClave(filtroEstadoCuentaInventario);
            estadoCuenta?.estadoCuentaDetalles?.forEach((detalles) => { this.setDetalles(detalles);  });
            return estadoCuenta;
        } catch (error) {
            console.error(error);
        }
    }
    private setDetalles = (detalles: EstadoCuentaDetalles) => {
        this.detallesRegistry.set(detalles.id, detalles);
    };

    get detallesById() {
        return Array.from(this.detallesRegistry.values()).sort(
            (a, b) => a.movto - b.movto
        );
    }
    public setDetallesByIdRegClear = () => {
        this.detallesRegistry.clear();
    }
}
