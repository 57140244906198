import { Impuesto } from "../configuracion/Impuesto";

export interface ImpuestoDetalle {
    id: number;
    impuestoId: number;
     impuesto?: Impuesto
     numero: number;
     base :number;
     tasa :number;
     importe:number;
     nombreImpuesto:string;
     tipo: string;
     incluidoEnPrecio: boolean
    //public GeneracionComprobanteDetalle ComprobanteDetalle { get; set; }
}
export interface IImpuestoDetalleFormValues extends Partial<ImpuestoDetalle> {
    selectedIdImpuestoTipo: any;

}
export class ImpuestoDetalleFormValues implements IImpuestoDetalleFormValues {
    id: number =0;
    selectedIdImpuestoTipo: undefined;
    numero: number =0;
    impuestoId: number =0;
     impuesto?: Impuesto
    //public Impuesto Impuesto { get; set; }
     base :number =0;
     tasa :number =0;
     importe:number =0;
     nombreImpuesto:string="";
     tipo: string="";
     incluidoEnPrecio: boolean = false;
    //public GeneracionComprobanteDetalle ComprobanteDetalle { get; set; }
}