import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import { VerificadorGestionCliente, VerificadorGestionClienteFormValues } from "../../../../app/models/gestionCliente/VerificadorGestionCliente";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

export default observer(function VerificadorGestionClienteForm() {
  const firstElement = 0;
  const lastElement = 14;
  const { verificadorGestionClienteStore } = useStore();
  const { imprimirVerificadorGestionClientes, loading, loadingInitial } = verificadorGestionClienteStore
  useParams<{ id: string; }>();
  const [verificadorGestionCliente, setVerificadorGestionCliente] = useState(new VerificadorGestionClienteFormValues());
  const TipoReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];
  const OrdenReporteByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().typeError("La fecha inicio debe ser fecha.").required("La fecha inicio es requerido"),
    fechaFin: Yup.date().typeError("La fecha fin debe ser fecha.").required("La fecha fin es requerido").min(Yup.ref('fechaInicio'), 'La fecha fin debe ser mayor a la fecha de inicio.'),
  });
  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setVerificadorGestionCliente((state) => ({
      ...state,
      nombre: "VERIFICADOR DE GESTION DE CLIENTES ",
      ordenado: 1,
      tipoReporte: 1,
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(verificadorGestionCliente: VerificadorGestionCliente) {
    void imprimirVerificadorGestionClientes(verificadorGestionCliente);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (
    event: any,
    firstElement?: number,
    lastElement?: number
  ) => {
    if (firstElement !== undefined && lastElement !== undefined)
      tabuladorButtons(event, firstElement, lastElement);
  };
  if (loadingInitial) return <LoadingComponent />
  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={verificadorGestionCliente}
      >
        {({ }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Verificador de Gestion de Cliente' color='blue' />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  autoFocus={true}
                  tabIndex={1}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                />
              </div>

              <div></div>

              <div className='col35'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={2}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}

                />
              </div>

              <div className='col35'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={3}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={5}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={6}
                  tipoDato={enumTipoDato.NumericoSinPunto}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={7}
                  tipoDato={enumTipoDato.NumericoSinPunto}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Tipo Movimiento'
                  label="Tipo Movimiento"
                  name='tipoMovimiento'
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Cuenta'
                  label="Cuenta"
                  name='cuentaCliente'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Cliente'
                  label="Cliente"
                  name='cliente'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Concepto'
                  label="Concepto"
                  name='conceptoCliente'
                  maxLength={50}
                  tabIndex={11}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Serie'
                  label="Serie"
                  name='serie'
                  maxLength={50}
                  tabIndex={12}
                  enterInput={enterInput} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Folio'
                  label="Folio"
                  name='folio'
                  maxLength={50}
                  tabIndex={13}
                  enterInput={enterInput} />
              </div>
            </Segment>
            <Segment>
              <Button disabled={loading} loading={loading} tabIndex={14} type="submit" color="blue" onKeyDown={(e: any) =>
                tabuladorButton(e, firstElement, lastElement)
              }>
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})