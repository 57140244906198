import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SerieFolioFormValues } from "../../../../app/models/venta/SerieFolioDeVenta";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import { enterInputs, tabuladorButtons } from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import FileWidgetDropzone from "../../../../app/common/form/FileWidgetDropzone";

interface idProps {
  id: number
}

export default observer(function SerieFolioForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 11;
  const { proveedorStore, serieFolioStore, serieFolioTipoStore, tipoVentaStore, sucursalStore, empresaStore, modalStore } = useStore();
  const { empresaSucursal } = proveedorStore;
  const { openModal, closeModal } = modalStore;
  const { createSerieFolio, updateSerieFolio, loadSerieFolio, loadingInitial, loading } = serieFolioStore;
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore;
  const { sucursalesByEmpresaByDropdownByClave, loadSucursalByIdEmpresa } = sucursalStore;
  const { TiposVentasByDropdownByClave } = tipoVentaStore;
  const { serieFolioTiposByDropdown, serieFolioTiposByDropdownByClave } = serieFolioTipoStore;
  const [serieFolio, setSerieFolio] = useState(new SerieFolioFormValues());
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  const [empresas, setempresas] = useState([
    { key: '0', value: '0', text: 'Seleccionar' }
  ])
  const [sucursales, setsucursales] = useState([{ key: '0', value: 'Sel', text: 'Seleccionar' }])
  const [tiposVentas, setTiposVentas] = useState([{ key: '1', value: 'Sel', text: 'Seleccionar' }])

  useEffect(() => {
    empresaStore.getEmpresas();
    void loadEntityEmpresas();
  }, [empresaStore, loadEntityEmpresas])

  useEffect(() => {
    sucursalStore.getSucursales().then(sucs => { })
  }, [sucursalStore])

  useEffect(() => {
    tipoVentaStore.getTiposVentas().then(TipsVentas => { })
  }, [tipoVentaStore])

  useEffect(() => {
    serieFolioTipoStore.getSerieFolioTipos().then(serieFolioTip => { })
  }, [serieFolioTipoStore])

  const validationSchema = Yup.object({
    empresaId: Yup.string().required('Es requerida.'),
    sucursalId: Yup.string().required('Es requerida.'),
    tipoVentaId: Yup.number().min(1, "Favor de seleccionar un tipo venta.").required("El tipo de moneda es requerido."),
    clave: Yup.string().required('Es requerida.'),
    nombre: Yup.string().required('Es requerido.'),
    serieFolioDeVentaTipoId: Yup.number().min(1, "Favor de seleccionar un tipo moneda.").required("El tipo de moneda es requerido."),

  });
  useEffect(() => {
    if (id) {
      loadSerieFolio(id).then(serieFolio => {
        setSerieFolio(new SerieFolioFormValues(serieFolio)!)
        setFechaObservacion(
          new FechaObservacionValues(serieFolio?.fechaObservacion)
        );
      }
      );
    }
  }, [id, loadSerieFolio])

  function handleSubmit(serieFolio: SerieFolioFormValues) {
    if (serieFolio.empresa) {
      if (serieFolio.fechaObservacion) {
        serieFolio.fechaObservacion = fechaObservacion;
      }
      const empresaSelected = empresas.find(
        (a) => a.key === serieFolio.empresaId.toString()
      );
      if (empresaSelected) {
        serieFolio.empresa.nombre = empresaSelected.text;
        serieFolio.empresa.id = Number.parseInt(empresaSelected.key);
        serieFolio.empresa.clave = Number.parseInt(empresaSelected.value);
      }
    }
    if (serieFolio.empresaId.toString() !== "0" && serieFolio.nombre !== null) {
      serieFolio.id > 0 ? updateSerieFolio(serieFolio) : createSerieFolio(serieFolio);
    } else {
      toast.info("Favor de llenar campos");
    }
  }


  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }
  function onChangeSelectEmpresa(idSelected: number, setFieldAny: any) {
    setFieldAny("sucursalId", 0);
    empresaSucursal.sucursalId = 0;
    void loadSucursalByIdEmpresa(idSelected);
    empresaSucursal.empresaId = idSelected;
  }

  if (loadingInitial) return <LoadingComponent content='Loading serie y folio' />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={serieFolio}>

        {({ isValid, dirty, setFieldValue, values }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Series y folios
              </Label>
              <div></div>
              <div className='col80 '>
                <MySelectInput
                  autoFocus={true}
                  tabIndex={1}
                  placeholder={serieFolio.empresa?.nombre || "Seleccionar"}
                  label="Empresa"
                  name="empresaId"
                  onChange={onChangeSelectEmpresa}
                  options={EmpresasByDropdownByClave}
                  enterInput={enterInput}
                  selectWithLabel={true}
                  inputWidth="85px"
                  menuWidth="450px"
                />
              </div>

              <div className='col80 '>
                <MySelectInput
                  tabIndex={2}
                  placeholder={"Seleccionar"}
                  label="Sucursal"
                  name="sucursalId"
                  options={sucursalesByEmpresaByDropdownByClave}
                  enterInput={enterInput}
                  selectWithLabel={true}
                  inputWidth="85px"
                  menuWidth="450px"
                />
              </div>

              <div className='col80 '>
                <MySelectInput
                  tabIndex={3}
                  placeholder={"Seleccionar"}
                  label="Tipo de Venta"
                  name="tipoVentaId"
                  options={TiposVentasByDropdownByClave}
                  enterInput={enterInput}
                  selectWithLabel={true}
                  inputWidth="85px"
                  menuWidth="300px"
                />
              </div>

              <div className='col10'></div>
              <div className='col10'>

                <MyTextInput placeholder="Clave" label="Clave" name="clave" tabIndex={4} enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col60'>
                <MyTextInput placeholder="Nombre" label="Nombre" name="nombre" tabIndex={5} enterInput={enterInput} />
              </div>
              <div></div>

              <div className='col80 '>
                <MySelectInput
                  tabIndex={6}
                  placeholder={"Seleccionar"}
                  label="Tipo"
                  name="serieFolioDeVentaTipoId"
                  options={serieFolioTiposByDropdownByClave}
                  enterInput={enterInput}
                  selectWithLabel={true}
                  inputWidth="85px"
                  menuWidth="140px"
                />
              </div>
            </Segment>
            <Label as='a' color='red' ribbon>
              Generales
            </Label>
            <Segment clearing >
              <div >
                <MyTextInput placeholder="Ultimo folio" label="Ultimo folio:" name="ultimoFolio" className="uiAnchoCombo heighttext" tabIndex={7} enterInput={enterInput} />
              </div>
              <div >
                <MyTextInput placeholder="Lema Empresa" label="Lema Empresa:" name="lemaEmpresa" className="uiAnchoCombo heighttext" tabIndex={8} enterInput={enterInput} />
              </div>
              <div className="col60Block">
                <FileWidgetDropzone
                  label="Logo de Empresa"
                  files={values.fileLogo}
                  className="uiAnchoCombo heightFileWidgetDropzone input"
                  acceptFileDrop={".png"}
                  name="logo"
                />
              </div>
              <div className="col60Block">
                <FileWidgetDropzone
                  label="Logo para ticket"
                  files={values.fileLogoTicket}
                  className="uiAnchoCombo heightFileWidgetDropzone input"
                  acceptFileDrop={".png"}
                  name="logoTicket"
                />
              </div>
              <div >
                <MyTextInput placeholder="Codigo contable" label="Codigo Contable:" name="codigoContable" className="uiAnchoCombo heighttext" tabIndex={9} enterInput={enterInput} />
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={10}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => {
                          setFieldValue("fechaObservacion", data)
                          serieFolio.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                      }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.SerieFolioVenta
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <BarraBotones
              toLink="/seriesFolios"
              loading={loading}
              tabIndex={11}
              firstElement={0}
              lastElement={13}></BarraBotones>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})