import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoMovimiento } from "../TipoMovimiento";
import { CuentaDeCliente } from "./CuentaDeCliente";
import { ConceptoCliente } from "./ConceptoCliente";
import { Cliente } from "./Cliente";
import { Moneda } from "../configuracion/Moneda";
import { FormaDePago } from "./FormaDePago";
import { Usuario } from "../configuracion/Usuario";

export interface MovimientoClienteDetalle {
    id: number;
    nombre?: string;
    cuentaDeClienteId: number;
    cuentaDeCliente?: CuentaDeCliente;
    conceptoDeClienteId: number;
    conceptoDeCliente?: ConceptoCliente;
    clienteId: number;
    cliente?: Cliente;
    serie: string;
    folio: number;
    serieAfecta: string;
    folioAfecta: number;
    importe: number;
    monedaId: number;
    moneda?: Moneda;
    tipoCambio: number;
    formaDePagoId: number;
    formaDePago?: FormaDePago;
    automatico?: boolean | number;
    movimientoClienteId?: number;
    subtotal: number;
    iva: number;

    //
    empresaId?: number;
    empresa?: Empresa;
    sucursalId?: number;
    sucursal?: Sucursal;
    tipoMovimientoId?: number;
    tipoMovimiento?: TipoMovimiento;
    clave: number;
    fecha: Date;

    fechaVencimiento?: Date;
    fechaCancelacion?: Date;
    esPPD?: boolean;
    UUID?: string;
    vendedorId?: number;
    vendedor?: Usuario;
    condicionDePagoId?: number;
    comentarios?: string;

}
export class MovimientoClienteDetalleFormValues implements MovimientoClienteDetalle {
    id: number = 0;
    clave: number = 0;
    fecha: Date = new Date();
    cuentaDeClienteId: number = 0;
    cuentaDeCliente?: CuentaDeCliente;
    conceptoDeClienteId: number = 0;
    conceptoDeCliente?: ConceptoCliente;
    clienteId: number = 0;
    cliente?: Cliente;
    serie: string = "";
    folio: number = 0;
    serieAfecta: string = "";
    folioAfecta: number = 0;
    importe: number = 0;
    monedaId: number = 0;
    moneda?: Moneda;
    tipoCambio: number = 0;
    formaDePagoId: number = 0;
    formaDePago?: FormaDePago;
    automatico?: boolean | number = 0;
    movimientoClienteId: number = 0;
    vendedorId: number = 0;
    vendedor?: Usuario;
    subtotal: number = 0;
    iva: number = 0;
    condicionDePagoId?: number = 0;
    constructor(init?: MovimientoClienteDetalle) {
        Object.assign(this, init);
    }
}
export interface MovimientoCliente extends MovimientoClienteDetalle {
    movimientoClienteDetalle?: MovimientoClienteDetalle[];
}
export class MovimientoClienteFormValues implements MovimientoCliente {
    id: number = 0;
    selectedIdEmpresa: undefined;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoMovimientoId: number = 0;
    tipoMovimiento?: TipoMovimiento;
    clave: number = 0;
    fecha: Date = new Date();
    comentarios: string = "";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    
    movimientoClienteDetalle: MovimientoClienteDetalle[] = [new MovimientoClienteDetalleFormValues()]
    //controlListaDePrecio: ControlListaDePrecio[]= [new ControlListaDePrecioFormValues()];
    //MovimientoClienteDetalle?: MovimientoClienteDetalle[];
    cuentaDeClienteId: number = 0;
    conceptoDeClienteId: number = 0;
    clienteId: number = 0;
    folio: number = 0;
    serieAfecta: string = "";
    folioAfecta: number = 0;
    importe: number = 0;
    monedaId: number = 0;
    tipoCambio: number = 0;
    formaDePagoId: number = 0;
    movimientoClienteId: number = 0;
    subtotal: number = 0;
    serie: string = "";
    iva: number = 0;
    constructor(init?: MovimientoCliente) {
        Object.assign(this, init);
    }
}

