import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ClienteTipo } from "../../models/gestionCliente/ClienteTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class ClienteTipoStore {
    clienteTipoRegistry = new Map<number, ClienteTipo>();
    selectedClienteTipo: ClienteTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get clientesTiposByDropdown() {
        return entityByDropdown<ClienteTipo>(
          Array.from(this.clienteTipoRegistry.values())
        );
      }

    get clientesByDate() {
        return Array.from(this.clienteTipoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadClienteTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const clienteTipos = await agent.ClienteTipos.list();
            clienteTipos.forEach(clienteTipo => {
                this.setClienteTipo(clienteTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getClienteTipos = async () => {
        this.setLoadingInitial(true);
        const clienteTipos = await agent.ClienteTipos.list();
        try {
            clienteTipos.forEach(clienteTipo => {
                this.setClienteTipo(clienteTipo);
            })
            this.setLoadingInitial(false);
            return clienteTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return clienteTipos;
        }
    }

    loadClienteTipo = async (id: number) => {
        let clienteTipo = this.getClienteTipo(id);
        if (clienteTipo) {
            this.selectedClienteTipo = clienteTipo;
            return clienteTipo;

        } else {
            this.loadingInitial = true;
            try {
                clienteTipo = await agent.ClienteTipos.details(id);
                this.setClienteTipo(clienteTipo);
                this.selectedClienteTipo = clienteTipo;
                this.setLoadingInitial(false);
                return clienteTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setClienteTipo = (clienteTipo: ClienteTipo) => {
        this.clienteTipoRegistry.set(clienteTipo.id, clienteTipo);
    }

    private getClienteTipo = (id: number) => {
        return this.clienteTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createClienteTipo = async (clienteTipo: ClienteTipo) => {
        this.loading = true;
        try {
            await agent.ClienteTipos.create(clienteTipo);
            runInAction(() => {
                this.clienteTipoRegistry.set(clienteTipo.id, clienteTipo);
                this.selectedClienteTipo = clienteTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteClienteTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ClienteTipos.delete(id);
            runInAction(() => {
                this.clienteTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
