import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { Banco } from "../../models/Finanza/Banco";

export default class BancoStore {
    bancoRegistry = new Map<number, Banco>();
    selectedBanco: Banco | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get bancoByDropdown() {
        return entityByDropdown<Banco>(Array.from(this.bancoRegistry.values()));
    }
    get bancoByDate() {
        return Array.from(this.bancoRegistry.values()).sort((a) => a.id);
    }

    get bancoById() {
        return Array.from(this.bancoRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get bancosByDropdown() {
        let BancoArreg = [new TipoDropDownValues()];
        let bancoOpt = new TipoDropDownValues();
        const cvts = Array.from(this.bancoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            bancoOpt.key = element.id;
            bancoOpt.text = element.nombre;
            bancoOpt.value = element.id;
            bancoOpt.content = element.nombre;
            BancoArreg.push(bancoOpt);
            bancoOpt = new TipoDropDownValues();

        });
        return BancoArreg;
    }

    getBancos = async () => {
        this.setLoadingInitial(true);
        const bancos = await agent.Bancos.list();
        try {
            bancos.forEach(banco => {
                this.setBanco(banco);
            })
            this.setLoadingInitial(false);
            return bancos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return bancos;
        }
    }

    loadBancos = async () => {
        this.setLoadingInitial(true);
        try {
            debugger
            const bancos = await agent.Bancos.list();
            bancos.forEach(banco => {
                setDate<Banco>(banco);
                this.setBanco(banco);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadBanco = async (id: number) => {
        let banco = this.getBanco(id);
        if (banco) {
            setDate<Banco>(banco);
            this.selectedBanco = banco;
            return banco;
        } else {
            this.loadingInitial = true;
            try {
                banco = await agent.Bancos.details(id);
                this.setBanco(banco);
                this.selectedBanco = banco;
                this.setLoadingInitial(false);
                return banco;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setBanco = (banco: Banco) => {
        this.bancoRegistry.set(banco.id, banco);
    }

    private getBanco = (id: number) => {
        return this.bancoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createBanco = async (banco: Banco) => {
        this.loading = true;
        try {
            await agent.Bancos.create(banco);
            runInAction(() => {
                this.selectedBanco = banco;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Banco creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateBanco = async (banco: Banco) => {
        this.loading = true;
        try {
            await agent.Bancos.update(banco);
            runInAction(() => {
                this.selectedBanco = banco;
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Banco editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteBanco = async (id: number) => {
        this.loading = true;
        try {
            await agent.Bancos.delete(id);
            runInAction(() => {
                this.bancoRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Banco eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar la banco");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
