import { roundTo } from "round-to";

export const formatDecimal = (val, decimals = 2) => {
    try {
        if (val) {
            if (val === 0) {
                return "0.00";
            }
            val = parseFloat(val);
            return val.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return "0.00";
    } catch (err) {
        return "";
    }
}

export const formatNumber = (number, decimals = 2) => {
    return number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const Redondear = (num, decimales = 2, down = false) => {
    if (!String(num).includes('.')) {
        num = parseFloat(String(num) + ".00")
    }
    if (!down) {
        return roundTo(num, decimales)
    }
    return roundTo.down(num, decimales)
}
export const SUM = (lista, key, formatear = true) => {
    if (!lista) return 0;
    if (formatear) {
        return formatDecimal(lista.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0));
    }
    let val = 0;
    for (let index = 0; index < lista.length; index++) {
        const element = lista[index];
        val += element[key];
    }
    return val;
}