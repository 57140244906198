import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Icon, Label, Segment } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { SerieFolioCompra, SerieFolioCompraFormValues } from "../../../../app/models/compra/SerieFolioCompra";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import FileWidgetDropzone from "../../../../app/common/form/FileWidgetDropzone";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { enterInputs, tabuladorButtons } from "../../../../app/common/funciones/tabuladorEnter";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";

interface idProps {
  id: number
}

export default observer(function SerieFolioCompraForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 13;
  const { serieFolioCompraStore, tipoDeCompraStore } = useStore();
  const { createSerieFolioCompra, updateSerieFolioCompra, loadClaveSerieFolioCompraByEmpresaSucursal, loadSerieFolioCompra, loadingInitial, loading, empresaSucursal } = serieFolioCompraStore;
  const { loadTiposDeCompras, tiposDeComprasByDropdown } = tipoDeCompraStore
  const [serieFolioCompra, setSerieFolioCompra] = useState(new SerieFolioCompraFormValues());
  const { sucursalStore, empresaStore, modalStore } = useStore();
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore;
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown } = sucursalStore;
  const { openModal, closeModal } = modalStore;
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }
  function onChangeSelectEmpresa(idSelectedEmpresa: number, setfieldAny: any,) {
    empresaSucursal.empresaId = idSelectedEmpresa;
    setfieldAny("sucursalId", 0);
    void loadSucursalByIdEmpresa(idSelectedEmpresa);
  }
  function OnChangeSelectSucursal(
    idSelectedSucursal: number,
    setfieldAny: any,
    index?: any
  ) {
    empresaSucursal.sucursalId = idSelectedSucursal;
    // void loadTableProductoByEmpresaSucursal(empresaSucursal);
    // setfieldAny(`controlListaDePrecio[${index}].productoId`, 0);
  }
  function onKeyUpInputClave(clave: string) {
    empresaSucursal.claveSerieFolioCompra = clave;
    if (empresaSucursal.empresaId && empresaSucursal.sucursalId && empresaSucursal.claveSerieFolioCompra) {
      loadClaveSerieFolioCompraByEmpresaSucursal(empresaSucursal).then((serieFolioCompra2) => {
        if (serieFolioCompra2) {
          setSerieFolioCompra(new SerieFolioCompraFormValues(serieFolioCompra2));
          void loadSucursalByIdEmpresa(serieFolioCompra2 ? serieFolioCompra2.empresaId : 0);
        } else {
          setSerieFolioCompra(new SerieFolioCompraFormValues({ id: 0, clave: clave, nombre: "", empresaId: empresaSucursal.empresaId, sucursalId: empresaSucursal.sucursalId, tipoCompraId: 0, ultimoFolio: 0, logotipoEmpresaFileId: 0, logotipoEmpresa: null, logotipoTicketFileId: 0, logotipoTicket: null, lemaEmpresa: "", fechaObservacionId: 0, fechaObservacion: new FechaObservacionValues() }));
        }

      })
    }
  }
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    tipoCompraId: Yup.number()
      .min(1, "Favor de seleccionar una tipo compra.")
      .required("Tipo compra es requerido."),
    clave: Yup.string().required("la clave serie folio de compra es requerida."),
    nombre: Yup.string().required("El nombre serie folio de compra es requerida."),
  });

  useEffect(() => {
    void loadEntityEmpresas();
    void loadTiposDeCompras();
    if (id) {
      void loadSerieFolioCompra(id).then((serieFolioCompras) => {
        setSerieFolioCompra(new SerieFolioCompraFormValues(serieFolioCompras));
        void loadSucursalByIdEmpresa(serieFolioCompras ? serieFolioCompras.empresaId : 0);
      });
    }
  }, []);

  function handleSubmit(serieFolioCompra: SerieFolioCompra) {
    serieFolioCompra.id > 0 ? updateSerieFolioCompra(serieFolioCompra) : createSerieFolioCompra(serieFolioCompra);
  }

  if (loadingInitial) return <LoadingComponent content='Loading Serie Folio Compra' />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={serieFolioCompra}>

        {({ values ,setFieldValue}) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Series y folios
              </Label>
              <div></div>
              <div className='col80 '>
                <MySelectInput
                  tabIndex={1}
                  placeholder={"Seleccionar"}
                  label="Empresa"
                  name="empresaId"
                  options={EmpresasByDropdown}
                  enterInput={enterInput}
                  onChange={onChangeSelectEmpresa}
                  autoFocus={true}
                />
              </div>

              <div className='col80 '>
                <MySelectInput
                  tabIndex={2}
                  placeholder={"Seleccionar"}
                  label="Sucursal"
                  name="sucursalId"
                  onChange={OnChangeSelectSucursal}
                  options={sucursalesByEmpresaByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div className='col20'>
                <MyTextInput placeholder="Clave" label="Clave" name="clave" tabIndex={3} enterInput={enterInput} maxLength={10} pattern="[A-Za-z0-9]+" onKeyDown={onKeyUpInputClave} />
              </div>
              <div className='col80 '>
                <MySelectInput
                  tabIndex={4}
                  placeholder={"Seleccionar"}
                  label="Tipo Compra"
                  name="tipoCompraId"
                  options={tiposDeComprasByDropdown}
                  enterInput={enterInput}
                />
              </div>

              <div className='col10'></div>

              <div className='colsp'></div>
              <div className='col60'>
                <MyTextInput placeholder="Nombre" label="Nombre" name="nombre" tabIndex={5} enterInput={enterInput} maxLength={100} />
              </div>
            </Segment>
            <Label as='a' color='red' ribbon>
              Generales
            </Label>
            <Segment clearing >
              <div >
                <MyTextInput placeholder="Ultimo folio" label="Ultimo folio:" name="ultimoFolio" className="uiAnchoCombo heighttext" tabIndex={6} enterInput={enterInput} pattern="[0-9]{0,16}" />
              </div>
              <div >
                <MyTextInput placeholder="Lema Empresa" label="Lema Empresa:" name="lemaEmpresa" className="uiAnchoCombo heighttext" tabIndex={7} enterInput={enterInput} />
              </div>
              <div className="col60Block">
                <FileWidgetDropzone
                  label="Logo de Empresa"
                  files={values.logotipoEmpresaFile}
                  className="uiAnchoCombo heightFileWidgetDropzone input"
                  acceptFileDrop={".png"}
                  name="logotipoEmpresa"
                  tabIndex={8}
                />
              </div>

              <div className="col60Block">
                <FileWidgetDropzone
                  label="Logo para ticket"
                  files={values.logotipoTicketFile}
                  className="uiAnchoCombo heightFileWidgetDropzone input"
                  acceptFileDrop={".png"}
                  name="logotipoTicket"
                  tabIndex={9}
                />
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={10}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(data) => { 
                          setFieldValue("fechaObservacion", data) 
                        }}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.SerieFolioCompra
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <BarraBotones
              toLink="/seriesFoliosCompras"
              loading={loading}
              tabIndex={11}
              //id={id}
              firstElement={firstElement}
              lastElement={lastElement}></BarraBotones>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})