import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import {
  Vehiculo,
  VehiculoFormValues
} from "../../../../app/models/gestionFlotilla/Vehiculo";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";

import MyDateInput from "../../../../app/common/form/MyDateInput";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import BarraBotones from "../../../Component/BarraBotones";
export default observer(function VehiculoForm() {
  const firstElement = 0;
  const lastElement = 19;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { vehiculoStore, sucursalStore, empresaStore,vehiculoTipoStore,departamentoStore,personalStore } = useStore();
  const { EmpresasByDropdown, loadEmpresa } = empresaStore;
  const { SucursalesByDropdown, loadSucursal } = sucursalStore;
  const { vehiculoTiposByDropdown, getVehiculoTipos } = vehiculoTipoStore;
  const { departamentosByDropdown, getDepartamentos } =departamentoStore;
  const { personalesByDropdown, getPersonales } =personalStore;
  const { deleteVehiculo,
    createVehiculo,
    updateVehiculo,
    loadVehiculo,
    loadingInitial,
    vehiculoById,
    loading,
  } = vehiculoStore;
  const { id } = useParams<{ id: string }>();

  const [vehiculo, setVehiculo] = useState(new VehiculoFormValues());


  const [empresas, setempresas] = useState([{ key: '1', value: 'sel', text: 'Seleccionar' }])
  const [sucursales, setsucursales] = useState([{ key: '1', value: 'Sel', text: 'Seleccionar' }])
  //const [sucursales, setsucursales] = useState([{ key: '1', value: 'Sel', text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  
  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de moneda es requerida."),
    nombre: Yup.string().required("El nombre de moneda es requerida.")
  });
  useEffect(() => { empresaStore.getEmpresas().then(emps => { }) }, [])
  useEffect(() => { sucursalStore.getSucursales().then(sucs => { }) }, [])
  useEffect(() => { vehiculoTipoStore.getVehiculoTipos().then(sucs => { }) }, [])
  useEffect(() => { departamentoStore.getDepartamentos().then(sucs => { }) }, [])


  useEffect(() => {
    if (id) {
      debugger
      void loadVehiculo(Number.parseInt(id)).then(vehiculo => {
        setVehiculo(new VehiculoFormValues(vehiculo));
        setFechaObservacion(
          new FechaObservacionValues(vehiculo?.fechaObservacion)
        );
      });
    } else {
      vehiculo.clave =
        vehiculoById.map((vehiculo) => vehiculo.clave).length > 0
          ? Math.max(...vehiculoById.map((vehiculo) => vehiculo.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(vehiculo: Vehiculo) {
    debugger
    if (vehiculo.empresa) {
      const empresaSelected = empresas.find(
        (a) => a.key === vehiculo.empresaId.toString()
      );
      if (empresaSelected) {
        vehiculo.empresa.nombre = empresaSelected.text;
        vehiculo.empresa.id = Number.parseInt(empresaSelected.key);
        vehiculo.empresa.clave = Number.parseInt(empresaSelected.value);
      }
    }
    vehiculo.empresa = undefined;
    vehiculo.sucursal = undefined;
    if (vehiculo.fechaObservacion) {
      vehiculo.fechaObservacion = fechaObservacion;
    }
    if (vehiculo.empresaId.toString() !== "0" && vehiculo.nombre !== null) {
      vehiculo.id > 0 ? updateVehiculo(vehiculo) : createVehiculo(vehiculo);
    } else {
      toast.info("Favor de llenar campos");
    }

  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }


  if (loadingInitial) return <LoadingComponent content='Loading vehiculo' />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={vehiculo}
      >
        {({ isValid, dirty }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Vehiculo
              </Label>
              <div></div>
              <div className='col80'>
                <div className='col80 '>
                  <MySelectInput
                    tabIndex={0}
                    placeholder={"Seleccionar"}
                    label="Empresa"
                    name="empresaId"
                    options={EmpresasByDropdown}
                    enterInput={enterInput}
                  />
                </div>

              </div>
              <div className='col10'>
                <MyCheckInput className="ui right floated " placeholder="Inactivo" name="inactivo " />
              </div>
              <div className="col80" >
                <MySelectInput
                  tabIndex={1}
                  placeholder={"Seleccionar"}
                  label="Sucursal"
                  name="sucursalId"
                  options={SucursalesByDropdown}
                  enterInput={enterInput}
                />

              </div>
              <div className='col10'></div>
              <div className='col10'>
                <MyTextInput placeholder="Clave" label="Clave:" name="clave" className="uiAnchoCombo heighttext " tabIndex={2} enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col60'>
                <MyTextInput placeholder="Nombre" label="Nombre:" maxLength={50} tipoDato={enumTipoDato.Numerico} name="nombre" className="uiAnchoCombo heighttext" tabIndex={3} enterInput={enterInput}/>
              </div>

              <div className='col15'>
                <MyTextInput placeholder="Odometro" label="Odometro:" name="odometro" className="uiAnchoCombo heighttext" tabIndex={4} enterInput={enterInput}/>
              </div>
              <div className='col20'>
              <MySelectInput
              tabIndex={3}
              placeholder="Seleccionar"
              label="Tipo"
              name="vehiculoTipoId"
              options={vehiculoTiposByDropdown}
              enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col20'>
              <MySelectInput
              tabIndex={3}
              placeholder="Seleccionar"
              label="Departamento"
              name="departamentoId"
              options={departamentosByDropdown}
              enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col20'>
              <MyTextInput placeholder="Horometro" label="Horometro:" name="horometro" className="uiAnchoCombo heighttext" tabIndex={4} enterInput={enterInput}/>
              </div>
              <div className='col30'></div>
              <div className='col20'>
              <MySelectInput
              tabIndex={3}
              placeholder="Seleccionar"
              label="Chofer"
              name="choferId"
              options={personalesByDropdown}
              enterInput={enterInput} />
              </div>
              <div className='colsp'></div>
              <div className='col20'>
              <MySelectInput
              tabIndex={3}
              placeholder="Seleccionar"
              label="Ayudante"
              name="ayudanteId"
              options={personalesByDropdown}
              enterInput={enterInput} />
              </div>
            </Segment>

            <Segment clearing >
              <div className='col20'>
                <MyTextInput placeholder="Marca" label="Marca:" name="marca" className="uiAnchoCombo heighttext" maxLength={100} tabIndex={5} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Año" name="anio" label="Añio:" className="uiAnchoCombo heighttext" maxLength={4} tabIndex={6} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Color" label="Color:" name="color" className="uiAnchoCombo heighttext" tabIndex={7} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Serie" label="Numero de Serie:" name="serie" className="uiAnchoCombo heighttext" tabIndex={8} enterInput={enterInput}/>
              </div>
           
              <div className='col20'>
                Venc. Placas.:
                <MyDateInput  name="renovarPlaca" tabIndex={9} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Placa" label="Placa:" name="placas" className="uiAnchoCombo heighttext" tabIndex={10} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Serie Bateria" label="Serie Bateria:" name="serieBateria" className="uiAnchoCombo heighttext" maxLength={5} tabIndex={11} enterInput={enterInput}/>
              </div>
            </Segment>
            <Label as='a' color='blue' ribbon>
              Seguro
            </Label>
            <Segment clearing>

              <div className='col20'>
                <MyTextInput placeholder="Compañia" label="Compañia:" name="companiaSeguro" className="uiAnchoCombo heighttext" tabIndex={12} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                No. poliza:
                <MyTextInput placeholder="Numero de poliza" name="numeroPoliza" className="uiAnchoCombo heighttext" tabIndex={13} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>

              <div className='col15'>
                Vencimiento:
                <MyDateInput name="venceSeguro" tabIndex={14} enterInput={enterInput}/>
                {/*<MyTextInput placeholder="Vencimiento Seguro" name="venceSeguro" />*/}
              </div>
            </Segment>
            <Label as='a' color='blue' ribbon>
              Mecanico
            </Label>
            <Segment clearing>

              <div className='col20'>
                <MyTextInput placeholder="Motor" label="Motor:" name="motor" className="uiAnchoCombo heighttext" tabIndex={15} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Trasmision" label="Trasmision:" name="trasmision" className="uiAnchoCombo heighttext" tabIndex={16} enterInput={enterInput}/>
              </div>
              <div className='colsp'></div>
              <div className='col20'>
                <MyTextInput placeholder="Tamaño de llantas" label="Tamaño de llantas:" name="tamanoLlantas" className="uiAnchoCombo heighttext" tabIndex={17} enterInput={enterInput}/>
              </div>
            </Segment>
            <Segment clearing >
              <div >
                <MyTextInput placeholder="Codigo contable" label="Codigo Contable:" name="codigoContable" className="uiAnchoCombo heighttext" tabIndex={18} enterInput={enterInput}/>
              </div>
            </Segment>




            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={19}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={setFechaObservacion}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Moneda
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={vehiculo.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
            <BarraBotones
              toLink="/vehiculos"
              loading={loading}
              tabIndex={20}
              id={id}
              firstElement={firstElement}
              lastElement={lastElement}
            ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})