import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeVenta } from "../../models/venta/CuentaDeVenta";
import { toast } from "react-toastify";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { setDate } from "../../common/util/util";

export default class CuentaDeVentaStore {
    cuentaDeVentaRegistry = new Map<number, CuentaDeVenta>();
    selectedCuentaDeVenta: CuentaDeVenta | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get cuentasDeVentasByDropdown() {
        let cuentasDeVentasArreg = [new TipoDropDownValues()];
        let cuentasDeVentasOpt = new TipoDropDownValues();
        const montipos = Array.from(this.cuentaDeVentaRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        montipos.forEach((element) => {
            cuentasDeVentasOpt.key = element.id;
            cuentasDeVentasOpt.text = element.nombre;
            cuentasDeVentasOpt.value = element.id;
            cuentasDeVentasOpt.content = element.nombre;
            cuentasDeVentasArreg.push(cuentasDeVentasOpt);
            cuentasDeVentasOpt = new TipoDropDownValues();
        });
        return cuentasDeVentasArreg;
    }

    get cuentaDeVentaById() {
        return Array.from(this.cuentaDeVentaRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    get cuentasDeVentasByDate() {
        return Array.from(this.cuentaDeVentaRegistry.values()).sort((a) => a.id);
    }

    fetchsCuentaDeVentas = async () => {
        try {
            const response = await agent.CuentasDeVentas.list();
            return response;
        } catch (error) {
            console.error("Error al buscar Cuentas de Ventas", error);
        }
    };

    loadCuentasDeVentas = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasDeVentas = await agent.CuentasDeVentas.list();
            cuentasDeVentas.forEach(cuentaDeVenta => {
                setDate<CuentaDeVenta>(cuentaDeVenta);
                this.setCuentaDeVenta(cuentaDeVenta);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasDeVentas = async () => {
        this.setLoadingInitial(true);
        const cuentasDeVentas = await agent.CuentasDeVentas.list();
        try {
            cuentasDeVentas.forEach(cuentaDeVenta => {
                setDate<CuentaDeVenta>(cuentaDeVenta);
                this.setCuentaDeVenta(cuentaDeVenta);
            })
            this.setLoadingInitial(false);
            return cuentasDeVentas;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasDeVentas;
        }
    }

    loadCuentaDeVenta = async (id: number) => {
        let cuentaDeVenta = this.getCuentaDeVenta(id);
        if (cuentaDeVenta) {
            setDate<CuentaDeVenta>(cuentaDeVenta);
            this.selectedCuentaDeVenta = cuentaDeVenta;
            return cuentaDeVenta;
        } else {
            this.loadingInitial = true;
            try {
                cuentaDeVenta = await agent.CuentasDeVentas.details(id);
                this.setCuentaDeVenta(cuentaDeVenta);
                this.selectedCuentaDeVenta = cuentaDeVenta;
                this.setLoadingInitial(false);
                return cuentaDeVenta;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeVenta = (cuentaDeVenta: CuentaDeVenta) => {
        this.cuentaDeVentaRegistry.set(cuentaDeVenta.id, cuentaDeVenta);
    }

    public getCuentaDeVenta = (id: number) => {
        return this.cuentaDeVentaRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeVenta = async (cuentaDeVenta: CuentaDeVenta) => {
        this.loading = true;
        try {
            await agent.CuentasDeVentas.create(cuentaDeVenta);

            runInAction(() => {
                this.selectedCuentaDeVenta = cuentaDeVenta;
                this.editMode = false;
                this.loading = false;
            })


            toast.success("Cuenta de Venta creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeVenta = async (cuentaDeVenta: CuentaDeVenta) => {
        this.loading = true;
        try {
            await agent.CuentasDeVentas.update(cuentaDeVenta);
            runInAction(() => {
                this.selectedCuentaDeVenta = cuentaDeVenta;
                this.editMode = false;
                this.loading = false;
            })

            toast.success("Cuenta de Venta Actualizada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeVenta = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeVentas.delete(id);
            runInAction(() => {
                this.cuentaDeVentaRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Cuenta de Venta eliminada con éxito");

        } catch (error) {
            toast.success("Problema al eliminar Cuenta de Venta");
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
