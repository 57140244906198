import { createContext, useContext } from "react";
import ListaDePrecioStore from "./ControlPrecio/listaDePrecioStore";
import ListaDePrecioTipoStore from "./ControlPrecio/listaDePrecioTipoStore";
import ControlListaDePrecioStore from "./ControlPrecio/controlListaDePrecioStore";
import EmpresaStore from "./Configuracion/empresaStore";
import SucursalStore from "./Configuracion/sucursalStore";
import UsuarioStore from "./usuarioStore";
import UsuarioTipoStore from "./Configuracion/usuarioTipoStore";
import PerfilStore from "./Configuracion/perfilStore";
import MonedaSATStore from "./monedaSATStore";
import MonedaStore from "./monedaStore";
import MonedaTipoStore from "./monedaTipoStore";
import ImpuestoStore from "./Configuracion/impuestoStore";
import ImpuestoTipoStore from "./Configuracion/impuestoTipoStore";
import PacStore from "./Configuracion/pacStore";
import PaisStore from "./Configuracion/paisStore";
import EstadoStore from "./Configuracion/estadoStore";

import ProductoStore from "./Inventario/productoStore";
import ProductoTipoStore from "./Inventario/productoTipoStore";

import CuentaDeVentaStore from "./Venta/cuentaDeVentaStore";
import CuentaDeVentaTipoStore from "./Venta/cuentaDeVentaTipoStore";
import TipoVentaStore from "./Venta/tipoVentaStore";
import TipoVentaTipoStore from "./Venta/tipoVentaTipoStore";
import SerieFolioStore from "./Venta/serieFolioStore";
import SerieFolioTipoStore from "./Venta/serieFolioTipoStore";

import ClienteStore from "./GestionCliente/clienteStore";
import ClienteTipoStore from "./GestionCliente/clienteTipoStore";
import CuentaDeClienteStore from "./GestionCliente/cuentaDeClienteStore";
import CuentaDeClienteTipoStore from "./GestionCliente/cuentaDeClienteTipoStore";
import ConceptoClienteStore from "./GestionCliente/conceptoClienteStore";
import ConceptoClienteTipoStore from "./GestionCliente/conceptoClienteTipoStore";
import CondicionPagoStore from "./GestionCliente/condicionPagoStore";
import FormaPagoStore from "./GestionCliente/formaPagoStore";

import MetodoPagoStore from "./SAT/metodoPagoStore";
import UsoCFDIStore from "./SAT/usoCFDIStore";
import UnidadMedidaStore from "./Inventario/unidadMedidaStore";
import CategoriaProductoStore from "./Inventario/categoriaProductoStore";

import AlmacenStore from "./Inventario/almacenStore";
import AlmacenTipoStore from "./Inventario/almacenTipoStore";
import ConceptoDeInventarioStore from "./Inventario/conceptoDeInventarioStore";
import ConceptoDeInventarioTipoStore from "./Inventario/conceptoDeInventarioTipoStore";

import ImpuestoComprobanteStore from "./Configuracion/impuestoComprobanteStore";

import ProveedorStore from "./GestionProveedor/proveedorStore";
import ProveedorTipoStore from "./GestionProveedor/proveedorTipoStore";
import ConceptoDeProveedorStore from "./GestionProveedor/conceptoProveedorStore";
import ConceptoDeProveedorTipoStore from "./GestionProveedor/conceptoProveedorTipoStore";
import CuentaDeProveedorStore from "./GestionProveedor/cuentaProveedorStore";
import CuentaDeProveedorTipoStore from "./GestionProveedor/cuentaProveedorTipoStore";

import CuentaDeCompraStore from "./Compra/cuentaDeCompraStore";
import CuentaDeCompraTipoStore from "./Compra/cuentaDeCompraTipoStore";
import TipoDeCompraStore from "./Compra/tipoDeCompraStore";
import TipoDeCompraTipoStore from "./Compra/tipoDeCompraTipoStore";
import SerieFolioCompraStore from "./Compra/serieFolioCompraStore";

import VehiculoStore from "./GestionFlotilla/vehiculoStore";
import PersonalStore from "./GestionFlotilla/personalStore";
import PersonalTipoStore from "./GestionFlotilla/personalTipoStore";
import ModalStore from "./modalStore";
import PoliticaDescuentoStore from "./Inventario/politicaDescuentoStore";
import ClasificacionStore from "./Inventario/clasificacionStore";
import PagoDeStore from "./PagoDeStore";
import VehiculoTipoStore from "./GestionFlotilla/vehiculoTipoStore";
import DepartamentoStore from "./GestionFlotilla/departamentoStore";
import GeneracionComprobanteStore from "./Venta/generacionComprobanteStore";
import c_RegimenFiscalStore from "./SAT/c_RegimenFiscalStore";
import TipoMovimientoStore from "./tipoMovimientoStore";
import MovimientoInventarioStore from "./Inventario/MovimientoInventarioStore";
import ReporteVentasStore from "./Venta/reporteVentasStore";
import MovimientoClienteStore from "./GestionCliente/movimientoClienteStore";
import MovimientoDeProveedorStore from "./GestionProveedor/MovimientoDeProveedorStore";
import CuentaFinanzaStore from "./Finanza/cuentaFinanzaStore";
import CuentaFinanzaTipoStore from "./Finanza/cuentaFinanzaTipoStore";
import ConceptoFinanzaStore from "./Finanza/conceptoFinanzaStore";
import ConceptoFinanzaTipoStore from "./Finanza/conceptoFinanzaTipoStore";
import BancoStore from "./Finanza/bancoStore";
import BancoSatStore from "./Finanza/bancoSatStore";
import MovimientoFinanzaStore from "./Finanza/movimientoFinanzaStore";
import GeneracionComprobanteCompraStore from "./Compra/generacionComprobanteCompraStore";
import ReporteComprasStore from "./Compra/reporteComprasStore";
import VerificadorGestionClienteStore from "./GestionCliente/verificadorGestionClienteStore";
import VerificadorGestionProveedorStore from "./GestionProveedor/verificadorGestionProveedorStore";
import VerificadorInventarioStore from "./Inventario/verificadorInventarioStore";
import c_MesStore from "./SAT/c_MesStore";

import c_PeriodicidadStore from "./SAT/c_PeriodicidadStore";
import tipoSATStore from "./SAT/tipoSATStore";
import CapturaPagosStore from "./GestionCliente/capturaPagosStore";
import EstadoCuentaStore from "./GestionCliente/estadoCuentaStore";

import ReporteConfiguracionesStore from "./Configuracion/reporteConfiguracionesStore";
import GeneralStore from "./Configuracion/generalStore";
import ReporteGestionProveedoresStore from "./GestionProveedor/reporteGestionProveedoresStore";
import CorteDeCajaStore from "./Venta/corteDeCajaStore";
import FacturaGlobalStore from "./Venta/facturaGlobalStore";
import SaldoClienteStore from "./GestionCliente/SaldoClienteStore";
import KardexStore from "./Inventario/reporteAlmacenesStore";
import EstadoCuentaAlmacenStore from "./Inventario/estadoCuentaAlmacenStore";
import ReporteAlmacenesStore from "./Inventario/reporteAlmacenesStore";
import VerificadorPagosStore from "./GestionCliente/verificadorPagosStore";
import ReporteGestionClientesStore from "./GestionCliente/reporteGestionClientesStore";
import InsertaObtenerInfoCatalogoStore from "./HerramientaExtra/InsertaObtenerInfoCatalogoStore";
import ReporteProductosVendidosStore from "./Venta/reporteProductosVendidosStore";
import c_MotivoCancelacionStore from "./SAT/c_MotivoCancelacionStore";
import c_TipoRelacionStore from "./SAT/c_TipoRelacionStore";
import tipoNotaCreditoStore from "./Venta/tipoNotaCreditoStore";
import TipoNotaCreditoStore from "./Venta/tipoNotaCreditoStore";
import CuentaBancariaStore from "./Finanza/cuentaBancariaStore";
import TraspasoEntreSucursalStore from "./Inventario/traspasoEntreSucursalStore";
import EnviarCorreoStore from "./Venta/enviarCorreoStore";
import TabsStore from "./General/TabsStore";
import ReporteProductosCompradosStore from "./Compra/reporteProductosCompradosStore";
import { CartStore } from "./PuntoVenta/cartStore";
import InventarioFisicoStore from "./Inventario/InventarioFisicoStore";
import ImpuestoProductoStore from "./Inventario/impuestoProductoStore";

interface Store {
  tipoMovimientoStore: TipoMovimientoStore;
  listaDePrecioStore: ListaDePrecioStore;
  listaDePrecioTipoStore: ListaDePrecioTipoStore
  controlListaDePrecioStore: ControlListaDePrecioStore
  empresaStore: EmpresaStore;
  sucursalStore: SucursalStore;
  monedaStore: MonedaStore;
  usuarioStore: UsuarioStore;
  usuarioTipoStore: UsuarioTipoStore;
  perfilStore: PerfilStore;
  monedaTipoStore: MonedaTipoStore;
  monedaSATStore: MonedaSATStore;
  impuestoStore: ImpuestoStore;
  impuestoTipoStore: ImpuestoTipoStore;
  generalStore: GeneralStore;
  pacStore: PacStore;
  paisStore: PaisStore;
  estadoStore: EstadoStore;
  cuentaDeVentaStore: CuentaDeVentaStore;
  cuentaDeVentaTipoStore: CuentaDeVentaTipoStore;
  tipoVentaStore: TipoVentaStore;
  tipoVentaTipoStore: TipoVentaTipoStore;
  serieFolioStore: SerieFolioStore;
  serieFolioTipoStore: SerieFolioTipoStore;
  generacionComprobanteStore: GeneracionComprobanteStore;
  facturaGlobalStore: FacturaGlobalStore;
  reporteProductosVendidosStore: ReporteProductosVendidosStore;
  corteDeCajaStore: CorteDeCajaStore;
  reporteConfiguracionesStore: ReporteConfiguracionesStore;
  tipoNotaCreditoStore: TipoNotaCreditoStore,
  enviarCorreoStore: EnviarCorreoStore,
  //ventas

  //gestio de cliente
  clienteStore: ClienteStore;
  clienteTipoStore: ClienteTipoStore;
  cuentaDeClienteStore: CuentaDeClienteStore;
  cuentaDeClienteTipoStore: CuentaDeClienteTipoStore;
  conceptoClienteStore: ConceptoClienteStore;
  conceptoClienteTipoStore: ConceptoClienteTipoStore;
  movimientoClienteStore: MovimientoClienteStore;
  verificadorGestionClienteStore: VerificadorGestionClienteStore;
  saldoClienteStore: SaldoClienteStore;
  capturaPagosStore: CapturaPagosStore;
  estadoCuentaStore: EstadoCuentaStore;
  verificadorPagosStore: VerificadorPagosStore;
  reporteGestionClientesStore: ReporteGestionClientesStore;

  tipoSATStore: tipoSATStore;
  c_mesStore: c_MesStore;
  c_TipoRelacionStore: c_TipoRelacionStore;
  c_periodicidadStore: c_PeriodicidadStore;
  condicionPagoStore: CondicionPagoStore;
  formaPagoStore: FormaPagoStore;
  metodoPagoStore: MetodoPagoStore;
  usoCFDIStore: UsoCFDIStore;
  c_RegimenFiscalStore: c_RegimenFiscalStore;
  c_MotivoCancelacionStore: c_MotivoCancelacionStore;
  unidadMedidaStore: UnidadMedidaStore;
  categoriaProductoStore: CategoriaProductoStore;
  impuestoComprobanteStore: ImpuestoComprobanteStore;


  productoStore: ProductoStore;
  impuestoProductoStore: ImpuestoProductoStore;
  cartStore: CartStore;
  productoTipoStore: ProductoTipoStore;
  almacenStore: AlmacenStore;
  almacenTipoStore: AlmacenTipoStore;
  estadoCuentaAlmacenStore: EstadoCuentaAlmacenStore;
  conceptoDeInventarioStore: ConceptoDeInventarioStore;
  conceptoDeInventarioTipoStore: ConceptoDeInventarioTipoStore;
  movimientoInventarioStore: MovimientoInventarioStore;
  inventarioFisicoStore:  InventarioFisicoStore;
  politicaDescuento: PoliticaDescuentoStore;
  clasificacionStore: ClasificacionStore;
  verificadorInventarioStore: VerificadorInventarioStore;
  reporteAlmacenesStore: ReporteAlmacenesStore;
  traspasoEntreSucursalStore: TraspasoEntreSucursalStore;

  proveedorStore: ProveedorStore;
  proveedorTipoStore: ProveedorTipoStore;
  cuentaDeProveedorStore: CuentaDeProveedorStore;
  cuentaDeProveedorTipoStore: CuentaDeProveedorTipoStore;
  conceptoDeProveedorStore: ConceptoDeProveedorStore;
  conceptoDeProveedorTipoStore: ConceptoDeProveedorTipoStore;
  movimientoDeProveedorStore: MovimientoDeProveedorStore;
  verificadorGestionProveedorStore: VerificadorGestionProveedorStore;
  reporteGestionProveedorStore: ReporteGestionProveedoresStore

  cuentaDeCompraStore: CuentaDeCompraStore;
  cuentaDeCompraTipoStore: CuentaDeCompraTipoStore;
  tipoDeCompraStore: TipoDeCompraStore;
  tipoDeCompraTipoStore: TipoDeCompraTipoStore;
  serieFolioCompraStore: SerieFolioCompraStore;
  reporteComprasStore: ReporteComprasStore;
  reporteProductosCompradosStore: ReporteProductosCompradosStore;

  vehiculoStore: VehiculoStore;
  vehiculoTipoStore: VehiculoTipoStore;
  personalStore: PersonalStore;
  personalTipoStore: PersonalTipoStore;
  departamentoStore: DepartamentoStore;
  reporteVentasStore: ReporteVentasStore;

  //Finanza
  cuentaFinanzaStore: CuentaFinanzaStore,
  cuentaFinanzaTipoStore: CuentaFinanzaTipoStore,
  conceptoFinanzaStore: ConceptoFinanzaStore,
  conceptoFinanzaTipoStore: ConceptoFinanzaTipoStore,
  bancoStore: BancoStore,
  bancoSatStore: BancoSatStore,
  cuentaBancariaStore: CuentaBancariaStore,
  movimientoFinanzaStore: MovimientoFinanzaStore;
  generacionComprobanteCompraStore: GeneracionComprobanteCompraStore;
  //bancoTipoStore: BancoTipoStore,

  //Herramientas extra
  insertaObtenerInfoCatalogoStore: InsertaObtenerInfoCatalogoStore;
  //modal
  modalStore: ModalStore;

  pagoDeStore: PagoDeStore
  //Tab
  tabsStore: TabsStore;
}

export const store: Store = {
  //General
  tipoMovimientoStore: new TipoMovimientoStore(),
  //Configuracion
  listaDePrecioStore: new ListaDePrecioStore(),
  listaDePrecioTipoStore: new ListaDePrecioTipoStore(),
  controlListaDePrecioStore: new ControlListaDePrecioStore(),
  empresaStore: new EmpresaStore(),
  sucursalStore: new SucursalStore(),
  usuarioStore: new UsuarioStore(),
  usuarioTipoStore: new UsuarioTipoStore(),
  perfilStore: new PerfilStore(),
  monedaStore: new MonedaStore(),
  monedaTipoStore: new MonedaTipoStore(),
  monedaSATStore: new MonedaSATStore(),
  impuestoStore: new ImpuestoStore(),
  impuestoTipoStore: new ImpuestoTipoStore(),
  generalStore: new GeneralStore(),
  pacStore: new PacStore(),
  paisStore: new PaisStore(),
  estadoStore: new EstadoStore(),
  reporteConfiguracionesStore: new ReporteConfiguracionesStore(),
  //Venta
  tipoSATStore: new tipoSATStore(),
  cuentaDeVentaStore: new CuentaDeVentaStore(),
  cuentaDeVentaTipoStore: new CuentaDeVentaTipoStore(),
  tipoVentaStore: new TipoVentaStore(),
  tipoVentaTipoStore: new TipoVentaTipoStore(),
  serieFolioStore: new SerieFolioStore(),
  serieFolioTipoStore: new SerieFolioTipoStore(),
  generacionComprobanteStore: new GeneracionComprobanteStore(),
  corteDeCajaStore: new CorteDeCajaStore(),
  reporteVentasStore: new ReporteVentasStore(),
  facturaGlobalStore: new FacturaGlobalStore(),
  reporteProductosVendidosStore: new ReporteProductosVendidosStore(),
  tipoNotaCreditoStore: new TipoNotaCreditoStore(),
  enviarCorreoStore: new EnviarCorreoStore(),
  //Gestion de cliente
  clienteStore: new ClienteStore(),
  clienteTipoStore: new ClienteTipoStore(),
  cuentaDeClienteStore: new CuentaDeClienteStore(),
  cuentaDeClienteTipoStore: new CuentaDeClienteTipoStore(),
  conceptoClienteStore: new ConceptoClienteStore(),
  conceptoClienteTipoStore: new ConceptoClienteTipoStore(),

  condicionPagoStore: new CondicionPagoStore(),
  formaPagoStore: new FormaPagoStore(),
  verificadorGestionClienteStore: new VerificadorGestionClienteStore(),
  saldoClienteStore: new SaldoClienteStore(),
  capturaPagosStore: new CapturaPagosStore(),
  verificadorPagosStore: new VerificadorPagosStore(),
  reporteGestionClientesStore: new ReporteGestionClientesStore(),
  estadoCuentaStore: new EstadoCuentaStore(),

  //Inventario
  productoStore: new ProductoStore(),
  cartStore: new CartStore(),
  almacenStore: new AlmacenStore(),
  almacenTipoStore: new AlmacenTipoStore(),
  impuestoProductoStore: new ImpuestoProductoStore(),
  estadoCuentaAlmacenStore: new EstadoCuentaAlmacenStore(),
  conceptoDeInventarioStore: new ConceptoDeInventarioStore(),
  conceptoDeInventarioTipoStore: new ConceptoDeInventarioTipoStore(),
  politicaDescuento: new PoliticaDescuentoStore(),
  clasificacionStore: new ClasificacionStore(),
  movimientoInventarioStore: new MovimientoInventarioStore(),
  inventarioFisicoStore: new InventarioFisicoStore(),
  verificadorInventarioStore: new VerificadorInventarioStore(),
  reporteAlmacenesStore: new ReporteAlmacenesStore(),
  traspasoEntreSucursalStore: new TraspasoEntreSucursalStore(),

  //kardexStore:new KardexStore(),
  //Gestion de Proveedor
  proveedorStore: new ProveedorStore(),
  proveedorTipoStore: new ProveedorTipoStore(),
  cuentaDeProveedorStore: new CuentaDeProveedorStore(),
  cuentaDeProveedorTipoStore: new CuentaDeProveedorTipoStore(),
  conceptoDeProveedorStore: new ConceptoDeProveedorStore(),
  conceptoDeProveedorTipoStore: new ConceptoDeProveedorTipoStore(),
  movimientoClienteStore: new MovimientoClienteStore(),
  movimientoDeProveedorStore: new MovimientoDeProveedorStore(),
  verificadorGestionProveedorStore: new VerificadorGestionProveedorStore(),
  reporteGestionProveedorStore: new ReporteGestionProveedoresStore(),
  //Compra
  cuentaDeCompraStore: new CuentaDeCompraStore(),
  cuentaDeCompraTipoStore: new CuentaDeCompraTipoStore(),
  tipoDeCompraStore: new TipoDeCompraStore(),
  tipoDeCompraTipoStore: new TipoDeCompraTipoStore(),
  serieFolioCompraStore: new SerieFolioCompraStore(),
  generacionComprobanteCompraStore: new GeneracionComprobanteCompraStore(),
  reporteComprasStore: new ReporteComprasStore(),
  reporteProductosCompradosStore: new ReporteProductosCompradosStore(),

  //SAT
  c_mesStore: new c_MesStore(),
  c_TipoRelacionStore: new c_TipoRelacionStore(),
  c_periodicidadStore: new c_PeriodicidadStore(),
  metodoPagoStore: new MetodoPagoStore(),
  usoCFDIStore: new UsoCFDIStore(),
  c_RegimenFiscalStore: new c_RegimenFiscalStore(),
  c_MotivoCancelacionStore: new c_MotivoCancelacionStore(),
  productoTipoStore: new ProductoTipoStore(),
  unidadMedidaStore: new UnidadMedidaStore(),
  categoriaProductoStore: new CategoriaProductoStore(),
  impuestoComprobanteStore: new ImpuestoComprobanteStore(),

  //Control Flotilla
  vehiculoStore: new VehiculoStore(),
  vehiculoTipoStore: new VehiculoTipoStore(),
  personalStore: new PersonalStore(),
  personalTipoStore: new PersonalTipoStore(),
  departamentoStore: new DepartamentoStore(),

  //Finanza
  cuentaFinanzaStore: new CuentaFinanzaStore(),
  cuentaFinanzaTipoStore: new CuentaFinanzaTipoStore(),
  conceptoFinanzaStore: new ConceptoFinanzaStore(),
  conceptoFinanzaTipoStore: new ConceptoFinanzaTipoStore(),
  bancoStore: new BancoStore(),
  cuentaBancariaStore: new CuentaBancariaStore(),
  bancoSatStore: new BancoSatStore(),
  movimientoFinanzaStore: new MovimientoFinanzaStore(),

  //Herramientas extra
  insertaObtenerInfoCatalogoStore: new InsertaObtenerInfoCatalogoStore(),
  //modal
  modalStore: new ModalStore(),


  pagoDeStore: new PagoDeStore(),
  //tab
  tabsStore: new TabsStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
