import { observer } from 'mobx-react-lite';
import { Grid, Header, Button } from 'semantic-ui-react';
import ImpuestoList from './ImpuestoList';
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import ReporteImpuestoForm from '../form/ReporteImpuestoForm';
import ImpuestoForm from '../form/ImpuestoForm';

export default observer(function ImpuestoDashboard() {
  const { tabsStore } = useStore();
  const { impuestoStore } = useStore();
  useEffect(() => {
    impuestoStore.loadImpuestos();
  }, [impuestoStore]);

  if (impuestoStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' content='Impuestos' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Impuesto', <ImpuestoForm id={0} />)}
              content='Crear Impuesto' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Impuesto', <ReporteImpuestoForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => impuestoStore.loadImpuestos()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <ImpuestoList />
      </Grid.Column>
    </Grid>
  )
})