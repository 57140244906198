import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Segment, Header } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import {
  enterInputs,
} from "../../../../app/common/funciones/tabuladorEnter";
import TraspasoEntresSucursalHeader from "./TraspasoEntresSucursalHeader";
import TraspasoEntresSucursalBody from "./TraspasoEntresSucursalBody";
import TraspasoEntresSucursalFooter from "./TraspasoEntresSucursalFooter";
import { useEffect, useState } from 'react';
import { TraspasoEntreSucursalFormValues } from '../../../../app/models/inventario/TraspasoEntreSucursal';
const validationSchema = Yup.object({
  sucursalId: Yup.number()
    .min(1, "Favor de seleccionar una Sucursal.")
    .required("La Sucursal es requerido."),
  sucursalDestinoId: Yup.number()
    .min(1, "Favor de seleccionar una Sucursal.")
    .required("La Sucursal es requerido."),
  serieFolioDeVentaId: Yup.number()
    .min(1, "Favor de seleccionar la serie .")
    .required("La serie de venta es requerido."),
  folio: Yup.number().required("El folio es requerido."),
  TraspasoEntresSucursalDetalles: Yup.array().of(
    Yup.object().shape({
      productoId: Yup.number()
        .typeError("El valor debe ser un numero.")
        .min(1, "Favor de seleccionar producto.")
        .required("Producto es requerido."),
      cantidad: Yup.number()
        .typeError("El valor debe ser un numero.")
        .moreThan(0, "Favor de ingresar cantidad mayor a 0.")
        .required("Cantidad es requerida."),
    })
  ),
});

// const initialValues = {
//   empresaId: 0,
//   sucursalId: 0,
//   serieFolioDeVentaId: 0,
//   folio: 0,
//   sucursalDestinoId: 0,
//   fechaEmision: new Date(),
//   fechaCancelacion: undefined,
//   TraspasoEntresSucursalDetalles: [],
//   totalCosto:0,
//   tipoVentaId: 0,
//     tipoVenta: undefined,
// };
const TraspasoEntreSucursalForm: React.FC = observer(() => {
  const [traspaso, setTraspaso] = useState(new TraspasoEntreSucursalFormValues());
  const { traspasoEntreSucursalStore } = useStore();
  const { lastElementTable, loadingInitial, createTraspasoEntreSucursales } = traspasoEntreSucursalStore
  if (loadingInitial) return <LoadingComponent />
  const handleSubmit = (traspasoEntreSucursal: any) => {
    const traspasoEntreSucursalSinProducto = { ...traspasoEntreSucursal };
    traspasoEntreSucursalSinProducto.TraspasoEntresSucursalDetalles = traspasoEntreSucursalSinProducto.TraspasoEntresSucursalDetalles.map((traspaso: any) => ({
      ...traspaso,
      producto: undefined,
    }));
    createTraspasoEntreSucursales(traspasoEntreSucursalSinProducto);
  }
  const firstElement = 0;
  const tabuladorEnterDom = (tabIndex: number) => {
    enterInputs(tabIndex, firstElement, lastElementTable + 4);
  }
  useEffect(() => {
    return () => {
      traspasoEntreSucursalStore.setLastElementTable(9)
    };
  }, []);
  return (
    <Segment clearing>
      <Formik
        initialValues={traspaso}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ handleSubmit }) => (
          <Form className='ui form' onSubmit={handleSubmit}>
            <Header as='h2' icon='id badge outline' content='Traspasos Entre Sucursales' color='blue' />
            <TraspasoEntresSucursalHeader tabuladorEnterDom={tabuladorEnterDom} />
            <TraspasoEntresSucursalBody tabuladorEnterDom={tabuladorEnterDom} />
            <TraspasoEntresSucursalFooter />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

export default TraspasoEntreSucursalForm;