import React, { useState } from 'react';
import { Tab, Form, Button, Dropdown, Segment, Header } from 'semantic-ui-react';
import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import MySelectInput from '../../../../app/common/form/MySelectInput';
import * as Yup from 'yup';
import FileWidgetDropzone from '../../../../app/common/form/FileWidgetDropzone';
import { InsertaInfoCatalogo, InsertaInfoCatalogoFormValues } from '../../../../app/models/HerramientaExtra/InsertaObtenerInfoCatalogo';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
// Listado de opciones para el campo de Catálogo
const options = [
  { key: 'seleccionar', value: 0, text: 'Seleccionar' },
  { key: 'articulos', value: 1, text: 'Artículos' },
  { key: 'clientes', value: 2, text: 'Clientes' },
  { key: 'proveedores', value: 3, text: 'Proveedores' },
  { key: 'movimientos', value: 4, text: 'Movimientos' },
  { key: 'inventario', value: 5, text: 'Inventario' },
  { key: 'cuentas', value: 6, text: 'Cuentas' },
];


const validationSchema = Yup.object({
  catalogoInsertaId: Yup.number()
    .min(1, "Favor de seleccionar una Catalogo.")
    .required("Catalogo es requerido."),
  archivoInserta: Yup.mixed().required('Archivo es requerido'),
});
// Función para simular la exportación de datos al backend
const handleExport = (values: any, actions: FormikHelpers<any>) => {
  // Aquí puedes realizar la lógica de envío de datos al backend
  actions.setSubmitting(false); // Marcar la acción como completada
};
const InsertaObtenerInfoForm: React.FC = observer(() => {
  const { insertaObtenerInfoCatalogoStore } = useStore();
  const { createInfoCatalogo } = insertaObtenerInfoCatalogoStore
  const [inserta, setInserta] = useState(new InsertaInfoCatalogoFormValues());
  const [activeTab, setActiveTab] = useState(0);
  const firstElement = 0;
  const lastElement = 3;
  const tabuladorButton = (
    event: any,
    firstElement?: number,
    lastElement?: number
  ) => {
    if (firstElement !== undefined && lastElement !== undefined)
      tabuladorButtons(event, firstElement, lastElement);
  };

  const enterInput = (tabIndex: number) => {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  // Función para simular la importación de datos al backend
  const handleImport = (values: InsertaInfoCatalogo, actions: FormikHelpers<any>) => {
    // Aquí puedes realizar la lógica de envío de datos al backend
    if (values?.catalogoInsertaId > 0 && values?.archivoInserta !== null) {
      void createInfoCatalogo(values);
    }
    // console.log('Valores a importar:', values);
    // actions.setSubmitting(false); // Marcar la acción como completada
  };
  const panes = [
    {
      menuItem: 'Insertar',
      render: () => (
        <Tab.Pane>
          <Formik
            initialValues={inserta}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => handleImport(values, actions)}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form className='ui form' onSubmit={handleSubmit}>
                {/* <label>Catálogo</label> */}
                <MySelectInput
                  autoFocus={true}
                  tabIndex={1}
                  placeholder={"Seleccionar"}
                  label="Catalogo"
                  name="catalogoInsertaId"
                  options={options}
                  enterInput={enterInput}
                //   onChange={onChangeSelectEmpresa}
                />
                {/* <Field placeholder={"Seleccionar"} label="catalogo" name="catalogo" as={MySelectInput} options={options} /> */}
                <div className="col60Block">
                  <FileWidgetDropzone
                    label="Ruta Archivo:"
                    files={values.archivoInsertaInfo}
                    className="uiAnchoCombo heightFileWidgetDropzone input"
                    acceptFileDrop={".csv"}
                    name="archivoInserta"
                    tabIndex={2}
                  />
                </div>
                <Segment>  <Button tabIndex={3} type="submit" onKeyDown={(e: any) =>
                  tabuladorButton(e, firstElement, lastElement)
                } primary>
                  Importar
                </Button></Segment>

              </Form>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Exportar',
      render: () => (
        <Tab.Pane>
          <Formik
            initialValues={{ catalogoExport: '', rutaExport: '' }}
            onSubmit={(values, actions) => handleExport(values, actions)}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>Catálogo</label>
                  <Dropdown
                    selection
                    name="catalogoExport"
                    options={options}
                    onChange={(e, { value }) => handleSubmit()}
                  />
                </Form.Field>
                <Form.Field>
                  {/* <label>Ruta del archivo</label> */}
                  <label>Ruta del archivo</label>
                  <input
                    type="text"
                    name="rutaExport"
                    placeholder="Ingrese la ruta del archivo"
                  // Permite que el usuario escriba manualmente la ruta del archivo
                  />
                </Form.Field>
                <Button type="submit" primary>
                  Exportar
                </Button>
              </Form>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Segment clearing>
      <Header as='h2' icon='id badge outline' content='Inserta y Obtener Información de Catálogos' color='blue' />
      <Tab
        panes={panes}
        activeIndex={activeTab}
        onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex as number)}
      />
    </Segment>

  );
});

export default InsertaObtenerInfoForm;
