import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { PaginationSpecParams } from '../../models/Especificaciones/PaginationSpecParams';
import { toast } from 'react-toastify';
export default class EnviarCorreoStore {
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }
    loadCorreosByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
        try {
            return await agent.EnviarCorreos.detailsByParams(filtros, options);
        } catch (error) {
            console.error("error", error);
        }
    }
    createEnviarCorreo = async (enviarCorreos: any) => {
        this.loading = true;
        try {
            await agent.EnviarCorreos.create(enviarCorreos);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Se envio correo con éxito");
        } catch (error: any) {
            // El error ya fue manejado y mostrado por el interceptor
            if (error) {
                console.error('Error:', error);
            }
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}