import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { PaginationSpecParams } from '../../models/Especificaciones/PaginationSpecParams';

export default class ReporteProductosCompradosStore {
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }
    imprimirReporteProductosComprados = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            const namePdf = await agent.ReporteProductosComprados.imprimirReporteProductosComprados(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    const blob = new Blob([namePdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                }
                this.loading = false;
            });
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}