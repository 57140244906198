import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { tipoSAT } from "../../models/sat/tipoSAT";

export default class tipoSATStore {
    tipoSATRegistry = new Map<number, tipoSAT>();
    selectedTipoSAT: tipoSAT | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tiopsSATsByDropdown() {
        let tiposSATsArreg = [new TipoDropDownValues()];
        let tiposSATsOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoSATRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tiposSATsOpt.key = element.id;
            tiposSATsOpt.text = element.clave;
            tiposSATsOpt.value = element.id;
            tiposSATsOpt.content = element.clave + " " + element.nombre;
            tiposSATsArreg.push(tiposSATsOpt);
            tiposSATsOpt = new TipoDropDownValues();

        });
        return tiposSATsArreg;
    }

    get tiposSATsByDate() {
        return Array.from(this.tipoSATRegistry.values()).sort((a) => a.id);
    }


    loadTiposSATs = async () => {
        this.setLoadingInitial(true);
        try {
            const tiposSATs = await agent.TiposSATs.list();
            tiposSATs.forEach(tipoSAT => {
                this.setTipoSAT(tipoSAT);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getTiposSATs = async () => {
        this.setLoadingInitial(true);
        debugger
        const TiposSATs = await agent.TiposSATs.list();
        try {
            TiposSATs.forEach(tipoSAT => {
                this.setTipoSAT(tipoSAT);
            })
            this.setLoadingInitial(false);
            return TiposSATs;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return TiposSATs;
        }
    }







    loadTipoSAT = async (id: number) => {
        let tipoSAT = this.getTipoSAT(id);
        if (tipoSAT) {
            this.selectedTipoSAT = tipoSAT;
            return tipoSAT;
        } else {
            this.loadingInitial = true;
            try {
                tipoSAT = await agent.TiposSATs.details(id);
                this.setTipoSAT(tipoSAT);
                this.selectedTipoSAT = tipoSAT;
                this.setLoadingInitial(false);
                return tipoSAT;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setTipoSAT = (TipoSAT: tipoSAT) => {
        this.tipoSATRegistry.set(TipoSAT.id, TipoSAT);
    }

    private getTipoSAT = (id: number) => {
        return this.tipoSATRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoSAT = async (TipoSAT: tipoSAT) => {
        this.loading = true;
        try {
            await agent.TiposSATs.create(TipoSAT);
            runInAction(() => {
                this.tipoSATRegistry.set(TipoSAT.id, TipoSAT);
                this.selectedTipoSAT = TipoSAT;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }


    deleteTipoSAT = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposSATs.delete(id);
            runInAction(() => {
                this.tipoSATRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
