import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Table, Segment, Grid, Header, GridRow, GridColumn, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import { GeneracionComprobanteCompra, GeneracionComprobanteCompraFormValues } from "../../../../app/models/compra/GeneracionComprobanteCompra";
import { GeneracionComprobanteCompraDetalleFormValues } from '../../../../app/models/compra/GeneracionComprobanteCompraDetalle';
import { enumHeaderModals } from "../../../modals/MensajeModals";
import DeleteModals from "../../../modals/DeleteModals";
import PagoComprobantes from "../../../Venta/GeneracionComprobantes/form/PagoComprobantes";
import { GeneracionComprobantePagoFormValues } from "../../../../app/models/venta/GeneracionComprobantePagos";
import { urlApi } from "../../../../app/api/agent";
import DescripcionAdicional from "../../../Venta/GeneracionComprobantes/form/DescripcionAdicional";
import { ImpuestoFormValues } from "../../../../app/models/configuracion/Impuesto";
import CopiarComprobanteCompra from "./CopiarComprobanteCompra";
import { ImpuestoProductoFormValues } from "../../../../app/models/inventario/ImpuestoProducto";
export default observer(function GeneracionComprobanteDashboard() {
  const impuesto = 16;
  const firstElement = 0;
  const [target, setTarget] = useState("");
  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { usuarioStore, empresaStore, sucursalStore, tipoDeCompraStore, serieFolioCompraStore, proveedorStore,
    generacionComprobanteCompraStore, cuentaDeCompraStore, monedaStore, productoStore, metodoPagoStore, formaPagoStore} = useStore();
  const { empresaSucursal } = proveedorStore
  const { loadProductoByEmpresaSucursal, productosByEmpresaSucursalDropdownByClave, productoByEmpresaSucursalRegistry, } = productoStore
  const { loadEntityEmpresas, EmpresasByDropdownByClave } = empresaStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const [monedaExtranjera, setMonedaExtranjera] = useState(false);
  const { tiposDeComprasByDropdownByClave } = tipoDeCompraStore
  const { loadSerieByIdTipoCompra, SeriesFoliosByEmpresaByDropdownByClave } = serieFolioCompraStore
  const { ProveedoresByDropdown } = proveedorStore
  const [pagos, setPagos] = useState(new GeneracionComprobantePagoFormValues());
  const [impuestoG, setImpuesto] = useState(new ImpuestoProductoFormValues());
  const { cuentasDeComprasByDropdown } = cuentaDeCompraStore
  const { MonedasByDropdown } = monedaStore
  const { usuariosByDropdown } = usuarioStore;
  const { obtenerSiguienteFolioCompra,
    loadingInitial,
    createGeneracionComprobanteCompra,
    updateGeneracionComprobanteCompra,
    loading,
    deleteGeneracionComprobanteCompra,
    cargarComprobante,
    imprimirComprobanteCompra
  } = generacionComprobanteCompraStore
  const { id } = useParams<{ id: string }>();
  const [generacionComprobanteCompra, setGeneracionComprobanteCompra] = useState(new GeneracionComprobanteCompraFormValues());
  const [imprimiendo, setImprimiendo] = useState(false);

  useEffect(() => {
    empresaStore.getEmpresas();
    void loadEntityEmpresas();
    metodoPagoStore.getMetodosPagos();
    formaPagoStore.getFormasPagos();
    sucursalStore.getSucursales();
    tipoDeCompraStore.getTiposDeCompras();
    serieFolioCompraStore.getSeriesFoliosCompras();
    proveedorStore.getProveedores();
    cuentaDeCompraStore.getCuentasCompras();
    monedaStore.getMonedas();
    usuarioStore.getUsuarios();
  }, []);
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("La Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),

    tipoCompraId: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de Compra es requerido."),

    serieFolioDeCompraId: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de Compra es requerido."),

    folio: Yup.number().required("El folio es requerido."),


    proveedorId: Yup.number()
      .min(1, "Favor de seleccionar el Proveedor.")
      .required("El Proveedor es requerido."),
    detalles: Yup.array().of(
      Yup.object().shape({
        productoId: Yup.number()
          .typeError("El valor debe ser un numero.")
          .min(1, "Favor de seleccionar producto.")
          .required("Producto es requerido."),
        cantidad: Yup.number()
          .typeError("El valor debe ser un numero.")
          .moreThan(0, "Favor de ingresar cantidad mayor a 0.")
          .required("Cantidad es requerida."),
        precio: Yup.number()
          .typeError("El valor debe ser un numero.")
          .moreThan(0, "Favor de ingresar precio mayor a 0.")
          .required("Precio es requerida.")
      })
    ),

  });
  const abrirPagos = (boton: string = "Pagos", generacionComprobante?: any) => {
    openModal(
      <PagoComprobantes
        pago={pagos}
        setPagos={(setPagos)}
        closeModals={() => {
          closeModal();
        }
        }
        onCopy={(data) => {
          if (data) {
            if (boton === "Guardar") {
              generacionComprobante.pago = data;
              Guardar(generacionComprobante);
            }
          }
        }}
        tabIndex={2228}
      />,
      'Pagos',
      'tiny'
    )
  }
  function handleSubmit(generacionComprobanteCompra: GeneracionComprobanteCompra) {
    const tipoCompra = tipoDeCompraStore.getTiposCompras(generacionComprobanteCompra.tipoCompraId);
    if (tipoCompra && tipoCompra.tipoDeCompraTipo?.clave === 15) {
      abrirPagos("Guardar", generacionComprobanteCompra)
    } else {
      generacionComprobanteCompra.pago = pagos;
      Guardar(generacionComprobanteCompra);
    }
  }
  const Guardar = (generacionComprobanteCompra: GeneracionComprobanteCompra) => {
    generacionComprobanteCompra.id > 0 ? updateGeneracionComprobanteCompra(generacionComprobanteCompra) : createGeneracionComprobanteCompra(generacionComprobanteCompra);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }
  function chageEnter(tabIndex: number) {
    obtenerSiguienteFolioCompra(empresaSucursal);
  }
  const [errorButon, setErrorButon] = useState("");
  const abrirCopiarComprobanteCompra = (values: GeneracionComprobanteCompra, setFieldValue: any) => {
    openModal(
      <CopiarComprobanteCompra
        closeModals={closeModals}
        tabIndex={2228}
        empresaId={values.empresaId}
        onCopy={(data) => {
          if (data) {
            setFieldValue("proveedorId", data.proveedorId);
            if (data.detalles?.length! > 0)
              setFieldValue("detalles", data.detalles); // Actualiza los valores del formulario con los datos copiados
          }
        }}
      />,
      'Copiar Comprobantes de Compras',
      'small'
    )
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElementTable + 3);
  };
  function closeModals() {
    closeModal();
  }

  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };

  function idSelectMoneda(idSelectedMoneda: number) {
    const moneda = monedaStore.getMoneda(idSelectedMoneda);
    setMonedaExtranjera(moneda?.monedaTipoId === 1);
  }

  function onChangeSelectEmpresa(idSelected: number, setFieldValue?: any, index?: any, values?: GeneracionComprobanteCompra) {
    empresaSucursal.empresaId = idSelected;
    empresaSucursal.sucursalId = 0;
    void loadSucursalByIdEmpresa(empresaSucursal.empresaId);
    setFieldValue("detalles", [new GeneracionComprobanteCompraDetalleFormValues()])
  }

  function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
    void loadProductoByEmpresaSucursal(empresaSucursal);
  }

  function idSelectTipo(idSelectedTipoCompra: number) {
    empresaSucursal.tipoCompraId = idSelectedTipoCompra;
    void loadSerieByIdTipoCompra(empresaSucursal);

  }


  function idSelectSerie(idSelectedSerie: number, setFieldValue: any) {
    empresaSucursal.serieId = idSelectedSerie;
    obtenerSiguienteFolioCompra(empresaSucursal).then((clave) => {
      setFieldValue("folio", clave);
      generacionComprobanteCompra.id = 0;
    });

  }
  function onKeyUpFolio(folio: any, setFieldValue: any, event: any, arrayHelpers: any) {
    empresaSucursal.folio = parseInt(folio);
    if (event.key === "Enter" || event.key === "Tab") {
      cargarComprobante(empresaSucursal).then((result) => {
        setGeneracionComprobanteCompra(new GeneracionComprobanteCompraFormValues(result));
        if (result?.id == 0) {
        }
        if (result !== null) {
          if (result?.detalles !== null) {
            setFieldValue("detalles", result?.detalles);
          }
        }
      })
    }
  }

  function OnChangeSelectProducto(
    idSelectedProducto: number,
    setfieldAny: any,
    index?: any,
    values?: GeneracionComprobanteCompra
  ) {
    if (idSelectedProducto === 0) {
      setfieldAny(`detalles[${index}].producto.nombre`, "");
      setfieldAny(`detalles[${index}].precio`, 0);
    }
    else {
      if (productoByEmpresaSucursalRegistry.values()) {
        productoByEmpresaSucursalRegistry.forEach((producto) => {
          if (idSelectedProducto)
            if (idSelectedProducto === producto.id) {
              setfieldAny(`detalles[${index}].producto.nombre`, producto.nombre);
              setfieldAny(`detalles[${index}].precio`, producto.controlListaDePrecio[0]?.precio ? producto.controlListaDePrecio[0]?.precio : 0);
              setfieldAny(`detalles[${index}].unidadMedida`, producto.unidadMedida1);
              setImpuesto(producto.impuesto);
              if (producto.usaDescripcionAdicional) {
                openModal(
                  <DescripcionAdicional
                    closeModals={closeModals}
                    tabIndex={2228}
                    onDescripcion={(data) => {
                      if (data) {
                        setfieldAny(`detalles[${index}].producto.nombre`, producto.nombre + " " + data);
                      }
                    }}
                  />,
                  'Descripcion Adicional',
                  'mini'
                )
              }
            }
        });
      }
      return true;
    }
  }

  const calcularDataGrid = (values: GeneracionComprobanteCompra) => {
    let subTotalPProd = 0, descValor = 0, Iva = 0, TotalPProd = 0;
    values?.detalles?.forEach((detalle) => {
      let descValor2 = detalle.cantidad * detalle.precio * detalle.descuento / 100;
      detalle.subtotal = detalle.cantidad * detalle.precio - descValor2;
      subTotalPProd += detalle.subtotal;
      descValor += descValor2;
      Iva = subTotalPProd * (impuesto / 100);
      TotalPProd = subTotalPProd + Iva;
      values.subtotal = subTotalPProd;
      values.iva = Iva;
      values.total = TotalPProd;
    })
  }

  function onKeyDownSelectCantidad(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobanteCompra): void {
    calcularDataGrid(values);
  }

  function onKeyDownSelectPrecio(clave: number, setFiledValue: any, evetn: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobanteCompra) {
    calcularDataGrid(values);
  }

  function onKeyDownSelectDescuento(clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: GeneracionComprobanteCompra, isValid: any) {
    calcularDataGrid(values);
    if (isValid) {
      arrayHelpers.push(new GeneracionComprobanteCompraDetalleFormValues());
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable + numberControlsTable
      );
    }
  }

  function deletedeclined() {
    closeModal();
  }

  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deleteGeneracionComprobanteCompra(id).then(() => {
      arrayHelpers.remove(index);
      setLastElementTable(
        (prevLastElementTable) =>
          prevLastElementTable - numberControlsTable
      );
    });
    setTarget(e);
    closeModal();
  }
  const addRowDetalle = (arrayHelpers: any) => {
    arrayHelpers.push(new GeneracionComprobanteCompraDetalleFormValues())
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        //  validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={generacionComprobanteCompra}
      >
        {({ values, setFieldValue, validateForm }) => (
          <Form className='ui form' >
            {generacionComprobanteCompra.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <div className='colsp' />
            <Grid columns='equal'>
              <Grid.Row>
                <Header as='h2' icon='compass' content='Generacion de Comprobantes Compras' color='blue' />
              </Grid.Row>
              <Segment>
                <Grid columns='equal'>
                  <GridRow>
                    <GridColumn width={2}>
                      <div className='col14'>

                        <MySelectInput
                          autoFocus={true}
                          tabIndex={1}
                          placeholder={"Seleccionar"}
                          label="Empresa"
                          name="empresaId"
                          enterInput={enterInput}
                          onChange={onChangeSelectEmpresa}
                          options={EmpresasByDropdownByClave}
                          inputWidth="85px"
                          menuWidth="300px"
                        />
                      </div>
                    </GridColumn>

                    <GridColumn width={2}>
                      <div className='col14'>

                        <MySelectInput
                          tabIndex={2}
                          placeholder={"Seleccionar"}
                          label="Sucursal"
                          name="sucursalId"
                          options={sucursalesByEmpresaByDropdownByClave}
                          enterInput={enterInput}
                          onChange={onChangeSelectSucursal}
                          inputWidth="85px"
                          menuWidth="300px"
                        />
                      </div>
                    </GridColumn>

                    <GridColumn width={2}>
                      <div className='col14'>
                        <MySelectInput
                          tabIndex={3}
                          placeholder={"Seleccionar"}
                          label="Tipo Comp."
                          name="tipoCompraId"
                          options={tiposDeComprasByDropdownByClave}
                          enterInput={enterInput}
                          onChange={idSelectTipo}
                          inputWidth="100px"
                          menuWidth="300px"
                        />
                      </div>

                    </GridColumn>

                    <GridColumn width={2}>
                      <MySelectInput
                        tabIndex={4}
                        placeholder="Serie"
                        label="Serie"
                        name="serieFolioDeCompraId"
                        options={SeriesFoliosByEmpresaByDropdownByClave}
                        enterInput={enterInput}
                        onChange={idSelectSerie}
                        inputWidth="85px"
                        menuWidth="300px"
                      />
                    </GridColumn>

                    <GridColumn width={2}>
                      <MyTextInput
                        className="textAlingRight widthtext heighttext input"
                        tabIndex={5}
                        placeholder=''
                        label="Folio"
                        name='folio'
                        enterInput={enterInput}
                        onKeyDown={onKeyUpFolio}

                        change={chageEnter}
                      />
                    </GridColumn>

                    <GridColumn width={3}>
                      <div className='col75'>
                        Fecha Emision
                        <MyDateInput
                          tabIndex={6}
                          placeholderText="Fecha Emision"
                          name="fecha"
                          dateFormat="dd/MM/yyyy"
                          enterInput={enterInput}
                        />
                      </div>
                    </GridColumn>
                    <GridColumn width={3}>
                      <div className='col75'>
                        F. Cancelacion
                        <MyDateInput
                          name="fechaCancelacion"
                          placeholderText="Fecha Cancelacion"
                          dateFormat="dd/MM/yyyy"
                          enterInput={enterInput}
                          tabIndex={9} />
                      </div>
                    </GridColumn>

                  </GridRow>
                </Grid>

              </Segment>
              <Grid.Row className="RowPadding0">

                <div className='colsp' />
                <div className='col20'>
                  <MySelectInput
                    tabIndex={7}
                    placeholder="Proveedor"
                    label="Proveedor"
                    name="proveedorId"
                    options={ProveedoresByDropdown}
                    enterInput={enterInput}
                    //onChange={idSelectSerie}
                    inputWidth="140px"
                    menuWidth="250px"
                  />
                </div>
                <div className='col35' />
                <div className='col25'>
                  <Button
                    type="button"
                    className="ui primary button"
                    tabIndex={8}
                    onKeyDown={tabuladorButton}
                    loading={loading}
                    onClick={() => {
                      //  abrirCopiarComprobanteCompra(values, setFieldValue);
                      validateForm().then((errors) => {
                        if (errors.empresaId || values.empresaId <= 0
                          || errors.sucursalId || values.sucursalId <= 0
                          || errors.tipoCompraId || values.tipoCompraId <= 0
                          || errors.serieFolioDeCompraId || values.serieFolioDeCompraId <= 0
                          || errors.folio || values.folio <= 0
                        ) {
                          setErrorButon("Favor de llenar los campos requeridos(Empresa,Sucursal,Tipo Venta y Serie)")
                          //   alert('El valor debe ser mayor que 0 y no puede estar vacío');
                        } else {
                          setErrorButon("")
                          abrirCopiarComprobanteCompra(values, setFieldValue);
                        }
                      });

                    }
                    }
                    floated="left"
                    color="blue"
                  >
                    <Icon name="map marker alternate" />
                    Copiar Comprobantes
                  </Button>
                  {errorButon ? (
                    <Label basic color="red">
                      {errorButon}
                    </Label>
                  ) : null}

                </div>
                <div className='colsp' />
                <div className='colsp' />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={3}>

                  <MySelectInput
                    tabIndex={9}
                    placeholder="Moneda"
                    label="Moneda"
                    name="monedaId"
                    options={MonedasByDropdown}
                    enterInput={enterInput}
                    onChange={idSelectMoneda}
                    inputWidth="140px"
                    menuWidth="180px"
                  />

                </Grid.Column>
                <Grid.Column width={2}>
                  <MyTextInput
                    tabIndex={11}
                    disabled={monedaExtranjera}
                    placeholder='Tipo de Cambio'
                    label="TC"
                    name='tipoCambio'
                    maxLength={6}
                    enterInput={enterInput}
                  />

                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={12}
                    placeholder="Cuenta de Compra"
                    label="Cuenta de Compra"
                    name="cuentaDeCompraId"
                    options={cuentasDeComprasByDropdown}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="200px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={13}
                    placeholder="Comprador"
                    label="Comprador"
                    name="compradorId"
                    options={usuariosByDropdown}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="200px"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <MySelectInput
                    tabIndex={14}
                    placeholder="Trabajador"
                    label="Trabajador"
                    name="trabajadorId"
                    options={usuariosByDropdown}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="200px"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Column>
                <div className='col15'>
                  <MyTextInput
                    tabIndex={15}
                    placeholder='Factura proveedor'
                    label="Factura Proveedor"
                    name='facturaProveedor'
                    maxLength={6}
                    enterInput={enterInput}
                  />
                </div>
                <div className='col15' >
                  Fecha Proveedor
                  <MyDateInput
                    name="fechaFacturaProveedor"
                    placeholderText="Fecha FactProv"
                    dateFormat="dd/MM/yyyy"
                    enterInput={enterInput}
                    tabIndex={16} />
                </div>
              </Grid.Column>

              <Segment>
                <div className="col100">
                  <FieldArray
                    name="detalles"
                    render={(arrayHelpers) => (
                      /////

                      <Table
                        style={{
                          overflow: "auto",
                          display: "inline-block",
                          maxHeight: "inherit",
                          width: 873,
                          height: 350
                        }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Producto</Table.HeaderCell>
                            <Table.HeaderCell>Nombre</Table.HeaderCell>
                            <Table.HeaderCell>U. Med.</Table.HeaderCell>
                            <Table.HeaderCell>Cantidad</Table.HeaderCell>
                            <Table.HeaderCell>Precio</Table.HeaderCell>
                            <Table.HeaderCell>Descuento</Table.HeaderCell>
                            <Table.HeaderCell>importe</Table.HeaderCell>

                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {values.detalles && values.detalles.length > 0 ? (
                            values.detalles?.map(
                              (detalles, index) => (
                                <Table.Row key={index.valueOf()} className="paddinTable">
                                  <Table.Cell className="paddinTable">
                                    {" "}
                                    <MySelectInput
                                      tabIndex={getIndexTable(index, 1)}
                                      placeholder="Producto"
                                      name={`detalles[${index}].productoId`}
                                      options={productosByEmpresaSucursalDropdownByClave}
                                      enterInput={enterInput}
                                      index={index}
                                      onChange={OnChangeSelectProducto}
                                      inputWidth="130px"
                                      menuWidth="300px"
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}

                                    <MyTextInput
                                      disabled={true}
                                      tabIndex={getIndexTable(index, 2)}
                                      className="widthTextTable200 heighttext input "
                                      placeholder="Nombre"
                                      name={`detalles[${index}].producto.nombre`}
                                      enterInput={enterInput}
                                      index={index}
                                      columna={1}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}

                                    <MyTextInput
                                      disabled={true}
                                      tabIndex={getIndexTable(index, 3)}
                                      className="widthTextTable90 heighttext input "
                                      placeholder="UnidadMedida"
                                      name={`detalles[${index}].unidadMedida.nombre`}
                                      enterInput={enterInput}
                                      index={index}
                                      columna={1}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 3)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Cantidad"
                                      name={`detalles[${index}].cantidad`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      onKeyDown={onKeyDownSelectCantidad}
                                      columna={2}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 4)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Precio"
                                      name={`detalles[${index}].precio`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      onKeyDown={onKeyDownSelectPrecio}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 5)}
                                      className="widthTextTable70 heighttext input textAlingRight"
                                      placeholder="Descuento"
                                      name={`detalles[${index}].descuento`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      onKeyDown={onKeyDownSelectDescuento}
                                    />
                                  </Table.Cell>

                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      disabled={true}
                                      tabIndex={getIndexTable(index, 6)}
                                      className="widthTextTable100 heighttext input textAlingRight"
                                      placeholder="Importe"
                                      name={`detalles[${index}].subtotal`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      arrayHelpers={arrayHelpers}
                                      index={index}
                                      columna={5}
                                    />
                                  </Table.Cell>


                                  <Table.Cell>
                                    <Button icon
                                      tabIndex={getIndexTable(index, 7)}
                                      name={detalles.id}
                                      type="Button"
                                      disabled={
                                        loading
                                        &&
                                        target ===
                                        String(detalles.id)

                                      }
                                      loading={
                                        loading &&
                                        target ===
                                        String(detalles.id)

                                      }
                                      onClick={(e) => {
                                        openModal(
                                          <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={detalles.id}
                                            nombre={values.detalles ? values.detalles[index].producto?.nombre : "Fila"}
                                            arrayHelpers={arrayHelpers}
                                            index={index}
                                          />,
                                          enumHeaderModals.DetallesComprobante
                                        );
                                      }}
                                      floated="right"
                                      //    content="Eliminar"
                                      color="red"
                                    >
                                      <Icon name='delete' />
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )) :
                            <Table.Row>
                              <Table.Cell>
                                <Button
                                  tabIndex={7}
                                  primary
                                  type="button"
                                  onClick={() => {
                                    addRowDetalle(arrayHelpers)
                                  }}
                                  content="Agregar Renglón"
                                />
                              </Table.Cell>
                            </Table.Row>
                          }
                        </Table.Body>
                      </Table>
                    )}
                  />
                </div>
              </Segment>
            </Grid>
            <div className='colsp' />

            <Segment>
              <div className='col20'>
                <Button
                  type="button"
                  className="ui primary button"
                  tabIndex={8}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <PagoComprobantes
                        pago={pagos}
                        setPagos={setPagos}
                        closeModals={closeModals}
                        tabIndex={2228}
                      />,
                      'Pagos',
                      'tiny'
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="money bill alternate" />
                  Pagos
                </Button>
              </div>
              <Grid className="ui grid lineheight">

                <div className='col60'></div>
                <label></label>
                <div className='col15'>

                  <label>Subtotal</label></div>
                <div className='col20'>
                  <MyTextInput className=" heighttext input textAlingRight"
                    placeholder=''
                    name='subtotal' />
                </div>
                <div className='col60'></div>
                <label></label>
                <div className='col15'>
                  <label>Iva</label></div>
                <div className='col20'>
                  <MyTextInput className=" heighttext input textAlingRight"
                    placeholder=''
                    name='iva' />
                </div>

                <div className='col60'></div>
                <label>   </label>
                <div className='col15'>
                  <label>Total</label></div>
                <div className='col20'>
                  <MyTextInput className=" heighttext input textAlingRight"
                    placeholder=''
                    name='total' />
                </div>
              </Grid>
            </Segment>
            <Segment>

              <BarraBotones
                toLink="/vehiculos"
                loading={loading}
                tabIndex={lastElementTable + 2}
                id={id}
                firstElement={firstElement}
                lastElement={lastElementTable + 4}
              ></BarraBotones>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={8}
                onKeyDown={tabuladorButton}
                loading={loading}
                disabled={generacionComprobanteCompra.id === 0 ? true : false}
                onClick={() => {
                  setImprimiendo(true);
                  imprimirComprobanteCompra(generacionComprobanteCompra.id).then(resul => {
                    if (resul !== undefined && resul !== "No se pudo actualizar") {
                      let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                      window.open(urlApi + resul, '_blank', features);
                    }
                  });
                }
                }
                floated="left"
                color="blue"
              >
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})