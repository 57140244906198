import React from 'react';
import { observer } from 'mobx-react-lite';
import { Segment, List, Button, Header } from 'semantic-ui-react';
import { CartItem } from '../../../../app/models/types';
import { formatDecimal } from '../../../../app/base/Tools';

interface ShoppingCartProps {
  items: CartItem[];
  removeFromCart: (id: number) => void;
}

export const ShoppingCart: React.FC<ShoppingCartProps> = observer(({ items, removeFromCart }) => {
  const cartStyle: React.CSSProperties = {
    maxHeight: items.length > 5 ? '300px' : 'none',
    overflowY: items.length > 5 ? 'auto' : 'visible',
    padding: items.length > 5 ? '0 1rem 0 0' : '0', // Add right padding for scrollbar
  };
  return (
    <Segment>
      <Header as="h2">Carrito de Compras</Header>
      {items.length === 0 ? (
        <p>El carrito está vacío</p>
      ) : (
        <div style={cartStyle}>
          <List divided relaxed>
            {items.map((item) => (
              <List.Item key={item.product.id}>
                <List.Content>
                  <List.Header as="a">{item.product.nombre}</List.Header>
                  <List.Description>
                    Cant.: {item.quantity} - Precio: ${formatDecimal(item.product.controlListaDePrecio?.length > 0 ? item.product.controlListaDePrecio[0]?.precio : 0)} - Total: ${formatDecimal((item.product.controlListaDePrecio?.length > 0 ? item.product.controlListaDePrecio[0]?.precio : 0) * item.quantity)}
                  </List.Description>
                </List.Content>
                <List.Content floated="right">
                  <Button color="red" onClick={() => removeFromCart(item.product.id)}>
                    Eliminar
                  </Button>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </div>
      )}
    </Segment>
  );
});