import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { AlmacenTipo } from "../../models/inventario/AlmacenTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class AlmacenTipoStore {
  almacenTipoRegistry = new Map<number, AlmacenTipo>();
  selectedAlmacenTipo: AlmacenTipo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get cuentasInventariosTiposByDate() {
    return Array.from(this.almacenTipoRegistry.values()).sort((a) => a.id);
    //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  }

  loadAlmacenTipos = async () => {
    this.setLoadingInitial(true);
    try {
      const almacenesTipos = await agent.AlmacenesTipos.list();
      almacenesTipos.forEach((almacenTipo) => {
        this.setAlmacenTipo(almacenTipo);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  get almacenTipoByDropdown() {
    let mondeaTipoArreg = [new TipoDropDownValues()];
    let tipoMonedasOpt = new TipoDropDownValues();
    const montipos = Array.from(this.almacenTipoRegistry.values()).sort(
      (a, b) => {
        if (a.nombre > b.nombre) return 1;
        if (a.nombre < b.nombre) return -1;
        return 0;
      }
    );
    montipos.forEach((element) => {
      tipoMonedasOpt.key = element.id;
      tipoMonedasOpt.text = element.nombre;
      tipoMonedasOpt.value = element.id;
      tipoMonedasOpt.content = element.nombre;
      mondeaTipoArreg.push(tipoMonedasOpt);
      tipoMonedasOpt = new TipoDropDownValues();
    });
    return mondeaTipoArreg;
  }
  get almacenTipoByDropdownByClave() {
    let mondeaTipoArreg = [new TipoDropDownValues()];
    let tipoMonedasOpt = new TipoDropDownValues();
    const montipos = Array.from(this.almacenTipoRegistry.values()).sort(
      (a, b) => {
        if (a.nombre > b.nombre) return 1;
        if (a.nombre < b.nombre) return -1;
        return 0;
      }
    );
    montipos.forEach((element) => {
      tipoMonedasOpt.key = element.id;
      tipoMonedasOpt.text = element.clave.toString();
      tipoMonedasOpt.value = element.id;
      tipoMonedasOpt.content = element.clave +" "+ element.nombre;
      mondeaTipoArreg.push(tipoMonedasOpt);
      tipoMonedasOpt = new TipoDropDownValues();
    });
    return mondeaTipoArreg;
  }

  getAlmacenesTipos = async () => {
    this.setLoadingInitial(true);
    const almacenesTipos = await agent.AlmacenesTipos.list();
    try {
      almacenesTipos.forEach((almacenTipo) => {
        this.setAlmacenTipo(almacenTipo);
      });
      this.setLoadingInitial(false);
      return almacenesTipos;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return almacenesTipos;
    }
  };

  loadAlmacenTipo = async (id: number) => {
    let almacenTipo = this.getAlmacenTipo(id);
    if (almacenTipo) {
      this.selectedAlmacenTipo = almacenTipo;
      return almacenTipo;
    } else {
      this.loadingInitial = true;
      try {
        almacenTipo = await agent.AlmacenesTipos.details(id);
        this.setAlmacenTipo(almacenTipo);
        this.selectedAlmacenTipo = almacenTipo;
        this.setLoadingInitial(false);
        return almacenTipo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setAlmacenTipo = (almacenTipo: AlmacenTipo) => {
    this.almacenTipoRegistry.set(almacenTipo.id, almacenTipo);
  };

  private getAlmacenTipo = (id: number) => {
    return this.almacenTipoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createAlmacenTipo = async (almacenTipo: AlmacenTipo) => {
    this.loading = true;
    try {
      await agent.AlmacenesTipos.create(almacenTipo);
      runInAction(() => {
        this.almacenTipoRegistry.set(almacenTipo.id, almacenTipo);
        this.selectedAlmacenTipo = almacenTipo;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateAlmacenTipo = async (almacenTipo: AlmacenTipo) => {
    this.loading = true;
    try {
      await agent.AlmacenesTipos.update(almacenTipo);
      runInAction(() => {
        this.almacenTipoRegistry.set(almacenTipo.id, almacenTipo);
        this.selectedAlmacenTipo = almacenTipo;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteAlmacenTipo = async (id: number) => {
    this.loading = true;
    try {
      await agent.AlmacenesTipos.delete(id);
      runInAction(() => {
        this.almacenTipoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
