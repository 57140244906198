import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ListaDePrecio,
  ListaDePrecioFormValues,

} from "../../../../app/models/controlPrecio/ListaDePrecio";
import MySelectInput from "../../../../app/common/form/MySelectInput";

import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import ListaDePrecioStore from "../../../../app/stores/ControlPrecio/listaDePrecioStore";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function ListaDePrecioForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 11;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { listaDePrecioStore, listaDePrecioTipoStore } = useStore();

  const {
    createListaDePrecio,
    updateListaDePrecio,
    loadListaDePrecio,
    loadingInitial,
    listaDePrecioById,
    loading
  } = listaDePrecioStore;
  const { listaDePrecioTiposByDropdown, getListasDePreciosTipos } = listaDePrecioTipoStore;
  //const { id } = useParams<{ id: string }>();
  const [listaDePrecio, setListaDePrecio] = useState(new ListaDePrecioFormValues());
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  useEffect(() => {
    getListasDePreciosTipos().then(cvts => { });
  }, [])


  const validationSchema = Yup.object({
    clave: Yup.number().required('La clave de la Lista de precio es requerida.'),
    nombre: Yup.string().required('El nombre de la Lista de precio es requerido.'),
    listaDePrecioTipoId: Yup.number()
       .min(1, "Favor de seleccionar el tipo.")
       .required("El tipo es requerido.")
  });

  useEffect(() => {
    debugger
    if (id) {
      loadListaDePrecio(id).then(listaDePrecio => {
        setListaDePrecio(new ListaDePrecioFormValues(listaDePrecio))
        setFechaObservacion(
          new FechaObservacionValues(listaDePrecio?.fechaObservacion)
        );
      }
      );
    } else {
      listaDePrecio.clave =
        listaDePrecioById.map((listaDePrecio) => listaDePrecio.clave).length > 0
          ? Math.max(...listaDePrecioById.map((listaDePrecio) => listaDePrecio.clave)) + 1
          : 1;
    }
  }, []);



  function handleSubmit(listaDePrecio: ListaDePrecio) {
    if (listaDePrecio.listaDePrecioTipoId.toString() !== "0" && listaDePrecio.nombre !== null) {
      if (listaDePrecio.fechaObservacion) {
        listaDePrecio.fechaObservacion = fechaObservacion;
      }
      listaDePrecio.id > 0 ? updateListaDePrecio(listaDePrecio) : createListaDePrecio(listaDePrecio);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Lista de Precio' />

  return (
    <Segment clearing>
      <Formik onSubmit={(values) => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={listaDePrecio}
      >
        {({ isValid, dirty, handleSubmit }) => (
          <Form className='ui form'>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Lista de precio
              </Label>

              <MyTextInput
                placeholder="Clave"
                label="Clave"
                name="clave"
                disabled={true}
                tabIndex={0}
              />

              <MyTextInput
                autoFocus={true}
                placeholder="Nombre"
                label="Nombre"
                name="nombre"
                tabIndex={1}
                enterInput={enterInput}
                maxLength={30}
              />

              <div className="col">
                <MySelectInput
                  tabIndex={2}
                  placeholder={"Seleccionar"}
                  label="Tipo"
                  name="listaDePrecioTipoId"
                  options={listaDePrecioTiposByDropdown}
                  enterInput={enterInput}
                />
              </div>

            </Segment>
            <Segment clearing>
              <div className="col10">
                <MyCheckInput 
                placeholder="Activa" 
                name="activa" 
                tabIndex={3}
                enterInput={enterInput}/>
              </div>


            </Segment>
            <Segment clearing> <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={8}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={setFechaObservacion}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Moneda
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <BarraFechaObservacion
                fechaObservacion={listaDePrecio.fechaObservacion}
              ></BarraFechaObservacion>
            </Segment >
            <Segment>
              <BarraBotones
                toLink="/listasPrecios"
                loading={loading}
                tabIndex={4}
                //id={id}
                firstElement={0}
                lastElement={6}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
