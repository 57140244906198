import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { UsuarioTipo } from "../../models/configuracion/UsuarioTipo";

export default class UsuarioTipoStore {
    usuarioTipoRegistry = new Map<number, UsuarioTipo>();
    selectedUsuarioTipo: UsuarioTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get usuariosByDate() {
        return Array.from(this.usuarioTipoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadUsuarioTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const usuarioTipos = await agent.UsuarioTipos.list();
            usuarioTipos.forEach(usuarioTipo => {
                this.setUsuarioTipo(usuarioTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getUsuarioTipos = async () => {
        this.setLoadingInitial(true);
        const usuarioTipos = await agent.UsuarioTipos.list();
        try {
            
            usuarioTipos.forEach(usuarioTipo => {
                this.setUsuarioTipo(usuarioTipo);
            })
            this.setLoadingInitial(false);
            return usuarioTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return usuarioTipos;
        }
    }

    loadUsuarioTipo = async (id: number) => {
        let usuarioTipo = this.getUsuarioTipo(id);
        if (usuarioTipo) {
            this.selectedUsuarioTipo = usuarioTipo;
            return usuarioTipo;

        } else {
            this.loadingInitial = true;
            try {
                usuarioTipo = await agent.UsuarioTipos.details(id);
                this.setUsuarioTipo(usuarioTipo);
                this.selectedUsuarioTipo = usuarioTipo;
                this.setLoadingInitial(false);
                return usuarioTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setUsuarioTipo = (usuarioTipo: UsuarioTipo) => {
        this.usuarioTipoRegistry.set(usuarioTipo.id, usuarioTipo);
    }

    private getUsuarioTipo = (id: number) => {
        return this.usuarioTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createUsuarioTipo = async (usuarioTipo: UsuarioTipo) => {
        this.loading = true;
        try {
            await agent.UsuarioTipos.create(usuarioTipo);
            runInAction(() => {
                this.usuarioTipoRegistry.set(usuarioTipo.id, usuarioTipo);
                this.selectedUsuarioTipo = usuarioTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteUsuarioTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.UsuarioTipos.delete(id);
            runInAction(() => {
                this.usuarioTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
