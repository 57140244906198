import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CuentaDeCliente } from "../models/gestionCliente/CuentaDeCliente";
import { toast } from "react-toastify";
import { history } from "../..";
import { TipoDropDownValues } from "../models/configuracion/TipoDropdown";
import { setDate } from "../common/util/util";
import { TipoMovimiento } from "../models/TipoMovimiento";

export default class TipoMovimientoStore {
    tipoMovimientoRegistry = new Map<number, TipoMovimiento>();
    selectedTipoMovimiento: TipoMovimiento | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tiposMovimientosByDate() {
        return Array.from(this.tipoMovimientoRegistry.values()).sort((a) => a.id);
    }

    get tipoMovimientoById() {
        return Array.from(this.tipoMovimientoRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }
    
    get tiposMovimientosByDropdown() {
        let TipoMovimientoArreg = [new TipoDropDownValues()];
        let tipoMovimientoOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoMovimientoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tipoMovimientoOpt.key = element.id;
            tipoMovimientoOpt.text = element.nombre;
            tipoMovimientoOpt.value = element.id;
            tipoMovimientoOpt.content = element.nombre;
            TipoMovimientoArreg.push(tipoMovimientoOpt);
            tipoMovimientoOpt = new TipoDropDownValues();
        });
        return TipoMovimientoArreg;
    }

    get tiposMovimientosByDropdownByClave() {
        let TipoMovimientoArreg = [new TipoDropDownValues()];
        let tipoMovimientoOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoMovimientoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tipoMovimientoOpt.key = element.id;
            tipoMovimientoOpt.text = element.clave.toString();
            tipoMovimientoOpt.value = element.id;
            tipoMovimientoOpt.content = element.clave.toString()+" "+ element.nombre;
            TipoMovimientoArreg.push(tipoMovimientoOpt);
            tipoMovimientoOpt = new TipoDropDownValues();
        });
        return TipoMovimientoArreg;
    }

    getTiposMovimientos = async () => {
        this.setLoadingInitial(true);
        const tiposMovimientos = await agent.TiposMovimientos.list();
        try {
            tiposMovimientos.forEach(tipoMovimiento => {
                this.setTipoMovimiento(tipoMovimiento);
            })
            this.setLoadingInitial(false);
            return tiposMovimientos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return tiposMovimientos;
        }
    }

    loadTiposMovimientos = async () => {
        this.setLoadingInitial(true);
        try {
            const tiposMovimientos = await agent.TiposMovimientos.list();
            tiposMovimientos.forEach(tipoMovimiento => {
                this.setTipoMovimiento(tipoMovimiento);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadTipoMovimiento = async (id: number) => {
        let tipoMovimiento = this.getTipoMovimiento(id);
        if (tipoMovimiento) {
            this.selectedTipoMovimiento = tipoMovimiento;
            return tipoMovimiento;
        } else {
            this.loadingInitial = true;
            try {
                tipoMovimiento = await agent.TiposMovimientos.details(id);
                this.setTipoMovimiento(tipoMovimiento);
                this.selectedTipoMovimiento = tipoMovimiento;
                this.setLoadingInitial(false);
                return tipoMovimiento;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setTipoMovimiento = (tipoMovimiento: TipoMovimiento) => {
        this.tipoMovimientoRegistry.set(tipoMovimiento.id, tipoMovimiento);
    }

    private getTipoMovimiento = (id: number) => {
        return this.tipoMovimientoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoMovimiento = async (tipoMovimiento: TipoMovimiento) => {
        this.loading = true;
        try {
            await agent.TiposMovimientos.create(tipoMovimiento);
            runInAction(() => {
                this.selectedTipoMovimiento = tipoMovimiento;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Tipo Movimiento creado con éxito")
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateTipoMovimiento = async (tipoMovimiento: TipoMovimiento) => {
        this.loading = true;
        try {
            await agent.TiposMovimientos.update(tipoMovimiento);
            runInAction(() => {
                this.selectedTipoMovimiento = tipoMovimiento;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Tipo Movimiento editado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteTipoMovimiento = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposMovimientos.delete(id);
            runInAction(() => {
                this.tipoMovimientoRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Tipo Movimiento eliminada con éxito");
        } catch (error) {
            toast.error("Problema al eliminar Tipo movimiento");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
