export interface CorteDeCajaFiltro {
    id: number;
    empresaId: number;
    sucursalId: number;
    cajeraId: number;
    fecha?: Date;
  }
 export class CorteDeCajaFiltroFormValues implements CorteDeCajaFiltro {
    id: number = 0;
    empresaId: number = 0;
    sucursalId: number = 0;
    cajeraId: number=0;
    fecha?: Date;
    constructor(init?: CorteDeCajaFiltro) {
      Object.assign(this, init);
    }
  }