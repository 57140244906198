import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import CuentaProveedorList from './CuentaDeProveedorList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CuentaDeProveedorForm from '../form/CuentaDeProveedorForm';
import ReporteProveedorForm from '../form/ReporteProveedorForm';

export default observer(function CuentaDeProveedorDashboard() {
  const { cuentaDeProveedorStore, tabsStore } = useStore();
  useEffect(() => {
    cuentaDeProveedorStore.loadCuentasDeProveedores();
  }, [cuentaDeProveedorStore]);

  if (cuentaDeProveedorStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h2' content='Cuentas Proveedores' color='blue' />
        </Grid.Column>
        <Grid.Column width={9}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Cuenta de Proveedor', <CuentaDeProveedorForm id={0} />)}
              content='Crear Cuenta de Proveedor' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Cuenta de Proveedor', <ReporteProveedorForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => cuentaDeProveedorStore.loadCuentasDeProveedores()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <CuentaProveedorList />
      </Grid.Column>
    </Grid>
  )
})