import { useState, useEffect, useRef, useContext, memo } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Header, Button, Icon, Label, SegmentGroup, TabPane, Tab } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form, FieldArray, FastField } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import BarraBotones from '../../../Component/BarraBotones';
import { enumHeaderModals } from "../../../modals/MensajeModals";
import { CapturaPagos, CapturaPagosFormValues, ComprobantesSaldo, MovimientoEliminado, PagoAplicado } from '../../../../app/models/gestionCliente/CapturaPagos';
import MyCheckInput from '../../../../app/common/form/MyCheckInput';
import { enumTipoDato } from '../../../../app/common/form/types/myTextinput-types';
import { Redondear, formatDecimal } from '../../../../app/base/Tools';
import moment from 'moment';
import MyDataGridRows from '../../../../app/common/form/MyDataGridRows';
import { UserContext } from '../../../../app/context/UserContext';
import DeleteModals from '../../../modals/DeleteModals';
import { enterInputs } from '../../../../app/common/funciones/tabuladorEnter';
import TextInput from '../../../../app/common/form/TextInput';
import { TipoDropdown, TipoDropDownValues } from '../../../../app/models/configuracion/TipoDropdown';
import { PaginationFormValues } from '../../../../app/models/Especificaciones/PaginationSpecParams';

export default observer(function CapturaPagosForm() {
  const { user } = useContext(UserContext);
  const txtAbono = useRef<any>()
  let indexCaptura = 0;
  const [target, setTarget] = useState("");
  const firstElement = 0;
  const lastElement = 20;
  const initialIndexTable = lastElement;
  const numberControlsTable = 4;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { empresaStore, sucursalStore, capturaPagosStore, cuentaDeClienteStore, conceptoClienteStore, clienteStore, formaPagoStore } = useStore();
  const { formasPagosByDropdown, loadFormasPagos } = formaPagoStore;
  const { loadClienteByParamsDropdown } = clienteStore
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore
  const { cuentaClienteByDropdownByClave, loadCuentasClientes } = cuentaDeClienteStore
  const { conceptosClientesByDropdown, loadConceptosClientes } = conceptoClienteStore
  const { fetchSucursales } = sucursalStore
  const { deletePagoAplicado, pagosAplicadosById, movimientoEliminadoById, loadingInitial, createCapturaPagos, loading, loadCapturaPagos, capturaPagosParams } = capturaPagosStore
  const [capturaPagosFormValues] = useState(new CapturaPagosFormValues());
  const [cliente, setCliente] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    cuentaDeClienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cuenta.")
      .required("Cuenta es requerido."),
    clienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cliente.")
      .required("Cliente es requerido."),
    abonar: Yup.number()
      .typeError("El valor debe ser un numero.")
      .moreThan(0, "Favor de ingresar abonar mayor a 0.")
      .required("Abonar es requerida."),
    numOperacion: Yup.number()
      .typeError("El valor debe ser un numero.")
      .moreThan(0, "Favor de ingresar Núm Operación mayor a 0.")
      .required("Núm Operación es requerida."),
    conceptoDeClienteId: Yup.number()
      .min(1, "Favor de seleccionar un Concepto.")
      .required("Concepto es requerido."),
    formaDePagoId: Yup.number()
      .min(1, "Favor de seleccionar un Forma de pago.")
      .required("Forma de pago es requerido."),
    fecha: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    fechaDeposito: Yup.date().typeError("El valor debe ser una fecha.")
      .required("La fecha es requerida"),
    comprobantesSaldo: Yup.array().of(
      Yup.object().shape({
      })
    ),
  });

  useEffect(() => {
    void loadFormasPagos();
    void loadConceptosClientes();
    void loadCuentasClientes();
    void loadEntityEmpresas();
  }, []);
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deletePagoAplicado(id);
    setTarget(e);
    closeModal();
  }
  const handleSubmit = (capturaPagos: CapturaPagos) => {
    let capturapagosArray: CapturaPagos[] = [];

    capturaPagos.comprobantesSaldo?.forEach((movimientos) => {
      debugger
      if (movimientos.abono > 0) {
        const porcentajePagoTotalFactura = (movimientos.abono * 100) / movimientos.importe;
        const subtotalAbono = movimientos.subtotal * (porcentajePagoTotalFactura / 100);
        const ivaAbono = movimientos.iva * (porcentajePagoTotalFactura / 100);

        capturapagosArray.push({
          empresa: movimientos.empresa,
          empresaId: capturaPagos.empresaId,
          id: 0,
          clave: capturaPagos.clave,
          nombre: "",
          sucursalId: capturaPagos.sucursalId,
          cuentaDeClienteId: capturaPagos.cuentaDeClienteId,
          cliente: movimientos?.cliente,
          clienteId: capturaPagos.clienteId,
          todo: capturaPagos.todo,
          limiteCredito: capturaPagos.limiteCredito,
          saldo: movimientos.saldo,
          saldoDisponible: capturaPagos.saldoDisponible,
          creditoSuspendido: capturaPagos.creditoSuspendido,
          abonar: capturaPagos.abonar,
          abonado: capturaPagos.abonado,
          disponible: capturaPagos.disponible,
          numOperacion: capturaPagos.numOperacion,
          conceptoDeClienteId: capturaPagos.conceptoDeClienteId,
          formaDePagoId: capturaPagos.formaDePagoId,
          esAnticipo: capturaPagos.esAnticipo,
          fecha: capturaPagos.fecha,
          fechaDeposito: capturaPagos.fechaDeposito,
          usuarioId: user.id,
          subtotalAbono: subtotalAbono,
          ivaAbono: ivaAbono,
          importe: movimientos.importe,
          //agrego datos de movimiento detalle
          abono: movimientos.abono,
          monedaId: movimientos.monedaId,
          observaciones: movimientos.observaciones,
          movimientoClienteDetalleId: movimientos.id,
          folio: movimientos.folio,
          serie: movimientos.serie,
          tipoCambio: movimientos.tipoCambio,
          pagoAplicado: [],
          comprobantesSaldo: [],
          movimientoEliminado: [],
          //agrego esta propiedad para saber de que movimiento cliente detalle es el pago, para sumar los pagos
          movimientoClienteId: movimientos.id,
          esPPD: movimientos.esPPD,
          //UUID:movimientos.UUID,
        })
      }
    });
    debugger
    if (capturapagosArray?.length > 0)
      void createCapturaPagos(capturapagosArray);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  function deletedeclined() {
    closeModal();
  }

  const selectInputEmpresaOnChange = (idSelected: number, setFieldAny: any) => {
    setFieldAny("sucursalId", 0);
    setFieldAny("clienteId", 0);
    setCliente([new TipoDropDownValues()]);
    capturaPagosParams.empresaId = 0;
    capturaPagosParams.sucursalId = 0;
    capturaPagosParams.clienteId = 0;
    fetchSucursales(idSelected).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
    capturaPagosParams.empresaId = idSelected;
  }
  const selectInputSucursalOnChange = (idSelected: number, setFieldAny: any, index: any, values: any) => {
    setFieldAny("clienteId", 0);
    setCliente([new TipoDropDownValues()]);
    capturaPagosParams.clienteId = 0;
    capturaPagosParams.sucursalId = idSelected;
    if (capturaPagosParams.empresaId > 0 && capturaPagosParams.sucursalId > 0) {
      loadClienteByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: idSelected, clienteId: 0 }))
        .then((result) => {
          if (result)
            setCliente(result);
        })
    }
  }

  const selectInputClienteOnChange = (idSelected: any) => {
    capturaPagosParams.clienteId = parseInt(idSelected);

  }
  const selectInputCuentaOnChange = (idSelected: any) => {
    capturaPagosParams.cuentaId = idSelected;

  }
  const selectInputClienteOnBluer = (setFieldValue: any) => {
    asignarDatos(setFieldValue);
  }

  const asignarDatos = (setFieldValue?: any) => {
    if (capturaPagosParams.clienteId > 0 && capturaPagosParams.empresaId > 0 && capturaPagosParams.sucursalId > 0 && capturaPagosParams.cuentaId > 0) {
      loadCapturaPagos(capturaPagosParams).then((result) => {
        if (result) {
          let pagoAplicadoMovimiento = 0;
          let movimientoDetalleArray: ComprobantesSaldo[] = [];
          result.comprobantesSaldo?.forEach((movimientoDetalle) => {
            pagoAplicadoMovimiento = 0;
            result?.pagoAplicado?.forEach(pagoAplicado => {
              //  if ((pagoAplicado.folio == movimientoDetalle.folio && pagoAplicado.serie == movimientoDetalle.serie)) {
              if ((pagoAplicado.movimientoClienteId === movimientoDetalle.id)) {
                pagoAplicadoMovimiento += pagoAplicado.abono;
              }
            });
            movimientoDetalle.saldo = movimientoDetalle.importe - pagoAplicadoMovimiento;
            movimientoDetalle.abono = 0;
            if (movimientoDetalle.saldo > 0)
              movimientoDetalleArray = [...movimientoDetalleArray, movimientoDetalle];
          });
          setFieldValue("comprobantesSaldo", movimientoDetalleArray);
          setFieldValue("pagoAplicado", result.pagoAplicado);
          setLastElementTable(
            (prevLastElementTable) =>
              prevLastElementTable + movimientoDetalleArray?.length * 2
          );
        }
        else {
          setFieldValue("comprobantesSaldo", []);
        }
      })
    } else {
      setFieldValue("comprobantesSaldo", []);
    }
  }
  // const inputAbonarKeyDown = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any) => {
  //   calcularDataGrid(values, event, index, setFiledValue);
  // }
  const inputAbonarOnBlur = (setFiledValue: any, values: any) => {
    calcularDataGrid(values, "", "", setFiledValue);
  }
  // const inputAbonoKeyDown = (clave: number, setFiledValue: any, event: any, arrayHelpers: any, index: any, columna: any, values: any) => {
  //   calcularDataGrid(values, event, index, setFiledValue);
  // }
  const inputAbonoOnBlur = (setFiledValue: any, values: any, index: any) => {
    calcularDataGrid(values, "", index, setFiledValue);
  }
  // const calcularDataGrid = (values: CapturaPagos, event?: any, index?: any, setFiledValue?: any) => {
  //   if (values.abonar > 0) {
  //     let abonadoGrid = 0;
  //     let totalFaacturas = 0;
  //     let saldo = 0;
  //     let validar = true;
  //     values?.comprobantesSaldo?.forEach((detalle) => {
  //       if (validar) {
  //         abonadoGrid += (parseFloat(detalle.abono.toString()) || 0);
  //         totalFaacturas += detalle.importe;
  //         saldo += detalle.saldo;
  //       }
  //       return detalle;
  //     });
  //     setFiledValue("totalFacturas", totalFaacturas);
  //     setFiledValue("totalSaldo", saldo);
  //     setFiledValue("abonado", Redondear(abonadoGrid, 2))
  //     let disponible = (values.abonar - abonadoGrid);
  //     setFiledValue("disponible", Redondear(disponible, 2))
  //   }
  // }

  const calcularDataGrid = (values: CapturaPagos, event?: any, index?: any, setFiledValue?: any) => {
    if (values.abonar > 0) {
      const { totalFaacturas, saldo, abonadoGrid } = values.comprobantesSaldo?.reduce((acc, detalle) => {
        const abono = parseFloat(detalle.abono?.toString() || "0");
        return {
          abonadoGrid: acc.abonadoGrid + abono,
          totalFaacturas: acc.totalFaacturas + detalle.importe,
          saldo: acc.saldo + detalle.saldo
        };
      }, { abonadoGrid: 0, totalFaacturas: 0, saldo: 0 }) || { abonadoGrid: 0, totalFaacturas: 0, saldo: 0 };

      setFiledValue("totalFacturas", totalFaacturas);
      setFiledValue("totalSaldo", saldo);
      setFiledValue("abonado", Redondear(abonadoGrid, 2));

      const disponible = values.abonar - abonadoGrid;
      setFiledValue("disponible", Redondear(disponible, 2));
    }
  };

  const calcularDiasDePlazo = (fecha: Date, fechaVencimiento?: Date): number => {
    if (!fechaVencimiento) {
      return 0; // Si no hay fecha de vencimiento, retornar 0
    }
    // Obtener la diferencia en milisegundos
    const diferenciaMilisegundos = new Date(fechaVencimiento).getTime() - new Date(fecha).getTime();
    // Convertir la diferencia en días,milisegundos que hay en un día((1000 * 60 * 60 * 24))
    const diferenciaDias = Math.floor(diferenciaMilisegundos / (86400000));
    return diferenciaDias;
  };

  const ComprobanteRow = memo(({ values, setFieldValue }: any) => (
    <Table.Body>
      {values?.comprobantesSaldo?.map(
        (item: ComprobantesSaldo, index: any) => (
          <Table.Row key={index.valueOf()}>
            <MyDataGridRows width="35px" value={item?.empresa?.clave} alineacion="center" />
            <MyDataGridRows width="40px" value={item?.sucursal?.clave} alineacion="center" />
            <MyDataGridRows width="100px" value={item.serie + item.folio} alineacion="center" />
            <MyDataGridRows width="90px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
            <MyDataGridRows width="50px" value={calcularDiasDePlazo(item.fecha, item.fechaVencimiento)} alineacion="center" />
            <MyDataGridRows width="90px" value={moment(item?.fechaVencimiento).format("DD/MM/YYYY")} alineacion="center" />
            <MyDataGridRows width="120" value={item.vendedor?.nombre} alineacion="left" />
            <MyDataGridRows width="40" value={item.moneda?.clave} alineacion="center" />
            <MyDataGridRows width="80" value={formatDecimal(item.importe, 2)} alineacion="right" />
            <MyDataGridRows width="80" value={formatDecimal(item.saldo, 2)} alineacion="right" />
            <Table.Cell>
              <FastField
                name={`comprobantesSaldo.[${index}].abono`}
                component={TextInput}
                placeholder="abono"
                enterInput={enterInput}
                tabIndex={index * 2 + 800001}
                tipoDato={enumTipoDato.Numerico}
                onBlur={() => inputAbonoOnBlur(setFieldValue, values, index)}
                style={{
                  textAlign: "right",
                  border: "0px solid rgba(0, 0, 0, 0.277)",
                  width: '90px',
                  background: "transparent"
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <FastField
                name={`comprobantesSaldo.[${index}].observaciones`}
                component={TextInput}
                placeholder="Observaciones"
                tabIndex={index * 2 + 800002}
                enterInput={enterInput}
                tipoDato={enumTipoDato.Caracteres}
                style={{
                  textAlign: "right",
                  border: "0px solid rgba(0, 0, 0, 0.277)",
                  width: '90px',
                  background: "transparent"
                }}
              />
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Button type="button" style={{
                width: '50px',
                background: "transparent"
              }}><Icon color='green' name='file pdf' />
              </Button>
            </Table.Cell>
            <MyDataGridRows width="50" value={item.esPPD ? "PPD" : "PUE"} alineacion="center" />
            <MyDataGridRows width="50" value={item.tipoCambio} alineacion="center" />
          </Table.Row>
        )
      )}
    </Table.Body>
  ));

  const panes = (values: any, setFieldValue: any) => [
    {
      menuItem: 'Comprobantes con saldo', pane:
      {
        key: 'tab1',
        content:
          <FieldArray
            name="comprobantesSaldo"
            render={() => (
              <Segment basic className="tabla-container">
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Emp</Table.HeaderCell>
                      <Table.HeaderCell>Suc</Table.HeaderCell>
                      <Table.HeaderCell>SerieFolio</Table.HeaderCell>
                      <Table.HeaderCell>Emisión</Table.HeaderCell>
                      <Table.HeaderCell>Plazo</Table.HeaderCell>
                      <Table.HeaderCell>Vence</Table.HeaderCell>
                      <Table.HeaderCell>Vendedor</Table.HeaderCell>
                      <Table.HeaderCell>Mon</Table.HeaderCell>
                      <Table.HeaderCell>Total</Table.HeaderCell>
                      <Table.HeaderCell>Saldo</Table.HeaderCell>
                      <Table.HeaderCell>Abono</Table.HeaderCell>
                      <Table.HeaderCell>Observaciones</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>M/Pago</Table.HeaderCell>
                      <Table.HeaderCell>T.C.</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <ComprobanteRow values={values} setFieldValue={setFieldValue} ></ComprobanteRow>
                </Table>
              </Segment>
            )}
          />
      }
    },
    {
      menuItem: 'Pagos aplicados', pane:
      {
        key: 'tab2',
        content:
          <Segment basic className="tabla-container">
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Fecha</Table.HeaderCell>
                  <Table.HeaderCell>Folio</Table.HeaderCell>
                  <Table.HeaderCell>Concepto</Table.HeaderCell>
                  <Table.HeaderCell>Abonar</Table.HeaderCell>
                  <Table.HeaderCell>Abonado</Table.HeaderCell>
                  <Table.HeaderCell>Abono</Table.HeaderCell>
                  <Table.HeaderCell>Elaboró</Table.HeaderCell>
                  <Table.HeaderCell>Núm Operación</Table.HeaderCell>
                  <Table.HeaderCell>Banco</Table.HeaderCell>
                  <Table.HeaderCell>Forma de Pago</Table.HeaderCell>
                  <Table.HeaderCell>Cta Teso</Table.HeaderCell>
                  <Table.HeaderCell>Aplicado</Table.HeaderCell>
                  <Table.HeaderCell>Imp Pago</Table.HeaderCell>
                  <Table.HeaderCell>Imp Rec</Table.HeaderCell>
                  <Table.HeaderCell>Gen rec</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {pagosAplicadosById?.map(
                  (item: PagoAplicado, index: any) => (
                    <Table.Row key={index.valueOf()}>
                      <MyDataGridRows width="80px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
                      <MyDataGridRows width="60px" value={item?.folio} alineacion="center" />
                      <MyDataGridRows width="100px" value={item.conceptoDeCliente?.nombre} alineacion="center" />
                      <MyDataGridRows width="90px" value={formatDecimal(item.abonar, 2)} alineacion="right" />
                      <MyDataGridRows width="90px" value={formatDecimal(item.abonado, 2)} alineacion="right" />
                      <MyDataGridRows width="90px" value={formatDecimal(item.abono, 2)} alineacion="right" />
                      <MyDataGridRows width="100px" value={item?.vendedor?.nombre} alineacion="center" />
                      <MyDataGridRows width="100px" value={item.numOperacion} alineacion="center" />
                      <MyDataGridRows width="120px" value={"Chequera.Nombre"} alineacion="center" />
                      <MyDataGridRows width="150px" value={item.formaDePago?.nombre} alineacion="center" />
                      <MyDataGridRows width="150px" value={"Teso"} alineacion="center" />
                      <Table.Cell textAlign='center' >
                        <Button type="button" style={{
                          width: '50px',
                          background: "transparent"
                        }}><Icon color='green' name='check' />
                        </Button>
                      </Table.Cell>
                      <Table.Cell textAlign='center' border="3px">
                        <Button type="button" style={{
                          width: '50px',
                          background: "transparent"
                        }}><Icon color='green' name='file pdf' />
                        </Button>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Button type="button" style={{
                          width: '50px',
                          background: "transparent"
                        }}><Icon color='green' name='file pdf' />
                        </Button>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Button type="button" style={{
                          width: '50px',
                          background: "transparent"
                        }}><Icon color='green' name='envelope' />
                        </Button>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          name={item.id}
                          type="Button"
                          disabled={
                            loading &&
                            target ===
                            String(item.id)
                          }
                          loading={
                            loading &&
                            target ===
                            String(item.id)
                          }
                          onClick={(e) => {
                            openModal(
                              <DeleteModals
                                deleteConfirmed={deleteConfirmed}
                                deletedeclined={deletedeclined}
                                e={e.currentTarget.name.toString()}
                                id={item.id}
                                nombre={values.pagoAplicado ? values.pagoAplicado[index]?.folio : "Fila"}
                              />,
                              enumHeaderModals.CapturaPago
                            );
                          }}
                          floated="right"
                          content="Eliminar"
                          color="red"
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>

            </Table>
          </Segment>
      }
    },
    {
      menuItem: 'Movimientos Eliminados', pane:
      {
        key: 'tab3',
        content:
          <Segment basic className="tabla-container">
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Fecha Pago</Table.HeaderCell>
                  <Table.HeaderCell>Folio Pago</Table.HeaderCell>
                  <Table.HeaderCell>Forma Pago</Table.HeaderCell>
                  <Table.HeaderCell>Importe</Table.HeaderCell>
                  <Table.HeaderCell>Elaboró</Table.HeaderCell>
                  <Table.HeaderCell>Eliminó</Table.HeaderCell>
                  <Table.HeaderCell>Fecha eliminó</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {movimientoEliminadoById?.map(
                  (item: MovimientoEliminado, index: any) => (
                    <Table.Row key={index.valueOf()}>
                      <MyDataGridRows width="80px" value={moment(item?.fecha).format("DD/MM/YYYY")} alineacion="center" />
                      <MyDataGridRows width="60px" value={item?.folio} alineacion="center" />
                      <MyDataGridRows width="150px" value={item.formaDePago?.nombre} alineacion="center" />
                      <MyDataGridRows width="90px" value={formatDecimal(item.importe, 2)} alineacion="right" />
                      <MyDataGridRows width="100px" value={item?.elaboro?.nombre} alineacion="center" />
                      <MyDataGridRows width="100px" value={item?.elimino?.nombre} alineacion="center" />
                      <MyDataGridRows width="80px" value={moment(item?.fechaElimino).format("DD/MM/YYYY")} alineacion="center" />

                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Segment>
      }
    },
  ]

  const TabCapturaPago = memo(({ values, setFieldValue }: any) => <Tab panes={panes(values, setFieldValue)} renderActiveOnly={false} />)

  if (loadingInitial) return <LoadingComponent />

  const SearchFilters = () => {
    return (
      <Segment clearing>
        <div>
          <Label as="a" color="red" ribbon>
            Filtros
          </Label>
        </div>
        <div className="col15">
          <MySelectInput
            autoFocus={true}
            tabIndex={1}
            placeholder="Empresa"
            label="Empresa"
            name="empresaId"
            options={EmpresasByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputEmpresaOnChange}
            inputWidth="100px"
            menuWidth="300px"
          />
        </div>
        <div className="col15">
          <MySelectInput
            tabIndex={2}
            placeholder="Sucursal"
            label="Sucursal"
            name="sucursalId"
            options={sucursales}
            enterInput={enterInput}
            onChange={selectInputSucursalOnChange}
            inputWidth="100px"
            menuWidth="300px"
          />
        </div>
        <div className="col15">
          <MySelectInput
            tabIndex={3}
            placeholder="Cuenta"
            label="Cuenta"
            name="cuentaDeClienteId"
            options={cuentaClienteByDropdownByClave}
            enterInput={enterInput}
            onChange={selectInputCuentaOnChange}
            inputWidth="100px"
            menuWidth="300px"
          />
        </div>
        <div className="col30">
          <MySelectInput
            tabIndex={4}
            placeholder="Cliente"
            label="Cliente"
            name="clienteId"
            options={cliente}
            enterInput={enterInput}
            onChange={selectInputClienteOnChange}
            selectWithLabel={true}
            onBlur={selectInputClienteOnBluer}
            inputWidth="100px"
            menuWidth="300px"

          />
        </div>
        <div className="col100" />
        <Label as="a" color="blue" ribbon="right" style={{ display: 'flex' }}>
          <div className="col25">
            Límite de crédito: {formatDecimal("2222", 2)}
          </div>
          <div className="col25">
            Saldo: {formatDecimal("2222", 2)}
          </div>
          <div className="col25">
            Disponible: {formatDecimal("2222", 2)}
          </div>
          <div className="col25">
            Crédito Suspendido: {true ? "Si" : "No"}
          </div>
        </Label>
      </Segment>
    );
  };
  const PaymentDetalls = () => {
    return (
      <Segment clearing>
        <div>
          <Label as="a" color="red" ribbon >
            Detalle del abono
          </Label>
        </div>

        <div className="col15">
          <MyTextInput
            placeholder="Abonar"
            label="Abonar"
            name="abonar"
            ref={txtAbono}
            tabIndex={5}
            // onKeyDown={inputAbonarKeyDown}
            onBlur={inputAbonarOnBlur}
            enterInput={enterInput}
            tipoDato={enumTipoDato.Numerico}
            decimalDigits={"1,2"}
          />
        </div>
        <div className="col15">
          <MyTextInput
            disabled={true}
            placeholder="Abonado"
            label="Abonado"
            name="abonado"
            tabIndex={6}
            enterInput={enterInput}
          />
        </div>
        <div className="col15">
          <MyTextInput
            placeholder="Disponible"
            label="Disponible"
            name="disponible"
            tabIndex={7}
            enterInput={enterInput}
            disabled={true}
          />
        </div>
        <div className="col15">
          <MyTextInput
            placeholder="Núm Operación"
            label="Núm Operación"
            name="numOperacion"
            tabIndex={8}
            enterInput={enterInput}
            tipoDato={enumTipoDato.NumericoSinPunto}
          />
        </div>
        <div className="col20">
          <MySelectInput
            label="Concepto"
            placeholder="Concepto"
            name="conceptoDeClienteId"
            options={conceptosClientesByDropdown.filter((valor) => { return (valor.key > 100 || valor.key === 0) })}
            enterInput={enterInput}
            tabIndex={9}
            inputWidth="140px"
            menuWidth="300px"
          />
        </div>
        <div></div>
        <div className="col20">
          <MySelectInput
            label="Forma de pago"
            placeholder="Forma de pago"
            name="formaDePagoId"
            options={formasPagosByDropdown}
            enterInput={enterInput}
            tabIndex={10}
            inputWidth="140px"
            menuWidth="300px"
          />
        </div>
        <div className="col26">
          <MyCheckInput
            placeholder="Es Anticipo de otro día"
            name="anticipoDia"
            enterInput={enterInput}
            tabIndex={11}

          />
        </div>
        <div className="col15">
          Fecha del pago
          <MyDateInput
            placeholderText="Fecha del pago"
            name="fecha"
            dateFormat="dd/MM/yyyy"
            tabIndex={12}
          />
        </div>
        <div className="col15">
          Fecha deposito
          <MyDateInput
            placeholderText="Fecha depósito"
            name="fechaDeposito"
            dateFormat="dd/MM/yyyy"
            tabIndex={13}
          />
        </div>
        <SegmentGroup horizontal>
          <Segment clearing >
            <div>
              <Label as="a" color="blue" ribbon>
                Cuenta Beneficiaría
              </Label>
            </div>
            <div className="col50">
              <MySelectInput
                label="Banco"
                placeholder="Banco"
                name="bancoId"
                options={[]}
                enterInput={enterInput}
                tabIndex={14}
              />
            </div>
            <div className="col30">
              <MySelectInput
                label="Cuenta Ban"
                placeholder="Cuenta Ban"
                name="CuentaBanId"
                options={[]}
                enterInput={enterInput}
                tabIndex={15}
              />
            </div>
          </Segment>
          <Segment clearing>
            <div>
              <Label as="a" color="blue" ribbon>
                Cuenta Ordenante
              </Label>
            </div>
            <div className="col50">
              <MySelectInput
                label="Banco"
                placeholder="Banco"
                name="bancoId"
                options={[]}
                enterInput={enterInput}
                tabIndex={16}
              />
            </div>
            <div className="col30">
              <MySelectInput
                label="Cuenta Ban"
                placeholder="Cuenta Ban"
                name="CuentaBanId"
                options={[]}
                enterInput={enterInput}
                tabIndex={17}
              />
            </div>
          </Segment>
        </SegmentGroup>
      </Segment>
    )
  }
  return (
    <Segment clearing>
      <Formik
        initialValues={capturaPagosFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form className='ui form' >
            {values.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }
            <Header as='h2' icon='id badge outline' content='Captura de Pagos' color='blue' />
            {SearchFilters()}
            {PaymentDetalls()}
            <TabCapturaPago values={values} setFieldValue={setFieldValue} />
            <Segment clearing>
              <div style={{ display: 'flex', fontWeight: 'bold' }}>
                <div style={{ width: '33%' }}>
                  Total Facturas: {formatDecimal(values.totalFacturas, 2)}
                </div>
                <div style={{ width: '33%' }}>
                  Total Saldo: {formatDecimal(values.totalSaldo, 2)}
                </div>
                <div style={{ width: '33%' }}>
                  Total Abonado: {formatDecimal(values.abonado, 2)}
                </div>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/"
                loading={loading}
                tabIndex={18}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})