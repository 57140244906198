import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import ClienteForm from '../form/ClienteForm';

export default observer(function ClienteList() {
    const { modalStore, clienteStore, tabsStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { deleteCliente, clientesByDate, loading } = clienteStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteCliente(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Empresa</Table.HeaderCell>
                    <Table.HeaderCell>Sucursal</Table.HeaderCell>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {clientesByDate.map(cliente => (
                    <Table.Row key={cliente.id}>
                        <Table.Cell>{cliente.empresa?.clave}</Table.Cell>
                        <Table.Cell>{cliente.sucursal?.clave}</Table.Cell>
                        <Table.Cell>{cliente.clave}</Table.Cell>
                        <Table.Cell>{cliente.nombre}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Editar Cliente', <ClienteForm id={cliente.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button name={cliente.id}
                                loading={loading && target === String(cliente.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={cliente.id}
                                        />,
                                        enumHeaderModals.Cliente
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}</Table.Body>
        </Table>
    )
})