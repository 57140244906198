import { Empresa, IFile } from "../configuracion/Empresa";
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoDeCompra } from "./TipoDeCompra";
export interface SerieFolioCompra {
  id: number;
  clave: string;
  nombre: string;
  empresaId: number;
  empresa?: Empresa;
  sucursalId: number;
  sucursal?: Sucursal;
  tipoCompraId: number;
  tipoCompra?: TipoDeCompra;
  ultimoFolio?: number;
  logotipoEmpresaFileId?: number;
  logotipoEmpresaFile?: IFile;
  logotipoEmpresa?: null;
  logotipoTicketFileId?: number;
  logotipoTicketFile?: IFile;
  logotipoTicket?: null;
  lemaEmpresa?: string;
  fechaObservacionId?: number;
  fechaObservacion: FechaObservacion;

}
export class SerieFolioCompraFormValues implements SerieFolioCompra {
  id: number = 0;
  clave: string = "";
  nombre: string = "";
  tipoCompraId: number = 0;
  tipoCompra?: TipoDeCompra;
  empresaId: number = 0;
  empresa?: Empresa;
  sucursalId: number = 0;
  sucursal?: Sucursal;
  ultimoFolio?: number = 0;
  logotipoEmpresaFileId?: number;
  logotipoEmpresaFile?: IFile;
  logotipoEmpresa?: null;
  logotipoTicketFileId?: number;
  logotipoTicketFile?: IFile;
  logotipoTicket?: null;
  lemaEmpresa?: string;
  fechaObservacionId?: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();


  constructor(init?: SerieFolioCompra) {
    Object.assign(this, init);
  }
}

