import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import {
  Table,
  Segment,
  Grid,
  Header,
  Label,
  Button,
} from "semantic-ui-react";
import { FieldArray, Formik, Form } from "formik";
import { useStore } from "../../../../app/stores/store";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import BarraBotones from "../../../Component/BarraBotones";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { ControlListaDePrecioArregloFormValues } from "../../../../app/models/controlPrecio/ControlListaDePrecio";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import MyDateInput from "../../../../app/common/form/MyDateInput";
import { TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import { OnKeyDownProps } from "../../../../app/models/myProps";

export default observer(function ControlListaDePrecioForm() {
  const inputEmpresaRef = useRef<any>([]);
  const firstElement = 0;
  const [target, setTarget] = useState("");
  const initialIndexTable = 7;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable);
  const {
    listaDePrecioStore,
    controlListaDePrecioStore,
    empresaStore,
    sucursalStore,
    productoStore,
    modalStore,
    categoriaProductoStore,
  } = useStore();
  const { openModal, closeModal } = modalStore;
  const {
    loadPaginationControlesListasDePrecios,
    controlListaPrecioParams,
    empresaSucursal,
    loading,
    loadingInitial,
    deleteControlListaDePrecio,
    createControlListaDePrecio,
  } = controlListaDePrecioStore;
  const { ListaDePreciosByDropdown } = listaDePrecioStore;
  const [controlListaDePrecioFormValues, setControlListaDePrecioFormValues] =
    useState(new ControlListaDePrecioArregloFormValues());
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore;
  const {
    loadTableProductoByEmpresaSucursal,
    tablaproductosByEmpresaSucursalDropdown,
  } = productoStore;
  const {
    loadSucursales,
    sucursalRegistry,
  } = sucursalStore;
  const { loadCategoriasProductos } =
    categoriaProductoStore;
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    deleteControlListaDePrecio(id).then(() => {
      arrayHelpers.remove(index);
    });
    setTarget(e);
    closeModal();
  }
  function deletedeclined() {
    closeModal();
  }
  const onKeyDownSelectPrecio = ({ arrayHelpers, event, values }: OnKeyDownProps) => {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    arrayHelpers.push({
      id: 0,
      empresaId: 0,
      sucursalId: 0,
      costo: 0,
      precio: 0,
      productoId: 0,
    });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
    setTimeout(() => {
      if (inputEmpresaRef.current) {
        inputEmpresaRef.current.Focus();
      }
    }, 0);

  }

  function OnChangeSelectProducto(idSelectedSucursal: number, setfieldAny: any, index?: any, values?: ControlListaDePrecioArregloFormValues
  ) {
    if (idSelectedSucursal !== 0) {
      let repetido = 0;
      values?.controlListaDePrecio?.forEach((vale) => {
        if (empresaSucursal.empresaId === vale.empresaId && empresaSucursal.sucursalId === vale.sucursalId && idSelectedSucursal === vale.productoId) {
          repetido = 1;
        }
      });
      if (repetido === 0) {
        if (tablaproductosByEmpresaSucursalDropdown.values()) {
          tablaproductosByEmpresaSucursalDropdown.forEach((producto) => {
            if (idSelectedSucursal)
              if (idSelectedSucursal === producto.id)
                setfieldAny(`controlListaDePrecio[${index}].producto.nombre`, producto.nombre);
          });
        }
        return true;
      }
      else {
        toast.info("producto ya esta dado de alta")
        return false;
      }
    }
    return true;
  }
  function OnChangeSelectSucursal(
    idSelectedSucursal: number,
    setfieldAny: any,
    index?: any
  ) {
    empresaSucursal.sucursalId = idSelectedSucursal;
    void loadTableProductoByEmpresaSucursal(empresaSucursal);
    setfieldAny(`controlListaDePrecio[${index}].productoId`, 0);
  }
  function onChangeSelectEmpresa(
    idSelectedEmpresa: number,
    setfieldAny: any,
    index?: any
  ) {
    //recibir el index
    // void loadTableSucursalByIdEmpresa(idSelectedEmpresa, index);
    empresaSucursal.empresaId = idSelectedEmpresa;
    setfieldAny(`controlListaDePrecio[${index}].sucursalId`, 0);
    setfieldAny(`controlListaDePrecio[${index}].productoId`, 0);
    empresaSucursal.sucursalId = 0;
  }
  const getOptionsSucursal = (
    index: any,
    values: ControlListaDePrecioArregloFormValues
  ) => {
    let EmpresasArreg = [new TipoDropDownValues()];
    let empresasOpt = new TipoDropDownValues();
    if (values.controlListaDePrecio)
      if (values.controlListaDePrecio[index].empresaId)
        if (sucursalRegistry.values()) {
          sucursalRegistry.forEach((sucursal) => {
            if (values.controlListaDePrecio)
              if (
                values.controlListaDePrecio[index].empresaId ===
                sucursal.empresaId
              ) {
                empresasOpt.key = sucursal.id;
                empresasOpt.text = sucursal.nombre;
                empresasOpt.value = sucursal.id;
                empresasOpt.content = sucursal.nombre;
                EmpresasArreg.push(empresasOpt);
                empresasOpt = new TipoDropDownValues();
              }
          });
        }
    return EmpresasArreg;
  };
  const getOptionsProducto = (
    index: any,
    values: ControlListaDePrecioArregloFormValues
  ) => {
    let productArreg = [new TipoDropDownValues()];
    let productOpt = new TipoDropDownValues();
    if (values.controlListaDePrecio) {
      if (values.controlListaDePrecio[index].sucursalId) {
        tablaproductosByEmpresaSucursalDropdown.forEach((producto) => {
          if (values.controlListaDePrecio)
            if (
              producto.sucursalId ===
              values.controlListaDePrecio[index].sucursalId &&
              producto.empresaId ===
              values.controlListaDePrecio[index].empresaId
            ) {
              productOpt.key = producto.id;
              productOpt.text = producto.clave;
              productOpt.value = producto.id;
              productOpt.content = producto.clave;
              productArreg.push(productOpt);
              productOpt = new TipoDropDownValues();
            }
        });
      }
    }
    return productArreg;
  };
  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };
  useEffect(() => {
    void loadCategoriasProductos();
    void listaDePrecioStore.getListasDePrecios()
  }, [loadCategoriasProductos,listaDePrecioStore]);

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  const validationSchema = Yup.object({
    listaDePrecioId: Yup.number()
      .min(1, "Favor de seleccionar lista de precio.")
      .required("La lista de precio es requerido."),
    controlListaDePrecio: Yup.array().of(
      Yup.object().shape({
        empresaId: Yup.number()
          .min(1, "Favor de seleccionar una Empresa.")
          .required("Empresa es requerido."),
        sucursalId: Yup.number()
          .min(1, "Favor de seleccionar una Sucursal.")
          .required("Sucursal es requerido."),
        productoId: Yup.number()
          .min(1, "Favor de seleccionar un producto.")
          .required("Producto es requerido."),
        costo: Yup.number()
          .positive("Costo debe de ser mayor a 0")
          .required("Costo es requerido"),
        precio: Yup.number()
          .positive("Precio debe de ser mayor a 0")
          .required("Precio es requerido"), // these constraints take precedence
      })
    ),
    // .required("Es requerido una fila"), // these constraints are shown if and only if inner constraints are satisfied
    //  .min(3, "Minimum of 3 friends"),
  });

  useEffect(() => {
    void loadSucursales();
    void loadEntityEmpresas();
  }, [loadSucursales, loadEntityEmpresas]);


  function BuscarArticulos(setFieldValue: any) {
    if (controlListaPrecioParams.listaDePrecioId) {
      controlListaPrecioParams.clave = 0;
      controlListaPrecioParams.nombre = "";
      controlListaPrecioParams.sort = "nombre";
      controlListaPrecioParams.pageIndex = 1;
      controlListaPrecioParams.pageNumber = 1;
      controlListaPrecioParams.pageSize = 12;
      controlListaPrecioParams.empresaId = 0;
      controlListaPrecioParams.sucursalId = 0;
      controlListaPrecioParams.tipoVentaId = 0;
      controlListaPrecioParams.serieId = 0;
      // controlListaPrecioParams.listaDePrecioId = controlListaPrecioParams.id;
      void loadPaginationControlesListasDePrecios(
        controlListaPrecioParams
      ).then((control) => {
        if (control) {
          control.forEach((controlListaPre) => {
            empresaSucursal.empresaId = controlListaPre.empresaId;
            empresaSucursal.sucursalId = controlListaPre.sucursalId;
            void loadTableProductoByEmpresaSucursal(empresaSucursal);
          }
          )
          void loadTableProductoByEmpresaSucursal(empresaSucursal);
          setLastElementTable(
            (prevLastElementTable) =>
              prevLastElementTable + numberControlsTable * control.length
          );
        }
        setFieldValue("controlListaDePrecio", control);
      }).catch(e => console.warn(e))
    }
  }
  function handleSubmit(controlListaDePrecioFormik: ControlListaDePrecioArregloFormValues) {
    const controlListaDePrecioCopia = JSON.parse(JSON.stringify(controlListaDePrecioFormik));
    if (controlListaDePrecioCopia.controlListaDePrecio)
      if (
        controlListaPrecioParams.listaDePrecioId?.toString() !== "0" &&
        controlListaPrecioParams.listaDePrecioId !== null &&
        controlListaPrecioParams.listaDePrecioId !== undefined
      ) {
        controlListaDePrecioCopia.controlListaDePrecio?.forEach((element: any) => {
          element.empresa = undefined;
          element.producto = undefined;
          element.sucursal = undefined;
          element.listaDePrecioId = controlListaPrecioParams.listaDePrecioId!;
        });
        void createControlListaDePrecio(controlListaDePrecioCopia);
      } else {
        toast.info("Favor de selecionar lista de precio");
      }
  }
  function onChangeSelectListaPrecios(idSelectedLista: number) {
    controlListaPrecioParams.listaDePrecioId = idSelectedLista;
  }
  function inputProveedorOnChange(name: string) {
    controlListaPrecioParams.proveedorName = name;
  }
  function inputCategoriaOnChange(name: string) {
    controlListaPrecioParams.categoriaName = name;
  }
  function inputMonedaOnChange(name: string) {
    controlListaPrecioParams.monedaName = name;
  }
  function inputFechaModificacionOnChange(fecha: any) {
    controlListaPrecioParams.fechaModificacion = fecha;
  }

  if (loadingInitial)
    return <LoadingComponent />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={controlListaDePrecioFormValues}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form className="ui form">
            <Segment>
              <Grid columns="equal">
                <Grid.Row>
                  <Header
                    as="h2"
                    icon="compass"
                    content="Control de Listas de precios"
                    color="blue"
                  />
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Label as="a" color="red" ribbon>
                      Identificación de Listas de Precios
                    </Label>
                    <div></div>
                    <MySelectInput
                      placeholder={"Seleccionar"}
                      label="Lista de Precios"
                      name="listaDePrecioId"
                      options={ListaDePreciosByDropdown}
                      tabIndex={1}
                      enterInput={enterInput}
                      onChange={onChangeSelectListaPrecios}
                      autoFocus={true}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Segment clearing>
                  <Label as="a" color="red" ribbon>
                    Filtros
                  </Label>
                  <div className="col100"> </div>
                  <div className="col60">
                    <MyTextInput
                      tabIndex={2}
                      placeholder="Proveedor Asignado"
                      label="Proveedor Asignado"
                      name="proveedorId"
                      enterInput={enterInput}
                      onChange={inputProveedorOnChange}
                    />
                  </div>
                  <div className="col30Block">
                    <MyTextInput
                      tabIndex={3}
                      placeholder="Categoría"
                      label="Categoría"
                      name="categoriaProductoId"
                      enterInput={enterInput}
                      onChange={inputCategoriaOnChange}
                    />
                  </div>
                  <div className="col30Block">
                    <MyTextInput
                      tabIndex={4}
                      placeholder="Moneda"
                      label="Moneda"
                      name="Moneda"
                      enterInput={enterInput}
                      onChange={inputMonedaOnChange}
                    />
                  </div>
                  Alta
                  <MyDateInput
                    ///disabled={true}
                    placeholderText="Fecha de Modif."
                    name="FechaModificacion"
                    dateFormat="dd/MM/yyyy"
                    onChangeFecha={inputFechaModificacionOnChange}
                    tabIndex={5}
                  />
                  <Grid.Row>
                    <Grid.Column></Grid.Column>
                  </Grid.Row>
                </Segment>
                <div className="col100">
                  <Label as="a" color="red" ribbon>
                    Identificación de Control Listas de Precios
                  </Label>
                </div>
                <br />
                <div className="col100">
                  <Button
                    tabIndex={6}
                    type="button"
                    name="buscar"
                    //loading={loading && target === String(empresa.id)}
                    onClick={() => BuscarArticulos(setFieldValue)}
                    floated="left"
                    content="Buscar"
                    color="blue"
                  />
                </div>
                <br />
                <div className="col100">
                  <FieldArray
                    name="controlListaDePrecio"
                    render={(arrayHelpers) => (
                      /////
                      <Segment basic className="tabla-container">
                        <Table celled>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Empresa</Table.HeaderCell>
                              <Table.HeaderCell>Sucursal</Table.HeaderCell>
                              <Table.HeaderCell>Producto</Table.HeaderCell>
                              <Table.HeaderCell>Descripción</Table.HeaderCell>
                              <Table.HeaderCell>Costo</Table.HeaderCell>
                              <Table.HeaderCell>Precio</Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {/* {values.friends && values.friends.length > 0 */}
                            {values.controlListaDePrecio?.map(
                              (controlListaDePrecios, index) => (
                                <Table.Row key={index.valueOf()}>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      // autoFocus={true}
                                      tabIndex={getIndexTable(index, 1)}
                                      placeholder="Empresa"
                                      name={`controlListaDePrecio[${index}].empresaId`}
                                      options={EmpresasByDropdown}
                                      enterInput={enterInput}
                                      onChange={onChangeSelectEmpresa}
                                      index={index}
                                      ref={inputEmpresaRef}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      // autoFocus={true}
                                      tabIndex={getIndexTable(index, 2)}
                                      placeholder="Sucursal"
                                      name={`controlListaDePrecio[${index}].sucursalId`}
                                      options={getOptionsSucursal(
                                        index,
                                        values
                                      )}
                                      enterInput={enterInput}
                                      onChange={OnChangeSelectSucursal}
                                      index={index}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MySelectInput
                                      // autoFocus={true}
                                      tabIndex={getIndexTable(index, 3)}
                                      placeholder="Producto"
                                      name={`controlListaDePrecio[${index}].productoId`}
                                      options={getOptionsProducto(
                                        index,
                                        values
                                      )}
                                      enterInput={enterInput}
                                      onChangeTable={OnChangeSelectProducto}
                                      index={index}
                                    // onChange={idSelect}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 4)}
                                      className="widthTextTable heighttext input"
                                      placeholder="Descripción"
                                      name={`controlListaDePrecio[${index}].producto.nombre`}
                                      disabled={true}
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 5)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Costo"
                                      name={`controlListaDePrecio[${index}].costo`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {" "}
                                    <MyTextInput
                                      tabIndex={getIndexTable(index, 6)}
                                      className="widthTextTable heighttext input "
                                      placeholder="Precio"
                                      name={`controlListaDePrecio[${index}].precio`}
                                      pattern="^([0-9]{1,16}(\.[0-9]{0,6})?)$"
                                      enterInput={enterInput}
                                      onKeyDownProps=
                                      {index === values.controlListaDePrecio?.length! - 1 && isValid ? onKeyDownSelectPrecio : undefined}
                                      arrayHelpers={arrayHelpers}
                                    />
                                  </Table.Cell>

                                  {/* <Table.Cell>
                                    <Button
                                      tabIndex={getIndexTable(index, 7)}
                                      disabled={!isValid || isSubmitting}
                                      primary
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.push({
                                          id: 0,
                                          empresaId: 0,
                                          sucursalId: 0,
                                          costo: 0,
                                          precio: 0,
                                          productoId: 0,
                                        });
                                        setLastElementTable(
                                          (prevLastElementTable) =>
                                            prevLastElementTable + 8
                                        );
                                      }}
                                      content="Nuevo"
                                    />
                                  </Table.Cell> */}

                                  <Table.Cell>
                                    <Button
                                      tabIndex={getIndexTable(index, 7)}
                                      name={controlListaDePrecios.id}
                                      type="Button"
                                      disabled={
                                        loading &&
                                        target ===
                                        String(controlListaDePrecios.id)
                                      }
                                      loading={
                                        loading &&
                                        target ===
                                        String(controlListaDePrecios.id)
                                      }
                                      onClick={(e) => {
                                        openModal(
                                          <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={controlListaDePrecios.id}
                                            nombre={values.controlListaDePrecio ? values.controlListaDePrecio[index].producto?.nombre : "Fila"}
                                            arrayHelpers={arrayHelpers}
                                            index={index}
                                          />,
                                          enumHeaderModals.controlListaPrecio
                                        );
                                        //  arrayHelpers.remove(index);
                                      }}
                                      floated="right"
                                      content="Eliminar"
                                      color="red"
                                    />

                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                          <Table.Footer>
                            <Table.Row>
                              <Table.Cell>
                                {/* {pagination?.count > 0 ? (
                                  <Pagination
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    onPageChange={(e, y) =>
                                      handleCheckboxChange(
                                        y.activePage,
                                        setFieldValue
                                      )
                                    }
                                    defaultActivePage={
                                      controlListaPrecioParams.pageIndex
                                    }
                                    totalPages={
                                      controlListaPrecioParams.pageSize
                                        ? Math.ceil(
                                          pagination.count /
                                          controlListaPrecioParams.pageSize
                                        )
                                        : 0
                                    }
                                  />
                                ) : 
                                 (*/}
                                <Button
                                  tabIndex={7}
                                  disabled={!isValid || isSubmitting}
                                  primary
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      id: 0,
                                      empresaId: 0,
                                      sucursalId: 0,
                                      costo: 0,
                                      precio: 0,
                                      productoId: 0,
                                    });
                                    setLastElementTable(
                                      (prevLastElementTable) =>
                                        prevLastElementTable + numberControlsTable
                                    );
                                  }}
                                  content="Nuevo"
                                />
                                {/* )} */}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Footer>
                        </Table>
                      </Segment>
                      /////
                    )}
                  />
                </div>
                <div className="cols"></div>
              </Grid>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/"
                loading={loading}
                tabIndex={lastElementTable + 1}
                firstElement={firstElement}
                lastElement={lastElementTable + 3}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});
