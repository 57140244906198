import {
  FechaObservacion,
  FechaObservacionValues,
} from "../configuracion/FechaObservacion";
import { ConceptoFinanzaTipo } from "./ConceptoFinanzaTipo";
export interface ConceptoFinanza {
  id: number;
  clave: number;
  nombre: string;
  conceptoFinanzaTipoId: number;
  tipo?: ConceptoFinanzaTipo;
  fechaObservacionId: number;
  fechaObservacion: FechaObservacion;
}
export class ConceptoFinanzaFormValues implements ConceptoFinanza {
  id: number = 0;
  clave: number = 0;
  nombre: string = "";
  conceptoFinanzaTipoId: number = 0;
  tipo?: ConceptoFinanzaTipo;
  fechaObservacionId: number = 0;
  fechaObservacion: FechaObservacion = new FechaObservacionValues();
  demo: boolean = false;

  constructor(conceptoFinanza?: ConceptoFinanza) {
    Object.assign(this, conceptoFinanza);
  }
}
