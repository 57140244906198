import { memo } from "react";
import { TableCell } from "semantic-ui-react";
interface Props {
  resize?: any;
  alineacion?: any;
  width?: any;
  backgroundColor?: any;
  color?: any;
  indiceColumnaActual?: any;
  className?: string;
  value?: any;
  height?: any;
}
function MyDataGridRows(props: Readonly<Props>) {
  const dibujarColumna = () => {
    const EsRenglon = true;
    if (EsRenglon) {
      return (
        <TableCell>
          <div
            style={{
              resize: props.resize ? "both" : "none",
              textAlign: props.alineacion ? props.alineacion : 'left',
              width: props.width ? props.width : '',
              maxWidth: props.width ? props.width : '',
              backgroundColor: props.backgroundColor,
              color: props.color,
              height: props.height ? props.height : "1px"
            }}
            key={'celda-' + props.indiceColumnaActual}
            className={props.className}
          >  {
              props.value
            }
          </div>
          <div className="div-corner">&nbsp;</div>
        </TableCell>
      )
    }
  }
  return <>
    {dibujarColumna()}
  </>
}
export default memo(MyDataGridRows);