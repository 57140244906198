export interface SaldoCliente {
  id: number;
  fechaFin?: Date;
  nombre: string;
  estado: string;
  tipoReporte: number;
  ordenado: string;
  empresa: string;
  sucursal: string;
  tipoMovimiento: string;
  serie: string;
  folios: string;
  cliente?: string;
  cuentaCliente?: string;
  conceptoCliente?: string;
}
export class SaldoClienteFormValues implements SaldoCliente {
  id: number = 0;
  fechaFin?: Date;
  nombre: string = "";
  estado: string = "";
  tipoReporte: number = 0;
  ordenado: string = "";
  empresa: string = "";
  sucursal: string = "";
  tipoMovimiento: string = "";
  serie: string = "";
  folios: string = "";
  cliente?: string = "";
  cuentaCliente?: string = "";
  conceptoCliente?: string = "";
  constructor(init?: SaldoCliente) {
    Object.assign(this, init);
  }
}
