import { Entity, Entity2 } from "../../models/Entity";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
interface IProps<T> extends Entity {
  item?: T
}
interface IProps2<T> extends Entity2 {
  item?: T
}
export const entityByDropdown = <T>(props: IProps<T>[], clave?: boolean) => {
  let EmpresasArreg = [new TipoDropDownValues()];
  let empresasOpt = new TipoDropDownValues();
  const emps = Array.from(props.values()).sort((a, b) => {
    if (a.nombre > b.nombre) return 1;
    if (a.nombre < b.nombre) return -1;
    return 0;
  });
  emps.forEach((element) => {
    empresasOpt.key = element.clave;
    empresasOpt.text = clave ? element.clave.toString() : element.nombre;
    empresasOpt.value = element.id;
    empresasOpt.content = element.clave.toString() + " " + element.nombre;
    EmpresasArreg.push(empresasOpt);
    empresasOpt = new TipoDropDownValues();
  });
  return EmpresasArreg;
};
export const entity2ByDropdown = <T>(props: IProps2<T>[], clave?: boolean) => {
  let EmpresasArreg = [new TipoDropDownValues()];
  let empresasOpt = new TipoDropDownValues();
  const emps = Array.from(props.values()).sort((a, b) => {
    if (a.nombre > b.nombre) return 1;
    if (a.nombre < b.nombre) return -1;
    return 0;
  });
  emps.forEach((element) => {
    empresasOpt.key = element.id;
    empresasOpt.text = clave ? element.clave.toString() : element.nombre;
    empresasOpt.value = element.id;
    empresasOpt.content = element.clave.toString() + " " + element.nombre;
    EmpresasArreg.push(empresasOpt);
    empresasOpt = new TipoDropDownValues();
  });
  return EmpresasArreg;
};
