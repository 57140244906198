import { Button, Grid, Segment } from "semantic-ui-react";
import React, { useRef } from "react";
interface IProps {
  id: number;
  e: string;
  nombre?: string;
  deleteConfirmed: (e: string, id: number, arrayHelpers: any, index: any) => void;
  deletedeclined: () => void;
  arrayHelpers?: any;
  index?: any;
}

const DeleteModals: React.FC<IProps> = ({
  id,
  nombre,
  e,
  deleteConfirmed,
  deletedeclined,
  arrayHelpers,
  index
}) => {
  const buttonNoRef = useRef<any>();
  return (
    <Segment>
      {/*  */}

      <Grid centered columns={1}>
        <Grid.Column>
          <p>
            ¿Estás seguro de que quieres eliminar <b>"{nombre}"</b>?
          </p>
        </Grid.Column>

        <Grid.Row columns={4}>
          <Grid.Column floated="right">
            <Button negative autoFocus onClick={() => deletedeclined()} ref={buttonNoRef}>
              No
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button positive onClick={() => deleteConfirmed(e, id, arrayHelpers, index)}
              onKeyDown={(e: any) => {
                if (e.key === "Tab" && !e.shiftKey) {
                  e.preventDefault();  // Prevenir el comportamiento predeterminado
                  if (buttonNoRef.current) {
                    buttonNoRef.current.focus();
                  }
                }
              }}
            >
              Si
            </Button>
          </Grid.Column>
        </Grid.Row>
        {/* </Grid.Row> */}
      </Grid>
    </Segment>
  );
};

export default DeleteModals;
