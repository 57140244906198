import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import { Sucursal } from "../../models/configuracion/Sucursal";
import { history } from "../../..";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { TipoDropdown } from "../../models/configuracion/TipoDropdown";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { setDate } from "../../common/util/util";

export default class SucursalStore {
  sucursalRegistry = new Map<number, Sucursal>();
  sucursalByEmpresaRegistry = new Map<number, Sucursal>();
  //tableSucursalByEmpresaRegistry = new Map<number, Sucursal>();
  selectedSucursal: Sucursal | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  tableDrowpon: TipoDropdown[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  get sucursalById() {
    return Array.from(this.sucursalRegistry.values()).sort(
      (a, b) => a.clave - b.clave
    );
  }

  obtenerSiguienteClave = async (filtros: PaginationSpecParams) => {
    try {
      const clave = await agent.Sucursales.obtenerClave(filtros);
      return clave;
    } catch (error) {
      console.log(error);
    }
  };

  get sucursalesByDate() {
    return Array.from(this.sucursalRegistry.values()).sort((a) => a.id);
  }
  get SucursalesByDropdown() {
    return entityByDropdown<Sucursal>(
      Array.from(this.sucursalRegistry.values())
    );
  }
  loadSucursales = async () => {
    this.setLoadingInitial(true);
    try {
      const sucursales = await agent.Sucursales.list();
      sucursales.forEach((sucursal) => {
        setDate<Sucursal>(sucursal);
        this.setSucursal(sucursal);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  fetchSucursales = async (empresaId: number) => {
    try {
      const sucursales = await agent.Sucursales.listByEmpresaSiganl(empresaId);
      return entityByDropdown<Sucursal>(Array.from(sucursales));
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      console.error("Failed to load sucursales", error);
    }
  };
  fetchSucursalesSinEmpresa = async () => {
    try {
      const response = await agent.Sucursales.list();
      return response;
    } catch (error) {
      console.error("Error al buscar sucursales", error);
    }

  };
  loadSucursalByIdEmpresa = async (id: number) => {
    if (id) {
      try {
        this.sucursalByEmpresaRegistry.clear();
        const sucursales = await agent.Sucursales.listByEmpresa(id);
        sucursales.forEach((sucursal) => {
          this.setSucursalbyEmpresa(sucursal);
        });
        return sucursales;
      } catch (error) {
        console.log(error);
      }
    } else {
      this.sucursalByEmpresaRegistry.clear();
    }
  };

  // get sucursalesTipoByDropdown() {
  //   return entityByDropdown<Sucursal>(
  //     Array.from(this.sucursalRegistry.values())
  //   );
  // }
  get sucursalesByEmpresaByDropdown() {
    return entityByDropdown<Sucursal>(
      Array.from(this.sucursalByEmpresaRegistry.values())
    );
  }
  get sucursalesByEmpresaByDropdownByClave() {
    return entityByDropdown<Sucursal>(
      Array.from(this.sucursalByEmpresaRegistry.values()), true
    );
  }

  getSucursales = async () => {
    this.setLoadingInitial(true);
    const sucursales = await agent.Sucursales.list();
    try {
      sucursales.forEach((sucursal) => {
        setDate<Sucursal>(sucursal);
        this.setSucursal(sucursal);
      });
      this.setLoadingInitial(false);
      return sucursales;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return sucursales;
    }
  };


  loadSucursal = async (id: number) => {
    let sucursal = this.getSucursal(id);
    if (sucursal) {
      this.selectedSucursal = sucursal;
      return sucursal;
    } else {
      this.loadingInitial = true;
      try {
        sucursal = await agent.Sucursales.details(id);
        setDate<Sucursal>(sucursal);
        this.setSucursal(sucursal);
        this.selectedSucursal = sucursal;
        this.setLoadingInitial(false);
        return sucursal;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setSucursal = (sucursal: Sucursal) => {
    this.sucursalRegistry.set(sucursal.id, sucursal);
  };
  private setSucursalbyEmpresa = (sucursal: Sucursal) => {
    this.sucursalByEmpresaRegistry.set(sucursal.id, sucursal);
  };

  private getSucursal = (id: number) => {
    return this.sucursalRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createSucursal = async (sucursal: Sucursal) => {
    this.loading = true;
    try {
      await agent.Sucursales.create(sucursal);
      runInAction(() => {
        this.loadSucursales();
        this.selectedSucursal = sucursal;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Sucursal creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateSucursal = async (sucursal: Sucursal) => {
    this.loading = true;
    try {
      await agent.Sucursales.update(sucursal);
      runInAction(() => {
        this.loadSucursales();
        this.selectedSucursal = sucursal;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Sucursal Actualizada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteSucursal = async (id: number) => {
    this.loading = true;
    try {
      await agent.Sucursales.delete(id);
      runInAction(() => {
        this.loading = false;
      });
      toast.success("Sucursal eliminada con éxito");
    } catch (error) {
      console.log("Error", error)
      toast.error("Problema al eliminar la Sucursal");
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  public setsucursalByEmpresaRegistryClear = () => {
    this.sucursalByEmpresaRegistry.clear();
  }

}
