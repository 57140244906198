import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReporteAlmacen } from "../../models/inventario/ReporteAlmacen";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";

export default class ReporteAlmacenesStore {
    reporteAlmacenRegistry = new Map<number, ReporteAlmacen>();
    selectedReporteAlmacen: ReporteAlmacen | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    imprimirKardex = async (filtros: PaginationSpecParams) => {
        this.loading = true;
        try {
            const namePdf = await agent.ReportesAlmacenes.imprimirReporteKardex(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    const blob = new Blob([namePdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                    // Limpiar el objeto URL después de un tiempo
                    // setTimeout(() => {
                    //     URL.revokeObjectURL(url);
                    // }, 1000);
                }
                this.loading = false;
            });
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };
    imprimirExistencia = async (filtros: ReporteAlmacen) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesAlmacenes.imprimirReporteExistencia(filtros);
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
}