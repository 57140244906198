import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import TipoVentaForm from '../form/TipoVentaForm';

export default observer(function TipoVentaList() {
  ///modal
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  //final modal
  const { tipoVentaStore } = useStore();
  const { deleteTipoVenta, tiposVentasByDate, loading } = tipoVentaStore;
  const [target, setTarget] = useState('');
  function deleteConfirmed(e: string, id: number) {
    deleteTipoVenta(id);
    setTarget(e);
    closeModal();
  }
  function deletedeclined() {
    closeModal();
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tiposVentasByDate.map(tipoVenta => (
          <Table.Row key={tipoVenta.clave}>
            <Table.Cell>{tipoVenta.clave}</Table.Cell>
            <Table.Cell>{tipoVenta.nombre}</Table.Cell>
            <Table.Cell>{tipoVenta.tipoVentaTipo?.nombre}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => tabsStore.addTab('Edit Tipo de Venta', <TipoVentaForm id={tipoVenta.id} />)}
                floated='right' content='Editar' color='blue' />
              <Button
                name={tipoVenta.id}
                loading={loading && target === String(tipoVenta.id)}
                onClick={(e) =>
                  openModal(
                    <DeleteModals
                      deleteConfirmed={deleteConfirmed}
                      deletedeclined={deletedeclined}
                      e={e.currentTarget.name.toString()}
                      id={tipoVenta.id}
                    />,
                    enumHeaderModals.TipoVenta
                  )
                }
                floated='right'
                content='Eliminar'
                color='red'
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})