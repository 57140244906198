import { observer } from "mobx-react-lite";
import { SyntheticEvent } from "react";
import { useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import EmpresaForm from "../form/EmpresaForm";

export default observer(function EmpresaList() {
  const { tabsStore } = useStore();
  const { empresaStore } = useStore();
  const { deleteEmpresa, empresasByDate, loading } = empresaStore;
  const [target, setTarget] = useState("");

  function handleEmpresaDelete(
    e: SyntheticEvent<HTMLButtonElement>,
    id: number
  ) {
    setTarget(e.currentTarget.name);
    deleteEmpresa(id);
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>RFC</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {empresasByDate.map((empresa) => (
          <Table.Row key={empresa.id}>
            <Table.Cell>{empresa.clave}</Table.Cell>
            <Table.Cell>{empresa.nombre}</Table.Cell>
            <Table.Cell>{empresa.rfc}</Table.Cell>

            <Table.Cell>
              <Button
                onClick={() => tabsStore.addTab('Editar Empresa', <EmpresaForm id={empresa.id} />)}
                floated="right" content="Editar" color="blue"
              />
              <Button
                name={empresa.id}
                loading={loading && target === String(empresa.id)}
                onClick={(e) => handleEmpresaDelete(e, empresa.id)}
                floated="right"
                content="Eliminar"
                color="red"
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
});