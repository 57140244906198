import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { ConceptoDeInventarioTipo } from "./ConceptoDeInventarioTipo";

export interface ConceptoDeInventario {
    id: number;
    clave:number;
    nombre: string;
    conceptoDeInventarioTipoId:number;
    conceptoDeInventarioTipo?:ConceptoDeInventarioTipo;
    fechaObservacionId: number;
    nombreCorto: string;
    fechaObservacion: FechaObservacion;
}

export interface IConceptoDeInventarioFormValues extends Partial<ConceptoDeInventario> {
    selectedIdTipo: any;
}

export class ConceptoDeInventarioFormValues implements IConceptoDeInventarioFormValues {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    nombreCorto: string= "";
    conceptoDeInventarioTipoId:number= 0;
    conceptoDeInventarioTipo?:ConceptoDeInventarioTipo;
    selectedIdTipo: undefined;
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    
    constructor(init?: ConceptoDeInventario) {
        Object.assign(this, init);
    }
}