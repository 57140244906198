import { observer } from "mobx-react-lite";
import MyTextInput from "../../app/common/form/MyTextInput";
import { FechaObservacionValues } from "../../app/models/configuracion/FechaObservacion";
import { Button, Segment, Icon } from "semantic-ui-react";
import { Formik, Form } from "formik";
import MyDateInput from "../../app/common/form/MyDateInput";
import {
  enterInputs,
  tabuladorButtons,
} from "../../app/common/funciones/tabuladorEnter";
interface Props {
  fechaObservacion: FechaObservacionValues;
  closeModals: () => void;
  setFechaObservacion: (setFO: FechaObservacionValues) => void;
  tabIndex: number;
}

export default observer(function FechaObservaciones(props: Props) {
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, props.tabIndex + 2, props.tabIndex + 6);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, props.tabIndex + 2, props.tabIndex + 6);
  };
  function focusPreviousScreen() {
    //regreso focus a boton fecha observacion
    let next = document.getElementById("fechaObservacion");
    next?.focus();
  }
  function handleSubmit(fo: FechaObservacionValues) {
    props.setFechaObservacion(fo);
    props.closeModals();
    focusPreviousScreen();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
    focusPreviousScreen();

  }

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.fechaObservacion}
    >
      {({ isValid, dirty }) => (
        <Form className="ui form">
          Alta
          <MyDateInput
            disabled={true}
            placeholderText="Alta"
            name="alta"
            dateFormat="dd/MM/yyyy"
            tabIndex={props.tabIndex + 1}
          />
          Cambio
          <MyDateInput
            disabled={true}
            placeholderText="Cambio"
            name="modificacion"
            dateFormat="dd/MM/yyyy"
            tabIndex={props.tabIndex + 2}
          />
          <MyTextInput
            placeholder="Observaciones"
            label="Observaciones"
            name="observaciones"
            tabIndex={props.tabIndex + 3}
            enterInput={enterInput}
            autoFocus={true}
          />
          Baja
          <MyDateInput
            placeholderText="Baja"
            name="baja"
            dateFormat="dd/MM/yyyy"
            enterInput={enterInput}
            tabIndex={props.tabIndex + 4}
          />
          <Segment>
            <Button.Group>
              <Button
                disabled={!dirty || !isValid}
                tabIndex={props.tabIndex + 5}
                type="submit"
              >
                <Icon name="save" />
                Guardar
              </Button>
              <Button
                type="button"
                tabIndex={props.tabIndex + 6}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Regresar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  );
});
