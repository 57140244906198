import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import ProveedorList from './ProveedorList';
import ProveedorForm from '../form/ProveedorForm';

export default observer(function ProveedorDashboard() {
  const { proveedorStore, tabsStore } = useStore();
  useEffect(() => {
    proveedorStore.loadProveedores();
  }, [proveedorStore]);

  if (proveedorStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' icon='money' content='Proveedores' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}  >
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Proveedor', <ProveedorForm id={0} />)}
              content='Crear Proveedor' color='blue' />
            <Button onClick={() => proveedorStore.loadProveedores()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <ProveedorList />
      </Grid.Column>
    </Grid>
  )
})