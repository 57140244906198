import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Segment, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import MyCheckInput from "../../../../app/common/form/MyCheckInput";
import {
  Usuario,
  UsuarioFormValues,
} from "../../../../app/models/configuracion/Usuario";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import "../../../../app/layout/styles.css";
import { toast } from "react-toastify";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import { TipoDropdown, TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import { OnChangeProps } from "../../../../app/models/myProps";
import { PaginationFormValues } from "../../../../app/models/Especificaciones/PaginationSpecParams";

interface idProps {
  id: number
}

export default observer(function SucursalForm({ id }: idProps) {
  const {
    usuarioStore,
    empresaStore,
    sucursalStore,
    perfilStore,
    usuarioTipoStore,
  } = useStore();
  const { getEmpresas } = empresaStore;
  const { fetchSucursales } = sucursalStore;
  const { getPerfiles } = perfilStore;
  const { getUsuarioTipos } = usuarioTipoStore;
  const {
    createUsuario,
    loadUsuario,
    loadingInitial,
    loading,
    obtenerSiguienteClave
  } = usuarioStore;
  const [usuario, setUsuario] = useState(new UsuarioFormValues());
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre de usuario es requerida."),
    empresaId: Yup.number().min(1, "Favor de seleccionar una empresa").required("La empresa es requerida."),
    sucursalId: Yup.number().min(1, "Favor de seleccionar una sucursal").required("La sucursal es requerida."),
    perfilId: Yup.number().min(1, "Favor de seleccionar un perfil").required("El perfil es requerida."),
    usuarioTipoId: Yup.number().min(1, "Favor de seleccionar tipo de usuario").required("El tipo de usuario es requerida."),

  });
  const [empresas] = useState([
    { key: "0", value: "0", text: "Seleccionar" },
  ]);
  const [perfiles] = useState([
    { key: "0", value: "0", text: "Seleccionar" },
  ]);
  const [usuarioTipos, setUsuarioTipos] = useState([
    { key: "0", value: "0", text: "Seleccionar" },
  ]);

  const firstElement = 0;
  const lastElement = 10;

  useEffect(() => {
    getEmpresas().then((emps) => {
      emps.forEach((element) => {
        let satEmpresasOpt = { key: "", value: "", text: "" };
        satEmpresasOpt.key = element.id.toString();
        satEmpresasOpt.value = element.clave.toString();
        satEmpresasOpt.text = element.nombre;
        empresas.push(satEmpresasOpt);
      });
    });

    getPerfiles().then((perfs) => {
      perfs.forEach((element) => {
        let perfilOpt = { key: "", value: "", text: "" };
        perfilOpt.key = element.id.toString();
        perfilOpt.value = element.clave.toString();
        perfilOpt.text = element.nombre;
        perfiles.push(perfilOpt);
      });
    });

    getUsuarioTipos().then((usuTips) => {
      usuTips.forEach((element) => {
        let usuTipsOpt = { key: "99", value: "ver", text: "vred" };
        usuTipsOpt.key = element.id.toString();
        usuTipsOpt.value = element.clave.toString();
        usuTipsOpt.text = element.nombre;
        usuarioTipos.push(usuTipsOpt);
      });
    });
  }, []);
  useEffect(() => {
    if (id) {
      loadUsuario(id).then((usuario) => {
        setUsuario(new UsuarioFormValues(usuario))
      }
      );
    }
  }, []);

  function handleSubmit(usuario: Usuario) {
    if (usuario.empresaId.toString() !== "0" && usuario.nombre !== null) {
      createUsuario(usuario);
    } else {
      toast.info("Favor de llenar campos");
    }

  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const selectEmpresaOnChange = ({ value, setFieldValue }: OnChangeProps) => {
    setFieldValue("sucursalId", 0);
    //  setFieldAny("proveedorId", 0);
    // setProveedorByEmpSucRegClear();
    fetchSucursales(value).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
  }

  const selectSucursalOnChange = ({ value, setFieldValue, values }: OnChangeProps) => {
    if (values.empresaId > 0 && value > 0)
      obtenerSiguienteClave(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: value })).then((clave) => {
        setFieldValue("clave", clave);
        setFieldValue("id", 0);
      });
  }


  if (loadingInitial) return <LoadingComponent content="Loading usuario" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={usuario}
      >
        {({ values, isValid, dirty }) => (
          <Form className="ui form">
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Usuario
              </Label>
              <div></div>
              <div className="cols">
                <div className="col35">
                  <MySelectInput
                    placeholder={usuario.empresa?.nombre || "Seleccionar"}
                    name="empresaId"
                    label="Empresa"
                    options={empresas}
                    tabIndex={2}
                    enterInput={enterInput}
                    onChangeProps={selectEmpresaOnChange}
                  />
                </div>

                <div className="col40">
                  <MySelectInput
                    placeholder={usuario.sucursal?.nombre || "Seleccionar"}
                    name="sucursalId"
                    label="Sucursal"
                    options={sucursales}
                    tabIndex={3}
                    enterInput={enterInput}
                    onChangeProps={selectSucursalOnChange}
                  />
                </div>
              </div>


              <div className="cols">
                <div className="col15">
                  <MyTextInput placeholder="Clave" name="clave" label="Clave" tabIndex={0} disabled={true} />
                </div>
                <div className="col37">
                  <div className="col80">
                    <MyTextInput
                      autoFocus={true}
                      placeholder="Nombre"
                      name="nombre"
                      label="Nombre"
                      tabIndex={1}
                      enterInput={enterInput}
                    />
                  </div>
                </div>
                <div className="col20">
                  <MyTextInput placeholder="Usuario" name="nombreCorto" label="Nombre corto" tabIndex={7} enterInput={enterInput} />
                </div>

              </div>
              <div className="cols">
                <div className="col35">
                  <MySelectInput
                    placeholder={usuario.perfil?.nombre || "Seleccionar"}
                    name="perfilId"
                    label="Perfiles"
                    options={perfiles}
                    tabIndex={4}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="300px"
                  />
                </div>
                <div className="col35">
                  <MySelectInput
                    placeholder={usuario.usuarioTipo?.nombre || "Seleccionar"}
                    name="usuarioTipoId"
                    label="Usuario Tipo"
                    options={usuarioTipos}
                    tabIndex={5}
                    enterInput={enterInput}
                    inputWidth="140px"
                    menuWidth="300px"
                  />
                </div>
              </div>
              <div className="cols">
                <div className="col1">
                  {/* Codigo Contable: */}
                  <MyTextInput
                    placeholder="Codigo contable"
                    name="codigoContable"
                    label="Codigo Contable"
                    tabIndex={6}
                    enterInput={enterInput}
                  />
                </div>
              </div>
              <div className="col10"></div>
              <div className="col10"></div>
              <div className="col10"></div>
              <div className="col10"></div>

              <div className="col15">
                <MyCheckInput placeholder="Es vendedor" name="esVendedor" />
              </div>

              <div className="col15">
                <MyCheckInput placeholder="Es trabajador" name="esTrabajador" />
              </div>

              <div className="col15">
                <MyCheckInput placeholder="Es comprador" name="esComprador" />
              </div>

              <div className="colsp"></div>
            </Segment>
            <Segment clearing>
              <Label as="a" color="blue" ribbon>
                Acceso
              </Label>
              <div></div>

              <div>
                {/* Usuario: */}
                <div className="cols">

                </div>
              </div>
              <div>
                {/* Correo electrónico: */}
                <div className="cols">
                  <div className="col80">
                    <MyTextInput placeholder="Correo electrónico" name="email" label="Correo electrónico" tabIndex={8} enterInput={enterInput} />
                  </div>
                </div>
              </div>
              <div className="cols">
                <div className="col1">
                  {/* Contraseña: */}
                  <MyTextInput placeholder="contraseña" name="contrasena" label="Contraseña" tabIndex={9} enterInput={enterInput} />
                </div>
                <div className="col1">
                  {/* Confirmar contraseña: */}
                  <MyTextInput
                    placeholder="Confirmar contraseña"
                    name="confirmarContrasena"
                    label="Confirmar contraseña"
                    tabIndex={10}
                    enterInput={enterInput}
                  />
                </div>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/usuarios"
                loading={loading}
                tabIndex={11}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});