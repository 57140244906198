import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";
import { Label, Segment } from "semantic-ui-react";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { TipoDropDownValues, TipoDropdown } from "../../../../app/models/configuracion/TipoDropdown";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";

const GeneralesHeader: React.FC<{ tabuladorEnterDom?: (e: number) => void; }> = (tabuladorEnterDom) => {
  const { empresaStore, generalStore } = useStore();
  const [headerParams] = useState<PaginationSpecParams>(new PaginationFormValues());
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { sucursalStore } = useStore();
  const { fetchSucursales } = sucursalStore
  const { values, setFieldValue } = useFormikContext<any>();
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const { fetchGeneralesByParams } = generalStore;
  useEffect(() => {
    let isMounted = true;
    const fetchSucuarsalData = async () => {
      setSucursales([new TipoDropDownValues()]);
      setFieldValue("sucursalId", 0);
      if (values.empresaId) {
        try {
          const response = await fetchSucursales(values.empresaId);
          if (isMounted && response) {
            setSucursales(response);
          }
        } catch (error) {
          console.error('Error al obtener datos de la empresa:', error);
        }
      }
    }
    fetchSucuarsalData();
    return () => {
      isMounted = false;
    };
  }, [fetchSucursales, setFieldValue, values.empresaId]);
  useEffect(() => {
    const fetchGenerales = async () => {
      if (values.sucursalId) {
        const generales = await fetchGeneralesByParams({ ...headerParams, empresaId: values.empresaId, sucursalId: values.sucursalId });
        if (generales) {
          setFieldValue('generales', generales);
        } else {
          setFieldValue('generales', {}); // Limpiar los campos si no hay datos
        }
      }
      else {
        setFieldValue('generales', {});
      }
    };
    fetchGenerales();
  }, [headerParams, values.empresaId, values.sucursalId, setFieldValue, fetchGeneralesByParams]);
  useEffect(() => {
    void loadEntityEmpresas();
  }, [loadEntityEmpresas]);
  return (
    <>    {values.id === 0 ? (<div className="nuevo">
      Registro Nuevo
    </div>
    ) : ("")
    }
      <Segment clearing>
        <div>
          <Label as="a" color="red" ribbon>
            Filtros
          </Label>

        </div>
        <div className='col18'>
          <MySelectInput
            tabIndex={1}
            autoFocus={true}
            placeholder={"Seleccionar"}
            label="Empresa"
            name="empresaId"
            options={EmpresasByDropdown}
            enterInput={tabuladorEnterDom.tabuladorEnterDom}
            inputWidth="140px"
            menuWidth="300px"
          />
        </div>
        <div className='col18'>
          <MySelectInput
            tabIndex={2}
            //autoFocus={true}
            placeholder={"Seleccionar"}
            label="Sucursal"
            name="sucursalId"
            options={sucursales}
            enterInput={tabuladorEnterDom.tabuladorEnterDom}
            inputWidth="140px"
            menuWidth="300px"
          />
        </div>
      </Segment>
    </>

  );
};
export default observer(GeneralesHeader);