import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Grid, Header, Segment } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import { ProductList } from '../dashboard/ProductList';
import { ShoppingCart } from '../dashboard/ShoppingCart';
import { PagoForm } from './PagoForm';
import { Producto } from '../../../../app/models/inventario/Producto';
import { PaginationFormValues } from '../../../../app/models/Especificaciones/PaginationSpecParams';


const PuntoVentaForm: React.FC = observer(() => {
    const { productoStore, cartStore } = useStore();
    const [productosFiltrados, setProductosFiltrados] = useState<Producto[]>([]);
    useEffect(() => {
        productoStore.fetchsProductos(new PaginationFormValues({ empresaId: 1, sucursalId: 1 })).then((productos) => {
            if (productos)
                setProductosFiltrados(productos);
        });
    }, []);
    return (
        <div className="App">
            <Segment inverted vertical textAlign="center" style={{ padding: '2em 0em' }}>
                <Container>
                    <Header as="h1" inverted>Sistema de Punto de Venta</Header>
                </Container>
            </Segment>
            <Container style={{ marginTop: '2em' }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <ProductList products={productosFiltrados} addToCart={cartStore.addToCart} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <ShoppingCart items={cartStore.items} removeFromCart={cartStore.removeFromCart} />
                            <PagoForm total={cartStore.total} onSubmit={cartStore.checkout} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    );
});

export default PuntoVentaForm;