import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import BarraBotones from "../../../Component/BarraBotones";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { Proveedor, ProveedorFormValues } from "../../../../app/models/gestionProveedor/Proveedor";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsDirecciones, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import Direccion from "../../../Component/Direccion";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import { OnChangeProps, OnKeyDownProps } from "../../../../app/models/myProps";
import { PaginationFormValues, PaginationSpecParams } from "../../../../app/models/Especificaciones/PaginationSpecParams";
import { toast } from "react-toastify";
import { TipoDropdown, TipoDropDownValues } from "../../../../app/models/configuracion/TipoDropdown";
import BarraDireccion from "../../../Component/BarraDireccion";

interface idProps {
  id: number
}

export default observer(function ProveedorForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 21;
  const { proveedorStore, empresaStore, sucursalStore, proveedorTipoStore, modalStore } = useStore();
  const { EmpresasByDropdown, loadEntityEmpresas } = empresaStore
  const { proveedoresTiposByDropdown } = proveedorTipoStore
  const { loading, obtenerSiguienteClave, cargarProveedor } = proveedorStore
  const { fetchSucursales } = sucursalStore;
  const { createProveedor, updateProveedor,
    loadProveedor,
    loadingInitial
  } = proveedorStore;
  const [proveedor, setProveedor] = useState(new ProveedorFormValues());
  const { openModal, closeModal } = modalStore;
  const [sucursales, setSucursales] = useState<TipoDropdown[]>([new TipoDropDownValues()]);

  useEffect(() => {
    empresaStore.getEmpresas();
    sucursalStore.getSucursales();
    proveedorTipoStore.getProveedorTipos();
    void loadEntityEmpresas();
  }, [])

  useEffect(() => {
    if (id) {
      loadProveedor(id).then(proveedor => {
        setProveedor(new ProveedorFormValues(proveedor))
        if (proveedor) {
          fetchSucursales(proveedor.empresaId).then((response) => {
            if (response) {
              setSucursales(response);
            }
          });
        }
      });
    }
  }, [])

  const validationSchema = Yup.object({
    empresaId: Yup.number().min(1, "Favor de seleccionar la Empresa.").required("La Empresa es requerida."),
    sucursalId: Yup.number().min(1, "Favor de seleccionar la Sucursal.").required("La Sucursal es requerida."),
    clave: Yup.number().min(1, "Clave no puede ser 0.").required('La clave del tipo de venta es requerida.'),
    nombre: Yup.string().required('El nombre del tipo de venta es requerido.'),
    rfc: Yup.string().required('El RFC es requerido.'),
    proveedorTipoId: Yup.number().min(1, "Favor de seleccionar un Tipo de Proveedor.").required("El tipo de Proveedor es requerido."),
  });

  function handleSubmit(proveedor: Proveedor) {
    proveedor.empresa = undefined;
    proveedor.sucursal = undefined;
    proveedor.proveedorTipo = undefined;
    if (proveedor.direccion?.paisId !== 0 && proveedor.direccion?.estadoId !== 0) {
      proveedor.id > 0 ? void updateProveedor(proveedor) : void createProveedor(proveedor);;
    } else {
      toast.info("Favor de llenar campos Direccion obligatorios");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }
  const onChangeSelectEmpresa = ({ value, setFieldValue }: OnChangeProps) => {
    setFieldValue("sucursalId", 0);
    fetchSucursales(value).then((response) => {
      if (response) {
        setSucursales(response);
      }
    });
  }
  const selectInputSucursalOnChange = ({ value, setFieldValue, values }: OnChangeProps) => {
    if (values.empresaId > 0 && value > 0)
      obtenerSiguienteClave(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: value })).then((clave) => {
        setFieldValue("clave", clave);
        setFieldValue("id", 0);
      });
  }
  const selectInputClaveOnKeyDown = ({ value, event, values }: OnKeyDownProps) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (values.empresaId > 0 && values.sucursalId > 0 && value > 0) {
        const paginacionCliente: PaginationSpecParams = new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, clave: parseInt(value) });
        cargarProveedor(paginacionCliente).then((result) => {
          if (result) {
            setProveedor(new ProveedorFormValues(result))
          } else {
            setProveedor(new ProveedorFormValues({
              ...new ProveedorFormValues(), empresaId: paginacionCliente.empresaId, sucursalId: paginacionCliente.sucursalId,
              clave: value,
            }));
          }
        })
      }
    }
  }
  if (loadingInitial) return <LoadingComponent content='Loading proveedor' />

  return (
    <Segment clearing>
      <Formik
        onSubmit={values => { handleSubmit(values) }}
        validationSchema={validationSchema}
        enableReinitialize initialValues={proveedor}>
        {({ setFieldValue, values }) => (
          <Form className='ui form' >
            {values.id === 0 ? (<div className="nuevo">
              Registro Nuevo
            </div>
            ) : ("")
            }

            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Proveedor
              </Label>
              <div></div>

              <MySelectInput
                tabIndex={0}
                placeholder={"Seleccionar"}
                label="Empresa"
                name="empresaId"
                options={EmpresasByDropdown}
                enterInput={enterInput}
                onChangeProps={onChangeSelectEmpresa}
                inputWidth="170px"
                menuWidth="300px"
                autoFocus={true}
              />

              <MySelectInput
                tabIndex={1}
                label="Sucursal"
                placeholder={"Seleccionar"}
                name="sucursalId"
                options={sucursales}
                onChangeProps={selectInputSucursalOnChange}
                enterInput={enterInput} />

              <div className='col20'>
                <MyTextInput
                  tabIndex={2}
                  label="Clave"
                  placeholder="Clave"
                  name="clave"
                  onKeyDownProps={selectInputClaveOnKeyDown}
                  enterInput={enterInput} />
              </div>
              <div className='colsp' />
              <div className='col50'>
                <MyTextInput
                  tabIndex={3}
                  label="Nombre"
                  placeholder="Nombre"
                  name="nombre"
                  enterInput={enterInput} />
              </div>
            </Segment>

            <Segment clearing>
              <Button
                type="button"
                className="ui primary button"
                tabIndex={4}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <Direccion
                      direccion={values.direccion}
                      setDireccion={(data) => { setFieldValue("direccion", data) }}
                      closeModals={closeModals}
                      tabIndex={4}
                    />,
                    enumHeaderModalsDirecciones.sucursal
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="map marker alternate" />
                Direccion
              </Button>
              <div className="col70">
                <BarraDireccion
                  direccion={values.direccion}
                ></BarraDireccion>
              </div>
            </Segment>

            <Segment clearing>
              <div className='col17'>
                <MyTextInput enterInput={enterInput} label="RFC" placeholder="RFC" name="rfc" />
              </div>
              <div className='colsp' />
              <div className='col20'>
                <MyTextInput enterInput={enterInput} label="CURP" placeholder="CURP" name="curp" />
              </div>
              <div className='colsp' />
              <div className='col20'>
                <MySelectInput enterInput={enterInput} label="Tipo de proveedor" placeholder="Proveedor tipos" name="proveedorTipoId" options={proveedoresTiposByDropdown} />
              </div>
              <div className='col20'>
                <MyTextInput enterInput={enterInput} label="Codigo Contable" placeholder="Codigo contable" name="codigoContable" />
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={21}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(data) => setFieldValue("fechaObservacion", data)}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Proveedor
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>

            <Segment>
              <BarraBotones
                toLink="/Proveedores"
                loading={loading}
                tabIndex={22}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})