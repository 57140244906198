import { useEffect, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from 'formik';
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Segment, Label } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import * as Yup from 'yup';
import { GeneracionComprobanteCompra } from '../../../../app/models/compra/GeneracionComprobanteCompra';
import { toast } from 'react-toastify';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { observer } from 'mobx-react-lite';
import { TipoDropdown, TipoDropDownValues } from '../../../../app/models/configuracion/TipoDropdown';
import { OnChangeProps, OnKeyDownProps } from '../../../../app/models/myProps';

interface Props {
  closeModals: () => void;
  tabIndex: number;
  empresaId: number;
  onCopy: (data: GeneracionComprobanteCompra) => void;
}

export default observer(function CopiarComprobanteCompra(props: Props) {
  const validationSchema = Yup.object({
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),

    tipoCompraId: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),

    serieFolioDeCompraId: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de venta es requerido."),

    folio: Yup.number().min(1, "Favor de ingresar folio mayor a 0.").required("El folio es requerido."),
  });

  const firstElement = props.tabIndex;
  const lastElement = props.tabIndex + 6;
  const { sucursalStore, tipoDeCompraStore, serieFolioCompraStore, generacionComprobanteCompraStore, proveedorStore } = useStore();
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const { tiposDeComprasByDropdownByClave } = tipoDeCompraStore
  const { loadSerieByParamsDropdown } = serieFolioCompraStore
  const { getComprobante, loading } = generacionComprobanteCompraStore;
  const [copiarComprobanteParams, setCopiarComprobanteParams] = useState<PaginationSpecParams>(new PaginationFormValues());
  const [serie, setSerie] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const [generacionComprobanteCom, setGeneracionComprobanteCom] = useState<GeneracionComprobanteCompra | null>(null);

  useEffect(() => {
    tipoDeCompraStore.getTiposDeCompras();
  }, [])

  useEffect(() => {
    const fetchSucuarsalData = async () => {
      try {
        await loadSucursalByIdEmpresa(props.empresaId);
      } catch (error) {
        console.error('Error al obtener datos de la sucursal:', error);
      }
    };
    fetchSucuarsalData();
  }, [props.empresaId]);

  useEffect(() => {
    const abortController = new AbortController();
    const loadSerie = async (params: PaginationSpecParams) => {
      try {
        if (copiarComprobanteParams.empresaId > 0 && copiarComprobanteParams.sucursalId > 0 && copiarComprobanteParams.tipoCompraId > 0) {
          const result = await loadSerieByParamsDropdown(params, { signal: abortController.signal });
          if (!abortController.signal.aborted && result) {
            setSerie(result);
          }
        }
      } catch (error) {
        console.error("Failed to load series:", error);
      }
    };
    loadSerie(copiarComprobanteParams);
    return () => {
      abortController.abort();
    };
  }, [copiarComprobanteParams.sucursalId, copiarComprobanteParams.tipoCompraId]);

  const handleTipoChange = async ({ value, setFieldValue }: OnChangeProps) => {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      tipoCompraId: value,
    }));
    setSerie([new TipoDropDownValues()]);
    setFieldValue("serieFolioDeCompraId", 0);
  }

  const obtenerComprobante = (values: any) => {
    if (props.empresaId > 0 && values.sucursalId > 0 && values.tipoCompraId > 0 && values.folio > 0) {
      getComprobante({ ...copiarComprobanteParams, empresaId: props.empresaId, sucursalId: values.sucursalId, tipoCompraId: values.tipoCompraId, serieId: values.serieFolioDeCompraId, folio: values.folio }).then((result) => {
        if (result) {
          setGeneracionComprobanteCom(result)
        }
        if (result === undefined || result === null) {
          setGeneracionComprobanteCom(null);
          toast.info("No hay Comprobante con esos Filtros", { position: "bottom-right" })
        }
      })
    }
    else {
      toast.info("Favor de llenar los campos requeridos", { position: "bottom-right" })
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };

  function handleSubmit() {
    if (generacionComprobanteCom) {
      props.onCopy(generacionComprobanteCom);
      props.closeModals();
    }
  }

  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  const handleSucursalChange = ({ value, setFieldValue }: OnChangeProps) => {
    setSerie([new TipoDropDownValues()]);
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      empresaId: props.empresaId,
      sucursalId: value,
    }));

    setFieldValue("serieFolioDeCompraId", 0);
  };

  const handleSerieChange = ({ value }: OnChangeProps) => {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      serieId: value,
    }));
  }

  const selectInputFolioOnKeyUp = ({ values, event }: OnKeyDownProps) => {
    if (event.key === "Enter" || event.key === "Tab") {
      obtenerComprobante(values);
    }
  }
  return (
    <Formik
      onSubmit={() => {
        handleSubmit();
      }}
      enableReinitialize
      initialValues={{
        empresaId: props.empresaId,
        sucursalId: 0,
        tipoCompraId: 0,
        serieFolioDeCompraId: 0,
        folio: 0,
      }}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => (

        <Form className="ui form">
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 1}
              placeholder={"Seleccionar"}
              label="Sucursal"
              name="sucursalId"
              options={sucursalesByEmpresaByDropdownByClave}
              enterInput={enterInput}
              onChangeProps={handleSucursalChange}
              autoFocus={true}
              inputWidth="85px"
              menuWidth="300px"
            />
          </div>
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 2}
              placeholder={"Seleccionar"}
              label="Tipo Compra"
              name="tipoCompraId"
              options={tiposDeComprasByDropdownByClave}
              enterInput={enterInput}
              onChangeProps={handleTipoChange}
              inputWidth="85px"
              menuWidth="300px"
            />
          </div>
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 3}
              placeholder="Serie"
              label="Serie"
              name="serieFolioDeCompraId"
              options={serie}
              enterInput={enterInput}
              onChangeProps={handleSerieChange}
              inputWidth="85px"
              menuWidth="300px"
            />
          </div>
          <div className='col10'>
            <MyTextInput
              tabIndex={props.tabIndex + 4}
              placeholder=''
              label="Folio"
              name='folio'
              enterInput={enterInput}
              onKeyDownProps={selectInputFolioOnKeyUp}
            />
          </div>
          {generacionComprobanteCom && <Segment>
            <Label>Proveedor: {generacionComprobanteCom?.proveedor?.nombre}</Label>
            <Label>Total: {generacionComprobanteCom?.total}</Label>
          </Segment>
          }
          <Segment>
            <Button.Group>
              <Button
                loading={loading}
                disabled={!dirty || !isValid || generacionComprobanteCom === null || loading}
                tabIndex={props.tabIndex + 5}
                type="submit"
              >
                <Icon name="save" />
                Copiar
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                tabIndex={props.tabIndex + 6}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Regresar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})