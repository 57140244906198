import { Empresa } from "../configuracion/Empresa";
import { Moneda } from "../configuracion/Moneda";
import { Sucursal } from "../configuracion/Sucursal";
import { Usuario } from "../configuracion/Usuario";
import { Cliente } from "./Cliente";
import { ConceptoCliente } from "./ConceptoCliente";
import { CuentaDeCliente } from "./CuentaDeCliente";
import { FormaDePago } from "./FormaDePago";
import { MovimientoClienteDetalle } from "./MovimientoCliente";
export interface CapturaPagos extends PagoAplicado {
    todo?: boolean;
    limiteCredito?: number;
    saldo?: number;
    saldoDisponible?: number;
    creditoSuspendido?: boolean;
    esAnticipo?: boolean;
    pagoAplicado: PagoAplicado[];
    movimientoClienteDetalleId?: number;
    comprobantesSaldo: ComprobantesSaldo[];
    movimientoEliminado: MovimientoEliminado[];
    //
    totalFacturas?: number;
    totalSaldo?: number;
    subtotalAbono: number;
    ivaAbono: number;
    importe: number;
    esPPD?:boolean;
    UUID?:string;
}
export interface ComprobantesSaldo extends MovimientoClienteDetalle {
    abono: number;
    saldo: number;
    observaciones?: string;
}
export class CapturaPagosFormValues implements CapturaPagos {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    cuentaDeClienteId?: number = 0;
    cuentaDeCliente?: CuentaDeCliente;
    clienteId?: number = 0;
    cliente?: Cliente;
    todo?: boolean = false;
    limiteCredito?: number = 0;
    saldo?: number = 0;
    saldoDisponible?: number = 0;
    creditoSuspendido?: boolean = false;
    abonar: number = 0;
    abonado?: number = 0;
    disponible?: number = 0;
    numOperacion?: string = '';
    conceptoDeClienteId?: number = 0;
    conceptoDeCliente?: ConceptoCliente;
    formaDePagoId?: number = 0;
    formaDePago?: FormaDePago;
    esAnticipo?: boolean = false;
    fecha?: Date = new Date();
    fechaDeposito?: undefined;
    monedaId?: number = 0;
    moneda?: Moneda;
    usuarioId?: number = 0;
    usuario?: Usuario;
    folio?: number = 0;
    abono: number = 0;
    observaciones?: string = "";
    pagoAplicado: PagoAplicado[] = [];
    movimientoClienteDetalleId?: number = 0;
    comprobantesSaldo: ComprobantesSaldo[] = [];
    movimientoEliminado: MovimientoEliminado[] = [];
    //
    totalFacturas?: number = 0;
    totalSaldo?: number = 0;
    subtotalAbono: number = 0;
    ivaAbono: number = 0;
    importe: number = 0;
    esPPD:boolean = false;
    UUID?:string ="";
}
export interface PagoAplicado {
    id: number;
    clave: number;
    nombre: string;
    empresaId?: number;
    empresa?: Empresa;
    sucursalId?: number;
    sucursal?: Sucursal;
    cuentaDeClienteId?: number;
    cuentaDeCliente?: CuentaDeCliente;
    monedaId?: number
    moneda?: Moneda;
    usuarioId?: number;
    usuario?: Usuario;
    vendedorId?: number;
    vendedor?: Usuario;
    conceptoDeClienteId?: number;
    conceptoDeCliente?: ConceptoCliente;
    fecha?: Date;
    fechaDeposito?: Date;
    clienteId?: number;
    cliente?: Cliente;
    folio?: number;
    serie?: string
    tipoCambio?: number;
    abonar: number;
    abonado?: number;
    abono: number;
    disponible?: number;
    observaciones?: string;
    movimientoClienteDetalleId?: number;
    formaDePagoId?: number;
    formaDePago?: FormaDePago;
    numOperacion?: string;
    movimientoClienteId?: number;
    esPPD?:boolean;
    UUID?:string;
}

export interface MovimientoEliminado {
    id: number;
    clave: number;
    nombre: string;
    empresaId?: number;
    empresa?: Empresa;
    sucursalId?: number;
    sucursal?: Sucursal;
    cuentaDeClienteId?: number;
    cuentaDeCliente?: CuentaDeCliente;
    clienteId?: number;
    cliente?: Cliente;
    fecha?: Date;
    folio?: number;
    formaDePagoId?: number;
    formaDePago?: FormaDePago;
    importe: number;
    elaboroId: number;
    elaboro: Usuario;
    eliminoId: number;
    elimino: Usuario;
    fechaElimino: Date;
    capturaPagoId?: number;
}