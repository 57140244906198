import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { VerificadorGestionCliente } from "../../models/gestionCliente/VerificadorGestionCliente";

export default class ReporteGestionClientesStore {
    reporteGestionClienteRegistry = new Map<number, VerificadorGestionCliente>();
    selectedVerificadorGestionCliente: VerificadorGestionCliente | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    imprimirAnaliticaClientes = async (filtros: VerificadorGestionCliente) => {
        try {
            this.loading = true;
            const namePdf = await agent.ReportesClientes.imprimirAnaliticaClientes(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    const blob = new Blob([namePdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                }
                this.loading = false;
            });
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    imprimirReporteClientes = async (filtros: VerificadorGestionCliente) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesClientes.imprimirAnaliticaClientes(filtros);// .imprimirReporteClientes(filtros);
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };

}