import { c_TipoRelacion } from "../sat/c_TipoRelacion";
import { GeneracionComprobanteNotaCreditoDetalle } from "./GeneracionComprobanteNotaCreditoDetalle";
import { TipoNotaCredito } from "./TipoNotaCredito";

export interface GeneracionComprobanteNotaCredito {
    id: number;
    clave : number;
    nombre : string;
    tipoNotaCreditoId?:number;
    tipoNotaCredito?:TipoNotaCredito
    c_TipoRelacionId:number;
    c_TipoRelacion?:c_TipoRelacion;
    uUID:string;
    notaCreditoDetalles?: GeneracionComprobanteNotaCreditoDetalle[] ;
}
export interface IGeneracionComprobanteNotaCreditoFormValues extends Partial<GeneracionComprobanteNotaCredito> {
    id: any;

}
export class GeneracionComprobanteNotaCreditoFormValues implements IGeneracionComprobanteNotaCreditoFormValues {
    id: number =0;
    clave : number=0;
    nombre : string="";
    tipoNotaCreditoId?:number =0;
    tipoNotaCredito?:TipoNotaCredito
    c_TipoRelacionId:number=0;
    c_TipoRelacion?:c_TipoRelacion;
    uUID:string="";
    notaCreditoDetalles?: GeneracionComprobanteNotaCreditoDetalle[] ;
    constructor(init?: GeneracionComprobanteNotaCredito) {
        Object.assign(this, init);
    }
}