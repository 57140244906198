import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDeCompraTipo } from "../../models/compra/TipoDeCompraTipo";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class TipoDeCompraTipoStore {
    tipoDeCompraTipoRegistry = new Map<number, TipoDeCompraTipo>();
    selectedTipoDeCompraTipo: TipoDeCompraTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadTipoDeCompraTipos = async () => {

        this.setLoadingInitial(true);
        try {
            const tipoDeCompraTipos = await agent.TiposDeCompraTipos.list();
            
            tipoDeCompraTipos.forEach(tipoDeCompraTipo => {
                this.setTipoDeCompraTipo(tipoDeCompraTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    get tipoDeCompraTiposByDropdown() {
        
        let tipoDeCompraTipoArreg = [new TipoDropDownValues()];
        let tipoDeCompraTipoOpt = new TipoDropDownValues();
        const cvts = Array.from(this.tipoDeCompraTipoRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            tipoDeCompraTipoOpt.key = element.id;
            tipoDeCompraTipoOpt.text = element.nombre;
            tipoDeCompraTipoOpt.value = element.id;
            tipoDeCompraTipoOpt.content = element.nombre;
            tipoDeCompraTipoArreg.push(tipoDeCompraTipoOpt);
            tipoDeCompraTipoOpt = new TipoDropDownValues();

        });
        return tipoDeCompraTipoArreg;
    }



    getTipoDeCompraTipos = async () => {

        this.setLoadingInitial(true);
        const tipoDeCompraTipos = await agent.TiposDeCompraTipos.list();
        try {
            tipoDeCompraTipos.forEach(tipoDeCompraTipo => {
                this.setTipoDeCompraTipo(tipoDeCompraTipo);
                
            })
            this.setLoadingInitial(false);
            return tipoDeCompraTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return tipoDeCompraTipos;
        }
    }

    loadTipoDeCompraTipo = async (id: number) => {
        let tipoDeCompraTipo = this.getTipoDeCompraTipo(id);
        if (tipoDeCompraTipo) {
            this.selectedTipoDeCompraTipo = tipoDeCompraTipo;
            return tipoDeCompraTipo;
        } else {
            this.loadingInitial = true;
            try {
                tipoDeCompraTipo = await agent.TiposDeCompraTipos.details(id);
                this.setTipoDeCompraTipo(tipoDeCompraTipo);
                this.selectedTipoDeCompraTipo = tipoDeCompraTipo;
                this.setLoadingInitial(false);
                return tipoDeCompraTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setTipoDeCompraTipo = (tipoDeCompraTipo: TipoDeCompraTipo) => {
        this.tipoDeCompraTipoRegistry.set(tipoDeCompraTipo.id, tipoDeCompraTipo);
    }

    private getTipoDeCompraTipo = (id: number) => {
        return this.tipoDeCompraTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTipoDeCompraTipo = async (tipoDeCompraTipo: TipoDeCompraTipo) => {
        this.loading = true;
        try {
            await agent.TiposDeCompraTipos.create(tipoDeCompraTipo);
            runInAction(() => {
                this.tipoDeCompraTipoRegistry.set(tipoDeCompraTipo.id, tipoDeCompraTipo);
                this.selectedTipoDeCompraTipo = tipoDeCompraTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateTipoDeCompraTipo = async (tipoDeCompraTipo: TipoDeCompraTipo) => {
        this.loading = true;
        try {
            await agent.TiposDeCompraTipos.update(tipoDeCompraTipo);
            runInAction(() => {
                this.tipoDeCompraTipoRegistry.set(tipoDeCompraTipo.id, tipoDeCompraTipo);
                this.selectedTipoDeCompraTipo = tipoDeCompraTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteTipoDeCompraTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.TiposDeCompraTipos.delete(id);
            runInAction(() => {
                this.tipoDeCompraTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
