import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { TipoMovimiento } from "../TipoMovimiento";
import { Almacen } from "./Almacen";
import { ConceptoDeInventario } from "./ConceptoDeInventario";
import { Producto } from "./Producto";

export interface MovimientoInventario {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    tipoMovimientoId: number;
    tipoMovimiento?: TipoMovimiento;
    clave: number;
    fecha: Date;
    fechaCancelacion?: Date;
    comentarios: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    detalles: MovimientoInventarioDetalles[];

}
export interface MovimientoInventarioDetalles {
    id: number;
    almacenId: number;
    almacen?: Almacen;
    conceptoDeInventarioId: number;
    conceptoDeInventario?: ConceptoDeInventario;
    productoId: number;
    producto?: Producto;
    cantidad: number;
    costo: number;
    importe: number;
}
export class MovimientoInventarioDetallesFormValues implements MovimientoInventarioDetalles {
    id: number = 0;
    almacenId: number = 0;
    almacen?: Almacen;
    conceptoDeInventarioId: number = 0;
    conceptoDeInventario?: ConceptoDeInventario;
    productoId: number = 0;
    producto?: Producto;
    cantidad: number = 0;
    costo: number = 0;
    importe: number = 0;
    constructor(init?: MovimientoInventarioDetalles) {
        Object.assign(this, init);
    }
}

export class MovimientoInventarioFormValues implements MovimientoInventario {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    tipoMovimientoId: number = 0;
    tipoMovimiento?: TipoMovimiento;
    clave: number = 0;
    fecha: Date = new Date();
    fechaCancelacion?: Date;
    comentarios: string = "";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    detalles: MovimientoInventarioDetalles[] = [new MovimientoInventarioDetallesFormValues()]
    constructor(init?: MovimientoInventario) {
        Object.assign(this, init);
    }
}