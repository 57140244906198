import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { c_Mes } from "../../models/sat/c_Mes";

export default class c_MesStore {
    c_MesRegistry = new Map<number, c_Mes>();
    selectedC_Mes: c_Mes | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    get c_mesesByDropdown() {
        let c_MesesArreg = [new TipoDropDownValues()];
        let c_MesesOpt = new TipoDropDownValues();
        const cvts = Array.from(this.c_MesRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            c_MesesOpt.key = element.id;
            c_MesesOpt.text = element.clave;
            c_MesesOpt.value = element.id;
            c_MesesOpt.content = element.nombre;
            c_MesesArreg.push(c_MesesOpt);
            c_MesesOpt = new TipoDropDownValues();

        });
        return c_MesesArreg;
    }

    get c_MesesByDate() {
        return Array.from(this.c_MesRegistry.values()).sort((a) => a.id);
    }


    loadc_Meses = async () => {
        this.setLoadingInitial(true);
        try {
            const c_Meses = await agent.C_Meses.list();
            c_Meses.forEach(c_Mes => {
                this.setC_Mes(c_Mes);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
        }
    }

    getc_Meses = async () => {
        this.setLoadingInitial(true);
        const c_Meses = await agent.C_Meses.list();
        try {
            c_Meses.forEach(c_Mes => {
                this.setC_Mes(c_Mes);
            })
            this.setLoadingInitial(false);
            return c_Meses;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return c_Meses;
        }
    }







    loadC_Mes = async (id: number) => {
        let c_Mes = this.getC_Mes(id);
        if (c_Mes) {
            this.selectedC_Mes = c_Mes;
            return c_Mes;
        } else {
            this.loadingInitial = true;
            try {
                c_Mes = await agent.C_Meses.details(id);
                this.setC_Mes(c_Mes);
                this.selectedC_Mes = c_Mes;
                this.setLoadingInitial(false);
                return c_Mes;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setC_Mes = (c_Mes: c_Mes) => {
        this.c_MesRegistry.set(c_Mes.id, c_Mes);
    }

    private getC_Mes = (id: number) => {
        return this.c_MesRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRegimenFiscal = async (c_Mes: c_Mes) => {
        this.loading = true;
        try {
            await agent.C_Meses.create(c_Mes);
            runInAction(() => {
                this.c_MesRegistry.set(c_Mes.id, c_Mes);
                this.selectedC_Mes = c_Mes;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteRegimenFiscal = async (id: number) => {
        this.loading = true;
        try {
            await agent.RegimenesFiscales.delete(id);
            runInAction(() => {
                this.c_MesRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
