import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Segment } from 'semantic-ui-react';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import BarraBotones from '../../../Component/BarraBotones';
import { useFormikContext } from 'formik';
import { useStore } from '../../../../app/stores/store';

const TraspasoEntresSucursalFooter = () => {
    const { traspasoEntreSucursalStore } = useStore();
    const { lastElementTable, loading } = traspasoEntreSucursalStore
    const { values, setFieldValue } = useFormikContext<any>();
    useEffect(() => {
        try {
            const calculateTotalCost = () => {
                return values.TraspasoEntresSucursalDetalles.reduce(
                    (acc: number, item: any) => acc + item.totalCosto,
                    0
                );
            };
            setFieldValue("totalCosto", calculateTotalCost() ? calculateTotalCost() : 0)
        } catch (error) {
            console.error('Error al obtener datos de la empresa:', error);
        }
    }, [values?.TraspasoEntresSucursalDetalles]);

    return (
        <>
            <Segment textAlign="center">
                <div className='col60'></div>

                <div className='col15'>

                    <label>Total Costo:</label></div>
                <div className='col20'>
                    <MyTextInput className=" heighttext input textAlingRight"
                        placeholder=''
                        name='totalCosto' />
                </div>
            </Segment>
            <Segment>
                <BarraBotones
                    toLink="/"
                    loading={loading}
                    tabIndex={lastElementTable + 2}
                    firstElement={0}
                    lastElement={lastElementTable + 4}
                ></BarraBotones>
            </Segment>
        </>
    )
}
export default observer(TraspasoEntresSucursalFooter);