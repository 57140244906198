import { Empresa } from ".././configuracion/Empresa";
import { Direccion, DireccionValues } from "../configuracion/Direccion";
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Sucursal } from "../configuracion/Sucursal";
import { ProveedorTipo } from "./ProveedorTipo";

export interface Proveedor  {
    id: number;
    clave: number;
    nombre: string;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    proveedorTipoId: number;
    proveedorTipo?: ProveedorTipo;
    rfc: string;
    curp: string;
    direccionId: number;
    direccion: Direccion;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}
export class ProveedorFormValues implements ProveedorFormValues {
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    proveedorTipoId: number = 0;
    proveedorTipo?: ProveedorTipo;
    rfc: string = "";
    curp: string = "";

    direccionId: number = 0;
    direccion: Direccion = new DireccionValues();
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    constructor(init?: Proveedor) {
        Object.assign(this, init);
    }
}