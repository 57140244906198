import { useEffect, useRef, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from 'formik';
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Label, Segment } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import { GeneracionComprobante } from '../../../../app/models/venta/GeneracionComprobante';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { toast } from 'react-toastify';
import { TipoDropDownValues, TipoDropdown } from '../../../../app/models/configuracion/TipoDropdown';

interface Props {
  closeModals: () => void;
  tabIndex: number;
  empresaId: number;
  onCopy: (data: string) => void;
}

export default observer(function RelacionarComprobante(props: Props) {
  const [errorButon, setErrorButon] = useState("");
  /*const validationSchema = Yup.object({
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("La Sucursal es requerido."),

    tipoVentaId: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),

    serieFolioDeVentaId: Yup.number()
      .min(1, "Favor de seleccionar la serie .")
      .required("La serie de venta es requerido."),

    folio: Yup.number().min(1, "Favor de ingresar folio mayor a 0.").required("El folio es requerido."),
  });*/
  const [generacionComprobanteCot, setGeneracionComprobanteCot] = useState<GeneracionComprobante | null>(null);
  const firstElement = props.tabIndex;
  const lastElement = props.tabIndex + 6;
  const { sucursalStore, tipoVentaStore, serieFolioStore, proveedorStore, generacionComprobanteStore } = useStore();
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdown, setsucursalByEmpresaRegistryClear } = sucursalStore
  const { TiposVentasByDropdown } = tipoVentaStore
  //const { loadSerieByParams } = serieFolioStore
  const { loadSerieByIdTipoVenta, SeriesFoliosByEmpresaByDropdown,loadSerieByParamsDropdown } = serieFolioStore
  const { getCotizacion,    loading } = generacionComprobanteStore;
  const [copiarComprobanteParams, setCopiarComprobanteParams] = useState<PaginationSpecParams>(new PaginationFormValues());
  const [serie, setSerie] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
  const inputUUIDRef = useRef<any>(null);
  useEffect(() => {
    tipoVentaStore.getTiposVentas();
  }, [])
  useEffect(() => {
    return () => {
      generacionComprobanteStore.resetGeneracionComprobanteParams();
    };
  }, []);
  useEffect(() => {
    const fetchSucuarsalData = async () => {
      try {
        await loadSucursalByIdEmpresa(props.empresaId);
      } catch (error) {
        console.error('Error al obtener datos de la empresa:', error);
      }
    };
    fetchSucuarsalData();
  }, [props.empresaId]);

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  const handleSubmit = () => {
    if (generacionComprobanteCot) {
      props.onCopy(inputUUIDRef.current.GetText());
      props.closeModals();
    }
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  const handleSucursalChange = (idSelected: number, setFieldAny: any) => {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      empresaId: props.empresaId,
      sucursalId: idSelected,
    }));
    setFieldAny("serieFolioDeVentaId", 0);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadSerie = async (params: PaginationSpecParams) => {
      try {
        if (copiarComprobanteParams.empresaId > 0 && copiarComprobanteParams.sucursalId > 0 && copiarComprobanteParams.tipoVentaId > 0) {
          const result = await loadSerieByParamsDropdown(params, { signal: abortController.signal });
          if (!abortController.signal.aborted && result) {
            setSerie(result);
          }
        }
      } catch (error) {
        console.error("Failed to load series:", error);
      }
    };
    loadSerie(copiarComprobanteParams);
    return () => {
      abortController.abort();
    };
  }, [copiarComprobanteParams.sucursalId, copiarComprobanteParams.tipoVentaId]);
  const handleTipoChange = async (idSelectedTipoVenta: number, setFieldAny: any) => {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      tipoVentaId: idSelectedTipoVenta,
    }));
    setFieldAny("serieFolioDeVentaId", 0);
  }

  function idSelectSerie(idSelectedSerie: number) {
    setCopiarComprobanteParams((prevParams) => ({
      ...prevParams,
      serieId: idSelectedSerie,
    }));
  }
  const obtenerComprobante = (values: any, setFieldValue: any) => {
    if (props.empresaId > 0 && values.sucursalId > 0 && values.tipoVentaId > 0 && values.folio > 0) {
      getCotizacion({ ...copiarComprobanteParams, empresaId: props.empresaId, sucursalId: values.sucursalId, tipoVentaId: values.tipoVentaId, serieId: values.serieFolioDeVentaId, folio: values.folio }).then((result) => {
        
        if (result) {
          setFieldValue("uuidsRelacionados", result.uuid);
          setGeneracionComprobanteCot(result)
        }
        if (result === undefined || result === null) {
          setGeneracionComprobanteCot(null);
          toast.info("No hay Comprobante con esos Filtros", { position: "bottom-right" })
        }
      })
    }
    else {
      toast.info("Favor de llenar los campos requeridos", { position: "bottom-right" })
    }
  }
  const selectInputFolioOnBluer = (setFieldValue?: any, values?: any) => {
    obtenerComprobante(values, setFieldValue);
  }
  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit();
      }}
      enableReinitialize
      initialValues={{
        empresaId: props.empresaId,
        sucursalId: 0,
        tipoVentaId: 0,
        serieFolioDeVentaId: 0,
        folio: 0,
      }}
      //validationSchema={validationSchema}
    >
      {({ isValid, dirty, values, setFieldValue }) => (

        <Form className="ui form">
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 1}
              placeholder={"Seleccionar"}
              label="Sucursal"
              name="sucursalId"
              options={sucursalesByEmpresaByDropdown}
              enterInput={enterInput}
              onChange={handleSucursalChange}
              autoFocus={true}
            />
          </div>
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 2}
              placeholder={"Seleccionar"}
              label="Tipo Venta"
              name="tipoVentaId"
              options={TiposVentasByDropdown}
              enterInput={enterInput}
              onChange={handleTipoChange}
            />
          </div>
          <div className='col18'>
            <MySelectInput
              tabIndex={props.tabIndex + 3}
              placeholder="Serie"
              label="Serie"
              name="serieFolioDeVentaId"
              options={serie}
              enterInput={enterInput}
              onChange={idSelectSerie}
            />
          </div>
          <div className='col18'>
            <MyTextInput
              tabIndex={props.tabIndex + 4}
              placeholder=''
              label="Folio"
              name="folio"
              enterInput={enterInput}
              onBlur={selectInputFolioOnBluer}
            />
          </div>
          <div className='col50'>
          <MyTextInput
                    ref={inputUUIDRef}
                    className="textAlingRight widthtext heighttext input"
                    tabIndex={5}
                    placeholder=''
                    label="UUID Relacionados"
                    name='uuidsRelacionados'
                    enterInput={enterInput}
                   // onKeyDown={onKeyUpFolio}

                   // change={chageEnter}
                  />
          </div>
          {/* <div className='col18'>
            <Button
              tabIndex={props.tabIndex + 5}
              type="button"
              name="buscar"
              text-align="center"
              disabled={loading}
              loading={loading}
              onClick={() => isValid && dirty ? (obtenerCotizacion(values, setFieldValue), setErrorButon("")) : setErrorButon("Favor de llenar los campos requeridos")}
              color="blue"
            >
              <Icon name="search" />
              Buscar
            </Button>
            {errorButon ? (
              <Label basic color="red">
                {errorButon}
              </Label>
            ) : null}
          </div> */}
          {generacionComprobanteCot && <Segment>
            <Label>Cliente: {generacionComprobanteCot?.cliente?.nombre}</Label>
            <Label>Total: {generacionComprobanteCot?.total}</Label>
          </Segment>
          }
          <Segment>
            <Button.Group>
              <Button
                loading={loading}
                disabled={!dirty || !isValid || generacionComprobanteCot === null || loading}
                tabIndex={props.tabIndex + 5}
                type="submit"
              >
                <Icon name="save" />
                Copiar
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                tabIndex={props.tabIndex + 6}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Regresar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})