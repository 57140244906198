import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { ConceptoDeProveedor, ConceptoDeProveedorFormValues } from "../../../../app/models/gestionProveedor/ConceptoDeProveedor";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import { enterInputs, tabuladorButtons, } from "../../../../app/common/funciones/tabuladorEnter";
import * as Yup from "yup";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function ConceptoDeProveedorForm({ id }: idProps) {
  const { conceptoDeProveedorStore, conceptoDeProveedorTipoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createConceptoDeProveedor, updateConceptoDeProveedor, loadConceptoDeProveedor, loadingInitial, conceptoDeProveedorById, loading } = conceptoDeProveedorStore;
  const { conceptosDeProveedoresTipoByDropdown, getConceptosDeProveedoresTipos } = conceptoDeProveedorTipoStore;
  //const { id } = useParams<{ id: string }>();
  const [conceptoDeProveedor, setConceptosDeProveedores] = useState(new ConceptoDeProveedorFormValues());
  const [ConceptoDeProveedorTipos, setConceptoDeProveedorTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const firstElement = 0;
  const lastElement = 6;

  useEffect(() => {
    getConceptosDeProveedoresTipos().then(concProvTTips => {
      concProvTTips.forEach(element => {
        let ConceptoProvTipOpt = { key: 999, value: 999, text: "" }
        ConceptoProvTipOpt.key = element.id;
        ConceptoProvTipOpt.value = element.clave;
        ConceptoProvTipOpt.text = element.nombre;
        ConceptoDeProveedorTipos.push(ConceptoProvTipOpt);
      });
    })
  }, [getConceptosDeProveedoresTipos, ConceptoDeProveedorTipos, setConceptoDeProveedorTipos])

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de el concepto de proveedor es requerida."),
    nombre: Yup.string().required("El nombre de el concepto de proveedor es requerida."),
    conceptoDeProveedorTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo de concepto de inventario.")
      .required("El tipo de concepto de inventario es requerido.")
  });

  useEffect(() => {
    if (id) {
      loadConceptoDeProveedor(id).then((conceptoDeProveedor) => {
        setConceptosDeProveedores(new ConceptoDeProveedorFormValues(conceptoDeProveedor))
      });
    }
    else{
      conceptoDeProveedor.clave =
        conceptoDeProveedorById.map((conceptoDeProveedor) => conceptoDeProveedor.clave).length > 0
          ? Math.max(...conceptoDeProveedorById.map((conceptoDeProveedor) => conceptoDeProveedor.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(conceptoDeProveedor: ConceptoDeProveedor) {
    if (conceptoDeProveedor.conceptoDeProveedorTipo) {
      const tipoConceptoDeProveedorSelected = ConceptoDeProveedorTipos.find(
        (a) => a.key === conceptoDeProveedor.conceptoDeProveedorTipoId
      );
      if (tipoConceptoDeProveedorSelected) {
        conceptoDeProveedor.conceptoDeProveedorTipo.nombre = tipoConceptoDeProveedorSelected.text;
        conceptoDeProveedor.conceptoDeProveedorTipo.id = tipoConceptoDeProveedorSelected.key;
        conceptoDeProveedor.conceptoDeProveedorTipo.clave = tipoConceptoDeProveedorSelected.value;
      }
    }
    if (conceptoDeProveedor.conceptoDeProveedorTipoId.toString() !== "0" && conceptoDeProveedor.nombre !== null) {
      conceptoDeProveedor.id > 0 ? updateConceptoDeProveedor(conceptoDeProveedor) : createConceptoDeProveedor(conceptoDeProveedor);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading Concepto Proveedor' />

  return (
    <Segment clearing>
      <Formik onSubmit={values => { handleSubmit(values)}}
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={conceptoDeProveedor}
      >

        {({ handleSubmit, isValid, dirty }) => {
          return (
            <Form className='ui form'>
              <Segment clearing>
                <Label as='a' color='red' ribbon>
                  Datos del concepto de proveedor
                </Label>
                <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
                <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
              </Segment>
              <Segment clearing>
                <Label as='a' color='blue' ribbon>
                  Datos especí­ficos
                </Label>

                <MySelectInput 
                  placeholder="Concepto Proveedor Tipo" 
                  label="Tipo de Concepto de Proveedor"
                  name="conceptoDeProveedorTipo"
                  enterInput={enterInput}
                  tabIndex={2}
                  options={conceptosDeProveedoresTipoByDropdown} 
                  />
              </Segment>

              <Segment>
                <BarraBotones
                toLink="/conceptosDeProveedores"
                loading={loading}
                tabIndex={3}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
                ></BarraBotones>
              </Segment>

            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
})