import { FormaDePago } from "../../models/gestionCliente/FormaDePago";
import { Moneda } from "../../models/configuracion/Moneda";
import { Banco }from "../../models/Finanza/Banco";
import { CuentaBancaria }from "../../models/Finanza/CuentaBancaria";
export interface GeneracionComprobanteComplementoPago {
    id: number;
    fechaPago: Date;
    horaPago: Date;
    formaDePagoId:number;
    formaDePago?:FormaDePago;
    monto:number;
    numeroOperacion:string ;
    
    monedaId:number;
    moneda?: Moneda;
    
    bancoBeneficiarioId:number;
    bancoBeneficiario?: Banco;
    
    cuentaBancariaBeneficiarioId:number;
    cuentaBancariaBeneficiario?:CuentaBancaria;

    bancoOrdenanteId:number;
    bancoOrdenante?: Banco;

    cuentaBancariaOrdenanteId:number;
    cuentaBancariaOrdenante?:CuentaBancaria;
}
export interface IGeneracionComprobanteComplementoPagoFormValues extends Partial<GeneracionComprobanteComplementoPago> {
    id: any;

}
export class GeneracionComprobanteComplementoPagoFormValues implements IGeneracionComprobanteComplementoPagoFormValues {
    id: number=0;
    fechaPago: Date = new Date();
    horaPago: Date = new Date();
    formaDePagoId:number =0;
    formaDePago?:FormaDePago;
    monto:number =0;
    numeroOperacion:string ="";
    
    monedaId:number=0;
    moneda?: Moneda;
    
    
    bancoBeneficiarioId: number = 0;
    bancoBeneficiario?: Banco;
    
    cuentaBancariaBeneficiarioId:number = 0;
    cuentaBancariaBeneficiario?:CuentaBancaria;

    bancoOrdenanteId:number=0;
    bancoOrdenante?: Banco;
    
    cuentaBancariaOrdenanteId:number=0;
    cuentaBancariaOrdenante?:CuentaBancaria;
}