import { observer } from "mobx-react-lite";
import { Grid, Header, Button } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import PerfilList from "./PerfilList";
import PerfilForm from "../form/PerfilForm";
import ReportePerfilForm from "../form/ReportePerfilForm";

export default observer(function PerfilDashboard() {
  const { perfilStore, tabsStore } = useStore();
  useEffect(() => {
    perfilStore.loadPerfiles();
  }, [perfilStore]);

  if (perfilStore.loadingInitial)
    return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2" icon="settings" content="Perfiles" color="blue" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Perfil', <PerfilForm id={0} />)}
              content="Crear Perfil" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Perfil', <ReportePerfilForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => perfilStore.loadPerfiles()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <PerfilList />
      </Grid.Column>
    </Grid>
  );
});