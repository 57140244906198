import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CuentaDeProveedorTipo } from "../../models/gestionProveedor/CuentaDeProveedorTipo";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class CuentaDeProveedorTipoStore {
    cuentaDeProveedorTipoRegistry = new Map<number, CuentaDeProveedorTipo>();
    selectedCuentaDeProveedorTipo: CuentaDeProveedorTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadCuentasDeProveedoresTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const cuentasDeProveedoresTipos = await agent.CuentasDeProveedoresTipos.list();
            cuentasDeProveedoresTipos.forEach(cuentaDeProveedorTipo => {
                this.setCuentaDeProveedorTipo(cuentaDeProveedorTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCuentasDeProveedoresTipos = async () => {
        this.setLoadingInitial(true);
        const cuentasDeProveedoresTipos = await agent.CuentasDeProveedoresTipos.list();
        try {
            cuentasDeProveedoresTipos.forEach(cuentaDeProveedorTipo => {
                this.setCuentaDeProveedorTipo(cuentaDeProveedorTipo);
            })
            this.setLoadingInitial(false);
            return cuentasDeProveedoresTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return cuentasDeProveedoresTipos;
        }
    }

    loadCuentaDeProveedorTipo = async (id: number) => {
        let cuentaDeProveedorTipo = this.getCuentaDeProveedorTipo(id);
        if (cuentaDeProveedorTipo) {
            this.selectedCuentaDeProveedorTipo = cuentaDeProveedorTipo;
            return cuentaDeProveedorTipo;
        } else {
            this.loadingInitial = true;
            try {
                cuentaDeProveedorTipo = await agent.CuentasDeProveedoresTipos.details(id);
                this.setCuentaDeProveedorTipo(cuentaDeProveedorTipo);
                this.selectedCuentaDeProveedorTipo = cuentaDeProveedorTipo;
                this.setLoadingInitial(false);
                return cuentaDeProveedorTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setCuentaDeProveedorTipo = (cuentaDeProveedorTipo: CuentaDeProveedorTipo) => {
        this.cuentaDeProveedorTipoRegistry.set(cuentaDeProveedorTipo.id, cuentaDeProveedorTipo);
    }
    private getCuentaDeProveedorTipo = (id: number) => {
        return this.cuentaDeProveedorTipoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCuentaDeProveedorTipo = async (cuentaDeProveedorTipo: CuentaDeProveedorTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedoresTipos.create(cuentaDeProveedorTipo);
            runInAction(() => {
                this.cuentaDeProveedorTipoRegistry.set(cuentaDeProveedorTipo.id, cuentaDeProveedorTipo);
                this.selectedCuentaDeProveedorTipo = cuentaDeProveedorTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateCuentaDeProveedorTipo = async (cuentaDeProveedorTipo: CuentaDeProveedorTipo) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedoresTipos.update(cuentaDeProveedorTipo)
            runInAction(() => {
                this.cuentaDeProveedorTipoRegistry.set(cuentaDeProveedorTipo.id, cuentaDeProveedorTipo);
                this.selectedCuentaDeProveedorTipo = cuentaDeProveedorTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCuentaDeProveedorTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.CuentasDeProveedoresTipos.delete(id);
            runInAction(() => {
                this.cuentaDeProveedorTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get cuentasDeProveedoresTipoByDropdown() {
        return entityByDropdown<CuentaDeProveedorTipo>(
          Array.from(this.cuentaDeProveedorTipoRegistry.values())
        );
    }
}