import { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Moneda,
  MonedaFormValues,
} from "../../../../app/models/configuracion/Moneda";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import "./moneda.css";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function MonedaForm({ id }: idProps) {
  const firstElement = 0;
  const lastElement = 11;

  const idiomaDelImporteConLetrasByDropdown = [
    {
      key: 0,
      text: "Seleccionar",
      value: 0,
      contex: "Seleccionar",
    },
    {
      key: 1,
      text: "Español",
      value: 1,
      contex: "Español",
    },
    {
      key: 2,
      text: "Ingles",
      value: 2,
      contex: "Ingles",
    },
  ];
  const { monedaStore, monedaTipoStore, monedaSATStore, modalStore } =
    useStore();
  const { openModal, closeModal } = modalStore;
  const {
    createMoneda,
    updateMoneda,
    loadMoneda,
    loadingInitial,
    monedasById,
    loading,
  } = monedaStore;
  const { monedasTipoByDropdown, loadMonedaTipos } = monedaTipoStore;
  const { monedasSATByDropdown, loadMonedasSAT } = monedaSATStore;
  const [moneda, setMoneda] = useState(new MonedaFormValues());
  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de moneda es requerida."),
    nombre: Yup.string().required("El nombre de moneda es requerida."),
    monedaTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo moneda.")
      .required("El tipo de moneda es requerido."),
    idiomaDelImporteConLetrasId: Yup.number()
      .min(1, "Favor de seleccionar idioma del Importe.")
      .required("El idioma del Importe es requerido."),
  });

  useEffect(() => {
    void loadMonedaTipos();
    void loadMonedasSAT();
  }, [loadMonedaTipos, loadMonedasSAT]);

  useEffect(() => {
    if (id) {
      void loadMoneda(id).then((moneda) => {
        setMoneda(new MonedaFormValues(moneda));
      });
    } else {
      moneda.clave =
        monedasById.map((moneda) => moneda.clave).length > 0
          ? Math.max(...monedasById.map((moneda) => moneda.clave)) + 1
          : 1;
    }
  }, []);

  function handleSubmit(moneda: Moneda) {
    moneda.tipo = undefined;
    if (moneda.monedaTipoId.toString() !== "0" && moneda.nombre !== null) {
      moneda.id > 0 ? void updateMoneda(moneda) : void createMoneda(moneda);
    } else {
      toast.info("Favor de llenar campos");
    }
  }
  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }
  if (loadingInitial) return <LoadingComponent content="Loading moneda" />;

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
        initialValues={moneda}
      >
        {({ values, setFieldValue }) => (
          <Form className="ui form">
            <Segment clearing>
              <Label as="a" color="red" ribbon>
                Identificación de la Moneda
              </Label>
              <div className="cols">
                <div className="col15Block">
                  <MyTextInput
                    placeholder="Clave"
                    label="Clave"
                    name="clave"
                    disabled={true}
                    tabIndex={0}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    autoFocus={true}
                    placeholder="Nombre"
                    label="Nombre"
                    name="nombre"
                    tabIndex={1}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    label="Nombre Singular"
                    placeholder="Nombre Singular"
                    name="nombreSingular"
                    tabIndex={2}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    label="Nombre Plural"
                    placeholder="Nombre Plural"
                    name="nombrePlural"
                    tabIndex={3}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="col60Block">
                  <MyTextInput
                    label="Leyenda de Centavos"
                    placeholder="Leyenda de Centavos"
                    name="leyendaDeCentavos"
                    tabIndex={4}
                    enterInput={enterInput}
                    maxLength={30}
                  />
                </div>
                <div className="displayInLine">
                  <div className="col30">
                    <MySelectInput
                      tabIndex={5}
                      placeholder={"Seleccionar"}
                      label="Tipo Moneda"
                      name="monedaTipoId"
                      options={monedasTipoByDropdown}
                      enterInput={enterInput}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                  </div>
                  <div className="col30">
                    <MySelectInput
                      tabIndex={6}
                      placeholder={"Seleccionar"}
                      label="Moneda SAT"
                      name="monedaSATId"
                      options={monedasSATByDropdown}
                      enterInput={enterInput}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                  </div>
                  <div className="col30">
                    <MySelectInput
                      tabIndex={7}
                      placeholder={"Seleccionar"}
                      label="Idioma del importe"
                      name="idiomaDelImporteConLetrasId"
                      options={idiomaDelImporteConLetrasByDropdown}
                      enterInput={enterInput}
                      inputWidth="140px"
                      menuWidth="300px"
                    />
                  </div>
                </div>
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  id="fechaObservacion"
                  type="button"
                  tabIndex={8}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={values.fechaObservacion}
                        setFechaObservacion={(data) => setFieldValue("fechaObservacion", data)}
                        closeModals={closeModals}
                        tabIndex={999999}
                      />,
                      enumHeaderModalsFechasObservaciones.Moneda, "mini", true
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={values.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                // toLink="/monedas"
                loading={loading}
                tabIndex={9}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
});