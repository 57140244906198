import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import EmpresaList from './EmpresaList';
import EmpresaForm from '../form/EmpresaForm';
import ReporteEmpresaForm from '../form/ReporteEmpresaForm';

export default observer(function EmpresaDashboard() {
  const { tabsStore } = useStore();
  const { empresaStore } = useStore();
  const { loadEmpresas } = empresaStore;

  useEffect(() => {
    loadEmpresas();
  }, [loadEmpresas]);

  if (empresaStore.loadingInitial) return <LoadingComponent />

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h1' icon='building' content='Empresas' color='blue'  ></Header>
        </Grid.Column>
        <Grid.Column width={8}  >
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Empresa', <EmpresaForm id={0} />)}
              content='Crear Empresas' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Empresa', <ReporteEmpresaForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => loadEmpresas()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <EmpresaList />
      </Grid.Column>
    </Grid>
  )
})