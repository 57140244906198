import { useParams } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import {
  Personal,
  PersonalFormValues
} from "../../../../app/models/gestionFlotilla/Personal";

import MySelectInput from "../../../../app/common/form/MySelectInput";

import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsDirecciones, enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import {
  enterInputs,
  tabuladorButtons,
} from "../../../../app/common/funciones/tabuladorEnter";


import MyDateInput from "../../../../app/common/form/MyDateInput";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";
import BarraBotones from "../../../Component/BarraBotones";
import Direccion from "../../../Component/Direccion";
import { DireccionValues } from "../../../../app/models/configuracion/Direccion";



export default observer(function PersonalForm() {
  const firstElement = 0;
  const lastElement = 9;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { personalStore,personalTipoStore, sucursalStore, empresaStore } = useStore();
  const { EmpresasByDropdown, loadEmpresa } = empresaStore;
  const { SucursalesByDropdown, loadSucursal } = sucursalStore;
  const { personalTiposByDropdown, getPersonalTipos } = personalTipoStore;
  const { deletePersonal,
    createPersonal,
    updatePersonal,
    loadPersonal,
    loadingInitial,
    personalById,
    loading,
  } = personalStore;
  const { id } = useParams<{ id: string }>();

  const [personal, setPersonal] = useState(new PersonalFormValues());


  const [empresas, setempresas] = useState([{ key: '0', value: 'Sel', text: 'Seleccionar' }])
  const [sucursales, setsucursales] = useState([{ key: '0', value: 'Sel', text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(
    new FechaObservacionValues()
  );
  const [direccion, setDireccion] = useState(
    new DireccionValues()
  );

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de moneda es requerida."),
    nombre: Yup.string().required("El nombre de moneda es requerida.")
  });
  useEffect(() => { empresaStore.getEmpresas().then(emps => { }) }, [])
  useEffect(() => { sucursalStore.getSucursales().then(sucs => { }) }, [])
  useEffect(() => { personalTipoStore.getPersonalTipos().then(sucs => { }) }, [])


  useEffect(() => {
    if (id) {
      debugger
      void loadPersonal(Number.parseInt(id)).then(personal => {
        setPersonal(new PersonalFormValues(personal));
        setFechaObservacion(
          new FechaObservacionValues(personal?.fechaObservacion)
        );
      });
    } else {
      personal.clave =
        personalById.map((personal) => personal.clave).length > 0
          ? Math.max(...personalById.map((personal) => personal.clave)) + 1
          : 1;
    }
  }, [])

  function handleSubmit(personal: Personal) {
    debugger
    if (personal.empresa) {
      const empresaSelected = empresas.find(
        (a) => a.key === personal.empresaId.toString()
      );
      if (empresaSelected) {
        personal.empresa.nombre = empresaSelected.text;
        personal.empresa.id = Number.parseInt(empresaSelected.key);
        personal.empresa.clave = Number.parseInt(empresaSelected.value);
      }
    }

    if (personal.fechaObservacion) {
      personal.fechaObservacion = fechaObservacion;
    }
    debugger
    if (personal.empresaId.toString() !== "0" && personal.nombre !== null) {
      personal.id > 0 ? updatePersonal(personal) : createPersonal(personal);
    } else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading sucursal' />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={personal}
      >
        {({ isValid, dirty }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Personal
              </Label>
              <div></div>
              <MySelectInput
                tabIndex={0}
                placeholder={"Seleccionar"}
                label="Empresa"
                name="empresaId"
                options={EmpresasByDropdown}
                enterInput={enterInput}
              />

              <MySelectInput
                tabIndex={1}
                placeholder={"Seleccionar"}
                label="Sucursal"
                name="sucursalId"
                options={SucursalesByDropdown}
                enterInput={enterInput}
              />

              <div className='col20'>
                <MyTextInput label="Clave" placeholder="Clave" name="clave" tabIndex={2} enterInput={enterInput}/>
              </div>
              <div className='col50'>
                <MyTextInput label="Nombre" placeholder="Nombre" name="nombre" tabIndex={3} enterInput={enterInput} />
              </div>
              <MySelectInput
              tabIndex={3}
              placeholder="Seleccionar"
              label="Tipo"
              name="personalTipoId"
              options={personalTiposByDropdown}
              enterInput={enterInput} />
            </Segment>

            <Segment clearing>
            <div >
                <MyTextInput label="Numero de Licencia" placeholder="Numero de Licencia" name="numeroLicencia" tabIndex={4} enterInput={enterInput} />
              </div>
              <div className="col20">
              Vencimiento de Licencia
                <MyDateInput
                  placeholderText="Vencimiento de Licencia"
                  name="vencimientoLicencia"
                  dateFormat="dd/MM/yyyy"
                  tabIndex={5} enterInput={enterInput}
                />
              </div>
              <Segment clearing>
              <Button
                type="button"
                class="ui primary button"
                tabIndex={3}
                onKeyDown={tabuladorButton}
                loading={loading}
                onClick={() =>
                  openModal(
                    <Direccion
                      direccion={direccion}
                      setDireccion={setDireccion}
                      closeModals={closeModals}
                      tabIndex={4}
                    />,
                    enumHeaderModalsDirecciones.sucursal
                  )
                }
                floated="left"
                color="blue"
              >
                <Icon name="map marker alternate" />
                Direccion
              </Button>
              <div className="col70">
                <Label.Group color="blue" size="mini">
                  <Label> {(personal.direccion ? "CP: " : "") + personal.direccion?.codigoPostal}</Label>
                  <Label> {(personal.direccion ? "calle: " : "") + personal.direccion?.calle}</Label>
                  <Label> {(personal.direccion ? "numero: " : "") + personal.direccion?.numero}</Label>
                  <Label> {(personal.direccion.numeroInterior ? "numero Interior: " + personal.direccion.numeroInterior : "")}</Label>
                  <Label> {(personal.direccion.colonia ? "Colonia: " + personal.direccion.colonia : "")}</Label>
                  <Label> {(personal.direccion.ciudad ? "Ciudad: " + personal.direccion.ciudad : "")}</Label>
                  <Label> {(personal.direccion.municipio ? "Municipio: " + personal.direccion.municipio : "")}</Label>
                </Label.Group>
              </div>
            </Segment>
              <div >
                Codigo Contable:
                <MyTextInput placeholder="Codigo contable" name="codigoContable" tabIndex={6} enterInput={enterInput}/>
              </div>
            </Segment>
            <Segment clearing>
              <div className="col30Block">
                <Button
                  type="button"
                  tabIndex={7}
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  disabled={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={setFechaObservacion}
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Moneda
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <div className="col80">
                <BarraFechaObservacion
                  fechaObservacion={personal.fechaObservacion}
                ></BarraFechaObservacion>
              </div>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/Personales"
                loading={loading}
                tabIndex={15}
                id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})