import { useState, useEffect, useRef } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label, Grid, GridColumn } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { ReporteVentas, ReporteVentasFormValues } from "../../../../app/models/venta/ReporteVentas";
import { urlApi,urlReports } from "../../../../app/api/agent";
import { OnKeyDownProps } from "../../../../app/models/myProps";
import { enumHeaderModalsBusqueda } from "../../../modals/MensajeModals";
import Busqueda from "../../../General/Busqueda";
import { enumTipoDato } from "../../../../app/common/form/types/myTextinput-types";

export default observer(function ReporteVentaForm() {
  const firstElement = 0;
  const lastElement = 19;
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { proveedorStore, generacionComprobanteStore, reporteVentasStore } = useStore();
  const { empresaSucursal } = proveedorStore
  const { obtenerSiguienteFolio, loadingInitial } = generacionComprobanteStore
  const { imprimirReporteVentas } = reporteVentasStore
  const [reporteVentas, setReporteVentas] = useState(new ReporteVentasFormValues());
  const TipoReporteByDropdown = [
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    },
    {
      key: 2,
      text: "A Totales",
      value: 2,
      contex: "A Totales",
    },
  ];

  const OrdenReporteByDropdown = [
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    },
    {
      key: 2,
      text: "Descendente",
      value: 2,
      contex: "Descendente",
    },
  ];
  const inputEmpresaRef = useRef<any>(null);
  const inputSucursalRef = useRef<any>(null);
  const inputTipoVentaRef = useRef<any>(null);
  const inputSerieRef = useRef<any>(null);
  const inputClienteRef = useRef<any>(null);
  const inputCuentaDeVentaRef = useRef<any>(null);
  const inputUsuarioTrabajadorRef = useRef<any>(null);
  const inputUsuarioVendedorRef = useRef<any>(null);
  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().required("El folio es requerido."),
    fechaFin: Yup.date().required("El folio es requerido."),
    tipoReporte: Yup.number()
      .min(1, "Favor de seleccionar un tipo Reporte.")
      .required("El tipo Reporte es requerido."),
    ordenado: Yup.number()
      .min(1, "Favor de seleccionar un Ordenado.")
      .required("El Ordenado es requerido."),
  });
  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    setReporteVentas((state) => ({
      ...state,
      nombre: "REPORTE DE VENTAS ", ordenado: "1",
      tipoReporte: "1",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }
  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(reporteVentas: ReporteVentas) {
    imprimirReporteVentas(reporteVentas).then(result => {
      if (result !== undefined) {
        let features =
          'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
          debugger
        window.open(urlApi + urlReports + result, '_blank', features);
      }
    })
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  function chageEnter(tabIndex: number) {
    obtenerSiguienteFolio(empresaSucursal);
  }

  function closeModals() {
    closeModal();
  }

  const closeModalsEmpresaAyuda = () => {
    if (inputEmpresaRef.current)
      inputEmpresaRef.current.Focus();
    closeModal();
  }

  const closeModalsSucursalAyuda = () => {
    if (inputSucursalRef.current)
      inputSucursalRef.current.Focus();
    closeModal();
  }

  const closeModalsTipoVentaAyuda = () => {
    if (inputTipoVentaRef.current)
      inputTipoVentaRef.current.Focus();
    closeModal();
  }

  const closeModalSerieAyuda = () => {
    if (inputSerieRef.current)
      inputSerieRef.current.Focus();
    closeModal();
  }

  const closeModalClienteAyuda = () => {
    if (inputClienteRef.current)
      inputClienteRef.current.Focus();
    closeModal();
  }

  const closeModalCuentaDeVentaAyuda = () => {
    if (inputCuentaDeVentaRef.current)
      inputCuentaDeVentaRef.current.Focus();
    closeModal();
  }

  const closeModalUsuarioVendedorAyuda = () => {
    if (inputUsuarioVendedorRef.current)
      inputUsuarioVendedorRef.current.Focus();
    closeModal();
  }
  const closeModalUsuarioTrabajadorAyuda = () => {
    if (inputUsuarioTrabajadorRef.current)
      inputUsuarioTrabajadorRef.current.Focus();
    closeModal();
  }

  const abrirBusqueda = (values: any, setFieldValue: any, name: string) => {
    openModal(
      <Busqueda
        name={name}
        values={values}
        tabIndex={32231213}
        closeModals={
          name === "Empresa" ? closeModalsEmpresaAyuda :
            (name === "Sucursal" ? closeModalsSucursalAyuda :
              (name === "TipoVenta" ? closeModalsTipoVentaAyuda :
                (name === "Serie" ? closeModalSerieAyuda :
                  (name === "Cliente" ? closeModalClienteAyuda :
                    (name === "CuentaDeVenta" ? closeModalCuentaDeVentaAyuda :
                      (name === "Vendedor" ? closeModalUsuarioVendedorAyuda :
                        (name === "Trabajador" ? closeModalUsuarioTrabajadorAyuda :
                          closeModals)))))))
        }
        onCopy={(data) => {
          if (data) {
            if (name === "Empresa")
              setFieldValue("empresa", (inputEmpresaRef.current.GetText() === "" ? "" : inputEmpresaRef.current.GetText() + ",") + data.clave);
            if (name === "Sucursal")
              setFieldValue("sucursal", (inputSucursalRef.current.GetText() === "" ? "" : inputSucursalRef.current.GetText() + ",") + data.clave);
            if (name === "TipoVenta")
              setFieldValue("tipoVenta", (inputTipoVentaRef.current.GetText() === "" ? "" : inputTipoVentaRef.current.GetText() + ",") + data.clave);
            if (name === "Serie")
              setFieldValue("serie", (inputSerieRef.current.GetText() === "" ? "" : inputSerieRef.current.GetText() + ",") + data.clave);
            if (name === "Cliente")
              setFieldValue("cliente", (inputClienteRef.current.GetText() === "" ? "" : inputClienteRef.current.GetText() + ",") + data.clave);
            if (name === "CuentaDeVenta")
              setFieldValue("cuentaVenta", (inputCuentaDeVentaRef.current.GetText() === "" ? "" : inputCuentaDeVentaRef.current.GetText() + ",") + data.clave);
            if (name === "Vendedor")
              setFieldValue("vendedor", (inputUsuarioVendedorRef.current.GetText() === "" ? "" : inputUsuarioVendedorRef.current.GetText() + ",") + data.clave);
            if (name === "Trabajador")
              setFieldValue("trabajador", (inputUsuarioTrabajadorRef.current.GetText() === "" ? "" : inputUsuarioTrabajadorRef.current.GetText() + ",") + data.clave);
          }
        }}
      />,

      name === "Empresa" ? enumHeaderModalsBusqueda.EmpresaBusqueda :
        name === "Sucursal" ? enumHeaderModalsBusqueda.SucursalBusqueda :
          name === "TipoVenta" ? enumHeaderModalsBusqueda.TipoVentaBusqueda :
            name === "Serie" ? enumHeaderModalsBusqueda.SerieBusqueda :
              name === "Cliente" ? enumHeaderModalsBusqueda.ClienteBusqueda :
                name === "CuentaDeVenta" ? enumHeaderModalsBusqueda.CuentaDeVentaBusqueda :
                  name === "Vendedor" ? enumHeaderModalsBusqueda.VendedorBusqueda :
                    name === "Trabajador" ? enumHeaderModalsBusqueda.TrabajadorBusqueda :
                      (enumHeaderModalsBusqueda.TipoVentaBusqueda),

      (name === "Empresa" || name === "Sucursal") ? "tiny" : "small",
      true, "search"
    );
  }
  const onKeyDownInputEmpresa = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Empresa");
    }
  }

  const onKeyDownInputSucursal = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Sucursal");
    }
  }

  const onKeyDownInputTipoVenta = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "TipoVenta");
    }
  }

  const onKeyDownInputSerie = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Serie");
    }
  }

  const onKeyDownInputCliente = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Cliente");
    }
  }

  const onKeyDownInputCuentaDeVenta = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "CuentaDeVenta");
    }
  }

  const onKeyDownInputUsuarioVendedor = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Vendedor");
    }
  }
  const onKeyDownInputUsuarioTrabajador = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, "Trabajador");
    }
  }

  if (loadingInitial) return <LoadingComponent />

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteVentas}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Reporte de Ventas' color='blue' />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  tabIndex={0}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                  change={chageEnter}
                  autoFocus={true}
                />
              </div>
              <div></div>
              <div className='col35'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={1}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}
                />
              </div>

              <div className='col35'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={2}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div></div>
              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>
              <Grid>

<GridColumn mobile={16} tablet={8} computer={8}>

                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={5}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputEmpresa}
                  ref={inputEmpresaRef}
                  tipoDato={enumTipoDato.NumericoFiltro}
                />
              </GridColumn>
              
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={6}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputSucursal}
                  ref={inputSucursalRef}
                />
              </GridColumn>
              
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Tipo Venta'
                  label="Tipo Venta"
                  name='tipoVenta'
                  maxLength={50}
                  tabIndex={7}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputTipoVenta}
                  ref={inputTipoVentaRef}
                />
              </GridColumn>
              
              
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Serie'
                  label="Serie"
                  name='serie'
                  maxLength={50}
                  tabIndex={8}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputSerie}
                  ref={inputSerieRef}
                />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Folio'
                  label="Folio"
                  name='folio'
                  maxLength={50}
                  tabIndex={9}
                  enterInput={enterInput} />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Cliente'
                  label="Cliente"
                  name='cliente'
                  maxLength={50}
                  tabIndex={10}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputCliente}
                  ref={inputClienteRef}
                />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Cuenta De Venta'
                  label="Cuenta De Venta"
                  name='cuentaVenta'
                  maxLength={50}
                  tabIndex={11}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputCuentaDeVenta}
                  ref={inputCuentaDeVentaRef}
                />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Vendedor'
                  label="Vendedor"
                  name='vendedor'
                  maxLength={50}
                  tabIndex={12}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputUsuarioVendedor}
                  ref={inputUsuarioVendedorRef}
                />
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <MyTextInput
                  placeholder='Trabajador'
                  label="Trabajador"
                  name='trabajador'
                  maxLength={50}
                  tabIndex={13}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInputUsuarioTrabajador}
                  ref={inputUsuarioTrabajadorRef}
                />
              </GridColumn>
              </Grid>
              
            </Segment>
            <Segment>
              <Button tabIndex={14} type="submit" color="blue">
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})
