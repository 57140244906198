export interface LoginForm  {
    username: string;
    password: string;
}

export class LoginFormValues implements LoginForm {
    username: string = "";
    password: string = "";

    constructor(init?: LoginForm) {
        Object.assign(this, init);
      }
    }