import { CuentaDeProveedorTipo } from "./CuentaDeProveedorTipo";

export interface CuentaDeProveedor {
    id: number;
    clave:number;
    nombre: string;
    abreviación: string;
    cuentaDeProveedorTipoId: number;
    cuentaDeProveedorTipo?: CuentaDeProveedorTipo;
}

export interface ICuentaDeProveedorFormValues extends Partial<CuentaDeProveedor>{
    selectedIdTipo: any;
}

export class CuentaDeProveedorFormValues implements ICuentaDeProveedorFormValues{
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    abreviación: string="";
    cuentaDeProveedorTipoId: number=0;
    cuentaDeProveedorTipo?:CuentaDeProveedorTipo;
    selectedIdTipo: undefined;

    constructor(init?: CuentaDeProveedor){
        Object.assign(this,init);
    }
}