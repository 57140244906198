import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import ConceptoDeInventarioList from './ConceptoDeInventarioList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import ConceptoDeInventarioForm from '../form/ConceptoDeInventarioForm';
import NotFound from '../../../../app/layout/NotFound';

export default observer(function ConceptoDeInventarioDashboard() {
  const { conceptoDeInventarioStore, tabsStore } = useStore();
  useEffect(() => {
    conceptoDeInventarioStore.loadConceptosDeInventarios();
  }, [conceptoDeInventarioStore]);

  if (conceptoDeInventarioStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h2' content='Conceptos de Inventario' color='blue' />
        </Grid.Column>
        <Grid.Column width={9}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear Concepto de Inventario', <ConceptoDeInventarioForm id={0} />)}
              content='Crear Concepto de Inventario' color='blue' />
            <Button onClick={() => tabsStore.addTab('Imprimir Concepto de Inventario', <NotFound />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => conceptoDeInventarioStore.loadConceptosDeInventarios()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <ConceptoDeInventarioList />
      </Grid.Column>
    </Grid>
  )
})