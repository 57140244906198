import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Cliente } from "../../models/gestionCliente/Cliente";
import { toast } from "react-toastify";
import { history } from "../../..";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";
import { setDate } from "../../common/util/util";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";


export default class ClienteStore {
    clienteRegistry = new Map<number, Cliente>();
    selectedCliente: Cliente | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    clienteByEmpresaSucursalRegistry = new Map<number, Cliente>();

    // pagination = new PaginationCliente();
    constructor() {
        makeAutoObservable(this)
    }
    get clienteById() {
        return Array.from(this.clienteRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }
    get clientesByDate() {
        return Array.from(this.clienteRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }

    fetchsClientes = async () => {
        try {
            const response = await agent.Clientes.list();
            return response;
        } catch (error) {
            console.error("Error al buscar Cliente", error);
        }
    };

    get ClientesByDropdown() {
        return entityByDropdown<Cliente>(Array.from(this.clienteRegistry.values()));
    }
    loadClienteByParamsDropdown = async (filtros: PaginationSpecParams, options: any = {}) => {
        try {
            const clientes = await agent.Clientes.listByEmpresaSucursal(filtros);
            return entityByDropdown<Cliente>(Array.from(clientes));
        } catch (error) {
            console.error("error", error);
        }
    }
    loadClienteByIdEmpresaSucursal = async (empresaSucursal: PaginationSpecParams) => {
        //debugger
        if (empresaSucursal.empresaId && empresaSucursal.sucursalId) {
            try {
                const clientes = await agent.Clientes.listByEmpresaSucursal(
                    empresaSucursal
                );
                clientes.forEach((cliente) => {
                    this.setClientebyEmpresaSucursal(cliente);
                });
            } catch (error) {
                console.warn(error);
            }
        }
    };
    private setClientebyEmpresaSucursal = (cliente: Cliente) => {
        this.clienteByEmpresaSucursalRegistry.set(cliente.id, cliente);
    };
    get clienteByEmpresaSucursalByDropdownByClave() {
        return entityByDropdown<Cliente>(Array.from(this.clienteByEmpresaSucursalRegistry.values()), true);
    }
    get clienteByEmpresaSucursalByDropdown() {
        return entityByDropdown<Cliente>(
            Array.from(this.clienteByEmpresaSucursalRegistry.values())
        );
    }
    loadClientes = async () => {
        //  debugger
        this.setLoadingInitial(true);
        try {
            const clientes = await agent.Clientes.list();
            clientes.forEach(cliente => {

                setDate<Cliente>(cliente);
                this.setCliente(cliente);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }




    getClientes = async () => {
        //  debugger
        this.setLoadingInitial(true);
        const clientes = await agent.Clientes.list();
        try {
            clientes.forEach((cliente) => {
                this.setCliente(cliente);
            });
            this.setLoadingInitial(false);
            return clientes;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return clientes;
        }
    };


    loadCliente = async (id: number) => {
        let cliente = this.getCliente(id);
        if (cliente) {
            this.selectedCliente = cliente;
            return cliente;

        } else {
            this.loadingInitial = true;
            try {
                cliente = await agent.Clientes.details(id);
                this.setCliente(cliente);
                this.selectedCliente = cliente;
                this.setLoadingInitial(false);
                return cliente;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }
    public setClienteByEmpSucRegClear = () => {
        this.clienteByEmpresaSucursalRegistry.clear();
    }
    private setCliente = (cliente: Cliente) => {
        this.clienteRegistry.set(cliente.id, cliente);
    }

    private getCliente = (id: number) => {
        return this.clienteRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCliente = async (cliente: Cliente) => {
        //  debugger
        this.loading = true;
        try {
            await agent.Clientes.create(cliente);
            runInAction(() => {
                this.selectedCliente = cliente;
                this.editMode = false;
                this.loading = false;
            })

            toast.success("Cliente creada con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
    updateCliente = async (cliente: Cliente) => {
        this.loading = true;
        try {
            await agent.Clientes.update(cliente);
            runInAction(() => {
                this.selectedCliente = cliente;
                this.editMode = false;
                this.loading = false;
            })

            toast.success("Cliente Actualizado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deleteCliente = async (id: number) => {
        this.loading = true;
        try {
            await agent.Clientes.delete(id);
            runInAction(() => {
                this.clienteRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
    obtenerSiguienteClave = async (filtros: PaginationSpecParams) => {
        try {
            const clave = await agent.Clientes.obtenerClave(filtros);
            return clave;
        } catch (error) {
            console.log(error);
        }
    };
    cargarCliente = async (filtros: PaginationSpecParams) => {
        try {
            debugger
            const clientes = await agent.Clientes.obtenerClientePorClave(filtros);
            if (clientes) {
                return clientes;
            }
        } catch (error) {
            console.log(error);
        }
    };
    cargarClientePorId = async (filtros: PaginationSpecParams) => {
        try {
            const clientes = await agent.Clientes.obtenerClientePorId(filtros);
            if (clientes) {
                return clientes;
            }
        } catch (error) {
            console.log(error);
        }
    };
}