import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Grid, Header, Button } from 'semantic-ui-react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import TipoVentaList from './TipoVentaList'
import TipoVentaForm from '../form/TipoVentaForm';
import ReporteTipoVentaForm from '../form/ReporteTipoVentaForm';

export default observer(function TipoVentaDashboard() {
  const { tipoVentaStore, tabsStore } = useStore();
  const { loadTiposVentas } = tipoVentaStore;
  useEffect(() => {
    tipoVentaStore.loadTiposVentas();
  }, [tipoVentaStore]);

  useEffect(() => {
    loadTiposVentas();
  }, [loadTiposVentas]);

  if (tipoVentaStore.loadingInitial) return <LoadingComponent />
  
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h1' icon='warehouse' content='Tipos de Ventas' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Tipo de Venta', <TipoVentaForm id={0} />)}
              content="Crear Cuenta Venta" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Tipo de Venta', <ReporteTipoVentaForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => tipoVentaStore.loadTiposVentas()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <TipoVentaList />
      </Grid.Column>
    </Grid>
  )
})