import { useState, useEffect } from 'react'
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { useStore } from '../../../../app/stores/store';
import { Table, Segment, Header, Label, TabPane, Tab, Grid } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Formik, Form, FieldArray } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { EstadoCuenta, EstadoCuentaDetalles, EstadoCuentaFormValues, TotalesMensuales } from '../../../../app/models/gestionCliente/EstadoCuenta';
import { formatDecimal } from '../../../../app/base/Tools';
import moment from 'moment';
import MyDataGridRows from '../../../../app/common/form/MyDataGridRows';
import { meses } from '../../../../app/common/options/monthsYear';

export default observer(function EstadoCuentaClienteForm() {
  const lastElementTable = 242;
  const firstElement = 0;
  const { empresaStore, sucursalStore, estadoCuentaStore, cuentaDeClienteStore, clienteStore } = useStore();
  const { clienteByEmpresaSucursalByDropdownByClave, setClienteByEmpSucRegClear, loadClienteByIdEmpresaSucursal } = clienteStore
  const { EmpresasByDropdownByClave, loadEntityEmpresas } = empresaStore
  const { cuentaClienteByDropdownByClave, loadCuentasClientes } = cuentaDeClienteStore
  const { loadSucursalByIdEmpresa, sucursalesByEmpresaByDropdownByClave } = sucursalStore
  const { detallesById, setDetallesByIdRegClear, loadingInitial, loadEstadoCuenta, estadoCuentaParams } = estadoCuentaStore
  const [estadoCuentaFormValues] = useState(new EstadoCuentaFormValues());
  const [mesSeleccionado, setMesSeleccionado] = useState('');
  const selectInputMesDropOnChange = (event: any) => { setMesSeleccionado(event); };
  const filtrarRegistrosPorMes = () => {
    //si viene basio o cero
    /// if (!mesSeleccionado) return [];
    if (!mesSeleccionado) return detallesById;
    return detallesById.filter((detalle: EstadoCuentaDetalles) => {
      return detalle.mes === parseInt(mesSeleccionado, 10);
    });
  };
  const validationSchema = Yup.object({
    empresaId: Yup.number()
      .min(1, "Favor de seleccionar una Empresa.")
      .required("Empresa es requerido."),
    sucursalId: Yup.number()
      .min(1, "Favor de seleccionar una Sucursal.")
      .required("Sucursal es requerido."),
    cuentaDeClienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cuenta.")
      .required("Cuenta es requerido."),
    clienteId: Yup.number()
      .min(1, "Favor de seleccionar una Cliente.")
      .required("Cliente es requerido."),
    comprobantesSaldo: Yup.array().of(
      Yup.object().shape({
      })
    ),
  });

  useEffect(() => {
    void loadCuentasClientes();
    void loadEntityEmpresas();
  }, []);

  const handleSubmit = (estadoCuenta: EstadoCuenta) => {
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElementTable + 3);
  }

  const selectInputEmpresaOnChange = (idSelected: number, setFieldAny: any, index: any, values: EstadoCuenta) => {
    setFieldAny("sucursalId", 0);
    setFieldAny("clienteId", 0);
    setClienteByEmpSucRegClear();
    estadoCuentaParams.empresaId = 0;
    estadoCuentaParams.sucursalId = 0;
    estadoCuentaParams.clienteId = 0;
    void loadSucursalByIdEmpresa(idSelected);
    estadoCuentaParams.empresaId = idSelected;
  }
  const selectInputSucursalOnChange = (idSelected: number, setFieldAny: any) => {
    setFieldAny("clienteId", 0);
    setClienteByEmpSucRegClear();
    estadoCuentaParams.clienteId = 0;
    estadoCuentaParams.sucursalId = idSelected;
    void loadClienteByIdEmpresaSucursal(estadoCuentaParams);
  }
  const selectInputClienteOnChange = (idSelected: any) => {
    estadoCuentaParams.clienteId = parseInt(idSelected);

  }
  const selectInputCuentaOnChange = (idSelected: any) => {
    estadoCuentaParams.cuentaId = idSelected;

  }
  const selectInputClienteOnBluer = (setFieldValue: any) => {
    setDetallesByIdRegClear();
    asignarDatos(setFieldValue);
  }

  const asignarDatos = (setFieldValue?: any) => {
    if (estadoCuentaParams.clienteId > 0 && estadoCuentaParams.empresaId > 0 && estadoCuentaParams.sucursalId > 0 && estadoCuentaParams.cuentaId > 0) {
      loadEstadoCuenta(estadoCuentaParams).then((result) => {
        if (result) {
          setFieldValue("totalesMensuales", result.totalesMensuales);
          setFieldValue("totales", result.totales);
        }
        else {
          setFieldValue("totalesMensuales", []);
        }
      })
    } else {
      setFieldValue("totalesMensuales", []);
    }
  }

  const panes = (values: any) => [
    {
      menuItem: 'Totales Mensuales', render: () => <TabPane tabIndex={5}>
        <FieldArray
          name="totalesMensuales"
          render={() => (
            <Segment basic className="tabla-container">
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Mes</Table.HeaderCell>
                    <Table.HeaderCell>Saldo Anterior</Table.HeaderCell>
                    <Table.HeaderCell>Cargos</Table.HeaderCell>
                    <Table.HeaderCell>Abonos</Table.HeaderCell>
                    <Table.HeaderCell>Saldo Actual</Table.HeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values.totalesMensuales?.map(
                    (item: TotalesMensuales, index: any) => (
                      <Table.Row key={index.valueOf()}>
                        <MyDataGridRows width="100px" value={new Date(2022, item.mes - 1, 1).toLocaleString('default', { month: 'long' }).toUpperCase()} alineacion="center" />
                        <MyDataGridRows width="40px" value={formatDecimal(item?.saldoAnterior, 2)} alineacion="center" />
                        <MyDataGridRows width="100px" value={formatDecimal(item?.totalCargos, 2)} alineacion="center" />
                        <MyDataGridRows width="90px" value={formatDecimal(item?.totalAbonos, 2)} alineacion="center" />
                        <MyDataGridRows width="50px" value={formatDecimal(item.saldoActual, 2)} alineacion="center" />
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Segment>
          )}
        />
      </TabPane>
    },
    {
      menuItem: 'Detalles', render: () => <TabPane tabIndex={5}>
        <Segment basic className="tabla-container">
          <MySelectInput
            placeholder="Selecciona un mes"
            name="mesDrop"
            options={meses}
            onChange={selectInputMesDropOnChange}
          />
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Movto</Table.HeaderCell>
                <Table.HeaderCell>Emisión</Table.HeaderCell>
                <Table.HeaderCell>Cuenta</Table.HeaderCell>
                <Table.HeaderCell>Concepto</Table.HeaderCell>
                <Table.HeaderCell>SerieFolio</Table.HeaderCell>
                <Table.HeaderCell>Afecta</Table.HeaderCell>
                <Table.HeaderCell>Vencto</Table.HeaderCell>
                <Table.HeaderCell>Referencia</Table.HeaderCell>
                <Table.HeaderCell>Cargos</Table.HeaderCell>
                <Table.HeaderCell>Abonos</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filtrarRegistrosPorMes().map((detalle, index) => (
                <Table.Row key={index}>
                  <MyDataGridRows width="100px" value={detalle.movto} alineacion="center" />
                  <MyDataGridRows width="80px" value={moment(detalle?.emisión).format("DD/MM/YYYY")} alineacion="center" />
                  <MyDataGridRows width="60px" value={detalle?.cuenta} alineacion="center" />
                  <MyDataGridRows width="100px" value={detalle.concepto} alineacion="center" />
                  <MyDataGridRows width="100px" value={detalle.serieFolio} alineacion="center" />
                  <MyDataGridRows width="100px" value={detalle.afecta === "0" ? "" : detalle.afecta} alineacion="center" />
                  <MyDataGridRows width="80px" value={moment(detalle?.vencto).format("DD/MM/YYYY")} alineacion="center" />
                  <MyDataGridRows width="100px" value={detalle.referencia} alineacion="center" />
                  <MyDataGridRows width="90px" value={formatDecimal(detalle.cargos, 2)} alineacion="right" />
                  <MyDataGridRows width="90px" value={formatDecimal(detalle.abonos, 2)} alineacion="right" />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment></TabPane>
    },
  ]

  const TabCapturaPago = (values: any) => <Tab panes={panes(values)} />

  if (loadingInitial) return <LoadingComponent />

  const SearchFilters = () => {
    return (
      <Segment clearing>
        <div>
          <Label as="a" color="red" ribbon>
            Filtros
          </Label>
        </div>
        <Grid  >
          <Grid.Column width={2}>
            <div className="col14">
              <MySelectInput
                autoFocus={true}
                tabIndex={1}
                placeholder="Empresa"
                label="Empresa"
                name="empresaId"
                options={EmpresasByDropdownByClave}
                enterInput={enterInput}
                onChange={selectInputEmpresaOnChange}
                inputWidth="85px"
                menuWidth="300px"
              />
            </div>
          </Grid.Column>

          <Grid.Column width={2}>
            <div className="col14">
              <MySelectInput
                tabIndex={2}
                placeholder="Sucursal"
                label="Sucursal"
                name="sucursalId"
                options={sucursalesByEmpresaByDropdownByClave}
                enterInput={enterInput}
                onChange={selectInputSucursalOnChange}
                inputWidth="85px"
                menuWidth="300px"
              />
            </div>
          </Grid.Column>

          <Grid.Column width={2}>
            <div className="col14">
              <MySelectInput
                tabIndex={3}
                placeholder="Cuenta"
                label="Cuenta"
                name="cuentaDeClienteId"
                options={cuentaClienteByDropdownByClave}
                enterInput={enterInput}
                onChange={selectInputCuentaOnChange}
                inputWidth="85px"
                menuWidth="300px"
              />
            </div>
          </Grid.Column>

          <Grid.Column width={10}>
            <div className="col14">
              <MySelectInput
                tabIndex={4}
                placeholder="Cliente"
                label="Cliente"
                name="clienteId"
                options={clienteByEmpresaSucursalByDropdownByClave}
                enterInput={enterInput}
                onChange={selectInputClienteOnChange}
                selectWithLabel={true}
                onBlur={selectInputClienteOnBluer}
                inputWidth="85px"
                menuWidth="300px"
              />
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  return (
    <Segment clearing>
      <Formik
        initialValues={estadoCuentaFormValues}
        validationSchema={validationSchema}
        onSubmit={
          handleSubmit
        }
      >
        {({ values }) => (
          <Form className='ui form' >
            <Header as='h2' icon='id badge outline' content='Estado de Cuenta de Cliente' color='blue' />
            {SearchFilters()}
            {TabCapturaPago(values)}
            <Segment clearing>
              <div style={{ display: 'flex', fontWeight: 'bold' }}>
                <div style={{ width: '20%' }}>
                  Total Meses: {values.totales?.mes}
                </div>
                <div style={{ width: '40%' }}>
                  Saldo Anterior: {formatDecimal(values.totales?.saldoAnterior, 2)}
                </div>
                <div style={{ width: '22%' }}>
                  Cargo: {formatDecimal(values.totales?.totalCargos, 2)}
                </div>
                <div style={{ width: '33%' }}>
                  Abono: {formatDecimal(values.totales?.totalAbonos, 2)}
                </div>
                <div style={{ width: '37%' }}>
                  Saldo Actual: {formatDecimal(values.totales?.saldoActual, 2)}
                </div>
              </div>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})