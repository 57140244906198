import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import ImpuestoForm from '../form/ImpuestoForm';

export default observer(function ImpuestoList() {
    const { tabsStore, impuestoStore } = useStore();
    const { modalStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { deleteImpuesto, impuestosById, loading, } = impuestoStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteImpuesto(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Tipo</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {impuestosById.map(impuesto => (
                    <Table.Row key={impuesto.id}>
                        <Table.Cell>{impuesto.clave}</Table.Cell>
                        <Table.Cell>{impuesto.nombre}</Table.Cell>
                        <Table.Cell>{impuesto.impuestoTipo?.nombre}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Editar Impuestos', <ImpuestoForm id={impuesto.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button
                                name={impuesto.id}
                                loading={loading && target === String(impuesto.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={impuesto.id}
                                        />,
                                        enumHeaderModals.Impuesto
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
})