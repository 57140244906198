import { makeAutoObservable, runInAction } from 'mobx';
import { InsertaInfoCatalogo } from '../../models/HerramientaExtra/InsertaObtenerInfoCatalogo';
import agent from '../../api/agent';
import { history } from '../../..';
import { toast } from 'react-toastify';

export default class InsertaObtenerInfoCatalogoStore {
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }
    private getFormInsertaInfoCatalogo = (insertaInfoCatalogo: InsertaInfoCatalogo) => {
        let formData = new FormData();
        formData.append("catalogoInsertaId", insertaInfoCatalogo.catalogoInsertaId.toString());
        formData.append("archivoInserta", insertaInfoCatalogo.archivoInserta!);
        return formData;
    };
    createInfoCatalogo = async (insertaInfoCatalogo: InsertaInfoCatalogo) => {
        this.loading = true;
        try {
            await agent.InsertaInfoCatalogos.create(this.getFormInsertaInfoCatalogo(insertaInfoCatalogo));
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
            
            toast.success("Se inserto con éxito");
        } catch (error: any) {
            // El error ya fue manejado y mostrado por el interceptor
            if (error) {
                console.error('Error:', error);
            }
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}