import { Empresa } from "../configuracion/Empresa";
import { Moneda } from "../configuracion/Moneda";
import { Sucursal } from "../configuracion/Sucursal";
import { Usuario } from "../configuracion/Usuario";
import { Cliente } from "./Cliente";
import { ConceptoCliente } from "./ConceptoCliente";
import { CuentaDeCliente } from "./CuentaDeCliente";
import { FormaDePago } from "./FormaDePago";
export interface EstadoCuenta extends TotalesMensuales {
    totalesMensuales: TotalesMensuales[];
    estadoCuentaDetalles: EstadoCuentaDetalles[];
    totales?: TotalesMensuales;
    //
    mesDrop: number;
    totalFacturas?: number;
    totalSaldo?: number;
    subtotalAbono: number;
    ivaAbono: number;
    importe: number;
}
export class EstadoCuentaFormValues implements EstadoCuenta {
    mesDrop: number = 0;
    id: number = 0;
    clave: number = 0;
    nombre: string = "";
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    cuentaDeClienteId: number = 0;
    cuentaDeCliente?: CuentaDeCliente;
    clienteId: number = 0;
    cliente?: Cliente;
    todo?: boolean = false;
    limiteCredito?: number = 0;
    saldo?: number = 0;
    saldoDisponible?: number = 0;
    creditoSuspendido?: boolean = false;
    abonar: number = 0;
    abonado?: number = 0;
    disponible?: number = 0;
    numOperacion?: string = '';
    conceptoDeClienteId?: number = 0;
    conceptoDeCliente?: ConceptoCliente;
    formaDePagoId?: number = 0;
    formaDePago?: FormaDePago;
    esAnticipo?: boolean = false;
    fecha?: Date = new Date();
    fechaDeposito?: undefined;
    monedaId?: number = 0;
    moneda?: Moneda;
    usuarioId?: number = 0;
    usuario?: Usuario;
    folio?: number = 0;
    abono: number = 0;
    observaciones?: string = "";
    totalesMensuales: TotalesMensuales[] = [];
    estadoCuentaDetalles: EstadoCuentaDetalles[] = [];
    saldoAnterior: number = 0;
    movimientoClienteDetalleId?: number = 0;
    //
    mes: number = 0;
    totalCargos: number = 0;
    totalAbonos: number = 0;
    saldoActual: number = 0;
    //
    totalFacturas?: number = 0;
    totalSaldo?: number = 0;
    subtotalAbono: number = 0;
    ivaAbono: number = 0;
    importe: number = 0;
    totales?: TotalesMensuales;
}
export interface TotalesMensuales {
    mes: number;
    totalCargos: number;
    totalAbonos: number;
    saldoAnterior: number;
    saldoActual: number;

}

export interface EstadoCuentaDetalles {
    id: number;
    mes: number;
    movto: number;
    emisión: Date;
    cuenta: number;
    concepto: string;
    serieFolio: string;
    afecta: string;
    vencto: Date;
    referencia: string;
    cargos: number;
    abonos: number;
}