import { Label } from "semantic-ui-react";
import { FechaObservacion } from "../../app/models/configuracion/FechaObservacion";
import { dateFormatOptions } from "../../app/common/options/dateFormatOptions";

interface Props {
  className?: string;
  fechaObservacion: FechaObservacion;
}
export default function BarraFechaObservacion(props: Props) {
  const { fechaObservacion } = props;
  return (
    <Label.Group color="blue" size="mini">
      {fechaObservacion?.alta ? (
        <Label>
          Alta:{" "}
          <strong>
            {fechaObservacion?.alta.toLocaleString("es", dateFormatOptions)}
          </strong>
        </Label>
      ) : null}
      {fechaObservacion?.modificacion ? (
        <Label>
          Cambio:{" "}
          <strong>
            {fechaObservacion?.modificacion.toLocaleString(
              "es",
              dateFormatOptions
            )}
          </strong>
        </Label>
      ) : null}
      {fechaObservacion?.observaciones ? (
        <Label>
          Observacion: <strong>{fechaObservacion?.observaciones}</strong>
        </Label>
      ) : null}
      {fechaObservacion?.baja ? (
        <Label>
          Baja:{" "}
          <strong>
            {fechaObservacion?.baja.toLocaleString("es", dateFormatOptions)}
          </strong>
        </Label>
      ) : null}
    </Label.Group>
  );
}
