import { useField, useFormikContext } from 'formik';
import { Form, Label } from "semantic-ui-react";
import { useTipoDato } from '../../hooks/useTipoDato';
import { enumTipoDato } from './types/myTextinput-types';
import { forwardRef, useRef, useImperativeHandle, memo } from 'react';
import { OnChangeProps, OnKeyDownProps } from '../../models/myProps';
//import numeral from 'numeral';

interface Props {
  placeholder?: string;
  name: string;
  label?: string;
  className?: string;
  maxLength?: number;
  tipoDato?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  pattern?: string;
  tabIndex?: number;
  enterInput?: (e: number) => void;
  type?: string;
  fieldName?: any;
  onChangeProps?: (props: OnChangeProps) => boolean | void;
  onChange?: (idSelected?: any, setFieldValue?: any) => boolean | void;
  /*onKeyUp?: (id: any, setFieldValue?: any, key?: any, arrayHelpers?: any) => void;*/
  onKeyDownProps?: (props: OnKeyDownProps) => boolean | void;
  onKeyDown?: (id: any, setFieldValue?: any, key?: any, arrayHelpers?: any, index?: any, columna?: any, values?: any, isValid?: any) => boolean | void;
  change?: (text: any) => void;
  arrayHelpers?: any;
  index?: any;
  columna?: any;
  aceptaNegativos?: any;
  formato?: any;
  decimalDigits?: any;
  style?: any;
  dataIndex?: any;
  onBlur?: (setFieldValue?: any, values?: any, index?: any, text?: any) => boolean | void;
  // accept?: string;
}

const MyTextInput = memo(forwardRef(function MyTextInput(props: Readonly<Props>, ref) {
  const inputRef = useRef<any>(null);
  const texto = useRef<any>();
  //let [texto, setTexto] = useState("");
  const { setFieldValue, values, isValid, setValues } = useFormikContext<any>();
  const [validateText, validateTextBlur] = useTipoDato();
  const [field, meta, helpers] = useField(props);
  useImperativeHandle(ref, () => {
    return {
      Focus(milisegundos = 0) { FocusControl(milisegundos); },
      ScrollIntoView() {
        inputRef.current.scrollIntoView();
      },
      SetText(texto: string) {
        if (!texto) texto = "";
        setTexto(texto);
      },
      GetText() {
        return inputRef.current.value;
      },
      Clear: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    };
  });
  const setTexto = (texto: any) => {
    helpers.setValue(texto);
  }
  const FocusControl = (milisegundos = 0) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
        inputRef.current.focus();
      }
    }, milisegundos);
  }
  const onKeyDown = (event: any) => {

    let esOnKeyDown;
    // if (event.key !== "Enter" && event.key !== "Tab") {
    //   return;
    // }
    if (props.onKeyDown) {
      esOnKeyDown = props.onKeyDown?.(field.value, setFieldValue, event, props?.arrayHelpers, props?.index, props?.columna, values, isValid);
    } else {
      esOnKeyDown = props.onKeyDownProps?.({ index: props.index, name: props.fieldName, value: field.value, arrayHelpers: props.arrayHelpers, event: event, values: values, setFieldValue: setFieldValue, setValues: setValues });
    }
    if (esOnKeyDown !== false) {
      if (event.key === "Enter" || event.key === "Tab") {
        let element = event.target;
        let tabIndex = element.tabIndex;
        props.enterInput?.(tabIndex);
        event.preventDefault();
      }
    }
  };
  const OnKeyUp = (event: any) => {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
  }
  const onChangeValidation = (e: any) => {
    let startPos = e.target.selectionStart;
    const esValido = e.target.validity.valid;
    let esValidoOnChange;
    if (esValido) {
      if (props.tipoDato && props.tipoDato !== enumTipoDato.Email) {
        validateText(e.target.value, props.tipoDato, props.aceptaNegativos, async (isValid: any, value: any) => {
          if (isValid) {
            texto.current = value;
            //  helpers.setValue(value);
          } else {
            setTimeout(() => {
              e.target.setSelectionRange(startPos - 1, startPos - 1);
            }, 1);
          }
        }, props.decimalDigits);
      } else {
        texto.current = e.target.value;
        //  setTexto((state) => value);
        // helpers.setValue(e.target.value);
      }
      if (props.onChange) {
        esValidoOnChange = props.onChange?.(e.target.value, setFieldValue);
      } else {
        esValidoOnChange = props.onChangeProps?.({ index: props.index, name: props.fieldName, value: texto.current, values: values, setFieldValue: setFieldValue });
      }
      if (esValidoOnChange !== false) {
        helpers.setValue(texto.current);
      } else {
        setTimeout(() => {
          e.target.setSelectionRange(startPos - 1, startPos - 1);
        }, 1);
      }
      // props.onChange?.(e.target.value, setFieldValue);
    }
  };

  const onBlurValidation = () => {
    let esValidoonBlur;
    texto.current = field.value;
    validateTextBlur(field.value, props.tipoDato, "", async (isValid: any, value: any) => {
      let _nt = "";
      if (isValid) {
        if (value === "" && props.tipoDato === enumTipoDato.Numerico) {
          texto.current = 0;
        } else {
          _nt = value;
          texto.current = value;
        }
      } else {
        if (props.tipoDato === enumTipoDato.Numerico) {
          _nt = '0';
          texto.current = _nt;
        } else {
          _nt = "";
          texto.current = _nt;
        }
      }
    }, "");
    esValidoonBlur = props.onBlur?.(setFieldValue, values, props?.index, texto.current);
    if (esValidoonBlur !== false) {
      helpers.setValue(texto.current);
    }
    //props.onBlur?.(setFieldValue, values, props?.index);
    // helpers.setValue(ObtenerTextoFormateado(field.value));
  }
  // const ObtenerTextoFormateado = (text: any) => {
  //   if (props.formato?.length > 0) {
  //     // text = numeral(text).format(props.formato)
  //   }
  //   return text;
  // }
  return (
    <Form.Field className="width:5rem" error={meta.touched && !!meta.error}>
      <div className="form-group">
        <label className="uiAnchoCombo heighttext">{props.label}</label>
        <input
          style={props.style}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          className={
            props.className ? props.className : " widthtext heighttext input"
          }
          {...field}
          // value={props.type === "file" ? undefined : field.value || ""}
          //value={field.value || ""}
          value={field.value || field.value === 0 ? field.value : ""}
          // accept={props.accept}
          maxLength={props.maxLength}
          type={props.type}
          ref={inputRef}
          pattern={props.pattern}
          tabIndex={props.tabIndex}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
          onKeyUp={OnKeyUp}
          onChange={onChangeValidation}
          onBlur={onBlurValidation}
          data-index={props.dataIndex}
        />
      </div>
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}));
export default MyTextInput;