export interface PaginationSpecParams {
  maxPageSize?: number;
  pageIndex?: number;
  pageSize?: number;
  sort?: string;
  pageNumber: number;

  search?: string;
  empresaId: number;
  sucursalId: number;
  id: number;
  clave: number;
  claveString?: string;
  folio: number;
  nombre: string;
  tipoVentaId: number;
  tipoVentaTipoId?: number;
  tipoVentaTipoClave?: number;
  serieId: number;
  tipoCompraId: number;
  serieCompraId: number;
  tipoMovimientoId: number
  ///control lista precio
  listaDePrecioId?: number;
  listaDePrecio2Id?: number;
  proveedorName?: string;
  categoriaName?: string;
  monedaName?: string;
  fechaModificacion?: Date;
  //fin control lista precio
  //serie folio Compra
  claveSerieFolioCompra?: string;
  clienteId: number;
  productoId: number;
  almacenId: number;
  cuentaId: number;
  ///facturaGlobal
  fechaInicio?: Date;
  fechaFin?: Date;
  folios?: string;
  mesId?: number;
  periodicidadId?: number;
  facturado?: number;
  //reporte productos vendidos
  tipoReporte?: string;
  ordenado?: string;
  empresa?: string;
  sucursal?: string;
  noArticluosIncluir?: number;
  tipoComprobante?: string;
  serie?: string;
  estado?: number;
  cuentaVenta?: string;
  cliente?: string;
  RFC?: string;
  moneda?: string;
  vendedor?: string;
  trabajador?: string;
  esVendedor?: boolean;
  esTrabajador?: boolean;
  esComprador?: boolean;
  generacionComprobanteId?: number;
  //reporte comprados
  cuentaCompra?: string;
  proveedor?: string;
  comprador?: string;
  uuid?: string;
  conceptoInventario?: string;
}
export class PaginationFormValues implements PaginationSpecParams {
  id: number = 0;
  clave: number = 0;
  claveString?: string = "";
  folio: number = 0;
  nombre: string = "";
  sort: string = "nombre";
  pageNumber: number = 1;
  pageSize: number = 12;
  empresaId: number = 0;
  sucursalId: number = 0;
  tipoMovimientoId: number = 0;
  tipoVentaId: number = 0;
  tipoVentaTipoId: number = 0;
  tipoVentaTipoClave?: number = 0;
  serieId: number = 0;
  tipoCompraId: number = 0;
  serieCompraId: number = 0;
  clienteId: number = 0;
  productoId: number =0;
  almacenId: number =0;
  cuentaId: number = 0;
  generacionComprobanteId?: number = 0;
  listaDePrecioId?: number = 0;
  listaDePrecio2Id?: number = 0;
  esVendedor?: boolean = false;
  esTrabajador?: boolean = false;
  esComprador?: boolean = false;
  uuid?: string = "";
  conceptoInventario?: string = "";
  constructor(init?: Partial<PaginationFormValues>) {
    Object.assign(this, init);
  }
}
