import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { VehiculoTipo } from "./VehiculoTipo";
import { Departamento } from "./Departamento";
import { Personal } from "./Personal";

//TODO: id and clave pased of number to string on this case
export interface Vehiculo {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    clave: number;
    nombre: string;
    vehiculoTipoId: number;
    vehiculoTipo?: VehiculoTipo;
    departamentoId: number;
    departamento?: Departamento;
    choferId: number ;
    chofer?:Personal;
    ayudanteId: number;
    ayudante?:Personal;
    odometro: number;
    horometro: number;
    fueraServicio: boolean;
    anio: number;
    marca: string;
    color: string;
    numeroEjes: number;
    
    serie: string;
    serieBateria: string;
    placas: string;
    renovarPlaca: Date;
    venceSeguro: Date;
    companiaSeguro: string;
    numeroPoliza: string;
    
    motor: string;
    trasmision: string;
    tamanoLlantas: string;
    codigoContable: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}


export class VehiculoFormValues implements Vehiculo {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    clave: number = 0;
    nombre: string = '';
    vehiculoTipoId: number = 0;
    vehiculoTipo?: VehiculoTipo;
    departamentoId: number = 0;
    departamento?: Departamento;
    choferId: number = 0;
    chofer?:Personal;
    ayudanteId: number = 0;
    ayudante?:Personal;
    odometro: number = 0;
    horometro: number = 0;
    fueraServicio: boolean = false;
    anio: number = 0;
    marca: string = '';
    color: string = '';
    numeroEjes: number = 0;

    serie: string = '';
    serieBateria: string = '';
    placas: string = '';
    renovarPlaca: Date = new Date();;
    venceSeguro: Date = new Date();;
    companiaSeguro: string = '';
    numeroPoliza: string = '';
    
    motor: string = '';
    trasmision: string = '';
    tamanoLlantas: string = '';
    codigoContable: string = '';
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    constructor(init?: Vehiculo) {
        Object.assign(this, init);
      }

}

