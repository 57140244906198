import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { MovimientoDeProveedor } from "../../models/gestionProveedor/MovimientoDeProveedor";
import { Entity } from "../../models/Entity";
import agent from "../../api/agent";
import { PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
import { setDate } from "../../common/util/util";
export default class MovimientoDeProveedorStore {
    movimientoDeProveedorRegistry = new Map<number, MovimientoDeProveedor>();
    movimientoDeProveedorEntity = new Map<number, Entity>();
    selectedMovimientoDeProveedor: MovimientoDeProveedor | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    constructor() {
        makeAutoObservable(this);
    }

    get MovimientosDeProveedoresById() {
        return Array.from(this.movimientoDeProveedorRegistry.values()).sort(
            (a, b) => a.clave - b.clave
        );
    }

    createMovimientoDeProveedor = async (movimientoDeProveedor: MovimientoDeProveedor) => {
        debugger
        this.loading = true;
        try {
            await agent.MovimientosDeProveedores.create(movimientoDeProveedor);
            runInAction(() => {
                this.selectedMovimientoDeProveedor = movimientoDeProveedor;
                this.editMode = false;
                this.loading = false;
            });
            toast.success("Movimiento De Proveedor creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };


    deleteMovimientoDeProveedor = async (id: number) => {
        if (id > 0) {
            this.loading = true;
            try {
                await agent.MovimientosDeProveedores.delete(id);
                runInAction(() => {
                    this.movimientoDeProveedorRegistry.delete(id);
                    this.loading = false;
                });
                toast.success("Movimiento de Proveedor eliminado con éxito");
            } catch (error) {
                toast.error("Problema al eliminar Movimiento De Proveedor");
                runInAction(() => {
                    this.loading = false;
                });
            }
        }
    };
    obtenerSiguienteFolio = async (filtros: PaginationSpecParams) => {
        if (filtros.empresaId && filtros.sucursalId && filtros.tipoMovimientoId) {
            try {
                const movimientoDeProveedor = await agent.MovimientosDeProveedores.obtenerFolio(filtros);
                return movimientoDeProveedor;

            } catch (error) {
                console.log(error);
            }
        } else {
        }
    };

    cargarMovimiento = async (filtros: PaginationSpecParams) => {
        try {
            const movimientoDeProveedor = await agent.MovimientosDeProveedores.obtenerMovimientoPorClave(filtros);
            if (movimientoDeProveedor) {
                movimientoDeProveedor.fecha = new Date(movimientoDeProveedor.fecha)
                setDate<MovimientoDeProveedor>(movimientoDeProveedor);
            }
            return movimientoDeProveedor;
        } catch (error) {
            console.log(error);
        }
    };
}