import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import ConceptoDeProveedorForm from '../form/ConceptoDeProveedorForm';

export default observer(function ConceptoProveedorList() {
    const { conceptoDeProveedorStore, tabsStore } = useStore();
    const { modalStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { deleteConceptoDeProveedor, conceptoDeProveedorById, loading } = conceptoDeProveedorStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteConceptoDeProveedor(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Abreviación</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {conceptoDeProveedorById.map(conceptoDeProveedor => (
                    <Table.Row key={conceptoDeProveedor.id}>
                        <Table.Cell>{conceptoDeProveedor.clave}</Table.Cell>
                        <Table.Cell>{conceptoDeProveedor.nombre}</Table.Cell>
                        <Table.Cell>{conceptoDeProveedor.abreviacion}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Crear Concepto de Proveedor', <ConceptoDeProveedorForm id={conceptoDeProveedor.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button
                                name={conceptoDeProveedor.id}
                                loading={loading && target === String(conceptoDeProveedor.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={conceptoDeProveedor.id}
                                        />,
                                        enumHeaderModals.Impuesto
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
})