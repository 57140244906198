import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Grid, Header, Button } from "semantic-ui-react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import MonedaList from "./MonedaList";
import MonedaForm from "../form/MonedaForm";
import ReporteMonedaForm from "../form/ReporteMonedaForm";
export default observer(function MonedaDashboard() {
  const { tabsStore, monedaStore } = useStore();

  useEffect(() => {
    monedaStore.loadMonedas();
  }, [monedaStore]);

  if (monedaStore.loadingInitial) return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2" icon="money" content="Monedas" color="blue"></Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button onClick={() => tabsStore.addTab('Crear Monedas', <MonedaForm id={0} />)}
              content="Crear Moneda" color="blue" />
            <Button onClick={() => tabsStore.addTab('Imprimir Monedas', <ReporteMonedaForm />)}
              content="Imprimir" color="blue" />
            <Button onClick={() => monedaStore.loadMonedas()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <MonedaList />
      </Grid.Column>
    </Grid>
  );
});