import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import { Pais } from "../../models/configuracion/Pais";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class PaisStore {
  paisRegistry = new Map<number, Pais>();
  selectedPais: Pais | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get paisById() {
    return Array.from(this.paisRegistry.values()).sort((a, b) => a.clave - b.clave);
  }

  get PaisesByDropdown() {
    let PaisesArreg = [new TipoDropDownValues()];
    let paisesOpt = new TipoDropDownValues();
    const paisess = Array.from(this.paisRegistry.values()).sort(
      (a, b) => {
        if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0
      }
    );
    paisess.forEach((element) => {
      paisesOpt.key = element.id;
      paisesOpt.text = element.nombre;
      paisesOpt.value = element.id;
      paisesOpt.content = element.nombre;
      PaisesArreg.push(paisesOpt);
      paisesOpt = new TipoDropDownValues();

    });
    return PaisesArreg;
  }

  loadPaises = async () => {
    this.setLoadingInitial(true);
    try {
      const paises = await agent.Paises.list();
      paises.forEach((pais) => {
        this.setPais(pais);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getPaises = async () => {
    this.setLoadingInitial(true);
    const paises = await agent.Paises.list();
    try {
      paises.forEach((pais) => {
        this.setPais(pais);
      });
      this.setLoadingInitial(false);
      return paises;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return paises;
    }
  };

  loadPais = async (id: number) => {
    let pais = this.getPais(id);
    if (pais) {
      this.selectedPais = pais;
      return pais;
    } else {
      this.loadingInitial = true;
      try {
        pais = await agent.Paises.details(id);
        this.setPais(pais);
        this.selectedPais = pais;
        this.setLoadingInitial(false);
        return pais;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setPais = (pais: Pais) => {
    this.paisRegistry.set(pais.id, pais);
  };

  private getPais = (id: number) => {
    return this.paisRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createPais = async (pais: Pais) => {
    this.loading = true;
    try {
      await agent.Paises.create(pais);
      runInAction(() => {
        this.selectedPais = pais;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Pais creada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updatePais = async (pais: Pais) => {
    this.loading = true;
    try {

      await agent.Paises.update(pais);
      runInAction(() => {
        this.selectedPais = pais;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Pais modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePais = async (id: number) => {
    this.loading = true;
    try {
      await agent.Paises.delete(id);
      runInAction(() => {
        this.paisRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Pais eliminada con éxito");
    } catch (error) {
      toast.error("Problema al eliminar pais");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
