import { Icon, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';

const ModalContainer = () => {
  const { modalStore } = useStore();
  const { modal: { open, body, header, headerIcon, sizes, closeIcon }, closeModal } = modalStore;
  return (

    <Modal closeIcon={closeIcon} open={open} onClose={closeModal} size={sizes} >

      <Modal.Header >
        {headerIcon && <Icon name={headerIcon} />}
        {header}</Modal.Header>
      <Modal.Content image scrolling>{body}</Modal.Content>
    </Modal>
  );
};

export default observer(ModalContainer);
