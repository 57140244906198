import { observer } from "mobx-react-lite";
import { Grid, Header, Button } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ConceptoClienteList from "./ConceptoClienteList";
import ConceptoClienteForm from "../form/ConceptoClienteForm";

export default observer(function ConceptoClienteDashboard() {
  const { conceptoClienteStore, tabsStore } = useStore();
  useEffect(() => {
    conceptoClienteStore.loadConceptosClientes();
  }, [conceptoClienteStore]);

  if (conceptoClienteStore.loadingInitial)
    return <LoadingComponent />;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />

        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header
            as="h2"
            content="Conceptos Clientes"
            color="blue"
          ></Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated="right">
            <Button
              onClick={() => tabsStore.addTab('Crear Conceptos Clientes', <ConceptoClienteForm id={0} />)}
              content="Crear Conceptos Clientes"
              color="blue"
            />
            <Button onClick={() => conceptoClienteStore.loadConceptosClientes()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width="16">
        <ConceptoClienteList />
      </Grid.Column>
    </Grid>
  );
});