import { Estado } from "./Estado";
import { Pais } from "./Pais";

export interface Direccion {
    id: number;
    calle: string;
    numero: string;
    numeroInterior: string;
    codigoPostal: number;
    colonia: string;
    ciudad: string;
    municipio: string;
    pais?: Pais;
    paisId: number;
    estado?: Estado;
    estadoId: number;
    telefono: number;
    referencia: string;
}
export class DireccionValues implements Direccion {
    id: number = 0;
    calle: string = "";
    numero: string = "";
    numeroInterior: string = "";
    codigoPostal: number = 0;
    colonia: string = "";
    ciudad: string = "";
    municipio: string = "";
    pais?: Pais = undefined;
    paisId: number = 0;
    estado?: Estado = undefined;
    estadoId: number = 0;
    telefono: number = 0;
    referencia: string = "";
    constructor(init?: Direccion) {
        Object.assign(this, init);
    }
}

