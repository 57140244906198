import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import ProveedorForm from '../form/ProveedorForm';

export default observer(function ProveedorList() {
    const { modalStore, proveedorStore, tabsStore } = useStore();
    const { openModal, closeModal } = modalStore;
    const { deleteProveedor, proveedoresByDate, loading } = proveedorStore;
    const [target, setTarget] = useState('');

    function deleteConfirmed(e: string, id: number) {
        deleteProveedor(id);
        setTarget(e);
        closeModal();
    }
    function deletedeclined() {
        closeModal();
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Empresa</Table.HeaderCell>
                    <Table.HeaderCell>Sucursal</Table.HeaderCell>
                    <Table.HeaderCell>Clave</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {proveedoresByDate.map(proveedor => (
                    <Table.Row key={proveedor.id}>
                        <Table.Cell>{proveedor.empresa?.clave}</Table.Cell>
                        <Table.Cell>{proveedor.sucursal?.clave}</Table.Cell>
                        <Table.Cell>{proveedor.clave}</Table.Cell>
                        <Table.Cell>{proveedor.nombre}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => tabsStore.addTab('Crear Proveedor', <ProveedorForm id={proveedor.id} />)}
                                floated='right' content='Editar' color='blue' />
                            <Button name={proveedor.id}
                                loading={loading && target === String(proveedor.id)}
                                onClick={(e) =>
                                    openModal(
                                        <DeleteModals
                                            deleteConfirmed={deleteConfirmed}
                                            deletedeclined={deletedeclined}
                                            e={e.currentTarget.name.toString()}
                                            id={proveedor.id}
                                        />,
                                        enumHeaderModals.Proveedor
                                    )
                                }
                                floated='right'
                                content='Eliminar'
                                color='red'
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}</Table.Body>
        </Table>
    )
})