import { observer } from 'mobx-react-lite';
import { SyntheticEvent } from 'react';
import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/store';
import SerieFolioForm from '../form/SerieFolioForm';

export default observer(function SerieFolioList() {
  const { serieFolioStore, tabsStore } = useStore();
  const { deleteSerieFolio, seriesFoliosByDate, loading } = serieFolioStore;
  const [target, setTarget] = useState('');
  function handleSerieFolioDelete(e: SyntheticEvent<HTMLButtonElement>, id: number) {
    setTarget(e.currentTarget.name);
    deleteSerieFolio(id);
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Empresa</Table.HeaderCell>
          <Table.HeaderCell>Sucursal</Table.HeaderCell>
          <Table.HeaderCell>Tipos de Venta</Table.HeaderCell>
          <Table.HeaderCell>Clave</Table.HeaderCell>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {seriesFoliosByDate.map(serieFolio => (
          <Table.Row key={serieFolio.id}>
            <Table.Cell>{serieFolio.empresa?.clave}</Table.Cell>
            <Table.Cell>{serieFolio.sucursal?.clave}</Table.Cell>
            <Table.Cell>{serieFolio.tipoVenta?.clave}</Table.Cell>
            <Table.Cell>{serieFolio.clave}</Table.Cell>
            <Table.Cell>{serieFolio.nombre}</Table.Cell>
            <Table.Cell>{serieFolio.serieFolioDeVentaTipo?.nombre}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => tabsStore.addTab('Crear Series y Folios', <SerieFolioForm id={serieFolio.id} />)}
                floated='right' content='Editar' color='blue' />
              <Button
                name={serieFolio.id}
                loading={loading && target === String(serieFolio.id)}
                onClick={(e) => handleSerieFolioDelete(e, serieFolio.id)}
                floated='right'
                content='Eliminar'
                color='red'
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})