import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Segment, Label } from 'semantic-ui-react';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import MyCheckInput from '../../../../app/common/form/MyCheckInput';
import { useStore } from '../../../../app/stores/store';
import { PaginationFormValues } from '../../../../app/models/Especificaciones/PaginationSpecParams';
import { TipoDropdown, TipoDropDownValues } from '../../../../app/models/configuracion/TipoDropdown';
import { useFormikContext } from 'formik';
import MySelectInput from '../../../../app/common/form/MySelectInput';

const GenealesBody: React.FC<{ tabuladorEnterDom?: (e: number) => void; }> = observer((tabuladorEnterDom) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { tipoVentaStore, serieFolioStore } = useStore();
    const { loadTiposVentasByParams } = tipoVentaStore
    const { loadSerieByParamsDropdown } = serieFolioStore
    const [tipoVenta, setTipoVenta] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
    const [serie, setSerie] = useState<TipoDropdown[]>([new TipoDropDownValues()]);
    useEffect(() => {
        setFieldValue("serieFolioDeVentaId", 0);
        setSerie([new TipoDropDownValues()])
        if (values.empresaId > 0 && values.sucursalId && values.generales.tipoVentaId > 0)
            loadSerieByParamsDropdown(new PaginationFormValues({ empresaId: values.empresaId, sucursalId: values.sucursalId, tipoVentaId: values.generales.tipoVentaId }))
                .then((result) => {
                    if (result) {
                        setSerie(result);
                    }
                });
    }, [setFieldValue, values.empresaId, values.sucursalId, values.generales.tipoVentaId, loadSerieByParamsDropdown]);
    useEffect(() => {
        const loadTipoVenta = async () => {
            try {
                loadTiposVentasByParams(new PaginationFormValues()).then((resul) => {
                    if (resul !== undefined)
                        setTipoVenta(resul);
                });
            } catch (error) {
                console.error("Failed to load series:", error);
            };
        }
        loadTipoVenta();
    }, [loadTiposVentasByParams]);
    const GestionClientes = () => {
        return (
            <Segment>
                <div><Label as='a' color='red' ribbon>Gestión de clientes</Label></div>
                <div className='col25'>
                    <MySelectInput
                        tabIndex={3}
                        placeholder={"Seleccionar"}
                        label="Tipo Venta"
                        name="generales.tipoVentaId"
                        options={tipoVenta}
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                        inputWidth="200px"
                    menuWidth="300px"
                    />
                </div>
                <div className='col20'>
                    <MySelectInput
                        tabIndex={4}
                        placeholder="Serie"
                        label="Serie"
                        name="generales.serieFolioDeVentaId"
                        options={serie}
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                        inputWidth="200px"
                    menuWidth="300px"
                    />
                </div>
            </Segment>)
    }
    return (
        <>
            <Segment>
                <div><Label as='a' color='red' ribbon>Configuracion correos</Label></div>
                <div className='col25'>
                    <MyTextInput
                        tabIndex={3}
                        placeholder=''
                        label="Servidor SMTP"
                        name='generales.servidorSMTP'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}

                    />
                </div>

                <div className='col25'>
                    <MyTextInput
                        tabIndex={4}
                        placeholder=''
                        label="Puerto SMTP"
                        name='generales.puertoSMTP'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                    />
                </div>

                <div className='col25'>
                    <MyCheckInput
                        tabIndex={5}
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                        placeholder="Utiliza SSL"
                        name="generales.utilizaSSL" />
                </div>

                <div className='col25'>
                    <MyTextInput
                        tabIndex={6}
                        placeholder=''
                        label="Usuario"
                        name='generales.usuario'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                    />
                </div>

                <div className='col25'>
                    <MyTextInput
                        tabIndex={7}
                        placeholder=''
                        type='password'
                        label="Contraseña"
                        name='generales.contrasena'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                    />
                </div>
                <div className='col25'>
                    <MyTextInput
                        tabIndex={8}
                        placeholder=''
                        label="Correo Electronico"
                        name='generales.correoElectronico'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                    />
                </div>

                <div className='col25'>
                    <MyTextInput
                        tabIndex={9}
                        placeholder=''
                        label="Destinatario"
                        name='generales.destinatario'
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                    />
                </div>
                <div className="col40">
                    <MyCheckInput
                        placeholder="Enviar correo en automático después de guardar  "
                        name="generales.correoAutomatico"
                        enterInput={tabuladorEnterDom.tabuladorEnterDom}
                        tabIndex={11}
                    />
                </div>
            </Segment>
            <GestionClientes />
        </>
    );
});
export default GenealesBody;
