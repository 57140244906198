import { FechaObservacion } from "./FechaObservacion";

//TODO: id and clave pased of number to string on this case
export interface Perfil{
    id: number;
    clave: number;
    nombre: string;
    
    tipoVenta: string;
    seriesVentas: string;
    
    tipoCompra: string;
    seriesCompras: string;
    
    fechaObservacion?: FechaObservacion;
    fechaObservacionId: number;
    codigoContable: string;  
}
export class PerfilFormValues implements Perfil {
    id: number = 0;
    clave: number = 0;
    nombre: string = '';

    tipoVenta: string = '';
    seriesVentas: string = '';
    
    tipoCompra: string = '' ;
    seriesCompras: string = '';

    fechaObservacion?: FechaObservacion;
    fechaObservacionId: number = 0;
    codigoContable: string = '';
    

    constructor(init?: Perfil) {
        Object.assign(this, init);
    }
}
