import { IFile } from "../configuracion/Empresa";

export interface InsertaInfoCatalogo {
    catalogoInsertaId: number;
    archivoInsertaId?: number;
    archivoInsertaInfo?: IFile;
    archivoInserta?: null;
    //archivoInserta?: IFile;
}
export class InsertaInfoCatalogoFormValues implements InsertaInfoCatalogo {
    catalogoInsertaId: number = 0;
    archivoInsertaId?: number;
    archivoInsertaInfo?: IFile;
    archivoInserta?: null;

}


