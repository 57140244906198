import { useField, useFormikContext } from "formik";
import { Form, Label } from "semantic-ui-react";
import { OnBlurProps } from "../../models/myProps";

interface Props {
  placeholder: string;
  name: string;
  className?: string;
  label?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  onChange?: (idSelected: any, setFieldValue?: any, values?: any, index?: any, fieldName?: any) => boolean | void;
  onBlur?: (props: OnBlurProps) => boolean | void;
  enterInput?: (e: number) => void;
  fieldName?: any;
  index?: any;
}

export default function MyCheckInput(props: Props, onChange = () => { }) {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta, helpers] = useField(props.name);
  const enterTabulador = (event: any) => {
    if (event.key !== "Enter" && event.key !== "Tab") {
      return;
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    props.enterInput?.(tabIndex);
    event.preventDefault();
  };
  const onChangeValidation = (e: any) => {
    const esValido = e.target.validity.valid;
    let esValidoOnChange;
    if (esValido) {

      esValidoOnChange = props.onChange?.(e.target.checked, setFieldValue, values, props.index, props.fieldName);
      if (esValidoOnChange !== false) {
        helpers.setValue(e.target.checked!);
      }
      // props.onChange?.(e.target.value, setFieldValue);
    }
  };
  const onBlurValidation = () => {
    props.onBlur?.({ setFieldValue: setFieldValue, values: values, index: props?.index });
  }
  return (
    <Form.Field className="width:5rem" error={meta.touched && !!meta.error}>
      <div className="form-group">
        <label className="uiAnchoCombo heighttext">{props.label}</label>
        <label className="labelCheck">
          {" "}
          <input
            {...field}
            value={field.value || ""}
            checked={field.value || ""}
            type="checkbox"
            className="inputCheck input "
            tabIndex={props.tabIndex}
            onKeyDown={enterTabulador}
            disabled={props.disabled}
            onChange={onChangeValidation}
            onBlur={onBlurValidation}
          />{" "}
          {props.placeholder}
        </label>
      </div>
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
