import { makeAutoObservable } from "mobx";
import agent from "../../api/agent";






import { ReporteCompras } from "../../models/compra/ReporteCompras";

export default class ReporteComprasStore {
    reporteCompraRegistry = new Map<number, ReporteCompras>();
    selectedReporteCompra: ReporteCompras | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    imprimirReporteCompras = async (filtros: ReporteCompras) => {
        debugger
        try {
            const generacionComprobanteCompra = await agent.ReportesCompras.imprimirReporteCompras(filtros);
            debugger
            if (generacionComprobanteCompra) {
                return generacionComprobanteCompra;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };

    
    imprimirReporteComprasDetalles = async (filtros: ReporteCompras) => {
        try {
            debugger
            const generacionComprobanteCompraDetalle = await agent.ReportesCompras.imprimirReporteComprasDetalles(filtros);
            debugger
            if (generacionComprobanteCompraDetalle) {
                //this.setGeneracionComprobante(generacionComprobante);
                //this.selectedGeneracionComprobante = generacionComprobante
                return generacionComprobanteCompraDetalle;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };

    imprimirReporteSurtidoComprobantesCompras = async (filtros: ReporteCompras) => {
        try {
            debugger
            const surtidoComprobanteCompra = await agent.ReportesCompras.imprimirReporteSurtidoComprobanteCompras(filtros);
            debugger
            if (surtidoComprobanteCompra) {
                //this.setGeneracionComprobante(generacionComprobante);
                //this.selectedGeneracionComprobante = generacionComprobante

                //window.open("http:\\localhost:3000\\personal\\repositorio\\adminya\\API\\Reports\\Pdfs\\ReportesDiario.pdf", "_blank", "toolbar=no,location=no,status=no,menubar=no,resizable=yes")
              //  window.open("http://localhost:44327/API/Reports/Pdfs/ReportesDiario.pdf", "_blank", "toolbar=no,location=no,status=no,menubar=no,resizable=yes")

                
                return surtidoComprobanteCompra;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };


}