import { observer } from 'mobx-react-lite'
import { Grid, Header, Button } from 'semantic-ui-react'
import CuentaCompraList from './CuentaDeCompraList'
import { useStore } from '../../../../app/stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CuentaDeCompraForm from '../form/CuentaDeCompraForm';

export default observer(function CuentaCompraDashboard() {
  const { cuentaDeCompraStore, tabsStore } = useStore();
  useEffect(() => {
    cuentaDeCompraStore.loadCuentasDeCompras();
  }, [cuentaDeCompraStore]);

  if (cuentaDeCompraStore.loadingInitial) return <LoadingComponent />
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <div className='colsp' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2' content='Cuentas de Compras' color='blue' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header floated='right'>
            <Button onClick={() => tabsStore.addTab('Crear cuenta de compras', <CuentaDeCompraForm id={0} />)}
              content='Crear cuenta de compras' color='blue' />
            <Button onClick={() => cuentaDeCompraStore.loadCuentasDeCompras()}
              content='Actualizar' color='blue' />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width='16'>
        <CuentaCompraList />
      </Grid.Column>
    </Grid>
  )
})