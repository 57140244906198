import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FormaDePago } from "../../models/gestionCliente/FormaDePago";
import { entityByDropdown } from "../../common/funciones/entityByDropdown";

export default class FormaPagoStore {
    formaPagoRegistry = new Map<number, FormaDePago>();
    selectedFormaPago: FormaDePago | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    get formasPagosByDropdown() {
        return entityByDropdown<FormaDePago>(
          Array.from(this.formaPagoRegistry.values())
        );
      }
    get formasPagosByDate() {
        return Array.from(this.formaPagoRegistry.values()).sort((a) => a.id);
        //.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }


    loadFormasPagos = async () => {
        this.setLoadingInitial(true);
        try {
            const formasPagos = await agent.FormasPagos.list();
            formasPagos.forEach(formaPago => {
                this.setFormaPago(formaPago);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getFormasPagos = async () => {
        this.setLoadingInitial(true);
        const formasPagos = await agent.FormasPagos.list();
        try {
            formasPagos.forEach(formaPago => {
                this.setFormaPago(formaPago);
            })
            this.setLoadingInitial(false);
            return formasPagos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return formasPagos;
        }
    }

    loadFormaPago = async (id: number) => {
        let formaPago = this.getFormaPago(id);
        if (formaPago) {
            this.selectedFormaPago = formaPago;
            return formaPago;

        } else {
            this.loadingInitial = true;
            try {
                formaPago = await agent.FormasPagos.details(id);
                this.setFormaPago(formaPago);
                this.selectedFormaPago = formaPago;
                this.setLoadingInitial(false);
                return formaPago;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setFormaPago = (formaPago: FormaDePago) => {
        this.formaPagoRegistry.set(formaPago.id, formaPago);
    }

    private getFormaPago = (id: number) => {
        return this.formaPagoRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createFormaPago = async (formaPago: FormaDePago) => {
        this.loading = true;
        try {
            await agent.FormasPagos.create(formaPago);
            runInAction(() => {
                this.formaPagoRegistry.set(formaPago.id, formaPago);
                this.selectedFormaPago = formaPago;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

  

    deleteFormaPago = async (id: number) => {
        this.loading = true;
        try {
            await agent.FormasPagos.delete(id);
            runInAction(() => {
                this.formaPagoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
