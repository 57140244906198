import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReporteVentas } from "../../models/venta/ReporteVentas";
import { PaginationFormValues } from "../../models/Especificaciones/PaginationSpecParams";

export default class ReporteVentasStore {
    reporteVentaRegistry = new Map<number, ReporteVentas>();
    selectedReporteVenta: ReporteVentas | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    imprimirReporteCuentasVentas = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesVentas.imprimirReporteCuentasVentas(filtros);
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
    imprimirReporteTiposVentas = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesVentas.imprimirReporteTiposVentas(filtros);
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };
    imprimirReporteSeriesFolio = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesVentas.imprimirReporteSeriesFolio(filtros);
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

    imprimirReporteVentas = async (filtros: ReporteVentas) => {
        try {
            const generacionComprobante = await agent.ReportesVentas.imprimirReporteVentas(filtros);
            if (generacionComprobante) {
                return generacionComprobante;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };


    imprimirReporteVentasDetalles = async (filtros: PaginationFormValues) => {
        try {
            this.loading = true;
            const bytesPdf = await agent.ReportesVentas.imprimirReporteVentasDetalles(filtros);
            runInAction(() => {
                if (bytesPdf != undefined) {
                    const blob = new Blob([bytesPdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(url, '_blank', features);
                }
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    ExportarComprobantesAExcel = async (filtros: ReporteVentas) => {
        try {
            debugger
            const generacionComprobante = await agent.ReportesVentas.exportarComprobantesAExcel(filtros);
            debugger
            if (generacionComprobante) {
                return generacionComprobante;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };


    imprimirReporteSurtidoComprobantes = async (filtros: ReporteVentas) => {
        try {
            debugger
            const surtidoComprobante = await agent.ReportesVentas.imprimirReporteSurtidoComprobantes(filtros);
            debugger
            if (surtidoComprobante) {
                //this.setGeneracionComprobante(generacionComprobante);
                //this.selectedGeneracionComprobante = generacionComprobante

                //window.open("http:\\localhost:3000\\personal\\repositorio\\adminya\\API\\Reports\\Pdfs\\ReportesDiario.pdf", "_blank", "toolbar=no,location=no,status=no,menubar=no,resizable=yes")
                //  window.open("http://localhost:44327/API/Reports/Pdfs/ReportesDiario.pdf", "_blank", "toolbar=no,location=no,status=no,menubar=no,resizable=yes")


                return surtidoComprobante;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };

}