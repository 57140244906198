export interface VerificadorGestionProveedor {    
    id: number;
    fechaInicio?: Date;
    fechaFin?: Date;
    nombre: string;
    estado: string;
    tipoReporte: string;
    ordenado: string;
    empresa: string;
    sucursal: string;
    tipoCompra:string;
    serie: string;
    folio: string;
    proveedor?: string;
    cuentaCompra?: string;
    comprador?: string;
    trabajador?: string;
    producto?: string;
  }
  export class VerificadorGestionProveedorFormValues implements VerificadorGestionProveedor {
    id: number = 0;
    fechaInicio?: Date;
    fechaFin?: Date;
    nombre: string="";
    estado: string ="";
    tipoReporte: string ="";
    ordenado: string="";
    empresa: string="";
    sucursal: string="";
    tipoCompra:string="";
    serie: string="";
    folio: string="";
    proveedor?: string="";
    cuentaCompra?: string="";
    comprador?: string ="";
    trabajador?: string="";
    producto?: string="";
    constructor(init?: VerificadorGestionProveedor) {
      Object.assign(this, init);
    }
  }
  