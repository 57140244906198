import React, { useEffect, useState } from 'react'

import '../../app/layout/styles.css'
import { DireccionValues } from '../../app/models/configuracion/Direccion';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Formik, Form } from 'formik';
import MySelectInput from '../../app/common/form/MySelectInput';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { enterInputs, tabuladorButtons } from '../../app/common/funciones/tabuladorEnter';
import { observer } from 'mobx-react-lite';
import { enumTipoDato } from '../../app/common/form/types/myTextinput-types';
import * as Yup from "yup";

interface Props {
  direccion: DireccionValues;
  closeModals: () => void;
  setDireccion: (setFO: DireccionValues) => void;
  tabIndex: number;
  onCopy?: (data: any) => void;
}

export default observer(function Direccion(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const { paisStore, estadoStore } = useStore();
  const { EstadosByDropdown } = estadoStore;
  const { PaisesByDropdown, loadPais } = paisStore;

  const [paises, setpaises] = useState([
    { key: '0', value: '0', text: 'Seleccionar' }
  ])
  const [estados, setestados] = useState([
    { key: '0', value: '0', text: 'Seleccionar' }
  ])

  const validationSchema = Yup.object({
    paisId: Yup.number()
      .min(1, "Favor de seleccionar un Pais.")
      .required("Pais es requerido."),
    estadoId: Yup.number()
      .min(1, "Favor de seleccionar el Estado.")
      .required("El Estado es requerido.")
  });

  useEffect(() => {
    paisStore.getPaises().then(lpaises => {
      lpaises.forEach(element => {
        let satPaisesOpt = { key: '9', value: '9', text: 'vred' }
        satPaisesOpt.key = element.id.toString();
        satPaisesOpt.value = element.clave.toString();
        satPaisesOpt.text = element.nombre;
        paises.push(satPaisesOpt);
      }
      );
    })
  }, [])

  useEffect(() => {
    estadoStore.getEstados().then(lestados => {
      lestados.forEach(element => {
        let satEstadosOpt = { key: '9', value: '9', text: 'vred' }
        satEstadosOpt.key = element.id.toString();
        satEstadosOpt.value = element.clave.toString();
        satEstadosOpt.text = element.nombre;
        estados.push(satEstadosOpt);
      }
      );
    })
  }, [])

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };

  function handleSubmit(fo: DireccionValues) {
    props.onCopy?.(fo);
    props.setDireccion(fo);
    props.closeModals();
  }

  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.direccion}
    >
      {({ isValid, dirty }) => (
        <Form className="ui form">
          <MySelectInput
            tabIndex={props.tabIndex + 1}
            placeholder={"Seleccionar"}
            label="Pais"
            name="paisId"
            options={PaisesByDropdown}
            enterInput={enterInput}
            autoFocus={true}
          />
          <MyTextInput placeholder='Código Postal' label="Código Postal" maxLength={5} tipoDato={enumTipoDato.Numerico} name='codigoPostal' tabIndex={props.tabIndex + 2} enterInput={enterInput} />
          <div className='cols'>
            <div className='col30'>
              <MyTextInput placeholder='Calle' label="Calle" name='calle' tabIndex={props.tabIndex + 3} enterInput={enterInput} />
            </div>
            <div className='col30'>
              <MyTextInput placeholder='No. Ext' label="No. Ext" name='numero' tabIndex={props.tabIndex + 4} enterInput={enterInput} />
            </div>
            <div className='col30'>
              <MyTextInput placeholder='No. Int' label="No. Int" name='numeroInterior' tabIndex={props.tabIndex + 5} enterInput={enterInput} />
            </div>
          </div>
          <div className='cols'>
            <div className='col30'>
              <MyTextInput placeholder='Colonia' label="Colonia" name='colonia' tabIndex={props.tabIndex + 6} enterInput={enterInput} />
            </div>
            <div className='col30'>
              <MyTextInput placeholder='Ciudad' label="Ciudad" name='ciudad' tabIndex={props.tabIndex + 7} enterInput={enterInput} />
            </div>
            <div className='col30'>
              <MyTextInput placeholder='Municipio' label="Municipio" name='municipio' tabIndex={props.tabIndex + 8} enterInput={enterInput} />
            </div>
          </div>
          <div className='col30'>
            <MySelectInput
              tabIndex={props.tabIndex + 9}
              placeholder={"Seleccionar"}
              label="Estado"
              name="estadoId"
              options={EstadosByDropdown}
              enterInput={enterInput}
            />
          </div>
          <div>
            <div className='col30'>
              <MyTextInput placeholder='Telefono' label="Telefono" name='telefono' tabIndex={props.tabIndex + 10} enterInput={enterInput} />
            </div>
            <div className='col30'>
              <MyTextInput placeholder='Referencia' label="Referencia" name='referencia' tabIndex={props.tabIndex + 11} enterInput={enterInput} />
            </div>
          </div>
          <Segment>
            <Button.Group>
              <Button
                disabled={!dirty || !isValid}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Guardar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Regresar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})