import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { Table, Button, Segment } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { PaginationFormValues } from "../../app/models/Especificaciones/PaginationSpecParams";
import MyTextInput from "../../app/common/form/MyTextInput";
import { OnKeyDownProps } from "../../app/models/myProps";
import { enterInputs } from "../../app/common/funciones/tabuladorEnter";

interface Props {
  closeModals: () => void;
  tabIndex: number;
  values: any;
  onCopy: (data: any) => void;
  name?: string;
}
const Ayuda: React.FC<Props> = observer((props: Props) => {
  const [filteredData, setFilteredData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const { productoStore, empresaStore,sucursalStore } = useStore();
  const { fetchsProductos } = productoStore;
  const { fetchsEmpresas } = empresaStore;
  const {fetchSucursalesSinEmpresa} = sucursalStore;
  const inputRef = useRef<any>(null);

  const setSearchQuery = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    setFilteredData(data.filter(
      (data: any) =>
        data.clave?.toString().toLowerCase().includes(lowerCaseQuery) ||
        data.nombre?.toLowerCase().includes(lowerCaseQuery)
    ));
  };

  const clearSearch = () => {
    setFilteredData(data);
  };
  const clearFilteredData = () => {
    setFilteredData([]);
    setData([]);
  }

  useEffect(() => {
    const abortController = new AbortController();
    const loadInformacion = async () => {
      try {
        switch (props.name) {
          case "Producto":
            if (props.values.empresaId && props.values.sucursalId) {
              const result = await fetchsProductos(new PaginationFormValues({ empresaId: props.values.empresaId, sucursalId: props.values.sucursalId }), { signal: abortController.signal });
              if (!abortController.signal.aborted && result) {
                setFilteredData(result);
                setData(result);
              }
            }
            break;
          case "Empresa":
            const result = await fetchsEmpresas();
            if (result) {
              debugger
              setFilteredData(result);
              setData(result);
            }
            break;
          case "ListaPrecio":
            setFilteredData(props.values.controlListaDePrecio);
            setData(props.values.controlListaDePrecio);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("No se pudo cargar la información:", error);
      }
    };
    loadInformacion();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    return () => {
      clearFilteredData();
      props.closeModals();
    }
  }, [])

  // const handleClear = (resetForm: any) => {
  //   resetForm();
  //   clearSearch();
  // };

  const handleDoubleClick = (info: any) => {
    switch (props.name) {
      case "Producto":
        props.onCopy(info.id);
        props.closeModals();
        break;
      case "Empresa":
        props.onCopy(info);
        props.closeModals();
        break
      case "ListaPrecio":
        props.onCopy(info);
        props.closeModals();
        break;
      default:
        break;
    }
  };
  const handleRowKeyDown = (e: any, info: any) => {
    if (e.key === "Enter") {
      handleDoubleClick(info);
    }
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      if (inputRef.current)
        inputRef.current.Focus(); // Llamar al método focus en lugar de Focus

    }
  };
  const handlButtonDown = (e: any) => {
    if (filteredData?.length < 1)
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        if (inputRef.current)
          inputRef.current.Focus(); // Llamar al método focus en lugar de Focus
      }
  };
  const handleInputKeyDown = (porps: OnKeyDownProps) => {
    const { event, values } = porps;
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchQuery(values.search);
    }
  };
  const enterInput = (tabIndex: number) => {
    enterInputs(tabIndex, 0, 55500);
  }
  return (
    <Segment clearing>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={values => {
          setSearchQuery(values.search);
        }}
      >
        {({ resetForm }) => (
          <Form className='ui form' >
            <div className='col65'>
              <MyTextInput
                ref={inputRef}
                autoFocus
                type="text"
                name="search"
                enterInput={enterInput}
                onKeyDownProps={handleInputKeyDown}
                placeholder="Buscar..."
                tabIndex={props.tabIndex + 1}
              />
            </div>
            <Button type="submit" onKeyDown={(e: any) => handlButtonDown(e)} primary tabIndex={props.tabIndex + 2}>Buscar</Button>
            {/* <Button type="button" onKeyDown={(e: any) => handlButtonDown(e)} onClick={() => handleClear(resetForm)} secondary tabIndex={props.tabIndex + 3}>Borrar Búsqueda</Button> */}
          </Form>
        )}
      </Formik>
      <Table celled selectable >
        <Table.Header>
          <Table.Row>
          {props.name === "Sucursal" && <Table.HeaderCell>Empresa</Table.HeaderCell>}
            <Table.HeaderCell>Clave</Table.HeaderCell>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            {props.name === "Producto" && <Table.HeaderCell>Existencia</Table.HeaderCell>}
            {props.name === "ListaPrecio" && <Table.HeaderCell>Precio</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredData?.map((data: any, index: any) => {
            return (
              <Table.Row key={data.id}
                onDoubleClick={() => handleDoubleClick(data)}
                tabIndex={props.tabIndex + index + 3}
                onKeyDown={(e: any) => handleRowKeyDown(e, data)}
              >
                {props.name === "Sucursal" && <Table.Cell>{data?.empresa?.clave}</Table.Cell>}
                <Table.Cell>{data.clave}</Table.Cell>
                <Table.Cell>{props.name === "ListaPrecio" ? data?.listaDePrecios?.nombre : data.nombre}</Table.Cell>
                {props.name === "Producto" && <Table.Cell>{data?.existencia}</Table.Cell>}
                {props.name === "ListaPrecio" && <Table.Cell>{data?.precio}</Table.Cell>}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Segment>
  );
});
export default Ayuda;