import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { ConceptoFinanzaTipo } from "../../models/Finanza/ConceptoFinanzaTipo";

export default class ConceptoFinanzaTipoStore {
    conceptoFinanzaTipoRegistry = new Map<number, ConceptoFinanzaTipo>();
    selectedConceptoFinanzaTipo: ConceptoFinanzaTipo | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadCuentasFinanzasTipos = async () => {
        this.setLoadingInitial(true);
        try {
            const conceptosFinanzasTipos = await agent.ConceptosFinanzasTipos.list();
            conceptosFinanzasTipos.forEach(conceptoFinanzaTipo => {
                this.setConceptoFinanzaTipo(conceptoFinanzaTipo);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getConceptoFinanzasTipos = async () => {
        this.setLoadingInitial(true);
        const conceptosFinanzasTipos = await agent.ConceptosFinanzasTipos.list();
        try {
            conceptosFinanzasTipos.forEach(conceptoFinanzaTipo => {
                this.setConceptoFinanzaTipo(conceptoFinanzaTipo);
            })
            this.setLoadingInitial(false);
            return conceptosFinanzasTipos;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return conceptosFinanzasTipos;
        }
    }

    loadConceptoFinanzaTipo = async (id: number) => {
        let conceptoFinanzaTipo = this.getConceptoFinanzaTipo(id);
        if (conceptoFinanzaTipo) {
            this.selectedConceptoFinanzaTipo = conceptoFinanzaTipo;
            return conceptoFinanzaTipo;

        } else {
            this.loadingInitial = true;
            try {
                conceptoFinanzaTipo = await agent.ConceptosFinanzasTipos.details(id);
                this.selectedConceptoFinanzaTipo = conceptoFinanzaTipo;
                this.setLoadingInitial(false);
                return conceptoFinanzaTipo;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setConceptoFinanzaTipo = (conceptoFinanzaTipo: ConceptoFinanzaTipo) => {
        this.conceptoFinanzaTipoRegistry.set(conceptoFinanzaTipo.id, conceptoFinanzaTipo);
    }
    
    private getConceptoFinanzaTipo = (id: number) => {
        return this.conceptoFinanzaTipoRegistry.get(id);
    }
    
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createConceptoFinanzaTipo = async (conceptoFinanzaTipo: ConceptoFinanzaTipo) => {
        this.loading = true;
        try {
            await agent.ConceptosFinanzasTipos.create(conceptoFinanzaTipo);
            runInAction(() => {
                this.conceptoFinanzaTipoRegistry.set(conceptoFinanzaTipo.id, conceptoFinanzaTipo);
                this.selectedConceptoFinanzaTipo = conceptoFinanzaTipo;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    

    deleteConceptoFinanzaTipo = async (id: number) => {
        this.loading = true;
        try {
            await agent.ConceptosFinanzasTipos.delete(id);
            runInAction(() => {
                this.conceptoFinanzaTipoRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get conceptosFinanzasTipoByDropdown(){
        let conceptoFinanzaTipoArreg=[new TipoDropDownValues()];
        let tipoConceptosFinanzasOpt =new TipoDropDownValues();
        const cucltipos=  Array.from(this.conceptoFinanzaTipoRegistry.values()).sort(
          (a, b) =>  {  if (a.nombre > b.nombre) return 1
            if (a.nombre < b.nombre) return -1
            return 0}
        );
        cucltipos.forEach((element) => {
            tipoConceptosFinanzasOpt.key = element.id;
            tipoConceptosFinanzasOpt.text = element.nombre;
            tipoConceptosFinanzasOpt.value = element.id;
            tipoConceptosFinanzasOpt.content = element.nombre;
            conceptoFinanzaTipoArreg.push(tipoConceptosFinanzasOpt);
            tipoConceptosFinanzasOpt =new TipoDropDownValues();
          
        });
        return conceptoFinanzaTipoArreg;
    }
}
