import React, { useEffect, useState } from "react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import { Button, Segment, Icon, Label } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import { Impuesto, ImpuestoFormValues } from "../../../../app/models/configuracion/Impuesto";
import MySelectInput from "../../../../app/common/form/MySelectInput";
import { toast } from "react-toastify";
import FechaObservaciones from "../../../Component/FechaObservaciones";
import { FechaObservacionValues } from "../../../../app/models/configuracion/FechaObservacion";
import { enumHeaderModalsFechasObservaciones } from "../../../modals/MensajeModals";
import * as Yup from "yup";
import BarraFechaObservacion from "../../../Component/BarraFechaObservacion";
import BarraBotones from "../../../Component/BarraBotones";

interface idProps {
  id: number
}

export default observer(function ImpuestoForm({ id }: idProps) {
  const { impuestoStore, impuestoTipoStore } = useStore();
  const { modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { createImpuesto, updateImpuesto, loadImpuesto, loadingInitial, impuestosById, loading } = impuestoStore;
  const { impuestosTipoByDropdown, getImpuestoTipos } = impuestoTipoStore;
  const [impuesto, setImpuesto] = useState(new ImpuestoFormValues());
  const [ImpuestoTipos, setImpuestoTipos] = useState([{ key: 998, value: 998, text: 'Seleccionar' }])
  const [fechaObservacion, setFechaObservacion] = useState(    new FechaObservacionValues()  );
  const firstElement = 0;
  const lastElement = 7;

  useEffect(() => {
    getImpuestoTipos().then((impTips) => {
      impTips.forEach((element) => {
        let satImpTipOpt = { key: 999, value: 999, text: "" };
        satImpTipOpt.key = element.id;
        satImpTipOpt.value = element.clave;
        satImpTipOpt.text = element.nombre;
        ImpuestoTipos.push(satImpTipOpt);
      });
    });
  }, [getImpuestoTipos, ImpuestoTipos, setImpuestoTipos]);

  const validationSchema = Yup.object({
    clave: Yup.number().required("la clave de impuesto es requerida."),
    nombre: Yup.string().required("El nombre de el impuesto es requerida."),
    porcentajeCuota: Yup.number().required("El porcentaje es requerido."),
    impuestoTipoId: Yup.number()
      .min(1, "Favor de seleccionar un tipo de impuesto.")
      .required("El tipo de impuesto es requerido.")
  });

  useEffect(() => {
    if (id) {
      debugger
      loadImpuesto(id).then((impuesto) => {
        setImpuesto(new ImpuestoFormValues(impuesto))
        setFechaObservacion(new FechaObservacionValues(impuesto?.fechaObservacion));
      });
    }
    else {
      impuesto.clave =
        impuestosById.map((impuesto) => impuesto.clave).length > 0
          ? Math.max(...impuestosById.map((impuesto) => impuesto.clave)) + 1
          : 1;
    }
    }, [])
  //}, [id, impuesto, impuestosById, loadImpuesto])

  function handleSubmit(impuesto: Impuesto) {
    if (impuesto.impuestoTipo) {
      const tipoImpuestoSelected = ImpuestoTipos.find(
        (a) => a.key === impuesto.impuestoTipoId
      );
      if (tipoImpuestoSelected) {
        impuesto.impuestoTipo.nombre = tipoImpuestoSelected.text;
        impuesto.impuestoTipo.id = tipoImpuestoSelected.key;
        impuesto.impuestoTipo.clave = tipoImpuestoSelected.value;
      }
    }
    if (impuesto.impuestoTipoId.toString() !== "0" && impuesto.nombre !== null) {
      if (impuesto.fechaObservacion) {
        impuesto.fechaObservacion = fechaObservacion;
    }
      impuesto.id > 0 ? updateImpuesto(impuesto) : createImpuesto(impuesto);
    }
    else {
      toast.info("Favor de llenar campos");
    }
  }

  function enterInput(tabIndex: number) {
    let x = tabIndex + 2;
    if (tabIndex === 11) tabIndex = 0;
    let next = document.querySelector<HTMLElement>(
      '[tabIndex="' + (tabIndex + 1) + '"]'
    );
    if (next) {
      while ((next as any)?.disabled === true) {
        next = document.querySelector<HTMLElement>('[tabIndex="' + x + '"]');
        x++;
      }
    }
    if (next) {
      next.focus();
    }
  }
  const tabuladorButton = (event: any) => {
    if (event.key !== "Tab") {
      return;
    }
    let element = event.target;
    let tabIndex = element.tabIndex;
    enterInput(tabIndex);
    event.preventDefault();
  };
  function closeModals() {
    closeModal();
  }

  if (loadingInitial) return <LoadingComponent content='Loading impuesto' />

  return (
    <Segment clearing>
      <Formik validationSchema={validationSchema} onSubmit={values => { handleSubmit(values) }}


        enableReinitialize initialValues={impuesto}>

        {({ handleSubmit, isValid, dirty,values,setFieldValue }) => (
          <Form className='ui form' >
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Datos del impuesto
              </Label>
              <MyTextInput disabled={true} tabIndex={0} label="Clave:" placeholder="Clave" name="clave" />
              <MyTextInput autoFocus={true} tabIndex={1} enterInput={enterInput} label="Nombre:" placeholder="Nombre" name="nombre" />
            </Segment>
            <Segment clearing>
              <Label as='a' color='blue' ribbon>
                Datos especí­ficos
              </Label>
              <MyTextInput tabIndex={2} enterInput={enterInput} label="Porcentaje:" placeholder="Porcentaje/Cuota" name="porcentajeCuota" />
              <MySelectInput
                placeholder={"Seleccionar"}
                label="Tipo Impuesto"
                name="impuestoTipoId"
                enterInput={enterInput}
                tabIndex={3}
                options={impuestosTipoByDropdown}
                inputWidth="140px"
                menuWidth="300px"
              />
            </Segment>

            <Segment clearing>
              <div className="col20">
                <Button
                  tabIndex={4}
                  type="button"
                  onKeyDown={tabuladorButton}
                  loading={loading}
                  onClick={() =>
                    openModal(
                      <FechaObservaciones
                        fechaObservacion={fechaObservacion}
                        setFechaObservacion={(data) => {setFieldValue("fechaObservacion", data)
                          impuesto.fechaObservacion = data;
                          setFechaObservacion(new FechaObservacionValues(data));
                      }}
                        
                        closeModals={closeModals}
                        tabIndex={lastElement}
                      />,
                      enumHeaderModalsFechasObservaciones.Impuesto
                    )
                  }
                  floated="left"
                  color="blue"
                >
                  <Icon name="calendar alternate outline" />
                  Fechas y Observaciones
                </Button>
              </div>
              <BarraFechaObservacion
                fechaObservacion={values.fechaObservacion}
              ></BarraFechaObservacion>
            </Segment>
            <Segment>
              <BarraBotones
                toLink="/impuestos"
                loading={loading}
                tabIndex={5}
                //id={id}
                firstElement={firstElement}
                lastElement={lastElement}
              ></BarraBotones>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  );
})