import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import { Personal } from "../../models/gestionFlotilla/Personal";
import { history } from "../../..";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class PersonalStore {
    personalRegistry = new Map<number, Personal>();
    selectedPersonal: Personal | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    get personalesByDropdown() {
        debugger
        let personalesArreg = [new TipoDropDownValues()];
        let personalesOpt = new TipoDropDownValues();
        const cvts = Array.from(this.personalRegistry.values()).sort(
            (a, b) => {
                if (a.nombre > b.nombre) return 1
                if (a.nombre < b.nombre) return -1
                return 0
            }
        );
        cvts.forEach((element) => {
            personalesOpt.key = element.id;
            personalesOpt.text = element.nombre;
            personalesOpt.value = element.id;
            personalesOpt.content = element.nombre;
            personalesArreg.push(personalesOpt);
            personalesOpt = new TipoDropDownValues();

        });
        return personalesArreg;
    }

    get personalesByDate() {
        debugger
        return Array.from(this.personalRegistry.values()).sort((a) => a.id);
    }

    get personalById() {
        return Array.from(this.personalRegistry.values()).sort(
          (a, b) => a.clave - b.clave
        );
      }
    loadPersonales = async () => {
        debugger
        this.setLoadingInitial(true);
        try {
            const personales = await agent.Personales.list();
         
            personales.forEach(personal => {
                personal.vencimientoLicencia = new Date(personal.vencimientoLicencia);
                this.setPersonal(personal);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getPersonales = async () => {
        debugger
        this.setLoadingInitial(true);
        const personales = await agent.Personales.list();
        try {
            
            personales.forEach(personal => {
                this.setPersonal(personal);
            })
            this.setLoadingInitial(false);
            return personales;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return personales;
        }
    }

    loadPersonal = async (id: number) => {
        debugger
        let personal = this.getPersonal(id);
        if (personal) {
            this.selectedPersonal = personal;
            return personal;

        } else {
            this.loadingInitial = true;
            try {
                personal = await agent.Personales.details(id);
                debugger
                personal.vencimientoLicencia = new Date(personal.vencimientoLicencia);
                this.setPersonal(personal);
                //this.selectedPersonal= personal;
                this.setLoadingInitial(false);
                return personal;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

    }

    private setPersonal = (personal: Personal) => {
        this.personalRegistry.set(personal.id, personal);
    }

    private getPersonal = (id: number) => {
        return this.personalRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createPersonal = async (personal: Personal) => {
        this.loading = true;
        try {
            debugger
            await agent.Personales.create(personal);
            runInAction(() => {
               
                this.selectedPersonal = personal;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Personal creado con éxito");
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updatePersonal = async (personal: Personal) => {
        this.loading = true;
        try {
            debugger
            await agent.Personales.create(personal);
            runInAction(() => {
                this.selectedPersonal = personal;
                this.editMode = false;
                this.loading = false;
            })
            
            toast.success("Personal Actualizado con éxito");
        } catch (error) {
            toast.error("Problema al actualizar el Personal");
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    deletePersonal = async (id: number) => {
        this.loading = true;
        try {
            await agent.Personales.delete(id);
            runInAction(() => {
                this.personalRegistry.delete(id);
                this.loading = false;
            })
            toast.success("Personal eliminado con éxito");
        } catch (error) {
            toast.error("Problema al eliminar el Vehículo");
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
