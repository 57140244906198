import React, { useCallback } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { Icon, Header, Form, Label } from "semantic-ui-react";
import { useField } from "formik";
import { IFile } from "../../models/configuracion/Empresa";
interface IProps {
  files?: IFile;
  setFiles?: (files: any[]) => void;
  acceptFileDrop?: string;
  maxFiles?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  maxSize?: number;
  minSize?: number;
  name: string;
  label?: string;
  className?: string;
  tabIndex?: number;
  setFieldValue?: (name: string, file: any) => void;
}

const dropzoneStyles = {
  border: "dashed 3px",
  borderColor: "#eee",
  borderRadius: "5px",
  paddingTop: "10px",
  textAlign: "center" as "center",
  height: "100px",
  cursor: "pointer",
};

const dropzoneActive = {
  borderColor: "green",
};

const FileWidgetDropzone: React.FC<IProps> = ({
  files,
  acceptFileDrop,
  name,
  label,
  tabIndex
}) => {
  const filesTernary = files ? (
    files.nombre + "-" + files.type + "bytes"
  ) : (
    <Header content="Suelta el archivo aquí" />
  );
  const [field, meta, helpers] = useField(name);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      helpers.setValue(acceptedFiles[0]);
    },
    [helpers]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/html": [acceptFileDrop ? acceptFileDrop : ""],
    },
    onDrop,
  });
  // const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  //   <li className="liDroopzone" key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={(file as FileWithPath).path}>
      {(file as FileWithPath).path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}> <Label basic color="red">
            {"Error de Archivo"}
          </Label></li>
        ))}
      </ul>
    </li>
  ));

  return (
    <Form.Field className="w-10" error={meta.touched && !!meta.error}>
      <label className="heighttext">{label}</label>
      <div
        {...getRootProps()}
        tabIndex={tabIndex}
        style={
          isDragActive
            ? { ...dropzoneStyles, ...dropzoneActive }
            : dropzoneStyles
        }
      >
        <input {...getInputProps()} />
        <Icon name="upload" size="large" />
        {field.value ? (
          <h4>
            {/* {acceptedFileItems.length > 0 ? ( */}
            {/* {file.path} - {file.size} bytes */}
            {field.value.path}-{field.value.size} bytes
            {/* ) : ( */}
            {/* <div> {console.log("FF",field.value,"valor ",acceptedFileItems)} */}
            {/* <Header content="Sueltasssss el archivo aquí" /></div> */}
            {/* )} */}
          </h4>
        ) : (
          filesTernary
        )}

      </div>
      {fileRejectionItems.length > 0 ? <Label basic color="red">
        Error de Archivo
      </Label> : null}
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export default FileWidgetDropzone;
