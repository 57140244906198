import { makeAutoObservable } from "mobx";
import { EstadoCuentaDetalles } from "../../models/gestionCliente/EstadoCuenta";
import agent from "../../api/agent";
import { PaginationFormValues, PaginationSpecParams } from "../../models/Especificaciones/PaginationSpecParams";
export default class EstadoCuentaStore {
    detallesRegistry = new Map<number, EstadoCuentaDetalles>();
    editMode = false;
    loading = false;
    loadingInitial = false;
    estadoCuentaParams: PaginationSpecParams = new PaginationFormValues();
    constructor() {
        makeAutoObservable(this);
    }

    loadEstadoCuenta = async (filtoCliente: PaginationSpecParams) => {
        try {
            let estadoCuenta =
                await agent.EstadoCuentas.getEstadoCuentaByClave(
                    filtoCliente
                );
            estadoCuenta?.estadoCuentaDetalles?.forEach((detalles) => {
                this.setDetalles(detalles);
            });
            return estadoCuenta;
        } catch (error) {
            console.error(error);
        }
    }
    private setDetalles = (detalles: EstadoCuentaDetalles) => {
        this.detallesRegistry.set(detalles.id, detalles);
    };

    get detallesById() {
        return Array.from(this.detallesRegistry.values()).sort(
            (a, b) => a.movto - b.movto
        );
    }
    public setDetallesByIdRegClear = () => {
        this.detallesRegistry.clear();
    }
}
