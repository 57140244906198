import { makeAutoObservable, runInAction } from "mobx";
import agent, { urlApi, urlReports } from "../../api/agent";
import { VerificadorGestionCliente } from "../../models/gestionCliente/VerificadorGestionCliente";
import { toast } from "react-toastify";

export default class VerificadorGestionClienteStore {
    reporteGestionClienteRegistry = new Map<number, VerificadorGestionCliente>();
    selectedVerificadorGestionCliente: VerificadorGestionCliente | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }
    imprimirVerificadorGestionClientes = async (filtros: VerificadorGestionCliente) => {
        this.loading = true;
        try {
            const namePdf = await agent.VerificadorGestionClientes.imprimirVerificadorGestionClientes(filtros);
            runInAction(() => {
                if (namePdf != undefined) {
                    let features =
                        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
                    window.open(urlApi+ urlReports+  namePdf, '_blank', features);
                }
                this.loading = false;
            });
        } catch (error) {
            toast.error("Problema al enviar datos");
            console.error(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    };

}