import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../../app/stores/store";
import DeleteModals from "../../../modals/DeleteModals";
import { enumHeaderModals } from "../../../modals/MensajeModals";
import ProductoForm from "../form/ProductoForm";

export default observer(function ProductoList() {
  const { modalStore, tabsStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { productoStore } = useStore();
  const { deleteProducto, productosById, loading } = productoStore;
  const [target, setTarget] = useState("");
  function deleteConfirmed(e: string, id: number) {
    deleteProducto(id);
    setTarget(e);
    closeModal();
  }

  function deletedeclined() {
    closeModal();
  }
  return (
    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Empresa</Table.HeaderCell>
            <Table.HeaderCell>Sucursal</Table.HeaderCell>
            <Table.HeaderCell>CLAVE</Table.HeaderCell>
            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
            <Table.HeaderCell>ALMACEN</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {productosById.map((producto) => (
            <Table.Row key={producto.id}>
              <Table.Cell>{producto.empresa?.clave}</Table.Cell>
              <Table.Cell>{producto.sucursal?.clave}</Table.Cell>
              <Table.Cell>{producto.clave}</Table.Cell>
              <Table.Cell>{producto.nombre}</Table.Cell>
              <Table.Cell>{producto.almacen?.nombre}</Table.Cell>
              <Table.Cell>
                <Button onClick={() => tabsStore.addTab('Editar Productos y Servicios', <ProductoForm id={producto.id} />)}
                  floated='right' content='Editar' color='blue' />
                <Button
                  name={producto.id}
                  disabled={loading && target === String(producto.id)}
                  loading={loading && target === String(producto.id)}
                  onClick={(e) =>
                    openModal(
                      <DeleteModals
                        deleteConfirmed={deleteConfirmed}
                        deletedeclined={deletedeclined}
                        e={e.currentTarget.name.toString()}
                        id={producto.id}
                        nombre={producto.nombre}
                      />,
                      enumHeaderModals.Producto
                    )
                  }
                  floated="right"
                  content="Eliminar"
                  color="red"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
});