import { useState, useEffect, useRef } from 'react'
import { useStore } from '../../../../app/stores/store';
import { Button, Segment, Header, Icon, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MySelectInput from "../../../../app/common/form/MySelectInput";
import MyDateInput from '../../../../app/common/form/MyDateInput';
import { enterInputs } from "../../../../app/common/funciones/tabuladorEnter";
import { enumTipoDato } from '../../../../app/common/form/types/myTextinput-types';
import { OnKeyDownProps } from '../../../../app/models/myProps';
import Busqueda from '../../../General/Busqueda';
import { updateInputValue } from '../../../../app/common/util/util';
import { enumHeaderModalsBusqueda } from '../../../modals/MensajeModals';
import { PaginationFormValues, PaginationSpecParams } from '../../../../app/models/Especificaciones/PaginationSpecParams';

export default observer(function KardexForm() {
  const firstElement = 0;
  const lastElement = 19;
  const { reporteAlmacenesStore, modalStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { imprimirKardex } = reporteAlmacenesStore
  const [reporteAlmacen, setReporteAlmacen] = useState(new PaginationFormValues());
  const TipoReporteByDropdown = [
    {
      key: 1,
      text: "A detalle",
      value: 1,
      contex: "A Detalle",
    }
  ];

  const OrdenReporteByDropdown = [
    {
      key: 1,
      text: "Ascendente",
      value: 1,
      contex: "Ascendente",
    }
  ];

  const validationSchema = Yup.object({
    nombre: Yup.string().required("El nombre es requerido."),
    fechaInicio: Yup.date().required("El folio es requerido."),
    fechaFin: Yup.date().required("El folio es requerido."),
    tipoReporte: Yup.number()
      .min(1, "Favor de seleccionar un tipo Reporte.")
      .required("El tipo Reporte es requerido."),
    ordenado: Yup.number()
      .min(1, "Favor de seleccionar un Ordenado.")
      .required("El Ordenado es requerido."),
  });

  function DatosIniciales() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    setReporteAlmacen((state) => ({
      ...state,
      nombre: "KARDEX ", ordenado: "1",
      tipoReporte: "1",
      fechaInicio: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()),
      fechaFin: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate())
    }))
  }

  useEffect(() => {
    DatosIniciales();
  }, []);

  function handleSubmit(reporteKardex: PaginationSpecParams) {
    void imprimirKardex(reporteKardex);
  }

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }

  const inputEmpresaRef = useRef<any>(null);
  const inputSucursalRef = useRef<any>(null);
  const inputAlamcenRef = useRef<any>(null);
  const inputProductoRef = useRef<any>(null);
  const inputCantidadRef = useRef<any>(null);
  const inputProveedorRef = useRef<any>(null);
  const inputConceptoInventarioRef = useRef<any>(null);

  const closeModalsEmpresaAyuda = () => {
    if (inputEmpresaRef.current)
      inputEmpresaRef.current.Focus();
    closeModal();
  }

  const closeModalsSucursalAyuda = () => {
    if (inputSucursalRef.current)
      inputSucursalRef.current.Focus();
    closeModal();
  }

  const closeModalsAlmacenAyuda = () => {
    if (inputAlamcenRef.current)
      inputAlamcenRef.current.Focus();
    closeModal();
  }

  const closeModalProductoAyuda = () => {
    if (inputProductoRef.current)
      inputProductoRef.current.Focus();
    closeModal();
  }

  const closeModalProveedorAyuda = () => {
    if (inputProveedorRef.current)
      inputProveedorRef.current.Focus();
    closeModal();
  }

  const closeModalConceptoInventarioAyuda = () => {
    if (inputConceptoInventarioRef.current)
      inputConceptoInventarioRef.current.Focus();
    closeModal();
  }

  function closeModals() {
    closeModal();
  }

  const getEnumHeaderModalBusqueda = (name: string) => {
    switch (name) {
      case "Empresa":
        return enumHeaderModalsBusqueda.EmpresaBusqueda;
      case "Sucursal":
        return enumHeaderModalsBusqueda.SucursalBusqueda;
      case "Almacen":
        return enumHeaderModalsBusqueda.AlmacenBusqueda;
      case "Producto":
        return enumHeaderModalsBusqueda.ProductoBusqueda;
      case "Proveedor":
        return enumHeaderModalsBusqueda.ProveedorBusqueda;
      case "ConceptoInventario":
        return enumHeaderModalsBusqueda.ConceptoInventarioBusqueda;
      default:
        return enumHeaderModalsBusqueda.TrabajadorBusqueda; // Valor por defecto
    }
  }

  const abrirBusqueda = (values: any, setFieldValue: any, name: string) => {
    openModal(
      <Busqueda
        name={name}
        values={values}
        tabIndex={32231213}
        closeModals={() => {
          switch (name) {
            case "Empresa": closeModalsEmpresaAyuda();
              break;
            case "Sucursal": closeModalsSucursalAyuda();
              break;
            case "Almacen": closeModalsAlmacenAyuda();
              break;
            case "Producto": closeModalProductoAyuda();
              break;
            case "Proveedor": closeModalProveedorAyuda();
              break;
            case "ConceptoInventario": closeModalConceptoInventarioAyuda();
              break;
            default: closeModals();
              break;
          }
        }}
        onCopy={(data) => {
          if (data) {
            setFieldValue(lowercaseFirstLetter(name), updateInputValue(values[lowercaseFirstLetter(name)], data.clave));
          }
        }}
      />,
      getEnumHeaderModalBusqueda(name),
      (name === "Empresa" || name === "Sucursal") ? "tiny" : "small",
      true, "search"
    );
  }

  function lowercaseFirstLetter(name: string): string {
    return name.charAt(0).toLowerCase() + name.slice(1);
  }

  const onKeyDownInput = (props: OnKeyDownProps) => {
    const { event, values, setFieldValue, name } = props;
    if (event.key === "F2") {
      event.preventDefault();
      abrirBusqueda(values, setFieldValue, name!);
    }
  }

  return (
    <Segment clearing>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
        enableReinitialize
        initialValues={reporteAlmacen}
      >
        {({ }) => (
          <Form className='ui form' >
            <Header as='h2' icon='compass' content='Kardex' color='blue' />
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Encabezado
              </Label>
              <div></div>
              <div className='col100'>
                <MyTextInput
                  tabIndex={0}
                  placeholder=''
                  label="NombreReporte"
                  name='nombre'
                  enterInput={enterInput}
                //change={chageEnter}
                />
              </div>
              <div></div>

              <div className='col35'>
                Fecha Inicio
                <MyDateInput
                  tabIndex={1}
                  placeholderText="Fecha Inicio"
                  name="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput}
                />
              </div>

              <div className='col35'>
                Fecha Fin
                <MyDateInput
                  name="fechaFin"
                  tabIndex={2}
                  placeholderText="Fecha Fin"
                  dateFormat="dd/MM/yyyy"
                  enterInput={enterInput} />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={3}
                  placeholder="Tipo Reporte"
                  label="Tipo Reporte"
                  name="tipoReporte"
                  options={TipoReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
              <div></div>

              <div className='col35'>
                <MySelectInput
                  tabIndex={4}
                  placeholder="Orden"
                  label="Ordenado"
                  name="ordenado"
                  options={OrdenReporteByDropdown}
                  enterInput={enterInput}
                />
              </div>
            </Segment>
            <div></div>
            <Segment clearing>
              <Label as='a' color='red' ribbon>
                Filtros
              </Label>
              <div></div>

              <div className='col35'>
                <MyTextInput
                  placeholder='Empresa'
                  label="Empresa"
                  name='empresa'
                  maxLength={50}
                  tabIndex={5}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  onKeyDownProps={onKeyDownInput}
                  fieldName="Empresa"
                  ref={inputEmpresaRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Sucursal'
                  label="Sucursal"
                  name='sucursal'
                  maxLength={50}
                  tabIndex={6}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  fieldName="Sucursal"
                  onKeyDownProps={onKeyDownInput}
                  ref={inputSucursalRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Almacen'
                  label="Almacen"
                  name='almacen'
                  maxLength={50}
                  tabIndex={7}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  fieldName="Almacen"
                  onKeyDownProps={onKeyDownInput}
                  ref={inputAlamcenRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Producto'
                  label="Producto"
                  name='producto'
                  maxLength={50}
                  tabIndex={8}
                  //tipoDato={enumTipoDato.}
                  enterInput={enterInput}
                  fieldName="Producto"
                  onKeyDownProps={onKeyDownInput}
                  ref={inputProductoRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Cantidad'
                  label="cantidad"
                  name='cantidad'
                  maxLength={50}
                  tabIndex={9}
                  tipoDato={enumTipoDato.Numerico}
                  enterInput={enterInput}
                />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Costo'
                  label="Costo"
                  name='costo'
                  maxLength={50}
                  tabIndex={10}
                  tipoDato={enumTipoDato.Numerico}
                  enterInput={enterInput}
                />
              </div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Proveedor'
                  label="Proveedor"
                  name='proveedor'
                  maxLength={50}
                  tabIndex={11}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  fieldName="Proveedor"
                  onKeyDownProps={onKeyDownInput}
                  ref={inputProveedorRef} />
              </div>
              <div className='col10'></div>
              <div className='col35'>
                <MyTextInput
                  placeholder='Concepto'
                  label="Concepto"
                  name='conceptoInventario'
                  maxLength={50}
                  tabIndex={11}
                  tipoDato={enumTipoDato.NumericoFiltro}
                  enterInput={enterInput}
                  fieldName="ConceptoInventario"
                  onKeyDownProps={onKeyDownInput}
                  ref={inputConceptoInventarioRef} />
              </div>
            </Segment>

            <Segment>
              <Button tabIndex={12} type="submit" color="blue">
                <Icon name="print" />
                Imprimir
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </Segment>
  )
})