import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";
import { BancoSat } from "../../models/Finanza/BancoSat";

export default class BancoSatStore {
    bancoSatRegistry = new Map<number, BancoSat>();
    selectedBancoSat: BancoSat | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    loadBancosSats = async () => {
        this.setLoadingInitial(true);
        try {
            const bancosTipos = await agent.BancosSats.list();
            bancosTipos.forEach(bancoSat => {
                this.setBancoSat(bancoSat);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getBancosSats = async () => {
        this.setLoadingInitial(true);
        const bancosSats = await agent.BancosSats.list();
        try {
            bancosSats.forEach(bancoSat => {
                this.setBancoSat(bancoSat);
            })
            this.setLoadingInitial(false);
            return bancosSats;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            return bancosSats;
        }
    }

    loadBancoSat = async (id: number) => {
        let bancoSat = this.getBancoSat(id);
        if (bancoSat) {
            this.selectedBancoSat = bancoSat;
            return bancoSat;

        } else {
            this.loadingInitial = true;
            try {
                bancoSat = await agent.BancosSats.details(id);
                this.selectedBancoSat = bancoSat;
                this.setLoadingInitial(false);
                return bancoSat;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setBancoSat = (bancoSat: BancoSat) => {
        this.bancoSatRegistry.set(bancoSat.id, bancoSat);
    }
    
    private getBancoSat = (id: number) => {
        return this.bancoSatRegistry.get(id);
    }
    
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createBancoSat = async (bancoSat: BancoSat) => {
        this.loading = true;
        try {
            await agent.BancosSats.create(bancoSat);
            runInAction(() => {
                this.bancoSatRegistry.set(bancoSat.id, bancoSat);
                this.selectedBancoSat = bancoSat;
                this.editMode = false;
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

   

    deleteBancoSat = async (id: number) => {
        this.loading = true;
        try {
            await agent.BancosSats.delete(id);
            runInAction(() => {
                this.bancoSatRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    get BancosSatByDropdown(){
        let bancoSatArreg=[new TipoDropDownValues()];
        let bancoSatOpt =new TipoDropDownValues();
        const cucltipos=  Array.from(this.bancoSatRegistry.values()).sort(
          (a, b) =>  {  if (a.nombre > b.nombre) return 1
            if (a.nombre < b.nombre) return -1
            return 0}
        );
        cucltipos.forEach((element) => {
            bancoSatOpt.key = element.id;
            bancoSatOpt.text = element.nombre;
            bancoSatOpt.value = element.id;
            bancoSatOpt.content = element.nombre;
            bancoSatArreg.push(bancoSatOpt);
            bancoSatOpt =new TipoDropDownValues();
          
        });
        return bancoSatArreg;
    }
}
