//import { useDispatch } from 'react-redux';
import validator from 'validator';
import { enumTipoDato, enumTipoRfc } from './../common/form/types/myTextinput-types'
//import { ShowCA } from '../reducers/CajaAlertaReducer'
//import { enumTipoBotonAlerta } from '../controles/types/enumsCajaAlerta'

export const useTipoDato = () => {
    //const dispatch = useDispatch()

    const listaEntidades = [
        { Clave: 'AS' },
        { Clave: 'BS' },
        { Clave: 'CL' },
        { Clave: 'CS' },
        { Clave: 'DF' },
        { Clave: 'GT' },
        { Clave: 'HG' },
        { Clave: 'MC' },
        { Clave: 'MS' },
        { Clave: 'NL' },
        { Clave: 'PL' },
        { Clave: 'QR' },
        { Clave: 'SL' },
        { Clave: 'TC' },
        { Clave: 'TL' },
        { Clave: 'YN' },
        { Clave: 'NE' },
        { Clave: 'BC' },
        { Clave: 'CC' },
        { Clave: 'CM' },
        { Clave: 'CH' },
        { Clave: 'DG' },
        { Clave: 'GR' },
        { Clave: 'JC' },
        { Clave: 'MN' },
        { Clave: 'NT' },
        { Clave: 'OC' },
        { Clave: 'QT' },
        { Clave: 'SP' },
        { Clave: 'SR' },
        { Clave: 'TS' },
        { Clave: 'VZ' },
        { Clave: 'ZS' }
    ]

    const validarRFC = (RFC, TipoRFC) => {
        let textoValidar = '';
        let resultado = true;
        if (!(RFC === "XAXX010101000" || RFC === "XEXX010101000")) {
            let Expresion = '';
            let regex = new RegExp("^([a-zA-ZñÑ&]{3,4}) ?(?: ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?: ?)?([a-zA-Z\\d]{2})([A\\d])$");
            if (!RFC.match(regex)) {
                return { success: false, tipoError: '' };
            }

            if (RFC.length === 13) {
                Expresion = "^[a-zA-ZñÑ&]*$";
                textoValidar = RFC.substring(0, 4);
                if (!textoValidar.match(Expresion)) {
                    return { success: false, tipoError: '' };
                }
                if (resultado) {
                    Expresion = "^[0-9]*$";
                    textoValidar = RFC.substring(4, 6);
                    if (!textoValidar.match(Expresion)) {
                        return { success: false, tipoError: '' };
                    } else {
                        try {
                            let Dia = RFC.substring(8, 10);
                            let Mes = RFC.substring(6, 8);
                            let anio = RFC.substring(4, 6);
                            if (anio === 0) {
                                anio = 2000;
                            }
                            new Date(anio, Mes, Dia);
                        } catch {
                            return { success: false, tipoError: '' };
                        }
                    }
                }

                if (resultado) {
                    Expresion = "^[a-zA-Z0-9]*$";
                    textoValidar = RFC.substring(10, 12);
                    if (!textoValidar.match(Expresion)) {
                        return { success: false, tipoError: '' };
                    }
                }


                if (resultado) {
                    let dVerificador = validarDigitoVerificadorRFC(RFC);
                    if (!dVerificador) {
                        return { success: false, tipoError: 'DigitoVerificador_Incorrecto' };
                    }
                }
            } else if (RFC.length === 12) {


                Expresion = "^[ñÑa-zA-Z&]*$";
                textoValidar = RFC.substring(0, 3);
                if (textoValidar.match(Expresion)) {
                    resultado = true;
                }

                if (resultado) {
                    Expresion = "^[0-9]*$";
                    textoValidar = RFC.substring(3, 9);
                    if (!textoValidar.match(Expresion)) {
                        return { success: false, tipoError: '' };
                    } else {
                        let Dia = RFC.substring(7, 9);
                        let Mes = RFC.substring(5, 7);
                        let anio = RFC.substring(3, 5);
                        try {
                            if (anio > 20) { anio += 1900; } else { anio += 2000; }
                            new Date(anio, Mes, Dia);
                        } catch {
                            if (Mes === 2 && Dia === 29) {
                                resultado = esBisiesto(anio);
                            } else {
                                return { success: false, tipoError: '' };
                            }
                        }
                    }
                }

                if (resultado) {
                    Expresion = "^[a-zA-Z0-9]*$";
                    textoValidar = RFC.substring(9, 11);
                    if (!textoValidar.match(Expresion)) {
                        return { success: false, tipoError: '' };
                    }
                }
                if (resultado) {
                    let dVerificador = validarDigitoVerificadorRFC(" " + RFC);
                    if (!dVerificador) {
                        return { success: false, tipoError: 'DigitoVerificador_Incorrecto' };
                    }
                }
            }
        }

        if (TipoRFC !== enumTipoRfc.Ambos && !(RFC === "XAXX010101000" || RFC === "XEXX010101000")) {
            if (TipoRFC === enumTipoRfc.Fisico) {
                if (RFC.length !== 13) {
                    return { success: false, tipoError: '' };
                }
            } else {
                if (RFC.length !== 12) {
                    return { success: false, tipoError: '' };
                }
            }
        }


        return { success: resultado, tipoError: '' };;
    }

    const esBisiesto = (anio) => {
        return ((anio % 4 === 0 && anio % 100 !== 0) || anio % 400 === 0);
    }

    const validarDigitoVerificadorRFC = (RFC) => {
        let resultado = true;
        let Expresion = "^[a-zA-Z0-9]*$";
        let textoValidar = RFC.substring(12, 13);

        if (!textoValidar.match(Expresion)) {
            resultado = false;
        } else {
            let ipi = 0;
            let isu = 0;
            let iva = 0;
            let sDig = "";
            let iax2 = 0;
            for (let li = 0; li < 12; li++) {
                let char_rfc = RFC.substring(li, li + 1).split('')[0].charCodeAt(0);
                iax2 = parseInt(char_rfc);
                iva = 0;
                ipi = 14 - (li + 1);
                if (iax2 > 47 && iax2 < 58) { iva = iax2 - 48; }
                if (iax2 > 64 && iax2 < 79) { iva = iax2 - 55; }
                if (iax2 > 78 && iax2 < 91) { iva = iax2 - 54; }
                if (iax2 === 38) { iva = 24; }
                if (iax2 === 32) { iva = 37; }
                isu = isu + iva * ipi;
            }
            iax2 = isu - parseInt(isu / 11) * 11;
            if (iax2 === 0) {
                sDig = "0";
            } else if (iax2 === 1) {
                sDig = "A";
            } else {
                sDig = parseInt(11 - iax2);
            }
            if (sDig.toString() !== textoValidar) { resultado = false; }
        }

        return resultado;

    }

    const validarDigitoVerificadorCURP = (CURP) => {
        let textoValidar = CURP.substring(17, 18);
        let Expresion = "^[0-9]*$";
        if (!textoValidar.match(Expresion)) {
            return { success: false, tipoError: 'DigitoVerificador_NoPermitido' };
        } else {
            let sSum = 0;
            let iChar = 0;
            let iVa = 0;

            for (let lCount = 0; lCount < 17; lCount++) {
                let char_rfc = CURP.substring(lCount, lCount + 1).split('')[0].charCodeAt(0);
                iChar = parseInt(char_rfc);
                iVa = 0;
                if (iChar > 47 && iChar < 58) { iVa = iChar - 48; }
                if (iChar > 64 && iChar < 79) { iVa = iChar - 55; }
                if (iChar > 78 && iChar < 91) { iVa = iChar - 54; }
                if (iChar === 165) { iVa = 24; }
                if (iChar === 32) { iVa = 37; }
                sSum = sSum + iVa * ((18 - (lCount + 1)) + 1);
            }

            iChar = sSum - parseInt(sSum / 10) * 10;
            let str = parseInt(10 - iChar).toString();
            let sChar = str.substring(str.length - 1, str.Length);
            if (sChar !== textoValidar) {
                return { success: false, tipoError: 'DigitoVerificador_Incorreto' };
            }
        }

        return { success: true, tipoError: '' };
    }

    const validateText = (value, tipoDato, aceptaNegativos, callback, formatoCaptura = "") => {

        let value2 = value;
        switch (tipoDato) {
            //#region enumTipoDato.Numerico

            case enumTipoDato.Numerico:
                if (value) {
                    if (value.endsWith('.')) {
                        value = value + '0'
                    }
                    if (!aceptaNegativos && value.indexOf('-') !== -1) {
                        callback(false, value2);
                        return false;
                    }
                    if (aceptaNegativos && value === '-') {
                        callback(true, value2);
                        return true;
                    }
                    //if (!validator.isDecimal(value, { force_decimal: false, decimal_digits: '1,6', locale: 'en-US' })) {
                    if (!formatoCaptura) { formatoCaptura = "1,6" }
                    if (!validator.isDecimal(value, { force_decimal: false, decimal_digits: formatoCaptura, locale: 'en-US' })) {
                        callback(false, value2);
                        return false;
                    }
                }
                break;
            //#endregion

            //#region enumTipoDato.NumericoSinPunto
            case enumTipoDato.NumericoSinPunto:
                if (value) {
                    if (!aceptaNegativos && value.indexOf('-') !== -1) {
                        return false;
                    }
                    if (aceptaNegativos && value === '-') {
                        return true;
                    }
                    if (!validator.isDecimal(value, { force_decimal: false, decimal_digits: '', locale: 'en-US' })) {
                        callback(false, value2);
                        return false;
                    }
                }
                break;
            //#endregion

            //#region enumTipoDato.Alfabetico
            case enumTipoDato.Alfabetico:
                value = value.replace(/[^A-Za-z]/g, '');
                value2 = value;
                if (value) {
                    value = value.replace(/  +/g, ' ');
                    if (!validator.isAlpha(value, ['es-ES'])) {
                        if (value.indexOf(' ') !== -1) {
                            value2 = value.replace(/ /g, '');
                        }
                        if (!validator.isAlpha(value2)) {
                            callback(false, value2);
                            return false;
                        }

                    }
                }
                break;
            //#endregion

            //#region enumTipoDato.Alfanumerico
            case enumTipoDato.Alfanumerico:
                if (value) {
                    if (!validator.isAlphanumeric(value, ['es-ES'])) {
                        value2 = value.replace(/  +/g, ' ');
                        if (value.indexOf(' ') !== -1) {
                            value2 = value.replace(/ /g, '');
                        }
                        if (!validator.isAlphanumeric(value2)) {
                            callback(false, value2);
                            return false;
                        }

                    }
                }
                break;
            //#endregion

            //#region enumTipoDato.NumericoFiltro
            case enumTipoDato.NumericoFiltro:
                value = value.replace(/[^\d,-]+/g, '');
                value2 = value;
                if (value) {
                    // debugger
                    if (value.includes(',,') || value.includes('--')) {
                        value2 = value.replaceAll(",,,", ",").replaceAll(",,", ",").replaceAll("--", "-");
                    }
                    if (value.indexOf('--') !== -1) {
                        callback(false, value);
                        return false;
                    }
                    if (value.indexOf(',-') !== -1 || value.indexOf('-,') !== -1) {
                        callback(false, value);
                        return false;
                    }
                    if (value.startsWith('-') || value.startsWith(',')) {
                        value2 = value.substring(1, value.length);
                    }
                    // if (value.endsWith('-') || value.endsWith(',')) {
                    //     value2 = value.substring(0, value.length - 1);
                    // }
                    if (!value.match('^[0-9,-]*$') && value !== "") {
                        callback(false, value);
                        return false;
                    }
                    //validamos solo un guión por agrupador
                    const pos = value.split(',');
                    let count = null;

                    for (let index = 0; index < pos.length; index++) {
                        const element = pos[index];
                        count = (element.match(/-/g) || []).length;
                        if (count > 1) { index = 9999999; }
                    }
                    if (count > 1) {
                        callback(false, value);
                        return false;
                    }
                }
                value2 = value2.replace(",,", ",");
                break;
            //#endregion

            //#region enumTipoDato.NumericoComa

            case enumTipoDato.NumericoComa:
                if (value) {
                    if (value.startsWith(',')) {
                        callback(false, value);
                        return false;
                    }
                    if (value.indexOf(',,') !== -1) {
                        callback(false, value);
                        return false;
                    }


                    if (!value.match('^[0-9,]*$')) {
                        callback(false, value);
                        return false;
                    }

                }
                break;
            //#endregion

            //#region enumTipoDato.NumericoEspacio

            case enumTipoDato.NumericoEspacio:
                if (value) {
                    if (value.startsWith(' ')) {
                        callback(false, value);
                        return false;
                    }
                    if (value.indexOf('  ') !== -1) {
                        callback(false, value);
                        return false;
                    }


                    if (!value.match('^[0-9 ]*$')) {
                        callback(false, value);
                        return false;
                    }

                }
                break;
            //#endregion

            //#region enumTipoDato.NumericoGuion

            case enumTipoDato.NumericoGuion:
                if (value) {
                    if (value.startsWith('-')) {
                        callback(false, value);
                        return false;
                    }
                    if (value.indexOf('--') !== -1) {
                        callback(false, value);
                        return false;
                    }


                    if (!value.match('^[0-9-]*$')) {
                        callback(false, value);
                        return false;
                    }

                }
                break;
            //#endregion

            //#region enumTipoDato.Estructura
            case enumTipoDato.Estructura:
                if (value) {
                    if (value.startsWith(',')) { callback(false, value); return false; }
                    if (value.indexOf(',,') !== -1) { callback(false, value); return false; }
                    value2 = value.replace(/[^ 9]/g, "");
                }
                break;
            //#endregion

            //#region enumTipoDato.Email

            case enumTipoDato.Email:
                if (value) {
                    if (value.indexOf(',,') !== -1) {
                        value = value.replace(',,', ',');
                    }
                    const emails = value.split(',');
                    for (var i = 0; i < emails.length; i++) {
                        if (!validator.isEmail(emails[i].trim())) {
                            callback(false, value2);
                            return false;
                        }
                    }
                }
                break;
            //#endregion

            //#region enumTipoDato.AlfanumericoGuion

            case enumTipoDato.AlfanumericoGuion:
                if (value) {
                    if (value.startsWith('-')) {
                        callback(false, value);
                        return false;
                    }
                    if (value.indexOf('--') !== -1) {
                        callback(false, value);
                        return false;
                    }


                    if (!value.match('^[a-zA-Z0-9-]*$')) {
                        callback(false, value);
                        return false;
                    }

                }
                break;
            //#endregion

            //#region enumTipoDato.AlfanumericoRFC

            case enumTipoDato.AlfanumericoRFC:
                if (value) {
                    if (!value.match('^[a-zA-Z0-9ñÑ&]*$')) {
                        callback(false, value);
                        return false;
                    }

                }
                break;
            //#endregion
            default: ;//caracteres

        }
        callback(true, value2);
    }
    const validarCURP = (CURP) => {
        CURP = CURP.toUpperCase();
        let resultado = true;
        let textoValidar = '';
        let Expresion = "^[a-zA-Z]{4}\\d{6}[a-zA-Z]{6}[a-zA-Z0-9]{1}\\d{1}$";
        if (!CURP.match(Expresion)) {
            return { success: false, tipoError: '' };
        } else {
            //#region Validar Iniciales
            Expresion = "^[a-zA-Z]*$";
            textoValidar = CURP.substring(0, 4);
            if (!textoValidar.match(Expresion)) { return { success: false, tipoError: '' }; }
            //#endregion
            // #region Validar Fecha    
            if (resultado) {
                Expresion = "^[0-9]*$";
                textoValidar = CURP.substring(4, 10);
                if (!textoValidar.match(Expresion)) {
                    return { success: false, tipoError: '' };
                } else {
                    try {
                        let Dia = CURP.substring(8, 10);
                        let Mes = CURP.substring(6, 8);
                        let anio = CURP.substring(4, 6);
                        if (anio === 0) { anio = 2000; }
                        new Date(anio, Mes, Dia);
                    } catch {
                        return { success: false, tipoError: '' };
                    }
                }
            }
            //#endregion
            //#region Validar Sexo
            if (resultado) {
                if (CURP.substring(10, 11) !== "H" && CURP.substring(10, 11) !== "M") {
                    return { success: false, tipoError: '' };
                }
            }
            //#endregion
            //#region Validar Entidad
            if (resultado) {
                Expresion = "^[a-zA-Z]*$";
                textoValidar = CURP.substring(11, 13);
                if (!textoValidar.match(Expresion)) {
                    return { success: false, tipoError: '' };
                } else {
                    let error = true;
                    for (let index = 0; index < listaEntidades.length; index++) {
                        const element = listaEntidades[index];
                        if (element.Clave === textoValidar) {
                            error = false;
                            break;
                        }
                    }
                    if (error) {
                        return { success: false, tipoError: '' };
                    }
                }
            }
            //#endregion
            //#region Validar letras Consonantes
            if (resultado) {
                let textoValidado = '';
                textoValidar = CURP.substring(13, 16);
                Expresion = "^[aeiouAEIOU]*$";
                textoValidado = textoValidar.replace(Expresion, "");
                Expresion = "^[a-zA-Z]*$";
                textoValidado = textoValidado.replace(Expresion, "");
                if (textoValidar !== textoValidado) {
                    return { success: false, tipoError: '' };
                }
            }
            //#endregion
            //#region Homoclave
            if (resultado) {
                textoValidar = CURP.substring(16, 17);
                Expresion = "^[a-zA-Z0-9]*$";
                if (!textoValidar.match(Expresion)) {
                    return { success: false, tipoError: '' };
                }
            }
            // #endregion
            // #region Validamos el Digito Verificador
            if (resultado) {
                let dVerificador = validarDigitoVerificadorCURP(CURP);
                if (!dVerificador.success) {
                    return dVerificador;
                }
            }
            // #endregion
        }
        return { success: true, tipoError: '' };
    }
    const validateTextBlur = (value, tipoDato, aceptaNegativos, callback, formatoCaptura = "") => {
        let esNavegacion = "";
        let tipoRfc = "";
        let value2 = value?.toString();
        switch (tipoDato) {
            //#region Numerico
            case enumTipoDato.Numerico: if (value) {
                if (value === "." || value === "-") {
                  
                    callback && callback(false, value2); return false;
                }
            } break;
            //#endregion
            //#region enumTipoDato.NumericoFiltro
            case enumTipoDato.NumericoFiltro:
                if (value) {
                    let _value = value.toString();
                    if (_value.endsWith('-') || _value.endsWith(',')) {
                        value2 = _value.substring(0, _value.length - 1);
                    }
                }
                break;
            //#endregion
            //#region enumTipoDato.Email
            case enumTipoDato.Email:
                if (value) {
                    if (value.indexOf(',,') !== -1) { value = value.replace(',,', ','); }
                    const emails = value.split(',');
                    let emailValidos = '';
                    for (var i = 0; i < emails.length; i++) {
                        if (validator.isEmail(emails[i].trim())) { emailValidos += emails[i].trim() + ","; }
                    }
                    if (emailValidos.length > 0) {
                        if (emailValidos.endsWith(',')) {
                            emailValidos = emailValidos.substring(0, emailValidos.length - 1);
                        }
                    }
                    callback && callback(true, emailValidos);
                    return true;
                }
                break;
            //#endregion
            //#region enumTipoDato.AlfanumericoRFC
            case enumTipoDato.AlfanumericoRFC:
                if (value) {
                    value = value.toUpperCase();
                    if (!esNavegacion) {
                        if (!value.match('^[a-zA-Z0-9ñÑ&]*$')) { callback && callback(false, ''); return false; }
                        const rfcMessage = validarRFC(value.toUpperCase(), tipoRfc);
                        if (!rfcMessage.success) { callback && callback(false, ''); return false; }
                    } else {
                        if (value === "N") { value = "XAXX010101000"; }
                        if (value === "E") { value = "XEXX010101000"; }
                        const rfcMessage = validarRFC(value.toUpperCase(), tipoRfc);

                        if (!rfcMessage.success) {
                            switch (rfcMessage.tipoError) {
                                case '':
                                    // dispatch(ShowCA({
                                    //     Mensaje: 'El dato capturado no es correcto, verifique.',
                                    //     Cerrar: (boton) => {
                                    //         callback && callback(false, value, true);
                                    //         return false;
                                    //     }
                                    // }));
                                    //Mostrar botones de que si es correcto o de correjir
                                    break;

                                //callback && callback(false, '', false);
                                //return false;
                                case 'DigitoVerificador_Incorrecto':
                                    // dispatch(ShowCA({
                                    //     Mensaje: 'No coincide el Dígito Verificador, verifique exhaustivamente.',
                                    //     TipoBoton: enumTipoBotonAlerta.SiNo,
                                    //     TextoSi: 'Es Correcto',
                                    //     TextoNo: 'Corregir',
                                    //     Cerrar: (boton) => {
                                    //         if (boton === enumTipoBotonAlerta.Si) {
                                    //             callback && callback(true, value);
                                    //             return true;
                                    //         } else {
                                    //             callback && callback(false, value, true);
                                    //             return false;
                                    //         }
                                    //     }
                                    // }));
                                    //Mostrar botones de que si es correcto o de correjir
                                    break;
                                default: break;
                            }
                            return false;
                        } else {
                            if (value.toUpperCase() === "XEXX010101000") { //&& this.MostrarMensajeRfcExtranjero) {
                                // dispatch(ShowCA({ Mensaje: 'El RFC Genérico para extranjeros es indicativo de que facturará con IVA a tasa cero en automático.' }));
                            }
                        }
                        if (value === "XAXX010101000" || value === "XEXX010101000") { callback && callback(true, value); return true; }
                    }
                }
                break;
            //#endregion
            //#region enumTipoDato.AlfanumericoCURP
            case enumTipoDato.AlfanumericoCURP:
                if (value) {
                    const result = validarCURP(value);
                    if (!result.success) {
                        // if (!esNavegacion) {
                        //     callback(false, '');
                        //     return false;
                        // } else {
                        if (result.tipoError === 'DigitoVerificador_Incorreto') {
                            // dispatch(ShowCA({
                            //     Mensaje: 'No coincide el Dígito Verificador, verifique exhaustivamente.',
                            //     TipoBoton: enumTipoBotonAlerta.SiNo,
                            //     TextoSi: 'Es Correcto',
                            //     TextoNo: 'Corregir',
                            //     Cerrar: (boton) => {
                            //         if (boton === enumTipoBotonAlerta.Si) {
                            //             callback && callback(true, value);
                            //         } else {
                            //             callback && callback(false, value, true);
                            //         }
                            //     }
                            // }));
                            //colocar botones de si es correcto o corregir
                        }
                        //}
                    }
                }
                break;
            //#endregion
            //#region enumTipoDato.Fecha
            case enumTipoDato.Fecha: if (value) { } break;
            //#endregion
            default: ;//caracteres
        }
        callback && callback(true, value2);
        return true;
    }
    return [validateText, validateTextBlur];
}