import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { history } from "../../..";
import { Estado } from "../../models/configuracion/Estado";
import { TipoDropDownValues } from "../../models/configuracion/TipoDropdown";

export default class EstadoStore {
  estadoRegistry = new Map<number, Estado>();
  selectedEstado: Estado | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get estadoById() {
    return Array.from(this.estadoRegistry.values()).sort((a, b) => a.clave - b.clave);
  }

  get EstadosByDropdown(){
    let EstadosArreg=[new TipoDropDownValues()];
    let estadosOpt =new TipoDropDownValues();
    const ests=  Array.from(this.estadoRegistry.values()).sort(
      (a, b) =>  {  if (a.nombre > b.nombre) return 1
        if (a.nombre < b.nombre) return -1
        return 0}
    );
    ests.forEach((element) => {
      estadosOpt.key = element.id;
      estadosOpt.text = element.nombre;
      estadosOpt.value = element.id;
      estadosOpt.content = element.nombre;
      EstadosArreg.push(estadosOpt);
      estadosOpt =new TipoDropDownValues();
      
    });
    return EstadosArreg;
    }

  loadEstados = async () => {
    this.setLoadingInitial(true);
    try {
      const estados = await agent.Estados.list();
      estados.forEach((estado) => {
        this.setEstado(estado);
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  getEstados = async () => {
    this.setLoadingInitial(true);
    const estados = await agent.Estados.list();
    try {
      estados.forEach((estado) => {
        this.setEstado(estado);
      });
      this.setLoadingInitial(false);
      return estados;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      return estados;
    }
  };

  loadEstado = async (id: number) => {
    let estado = this.getEstado(id);
    if (estado) {
      this.selectedEstado = estado;
      return estado;
    } else {
      this.loadingInitial = true;
      try {
        estado = await agent.Estados.details(id);
        this.setEstado(estado);
        this.selectedEstado = estado;
        this.setLoadingInitial(false);
        return estado;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  private setEstado = (estado: Estado) => {
    this.estadoRegistry.set(estado.id, estado);
  };

  private getEstado = (id: number) => {
    return this.estadoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createEstado = async (estado: Estado) => {
    this.loading = true;
    try {
      await agent.Estados.create(estado);
      runInAction(() => {
        this.selectedEstado = estado;
        this.editMode = false;
        this.loading = false;
      });
      
      toast.success("Estado creado con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateEstado = async (estado: Estado) => {
    this.loading = true;
    try {
      
      await agent.Estados.update(estado);
      runInAction(() => {
        this.selectedEstado = estado;
        this.editMode = false;
        this.loading = false;
      });
      toast.success("Estado modificada con éxito");
    } catch (error) {
      toast.error("Problema al enviar datos");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteEstado = async (id: number) => {
    this.loading = true;
    try {
      await agent.Estados.delete(id);
      runInAction(() => {
        this.estadoRegistry.delete(id);
        this.loading = false;
      });
      toast.success("Estado eliminado con éxito");
    } catch (error) {
      toast.error("Problema al eliminar el estado");
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
