import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Empresa } from "../configuracion/Empresa";
import { Sucursal } from "../configuracion/Sucursal";
import { Almacen } from "./Almacen";
import { Producto } from "./Producto";
import { UnidadMedida } from "./UnidadMedida";

export interface InventarioFisico {
    id: number;
    empresaId: number;
    empresa?: Empresa;
    sucursalId: number;
    sucursal?: Sucursal;
    almacenId: number;
    almacen?: Almacen;
    clave: number;
    fecha: Date;
    fechaCancelacion?: Date;
    comentarios: string;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
    detalles: InventarioFisicoDetalles[];
    
}
export interface InventarioFisicoDetalles {
    id: number;
    productoId: number;
    producto?: Producto;
    unidadMedidaId: number;
    unidadMedida?: UnidadMedida;
    existencia: number;
    costo: number;
    costoAlmacen: number;
    diferencia: number;
    descripcion:string;
}


export class InventarioFisicoDetallesFormValues implements InventarioFisicoDetalles {
    id: number = 0;
    productoId: number = 0;
    producto?: Producto;
    unidadMedidaId: number = 0;
    unidadMedida?: UnidadMedida;
    existencia: number = 0;
    costo: number = 0;
    costoAlmacen: number = 0;
    diferencia: number = 0;
    descripcion:string ="";
    constructor(init?: InventarioFisicoDetalles) {
        Object.assign(this, init);
    }
}


export class InventarioFisicoFormValues implements InventarioFisico {
    id: number = 0;
    empresaId: number = 0;
    empresa?: Empresa;
    sucursalId: number = 0;
    sucursal?: Sucursal;
    almacenId: number =0;
    almacen?: Almacen;
    clave: number = 0;
    fecha: Date = new Date();
    fechaCancelacion?: Date;
    comentarios: string = "";
    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion = new FechaObservacionValues();
    detalles: InventarioFisicoDetalles[] = [new InventarioFisicoDetallesFormValues()]
    constructor(init?: InventarioFisico) {
        Object.assign(this, init);
    }
}