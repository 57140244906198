import React, { useEffect, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import { Button, Divider, Grid, GridColumn, Icon, Label, Segment } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import { GeneracionComprobantePagoDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobantePagosDetalle';
import { CorteDeCajaEfectivoFormValues } from '../../../../app/models/venta/CorteDeCajaEfectivo';

interface Props {
  efectivo: CorteDeCajaEfectivoFormValues;
  closeModals: () => void;
  setPagos: (setFO: CorteDeCajaEfectivoFormValues) => void;
  tabIndex: number;
}

export default function CorteDeCajaEfectivoForm(props: Props) {
  const firstElement = props.tabIndex + 1;
  const totalEfectivo = 2500;
  const lastElement = props.tabIndex + 13;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { condicionPagoStore, formaPagoStore } = useStore();
  const { condicionesPagosByDropdown } = condicionPagoStore;
  const { formasPagosByDropdown } = formaPagoStore;
  const validationSchema = Yup.object({
    importe: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
  });
  useEffect(() => {
    condicionPagoStore.getCondicionesPagos();
  }, [])

  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    props.setPagos(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={CorteDeCajaEfectivoFormValues}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <div className="col100">
            <FieldArray
              name="pagos"
              render={(arrayHelpers) => (
                <Segment placeholder>
                  <Grid columns={2} relaxed='very' stackable>
                    <GridColumn>
                      <Form>
                        <div className='cols'>
                          <div className='col30'>Billetes 20 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete20" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col30'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                        <div className='cols'>
                          <div className='col30'>Billetes 50 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete50" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col30'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                        <div className='cols'>
                          <div className='col30'>Billetes 100 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete100" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                        <div className='cols'>
                          <div className='col30'>Billetes 200 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete200" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                        <div className='cols'>
                          <div className='col30'>Billetes 500 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete500" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                        <div className='cols'>
                          <div className='col30'>Billetes 1000 pesos </div>
                          <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadBillete1000" tabIndex={0} maxLength={8} />                        </div>
                          <div className='col5'> = </div>
                          <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                        </div>
                      </Form>
                    </GridColumn>

                    <GridColumn verticalAlign='middle'>
                      <div className='cols'>
                        <div className='col30'>Monedas 10 Centavos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda10c" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 20 Centavos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda20c" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 50 Centavos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda50c" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 1 Peso </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda1" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 2 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda2" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 5 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda5" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 10 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda10" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 20 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda20" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 50 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda50" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>
                      <div className='cols'>
                        <div className='col30'>Monedas 100 Pesos </div>
                        <div className='col20'><MyTextInput placeholder="cantidad" name="CantidadMoneda100" tabIndex={0} maxLength={8} />                        </div>
                        <div className='col5'> = </div>
                        <div className='col25'><MyTextInput placeholder="Total" name="Total" tabIndex={0} maxLength={8} /></div>
                      </div>

                    </GridColumn>
                  </Grid>
                  <Divider vertical></Divider>
                </Segment>
              )}
            />
          </div>
          <div><Label as='a' color='red' ribbon='right' >Total Efectivo: {totalEfectivo}</Label></div>
          <Segment>
            <Button.Group>
              <Button
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>
  )
}