import React, { useEffect, useState } from 'react'
import MyTextInput from "../../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from "formik";
import MySelectInput from '../../../../app/common/form/MySelectInput';
import { Button, Icon, Modal, ModalContent, ModalHeader, Segment, Table } from 'semantic-ui-react';
import { enterInputs, tabuladorButtons } from '../../../../app/common/funciones/tabuladorEnter';
import { useStore } from '../../../../app/stores/store';
import DeleteModals from '../../../modals/DeleteModals';
import { enumHeaderModals } from '../../../modals/MensajeModals';
import { observer } from 'mobx-react-lite';
import { GeneracionComprobanteNotaCredito, GeneracionComprobanteNotaCreditoFormValues } from '../../../../app/models/venta/GeneracionComprobanteNotaCredito';
import { GeneracionComprobanteNotaCreditoDetalleFormValues } from '../../../../app/models/venta/GeneracionComprobanteNotaCreditoDetalle';

interface Props {
  notaCredito: GeneracionComprobanteNotaCredito;
  closeModals: () => void;
  setNotaCreditos: (setFO: GeneracionComprobanteNotaCreditoFormValues) => void;
  tabIndex: number;
  empresaId:number;
  onCopy?: (data: any) => void;
}

export default observer(function NotaCreditoComprobante(props: Props) {
  const firstElement = props.tabIndex + 1;
  const lastElement = props.tabIndex + 13;
  const [open, setOpen] = useState({ abrir: false, index: 0 })
  const { serieFolioStore, proveedorStore, cuentaDeVentaStore, sucursalStore, tipoVentaStore, c_TipoRelacionStore, tipoNotaCreditoStore, generacionComprobanteStore } = useStore();
  const { loadSerieByIdTipoVenta, SeriesFoliosByEmpresaByDropdown } = serieFolioStore
  const { c_TiposRelacionesByDropdown } = c_TipoRelacionStore;
  const { tiposNotasCreditosByDropdown } = tipoNotaCreditoStore;
  const { sucursalesByEmpresaByDropdown } = sucursalStore;
  const { TiposVentasByDropdown } = tipoVentaStore
  const { cuentasDeVentasByDropdown } = cuentaDeVentaStore
  const { empresaSucursal } = proveedorStore
  const { cargarComprobante  } = generacionComprobanteStore

  const validationSchema = Yup.object({
    importe: Yup.number()
      .min(1, "Favor de seleccionar el tipo de venta.")
      .required("El tipo de venta es requerido."),
  });


  useEffect(() => {
    empresaSucursal.empresaId = props.empresaId;
    empresaSucursal.sucursalId = 1;
    sucursalStore.loadSucursalByIdEmpresa(1);
    tipoNotaCreditoStore.getTiposNotasCreditos();
    c_TipoRelacionStore.getc_TiposRelaciones();
    tipoVentaStore.getTiposVentas();
    cuentaDeVentaStore.getCuentasDeVentas();

  }, [])

  const initialIndexTable = 14;
  const numberControlsTable = 7;
  const [lastElementTable, setLastElementTable] = useState(numberControlsTable + initialIndexTable);

  function onKeyDownSelectImporte(clave: number, setFiledValue: any, values: any, arrayHelpers: any) {
    debugger
    arrayHelpers?.push({ condiciondePagoId: 0, formaDePagoId: 0, importe: 0 });
    setLastElementTable(
      (prevLastElementTable) =>
        prevLastElementTable + numberControlsTable
    );
  }

  /*function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
    
  }*/

  function enterInput(tabIndex: number) {
    enterInputs(tabIndex, firstElement, lastElement);
  }
  const tabuladorButton = (event: any) => {
    tabuladorButtons(event, firstElement, lastElement);
  };
  function handleSubmit(fo: any) {
    debugger
    props.onCopy?.(fo);
    props.setNotaCreditos(fo);
    props.closeModals();
  }
  function regresar(event: any) {
    event.preventDefault();
    props.closeModals();
  }
  function onChangeSelectSucursal(idSelected: number) {
    empresaSucursal.sucursalId = idSelected;
    // void loadProductoByEmpresaSucursal(empresaSucursal);
  }
  function idSelectTipo(idSelectedTipoVenta: number) {
    empresaSucursal.tipoVentaId = idSelectedTipoVenta;
    debugger
    void loadSerieByIdTipoVenta(empresaSucursal);
  }

  function idSelectSerie(idSelectedSerie: number, setFieldValue: any) {
    empresaSucursal.serieId = idSelectedSerie;
  }

  function deletedeclined() {
    setOpen({ abrir: false, index: 0 })
  }
  function deleteConfirmed(e: string, id: number, arrayHelpers: any, index: any) {
    arrayHelpers.remove(index);
    setOpen({ abrir: false, index: 0 })
  }


  function onKeyUpFolio(folio: any, setFieldValue: any, event: any, arrayHelpers: any) {
    empresaSucursal.folio = parseInt(folio);
    if (event.key === "Enter" || event.key === "Tab") {
      cargarComprobante(empresaSucursal).then((result) => {
        debugger

        if (result?.id !== 0) {
          var ddc = new GeneracionComprobanteNotaCreditoDetalleFormValues();
          ddc.serie = result?.serieFolioDeVenta ? (result?.serieFolioDeVenta.clave) : ""
          ddc.folio = folio;
          ddc.importe = result ? (result?.total) : 0
          ddc.uuid = result?(result?.uuid):"";
          setFieldValue("notaCreditoDetalles", [ddc])
        } else {

        }
      })
    }




  }

  function chageEnter(tabIndex: number) {
    //obtenerSiguienteFolio(empresaSucursal);
  }
  const getIndexTable = (index: number, numberInput: number) => {
    return initialIndexTable + numberInput + numberControlsTable * index;
  };
  return (

    <Formik
      //validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize
      initialValues={props.notaCredito}
    >
      {({ values, isValid, dirty }) => (
        <Form className="ui form">
          <Segment>
            <div className="col100">
              <FieldArray
                name="notaCreditoDetalles"
                render={(arrayHelpers) => (
                  /////
                  <Segment>
                    <Segment>
                      <div >
                        <MySelectInput
                          tabIndex={9}
                          label="Tipo Nota de Crédito"
                          placeholder="Tipo Notas de Crédito"
                          name="tipoNotaCreditoId"
                          options={tiposNotasCreditosByDropdown}
                          selectWithLabel={true}
                          enterInput={enterInput} />
                      </div>
                      <div className='col50'>
                        <MySelectInput
                          tabIndex={9}
                          label="Tipo Relacion SAT"
                          placeholder="Tipo Relacion SAT"
                          name="c_TipoRelacionId"
                          options={c_TiposRelacionesByDropdown}
                          selectWithLabel={true}
                          enterInput={enterInput} 
                          inputWidth="85px"
                          menuWidth="400px"
                          />
                      </div>
                      <div className='col50'>
                        <MyTextInput
                          tabIndex={11}
                          placeholder='UUID'
                          label="UUID"
                          name='UUID'
                          maxLength={20}
                          enterInput={enterInput}
                        />
                      </div>
                      <div className='col40'></div>
                      <div className='col20'>
                        <MySelectInput
                          tabIndex={1}
                          placeholder={"Seleccionar"}
                          label="cuenta"
                          name="cuentaId"
                          options={cuentasDeVentasByDropdown}
                          enterInput={enterInput}
                          inputWidth="120px"
                          menuWidth="300px"
                        />
                      </div>
                      <div className='col20'>
                        <MySelectInput
                          tabIndex={1}
                          placeholder={"Seleccionar"}
                          label="Sucursal"
                          name="sucursalId"
                          options={sucursalesByEmpresaByDropdown}
                          enterInput={enterInput}
                          onChange={onChangeSelectSucursal}
                          inputWidth="120px"
                          menuWidth="300px"
                        />
                      </div>
                      <div className='col20'>
                        <MySelectInput
                          tabIndex={2}
                          placeholder={"Seleccionar"}
                          label="Tipo Venta"
                          name="tipoVentaId"
                          options={TiposVentasByDropdown}
                          enterInput={enterInput}
                          onChange={idSelectTipo}
                          inputWidth="120px"
                          menuWidth="300px"
                        />
                      </div>
                      <div className='col20'>
                        <MySelectInput
                          tabIndex={3}
                          placeholder="Serie"
                          label="Serie"
                          name="serieFolioDeVentaId"
                          options={SeriesFoliosByEmpresaByDropdown}
                          enterInput={enterInput}
                          onChange={idSelectSerie}
                          inputWidth="120px"
                          menuWidth="300px"
                        />
                      </div>
                      <div className='col15'>
                        <MyTextInput
                          className="textAlingRight widthtext160 heighttext input"
                          tabIndex={4}
                          placeholder=''
                          label="Folio"
                          name='folio'
                          enterInput={enterInput}
                          onKeyDown={onKeyUpFolio}
                          change={chageEnter}
                          
                          
                        />
                      </div>
                    </Segment>

                    <Table
                      style={{
                        overflow: "auto",
                        display: "inline-block",
                        maxHeight: "inherit",
                        width: 660,
                        height: 200
                      }}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Serie</Table.HeaderCell>
                          <Table.HeaderCell>Folio</Table.HeaderCell>
                          <Table.HeaderCell>Importe</Table.HeaderCell>
                          <Table.HeaderCell>UUID</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {values.notaCreditoDetalles?.map(
                          (notaCredito, index) => (

                            <Table.Row key={index.valueOf()} className="paddinTable">

                              <Table.Cell className="paddinTable">
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Serie"
                                  name={`notaCreditoDetalles[${index}].serie`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}

                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Folio"
                                  name={`notaCreditoDetalles[${index}].folio`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>



                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable100 heighttext input textAlingRight"
                                  placeholder="Importe"
                                  name={`notaCreditoDetalles[${index}].importe`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                <MyTextInput
                                  tabIndex={getIndexTable(2, 6)}
                                  className="widthTextTable200 heighttext input textAlingRight"
                                  placeholder="UUID"
                                  name={`notaCreditoDetalles[${index}].uuid`}
                                  enterInput={enterInput}
                                  arrayHelpers={arrayHelpers}
                                  onKeyDown={onKeyDownSelectImporte}
                                  // index={index}
                                  columna={3}
                                />
                              </Table.Cell>


                              <Table.Cell>
                                <Button icon
                                  tabIndex={getIndexTable(2, 7)}
                                  name={notaCredito.id}
                                  type="Button"
                                  disabled={false}
                                  onClick={() => setOpen({ abrir: true, index: index })}
                                  floated="right"
                                  //    content="Eliminar"
                                  color="red"
                                >
                                  <Icon name='delete' />
                                </Button>
                              </Table.Cell>
                              <Modal
                                onClose={() => setOpen({ abrir: false, index: 0 })}
                                open={open.abrir}
                                size='mini'
                              >
                                <ModalHeader>{enumHeaderModals.DetallesComprobante}</ModalHeader>
                                <ModalContent>
                                  <DeleteModals
                                    deleteConfirmed={deleteConfirmed}
                                    deletedeclined={deletedeclined}
                                    e={""}
                                    id={notaCredito.id}
                                    nombre={"Fila"}
                                    arrayHelpers={arrayHelpers}
                                    index={open.index}
                                  />
                                </ModalContent>
                              </Modal>
                            </Table.Row>
                          )
                        )
                        }

                        {!values.notaCreditoDetalles?.length ? (
                          //null
                          arrayHelpers.push(new GeneracionComprobanteNotaCreditoDetalleFormValues())
                        ) : null}
                      </Table.Body>
                    </Table>



                  </Segment>
                  /////
                )}
              />
            </div>
          </Segment>
          <Segment>
            <Button.Group>
              <Button
                //onClick={(e) => regresar(e)}
                tabIndex={props.tabIndex + 12}
                type="submit"
              >
                <Icon name="save" />
                Aceptar
              </Button>
              <Button
                tabIndex={props.tabIndex + 13}
                onClick={(e) => regresar(e)}
                onKeyDown={tabuladorButton}
              >
                <Icon name="backward" />
                Cancelar
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      )}
    </Formik>

  )
})