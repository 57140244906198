import { makeAutoObservable } from "mobx";
import agent from "../../api/agent";
import { ReporteVentas } from "../../models/venta/ReporteVentas";
import { VerificadorGestionProveedor } from "../../models/gestionProveedor/VerificadorGestionProveedor";

export default class ReporteGestionProveedoresStore {
    reporteVentaRegistry = new Map<number, ReporteVentas>();
    selectedReporteVenta: ReporteVentas | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }


    imprimirReporteGestionProveedores = async (filtros: ReporteVentas) => {
        try {
            debugger
            const NombreReporte = await agent.ReportesGestionProveedores.imprimirReporteCuentaProveedor(filtros);
            debugger
            if (NombreReporte) {
                return NombreReporte;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
            //this.setLoadingInitial(false);
        }
    };

    imprimirAnaliticaProveedores = async (filtros: VerificadorGestionProveedor) => {
        try {
            debugger
            const verificadorGestionCliente = await agent.ReportesGestionProveedores.imprimirAnaliticaProveedores(filtros);
            if (verificadorGestionCliente) {
                return verificadorGestionCliente;
            } else {
                this.loadingInitial = true;
            }
        } catch (error) {
            console.log(error);
        }
    };

}