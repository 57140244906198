import { FechaObservacion, FechaObservacionValues } from "../configuracion/FechaObservacion";
import { Impuesto } from "../configuracion/Impuesto";


export interface ImpuestoProducto {
    id: number;
    clave:number;
    nombre: string;
    ivaTrasladadoId?:number;
    ivaTrasladado?:Impuesto;
    ivaTrasladadoIncluidoEnPrecio:boolean;
    iepsTrasladadoId?:number;
    iepsTrasladado?:Impuesto;
    impuestoLocalTrasladadoId?:number;
    impuestoLocalTrasladado?:Impuesto;
    impuestoLocalRetenidoId?:number;
    impuestoLocalRetenido?:Impuesto;
    impuestoRetenidoId?:number;
    iImpuestoRetenido?:Impuesto;
    ivaRetenidoId?:number;
    ivaRetenido?:Impuesto;
    exento:boolean;
    fechaObservacionId: number;
    fechaObservacion: FechaObservacion;
}

export class ImpuestoProductoFormValues implements ImpuestoProducto {
    id:number= 0;
    clave:number= 0;
    nombre:string= "";
    ivaTrasladadoId?:number =0;
    ivaTrasladado?:Impuesto;
    ivaTrasladadoIncluidoEnPrecio:boolean = false;
    iepsTrasladadoId?:number =0;
    iepsTrasladado?:Impuesto;
    impuestoLocalTrasladadoId?:number=0;
    impuestoLocalTrasladado?:Impuesto;
    impuestoLocalRetenidoId?:number=0;
    impuestoLocalRetenido?:Impuesto;
    impuestoRetenidoId?:number =0;
    iImpuestoRetenido?:Impuesto;
    ivaRetenidoId?:number=0;
    ivaRetenido?:Impuesto;
    exento:boolean = false;

    fechaObservacionId: number = 0;
    fechaObservacion: FechaObservacion=new FechaObservacionValues();
    constructor(init?: ImpuestoProducto) {
        Object.assign(this, init);
    }
}